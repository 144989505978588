import { useState } from "react";
import { useContext } from "react";
import { View, Text } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import IconButton from "./IconButton";
import moment from "moment";
import Modal from "./Modal";
import { modalPickerOpts } from "../lib/helperFns";
import DatePickerRow from "./DatePickerRow";
import MonthPicker from "./MonthPicker";

const getRangeStart = (rangeStart, timePeriod) => {
  return rangeStart ? rangeStart : moment().startOf(timePeriod);
};
const getRangeEnd = (rangeEnd, timePeriod) => {
  return rangeEnd ? rangeEnd : moment().add(1, timePeriod).endOf(timePeriod);
};
export default function DateRangeSelector(props) {
  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const [datePicker, setDatePicker] = useState({ isVisible: false });

  const startDate = props.rangeStart?.format("DD.MM.YYYY");
  const endDate = props.rangeEnd?.format("DD.MM.YYYY");
  const timePeriod = props.timePeriod || "months";

  const onRangeStartChange = (newDate) => {
    props.onChange({
      rangeStart: newDate,
      rangeEnd:
        !props.rangeEnd || newDate > props.rangeEnd
          ? newDate.clone().add(1, timePeriod).endOf(timePeriod)
          : props.rangeEnd,
    });
  };

  const onRangeEndChange = (newDate) => {
    props.onChange({
      rangeStart:
        !props.rangeStart || newDate < props.rangeStart
          ? newDate.clone().subtract(1, timePeriod).endOf(timePeriod)
          : props.rangeStart,
      rangeEnd: newDate,
    });
  };

  const openDatePicker = (prop) => (datePickerProps) => {
    setDatePicker({
      isVisible: true,
      ...datePickerProps,
      onConfirm: (year, month, monthNum, day) => {
        const newDate = moment(`${day}.${monthNum}.${year}`, "D.M.YYYY");

        if (prop === "rangeStart") onRangeStartChange(newDate);
        else onRangeEndChange(newDate);
        setDatePicker({ isVisible: false });
      },
    });
  };

  return (
    <View style={theming.theme.rowContainer}>
      <IconButton
        backgroundColor={theming.colors.accent}
        color={theming.colors.textOnAccent}
        theme={theming.theme}
        onPress={() => {
          props.onChange({
            rangeStart: getRangeStart(props.rangeStart, timePeriod)
              .clone()
              .subtract(1, timePeriod)
              .startOf(timePeriod),
            rangeEnd: getRangeEnd(props.rangeEnd, timePeriod)
              .clone()
              .subtract(1, timePeriod)
              .endOf(timePeriod),
          });
        }}
        icon="chevron-left"
        width={36}
        fillHeight
      />

      <DatePickerRow
        setDatePicker={() => {
          openDatePicker("rangeStart")({
            date: startDate,
            title: t("rangeStart"),
            visible: true,
            timePicker: props.timePicker,
            minDate: props.minDate,
            maxDate: props.maxDate,
            dateFormat: props.dateFormat,
          });
        }}
        setDate={(date) => {
          if (date) {
            onRangeStartChange(moment(date));
          } else {
            props.onChange({
              rangeStart: undefined,
            });
          }
        }}
        timePicker={props.timePicker}
        dateFormat={props.dateFormat}
        date={startDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />

      <View style={theming.theme.padding4}>
        <Text style={theming.theme.boldText}>-</Text>
      </View>

      <DatePickerRow
        setDatePicker={() => {
          openDatePicker("rangeEnd")({
            date: endDate,
            title: t("rangeEnd"),
            visible: true,
            timePicker: props.timePicker,
            minDate: props.minDate,
            maxDate: props.maxDate,
            dateFormat: props.dateFormat,
          });
        }}
        setDate={(date) => {
          if (date) {
            onRangeEndChange(moment(date));
          } else {
            props.onChange({
              rangeEnd: undefined,
            });
          }
        }}
        timePicker={props.timePicker}
        dateFormat={props.dateFormat}
        date={endDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />

      <IconButton
        backgroundColor={theming.colors.accent}
        color={theming.colors.textOnAccent}
        theme={theming.theme}
        onPress={() => {
          props.onChange({
            rangeStart: getRangeStart(props.rangeStart, timePeriod)
              .clone()
              .add(1, timePeriod)
              .startOf(timePeriod),
            rangeEnd: getRangeEnd(props.rangeEnd, timePeriod)
              .clone()
              .add(1, timePeriod)
              .endOf(timePeriod),
          });
        }}
        icon="chevron-right"
        width={36}
        fillHeight
      />

      <Modal
        {...modalPickerOpts}
        isVisible={datePicker.isVisible}
        onBackButtonPress={() => setDatePicker({ isVisible: false })}
        onBackdropPress={() => setDatePicker({ isVisible: false })}
        backdropColor={theming.colors.borderLighter}
        backdropOpacity={0.6}
      >
        <MonthPicker
          {...datePicker}
          noToggleOnConfirm
          lang={props.lang}
          dayPicker={true}
          toggle={() => setDatePicker({ isVisible: false })}
          onConfirm={datePicker.onConfirm}
          noModal
        />
      </Modal>
    </View>
  );
}

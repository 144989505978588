import React from "react";
import { Text, View } from "react-native";
import moment from "moment";
import { getLocation } from "../lib/helperFns";
import { cellTypesWithExtraValueKey } from "../lib/constants";
import CheckBoxRow from "./CheckBoxRow";
import TextInputRow from "./SimpleTextInputRow";
import MultilineTextInputRow from "./MultilineTextInputRow";
import FilePickerRows from "./FilePickerRows";
import ModalPicker from "./ModalPicker";
import TextModalButton from "./TextModalButton";
import PickerObjectsCell from "./PickerObjectsCell";
import GraphCell from "./GraphCell";
import CounterComponent from "./CounterComponent";
import CellIconButton from "./CellIconButton";

import { ExtraRowsCell, DividedRowInputs } from "../internal";
import RowText from "./RowText";

import DatePickerRow from "./DatePickerRow";

import {
  errorReport,
  getCalculatedTextValues,
  getTranslatedText,
  evaluateCellEquation,
  getTranslatedOptions,
  checkInputVisibility,
  getTranslatedUnit,
  getPickerChoices,
  checkNeedToBeFilledBefore,
  getDocProps,
} from "../lib/functions";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SET_COMPANY_SETTINGS_PROP } from "../actions/ProfileActions";
import { ADD_ATTACHMENT } from "../actions/OptionsActions";

const getValueKey = (prefix, key) => (prefix ? `${prefix}_${key}` : key);

const shouldCellUpdate = (
  cell,
  props,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix = ""
) => {
  const valueKey = getValueKey(valueKeyPrefix, cell.valueKey);
  return (
    values[valueKey] !== nextValues[valueKey] ||
    shouldDefaultValueKeyCauseUpdate(
      cell,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldLocationCauseUpdate(cell, props, nextProps) ||
    shouldExtraValueUpdate(
      cell,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldRadioCellUpdate(
      cell,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldCalculatedTextUpdate(
      cell,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldChartUpdate(cell, nextProps, nextValues, values, valueKeyPrefix) ||
    shouldChecksToBeVisibleUpdate(
      cell,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldDividedInputsUpdate(
      cell,
      props,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldExtraRowsUpdate(
      cell,
      props,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    ) ||
    shouldAtchCellUpdate(
      cell,
      props,
      nextProps,
      nextValues,
      values,
      valueKeyPrefix
    )
  );
};

const shouldLocationCauseUpdate = (cell, props, nextProps) => {
  if (
    cell.type === "textField" &&
    cell.location &&
    (props.gpsLocation !== nextProps.gpsLocation ||
      props.company?.settings?.mapboxAccessToken !==
        nextProps.company?.settings?.mapboxAccessToken)
  )
    return true;
};
const shouldDefaultValueKeyCauseUpdate = (
  cell,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell.defaultValueKey) {
    const defaultValueKey = getValueKey(valueKeyPrefix, cell.defaultValueKey);
    return values[defaultValueKey] !== nextValues[defaultValueKey];
  } else {
    return false;
  }
};
const shouldExtraValueUpdate = (
  item,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (
    item.extraValueKeyUsage ||
    cellTypesWithExtraValueKey.includes(item.type)
  ) {
    const _valueKey = getValueKey(valueKeyPrefix, item.valueKey + ".5");
    return values[_valueKey] !== nextValues[_valueKey];
  } else {
    return false;
  }
};
const shouldRadioCellUpdate = (
  cell,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell?.type.startsWith("radio")) {
    return cell.inputs?.some((x) => {
      const _valueKey = getValueKey(valueKeyPrefix, x.valueKey);
      return values[_valueKey] !== nextValues[_valueKey];
    });
  } else {
    return false;
  }
};
const shouldCalculatedTextUpdate = (
  cell,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell?.type === "calculatedText" && Array.isArray(cell.fnProps)) {
    const _values = getCalculatedTextValues(
      cell.fnProps,
      valueKeyPrefix,
      values
    );
    const _nextValues = getCalculatedTextValues(
      cell.fnProps,
      valueKeyPrefix,
      nextValues
    );
    return (
      _values.length !== _nextValues.length ||
      _values.some((x, i) => x !== _nextValues[i])
    );
    // // return cell.fnProps.some((x) => {
    // //   const _valueKey = getValueKey(valueKeyPrefix, x);
    // //   return values[_valueKey] !== nextValues[_valueKey];
    // // });
  } else {
    return false;
  }
};
const shouldChartUpdate = (
  cell,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell?.type === "chart" || cell?.type === "graph") {
    const dataProps = cell.chartData?.dataProps;
    let valueKeys = [];

    if (dataProps) {
      if (dataProps?.type === "measurementObjects") {
        values[dataProps.layoutId]?.forEach((measObj) => {
          dataProps.valueKeys.x?.forEach((_valueKey) => {
            valueKeys.push(
              `${dataProps.layoutId}_${measObj.valueKey}_${_valueKey}`
            );
          });
          dataProps.valueKeys.y?.forEach((_valueKey) => {
            valueKeys.push(
              `${dataProps.layoutId}_${measObj.valueKey}_${_valueKey}`
            );
          });
        });
      } else if (dataProps?.type === "values") {
        if (dataProps.valueKeys) {
          dataProps.valueKeys.x?.forEach((_valueKey) => {
            valueKeys.push(getValueKey(valueKeyPrefix, _valueKey));
          });
          dataProps.valueKeys.y?.forEach((_valueKey) => {
            valueKeys.push(getValueKey(valueKeyPrefix, _valueKey));
          });
        }
      }
      return valueKeys.some((x) => values[x] !== nextValues[x]);
    } else {
      const _valueKey = getValueKey(valueKeyPrefix, cell.valueKey);
      return values[_valueKey] !== nextValues[_valueKey];
    }
  } else {
    return false;
  }
};
const shouldChecksToBeVisibleUpdate = (
  cell,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (Array.isArray(cell?.checksToBeVisible)) {
    return cell.checksToBeVisible.some((x) => {
      const _valueKey = getValueKey(valueKeyPrefix, x.valueKey);
      return values[_valueKey] !== nextValues[_valueKey];
    });
  } else {
    return false;
  }
};
const shouldDividedInputsUpdate = (
  cell,
  props,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell?.type === "dividedInputs" && Array.isArray(cell.inputs)) {
    return cell.inputs.some((x) =>
      shouldCellUpdate(x, props, nextProps, nextValues, values, valueKeyPrefix)
    );
  } else {
    return false;
  }
};
const shouldExtraRowsUpdate = (
  cell,
  props,
  nextProps,
  nextValues,
  values,
  valueKeyPrefix
) => {
  if (cell?.type === "extraRows" && cell.inputs) {
    const _valueKey = getValueKey(valueKeyPrefix, cell.valueKey);
    return Array.isArray(values[_valueKey])
      ? values[_valueKey].some((valueItem) =>
          cell.inputs.some((input) => {
            const _valueKeyPrefix = getValueKey(
              valueKeyPrefix,
              `${cell.valueKey}_${valueItem.valueKey}`
            );
            return shouldCellUpdate(
              input,
              props,
              nextProps,
              nextValues,
              values,
              _valueKeyPrefix
            );
          })
        )
      : false;
  } else {
    return false;
  }
};
const shouldAtchCellUpdate = (cell, props, nextProps) => {
  if (
    cell &&
    (cell.type === "attachment" ||
      cell.type === "multiAttachment" ||
      cell.type === "pdfAttachment")
  ) {
    return (
      props.fullWidth !== nextProps.fullWidth ||
      props.fullHeight !== nextProps.fullHeight
    );
  } else {
    return false;
  }
};

export class CellComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshingLocation: false,
    };
  }

  componentDidMount() {
    try {
      const {
        item,
        value,
        extraModifyProps,
        lang,
        options,
        modifyValue,
        addToObjectArr,
        profile,
        valueKeyPrefix,
      } = this.props;

      const values = this.props.tmpValues || this.props.values || {};
      const { type = "" } = item;

      let defaultVal = item.defaultValue
        ? getTranslatedText(item.defaultValue, lang)
        : null;
      if (defaultVal === "{date}") {
        defaultVal = moment().format("DD.MM.YYYY");
      } else if (defaultVal === "{userName}") {
        if (profile?.name) {
          defaultVal = profile.name + " " + profile.lName;
        }
      }

      const valueKey = getValueKey(valueKeyPrefix, item.valueKey);

      const hasValue = Object.prototype.hasOwnProperty.call(values, valueKey);
      let _value =
        value ??
        (hasValue
          ? values[valueKey]
          : item.defaultValueKey
          ? values[
              valueKeyPrefix
                ? `${valueKeyPrefix}_${item.defaultValueKey}`
                : item.defaultValueKey
            ]
          : undefined);

      // TODO don'this.props.t save default value, just use it here and in pdfCreator if the field is not filled
      if (type === "checkBox" || type === "checkBoxText") {
        if (item.checkedByDefault && valueKey && !hasValue) {
          modifyValue({
            ...extraModifyProps,
            valueKey: valueKey,
            value: true,
            docId: this.props.docId,
          });
        }
      } else if (type === "dualCheckBox" || type === "dualCheckBoxText") {
        if (item.checkedByDefault && valueKey && !hasValue) {
          modifyValue({
            ...extraModifyProps,
            valueKey: valueKey,
            value: true,
            docId: this.props.docId,
          });
        }
      }
      // else if (type === "calculatedText") {
      //   const _calculatedVal =
      //     (fn && fnProps
      //       ? `${evaluateCellEquation(
      //           fn,
      //           fnProps,
      //           valueKeyPrefix,
      //           values,
      //           options
      //         )}`
      //       : _value) ?? "-";

      //   if (item.valueKey && values[valueKey] !== _calculatedVal) {
      //     modifyValue({
      //       ...extraModifyProps,
      //       valueKey: valueKey,
      //       value: _calculatedVal,
      //       docId: this.props.docId,
      //     });
      //   }
      // }
      else if (type === "textField" || type === "multilineField") {
        if (defaultVal && valueKey && !hasValue) {
          if (defaultVal.includes("{location}")) {
            getLocation(
              this.props.company?.settings?.mapboxAccessToken,
              this.props.SET_COMPANY_SETTINGS_PROP,
              this.props.gpsLocation,
              this.props.SET_GPS_LOCATION,
              (refreshing) => {
                this.setState({ refreshingLocation: refreshing });
              },
              (value) => {
                if (value)
                  modifyValue({
                    ...extraModifyProps,
                    valueKey: valueKey,
                    value: defaultVal.replace("{location}", value),
                    docId: this.props.docId,
                  });
              },
              item.location
            );
          } else {
            modifyValue({
              ...extraModifyProps,
              valueKey: valueKey,
              value: defaultVal,
              docId: this.props.docId,
            });
          }
        }
      } else if (type === "datePicker" || type === "datePickerCheckBox") {
        const _defaultVal = item.defaultValue
          ? getTranslatedText(item.defaultValue, lang)
          : null;
        if (
          typeof _defaultVal === "string" &&
          _defaultVal.includes("{creationDate}")
        ) {
          const isIsoDate = moment(
            _value,
            "YYYY-MM-DDTHH:mm:ssZ",
            true
          ).isValid();
          const isIsoDate2 = moment(
            _value,
            "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
            true
          ).isValid();
          const dateFormat =
            item.dateFormat ??
            (item.timePicker ? "HH:mm DD.MM.YYYY" : "DD.MM.YYYY");
          let __value = isIsoDate
            ? moment(_value, "YYYY-MM-DDTHH:mm:ssZ").format(dateFormat)
            : isIsoDate2
            ? moment(_value, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format(dateFormat)
            : hasValue && moment(_value, dateFormat).isValid()
            ? _value
            : defaultVal
            ? moment().format(dateFormat)
            : undefined;

          if (defaultVal && !hasValue && valueKey) {
            modifyValue({
              ...extraModifyProps,
              valueKey: valueKey,
              value: __value,
              docId: this.props.docId,
            });
          }
        }
      } else if (
        type === "picker" ||
        type === "pickerTextarea" ||
        type === "pickerTextField" ||
        type === "multiPicker"
      ) {
        const isMultiPicker = type === "multiPicker";
        if (!hasValue && valueKey) {
          if (isMultiPicker) {
            if (item.defaultOptions) {
              modifyValue({
                ...extraModifyProps,
                valueKey: valueKey,
                value: getTranslatedOptions(item.defaultOptions, lang),
                docId: this.props.docId,
              });
            }
          } else {
            if (defaultVal) {
              modifyValue({
                ...extraModifyProps,
                valueKey: valueKey,
                value: defaultVal,
                docId: this.props.docId,
              });
            }
          }
        }
      } else if (
        type === "attachment" ||
        type === "multiAttachment" ||
        type === "pdfAttachment"
      ) {
        if (item.defaultAttachments && !_value && valueKey) {
          modifyValue({
            valueKey: valueKey,
            value: item.defaultAttachments,
            docId: this.props.docId,
          });
        }
        // update attachment names
        else if (_value && valueKey) {
          let hasOutDatedAttachments = false;
          let newValue = Array.isArray(_value)
            ? _value.map((x) => {
                const optionsAtch = options.attachments?.[x.id];
                if (
                  x &&
                  optionsAtch &&
                  !x.docRef &&
                  optionsAtch.name !== x.name
                ) {
                  hasOutDatedAttachments = true;
                  return {
                    id: x.id,
                    name: optionsAtch.name,
                    desc: x.desc || optionsAtch.desc,
                    height: x.height,
                    offlineAtch: x.offlineAtch,
                    hideInGallery: x.hideInGallery,
                    saveFailed: x.saveFailed,
                    hidePreview: x.hidePreview,
                  };
                } else {
                  return x;
                }
              })
            : _value;
          if (hasOutDatedAttachments) {
            modifyValue({
              valueKey: valueKey,
              value: newValue,
              docId: this.props.docId,
            });
          }
        }
      } else if (type === "extraRows") {
        if (item?.presetObjects && valueKey && !hasValue) {
          item.presetObjects.forEach((x) => {
            addToObjectArr({
              type: "ExtraRow",
              valueKey,
              value: { valueKey: x.valueKey },
              docId: this.props.docId,
              idProp: "valueKey",
            });

            if (x.cells) {
              // TODO only defaultValue that works is primitive types, not arrays etc.
              x.cells.forEach((cell) => {
                modifyValue({
                  docId: this.props.docId,
                  valueKey: `${valueKey}_${x.valueKey}_${cell.valueKey}`,
                  value: getTranslatedText(cell.defaultValue, lang),
                });
              });
            }
          });
        }
      }
    } catch (error) {
      errorReport({
        error,
        errorInFn: "componentDidMount",
        errorInScreen: "CellComponent",
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const values = this.props.values || {};
    // TODO test that measuremtnObjects and modularItems work with valueKeyPrefix change
    const nextValues = nextProps.values || {};
    // const ogValueKeyPrefix = this.props.valueKey
    //   ?.split?.("_")
    //   .slice(0, -1)
    //   .join("_");

    if (this.props.optimize) {
      if (
        this.props.docId !== nextProps.docId ||
        this.props.uiSettings !== nextProps.uiSettings ||
        this.props.visibleExtraRowsData !== nextProps.visibleExtraRowsData ||
        this.props.onlineDoc !== nextProps.onlineDoc ||
        this.props.role !== nextProps.role ||
        this.props.options !== nextProps.options ||
        this.props.isFetching !== nextProps.isFetching ||
        this.props.disabled !== nextProps.disabled ||
        this.props.valueKey !== nextProps.valueKey ||
        this.props.value !== nextProps.value ||
        this.props.modalVisible !== nextProps.modalVisible ||
        this.state.selected !== nextState.selected ||
        this.state.refreshingLocation !== nextState.refreshingLocation ||
        this.props.lang !== nextProps.lang ||
        this.props.rangeStart !== nextProps.rangeStart ||
        this.props.rangeEnd !== nextProps.rangeEnd
      ) {
        return true;
      }
      // if cells value / values have changed => update
      else {
        return shouldCellUpdate(
          this.props.item,
          this.props,
          nextProps,
          nextValues,
          values,
          this.props.valueKeyPrefix
        );
      }
    } else {
      return true;
    }
  }

  render() {
    const {
      docId,
      childKey = "",
      item,
      itemIndex,
      innerItemIndex,
      navigation,
      value,
      // valueKey = "",
      modifyValueItem,
      extraModifyProps,
      extraCellProps,
      lang,
      theme,
      colors,
      setDatePicker,
      toggleDocSearchModal,
      chartRefs,
      setChartRef,
      options,
      screenToGoBackTo = "create",
      role,
      isFetching,
      onlineDoc,
      setTextModal,
      // modify functions
      modifyObjectArrItem,
      deleteFromObjectArr,
      deleteFromStringArr,
      modifyStringArr,
      addToStringArr,
      modifyValue,
      profile,
      valueKeyPrefix,
    } = this.props;

    const values = this.props.tmpValues || this.props.values || {};
    const {
      type = "",
      title = {},
      formTitle,
      firstCheckTitle = {},
      secondCheckTitle = {},
      fn,
      fnProps,
    } = item;
    const disabled = this.props.disabled || item.disabled;
    const noDisabledStyle = this.props.noDisabledStyle || item.noDisabledStyle;
    const _title = getTranslatedText(formTitle ?? title, lang);

    const _unit = getTranslatedText(item?.unit, lang);
    const _hint = item.hint ? getTranslatedText(item?.hint, lang) : null;

    let defaultVal = item.defaultValue
      ? getTranslatedText(item.defaultValue, lang)
      : null;
    if (defaultVal === "{date}") {
      defaultVal = moment().format("DD.MM.YYYY");
    } else if (defaultVal === "{userName}") {
      if (profile?.name) {
        defaultVal = profile.name + " " + profile.lName;
      }
    }

    // const valueKeyPrefix = valueKey.split?.("_").slice(0, -1).join("_");

    const valueKey = getValueKey(valueKeyPrefix, item.valueKey);
    const extraValueKey =
      item.extraValueKeyUsage || cellTypesWithExtraValueKey.includes(item.type)
        ? `${valueKey}.5`
        : "";

    const hasValue = Object.prototype.hasOwnProperty.call(values, valueKey);
    let _value =
      value ??
      (hasValue
        ? values[valueKey]
        : item.defaultValueKey
        ? values[
            valueKeyPrefix
              ? `${valueKeyPrefix}_${item.defaultValueKey}`
              : item.defaultValueKey
          ]
        : undefined);

    const keyIndices = childKey + `${valueKey}_${itemIndex}_${innerItemIndex}`;

    if (
      !checkInputVisibility({
        lang,
        input: this.props.item,
        values,
        valueKeyPrefix,
        role,
        doc: this.props.docToModify,
      })
    ) {
      return null;
    } else {
      if (type === "checkBox" || type === "checkBoxText") {
        const _firstCheckTitle = getTranslatedText(firstCheckTitle, lang);
        const _secondCheckTitle = getTranslatedText(secondCheckTitle, lang);

        const viewKey = `CheckBoxRowView${keyIndices}`;
        const key = `CheckBoxRow${keyIndices}`;
        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <CheckBoxRow
              key={key}
              testID={key}
              disabled={disabled}
              longTitle={true}
              title={_firstCheckTitle ? _title : null}
              firstCheckTitle={_firstCheckTitle || _title}
              secondCheckTitle={_secondCheckTitle || null}
              checked={_value}
              booleanChecked={true}
              nullable={item.nullable}
              boldTitle={true}
              hint={_hint}
              onClear={() => {
                modifyValue({
                  ...extraModifyProps,
                  valueKey,
                  value: null,
                  docId: this.props.docId,
                });
              }}
              setChecked={(id, value) =>
                modifyValue({
                  ...extraModifyProps,
                  valueKey,
                  value: value,
                  docId: this.props.docId,
                })
              }
            />
          </View>
        );
      } else if (type === "dualCheckBox" || type === "dualCheckBoxText") {
        const _firstCheckTitle = getTranslatedText(firstCheckTitle, lang);
        const _secondCheckTitle = getTranslatedText(secondCheckTitle, lang);

        const viewKey = `DualCheckBoxRowView${keyIndices}`;
        const key = `DualCheckBoxRow${keyIndices}`;
        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <CheckBoxRow
              key={key}
              testID={key}
              disabled={disabled}
              title={_title}
              firstCheckTitle={_firstCheckTitle || this.props.t("yes")}
              secondCheckTitle={_secondCheckTitle || this.props.t("no")}
              extraValueKey={extraValueKey}
              booleanChecked={true}
              checked={_value}
              checked2={values[extraValueKey]}
              longTitle={true}
              boldTitle={true}
              hint={_hint}
              onClear={() => {
                modifyValue({
                  ...extraModifyProps,
                  valueKey: valueKey,
                  value: null,
                  docId: this.props.docId,
                });
              }}
              setChecked={(id, value, checkBoxValueKey) =>
                modifyValue({
                  ...extraModifyProps,
                  valueKey: checkBoxValueKey ?? valueKey,
                  value: value,
                  docId: this.props.docId,
                })
              }
            />
          </View>
        );
      } else if (type === "radio" || type === "radioSelect") {
        const viewKey = `RadioSelectView${keyIndices}`;
        const key = `RadioSelect${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <DividedRowInputs
              key={viewKey}
              childKey={key}
              {...this.props}
              title={_title}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              valueKeyPrefix={valueKeyPrefix}
              modifyValue={(payload) => {
                const keys = item.inputs.reduce((prev, input) => {
                  prev.push(
                    valueKeyPrefix
                      ? `${valueKeyPrefix}_${input.valueKey}`
                      : input.valueKey
                  );
                  return prev;
                }, []);

                for (let i = 0; i < keys.length; i++) {
                  const _key = keys[i];
                  if (_key !== payload.valueKey) {
                    modifyValue({
                      valueKey: _key,
                      value: null,
                      docId: this.props.docId,
                    });
                  } else {
                    modifyValue(payload);
                  }
                }
              }}
            />
          </View>
        );
      } else if (type === "calculatedText") {
        // TODO debounce editing
        const viewKey = `TextView${keyIndices}`;
        const key = `TextRow${keyIndices}`;

        const _calculatedVal =
          (fn
            ? `${evaluateCellEquation(
                fn,
                fnProps,
                item.maxDecimals,
                valueKeyPrefix,
                values,
                options
              )}`
            : _value) ?? "-";

        if (item.valueKey && values[valueKey] !== _calculatedVal) {
          modifyValue({
            ...extraModifyProps,
            valueKey: valueKey,
            value: _calculatedVal,
            docId: this.props.docId,
          });
        }

        return (
          <View
            key={onlineDoc ? null : viewKey}
            testID={onlineDoc ? null : viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <RowText
              key={key}
              testID={key}
              hint={_hint}
              hintTable={item.hintTable}
              theme={theme}
              colors={colors}
              title={_title}
              text={_calculatedVal}
              lang={lang}
            />
          </View>
        );
      } else if (type === "textField") {
        const viewKey = `TextInputRowView${keyIndices}`;
        const key = `TextInputRow${keyIndices}`;
        const _optionsProp =
          item.prop === "user"
            ? `userOption.${item.optionsProp}`
            : item.optionsProp;
        const optionsOptions = item.optionsProp
          ? item.optionsProp === "users"
            ? this.props.options.users.reduce((_options, user) => {
                if (user.email && user.name) {
                  _options.push(user.name + " " + user.lName);
                }
                return _options;
              }, [])
            : options?.[_optionsProp]
          : null;
        const itemOptions = getTranslatedOptions(item.options, lang);
        const optionsToUse = item.optionsProp
          ? item.optionsProp && optionsOptions
            ? itemOptions.concat(optionsOptions)
            : itemOptions
          : null;

        const addUnit = (value) => {
          modifyValue({
            ...extraModifyProps,
            valueKey: extraValueKey,
            value: value,
            docId: this.props.docId,
          });
        };
        const removeUnit = () => {
          modifyValue({
            ...extraModifyProps,
            valueKey: extraValueKey,
            value: "",
            docId: this.props.docId,
          });
        };

        return (
          <View
            key={onlineDoc ? null : viewKey}
            testID={onlineDoc ? null : viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <TextModalButton
              setTextModal={setTextModal}
              onlineDoc={onlineDoc}
              viewKey={viewKey}
              title={_title}
              placeholder={_value}
              multiline={false}
              visible={true}
              valueKey={valueKey}
              value={_value}
              numeric={item?.numeric}
              maxDecimals={item.maxDecimals}
              unit={_unit}
              theme={theme}
              colors={colors}
              disabled={disabled}
              getMultiEditValueKeys={this.props.getMultiEditValueKeys}
            >
              <TextInputRow
                hint={_hint}
                lang={lang}
                hintTable={item.hintTable}
                key={key}
                testID={key}
                disabled={onlineDoc || disabled}
                noDisabledStyle={onlineDoc || noDisabledStyle}
                value={_value}
                valueKey={valueKey}
                cellRef={this.props.cellRef}
                setCellRef={this.props.setCellRef}
                focusNextInput={this.props.focusNextInput}
                // for some reason asdf works for divided inputs
                onChangeText={(newText) => {
                  modifyValue({
                    ...extraModifyProps,
                    valueKey,
                    value: newText,
                    docId: this.props.docId,
                  });
                }}
                unit={
                  item.extraValueKeyUsage === "unit" &&
                  extraValueKey &&
                  values[extraValueKey]
                    ? values[extraValueKey]
                    : getTranslatedText(item?.unit, lang)
                }
                numeric={item?.numeric}
                title={_title}
                border={false}
                maxLength={item?.maxLength}
                maxDecimals={item.maxDecimals}
                minValue={item?.minValue}
                maxValue={item?.maxValue}
                endIcon={item.location ? "map-marker-outline" : null}
                loadingIcon={item.location && this.state.refreshingLocation}
                onEndIconPress={
                  item.location
                    ? () => {
                        getLocation(
                          this.props.company?.settings?.mapboxAccessToken,
                          this.props.SET_COMPANY_SETTINGS_PROP,
                          this.props.gpsLocation,
                          this.props.SET_GPS_LOCATION,
                          (refreshing) => {
                            this.setState({ refreshingLocation: refreshing });
                          },
                          (value) => {
                            if (value)
                              modifyValue({
                                ...extraModifyProps,
                                valueKey,
                                value: value,
                                docId: this.props.docId,
                              });
                          },
                          item.location
                        );
                      }
                    : null
                }
                onUnitPress={
                  extraValueKey
                    ? () => {
                        this.props.setPicker({
                          visible: true,
                          addableProp: item.addableProp,
                          optionsProp: _optionsProp,
                          valueKey: extraValueKey,
                          value: values[extraValueKey],
                          title: this.props.t("unit"),
                          options: optionsToUse,
                          itemOptions: itemOptions,
                          modifyValueItem: modifyValueItem,
                          onPress: addUnit,
                          onRemove: removeUnit,
                          onAdd: addUnit,
                          modifyValue: modifyValue,
                        });
                      }
                    : null
                }
                autoFocus={this.props.autoFocus}
                handleChangeReturn
              />
            </TextModalButton>
          </View>
        );
      } else if (type === "multilineField") {
        const viewKey = `MultilineTextInputRowView${keyIndices}`;
        const key = `MultilineTextInputRow${keyIndices}`;
        return (
          <TextModalButton
            setTextModal={setTextModal}
            onlineDoc={onlineDoc}
            viewKey={viewKey}
            title={_title}
            placeholder={_value}
            multiline={true}
            visible={true}
            valueKey={valueKey}
            value={_value}
            numeric={item.numeric}
            maxDecimals={item.maxDecimals}
            hint={_hint}
            lang={lang}
            hintTable={item.hintTable}
            unit={_unit}
            theme={theme}
            colors={colors}
            disabled={disabled}
            getMultiEditValueKeys={this.props.getMultiEditValueKeys}
          >
            <View
              key={onlineDoc ? null : viewKey}
              testID={onlineDoc ? null : viewKey}
              style={[
                theme.rowContainer,
                this.props.noTopMargin ? null : theme.marginTop16,
              ]}
            >
              <MultilineTextInputRow
                key={key}
                testID={key}
                disabled={onlineDoc || disabled}
                noDisabledStyle={onlineDoc || noDisabledStyle}
                valueKey={valueKey}
                cellRef={this.props.cellRef}
                setCellRef={this.props.setCellRef}
                focusNextInput={this.props.focusNextInput}
                value={_value}
                unit={getTranslatedUnit(item?.unit, lang)}
                hint={_hint}
                lang={lang}
                hintTable={item.hintTable}
                setTargetProperty={(prop, value) =>
                  modifyValue({
                    ...extraModifyProps,
                    valueKey,
                    value: value,
                    docId: this.props.docId,
                  })
                }
                title={_title}
                autoFocus={this.props.autoFocus}
              />
            </View>
          </TextModalButton>
        );
      } else if (type === "datePicker") {
        const viewKey = `DatePickerView${keyIndices}`;
        const key = `DatePicker${keyIndices}`;

        const isIsoDate = moment(
          _value,
          "YYYY-MM-DDTHH:mm:ssZ",
          true
        ).isValid();
        const isIsoDate2 = moment(
          _value,
          "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
          true
        ).isValid();
        const dateFormat =
          item.dateFormat ??
          (item.timePicker ? "HH:mm DD.MM.YYYY" : "DD.MM.YYYY");
        let __value = isIsoDate
          ? moment(_value, "YYYY-MM-DDTHH:mm:ssZ").format(dateFormat)
          : isIsoDate2
          ? moment(_value, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format(dateFormat)
          : hasValue && moment(_value, dateFormat).isValid()
          ? _value
          : defaultVal
          ? moment().format(dateFormat)
          : undefined;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <DatePickerRow
              key={key}
              disabled={disabled}
              noDisabledStyle={noDisabledStyle}
              testID={key}
              setDatePicker={() => {
                if (setDatePicker) {
                  setDatePicker({
                    date: __value,
                    valueKey,
                    title: _title,
                    visible: true,
                    timePicker: item.timePicker,
                    minDate: item.minDate,
                    maxDate: item.maxDate,
                    dateFormat: item.dateFormat,
                  });
                }
              }}
              setDate={(date) => {
                modifyValue({
                  docId: this.props.docId,
                  valueKey,
                  value: date ? moment(date).format(dateFormat) : null,
                });
              }}
              timePicker={item.timePicker}
              dateFormat={dateFormat}
              date={__value}
              title={_title}
              minDate={item.minDate}
              maxDate={item.maxDate}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
            />
          </View>
        );
      } else if (type === "datePickerCheckBox") {
        const _firstCheckTitle = getTranslatedText(firstCheckTitle, lang);
        const _secondCheckTitle = getTranslatedText(secondCheckTitle, lang);

        const viewKey = `DatePickerCheckBoxView${keyIndices}`;
        const key = `DatePickerCheckBox${keyIndices}`;

        const isIsoDate = moment(
          _value,
          "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
          true
        ).isValid();
        const isIsoDate2 = moment(
          _value,
          "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
          true
        ).isValid();
        const dateFormat =
          item.dateFormat ??
          (item.timePicker ? "HH:mm DD.MM.YYYY" : "DD.MM.YYYY");
        let __value = isIsoDate
          ? moment(_value, "YYYY-MM-DDTHH:mm:ssZ").format(dateFormat)
          : isIsoDate2
          ? moment(_value, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format(dateFormat)
          : hasValue && moment(_value, dateFormat).isValid()
          ? _value
          : defaultVal
          ? moment().format(dateFormat)
          : undefined;
        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={[
              theme.rowContainer,
              this.props.noTopMargin ? null : theme.marginTop16,
            ]}
          >
            <DatePickerRow
              key={key}
              testID={key}
              disabled={disabled}
              setDatePicker={() => {
                if (setDatePicker) {
                  setDatePicker({
                    date: __value,
                    valueKey,
                    title: _title,
                    visible: true,
                  });
                }
              }}
              setDate={(date) => {
                modifyValue({
                  docId: this.props.docId,
                  valueKey,
                  value: date ? moment(date).format(dateFormat) : null,
                });
              }}
              timePicker={item.timePicker}
              dateFormat={dateFormat}
              date={__value}
              title={_title}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              checkBoxToDisableDate={true}
              checkBoxToDisableDateTitle={_firstCheckTitle}
              secondCheckTitle={_secondCheckTitle}
              checkBoxToDisableDateTitleChecked={values?.[extraValueKey]}
              checkBoxToDisableDateTitleSetChecked={(value) => {
                modifyValue({
                  ...extraModifyProps,
                  valueKey: extraValueKey,
                  value: value,
                  docId: this.props.docId,
                });
              }}
            />
          </View>
        );
      } else if (
        type === "picker" ||
        type === "pickerTextarea" ||
        type === "pickerTextField" ||
        type === "multiPicker"
      ) {
        const viewKey = `ModalPickerView${keyIndices}`;
        const key = `ModalPicker${keyIndices}`;
        const filledBeforeChoices = getPickerChoices(
          item.filledBeforeChoices,
          values,
          valueKeyPrefix,
          lang
        );
        // add userOption in front of the property to not confuse with company wide options
        // server also does this
        const _optionsProp =
          item.prop === "user"
            ? `userOption.${item.optionsProp}`
            : item.optionsProp;
        const itemOptions = getTranslatedOptions(item.options, lang);
        const optionsOptions =
          _optionsProp === "users"
            ? this.props.options.users.reduce((_options, user) => {
                if (user.email && user.name) {
                  _options.push(user.name + " " + user.lName);
                }
                return _options;
              }, [])
            : options?.[_optionsProp];
        const optionsToUse =
          filledBeforeChoices.length < 1
            ? item.optionsProp && optionsOptions
              ? itemOptions.concat(optionsOptions)
              : itemOptions
            : filledBeforeChoices;

        const isMultiPicker = type === "multiPicker";
        const _addableProp =
          item.optionsProp === "users" ? false : item.addableProp;
        const pickerModify = (value) => {
          isMultiPicker
            ? addToStringArr({
                ...extraModifyProps,
                valueKey,
                value: value,
                docId: this.props.docId,
              })
            : modifyValue({
                ...extraModifyProps,
                valueKey,
                value: value,
                docId: this.props.docId,
              });
        };
        const removeValue = () => {
          modifyValue({
            ...extraModifyProps,
            valueKey,
            value: "",
            docId: this.props.docId,
          });
        };
        // TODO just render a button that opens a modalPicker elsewhere, no need to render multiple pickers here
        return (
          <View style={theme.displayBlock}>
            <View
              key={viewKey}
              testID={viewKey}
              style={[
                theme.rowContainer,
                this.props.noTopMargin ? null : theme.marginTop16,
              ]}
            >
              <ModalPicker
                key={key}
                testID={key}
                docId={docId}
                hint={_hint}
                hintTable={item.hintTable}
                lastModified={options.lastModified}
                isFetching={isFetching}
                role={role}
                lang={lang}
                disabled={disabled}
                noDisabledStyle={noDisabledStyle}
                noRemoval={item.noRemoval}
                pageH={this.props.fullHeight}
                pageW={this.props.fullWidth}
                // openIfNotFilled={item.openIfNotFilled} // DEPRECATED, cases issues when multiple measurementObjects are opened
                setValueSequence={item.setValueSequence}
                extraCellProps={{ ...extraCellProps, values }}
                listKey={"ListKey" + key}
                onDelete={isMultiPicker ? null : removeValue}
                onBeforeModalOpen={() => {
                  return checkNeedToBeFilledBefore(
                    lang,
                    item.needToBeFilledBefore,
                    valueKeyPrefix,
                    this.props.cells,
                    values
                  );
                }}
                maxHeight={this.props.maxHeight}
                multiPicker={isMultiPicker}
                addableProp={_addableProp}
                prop={_optionsProp}
                isUserOption={item.prop === "user"}
                valueKey={valueKey}
                disableRefresh={!_addableProp}
                boldTitle={true}
                numeric={item.numeric}
                value={isMultiPicker ? null : _value}
                values={isMultiPicker ? _value : null}
                title={_title}
                options={optionsToUse}
                itemOptions={itemOptions}
                modifyValueItem={modifyValueItem}
                onPress={pickerModify}
                onRemove={(oldVal) =>
                  deleteFromStringArr({
                    ...extraModifyProps,
                    valueKey,
                    oldVal: oldVal,
                    docId: this.props.docId,
                  })
                }
                onAdd={pickerModify}
                modifyValue={(payload) => {
                  isMultiPicker
                    ? modifyStringArr(payload)
                    : modifyValue(payload);
                }}
              />
            </View>
            {type === "pickerTextarea" ? (
              <CellComponent
                {...this.props}
                item={{
                  ...this.props.item,
                  title: null,
                  type: "multilineField",
                }}
                noTopMargin
              />
            ) : type === "pickerTextField" ? (
              <CellComponent
                {...this.props}
                item={{
                  ...this.props.item,
                  title: null,
                  type: "textField",
                }}
                noTopMargin
              />
            ) : null}
            {type === "pickerTextarea"
              ? null
              : // (
                //   <TextModalButton
                //     setTextModal={setTextModal}
                //     onlineDoc={onlineDoc}
                //     viewKey={viewKey + "_2"}
                //     title={_title}
                //     placeholder={_value}
                //     multiline={true}
                //     visible={true}
                //     valueKey={valueKey}
                //     value={_value}
                //     numeric={item?.numeric}
                //     maxDecimals={item.maxDecimals}
                //     unit={_unit}
                //     theme={theme}
                //     colors={colors}
                //     disabled={disabled}
                //     getMultiEditValueKeys={this.props.getMultiEditValueKeys}
                //   >
                //     <View
                //       key={onlineDoc ? null : viewKey + "_2"}
                //       testID={onlineDoc ? null : viewKey + "_2"}
                //       style={theme.rowContainer}
                //     >
                //       <MultilineTextInputRow
                //         key={key}
                //         testID={key}
                //         disabled={onlineDoc || disabled}
                //         noDisabledStyle={onlineDoc || noDisabledStyle}
                //         valueKey={valueKey}
                //         cellRef={this.props.cellRef}
                //         setCellRef={this.props.setCellRef}
                //         focusNextInput={this.props.focusNextInput}
                //         value={_value}
                //         unit={getTranslatedUnit(item?.unit, lang)}
                //         setTargetProperty={(prop, value) =>
                //           modifyValue({
                //             ...extraModifyProps,
                //             valueKey,
                //             value: value,
                //             docId: this.props.docId,
                //           })
                //         }
                //         // title={_title}
                //       />
                //     </View>
                //   </TextModalButton>
                // )
                null}
          </View>
        );
      } else if (type === "formTitle") {
        const viewKey = `FormTitleView${keyIndices}`;
        const key = `FormTitle${keyIndices}`;
        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={[
              theme.rowContainer,
              theme.halfContainer,
              {
                marginTop: this.props.noTopMargin ? 0 : 16,
                paddingLeft: 8,
                paddingRight: 8,
                borderBottomColor: this.props.colors.accent,
                borderBottomWidth: 2,
              },
            ]}
          >
            <Text key={key} testID={key} style={theme.title}>
              {_title}
            </Text>
          </View>
        );
      } else if (
        type === "attachment" ||
        type === "multiAttachment" ||
        type === "pdfAttachment"
      ) {
        const viewKey = `FilePickerRowsView${keyIndices}`;
        const key = `FilePickerRows${keyIndices}`;

        const isPdfAttachment = type === "pdfAttachment";
        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <FilePickerRows
              key={key}
              testID={key}
              disabled={disabled}
              noDisabledStyle={noDisabledStyle}
              userRole={role}
              listKey={"ListKey" + key}
              title={_title}
              noBorder={true}
              onlineDoc={onlineDoc}
              navigation={navigation}
              navigate={this.props.navigate}
              goBack={this.props.goBack}
              values={_value}
              valueKey={valueKey}
              getMultiEditValueKeys={this.props.getMultiEditValueKeys}
              single={type === "attachment" || (isPdfAttachment && !item.multi)}
              pdfAttachment={isPdfAttachment}
              screenToGoBackTo={screenToGoBackTo}
              toggleDocSearchModal={() => {
                if (toggleDocSearchModal) {
                  toggleDocSearchModal(valueKey, _title, item.defaultSearch);
                }
              }}
              deletable={item.deletable}
              attachments={options.attachments}
              allowedTypes={item.allowedTypes}
              docId={this.props.docId}
              offlineAtch={onlineDoc ? false : item.offlineAtch}
              hidePreview={item.hidePreview}
              pageH={this.props.pageH}
              pageW={this.props.pageW}
              fullHeight={this.props.fullHeight}
              fullWidth={this.props.fullWidth}
              setTextModal={setTextModal}
              modifyObjectArrItem={modifyObjectArrItem}
              deleteFromObjectArr={deleteFromObjectArr}
              hideInGallery={item.hideInGallery}
              requiredProps={item.requiredProps}
              saveOnMount={item.saveOnMount}
              disableEditing={item.disableEditing}
              pdfOverride={item.pdfOverride}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              saveAttachmentsToPhotos={
                this.props.uiSettings?.saveAttachmentsToPhotos
              }
              profile={profile}
              customToken={this.props.customToken}
              ADD_ATTACHMENT={this.props.ADD_ATTACHMENT}
            />
          </View>
        );
      } else if (type === "extraRows") {
        const viewKey = `ExtraRowsCellView${keyIndices}`;
        const key = `ExtraRowsCell${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={
              !this.props.headerOnly || this.props.noTopMargin
                ? null
                : theme.primaryPaddingTop16
            }
          >
            <ExtraRowsCell
              childKey={key}
              {...this.props}
              title={_title}
              valueKey={valueKey}
              valueKeyPrefix={valueKeyPrefix}
              location={this.props.location}
              onlineDoc={onlineDoc}
              disabled={disabled}
              noDisabledStyle={noDisabledStyle}
              headerOnly={this.props.headerOnly}
              noHeader={this.props.noHeader}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              renderSpecificRow={this.props.renderSpecificRow}
              disableAdd={this.props.disableAdd}
              autoFocus={this.props.autoFocus}
            />
          </View>
        );
      } else if (type === "dividedInputs") {
        const viewKey = `DividedInputsView${keyIndices}`;
        const key = `DividedInputs${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <DividedRowInputs
              key={viewKey}
              childKey={key}
              {...this.props}
              title={_title}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              valueKeyPrefix={valueKeyPrefix}
            />
          </View>
        );
      } else if (type === "graph") {
        const viewKey = `ChartView${keyIndices}`;
        const key = `Chart${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <GraphCell
              childKey={key}
              type={type}
              item={item}
              disabled={disabled}
              noDisabledStyle={noDisabledStyle}
              valueKey={valueKey}
              title={_title}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              chartRefs={chartRefs}
              setChartRef={setChartRef}
              modifyValueItem={modifyValueItem}
              modifyObjectArrItem={modifyObjectArrItem}
              extraModifyProps={extraModifyProps}
              docId={docId}
              _value={_value}
              values={values}
              rangeStart={this.props.rangeStart}
              rangeEnd={this.props.rangeEnd}
            />
          </View>
        );
      }
      // else if (type === "measurementRow") {
      //   const viewKey = `MeasurementRowView${keyIndices}`;
      //   const key = `MeasurementRow${keyIndices}`;
      //   return (
      //     <View
      //       key={viewKey}
      //       testID={viewKey}
      //       style={{
      //         marginTop: this.props.noTopMargin ? 0 : 16,
      //       }}
      //     >
      //       <MeasurementRow
      //         key={key}
      //         title={_title}
      //         disabled={disabled}
      //         navigation={this.props.navigation}
      //         docId={this.props.docId}
      //         //isDualInput={dualInput ? true : false}
      //         measurementKey={valueKey}
      //         //measurementKey2={measurementKey2}
      //         unit={_unit}
      //         //unit2={unit2}
      //         maxOrMin={item.maxOrMin ?? false}
      //         onRemove={() => null}
      //         dataObjKey={valueKey}
      //         //secondaryDataObjKey={_secondaryId}
      //         onGroupRowPress={
      //           () => null
      //           // onGroupRowPress(connection.valueKey, i, {
      //           //   extraDataObj: cell.measurementSource,
      //           //   key: connection.valueKey,
      //           //   unit,
      //           //   unit2,
      //           //   measurementKey: measKey,
      //           //   measurementKey2: measKey2,
      //           // })
      //         }
      //         value={_value ? _value.worstValue : 0}
      //         values={_value ? _value.values : []}
      //         // value2={itemValue2?.worstValue}
      //         // values2={itemValue2?.values}
      //         dataPropName={""} //props.layoutId}
      //       />
      //     </View>
      //   );
      // }
      else if (type === "pickerObjects") {
        const viewKey = `PickerObjectsView${keyIndices}`;
        const key = `PickerObjects${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <PickerObjectsCell
              key={key}
              theme={theme}
              colors={colors}
              docId={this.props.docId}
              item={item}
              title={_title}
              hint={_hint}
              lang={lang}
              hintTable={item.hintTable}
              keyIndices={keyIndices}
              options={this.props.options}
              values={this.props.values}
              setTextModal={setTextModal}
              onlineDoc={onlineDoc}
              modifyObjectArrItem={modifyObjectArrItem}
              disabled={disabled}
              noDisabledStyle={noDisabledStyle}
              valueKey={valueKey}
              deleteFromObjectArr={deleteFromObjectArr}
              setInputModal={this.props.setInputModal}
              unlimitedWidth
            />
          </View>
        );
      } else if (type === "counter") {
        const viewKey = `CounterView${keyIndices}`;

        return (
          <View
            key={viewKey}
            testID={viewKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <CounterComponent
              title={_title}
              hint={_hint}
              hintTable={item.hintTable}
              leftIcon={item.icons?.left || "minus"}
              rightIcon={item.icons?.right || "plus"}
              leftIconColor={item.colors?.left || "text"}
              rightIconColor={item.colors?.right || "text"}
              leftIconBgColor={item.backgroundColors?.left || "lightAccent2"}
              rightIconBgColor={item.backgroundColors?.right || "success"}
              docId={docId}
              valueKey={valueKey}
              value={_value}
              unsigned
              onTap={(increase) =>
                modifyValue({
                  valueKey,
                  increase,
                  unsigned: true,
                  docId: this.props.docId,
                })
              }
              onChange={(value) =>
                modifyValue({
                  valueKey,
                  value: value,
                  docId: this.props.docId,
                })
              }
            />
          </View>
        );
      } else if (type === "iconButton") {
        return (
          <View
            key={valueKey}
            style={this.props.noTopMargin ? null : theme.marginTop16}
          >
            <CellIconButton {...this.props} cell={item} valueKey={valueKey} />
          </View>
        );
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.overrideValues) {
    return {
      profile: state.userInfo.profile,
      company: state.userInfo.company,
      docToModify: ownProps.docToModify,
      values: ownProps.overrideValues,
    };
  }
  const { docToModify } = getDocProps(
    ownProps.viewUnfinishedLayout,
    state,
    false,
    ownProps.docId,
    ownProps.layoutId
  );

  if (ownProps.tmpValues) {
    return {
      profile: state.userInfo.profile,
      gpsLocation: state.userInfo.gpsLocation,
      company: state.userInfo.company,
      docToModify,
      values: ownProps.tmpValues,
    };
  } else {
    return {
      profile: state.userInfo.profile,
      gpsLocation: state.userInfo.gpsLocation,
      company: state.userInfo.company,
      docToModify,
      values: docToModify?.values || {},
    };
  }
};

export default connect(mapStateToProps, {
  SET_COMPANY_SETTINGS_PROP,
  ADD_ATTACHMENT,
})(withTranslation(undefined, { withRef: true })(CellComponent));

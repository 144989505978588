import React from "react";
import { View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import Input from "./InputWithWarnings";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeEmail, setInputErr } from "../actions/ProfileActions";

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  focus = () => {
    this.input.current?.focus();
  };

  render() {
    let { theme } = this.context;
    return (
      <View style={theme.signInInnerContainer}>
        <Input
          ref={this.input}
          accessibilityLabel={this.props.t("emailField")}
          testID={this.props.t("emailField")}
          style={theme.signInScreenInput}
          value={this.props.email}
          label={this.props.t("email")}
          err={this.props.errors.email}
          textContentType={"emailAddress"}
          keyboardType={"email-address"}
          autoCapitalize={"none"}
          onChangeText={(text) => {
            this.props.changeEmail(text);
            this.props.setInputErr({ email: "" });
          }}
          onSubmitEditing={this.props.onSubmitEditing}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEmail,
      setInputErr,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  email: state.userInfo.profile.email,
  errors: state.userInfo.errors,
});

EmailInput.contextType = ThemeContext;

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput);

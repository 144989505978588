export const requestApiData = () => ({
    type: "REQUEST_API"
});

export const receiveUserData = (payload) => ({
    type: "RECEIVE_USER_DATA",
    payload
});

export const receiveEmailConfResponse = () => ({
    type: "RECEIVE_EMAIL_CONF_RESPONSE"
});

export const setFetchNotification = (msg) => ({
    type: "SET_FETCH_NOTIFICATION",
    msg
});

export const resetFetchNotification = () => ({
    type: "RESET_FETCH_NOTIFICATION",
});

export const resetEmailSent = () => ({
    type: "RESET_EMAIL_SENT"
});

export const receiveResponse = () => ({
    type: "RECEIVE_RESPONSE"
});
import React from "react";
import { TouchableOpacity, Text, View, Platform } from "react-native";
import ProgressBarElem from "./ProgressBar";
import Input from "./InputWithWarnings";
import { ThemeContext } from "../theming/theme-context";
import Icon from "../components/Icon";
import { wp } from "../lib/helperFns";
import { getPasswordScore } from "../lib/functions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setInputValues, setInputErr } from "../actions/ProfileActions";
import { withTranslation } from "react-i18next";

// type PasswordInputProps = {
//   label: String,
//   passStrengthBar: Boolean,
//   returnKeyType: String,
//   lessPadding: Boolean,
//   blurOnSubmit: Boolean,
// };

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textInputVisible: true,
      tintColor: "#ddd",
      hiddenPass: true,
    };
    this.input = React.createRef();
  }

  toggleSecureTextEntry = () => {
    if (Platform.OS === "ios") {
      // Temporarily remove TextInput from the DOM to force re-render on iOS
      this.setState({
        hiddenPass: !this.state.hiddenPass,
        textInputVisible: false,
      });
      setTimeout(() => {
        this.setState({
          textInputVisible: true,
        });
      }, 0); // Reinsert after 0 ms
    } else {
      this.setState({
        hiddenPass: !this.state.hiddenPass,
      });
    }
  };

  focus = () => {
    this.input.current?.focus();
  };

  getProgress = () => {
    const { score } = this.props.inputs.passScore;
    if (score == 0 && this.props.inputs.password != "") return 0.2;
    else if (score == 1) return 0.4;
    else if (score == 2) return 0.6;
    else if (score == 3) return 0.8;
    else if (score == 4) return 1;
    else return 0;
  };

  getProgressText = () => {
    const { score } = this.props.inputs.passScore;
    if (score == 0 && this.props.inputs.password !== "")
      return this.props.t("veryWeak");
    else if (score == 1) return this.props.t("weak");
    else if (score == 2) return this.props.t("passable");
    else if (score == 3) return this.props.t("great");
    else if (score == 4) return this.props.t("excellent");
    else return "";
  };

  getInfoText = (theme) => {
    if (this.props.inputs.passScore?.feedback) {
      const {
        feedback: { warning, suggestions },
      } = this.props.inputs.passScore;
      const warningString = this.props.t(warning);
      return [
        warningString ? (
          <Text key={"PassWarningString"} style={theme.label}>
            {warningString}
          </Text>
        ) : null,
        ...suggestions.map((x, i) => {
          const suggestion = this.props.t(x);
          if (suggestion) {
            return (
              <Text key={"PassSuggestion" + i} style={theme.label}>
                {suggestion}
              </Text>
            );
          }
        }),
      ];
    } else {
      return [
        <Text key={"PassSuggestion 1"} style={theme.label}>
          {this.props.t("useMultipleWords")}
        </Text>,
        <Text key={"PassSuggestion 2"} style={theme.label}>
          {this.props.t("noNeedForSpecialCharacters")}
        </Text>,
      ];
    }
  };

  render() {
    let { theme, colors } = this.context;

    return (
      <View style={this.props.style || theme.signInInnerContainer}>
        {this.state.textInputVisible ? (
          <Input
            ref={this.input}
            returnKeyType={this.props.returnKeyType || "next"}
            secureTextEntry={this.state.hiddenPass}
            passwordInput={true}
            autoCapitalize={"none"}
            style={theme.signInScreenInput}
            inputStyle={[theme.modalTextInput, { width: "90%" }]}
            selectTextOnFocus={true}
            value={this.props.inputs.password}
            label={this.props.label || `${this.props.t("password")} *`}
            err={this.props.errors.password}
            blurOnSubmit={this.props.blurOnSubmit ?? true}
            onChangeText={(text) => {
              this.props.setInputValues({
                password: text,
              });

              const setPassScore = async (_text) => {
                const passScore = await getPasswordScore(_text);
                this.props.setInputValues({
                  passScore,
                });
                this.props.setInputErr({ password: "" });
              };
              setPassScore(text);
            }}
            onSubmitEditing={this.props.onSubmitEditing}
            icon={
              <TouchableOpacity
                style={theme.showPassButton}
                onPress={this.toggleSecureTextEntry}
              >
                <Icon
                  name={this.state.hiddenPass ? "eye-off" : "eye"}
                  size={22}
                  color={colors.text}
                />
              </TouchableOpacity>
            }
          />
        ) : (
          <View style={{ height: 72 }} />
        )}

        {(this.props.passStrengthBar ?? true) &&
        this.props.inputs.password != "" ? (
          <View style={{ width: wp(85) }}>
            <ProgressBarElem
              colorRanges={[0.2, 0.4, 0.6, 0.8, 1]}
              colors={["#B00020", "orange", "blue", "green", "green"]}
              styleAttr={"Horizontal"}
              indeterminate={false}
              progress={this.getProgress()}
            />

            <Text style={theme.label}>{this.getProgressText()}</Text>
            <View style={[theme.fatLine, { marginTop: 5, marginBottom: 5 }]} />
            {this.getInfoText(theme)}
          </View>
        ) : null}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setInputValues,
      setInputErr,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  inputs: state.userInfo.inputs,
  errors: state.userInfo.errors,
});

PasswordInput.contextType = ThemeContext;

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation(undefined, { withRef: true })(PasswordInput));

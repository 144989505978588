import { useRef, useState, memo } from "react";
import { ScrollView, View, TouchableOpacity } from "react-native";
import { useTranslation } from "react-i18next";
import { Tooltip } from "./Tooltip";
import { svgElemTypes } from "../lib/constants";
import StretchButton from "./StretchButton";
import Icon from "./Icon";
import SimpleTextInputRow from "./SimpleTextInputRow";

const styles = {
  removeColorButton: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
  },
  fullSize: {
    height: "100%",
    width: "100%",
  },
  removeColorIcon: {
    position: "absolute",
    top: 17,
    left: -2,
    width: 40,
    height: 2,
    backgroundColor: "red",
    transform: [{ rotate: "-45deg" }],
  },
  colorSelectedIcon: { position: "absolute", top: "10%", left: "10%" },
};
const colorSwatches = [
  ["b71c1c", "d32f2f", "f44336", "e57373", "ffcdd2"],
  ["880e4f", "c2185b", "e91e63", "f06292", "f8bbd0"],
  ["4a148c", "7b1fa2", "9c27b0", "ba68c8", "e1bee7"],
  ["311b92", "512da8", "673ab7", "9575cd", "d1c4e9"],
  ["1a237e", "303f9f", "3f51b5", "7986cb", "c5cae9"],
  ["0d47a1", "1976d2", "2196f3", "64b5f6", "bbdefb"],
  ["01579b", "0288d1", "03a9f4", "4fc3f7", "b3e5fc"],
  ["006064", "0097a7", "00bcd4", "4dd0e1", "b2ebf2"],
  ["004d40", "00796b", "009688", "4db6ac", "b2dfdb"],
  ["194d33", "388e3c", "4caf50", "81c784", "c8e6c9"],
  ["33691e", "689f38", "8bc34a", "aed581", "dcedc8"],
  ["827717", "afb42b", "cddc39", "dce775", "f0f4c3"],
  ["f57f17", "fbc02d", "ffeb3b", "fff176", "fff9c4"],
  ["ff6f00", "ffa000", "ffc107", "ffd54f", "ffecb3"],
  ["e65100", "f57c00", "ff9800", "ffb74d", "ffe0b2"],
  ["bf360c", "e64a19", "ff5722", "ff8a65", "ffccbc"],
  ["3e2723", "5d4037", "795548", "a1887f", "d7ccc8"],
  ["263238", "455a64", "607d8b", "90a4ae", "cfd8dc"],
  ["000000", "525252", "969696", /*"d9d9d9",*/ "ffffff", null],
];
const WhiteboardButtons = memo(
  ({
    OS,
    pageH,
    pageW,
    theme,
    colors,
    orientation,
    tappedSvgElem,
    lockZoom,
    strokeColor,
    strokeWidth,
    elementsDisabled,

    clear,
    rewind,
    setSvgElemCoords,
    removeTappedElem,
    setAlert,
    toggleLockZoom,
    setTextModal,
    SET_USER_PROP,
    openPreviewModalWithAtchId,

    // svgElemOptionsProps
    addSvgElem,
    addingSvgElem,
    disableSvgElemAdd,
  }) => {
    const { t } = useTranslation();
    const [controlsOpen, setControlsOpen] = useState(false);
    const [tmpStrokeWidth, setTmpStrokeWidth] = useState(strokeWidth);
    const strokeInputRef = useRef(null);
    // const [currentOrientation, setOrientation] = useState("PORTRAIT");

    // useEffect(() => {
    //   getDeviceOrientation(setOrientation);

    //   addOrientationListener(setOrientation);
    //   return () => {
    //     removeOrientationListener(setOrientation);
    //   };
    // }, []);

    // renderStrokeInput = (theme, colors, orientation) => {
    //   return (
    //     <View
    //       style={{
    //         height: 60,
    //         width: 80,
    //         margin: 8,
    //         flexDirection: "row",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <TextInputRow
    //         //hint={_hint}
    //         disabled={false}
    //         noDisabledStyle={true}
    //         value={props.strokeWidth.toString()}
    //         onChangeText={(value) => {
    //           props.SET_USER_PROP({
    //             prop: "strokeWidth",
    //             value: parseFloat(value),
    //           });
    //         }}
    //         numeric={true}
    //         title={t("width")}
    //         maxDecimals={1}
    //       />
    //     </View>
    //   );
    // };
    const renderRemoveColorButton = (setElemColor) => {
      return (
        <TouchableOpacity
          onPress={() => {
            if (setElemColor) {
              setSvgElemCoords(
                { [setElemColor]: null },
                tappedSvgElem.index,
                true
              );
            } else {
              SET_USER_PROP({ prop: "strokeColor", value: null });
            }
          }}
          style={styles.removeColorButton}
        >
          <View style={styles.removeColorIcon} />
        </TouchableOpacity>
      );
    };
    const renderColorButton = (color, setElemColor) => {
      return (
        <TouchableOpacity
          onPress={() => {
            if (setElemColor) {
              setSvgElemCoords(
                { [setElemColor]: color },
                tappedSvgElem.index,
                true
              );
            } else {
              SET_USER_PROP({ prop: "strokeColor", value: color });
            }
          }}
          style={[styles.fullSize, { backgroundColor: color }]}
        />
      );
    };
    const renderColorPicker = (color, colors, setElemColor, currentColor) => {
      const isSelected = currentColor === color;
      return (
        <View
          key={"ColorPicker" + color}
          style={{
            width: 40,
            height: 40,
            borderWidth: isSelected ? 4 : 2,
            borderColor: isSelected ? colors.accent : colors.text,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {color
            ? renderColorButton(color, setElemColor)
            : renderRemoveColorButton(setElemColor)}
          {isSelected ? (
            <View style={styles.colorSelectedIcon}>
              <Icon name={"check"} size={24} color={colors.text} />
            </View>
          ) : null}
        </View>
      );
    };
    const renderColorPickers = (
      colors,
      orientation,
      setElemColor,
      currentColor
    ) => {
      return (
        <View
          style={{
            flexDirection: orientation === 1 ? "row" : "column",
            justifyContent: "space-around",
          }}
        >
          {colorSwatches.map((row, rowIndex) => (
            <View
              key={"ColorPickerRow" + rowIndex}
              style={{ flexDirection: orientation === 1 ? "column" : "row" }}
            >
              {row.map((x) =>
                renderColorPicker(
                  x ? "#" + x : null,
                  colors,
                  setElemColor,
                  currentColor
                )
              )}
            </View>
          ))}
        </View>
      );
    };

    return (
      <>
        <View
          style={[
            {
              position: "absolute",
              bottom: 0,
              zIndex: 1000,
              backgroundColor: colors.primary,
            },
            orientation === 1
              ? {
                  left: 0,
                  borderTopColor: colors.accent,
                  borderTopWidth: 2,
                  flexDirection: "column",
                }
              : {
                  right: 0,
                  borderLeftColor: colors.accent,
                  borderLeftWidth: 2,
                  flexDirection: "row",
                },
          ]}
        >
          <View
            style={[
              {
                flexDirection: orientation === 1 ? "row" : "column-reverse",
                justifyContent: "space-between",
                alignItems: "center",
              },
              orientation === 1 ? { width: pageW } : { height: pageH },
            ]}
          >
            <Tooltip
              tip={
                tappedSvgElem.isTappeds
                  ? t("deleteSvgElemHint")
                  : t("deleteSvgStrokesHint")
              }
              style={theme.flex}
            >
              <StretchButton
                icon={tappedSvgElem.isTapped ? "delete-variant" : "trash-can"}
                onPress={() => {
                  if (tappedSvgElem.isTapped) {
                    removeTappedElem();
                  } else {
                    setAlert({
                      visible: true,
                      title: t("alert"),
                      text: t("svgDrawDeletionAlert"),
                      leftButtonTitle: t("cancel"),
                      rightButtonTitle: t("ok"),
                      cancelButton: true,
                      onRightButtonPress: clear,
                    });
                  }
                }}
              />
            </Tooltip>
            <Tooltip
              // TODO add undo for svg element actions
              tip={t("undoSvgStrokeHint")}
              style={theme.flex}
            >
              <StretchButton icon="undo" onPress={rewind} />
            </Tooltip>
            <Tooltip tip={t("whiteboardZoomHint")} style={theme.flex}>
              <StretchButton
                icon={lockZoom ? "gesture-pinch" : "lock"}
                onPress={toggleLockZoom}
              />
            </Tooltip>
            {tappedSvgElem.type === "5" ? (
              <Tooltip tip={t("editSvgElemTextHint")} style={theme.flex}>
                <StretchButton
                  icon="format-text"
                  onPress={() => {
                    setTextModal({
                      visible: true,
                      title: t("textToAdd"),
                      placeholder: "",
                      value: "",
                      onSave: (val) =>
                        setSvgElemCoords(
                          { text: val.trim() },
                          tappedSvgElem.index,
                          true
                        ),
                      closeOnRighButtonPress: true,
                    });
                  }}
                />
              </Tooltip>
            ) : elementsDisabled ? null : (
              <Tooltip tip={t("svgElemChoicesHint")} style={theme.flex}>
                <StretchButton
                  icon={
                    controlsOpen === "elemChoices"
                      ? orientation === 1
                        ? "chevron-down"
                        : "chevron-right"
                      : "shape-plus"
                  }
                  onPress={() => {
                    setControlsOpen((_controlsOpen) =>
                      _controlsOpen === "elemChoices" ? null : "elemChoices"
                    );
                  }}
                />
              </Tooltip>
            )}

            <Tooltip tip={t("settings")} style={theme.flex}>
              <StretchButton
                icon={
                  controlsOpen === "controls"
                    ? orientation === 1
                      ? "chevron-down"
                      : "chevron-right"
                    : "cog"
                }
                onPress={() => {
                  setControlsOpen((_controlsOpen) =>
                    _controlsOpen === "controls" ? null : "controls"
                  );
                }}
              />
            </Tooltip>
          </View>
        </View>
        <View
          style={[
            {
              position: "absolute",
              backgroundColor: controlsOpen ? colors.primary : null,
            },
            orientation === 1
              ? {
                  width: OS !== "web" ? "100%" : pageW,
                  borderTopColor: colors.accent,
                  borderTopWidth: controlsOpen ? 2 : 0,
                  bottom: 38,
                  left: 0,
                }
              : {
                  height: OS !== "web" ? "100%" : pageH,
                  borderLeftColor: colors.accent,
                  borderLeftWidth: controlsOpen ? 2 : 0,
                  bottom: 0,
                  right: 38,
                },
          ]}
        >
          {controlsOpen ? (
            controlsOpen === "controls" ? (
              <View
                style={[
                  orientation === 1
                    ? {
                        flexDirection: "column",
                        width: OS !== "web" ? "100%" : pageW,
                        paddingRight: 0,
                      }
                    : { flexDirection: "row" },
                ]}
              >
                <View
                  style={[
                    {
                      flexDirection: orientation === 1 ? "column" : "row",
                      zIndex: 1000,
                    },
                    orientation === 1
                      ? { width: OS !== "web" ? "100%" : pageW }
                      : {},
                  ]}
                >
                  {tappedSvgElem.isTapped ? (
                    <ScrollView horizontal={orientation === 1}>
                      {renderColorPickers(colors, orientation, "stroke")}
                      {tappedSvgElem.type !== "5" &&
                      tappedSvgElem.type !== "6" &&
                      tappedSvgElem.type !== "7" ? (
                        <View
                          style={
                            orientation === 1
                              ? { marginLeft: 16 }
                              : { marginBottom: 16 }
                          }
                        >
                          {renderColorPickers(colors, orientation, "fill")}
                        </View>
                      ) : null}
                      {tappedSvgElem.type === "100" ||
                      tappedSvgElem.type === "101" ? (
                        <StretchButton
                          icon="image"
                          onPress={() => {
                            openPreviewModalWithAtchId(
                              tappedSvgElem.attachmentIds[0]
                            );
                          }}
                        />
                      ) : null}
                    </ScrollView>
                  ) : (
                    <ScrollView horizontal={orientation === 1}>
                      {renderColorPickers(
                        colors,
                        orientation,
                        null,
                        strokeColor
                      )}
                    </ScrollView>
                  )}
                </View>
                {tappedSvgElem.isTapped ? null : (
                  // t("width") +
                  //         ": " +
                  //         roundToTwo(Math.ceil(strokeWidth * 5 * 10) / 10)
                  <SimpleTextInputRow
                    numeric
                    value={tmpStrokeWidth}
                    onChangeText={(text) => {
                      setTmpStrokeWidth(Number(text));
                    }}
                    onBlur={() => {
                      if (tmpStrokeWidth < 4)
                        SET_USER_PROP({
                          prop: "strokeWidth",
                          value: Number(tmpStrokeWidth),
                        });
                      else {
                        SET_USER_PROP({
                          prop: "strokeWidth",
                          value: Number(tmpStrokeWidth),
                        });
                        setTmpStrokeWidth(Number(4));
                      }
                    }}
                    title={t("width")}
                    endIcon={"check"}
                    setCellRef={(ref) => (strokeInputRef.current = ref)}
                    onEndIconPress={() => {
                      strokeInputRef.current?.blur();
                    }}
                  />
                )}
              </View>
            ) : controlsOpen === "elemChoices" ? (
              <View
                style={[
                  {
                    flexDirection: orientation === 1 ? "row" : "column",
                  },
                  orientation === 1
                    ? { width: OS !== "web" ? "100%" : pageW, paddingRight: 0 }
                    : { paddingBottom: 0 },
                ]}
              >
                <View
                  style={[
                    {
                      flexDirection: orientation === 1 ? "column" : "row",
                      zIndex: 1000,
                    },
                    orientation === 1
                      ? { width: OS !== "web" ? "100%" : pageW }
                      : {},
                  ]}
                >
                  <ScrollView horizontal={orientation === 1}>
                    {Object.entries(svgElemTypes).map((x, i) => (
                      <TouchableOpacity
                        key={"SvgCreationButton" + i}
                        style={{
                          width: 40,
                          height: 40,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            addingSvgElem?.type === x[0]
                              ? "green"
                              : colors.darkPrimary,
                          borderWidth: 2,
                          borderColor: colors.accent,
                          margin: 8,
                        }}
                        onPress={() => {
                          if (addingSvgElem?.type === x[0]) {
                            disableSvgElemAdd();
                          } else if (x[0] === "5") {
                            setTextModal({
                              visible: true,
                              title: t("textToAdd"),
                              placeholder: "",
                              value: "",
                              onSave: (val) =>
                                addSvgElem("5", {
                                  text: val.trim(),
                                }),
                              closeOnRighButtonPress: true,
                            });
                          } else {
                            addSvgElem(x[0]);
                          }
                        }}
                      >
                        <Icon name={x[1].icon} size={24} color={colors.text} />
                      </TouchableOpacity>
                    ))}
                  </ScrollView>
                </View>
              </View>
            ) : null
          ) : null}
        </View>
      </>
    );
  }
);
export default WhiteboardButtons;

import React from "react";
import { TouchableOpacity, View, Platform } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import Icon from "../components/Icon";
import Input from "./InputWithWarnings";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setInputValues, setInputErr } from "../actions/ProfileActions";
import { withTranslation } from "react-i18next";

class PassConfInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textInputVisible: true, hiddenPass: true };
    this.input = React.createRef();
  }

  toggleSecureTextEntry = () => {
    if (Platform.OS === "ios") {
      // Temporarily remove TextInput from the DOM to force re-render on iOS
      this.setState({
        hiddenPass: !this.state.hiddenPass,
        textInputVisible: false,
      });
      setTimeout(() => {
        this.setState({
          textInputVisible: true,
        });
      }, 0); // Reinsert after 0 ms
    } else {
      this.setState({
        hiddenPass: !this.state.hiddenPass,
      });
    }
  };

  focus = () => {
    this.input.current?.focus();
  };

  render() {
    let { theme, colors } = this.context;
    return (
      <View style={this.props.style || theme.signInInnerContainer}>
        {this.state.textInputVisible ? (
          <Input
            ref={this.input}
            returnKeyType={this.props.returnKeyType || "next"}
            secureTextEntry={this.state.hiddenPass}
            passwordInput={true}
            autoCapitalize={"none"}
            style={theme.signInScreenInput}
            inputStyle={[theme.modalTextInput, { width: "90%" }]}
            selectTextOnFocus={true}
            value={this.props.inputs.passwordConf}
            label={
              this.props.errors.passwordConf
                ? this.props.errors.passwordConf
                : this.props.label || `${this.props.t("passwordConf")} *`
            }
            err={this.props.errors.password}
            blurOnSubmit={this.props.blurOnSubmit ?? true}
            onChangeText={(text) => {
              this.props.setInputValues({ passwordConf: text });
              this.props.setInputErr({ passwordConf: "" });
            }}
            onSubmitEditing={this.props.onSubmitEditing}
            icon={
              <TouchableOpacity
                style={theme.showPassButton}
                onPress={this.toggleSecureTextEntry}
              >
                <Icon
                  name={this.state.hiddenPass ? "eye-off" : "eye"}
                  size={24}
                  color={colors.text}
                />
              </TouchableOpacity>
            }
          />
        ) : (
          <View style={{ height: 72 }} />
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setInputValues,
      setInputErr,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  inputs: state.userInfo.inputs,
  errors: state.userInfo.errors,
});

PassConfInput.contextType = ThemeContext;

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(PassConfInput));

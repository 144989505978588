import { useEffect, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import TypeSpecificPreview from "./TypeSpecificPreview";
import { ThemeContext } from "../theming/theme-context";
import Modal from "./Modal";
import { checkAtchDownloadNeed } from "../lib/functions";
import { modalPickerOpts } from "../lib/helperFns";
import { dirs } from "../lib/fileOperations";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ADD_ATTACHMENT } from "../actions/OptionsActions";

function PreviewModal(props) {
  const { t } = useTranslation();
  const optionsAttachment = props.attachments?.[props.attachment?.id];
  const atch = optionsAttachment
    ? { ...props.attachment, ...optionsAttachment }
    : props.attachment;
  const [atchLoaded, setAtchLoaded] = useState();

  useEffect(() => {
    if (props.attachment) {
      checkAtchDownloadNeed(
        atch,
        setAtchLoaded,
        props.ADD_ATTACHMENT,
        props.docId,
        props.ogImage,
        undefined,
        undefined,
        props.customToken,
        true
      );
    }
  }, [props.attachment]);

  useEffect(() => {
    if (!props.visible) {
      setAtchLoaded(false);
    }
  }, [props.visible]);

  const _onAttachmentEdit = () => {
    props.onAttachmentEdit(atch.id);
  };
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          animationIn="fadeIn"
          animationOut="fadeOut"
          onBackButtonPress={props.closeModal}
          onBackdropPress={props.closeModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          onClose={() => setAtchLoaded()}
        >
          <View style={theme.container}>
            <TypeSpecificPreview
              ogImage={props.ogImage ? atch?.uri : null}
              pageH={props.pageH}
              pageW={props.pageW}
              atch={atch}
              fetchedAttachments={atchLoaded?.value}
              dirs={dirs}
              t={t}
              theme={theme}
              colors={colors}
              fullscreenAtch={{
                id: props.ogImage ? atch?.uri : atch?.id,
                fetching: !atchLoaded,
              }}
              noFullScreenButton={true}
              setAttachmentsFetched={setAtchLoaded}
              deleteButton={true}
              deleteIconName={"close"}
              onDelete={props.closeModal}
              onRotate={props.onRotate}
              rotateButton={true}
              rotation={props.rotation}
              onAttachmentEdit={
                props.onAttachmentEdit ? _onAttachmentEdit : null
              }
              ADD_ATTACHMENT={props.ADD_ATTACHMENT}
              disabled={props.disabled}
              customToken={props.customToken}
              permissions={props.permissions}
            />
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

const mapStateToProps = (state) => {
  return {
    attachments: state.options.attachments,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ADD_ATTACHMENT,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);

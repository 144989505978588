import update from "immutability-helper";
import { errorReport } from "../lib/functions";
import { dictPathsUpdateObj } from "./DocsReducer";

export const targetPropArrs = ["measuringDevices", "standards"];

const INITIAL_STATE = {
  folders: {},
  folderPath: {
    pathIds: [],
  },
};

export default function folderReducer(state = INITIAL_STATE, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return INITIAL_STATE;
    } else if (action.type === "RECEIVE_USER_DATA") {
      const { cleanSignIn } = action.payload;
      if (cleanSignIn) return INITIAL_STATE;
      else return state;
    } else if (action.type === "MERGE_FOLDERS") {
      return update(state, { folders: { $merge: action.payload } });
    } else if (action.type === "PUSH_TO_FOLDER_PATH") {
      const { id } = action.payload;
      return update(state, {
        folderPath: (tmp) =>
          update(tmp || {}, {
            pathIds: (tmpPathIds) => update(tmpPathIds || [], { $push: [id] }),
          }),
      });
    } else if (action.type === "SET_FOLDER_PATH") {
      const { pathIds } = action.payload;
      return update(state, {
        folderPath: (tmp) =>
          update(tmp || {}, {
            pathIds: { $set: pathIds },
          }),
      });
    } else if (action.type === "MOVE_TO_FOLDER") {
      const { id } = action.payload;
      return update(state, {
        folderPath: (tmp) =>
          update(tmp || {}, {
            pathIds: (tmpPathIds) =>
              update(tmpPathIds || [], {
                $apply: (x) => {
                  const index = x.findIndex((y) => y === id);
                  if (index !== -1) {
                    return update(x, {
                      $splice: [[index + 1, x.length - (index + 1)]],
                    });
                  } else {
                    return x;
                  }
                },
              }),
          }),
      });
    } else if (action.type === "RESET_FOLDER_PATH") {
      return update(state, {
        folderPath: (tmp) =>
          update(tmp || {}, {
            pathIds: { $set: [] },
          }),
      });
    } else if (action.type === "GO_BACK_A_FOLDER") {
      return update(state, {
        folderPath: (tmp) =>
          update(tmp || {}, {
            pathIds: (tmpPathIds) =>
              update(tmpPathIds || [], { $apply: (x) => x.slice(0, -1) }),
          }),
      });
    } else if (action.type === "DELETE_FOLDERS") {
      return update(state, { folders: { $unset: action.payload } });
    } else if (action.type === "SET_FOLDERS_PROPS") {
      return update(state, {
        folders: (tmpFolders) =>
          update(
            tmpFolders || {},
            action.payload.reduce((prev, cur) => {
              prev[cur.id] = (tmp) =>
                update(tmp || {}, { [cur.prop]: { $set: cur.value } });
              return prev;
            }, {})
          ),
      });
    } else if (action.type === "UPDATE_PATHS") {
      return update(state, {
        folders: dictPathsUpdateObj(action.payload),
      });
    }

    return state;
  } catch (error) {
    const errorStr = `Error: ${error}, Reducer: FolderReducer, Action: ${
      action.type
    }, Payload: ${
      action.payload ? JSON.stringify(action.payload) : "no payload"
    }`;
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "FolderReducer",
        errorParams: {
          type: action?.type,
          payload: errorStr,
        },
      });
    } else {
      console.warn(errorStr);
    }
    return state;
  }
}

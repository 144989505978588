import React from "react";
import { View, TouchableOpacity } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import Icon from "./Icon";
import TextInput from "./TextInput";

const SearchBarElem = React.forwardRef((props, ref) => {
  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            height: 36,
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              height: "100%",
            }}
          >
            <View
              style={{
                width: 36,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon name="magnify" color={colors.text} size={24} />
            </View>
            <View style={{ height: "100%", flex: 1 }}>
              <TextInput
                ref={ref}
                orientation={orientation}
                disableFullscreenUI={false}
                editable={!props.disabled}
                style={[
                  theme.text,
                  {
                    height: "100%",
                    paddingLeft: 8,
                    paddingRight: 8,
                    outline: "none",
                  },
                ]}
                autoFocus={props.autofocus}
                placeholder={props.placeholder}
                placeholderTextColor={colors.disabledText}
                value={props.value}
                onChangeText={props.onChangeText}
                keyboardType={
                  props.keyboardType ??
                  (props.numeric ? "decimal-pad" : "default")
                }
                selectTextOnFocus
                onSubmitEditing={props.onSubmitEditing}
              />
            </View>
          </View>
          {props.value ? (
            <TouchableOpacity
              style={{
                width: 36,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={props.onClear}
            >
              <Icon name={"close"} size={24} color={colors.text} />
            </TouchableOpacity>
          ) : null}
        </View>
      )}
    </ThemeContext.Consumer>
  );
});

export default SearchBarElem;

import "react";
import {
  StyleSheet,
  ActivityIndicator,
  Image,
  TouchableOpacity,
  Text,
  View,
} from "react-native";
import Icon from "../components/Icon";
import IconButton from "./IconButton";

const styles = StyleSheet.create({
  textWrapper: { flex: 1, overflow: "hidden" },
  rightIcon: { marginLeft: "auto" },
  leftIcon: { height: 20, width: 20 },
});
export default function ListPickerItem({
  theme,
  colors,
  titleStyle,
  itemIndex,
  title,
  subtitle,
  item,
  itemSpinner,
  startLogo,
  icon,
  setValue,
  editing,
  isSelected,
  onSelect,
}) {
  return (
    <TouchableOpacity
      onPress={() => (editing ? onSelect(item) : setValue(item))}
    >
      <View
        style={
          itemIndex % 2 === 0
            ? theme.layoutPickerItem
            : theme.alternateLayoutPickerItem
        }
      >
        {editing ? (
          <IconButton
            icon={isSelected ? "select-inverse" : "select"}
            onPress={() => onSelect(item)}
          />
        ) : null}

        {startLogo ? (
          <Image
            source={startLogo}
            resizeMode={"contain"}
            style={styles.leftIcon}
          />
        ) : null}

        <View style={styles.textWrapper}>
          <Text style={titleStyle ?? theme.text}>{title}</Text>
          {subtitle ? <Text style={theme.caption}>{subtitle}</Text> : null}
        </View>

        <View style={styles.rightIcon}>
          {itemSpinner === item ? (
            <ActivityIndicator color={colors.accent} />
          ) : (
            <Icon
              name={icon ?? "plus"}
              size={icon ? 26 : 30}
              color={colors.text}
              style={theme.zeroPaddingAndMargin}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}

export const licenses = `@floating-ui/react-dom,MIT,atomiks,https://floating-ui.com/docs/react-dom,git+https://github.com/floating-ui/floating-ui.git
@microsoft/signalr,Apache-2.0,Microsoft,https://github.com/dotnet/aspnetcore/tree/master/src/SignalR#readme,git+https://github.com/dotnet/aspnetcore.git
@yaireo/tagify,MIT,Yair Even-Or <vsync.design@gmail.com>,https://github.com/yairEO/tagify,git+https://github.com/yairEO/tagify.git
axios,MIT,Matt Zabriskie,https://github.com/axios/axios,git+https://github.com/axios/axios.git
browser-image-compression,MIT,Donald <donaldcwl@gmail.com>,https://github.com/Donaldcwl/browser-image-compression#readme,git+https://github.com/Donaldcwl/browser-image-compression.git
canvg,MIT,Gabe Lerner,n/a,git+https://github.com/canvg/canvg.git
file-saver,MIT,Eli Grey <me@eligrey.com>,https://github.com/eligrey/FileSaver.js#readme,git+https://github.com/eligrey/FileSaver.js.git
flexlayout-react,ISC,Caplin Systems Ltd,n/a,git+https://github.com/caplin/FlexLayout.git
hash.js,MIT,Fedor Indutny <fedor@indutny.com>,https://github.com/indutny/hash.js,git+ssh://git@github.com/indutny/hash.js.git
html2canvas,MIT,Niklas von Hertzen niklasvh@gmail.com https://hertzen.com,https://html2canvas.hertzen.com,git+ssh://git@github.com/niklasvh/html2canvas.git
hyphen,ISC,Eugene Tiurin <yevhentiurin@gmail.com> (https://github.com/ytiurin),https://ytiurin.github.io/hyphen,git+https://github.com/ytiurin/hyphen.git
i18next,MIT,Jan Mühlemann <jan.muehlemann@gmail.com> (https://github.com/jamuhl),https://www.i18next.com,git+https://github.com/i18next/i18next.git
i18next-http-backend,MIT,n/a,https://github.com/i18next/i18next-http-backend,git+ssh://git@github.com/i18next/i18next-http-backend.git
immutability-helper,MIT,Moshe Kolodny,https://github.com/kolodny/immutability-helper#readme,git+https://github.com/kolodny/immutability-helper.git
jszip,(MIT OR GPL-3.0-or-later),Stuart Knightley <stuart@stuartk.com>,n/a,git+https://github.com/Stuk/jszip.git
localforage,Apache-2.0,Mozilla,https://github.com/localForage/localForage,git://github.com/localForage/localForage.git
mathjs,Apache-2.0,Jos de Jong <wjosdejong@gmail.com> (https://github.com/josdejong),https://mathjs.org,git+https://github.com/josdejong/mathjs.git
merge-images,MIT,Luke Childs <lukechilds123@gmail.com> (http://lukechilds.co.uk),https://github.com/lukechilds/merge-images,git+https://github.com/lukechilds/merge-images.git
mime,MIT,Robert Kieffer robert@broofa.com http://github.com/broofa,n/a,git+https://github.com/broofa/mime.git
moment,MIT,Iskren Ivov Chernev <iskren.chernev@gmail.com> (https://github.com/ichernev),https://momentjs.com,git+https://github.com/moment/moment.git
pdf-lib,MIT,Andrew Dillon <andrew.dillon.j@gmail.com>,https://pdf-lib.js.org,git+https://github.com/Hopding/pdf-lib.git
query-string,MIT,Sindre Sorhus sindresorhus@gmail.com https://sindresorhus.com,n/a,git+https://github.com/sindresorhus/query-string.git
react,MIT,n/a,https://reactjs.org/,git+https://github.com/facebook/react.git
react-color,MIT,case <case@casesandberg.com>,http://casesandberg.github.io/react-color/,git+https://github.com/casesandberg/react-color.git
react-dnd,MIT,n/a,n/a,https://github.com/react-dnd/react-dnd.git
react-dnd-html5-backend,MIT,n/a,n/a,https://github.com/react-dnd/react-dnd.git
react-dom,MIT,n/a,https://reactjs.org/,git+https://github.com/facebook/react.git
react-draggable,MIT,Matt Zabriskie,https://github.com/react-grid-layout/react-draggable,https://github.com/react-grid-layout/react-draggable.git
react-dropzone,MIT,Param Aggarwal,https://github.com/react-dropzone/react-dropzone,git+https://github.com/react-dropzone/react-dropzone.git
react-helmet,MIT,NFL <engineers@nfl.com>,n/a,git+https://github.com/nfl/react-helmet.git
react-hot-toast,MIT,Timo Lins,n/a,git+https://github.com/timolins/react-hot-toast.git
react-i18next,MIT,Jan Mühlemann <jan.muehlemann@gmail.com> (https://github.com/jamuhl),https://github.com/i18next/react-i18next,git+https://github.com/i18next/react-i18next.git
react-image-file-resizer,MIT,Onur Zorluer zorlueronur@gmail.com https://onurzorluer.com,https://github.com/onurzorluer/react-image-file-resizer#readme,git+https://github.com/onurzorluer/react-image-file-resizer.git
react-native-svg,MIT,n/a,https://github.com/react-native-community/react-native-svg,git+https://github.com/react-native-community/react-native-svg.git
react-native-vector-icons,MIT,Joel Arvidsson joel@oblador.se,https://github.com/oblador/react-native-vector-icons,git://github.com/oblador/react-native-vector-icons.git
react-native-web,MIT,Nicolas Gallagher,n/a,git://github.com/necolas/react-native-web.git
react-pdf,MIT,Wojciech Maj kontakt@wojtekmaj.pl,n/a,git+https://github.com/wojtekmaj/react-pdf.git
react-redux,MIT,Dan Abramov <dan.abramov@me.com> (https://github.com/gaearon),https://github.com/reduxjs/react-redux,git+https://github.com/reduxjs/react-redux.git
react-resizable,MIT,Samuel Reed <samuel.trace.reed@gmail.com> (http://strml.net/),https://github.com/react-grid-layout/react-resizable,git@github.com:react-grid-layout/react-resizable.git
react-router-dom,MIT,Remix Software <hello@remix.run>,https://reactrouter.com/,git+https://github.com/remix-run/react-router.git
react-selecto,MIT,Daybrush,https://daybrush.com/selecto,https://github.com/daybrush/selecto/blob/master/packages/react-selecto
react-zoom-pan-pinch,MIT,prc5,n/a,https://github.com/prc5/react-zoom-pan-pinch.git
redux,MIT,n/a,http://redux.js.org,git+https://github.com/reduxjs/redux.git
redux-persist,MIT,,https://github.com/rt2zz/redux-persist#readme,git+https://github.com/rt2zz/redux-persist.git
redux-thunk,MIT,Dan Abramov <dan.abramov@me.com>,https://github.com/reduxjs/redux-thunk,git+https://github.com/reduxjs/redux-thunk.git
route-parser,MIT,Ryan Sorensen rcsorensen@gmail.com https://github.com/rcs,http://github.com/rcs/route-parser,git+https://github.com/rcs/route-parser.git
swiper,MIT,Vladimir Kharlampidi,https://swiperjs.com,git+https://github.com/nolimits4web/Swiper.git
victory,MIT,Formidable,https://formidable.com/open-source/victory,git+https://github.com/formidablelabs/victory.git
zxcvbn,MIT,Dan Wheeler,n/a,git+https://github.com/dropbox/zxcvbn.git`;

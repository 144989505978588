import "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PublicHeaderBar from "../components/PublicHeaderBar";
import { fullWp } from "../../../common/lib/helperFns";
import InfoFooter from "../components/InfoFooter";
import Text from "../../../common/components/GDPR-text";

function GDPR() {
  const { t } = useTranslation();
  return (
    <div className="company-registration-container">
      <Helmet>
        <meta name="description" content={t("privacyStatement")} />
        <title>{t("privacyStatement")} - Fondion Docs</title>
      </Helmet>
      <PublicHeaderBar />
      <div className="flex-center">
        <Text lightTheme wrapperStyle={{ maxWidth: fullWp(90), padding: 32 }} />
      </div>
      <InfoFooter />
    </div>
  );
}

export default GDPR;

import { memo, useState } from "react";
import { View, Text } from "react-native";
import StretchButton from "./StretchButton";

const WhiteboardHeader = memo(
  ({
    pageH,
    pageW,
    theme,
    colors,
    orientation,
    tappedSvgElem,
    goBack,
    setTappedElem,
    handleSave,
    pageCount,
    currentPage,
    setPage,
    title,
  }) => {
    const [titleDimensions, setTitleDimensions] = useState();
    const prevPageDisabled = currentPage === 1;
    const nextPageDisabled = currentPage === pageCount;
    return (
      <View
        style={[
          {
            zIndex: 1000,
            backgroundColor: colors.primary,
          },
          orientation === 1
            ? {
                borderBottomColor: colors.accent,
                borderBottomWidth: 2,
                flexDirection: "column",
              }
            : {
                borderRightColor: colors.accent,
                borderRightWidth: 2,
                flexDirection: "row",
              },
        ]}
      >
        <View
          style={[
            {
              flexDirection: orientation === 1 ? "row" : "column-reverse",
              justifyContent: "space-between",
              alignItems: "center",
            },
            orientation === 1 ? { width: pageW } : { height: pageH },
          ]}
        >
          <StretchButton
            backgroundColor={colors.secondary}
            color={colors.text}
            icon={
              tappedSvgElem.isTapped ? "subdirectory-arrow-left" : "arrow-left"
            }
            onPress={
              tappedSvgElem.isTapped
                ? () => setTappedElem({ isTapped: false })
                : goBack
            }
          />

          {title && orientation === 1 ? (
            <View
              style={[
                {
                  padding: 8,
                  backgroundColor: colors.primary,
                  borderRadius: 4,
                },
              ]}
            >
              <Text style={theme.title}>{title}</Text>
            </View>
          ) : null}

          {pageCount ? (
            <>
              <StretchButton
                icon="chevron-left"
                onPress={() => {
                  setPage(currentPage - 1);
                }}
                disabled={prevPageDisabled}
              />
              <Text
                style={[theme.title, theme.textPaddingContainer]}
              >{`${currentPage} / ${pageCount}`}</Text>
              <StretchButton
                icon="chevron-right"
                onPress={() => {
                  setPage(currentPage + 1);
                }}
                disabled={nextPageDisabled}
              />
            </>
          ) : null}

          <StretchButton icon="content-save" onPress={handleSave} />
        </View>
        {title && orientation === 2 ? (
          <View
            style={[
              {
                position: "absolute",
                top: 0,
                left:
                  pageW / 2 -
                  (titleDimensions?.width ? titleDimensions.width / 2 : 0),
                height: 36,
                paddingLeft: 8,
                paddingRight: 8,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.primary,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: colors.accent,
              },
            ]}
            onLayout={(event) => {
              if (!titleDimensions) {
                setTitleDimensions(event.nativeEvent.layout);
              }
            }}
          >
            <Text style={theme.title}>{title}</Text>
          </View>
        ) : null}
      </View>
    );
  }
);
export default WhiteboardHeader;

import React from "react";
import {
  TouchableOpacity,
  Text,
  View,
  Platform,
  FlatList,
  Keyboard,
} from "react-native";
import moment from "moment";
import update from "immutability-helper";
import {
  modalPickerOpts,
  fetchNetInfo,
  showExpiredTokenToast,
} from "../lib/helperFns";
import TextInput from "./TextInput";
import Modal from "./Modal";
import SearchBarElem from "./SearchBarElem";
import Icon from "./Icon";
import RemovalTitleRowWithAlert from "./RemovalTitleRowWithAlert";
import { ModalButton } from "./ModalButton";
import MultiPickerItem from "./MultiPickerItem";
import ButtonGroup from "./ButtonGroup";
import IconButton from "./IconButton";

import { apiRequestWithToken } from "../lib/api";
import {
  errorReport,
  isArrayWithItems,
  setNewGroupsWithLineCount,
} from "../lib/functions";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestApiData, receiveResponse } from "../actions/ApiActions";
import {
  setTargetProp,
  setDataValueAndValues,
  setFuseTypeOrSize,
  removePropArrItem,
  setRcdType,
  setRcdUse,
  addRcdProtectedGroup,
  addMeasurement,
} from "../actions/DocsActions";

import {
  DEPRECATEDaddOption,
  addModularOption,
  refreshOptions,
  removeOption,
  modifyOption,
} from "../actions/OptionsActions";
import { signOut } from "../actions/ProfileActions";
import { ThemeContext } from "../theming/theme-context";
import { withTranslation } from "react-i18next";

const OS = Platform.OS;

// TODO block pickerObject deletion from users
class ModalPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.fusePicker && !props.fuseType ? true : false,
      propertyInputs: false,
      searchText: "",
      searchArr: [],
      selectedArr: [],
      title: props.title || "",
      keyboardHeight: 0,
    };
    this.keyboardDidShowSubscription;
  }

  componentDidMount() {
    this.keyboardDidShowSubscription = Keyboard.addListener(
      "keyboardDidShow",
      (e) => {
        this.setState({ keyboardHeight: e.endCoordinates.height });
      }
    );
    this.keyboardDidHideSubscription = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        this.setState({ keyboardHeight: 0 });
      }
    );
  }
  componentWillUnmount() {
    this.keyboardDidShowSubscription.remove();
    this.keyboardDidHideSubscription.remove();
  }
  resetState = () => {
    this.setState({
      err: "",
      title: "",
      fuseSize: "",
      fuseType: "",
      propertyInputs: false,
      searchText: "",
      searchArr: [],
      selectedArr: [],
      dropDownItem: null,
      [this.props.prop]: "",
    });
  };

  openFuseSizeInputOrPicker = (value) => {
    if (
      value === "gG 0,4s" ||
      value === "gG 5,0s" ||
      value === "gL 0,4s" ||
      value === "gL 5,0s"
    ) {
      this.setState({
        fuseType: value,
        searchText: "fuseSize",
        searchArr: this.props.extraCellProps.fuseSizeOptions,
        title: this.props.t("fuseSize"),
      });
    } else {
      this.setState({
        searchText: "fuseSize",
        fuseType: value,
        propertyInputs: true,
        title: this.props.t("fuseSize"),
      });
    }
  };

  checkForGGGLSize = (value) => {
    const { docId, extraCellProps } = this.props;
    const {
      measurementObjectId,
      valueKey,
      fuseTypeValueKey,
      fuseSizeValueKey,
      fuseSize,
      modifyValue,
      fuseSizeOptions,
    } = extraCellProps;

    if (fuseSizeOptions.includes(fuseSize)) {
      modifyValue({
        valueKey: `${measurementObjectId}_${valueKey}_${fuseTypeValueKey}`,
        value: value,
        docId,
      });
      modifyValue({
        valueKey: `${measurementObjectId}_${valueKey}_${fuseSizeValueKey}`,
        value: fuseSize,
        docId,
      });
      this.toggleModal();
    } else {
      this.openFuseSizeInputOrPicker(value);
    }
  };

  setRcdProtectedPicker = (lineCount, disableRcdProtected, fuseSize) => {
    if (
      this.state.fuseType === "gG 0,4s" ||
      this.state.fuseType === "gG 5,0s" ||
      this.state.fuseType === "gL 0,4s" ||
      this.state.fuseType === "gL 5,0s" ||
      disableRcdProtected
    ) {
      this.setState(
        {
          lineCount: parseInt(lineCount),
          propertyInputs: false,
          searchText: "rcd",
          fuseSize: fuseSize || this.state.fuseSize,
        },
        () => {
          this.setItem(lineCount);
        }
      );
    } else {
      this.setState({
        lineCount: parseInt(lineCount),
        propertyInputs: false,
        fuseSize: fuseSize || this.state.fuseSize,
        searchText: "rcd",
        searchArr: [this.props.t("yes"), this.props.t("no")],
        title: this.props.t("isItemRcd"),
      });
    }
  };

  fuseSequence = (value) => {
    const { docId, extraCellProps } = this.props;
    const {
      measurementObjectId,
      valueKey,
      fuseTypeValueKey,
      fuseSizeValueKey,
      modifyValue,
      disableLineCount,
      disableRcdProtected,
      fuseSize,
      fuseType,
    } = extraCellProps;
    const { searchText } = this.state;
    // if fuseType isn't set and fuseType and fuseSize have been picked, groups are created according to lineCount
    if (searchText === "rcd") {
      setNewGroupsWithLineCount({
        ...extraCellProps,
        docId,
        lineCount: this.state.lineCount || 1,
        fuseSize: this.state.fuseSize,
        fuseType: this.state.fuseType,
        addRcd: value,
      });
      this.toggleModal();
    }
    // if fuseType was picked and fuseSize needs to be picked, searchtext should be fuseSize
    else if (searchText === "fuseSize" && fuseType) {
      if (Number.isInteger(parseInt(value))) {
        modifyValue({
          valueKey: `${measurementObjectId}_${valueKey}_${fuseTypeValueKey}`,
          value: this.state.fuseType,
          docId,
        });
        modifyValue({
          valueKey: `${measurementObjectId}_${valueKey}_${fuseSizeValueKey}`,
          value: parseInt(value).toString(),
          docId,
        });
        this.toggleModal();
      } else {
        this.setState({
          err: this.props.t("inputInteger"),
        });
      }
    }
    // if fuseType was picked and fuseSize needs to be picked, searchtext is fuseSize
    // if fuseType hasn't been set, set it
    // if fuseType prop is null, open lineCount picker
    else if (searchText === "fuseSize" && !fuseType) {
      if (Number.isInteger(parseInt(value))) {
        if (disableLineCount && disableRcdProtected) {
          setNewGroupsWithLineCount({
            ...extraCellProps,
            docId,
            lineCount: 1,
            fuseSize: parseInt(value).toString(),
            fuseType: this.state.fuseType,
            addRcd: null,
          });
          this.toggleModal();
        } else if (disableLineCount) {
          this.setRcdProtectedPicker(
            1,
            disableRcdProtected,
            parseInt(value).toString()
          );
        } else {
          this.setState({
            fuseSize: parseInt(value).toString(),
            propertyInputs: false,
            searchText: "lineCount",
            searchArr: [1, 2, 3],
            title: this.props.t("lineCount"),
          });
        }
      } else {
        this.setState({
          err: this.props.t("inputInteger"),
        });
      }
    }
    // if picking fuse stuff, ask user if theres a corresponding rcd
    else if (searchText === "lineCount") {
      this.setRcdProtectedPicker(value, disableRcdProtected);
    }
    // if fuseSize is already set and new type isn't gg or gl, just set new fuseType value
    else if (
      fuseSize &&
      value !== "gG 0,4s" &&
      value !== "gG 5,0s" &&
      value !== "gL 0,4s" &&
      value !== "gL 5,0s"
    ) {
      this.toggleModal();
      modifyValue({
        valueKey: `${measurementObjectId}_${valueKey}_${fuseTypeValueKey}`,
        value: value,
        docId,
      });
    }
    // if fuseSize isn't set, or new type is gggl, open fuseSize input/picker
    else {
      // this.props.onPress(item, this.getItemText(item));
      // this.toggleModal();
      // if new and old types are gggl then just set type
      // check if gggl fuseSize options has old fuseSize, if not, open picker
      if (
        value === "gG 0,4s" ||
        value === "gG 5,0s" ||
        value === "gL 0,4s" ||
        value === "gL 5,0s"
      ) {
        if (
          fuseType === "gG 0,4s" ||
          fuseType === "gG 5,0s" ||
          fuseType === "gL 0,4s" ||
          fuseType === "gL 5,0s"
        ) {
          this.toggleModal();
          modifyValue({
            valueKey: `${measurementObjectId}_${valueKey}_${fuseTypeValueKey}`,
            value: value,
            docId,
          });
        } else {
          this.checkForGGGLSize(value);
        }
      } else {
        this.checkForGGGLSize(value);
      }
    }
    // if picker is fuseSize, just set new size
    // else if (!setType && fuseType) {
    //   if (Number.isInteger(parseInt(value))) {
    //     this.toggleModal();
    //     this.props.setFuseTypeOrSize({
    //       docId,
    //       groupId: prop,
    //       setType: false,
    //       value: parseInt(value).toString(),
    //     });
    //   } else {
    //     this.setState({
    //       err: this.props.t("inputInteger"),
    //     });
    //   }
    // }
  };

  DEPRECATEDopenFuseSizeInputOrPicker = (value) => {
    if (
      value === "gG 0,4s" ||
      value === "gG 5,0s" ||
      value === "gL 0,4s" ||
      value === "gL 5,0s"
    ) {
      this.setState({
        fuseType: value,
        searchText: "fuseSize",
        searchArr: this.props.fuseSizeOptions,
        title: this.props.t("fuseSize"),
      });
    } else {
      this.setState({
        searchText: "fuseSize",
        fuseType: value,
        propertyInputs: true,
        title: this.props.t("fuseSize"),
      });
    }
  };

  DEPRECATEDcheckForGGGLSize = (value) => {
    if (this.props.fuseSizeOptions.includes(this.props.fuseSize)) {
      this.toggleModal();
      this.props.setFuseTypeOrSize({
        docId: this.props.docId,
        groupId: this.props.prop,
        setType: true,
        value,
      });
      this.props.setFuseTypeOrSize({
        docId: this.props.docId,
        groupId: this.props.prop,
        setType: false,
        value: this.props.fuseSize,
      });
    } else {
      this.DEPRECATEDopenFuseSizeInputOrPicker(value);
    }
  };

  DEPRECATEDfuseSequence = (value) => {
    const { prop, docId, setType, fuseSize, fuseType } = this.props;
    const { searchText } = this.state;
    // if fuseType isn't set and fuseType and fuseSize have been picked, groups are created according to lineCount
    if (searchText === "rcd") {
      this.toggleModal();
      this.props.setNewGroupsWithLineCount(
        this.props.prop,
        this.state.lineCount,
        this.state.fuseSize,
        this.state.fuseType,
        value
      );
    }
    // if fuseType was picked and fuseSize needs to be picked, searchtext should be fuseSize
    else if (searchText === "fuseSize" && fuseType) {
      if (Number.isInteger(parseInt(value))) {
        this.toggleModal();
        this.props.setFuseTypeOrSize({
          docId,
          groupId: prop,
          setType: true,
          value: this.state.fuseType,
        });
        this.props.setFuseTypeOrSize({
          docId,
          groupId: prop,
          setType: false,
          value: parseInt(value).toString(),
        });
      } else {
        this.setState({
          err: this.props.t("inputInteger"),
        });
      }
    }
    // if fuseType was picked and fuseSize needs to be picked, searchtext is fuseSize
    // if fuseType hasn't been set, set it
    // if fuseType prop is null, open lineCount picker
    else if (searchText === "fuseSize" && !fuseType) {
      if (Number.isInteger(parseInt(value))) {
        this.setState({
          fuseSize: parseInt(value).toString(),
          propertyInputs: false,
          searchText: "lineCount",
          searchArr: [1, 2, 3],
          title: this.props.t("lineCount"),
        });
      } else {
        this.setState({
          err: this.props.t("inputInteger"),
        });
      }
    }
    // if picking fuse stuff, ask user if theres a corresponding rcd
    else if (searchText === "lineCount") {
      if (
        this.state.fuseType === "gG 0,4s" ||
        this.state.fuseType === "gG 5,0s" ||
        this.state.fuseType === "gL 0,4s" ||
        this.state.fuseType === "gL 5,0s"
      ) {
        this.setState(
          {
            lineCount: parseInt(value),
            propertyInputs: false,
            searchText: "rcd",
          },
          function () {
            this.setItem(value);
          }
        );
      } else {
        this.setState({
          lineCount: parseInt(value),
          propertyInputs: false,
          searchText: "rcd",
          searchArr: [this.props.t("yes"), this.props.t("no")],
          title: this.props.t("isItemRcd"),
        });
      }
    }
    // if picker is fuseType picker
    else if (setType) {
      // if fuseSize is already set and new type isn't gg or gl, just set new fuseType value
      if (
        fuseSize &&
        value !== "gG 0,4s" &&
        value !== "gG 5,0s" &&
        value !== "gL 0,4s" &&
        value !== "gL 5,0s"
      ) {
        this.toggleModal();
        this.props.setFuseTypeOrSize({
          docId,
          groupId: prop,
          setType: true,
          value,
        });
      }
      // if fuseSize isn't set, or new type is gggl, open fuseSize input/picker
      else {
        // if new and old types are gggl then just set type
        // check if gggl fuseSize options has old fuseSize, if not, open picker
        if (
          value === "gG 0,4s" ||
          value === "gG 5,0s" ||
          value === "gL 0,4s" ||
          value === "gL 5,0s"
        ) {
          if (
            fuseType === "gG 0,4s" ||
            fuseType === "gG 5,0s" ||
            fuseType === "gL 0,4s" ||
            fuseType === "gL 5,0s"
          ) {
            this.toggleModal();
            this.props.setFuseTypeOrSize({
              docId,
              groupId: prop,
              setType: true,
              value,
            });
          } else {
            this.DEPRECATEDcheckForGGGLSize(value);
          }
        } else {
          this.DEPRECATEDcheckForGGGLSize(value);
        }
      }
    }
    // if picker is fuseSize, just set new size
    else if (!setType && fuseType) {
      if (Number.isInteger(parseInt(value))) {
        this.toggleModal();
        this.props.setFuseTypeOrSize({
          docId,
          groupId: prop,
          setType: false,
          value: parseInt(value).toString(),
        });
      } else {
        this.setState({
          err: this.props.t("inputInteger"),
        });
      }
    }
  };

  setItem = (value) => {
    const { prop, docId, fusePicker } = this.props;

    if (this.props.setValueSequence === "fuseSequence") {
      this.fuseSequence(value);
    } else if (this.props.onSelect) {
      this.toggleModal();
      this.props.onSelect(value);
    } else if (fusePicker) {
      this.DEPRECATEDfuseSequence(value);
    } else {
      if (this.props.valueInputPicker) {
        this.toggleModal();

        if (this.props.addMeasurementFn) {
          this.props.addMeasurementFn({
            docId: this.props.docId,
            valueKey: this.props.valueKey,
            value,
            maxOrMin: this.props.maxOrMin,
            replace: true,
          });
        } else {
          this.props.addMeasurement({
            docId: this.props.docId,
            valueKey: this.props.valueKey,
            value,
            maxOrMin: this.props.maxOrMin,
            replace: true,
          });
        }
      } else if (this.props.rcdUsePicker) {
        this.toggleModal();
        this.props.setRcdUse({ docId, rcdId: prop, value: value });
      } else if (this.props.rcdTypePicker) {
        this.toggleModal();
        this.props.setRcdType({ docId, rcdId: prop, value: value });
      } else if (this.props.onPress) {
        this.toggleModal();
        this.props.onPress(value);
      }
    }
  };

  setMultipleItems = () => {
    this.props.onAddMultiplePress(this.state.selectedArr.map((x) => x.item));
  };

  toggleModal = (ev, propertyInputs, closeModalOnInputClose) => {
    if (
      !this.props.onBeforeModalOpen ||
      (this.props.onBeforeModalOpen && this.props.onBeforeModalOpen())
    ) {
      if (this.props.toggleModal) {
        this.setState({ closeModalOnInputClose, propertyInputs });
        this.props.toggleModal();
      } else {
        this.setState({
          closeModalOnInputClose,
          propertyInputs,
          visible: !this.state.visible,
        });
      }
    }
  };

  matchSearchShallow = (searchable, searchText) => {
    const res = searchable
      .toString()
      .toLowerCase()
      .replace(/[\s]/gi, "")
      .search(searchText);
    return res !== -1;
  };

  search = (text) => {
    let matches = [];

    if (this.props.searchProps) {
      this.props.searchProps.forEach((x) => {
        let textToLower = text.toLowerCase().replace(/[\s]/gi, "");

        this.props.options.forEach((v) => {
          const propToMatch = this.getDropDownItemText(v, x);

          if (
            this.matchSearchShallow(propToMatch, textToLower) &&
            !matches.includes(v)
          ) {
            matches.push(v);
          }
        });
      });
    } else {
      let textToLower = text.toLowerCase().replace(/[\s]/gi, "");

      try {
        matches = this.props.options.filter((item) => {
          return this.matchSearchShallow(
            this.props.getItemText
              ? this.props.getItemText(item)
              : this.getItemText(item),
            textToLower
          );
        });
      } catch (error) {
        errorReport({
          error,
          errorInFn: "search",
          errorInScreen: "ModalPicker",
        });
      }
    }

    this.setState({ searchText: text, searchArr: matches });
  };

  clearSearch = () => {
    this.setState({ searchText: "", searchArr: this.props.options });
  };

  getFuseHeaderTitle = () => {
    if (this.props.setValueSequence === "fuseSequence") {
      if (this.state.title)
        return this.props.extraCellProps.id + " " + this.state.title;
      else if (this.props.setType)
        return this.props.extraCellProps.id + " Sulaketyyppi";
      else return this.props.extraCellProps.id + " Sulakekoko";
    }
    // ! SOON TO BE DEPRECATED TITLES
    else {
      if (this.state.title) return this.props.prop + " " + this.state.title;
      else if (this.props.setType) return this.props.prop + " Sulaketyyppi";
      else return this.props.prop + " Sulakekoko";
    }
  };

  renderHeader = (theme, colors, hideButtons) => {
    if (
      this.props.fusePicker ||
      this.props.setValueSequence === "fuseSequence"
    ) {
      return (
        <View
          style={[
            this.state.propertyInputs ? null : theme.line,
            {
              borderBottomColor: colors.secondary,
              width: "100%",
              padding: 8,
              alignContent: "center",
              justifyContent: "center",
              backgroundColor: colors.secondary,
            },
          ]}
        >
          <Text style={theme.title}>{this.getFuseHeaderTitle()}</Text>
        </View>
      );
    } else {
      return (
        <View style={theme.modalButtonContainer}>
          {(hideButtons && this.props.inputTitle) ||
          ((this.props.dataObjKey || this.props.dataObjKey == 0) &&
            this.props.measurementTitle) ? (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: this.props.extraDataObjProps
                  ? "center"
                  : "space-between",
                alignItems: "center",
                backgroundColor: colors.darkPrimary,
              }}
            >
              {!hideButtons && this.props.switchMeasOrGroup ? (
                <TouchableOpacity
                  onPress={() =>
                    this.props.switchMeasOrGroup(
                      true,
                      this.props.measurementKey,
                      this.props.dataObjKey
                    )
                  }
                >
                  <View
                    style={[
                      {
                        width: 36,
                        height: 36,
                        borderBottomRightRadius: 8,
                        paddingTop: 0,
                        paddingBottom: 0,
                        backgroundColor: colors.primary,
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <Icon name={"arrow-left"} size={24} color={colors.text} />
                  </View>
                </TouchableOpacity>
              ) : null}

              <View
                style={[
                  {
                    height: 36,
                    paddingTop: 0,
                    paddingBottom: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={theme.text}>
                  {this.props.inputTitle ||
                    `${this.props.dataObjName} - ${this.props.measurementTitle}`}
                </Text>
              </View>
              {!hideButtons && this.props.switchMeasOrGroup ? (
                <TouchableOpacity
                  onPress={() =>
                    this.props.switchMeasOrGroup(
                      false,
                      this.props.measurementKey,
                      this.props.dataObjKey
                    )
                  }
                >
                  <View
                    style={[
                      {
                        width: 36,
                        height: 36,
                        borderBottomLeftRadius: 8,
                        paddingTop: 0,
                        paddingBottom: 0,
                        backgroundColor: colors.primary,
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <Icon name={"arrow-right"} size={24} color={colors.text} />
                  </View>
                </TouchableOpacity>
              ) : null}
            </View>
          ) : null}

          {!this.state.propertyInputs ? (
            <SearchBarElem
              placeholder={this.props.t("search")}
              containerStyle={[
                //theme.searchBarContainer,
                theme.zeroPaddingAndMargin,
                { backgroundColor: colors.secondary },
              ]}
              inputContainerStyle={[
                theme.inputContainerStyle,
                { backgroundColor: colors.secondary },
              ]}
              inputStyle={theme.searchBarInput}
              clearIcon={
                <TouchableOpacity onPress={() => this.clearSearch()}>
                  <Icon name={"close"} size={24} color={colors.text} />
                </TouchableOpacity>
              }
              searchIcon={
                <Icon name={"magnify"} size={24} color={colors.text} />
              }
              onChangeText={this.search}
              value={this.state.searchText}
              onClear={this.clearSearch}
            />
          ) : null}
        </View>
      );
    }
  };

  renderSeparator = () => {
    return <View style={{ paddingBottom: 8 }} />;
  };

  DEPRECATEDaddOption = (value) => {
    this.props.requestApiData();

    this.props.addModularOption({
      prop: this.props.prop,
      value,
      isUserOption: this.props.isUserOption,
    });
    if (this.props.onAdd) {
      this.props.onAdd(this.state[this.props.prop]);
    }

    fetchNetInfo().then((state) => {
      if (state.isConnected && this.props.role !== "Trial") {
        apiRequestWithToken(
          {
            option: this.props.prop,
            value,
            isUserOption: this.props.isUserOption,
          },
          "/options/add"
        )
          .then((response) => {
            this.props.receiveResponse();
            this.setState({ propertyInputs: false });
            if (response === "expired" || response === "tokenErr") {
              this.props.signOut();
              showExpiredTokenToast();
            }
          })
          .catch(() => {
            this.props.receiveResponse();
          });
      } else {
        this.props.receiveResponse();
      }
    });
  };

  onAddDocProp = () => {
    if (this.state[this.props.prop] === "") {
      this.setState({ err: this.props.t("checkValue") });
    } else if (
      this.props.options &&
      this.props.options.includes(this.state[this.props.prop])
    ) {
      this.setState({ err: this.props.t("valueExists") });
    } else {
      const value = this.state[this.props.prop];
      this.DEPRECATEDaddOption(value.toString());
      this.setState({
        visible: false,
        propertyInputs: false,
        searchText: "",
        searchArr: [],
        selectedArr: [],
        [this.props.prop]: "",
      });
    }
  };

  removeValue = (prop, value) => {
    this.props.onRemove(value);
  };

  getLastModified = () => {
    if (this.props.isUserOption)
      return this.props.userLastModified?.[`options.${this.props.prop}`];
    return this.props.lastModified[this.props.prop];
  };

  addItemToSelected = (_selected) => {
    if (this.props.multi) {
      const found = this.state.selectedArr.findIndex(
        (x) => x.index === _selected.index
      );
      if (found !== -1) {
        this.setState({
          selectedArr: update(this.state.selectedArr, {
            $splice: [[found, 1]],
          }),
        });
      } else {
        this.setState({
          selectedArr: update(this.state.selectedArr, { $push: [_selected] }),
        });
      }
    } else {
      const found = this.state.selectedArr.findIndex(
        (x) => x.index === _selected.index
      );
      if (found !== -1) {
        this.setState({
          selectedArr: update(this.state.selectedArr, { $set: [] }),
        });
      } else {
        this.setState({
          selectedArr: update(this.state.selectedArr, { $set: [_selected] }),
        });
      }
    }
  };

  onRemovePress = (_selected) => {
    this.props.removeOption(this.props.role, {
      docId: this.props.docId,
      prop: this.props.prop,
      index: (_selected ?? this.state.selectedArr[0]).index,
      currentValue: this.props.value,
      value: (_selected ?? this.state.selectedArr[0]).item,
      isUserOption: this.props.isUserOption,
    });
    this.setState({ selectedArr: [] });
  };

  onPropertyInputDone = () => {
    if (
      this.props.fusePicker ||
      this.props.setValueSequence === "fuseSequence"
    ) {
      this.setItem(this.state[this.props.prop]);
    } else if (this.state.selectedArr.length > 0) {
      if (this.state[this.props.prop]) {
        this.props.modifyOption(
          this.props.role,
          {
            docId: this.props.docId,
            prop: this.props.prop,
            value: this.state[this.props.prop],
            currentValue: this.props.multiPicker
              ? this.props.values
              : this.props.value,
            isArray: this.props.multiPicker,
            selectedValue: this.state.selectedArr[0].item,
            index: this.state.selectedArr[0].index,
            valueKey: this.props.valueKey,
            isUserOption: this.props.isUserOption,
          },
          this.props.modifyValue
        );
        this.setState({
          visible: false,
          propertyInputs: false,
          searchText: "",
          searchArr: [],
          selectedArr: [],
          [this.props.prop]: "",
        });
      }
    } else {
      this.onAddDocProp();
    }
  };

  getNoDataArr = () => {
    if (OS == "web") {
      return [this.props.t("emptyList")];
    } else if (this.props.addableProp) {
      return [this.props.t("emptyListRefreshWithGesture")];
    } else if (this.props.onRefresh) {
      return [this.props.t("emptyListRefreshWithGesture2")];
    } else {
      return [this.props.t("emptyList")];
    }
  };

  getItemText = (item) => {
    if (this.props.translateText) {
      return this.props.translationPrefix
        ? this.props.t(this.props.translationPrefix + item)
        : this.props.t(item);
    } else {
      const textProps = this.props.itemTextProps;
      if (textProps) {
        if (textProps.length > 1) {
          let itemText = "";

          textProps.forEach((x, i) => {
            if (x.value) {
              if (x.format) {
                if (x.format === "date") {
                  itemText += moment(item[x.prop][x.value]).format(
                    "DD.MM.YYYY"
                  );
                } else {
                  itemText += item[x.prop][x.value];
                }
              } else {
                itemText += item[x.prop][x.value];
              }

              if (i >= 0 && i < textProps.length - 1) {
                itemText += " ";
              }
            } else {
              if (x.format) {
                if (x.format === "date") {
                  itemText += moment(item[x.prop]).format("DD.MM.YYYY");
                } else {
                  itemText += item[x.prop];
                }
              } else {
                itemText += item[x.prop] || "";
              }

              if (i >= 0 && i < textProps.length - 1) {
                itemText += " ";
              }
            }
          });

          return itemText;
        } else {
          return item[textProps];
        }
      } else {
        return item;
      }
    }
  };

  getValuesItemText = (item) => {
    if (this.props.translateText) {
      return this.getItemText(item);
    }
    if (this.props.valuesProp) {
      return item[this.props.valuesProp];
    }
    return item;
  };

  getDropDownItemText = (item, textProp) => {
    if (typeof item[textProp.prop][textProp.value] === "string") {
      return item[textProp.prop][textProp.value];
    } else if (typeof item[textProp.prop] === "string") {
      return item[textProp.prop];
    } else return "-";
  };

  renderDropDownContents = (item, theme, colors) => {
    const leftViewStyles = [theme.optionContainer, { flex: 3 }];
    const rightViewStyles = [theme.optionContainer, { flex: 5 }];

    return (
      <View style={{ flex: 1 }}>
        <View style={[theme.line, { borderBottomColor: colors.secondary }]} />
        {this.props.dropDownItems.map((x) => {
          const text = this.getDropDownItemText(item, x);
          return (
            <View key={"dropDownItem" + text} style={theme.customerInfo}>
              <View style={leftViewStyles}>
                <Text style={theme.optionTextLeft}>{x.title}</Text>
              </View>

              <View style={rightViewStyles}>
                <Text style={theme.optionTextRight}>{text}</Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  onItemPress =
    (_toggleModal) =>
    (item, index, haveData = true) =>
    () => {
      if (haveData) {
        if (this.props.multi) {
          this.addItemToSelected({ item, index });
        } else if (this.props.onPress) {
          if (this.props.setValueSequence === "fuseSequence") {
            this.fuseSequence(item);
          } else {
            this.props.onPress(
              item,
              this.props.getItemText
                ? this.props.getItemText(item)
                : this.getItemText(item)
            );
            _toggleModal && this.toggleModal();
          }
        } else {
          this.setItem(item);
        }
      }
    };

  renderListElem = (refreshProps, theme, colors) => {
    const data = !this.state.searchText
      ? this.props.options
      : this.state.searchArr;
    const haveData =
      this.props.options && this.props.options.length > 0 ? true : false;

    return (
      <FlatList
        {...refreshProps}
        style={Platform.OS === "web" ? theme.scrollContainer : null} // for some reason web needs this or scrolling won't work
        listKey={
          this.props.listKey
            ? this.props.listKey + "ModalPickerMainList"
            : this.props.dataObjKey + "modalPickerList"
        }
        keyboardShouldPersistTaps="handled"
        initialNumToRender={15}
        data={haveData ? data : this.getNoDataArr()}
        keyExtractor={(item, index) =>
          "picker" +
          index +
          this.props.dataObjKey +
          (this.props.getItemText
            ? this.props.getItemText(item)
            : this.getItemText(item))
        }
        ItemSeparatorComponent={() => this.renderSeparator(theme, colors)}
        renderItem={({ item, index }) => {
          const isCurrentValue = this.props.currentValue === item;

          // onLongPress={() => {
          //   this.props.multi
          //     ? this.addItemToSelected({ item, index })
          //     : (this.props.itemOptions &&
          //         this.props.itemOptions.includes(item)) ||
          //       !this.props.addableProp ||
          //       this.props.fusePicker ||
          //       this.props.setValueSequence === "fuseSequence" ||
          //       (this.props.prop !== "protections" &&
          //         this.props.valueInputPicker)
          //     ? null
          //     : this.addItemToSelected({ item, index });
          // }}

          const selectable =
            haveData &&
            !(
              this.props.multi ||
              (this.props.itemOptions &&
                this.props.itemOptions.includes(item)) ||
              !this.props.addableProp ||
              this.props.fusePicker ||
              this.props.setValueSequence === "fuseSequence" ||
              (this.props.prop !== "protections" && this.props.valueInputPicker)
            );
          const isSelected = this.state.selectedArr.some(
            (x) => x.item === item
          );
          return (
            <View
              style={[
                {
                  flex: 1,
                  backgroundColor: isSelected
                    ? colors.lightAccent
                    : isCurrentValue
                    ? colors.accent
                    : colors.lightBg,
                },
              ]}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {selectable ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    onPress={() => this.addItemToSelected({ item, index })}
                    backgroundColor={
                      isSelected ? colors.lightAccent : colors.lightBg
                    }
                    icon={isSelected ? "select-inverse" : "select"}
                    color={colors.text}
                  />
                ) : null}
                <TouchableOpacity
                  style={{ flex: this.props.dropDownInfo ? 4 : 1 }}
                  onPress={() => {
                    this.onItemPress(true)(item, index, haveData)();
                  }}
                  onLongPress={
                    selectable
                      ? () => this.addItemToSelected({ item, index })
                      : null
                  }
                >
                  <View
                    style={[
                      theme.optionContainer,
                      {
                        backgroundColor: isSelected
                          ? colors.lightAccent
                          : isCurrentValue
                          ? colors.accent
                          : colors.lightBg,
                      },
                    ]}
                  >
                    <Text style={theme.optionText}>
                      {this.props.getItemText
                        ? this.props.getItemText(item)
                        : this.getItemText(item)}
                    </Text>
                  </View>
                </TouchableOpacity>

                {this.props.dropDownInfo ? (
                  <>
                    <View
                      style={[theme.divider, { borderColor: colors.secondary }]}
                    />

                    <TouchableOpacity
                      style={{ flex: 1 }}
                      onPress={() =>
                        this.setState({
                          dropDownItem:
                            this.state.dropDownItem === item ? null : item,
                        })
                      }
                    >
                      <View
                        style={[
                          theme.optionContainer,
                          {
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <Icon
                          name={
                            this.state.dropDownItem === item
                              ? "chevron-up"
                              : "chevron-down"
                          }
                          size={24}
                          color={colors.text}
                        />
                      </View>
                    </TouchableOpacity>
                  </>
                ) : null}
              </View>
              {this.state.dropDownItem === item
                ? this.renderDropDownContents(item, theme, colors)
                : null}
            </View>
          );
        }}
      />
    );
  };
  renderModalPicker = (theme, colors, orientation) => {
    const refreshProps = {
      refreshing:
        this.props.role === "Trial" ||
        this.props.fusePicker ||
        this.props.setValueSequence === "fuseSequence" ||
        this.props.disableRefresh
          ? null
          : this.props.onRefreshList
          ? this.props.refreshing
            ? true
            : false
          : this.props.isFetching,
      onRefresh:
        this.props.role === "Trial" ||
        this.props.fusePicker ||
        this.props.setValueSequence === "fuseSequence" ||
        this.props.disableRefresh
          ? null
          : () =>
              this.props.onRefreshList
                ? this.props.onRefreshList()
                : this.props.refreshOptions(
                    this.props.prop,
                    this.getLastModified(),
                    this.props.role,
                    null,
                    null,
                    this.props.isUserOption
                  ),
    };

    return (
      <Modal
        {...modalPickerOpts}
        isVisible={
          this.props.controlledVisibility
            ? this.props.visible
            : this.state.visible
        }
        onBackButtonPress={this.toggleModal}
        onBackdropPress={this.toggleModal}
        backdropColor={colors.borderLighter}
        backdropOpacity={0.6}
      >
        {this.state.propertyInputs ? (
          <View style={[theme.modalInputContainer, { paddingBottom: 0 }]}>
            {this.renderHeader(theme, colors, true)}
            <View style={theme.textInputWrapper}>
              {this.state.err ? (
                <Text style={[theme.warningLabel, { textAlign: "left" }]}>
                  {this.state.err}
                </Text>
              ) : null}
              <View style={theme.textInputContainer}>
                <TextInput
                  orientation={orientation}
                  disableFullscreenUI={false}
                  autoCapitalize={"sentences"}
                  autoFocus={true}
                  keyboardType={
                    this.props.fusePicker ||
                    this.props.setValueSequence === "fuseSequence" ||
                    this.props.numeric
                      ? "decimal-pad"
                      : "default"
                  }
                  selectTextOnFocus={
                    this.state.selectedArr.length > 0 ? true : false
                  }
                  value={this.state[[this.props.prop]]}
                  style={theme.modalTextInput}
                  onChangeText={(text) => {
                    if (
                      this.props.numeric ||
                      this.props.prop === "voltages" ||
                      this.props.prop === "mainFuses" ||
                      this.props.prop === "protections"
                    ) {
                      const value = text.replace(/[^\d]/gi, "");
                      this.setState({ [this.props.prop]: value, err: "" });
                    } else {
                      const textToUse = text.replace(/\r?\n|\r/g, "");
                      this.setState({ [this.props.prop]: textToUse, err: "" });
                    }
                  }}
                  onSubmitEditing={() => this.onPropertyInputDone()}
                  blurOnSubmit={this.state.err ? false : true}
                />
              </View>
            </View>

            <View style={theme.buttonContainer}>
              <ButtonGroup
                buttons={[
                  {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: this.props.t("close"),
                    onPress: () => {
                      if (
                        this.props.fusePicker ||
                        this.props.setValueSequence === "fuseSequence" ||
                        this.state.closeModalOnInputClose
                      ) {
                        this.toggleModal();
                      } else {
                        this.setState({ propertyInputs: false });
                      }
                    },
                  },
                  {
                    title:
                      this.state.selectedArr.length > 0 ||
                      this.props.fusePicker ||
                      this.props.setValueSequence === "fuseSequence"
                        ? this.props.t("save")
                        : this.props.t("add"),
                    disabled: this.props.isFetching,
                    loading: this.props.isFetching,
                    onPress: () => this.onPropertyInputDone(),
                  },
                ]}
              />
            </View>
          </View>
        ) : (
          <View style={theme.flex}>
            {this.state.selectedArr.length === 1 ? (
              <RemovalTitleRowWithAlert
                onCancelPress={() => this.setState({ selectedArr: [] })}
                style={{ flex: 1 }}
                onRemovePress={() => this.onRemovePress()}
                onModifyPress={() =>
                  this.setState({
                    propertyInputs: this.state.selectedArr[0],
                    [this.props.prop]: this.state.selectedArr[0].item,
                  })
                }
                deletionWarning={
                  this.props.t("deleteConfirmation") +
                  ": " +
                  this.props.title +
                  (this.props.title?.[this.props.title?.length - 1] !== ":"
                    ? ": "
                    : " ") +
                  this.state.selectedArr[0].item
                }
              />
            ) : (
              this.renderHeader(theme, colors)
            )}
            <View style={theme.flex}>
              {this.renderListElem(refreshProps, theme, colors)}
            </View>
            <View style={theme.modalButtonContainer}>
              <ButtonGroup
                buttons={[
                  {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: this.props.t("close"),
                    onPress: () => this.toggleModal(),
                  },
                  refreshProps.onRefresh
                    ? {
                        title: this.props.t("refreshList"),
                        disabled: refreshProps.refreshing,
                        loading: refreshProps.refreshing,
                        onPress: () => refreshProps.onRefresh(),
                      }
                    : null,
                  this.props.multi
                    ? this.state.selectedArr.length > 0
                      ? {
                          title: this.props.t("save"),
                          onPress: () => this.setMultipleItems(),
                        }
                      : null
                    : this.state.selectedArr.length == 1
                    ? {
                        title: this.props.t("select"),
                        onPress: () =>
                          this.onItemPress(true)(
                            this.state.selectedArr[0].item,
                            this.state.selectedArr[0].index
                          )(),
                      }
                    : null,

                  this.state.selectedArr.length > 0 || !this.props.addableProp
                    ? null
                    : {
                        title: this.props.addButtonTitle
                          ? this.props.addButtonTitle
                          : this.props.t("addNew"),
                        onPress: () => this.setState({ propertyInputs: true }),
                      },
                ]}
              />
            </View>
          </View>
        )}
      </Modal>
    );
  };

  renderPlaceHolderItem = (theme) => (
    <View style={theme.rowContainer}>
      <View
        style={[
          theme.halfContainer,
          theme.rowContainer,
          { paddingLeft: 8 /*wp(2)*/ },
        ]}
      >
        <Text style={theme.text}>{"-"}</Text>
      </View>
    </View>
  );

  renderMultiPickerContent = (theme, colors) => {
    const haveArr =
      Array.isArray(this.props.values) && this.props.values.length > 0;
    const data = haveArr ? this.props.values : ["-"];

    if (this.props.multiPicker && !this.props.noItemList) {
      if (this.props.scrollableList ?? true) {
        return (
          <View
            style={[
              { backgroundColor: colors.lightBg },
              this.props.maxHeight ? { maxHeight: this.props.maxHeight } : null,
            ]}
          >
            <FlatList
              listKey={this.props.listKey + "MultiPickerContent"}
              data={data}
              keyExtractor={(item, index) =>
                "multiPicker" +
                index +
                this.props.prop +
                this.getValuesItemText(item)
              }
              ItemSeparatorComponent={() => this.renderSeparator(theme, colors)}
              renderItem={({ item }) => {
                if (haveArr) {
                  let found;
                  if (this.props.optionsProp) {
                    found = this.props.options.find(
                      (x) => x[this.props.optionsProp] === item
                    );
                  }
                  const notRemovable =
                    this.props.noRemoval ||
                    (isArrayWithItems(this.props.nonRemovable) &&
                      this.props.nonRemovable.includes(item));
                  return (
                    <MultiPickerItem
                      prop={this.props.prop}
                      item={item}
                      text={
                        this.props.getItemText
                          ? this.props.getItemText(item)
                          : this.props.optionsProp && found
                          ? this.getItemText(found)
                          : this.getValuesItemText(item)
                      }
                      onRemove={notRemovable ? null : this.props.onRemove}
                      removeValue={notRemovable ? null : this.removeValue}
                      theme={theme}
                      colors={colors}
                      disabled={this.props.disabled}
                    />
                  );
                } else {
                  return this.renderPlaceHolderItem(theme, colors);
                }
              }}
            />
          </View>
        );
      } else {
        if (haveArr) {
          return data.map((item, index) => {
            let found;
            if (this.props.optionsProp) {
              found = this.props.options.find(
                (x) => x[this.props.optionsProp] === item
              );
            }
            const notRemovable = this.props.noRemoval;
            return (
              <View
                key={
                  "multiPicker" +
                  this.props.prop +
                  index +
                  this.getValuesItemText(item)
                }
                style={[theme.line, { borderBottomColor: colors.secondary }]}
              >
                {
                  <MultiPickerItem
                    prop={this.props.prop}
                    item={item}
                    text={
                      this.props.getItemText
                        ? this.props.getItemText(item)
                        : this.props.optionsProp && found
                        ? this.getItemText(found)
                        : this.getValuesItemText(item)
                    }
                    onRemove={notRemovable ? null : this.props.onRemove}
                    removeValue={notRemovable ? null : this.removeValue}
                    theme={theme}
                    colors={colors}
                    disabled={this.props.disabled}
                  />
                }
              </View>
            );
          });
        } else {
          return this.renderPlaceHolderItem(theme, colors);
        }
      }
    } else return null;
  };

  render() {
    return (
      <ThemeContext.Consumer>
        {({ theme, colors, orientation }) =>
          this.props.onlyPicker ? (
            <>
              {this.renderModalPicker(theme, colors, orientation)}
              {this.renderMultiPickerContent(theme, colors)}
            </>
          ) : (
            <View
              style={{
                flex: 1,
                opacity:
                  this.props.disabled && !this.props.noDisabledStyle ? 0.6 : 1,
              }}
            >
              {this.props.options &&
              this.props.options.length > 0 &&
              this.props.options.length < 4 ? (
                <ButtonGroup
                  isRadio
                  theme={theme}
                  colors={colors}
                  error={this.props.error}
                  hint={this.props.hint}
                  hintTable={this.props.hintTable}
                  onClear={this.props.onDelete}
                  // wrapperStyle={wrapperStyle}
                  value={this.props.value}
                  title={this.props.title}
                  onPress={(buttonValue, buttonIndex) =>
                    this.onItemPress(false)(
                      buttonValue,
                      buttonIndex,
                      this.props.options && this.props.options.length > 0
                        ? true
                        : false
                    )
                  }
                  disabled={this.props.disabled}
                  noDisabledStyle={this.props.noDisabledStyle}
                  onTitlePress={() => {
                    this.resetState();
                    this.toggleModal(null, false, false);
                  }}
                  buttons={this.props.options.map((_value) => ({
                    value: _value,
                    title: this.props.getItemText
                      ? this.props.getItemText(_value)
                      : this.getItemText(_value),
                  }))}
                  icons={[
                    {
                      icon: "chevron-down",
                      color: colors.text,
                      onPress: () => {
                        this.resetState();
                        this.toggleModal(null, false, false);
                      },
                    },
                  ]}
                >
                  {this.renderModalPicker(theme, colors)}
                </ButtonGroup>
              ) : (
                <ModalButton
                  lang={this.props.lang}
                  hint={this.props.hint}
                  hintTable={this.props.hintTable}
                  onDelete={this.props.onDelete}
                  disabled={this.props.disabled}
                  noDisabledStyle={this.props.noDisabledStyle}
                  title={this.props.title}
                  text={
                    this.props.translateText
                      ? this.props.t(this.props.value)
                      : this.props.value
                  }
                  error={this.props.error}
                  boldTitle={this.props.boldTitle}
                  onModalPress={() => {
                    this.resetState();
                    this.toggleModal();
                    if (this.props.refreshOnOpen && this.props.onRefreshList) {
                      this.props.onRefreshList();
                    }
                  }}
                  multiModalPickerButton={this.props.multiPicker ? true : false}
                  longTitle={this.props.longTitle}
                  buttonColor={this.props.buttonColor}
                  textColor={this.props.textColor}
                  multiPicker={this.props.multiPicker}
                >
                  {this.renderModalPicker(theme, colors)}
                </ModalButton>
              )}

              {this.renderMultiPickerContent(theme, colors)}
            </View>
          )
        }
      </ThemeContext.Consumer>
    );
  }
}

const mapDispatchToModalPickerProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      DEPRECATEDaddOption,
      addModularOption,
      setTargetProp,
      setDataValueAndValues,
      setFuseTypeOrSize,
      removePropArrItem,
      refreshOptions,
      removeOption,
      modifyOption,
      setRcdType,
      setRcdUse,
      addRcdProtectedGroup,
      requestApiData,
      receiveResponse,
      addMeasurement,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    userLastModified: state.userInfo.profile?.userLastModified,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToModalPickerProps
)(withTranslation()(ModalPicker));

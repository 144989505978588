import "react";
import update from "immutability-helper";
import {
  findLayoutCell,
  getLayoutCellsArray,
  errorReport,
  getInitialLayouts,
  replaceStringsInObject,
  recursiveFindDependenciesFromLayout,
  getTranslatedText,
  findAllProperties,
  getNewestLayoutVersion,
  getReduxLayout,
  getLayoutSection,
  isArrayWithItems,
} from "../../lib/functions";
import { nonInputCellTypes } from "../../lib/constants";
import { showToast } from "../../lib/helperFns";
import { apiRequest } from "../../lib/api";
import { getLayouts } from "../../reducers/OptionsReducer";
import i18next from "i18next";

const fonts = {
  timesRoman: "Times New Roman",
  timesRomanBold: "Times New Roman",
  timesRomanItalic: "Times New Roman",

  courier: "Courier",
  courierBold: "Courier",
  courierOblique: "Courier",

  helvetica: "Helvetica",
  helveticaBold: "Helvetica",
  helveticaOblique: "Helvetica",
};
export const cellFontToFontFamily = (font) => {
  return fonts[font] || "Helvetica";
};

export const valueKeyToReadable = (valueKey) => {
  // old deprecated togglableCells have valuekeys as e.g. "100_togglableCellsSections/1_20"
  // so we remove the togglable from the middle so it looks better when displayed to user

  // some cells that are from pdfCreator may not have valueKeys

  if (!valueKey) {
    return "";
  }
  if (valueKey.includes("togglable")) {
    const split = valueKey.split("_");
    return `${split[0]}_${split[2]}`;
  } else return valueKey;
};
export const lockLayouts = async (
  t,
  windowModel,
  layoutsToLock,
  options,
  data,
  REMOVE_LAYOUT,
  CHANGE_LAYOUTS,
  REMOVE_DEPENDENCIES,
  closeTab,
  callback
) => {
  const layoutsToLockBody = {};
  const layouts = getLayouts(options, data);
  const idToLayoutId = {};

  let foundProperties;
  if (windowModel) {
    foundProperties = getLayoutsFromWindowModel(windowModel, layouts, options);
  } else if (layoutsToLock) {
    foundProperties = {};
    layoutsToLock.forEach((layoutId) => {
      if (!foundProperties[layoutId]) {
        foundProperties[layoutId] = {
          dependencies: [],
        };
        recursiveFindDependenciesFromLayout(
          layoutId,
          Math.max(...Object.keys(layouts[layoutId].versions)),
          layouts,
          foundProperties[layoutId].dependencies
        );
      }
    });
  }

  const foundPropertiesKeys = Object.keys(foundProperties);
  // TODO if the layout is missing from local we can't get its database id -> should presume its removed
  foundPropertiesKeys.forEach((x) => {
    if (layouts[x]) {
      // old versions of main layouts can't be edited so we just get the newest one
      const layout = getNewestLayoutVersion(layouts, x);
      idToLayoutId[layout?.databaseId || x] = layout?.layoutId || x;

      layoutsToLockBody[layout?.databaseId || x] = foundProperties[
        x
      ].dependencies.reduce((prev, cur) => {
        if (cur.layoutId !== x) {
          const curLayout =
            layouts[cur.layoutId]?.versions?.[cur.layoutVersion];
          if (curLayout?.databaseId) {
            idToLayoutId[curLayout.databaseId] = cur.layoutId;
            prev.push(curLayout.databaseId);
          } else {
            idToLayoutId[cur.layoutId] = cur.layoutId;
            // just add the layoutid and server will return it as missing if its missing locally
            prev.push(cur.layoutId);
          }
        }
        return prev;
      }, []);
    } else if (x !== "null") {
      layoutsToLockBody[x] = [];
    }
  });

  // return if there is no layouts to lock
  if (Object.keys(layoutsToLockBody).length === 0) {
    callback?.([]);
    return;
  }

  const res = __DEV__
    ? { status: 204 }
    : await apiRequest("/layouts/lockIfExists", "put", {
        layoutsToLock: layoutsToLockBody,
      });

  let availableLayouts = [];
  let tabsToClose = [];
  if (res?.status === 204) {
    Object.keys(layoutsToLockBody).forEach((x) => {
      const _availableLayout = getNewestLayoutVersion(layouts, idToLayoutId[x]);
      availableLayouts.push({
        ..._availableLayout,
        type: layouts[idToLayoutId[x]].type || "tmp",
        layoutVersion: _availableLayout.layoutVersion,
      });
      layoutsToLockBody[x]?.forEach((y) => {
        if (y !== x) {
          const foundDependency = foundProperties[
            idToLayoutId[x]
          ].dependencies.find(
            (dependency) => dependency.layoutId === idToLayoutId[y]
          );
          const __availableLayout = getReduxLayout(
            { layouts },
            foundDependency.layoutId,
            foundDependency.layoutVersion
          );
          availableLayouts.push({
            ...__availableLayout,
            type: layouts[foundDependency.layoutId].type || "tmp",
            layoutVersion: __availableLayout.layoutVersion,
          });
        }
      });
    });
  } else {
    if (res.status === 409) {
      // res.data.missing;
      // res.data.missingDependencies;
      // res.data.notAvailable;
      // res.data.notAvailableDependencies;
      Object.keys(layoutsToLockBody).forEach((x) => {
        let notAvailable =
          (isArrayWithItems(res.data.notAvailable) &&
            res.data.notAvailable.includes(x)) ||
          (isArrayWithItems(res.data.notAvailableDependencies) &&
            res.data.notAvailableDependencies[x]?.length > 0);

        // if the main layout is missing delete it and close tabs
        if (res.data.missing.includes(x)) {
          notAvailable = true;
          REMOVE_LAYOUT({
            id: idToLayoutId[x] || x,
          });

          // remove missing dependencies too
          if (res.data.missingDependencies[x]) {
            res.data.missingDependencies[x].forEach((y) => {
              REMOVE_LAYOUT({
                id: idToLayoutId[y] || y,
              });
            });
          }
        }

        // if the layout or some of its dependencies are not available, inform the user and close the editor tabs
        if (notAvailable) {
          tabsToClose.push(idToLayoutId[x] || x);
        } else {
          const _availableLayout = getNewestLayoutVersion(
            layouts,
            idToLayoutId[x]
          );
          availableLayouts.push({
            ..._availableLayout,
            type: layouts[idToLayoutId[x]].type || "tmp",
            layoutVersion: _availableLayout.layoutVersion,
          });

          if (res.data.missingDependencies[x]) {
            // TODO if dependencies are missing ask the user if the references
            // TODO should be replaced with another layout or removed
            // TODO also need to wait for user to select for each one if multiple opened layouts have missing dependencies
            // if a dependency is missing remove it from redux
            // main layout is still available to edit even if dependencies are missing

            res.data.missingDependencies[x].forEach((y) => {
              REMOVE_LAYOUT({
                id: idToLayoutId[y] || y,
              });
            });

            // for now just remove the relations from everywhere except cell texts
            REMOVE_DEPENDENCIES({
              layoutId: idToLayoutId[x],
              dependenciesLayoutIds: res.data.missingDependencies[x]
                .filter((y) => y !== x)
                .map((y) => idToLayoutId[y]),
            });
          }

          // add the dependencies to available layouts
          layoutsToLockBody[x]?.forEach((y) => {
            if (y !== x && !res.data.missingDependencies[x]?.includes(y)) {
              const foundDependency = foundProperties[
                idToLayoutId[x]
              ].dependencies.find(
                (dependency) => dependency.layoutId === idToLayoutId[y]
              );
              const _availableLayout = getReduxLayout(
                { layouts },
                foundDependency.layoutId,
                foundDependency.layoutVersion
              );
              availableLayouts.push({
                ..._availableLayout,
                type: layouts[foundDependency.layoutId].type || "tmp",
                layoutVersion: _availableLayout.layoutVersion,
              });
            }
          });
        }
      });

      // if dependencies are missing ask the user if the references should be replaced with another layout or removed
      // how to convey what is missing from where if there is no longer a layout to get a title from
      // - only info we have is the locations of the dependencies
      //   - cells with the dependency in text
      //   - specialInput with the dependency
      //   - section with the dependency
      // - show a list where the dependency is
      // - if we still have the layout locally we can show its type
      // need to wait for user to select for each one if multiple opened layouts have missing dependencies
      // const missingDependenciesKeys = Object.keys(res.data.missingDependencies);
      // missingDependenciesKeys.forEach((x) => {});
      if (tabsToClose.length > 0) {
        // if windowModel is usable then the lock was requested from activity
        if (windowModel) {
          showToast(t("autoLayoutLockConflict"));
        }
        // else the lock was requested from trying to open the layout or attaching it to a section
        else {
          // if the main layouts that were requested to be locked are missing, show a toast that they were removed
          if (
            res.data.missing.some((x) =>
              layoutsToLock.includes(idToLayoutId[x])
            )
          ) {
            showToast(t("layoutHasBeenRemoved"));
          }
          // if the main layouts weren't available, show a toast
          else if (
            res.data.notAvailable.some((x) =>
              layoutsToLock.includes(idToLayoutId[x])
            )
          ) {
            showToast(t("layoutAccessConflict"));
          }
        }

        tabsToClose.forEach((layoutId) => {
          // there's normally 3 tabs opened for every layout if the user hasn't closed some
          closeTab(`formpreview_${layoutId}`);
          closeTab(`formedit_${layoutId}`);
          closeTab(`pdfedit_${layoutId}`);
          closeTab(`pdfpreview_${layoutId}`);
        });
      }
    } else {
      showToast(t("unhandledError"));
    }
  }
  // add temporary layouts to redux so we won't edit the real layouts used for document creation
  if (availableLayouts.length > 0) {
    CHANGE_LAYOUTS({
      layouts: availableLayouts,
    });
  }
  callback?.(availableLayouts);
};

export const getLayoutsFromWindowModel = (
  storedModel,
  layouts,
  options,
  noDependencies
) => {
  let foundLayouts = [];

  findAllProperties(storedModel, ["layoutId"], foundLayouts);

  let foundProperties = {};

  foundLayouts.forEach((x) => {
    if (!foundProperties[x.value] && x.value) {
      foundProperties[x.value] = {
        dependencies: [],
        obj: x.obj,
        parent: x.parent,
      };

      if (!noDependencies && layouts[x.value]) {
        recursiveFindDependenciesFromLayout(
          x.value,
          Math.max(...Object.keys(layouts[x.value].versions)),
          layouts,
          foundProperties[x.value].dependencies,
          options.layoutEditor
        );
      }
    }
  });

  return foundProperties;
};

export const getCellTitle = (cell, lang, t, sectionIndex) => {
  if (cell) {
    const cellTitle =
      getTranslatedText(cell.title, lang) ||
      getTranslatedText(cell.formTitle, lang) ||
      getTranslatedText(cell.parentTitle, lang);
    return cellTitle
      ? `${cellTitle}${cell.type ? " - " + t(cell.type) : ""}`
      : `${
          sectionIndex !== undefined
            ? t("section") + (sectionIndex + 1) + " "
            : ""
        }${cell.row ? t("row") + cell.row + " " : ""}${
          cell.column ? t("column") + cell.column + " - " : ""
        }${t(cell.type)}`;
  }
  return "-";
};
export const getCellValueKeyWithTitle = (
  originalCell,
  valueKeys,
  valueTitles,
  cell,
  sectionIndex,
  langToEdit,
  t,
  valueKeyPrefix = "",
  titlePrefix = ""
) => {
  // need to add extraRows etc. here too so they will be available for checksToBeVisible etc.
  if (
    cell.valueKey !== originalCell.valueKey &&
    !nonInputCellTypes.includes(cell.type)
  ) {
    valueKeys.push(cell.valueKey);
    valueTitles.push(
      `${titlePrefix}${getCellTitle(cell, langToEdit, t, sectionIndex)}`
    );
  }
  // only loop dividedInput for inner cells
  if (cell.type === "dividedInputs") {
    cell.inputs?.forEach((x) => {
      getCellValueKeyWithTitle(
        originalCell,
        valueKeys,
        valueTitles,
        x,
        sectionIndex,
        langToEdit,
        t,
        valueKeyPrefix,
        `${titlePrefix}${getCellTitle(cell, langToEdit, t, sectionIndex)} - `
      );
    });
  }
};
export const getCellValueKeys = (layout, layoutCell, langToEdit, t) => {
  let valueKeys = [];
  let valueTitles = [];

  if (layoutCell.parentCell?.type === "extraRows") {
    layoutCell.parentCell.inputs?.forEach((x) => {
      getCellValueKeyWithTitle(
        layoutCell.found,
        valueKeys,
        valueTitles,
        x,
        layoutCell.sectionIndex,
        langToEdit,
        t,
        `${layoutCell.parentCell.valueKey}_${x.valueKey}_`,
        getCellTitle(
          layoutCell.parentCell,
          langToEdit,
          t,
          layoutCell.sectionIndex
        )
      );
    });
  } else if (layoutCell.sectionIndex < -2) {
    const section = getLayoutSection(layout, layoutCell.sectionIndex);
    section?.forEach((x) => {
      getCellValueKeyWithTitle(
        layoutCell.found,
        valueKeys,
        valueTitles,
        x,
        layoutCell.sectionIndex,
        langToEdit,
        t
      );
    });
  } else {
    layout.sections?.forEach((section, sectionIndex) => {
      section.cells?.forEach((x) => {
        getCellValueKeyWithTitle(
          layoutCell.found,
          valueKeys,
          valueTitles,
          x,
          sectionIndex,
          langToEdit,
          t
        );
      });
      section.alternateCells?.forEach((x) => {
        getCellValueKeyWithTitle(
          layoutCell.found,
          valueKeys,
          valueTitles,
          x,
          sectionIndex,
          langToEdit,
          t
        );
      });
    });
  }

  return { valueKeys, valueTitles };
};

/**
 * Tries to find a layout that has the same copiedFromGlobalLayout as requested layoutId
 * @param {*} layouts layouts dict or layouts array
 * @param {*} layoutId layoutId to find
 * @returns layout
 */
export const findDuplicatedLayoutWithId = (layouts, layoutId) => {
  const _layouts = Array.isArray(layouts) ? layouts : Object.values(layouts);

  let foundLayout;
  // try to find a layout that was made from the old layout
  for (let i = 0; i < _layouts.length; i++) {
    if (_layouts[i].copiedFromGlobalLayout === layoutId) {
      foundLayout = _layouts[i];
      break;
    }
  }
  return foundLayout;
};

export const createNewFromGlobalLayout = async (
  layoutId,
  options,
  SET_LAYOUTS,
  setLoading,
  callback
) => {
  try {
    const layoutWrapper = options.layouts[layoutId];

    if (!layoutWrapper) {
      setLoading?.("");
      return;
    }

    const layoutVersion = Math.max(...Object.keys(layoutWrapper.versions));
    const newLayout = layoutWrapper.versions[layoutVersion];
    // get all layouts so we can be sure that someone else hasn't already done this
    // and we need all the dependencies fetched anyway
    // TODO do we need to fetch everything since they should be fetched anyway
    // TODO can just use the lastmodified to fetch new
    const layoutsRes = await getInitialLayouts(
      options?.lastModifiedEditableLayouts,
      "/layouts/checkEditable"
    );

    const layoutsData = {
      layouts: {
        layouts: layoutsRes.layouts,
        lastModifiedEditableLayouts: layoutsRes.lastModifiedLayouts,
      },
    };
    const layouts = getLayouts(options, layoutsData);

    // check if there already is a duplicated layout
    let foundLayout = findDuplicatedLayoutWithId(
      layouts,
      layoutWrapper.layoutId
    );
    if (foundLayout) {
      if (layoutsRes?.lastModifiedLayouts) {
        SET_LAYOUTS(layoutsData);
      }
      callback(foundLayout.id, true);
      return;
    }

    // find all the dependencies in the base layout and its dependencies as well
    // e.g. copying doc1 which has measobj1 and measobj2, so also need to find all dependencies
    // from measobj1 and measobj2

    let newIds = {};
    let foundProperties = [];

    recursiveFindDependenciesFromLayout(
      newLayout.layoutId,
      layoutVersion,
      layouts,
      foundProperties
    );

    let addRequest = [];
    let foundLayouts = foundProperties.reduce((prev, cur) => {
      const layoutWrapper = layouts[cur.layoutId];
      const layout = layoutWrapper.versions[cur.layoutVersion];

      // ! dont duplicate pickerObjects
      // TODO if pickerObjects are made to be modified by user, change this
      if (layout.layoutType !== "pickerObjects") {
        addRequest.push({
          layoutType: layoutWrapper.layoutType,
          type: layoutWrapper.type,
          language: i18next.language, // redundant since we pass languages but its a required param
          languages: layoutWrapper.languages,
          // layout: layout,
          copiedFromGlobalLayout: layoutWrapper.layoutId,
          layoutValue: layout,
        });
        prev.push(layout);
      }

      return prev;
    }, []);

    // const res = {
    //   status: 200,
    //   data: addRequest.map((x) => {
    //     const newId =
    //       "LayoutVersionInfo/" + Math.random().toString(16).slice(2);
    //     return {
    //       id: newId,
    //       layoutId: newId,
    //       ...x,
    //     };
    //   }),
    // };
    const res = await apiRequest("/layouts/addmultiple", "post", addRequest);

    if (res.status === 200) {
      foundLayouts.forEach((x) => {
        newIds[x.layoutId] = res.data.find(
          (y) => y.copiedFromGlobalLayout === x.layoutId
        ).layoutId;
      });

      let searchTerms = [];
      let replacements = [];

      const dependencyKeys = Object.keys(newIds);
      dependencyKeys.forEach((x) => {
        searchTerms.push(x);
        replacements.push(newIds[x]);
      });

      let saveRequest = [];
      if (searchTerms.length > 0) {
        foundLayouts.forEach((x) => {
          const layoutId = x.layoutId;
          const _x = JSON.parse(JSON.stringify(x));
          replaceStringsInObject(_x, searchTerms, replacements);
          saveRequest.push({
            id: newIds[layoutId],
            layoutValue: _x,
          });
        });
      }

      const res2 = await apiRequest(
        "/layouts/savemultiple",
        "post",
        saveRequest
      );

      if (res2.status === 200) callback(newIds[layoutId]);
      else {
        errorReport({
          error: "failed to save copies",
          errorInFn: "createNewFromGlobalLayout1",
          errorInScreen: "ManageLayouts",
        });
        setLoading?.("");
      }
    } else {
      // err handling
      errorReport({
        error: "failed to add copies",
        errorInFn: "createNewFromGlobalLayout2",
        errorInScreen: "ManageLayouts",
      });
      setLoading?.("");
    }
  } catch (error) {
    errorReport({
      error: error,
      errorInFn: "createNewFromGlobalLayout3",
      errorInScreen: "ManageLayouts",
    });
    setLoading?.("");
  }
};

export const addToProp = (prop, propsToAdd) =>
  (Array.isArray(prop) ? prop : [prop]).concat(propsToAdd);

const pageSeparation = 10;
export const getEditorPageY = (top, pageNum) =>
  top * (pageNum + 1) + pageNum * pageSeparation;

export const getBorderColor = (border, cell) => {
  let color;
  if (border === 0) {
    color = cell.borders.top?.color;
  } else if (border === 1) {
    color = cell.borders.right?.color;
  } else if (border === 2) {
    color = cell.borders.bottom?.color;
  } else if (border === 3) {
    color = cell.borders.left?.color;
  }
  if (color) {
    return `rgb(${color.r},${color.g},${color.b})`;
  } else {
    return "#000";
  }
};

export function getConsequentCellsForSelection() {
  // layout, firstCell, lastCell
  // TODO
  // let cells = [];
  // for (let i = firstCell.sectionIndex; i < lastCell.sectionIndex + 1; i++) {
  //   const section = layout.sections[i];
  //   section.cells.forEach((cell) => {});
  // }
}

export const findCellInArr = (cells, cell) =>
  cells.some(
    (_cell) =>
      // _cell.sectionIndex === cell.sectionIndex &&
      // _cell.row === cell.row &&
      // _cell.column === cell.column &&
      _cell.valueKey === cell.valueKey
  );

export const getCellsInSection = (layout, sectionIndex) =>
  getLayoutCellsArray(layout, sectionIndex).map((c, i) =>
    update(c, {
      sectionIndex: { $set: sectionIndex },
      cellIndex: {
        $set: i,
      },
    })
  );

export const getCellsInRow = (
  layout,
  sectionIndex,
  cellsProp,
  row,
  parentValueKey
) =>
  parentValueKey
    ? findLayoutCell(layout, parentValueKey).found.inputs.filter(
        (x) => x.row === row
      )
    : getLayoutCellsArray(layout, sectionIndex, cellsProp).reduce((a, c, i) => {
        if (c.row === row) {
          a.push(
            update(c, {
              sectionIndex: { $set: sectionIndex },
              cellIndex: {
                $set: i,
              },
            })
          );
        }
        return a;
      }, []);

/**
 * if a nested cell is selected, only cells with the same parent can be selected at the same time
 * if no regular cells are selected, they can't be mixed with nested cells
 * @param {*} cells
 * @returns
 */
export const cellSelectionCheck = (cells, t, layout, selectedCellsRef) => {
  let nestedCheck = true,
    hasParentCell = false;

  const _selectedCellsParentValueKeys = selectedCellsRef.current.map(
    (x) => findLayoutCell(layout, x.valueKey).parentValueKey
  );

  for (let i = 0; i < cells.length; i++) {
    if (!nestedCheck) break;

    const { parentValueKey } = findLayoutCell(layout, cells[i].valueKey);

    if (parentValueKey) {
      hasParentCell = true;
      nestedCheck =
        selectedCellsRef.current.length < 1 ||
        _selectedCellsParentValueKeys.some((x) => x === parentValueKey);
    } else {
      nestedCheck = _selectedCellsParentValueKeys.every((x) => !x);
    }
  }

  if (nestedCheck) {
    return true;
  } else {
    if (hasParentCell) {
      showToast(t("nestedCellSelectionErr1"));
    } else {
      // TODO doesn't allow the selection of all inner cells
      showToast(t("nestedCellSelectionErr2"));
    }
    return false;
  }
};

export function isSectionSelected(
  section,
  selectedCellsRef,
  selectedSectionsRef
) {
  // TODO handle alternateCells, headerLayout etc.
  return section.cells?.length > 0
    ? section.cells.every((x) =>
        selectedCellsRef.current?.some((y) => y.valueKey === x.valueKey)
      )
    : selectedSectionsRef.current?.some((x) => x.valueKey === section.valueKey);
}

export function updateText(obj, prop, name, langToEdit, value) {
  return update(obj, {
    [prop]: (tmpProp) =>
      update(tmpProp || {}, {
        [name]: (tmpName) =>
          update(tmpName || {}, {
            [langToEdit]: { $set: value },
          }),
      }),
  });
}

export function getColCount(sectionIndex, rowIndex = -1, layout) {
  const _row = rowIndex + 1;

  const sectionHasTitle =
    sectionIndex === -1
      ? layout.headerLayout?.title
      : layout.sections?.[sectionIndex]?.title;
  const columnsCount =
    sectionIndex === -1
      ? layout.headerLayout?.columnsCount
      : layout.sections?.[sectionIndex]?.columnsCount;
  const defaultColumnsCount = sectionHasTitle ? 5 : 6;
  return (
    layout.columns?.[sectionIndex]?.[_row]?.count ??
    columnsCount ??
    layout.globalColumnsCount ??
    defaultColumnsCount
  );
}

export function getMenuItemsFromArr(arr, arrTitles) {
  let menuItems = [];
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    menuItems.push(
      <MenuItem key={"widthMenuItem" + item + i} value={item}>
        {arrTitles[i] ?? item}
      </MenuItem>
    );
  }
  return menuItems;
}

export function getDefaultCell(newType, cellProps = {}) {
  const basicText = {};
  const basicTitle = { text: { fin: "title" } };
  const {
    row = 1,
    column = 1,
    width = 1,
    type = "text",
    valueKey,
    text = basicText,
    title = basicTitle,
    borders = {
      top: { visible: true },
      left: { visible: true },
      bottom: { visible: true },
      right: { visible: true },
    },
    // unit = undefined,
    // addableProp = undefined,
    // align = undefined,
    // allowedTypes = undefined,
    // backgroundColor = undefined,
    // bordersForEveryCol = undefined,
    // chartData = undefined,
    // chartType = undefined, //"line",
    // checkBoxBeforeText = undefined,
    // checkedByDefault = undefined,
    // checksToBeVisible = undefined,
    // dateFormat = undefined,
    // defaultAttachments = undefined, // not fully implemented, only works with base64 images
    // // defaultAttachments: [
    // //   {
    // //     id: "logo",
    // //     base64:
    // //       "asdfqwerzxcvasdf",
    // //   },
    // // ],
    // defaultValue = undefined,
    // direction = undefined,
    // emptyValueAsEmpty = undefined,
    // extraAlign = undefined,
    // extraValueKey = undefined,
    // filledBeforeChoices = undefined,
    // firstCheckBoxWidth = undefined,
    // firstCheckTitle = undefined,
    // fn = undefined,
    // fnProps = undefined,
    // font = undefined,
    // formOnly = undefined,
    // formTitleOnly = undefined,
    // height = undefined,
    // hideIfEmpty = false,
    // hideInGallery = undefined,
    // imageAlign = undefined, // doesn't work, use align instead
    // imgTitleOnTop = undefined,
    // inputs = undefined,
    // items = undefined,
    // maxDecimals = undefined,
    // maxValue = undefined,
    // measurementSource = undefined,
    // measurementSources = undefined,
    // measurementKeys = undefined,
    // minValue = undefined,
    // multi = undefined,
    // needToBeFilledBefore = undefined,
    // noCheckTitles = undefined,
    // noImageDesc = undefined,
    // noImageName = undefined,
    // listStyle = undefined,
    // noPdfUnit = undefined,
    // numeric = undefined,
    // offlineAtch = undefined,
    // onlyNew = undefined, // only allow attaching new attachments
    // openByDefault = undefined,
    // optional = undefined,
    // options = undefined,
    // optionsProp = undefined,
    // pdfOnly = undefined,
    // required = undefined,
    // requiredProps = undefined, // currently required inputs when adding attachments
    // secondCheckBoxWidth = undefined,
    // secondCheckTitle = undefined,
    // sequenceProps = undefined,
    // size = undefined,
    // textIfChecked = undefined,
    // textIfNotChecked = undefined,
    // timePicker = undefined,
    // titleWidth = undefined, //1,
    // vAlign = undefined,
    // valuesSep = undefined,
  } = cellProps;

  // TODO handle rest of the width changes
  // cellProps.width = newType === "dualCheckBox" ? 3 : cellProps.width;

  // const basicProps = {
  //   valueKey: cellProps.valueKey,
  //   row: row,
  //   column: column,
  //   type: newType,
  //   borders: borders,
  //   width,
  //   // formTitleOnly: false,
  //   // hideIfEmpty: false,
  //   // checksToBeVisible: null,
  // };

  // const baseProps = {
  //   required: cellProps.required,
  //   optional: cellProps.optional,
  //   title: cellProps.title,
  // };

  // const basictypes = ["filler"];

  return {
    ...cellProps,
    row,
    column,
    width,
    type,
    valueKey,
    text,
    title,
    borders,
  };
  // return {
  //   addableProp,
  //   align,
  //   allowedTypes,
  //   backgroundColor,
  //   borders,
  //   bordersForEveryCol,
  //   chartData,
  //   chartType,
  //   checkBoxBeforeText,
  //   checkedByDefault,
  //   checksToBeVisible,
  //   column,
  //   dateFormat,
  //   defaultValue,
  //   direction,
  //   emptyValueAsEmpty,
  //   extraAlign,
  //   extraValueKey,
  //   filledBeforeChoices,
  //   firstCheckBoxWidth,
  //   firstCheckTitle,
  //   fn,
  //   fnProps,
  //   font,
  //   formOnly,
  //   formTitleOnly,
  //   height,
  //   hideIfEmpty,
  //   imageAlign,
  //   imgTitleOnTop,
  //   inputs,
  //   items,
  //   maxDecimals,
  //   maxValue,
  //   measurementSource,
  //   measurementSources,
  //   measurementKeys,
  //   minValue,
  //   needToBeFilledBefore,
  //   noCheckTitles,
  //   noImageDesc,
  //   noImageName,
  //   listStyle,
  //   noPdfUnit,
  //   numeric,
  //   offlineAtch,
  //   optional,
  //   options,
  //   optionsProp,
  //   pdfOnly,
  //   required,
  //   row,
  //   secondCheckBoxWidth,
  //   secondCheckTitle,
  //   sequenceProps,
  //   size,
  //   text,
  //   textIfChecked,
  //   textIfNotChecked,
  //   timePicker,
  //   title,
  //   titleWidth,
  //   type: newType,
  //   unit,
  //   valueKey,
  //   valuesSep,
  //   width: newType === "dualCheckBox" ? 3 : width,
  // };
  // if (basictypes.includes(newType)) {
  //   return cellProps;
  // } else if (newType === "text") {
  //   return {
  //     ...basicProps,
  //     text,
  //     title,
  //     direction,
  //     titleWidth,
  //     //tocLevel: 0,
  //   };
  // } else if (newType === "textField" || newType === "multilineField") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     numeric,
  //     maxDecimals,
  //     maxValue,
  //     minValue,
  //     direction,
  //     titleWidth,
  //   };
  // } else if (newType === "picker") {
  //   let _obj = {
  //     ...basicProps,
  //     ...baseProps,
  //     options: {},
  //     addableProp: false,
  //     optionsProp: null,
  //     direction: "column",
  //     titleWidth: 1,
  //   };
  //   if (baseType === "measurementObjects") {
  //     _obj = {
  //       ...basicProps,
  //       ...baseProps,
  //       valueKey: valueKey,
  //       type: newType,
  //       checksToBeVisible: null,
  //       openIfNotFilled: true,
  //       setValueSequence: "",
  //       sequenceProps: {},
  //     };
  //   }
  //   return _obj;
  // } else if (newType === "multiPicker") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     title: basicTitle,
  //     options: {},
  //     valuesSep: null,
  //     addableProp: false,
  //     optionsProp: null,
  //     direction: "column",
  //     titleWidth: 1,
  //   };
  // } else if (newType === "checkBox") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     title: basicTitle,
  //     checkedByDefault: false,
  //   };
  // } else if (newType === "checkBoxText") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     title: basicTitle,
  //     textIfChecked: {},
  //     textIfNotChecked: {},
  //     checkedByDefault: false,
  //   };
  // } else if (newType === "dualCheckBox") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     title: basicTitle,
  //     titleWidth: 1,
  //     firstCheckTitle: "",
  //     firstCheckBoxWidth: "",
  //     secondCheckTitle: "",
  //     secondCheckBoxWidth: 1,
  //   };
  // } else if (newType === "dualCheckBoxText") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     title: basicTitle,
  //     titleWidth: 1,
  //     textIfChecked: {},
  //     textIfNotChecked: {},
  //     firstCheckTitle: "",
  //     firstCheckBoxWidth: "",
  //     secondCheckTitle: "",
  //     secondCheckBoxWidth: 1,
  //   };
  // } else if (newType === "pdfCheckBox") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //   };
  // } else if (newType === "attachment") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     direction: "column",
  //     titleWidth: 1,
  //   };
  // } else if (newType === "multiAttachment") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     valueSep: null,
  //     direction: "column",
  //     titleWidth: 1,
  //   };
  // } else if (newType === "pdfAttachment") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     height: 50,
  //     offlineAtch: false,
  //     formTitleOnly: false,
  //     noImageName: false,
  //     noImageDesc: false,
  //     multi: false,
  //   };
  // } else if (newType === "datePicker") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     direction: "column",
  //     timePicker: false,
  //     titleWidth: 1,
  //   };
  // } else if (newType === "datePickerCheckBox") {
  //   return {
  //     ...basicProps,
  //     ...baseProps,
  //     firstCheckTitle: {},
  //     direction: "column",
  //     titleWidth: 1,
  //     textIfChecked: {},
  //     textIfNotChecked: {},
  //     timePicker: false,
  //   };
  // } else if (
  //   baseType === "measurementObjects" &&
  //   newType === "calculatedText"
  // ) {
  //   return {
  //     ...baseProps,
  //     fn: null,
  //     fnProps: [],
  //   };
  // } else if (
  //   baseType === "measurementObjects" &&
  //   newType === "extraMeasurementRow"
  // ) {
  //   return {
  //     measurementSource: null,
  //     measurementKeys: [],
  //   };
  // }
}

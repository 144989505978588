import "react";
import { View, Text, Platform } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { useTranslation } from "react-i18next";

import EmailInput from "./EmailInput";
import DualButtons from "./DualButtons";
import Picker from "./Picker";

export default function VerifyEmail(props) {
  const { t } = useTranslation();

  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <>
          <View
            style={
              Platform.OS === "web"
                ? theming.theme.signInContainer
                : theming.theme.mobileSignInContainer
            }
          >
            <Picker
              t={t}
              theme={theming.theme}
              colors={theming.colors}
              screen={props.screen}
              setScreen={props.setScreen}
            />

            <View style={theming.theme.signInFormContainer}>
              <View>
                <Text style={theming.theme.boldText}>{props.infoText}</Text>
              </View>

              <EmailInput
                t={props.t}
                onSubmitEditing={props.onRightButtonPress}
              />
            </View>

            {Platform.OS === "web" ? (
              <DualButtons
                leftButtonDisabled={props.leftButtonDisabled}
                rightButtonDisabled={props.rightButtonDisabled}
                leftButtonTitle={props.leftButtonTitle}
                onLeftButtonPress={props.onLeftButtonPress}
                rightButtonTitle={props.rightButtonTitle}
                onRightButtonPress={props.onRightButtonPress}
              />
            ) : null}
          </View>

          <View style={theming.theme.ssoLoginContainer} />

          {Platform.OS !== "web" ? (
            <DualButtons
              leftButtonDisabled={props.leftButtonDisabled}
              rightButtonDisabled={props.rightButtonDisabled}
              leftButtonTitle={props.leftButtonTitle}
              onLeftButtonPress={props.onLeftButtonPress}
              rightButtonTitle={props.rightButtonTitle}
              onRightButtonPress={props.onRightButtonPress}
            />
          ) : null}
        </>
      )}
    </ThemeContext.Consumer>
  );
}

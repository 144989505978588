import axios from "axios";
import localForage from "localforage";
import i18next from "i18next";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import queryString from "query-string";
import mergeImages from "merge-images";
import { api } from "../lib/constants";
import { base64ToBlob, errorReport } from "./functions";
import { showToast } from "./helperFns";

const fileDownloadInstance = axios.create({
  baseURL: api,
  https: true,
  timeout: 100000,
});
fileDownloadInstance.defaults.withCredentials = true;

const fileInstance = axios.create({
  baseURL: api,
  https: true,
  timeout: 0,
});
fileInstance.defaults.withCredentials = true;

const notFoundImg = require("../assets/noImageAvailable.png");

export const createZip = () => {
  return new JSZip();
};
export const overlayImage = (arr) => mergeImages(arr);

export const downloadSignedUrlAsBase64 = async (
  signedUrl,
  fileName,
  setDownloading,
  noDownloadErrorHandling
) => {
  let res = await fetch(signedUrl);

  if (res.status === 200) {
    let blob = await res.blob();
    const base64 = await blobToBase64(blob);
    setDownloading?.(false);
    return base64;
  } else {
    if (!noDownloadErrorHandling) {
      errorReport({
        error: res.status,
        errorInFn: "downloadSignedUrlAsBase64",
      });
    }
    setDownloading?.(false, res.status);
    return null;
  }
};
export const downloadSignedUrl = async (
  signedUrl,
  fileName,
  mimeType = "application/pdf",
  setDownloading
) => {
  let res;
  let blob;

  res = await fetch(signedUrl);
  if (res.status === 200) blob = await res.blob();

  if (res.status === 200) {
    await downloadBase64(null, fileName, mimeType, true, setDownloading, blob);
    setDownloading?.(false);
  } else {
    errorReport({
      error: res.status,
      errorInFn: "downloadSignedUrl",
    });
    setDownloading?.(false, res.status);
  }
};
/**
 * Download resource using /attachments/{id} endpoint
 * @param {*} id Resource id
 * @param {*} fileName
 * @param {*} mimeType
 * @param {*} setDownloading
 * @param {*} customToken
 * @param {*} asBase64
 * @param {*} share Native only, whether to show share window after download
 * @param {*} returnType Regular, Thumbnail or Original
 * @param {*} noDownloadErrorHandling If true, doesn't report download error
 * @param {*} returnFilePath whether to return file path MOBILE ONLY
 */
export const downloadResource = async (
  id,
  fileName,
  mimeType = "application/pdf",
  setDownloading,
  customToken,
  asBase64,
  share,
  returnType = "Regular",
  noDownloadErrorHandling
) => {
  // first get the signed url
  let res = await fileInstance.get(
    `${api}/attachments/${encodeURIComponent(
      id
    )}?redirect=false&returnType=${returnType}`,
    {
      headers: customToken
        ? {
            "content-type": "application/json",
            Credentials: "include",
            Authorization: "Bearer " + customToken,
          }
        : {
            "content-type": "application/json",
          },
    }
  );

  if (res.status === 200) {
    // signed url contains filename and mime type
    const parsed = queryString.parse(res.data.split("?")[1]);
    let filename = parsed.filename || fileName;
    let mime = parsed.mime || mimeType;
    if (asBase64)
      return await downloadSignedUrlAsBase64(
        res.data,
        filename,
        setDownloading,
        noDownloadErrorHandling
      );
    else downloadSignedUrl(res.data, filename, mime, setDownloading, share);
  } else {
    errorReport({
      error: res.status,
      errorInFn: "downloadSignedUrl",
    });
    setDownloading?.(false, res.status);
  }
};
export async function downloadBase64(
  base64String,
  fileName,
  mimeType = "application/pdf",
  openFilePicker,
  setDownloading,
  blob
) {
  try {
    blob ??= base64ToBlob(base64String, mimeType);
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
    showToast(i18next.t("fileDownloaded"), 3000, "green");
  } catch (error) {
    errorReport({
      error,
      errorMsg: i18next.t("unexpectedDLErr"),
      errorInScreen: screen,
      errorInFn: "shareFile",
    });
  }
  setDownloading?.(false);
}

export const onDownloadFile = async (
  url,
  params,
  screen,
  t,
  setDownloading,
  customToken
) => {
  // const res = await getWithToken(url, null, params?.body);

  try {
    const method = params.method || "GET";
    const res = await fileInstance({
      method: params.method || "GET",
      url:
        method.toLowerCase() === "get"
          ? url + (params?.body ? "?" + queryString.stringify(params.body) : "")
          : url,
      responseType: "blob", // Ensures the response is treated as binary Blob data
      headers: customToken
        ? {
            "content-type": "application/json",
            Credentials: "include",
            Authorization: "Bearer " + customToken,
          }
        : {
            "content-type": "application/json",
          },
      data: params.body,
    });

    if (res.status === 200) {
      let filename = params.fileName;
      const contentDisposition = res.headers["content-disposition"];

      if (contentDisposition) {
        // Use a regular expression to capture the filename after the `filename*=UTF-8''` part
        const matches = /filename\*?=\s*UTF-8''([^;]+)/.exec(
          contentDisposition
        );

        if (matches) {
          // The filename is now URL-encoded, so we need to decode it
          filename = decodeURIComponent(matches[1]);
        }
      }

      const url = window.URL.createObjectURL(new Blob([res.data]));

      // Create a temporary <a> element to simulate file download
      const link = document.createElement("a");
      link.href = url;

      // Set a default filename for the download (you can also extract it from headers if available)
      link.setAttribute("download", filename);

      // Append the <a> element to the DOM and trigger a click event to start download
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the <a> element and revoke the URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      showToast(i18next.t("fileDownloaded"), 3000, "green");
      setDownloading?.(false);
    } else {
      errorReport({
        error: res.status,
        errorInFn: "onDownloadFile",
      });
      setDownloading?.(false, res.status);
    }
    return;
  } catch (error) {
    errorReport({
      error,
      errorInFn: "onDownloadFile",
    });
    setDownloading?.(false, error);
    return;
  }

  // const _url =
  //   api +
  //   url +
  //   (params.method === "GET" ? "?" + queryString.stringify(params.body) : "");
  // let form = document.createElement("form");
  // form.method = params.method ?? "POST";
  // form.target = "_blank";
  // form.action = _url;

  // if (params.body) {
  //   Object.entries(params.body).forEach(([key, value]) => {
  //     form.innerHTML += `<input type="hidden" name="${key}" value="${value}">`;
  //   });
  // }
  // document.body.appendChild(form);
  // form.submit();
  // setDownloading?.(false);
};

export const dirs = { DocumentDir: "", CacheDir: "cache" };

export const saveFilesAs = (base64Arr) => {
  if (base64Arr.length > 1) {
    var zip = new JSZip();

    base64Arr.forEach((base64Doc) => {
      zip.file(`${base64Doc.name}.${base64Doc.ext}`, base64Doc.base64, {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, base64Arr[0].name + ".zip");
    });
  } else {
    saveAs(
      "data:application/pdf;base64," + base64Arr[0].base64,
      `${base64Arr[0].name}.${base64Arr[0].ext}`
    );
  }
};

export async function getNotFoundImg() {
  const imageBlob = await fetch(notFoundImg).then((res) => res.blob());
  const base64 = await blobToBase64(imageBlob);
  return { type: "png", imageBytes: base64 };
}

function getFileSignature(arrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const header = uint8Array
    .slice(0, 4)
    .reduce((acc, byte) => acc + byte.toString(16).padStart(2, "0"), "");
  return header.toUpperCase(); // Return the first 4 bytes as a hex string
}

function getFileTypeFromSignature(signature) {
  switch (signature) {
    case "89504E47":
      return "png";
    case "FFD8FFE0":
    case "FFD8FFE1":
    case "FFD8FFE2":
    case "FFD8FFE3":
    case "FFD8FFE8":
      return "jpg";
    default:
      return "unknown";
  }
}

export const detectBase64MimeType = (b64) => {
  const buffer = Buffer.from(b64, "base64");
  const signature = getFileSignature(buffer);
  const fileType = getFileTypeFromSignature(signature);
  return { ext: fileType };
};

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      var comma = reader.result.indexOf(",");
      resolve(reader.result.substr(comma + 1));
    };
  });
};

export const lsStat = () => {
  return localForage.keys();
};

export const saveFile = (path, base64) => {
  return localForage.setItem(path, base64);
};

export const fileStats = (url) => {
  if (!url) return Promise.resolve(false);
  return localForage.getItem(url);
};

export const readFile = (url) => {
  if (!url) return Promise.resolve(false);
  return localForage.getItem(url);
};

export const readFileWithProps = (url, props) => {
  if (!url) return Promise.resolve(false);
  return localForage.getItem(url).then((res) => {
    return { ...props, res };
  });
};

export const fileExists = (url) => {
  if (!url) return Promise.resolve(false);
  return localForage.getItem(url);
};

export const unlinkFile = (url) => {
  if (!url) return Promise.resolve(true);
  return localForage.removeItem(url);
};

export const moveFile = (path, newPath) => {
  if (!path || !newPath) return Promise.resolve(false);
  return localForage.getItem(path).then((item) => {
    return localForage.setItem(newPath, item).then(() => {
      return localForage.removeItem(path).then(() => newPath);
    });
  });
};

export const createDir = () => {
  return Promise.resolve(true);
};

export const copyFile = (path, newPath) => {
  if (!path || !newPath) return Promise.resolve(false);
  return localForage.getItem(path).then((item) => {
    //return localForage.removeItem(path).then(() => {
    return localForage.setItem(newPath, item);
    //});
  });
};

export const wrap = (path) => {
  return path;
};

export const urlDownload = (url, token, params) => {
  return fileDownloadInstance({
    method: "GET",
    url: url,
    responseType: "blob",
    // "Content-Type": "image",
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    params: params,
  })
    .then((res) => {
      if (res.status === 200) {
        return blobToBase64(res.data);
      } else if (res.status === 204) {
        return "no content";
      } else {
        return null;
      }
    })
    .then((base64) => {
      if (base64) {
        const fileType = detectBase64MimeType(base64);
        return {
          base64,
          ext: fileType?.ext,
        };
      } else return base64;
    })
    .catch((err) => {
      if (err?.response?.status === 422) return "no content";
      else if (err?.response?.status === 403) return "forbidden";
      else return "error";
    });
};

/**
 * Downloads file and saves it to file system (localforage in web and file system in native)
 * ALWAYS CREATES/OVERRIDES FILE IN NATIVE, EVEN IF SERVER DOES NOT RETURN ANYTHING
 * @param {String} path path to save file in
 * @param {Object} url endpoint url
 * @param {Object} token bearer token
 * @param {Object} method request method, default = "post"
 * @param {Object} body body or query params to send with request
 * @param {Object} setUploadProgress callback to set upload progress
 * @param {Object} checkFileType whether to get file type from base64 string
 * @return {String} "no content", "error", "forbidden" or file in base64 string
 */
export const fileDownload = async (
  path,
  url,
  token,
  method = "post",
  body,
  setUploadProgress,
  checkFileType
) => {
  return fileInstance({
    withCredentials: url.startsWith("https://cdn") ? false : true,
    method: method,
    url:
      url + (body && method === "get" ? "?" + queryString.stringify(body) : ""),
    responseType: "blob",
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: method === "get" ? undefined : body,
    onUploadProgress: setUploadProgress,
  })
    .then((res) => {
      if (res.status === 200) {
        return blobToBase64(res.data);
      } else if (res.status === 204) {
        return "no content";
      } else {
        return null;
      }
    })
    .then((base64) => {
      if (base64 !== "no content") {
        if (checkFileType) {
          const fileType = detectBase64MimeType(base64);
          return localForage.setItem(path + (fileType?.ext || "jpg"), base64);
        } else {
          return localForage.setItem(path, base64);
        }
      } else return base64;
    })
    .catch((err) => {
      if (err?.response?.status === 422 || err?.response?.status === 410)
        return "no content";
      else if (err?.response?.status === 403) return "forbidden";
      else if (err?.response?.status === 503) return "serviceErr";
      else return "error";
    });
};

export const fileUpload = (url, token, body, onUploadProgress) => {
  return fileInstance({
    method: "post",
    url: url,
    data: body,
    headers: token
      ? {
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {},
    onUploadProgress: onUploadProgress,
  });
};

import "react";
import { View, Text } from "react-native";
import { getTranslatedText } from "../lib/functions";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ThemeContext } from "../theming/theme-context";

// head = [{title: ""}]
// data = [{title: ""}]
function TableRow(props) {
  return (
    <View style={props.theming.theme.tableRow}>
      {props.data.map((x, i) => (
        <View
          key={"tablerowData" + i}
          style={{ flex: 1, flexDirection: "column" }}
        >
          <View style={props.theming.theme.tableColumn}>
            <Text style={props.theming.theme.textLineItem}>
              {props.translate
                ? props.t(x.title) || x.title
                : getTranslatedText(x.title, props.lang)}
            </Text>
          </View>
          {x.subTitle ? (
            <View style={props.theming.theme.tableColumn}>
              <Text style={props.theming.theme.textLineItem}>
                {props.translate
                  ? props.t(x.subTitle) || x.subTitle
                  : getTranslatedText(x.subTitle, props.lang)}
              </Text>
            </View>
          ) : null}
        </View>
      ))}
    </View>
  );
}
function TableHeader(props) {
  if (props.head)
    return (
      <View style={props.theming.theme.tableRowHeader}>
        {props.head.map((x, i) => (
          <View
            key={"tableheader" + i}
            style={props.theming.theme.tableColumnHeader}
          >
            <Text style={props.theming.theme.textHeader}>
              {props.translate
                ? props.t(x.title) || x.title
                : getTranslatedText(x.title, props.lang)}
            </Text>
            {x.subTitle ? (
              <Text style={props.theming.theme.textHeaderSubTitle}>
                {props.translate
                  ? props.t(x.subTitle) || x.subTitle
                  : getTranslatedText(x.subTitle, props.lang)}
              </Text>
            ) : null}
          </View>
        ))}
      </View>
    );
  else return null;
}
export default function Table(props) {
  const theming = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <View style={theming.theme.tableWrapper}>
      <View style={theming.theme.tableContainer}>
        <TableHeader
          t={t}
          theming={theming}
          translate={props.translate}
          lang={props.lang}
          head={props.head}
        />
        {props.data.map((x, i) => (
          <TableRow
            key={"tablerow" + i}
            theming={theming}
            t={t}
            translate={props.translate}
            data={x}
            lang={props.lang}
          />
        ))}
      </View>
    </View>
  );
}

import "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const zoomableConstPanningProps = {
  velocityDisabled: true,
};
const centeredContainer = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
};
export default function ZoomableView(props) {
  return (
    <TransformWrapper
      disabled={!!(props.disabled || props.captureEvent)}
      velocityDisabled={true}
      panning={zoomableConstPanningProps}
      onZoomStop={props.onZoomStop}
      onPanningStop={props.onPanningStop}
      minScale={0.4}
      maxScale={30}
    >
      <TransformComponent
        wrapperStyle={centeredContainer}
        contentStyle={centeredContainer}
      >
        {props.children}
      </TransformComponent>
    </TransformWrapper>
  );
}

import "react";
import { ActivityIndicator, TouchableOpacity, Text, View } from "react-native";
import Icon from "./Icon";
import { ThemeContext } from "../theming/theme-context";
import { useTranslation } from "react-i18next";

export default function RemovalTitleRow(props) {
  const { t } = useTranslation();

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View
          style={[
            theme.titleRowContainer,
            { justifyContent: "space-between", alignItems: "center" },
          ]}
        >
          {props.onSelectAllPress ? (
            <>
              <TouchableOpacity
                onPress={props.onSelectAllPress}
                style={{
                  height: 36,
                  width: 36,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={props.disabled || props.selectAllDisabled}
              >
                <Icon
                  name={props.allSelected ? "select-off" : "select-all"}
                  size={24}
                  color={colors.text}
                />
              </TouchableOpacity>
              <View style={theme.divider} />
            </>
          ) : null}

          <TouchableOpacity onPress={props.onCancelPress}>
            <View style={theme.titleItemContainer}>
              <Text style={theme.boldText}>{t("cancel")}</Text>
            </View>
          </TouchableOpacity>

          {props.disableDeletion ? null : (
            <>
              <View style={theme.divider} />

              <TouchableOpacity
                onPress={props.handleRemove}
                style={{ flexDirection: "row", alignItems: "center" }}
                disabled={
                  !!(
                    props.deleting ||
                    props.disabled ||
                    props.selectAllDisabled
                  )
                }
              >
                <View style={theme.titleItemContainer}>
                  <Text style={theme.boldText}>
                    {props.removeTitle ?? t("delete")}
                  </Text>
                </View>
                {props.deleting ? (
                  <ActivityIndicator size="large" color={colors.accent} />
                ) : (
                  <Icon
                    name={props.removeIcon ?? "trash-can"}
                    size={24}
                    color={colors.accent}
                  />
                )}
              </TouchableOpacity>
            </>
          )}

          {props.onModifyPress ? (
            <>
              <View style={theme.divider} />
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={props.onModifyPress}
                disabled={!!(props.disabled || props.modifyDisabled)}
              >
                <View style={theme.titleItemContainer}>
                  <Text style={theme.boldText}>
                    {props.modifyTitle ?? t("edit")}
                  </Text>
                </View>
                <Icon
                  name={props.modifyIcon ?? "pencil"}
                  size={24}
                  color={colors.accent}
                />
              </TouchableOpacity>
            </>
          ) : null}

          {props.extraActionTitle ? (
            <>
              <View style={theme.divider} />
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onPress={props.onExtraActionPress}
                disabled={!!(props.disabled || props.extraActionDisabled)}
              >
                <View style={theme.titleItemContainer}>
                  <Text style={theme.boldText}>{props.extraActionTitle}</Text>
                </View>
                {props.extraActionLoading ? (
                  <ActivityIndicator size="large" color={colors.accent} />
                ) : (
                  <Icon
                    name={props.extraActionIcon ?? "chevron-down"}
                    size={24}
                    color={colors.accent}
                  />
                )}
              </TouchableOpacity>
            </>
          ) : null}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

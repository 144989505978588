import { useContext, useReducer, useEffect } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import i18next from "i18next";
import moment from "moment";
import { useTranslation } from "react-i18next";
import StretchButton from "../components/StretchButton";
import { ThemeContext } from "../theming/theme-context";
import {
  getNewestLayoutVersion,
  isSameOrGreaterThanRole,
} from "../lib/functions";
import { modalPickerOpts, navigate, showToast } from "../lib/helperFns";
import { SET_UI_SETTINGS_PROP } from "../actions/ProfileActions";
import { connect } from "react-redux";
import CreateDocScreen from "./CreateDocScreen";
import { getWithToken } from "../lib/api";
import { addDoc, MERGE_VALUES } from "../actions/DocsActions";
import DatePickerRow from "../components/DatePickerRow";
import RegisterObjectPicker from "../components/RegisterObjectPicker";
import Modal from "../components/Modal";
import MonthPicker from "../components/MonthPicker";
import IconButton from "../components/IconButton";
import componentReducer from "../reducers/ComponentReducer";

const onlyReadPermissions = { Read: true };
const updateOnValueKeysChanged = ["valuesMerged"];
function DailySiteDocument(props) {
  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const [state, dispatchState] = useReducer(componentReducer, {
    loading: true,
    date: moment(),
    tmpValues: {
      sites_1: props.uiSettings?.defaultSiteId
        ? props.options?.sites?.[props.uiSettings.defaultSiteId]
        : undefined,
      customers_1: props.uiSettings?.defaultCustomerId
        ? props.options?.customers?.[props.uiSettings.defaultCustomerId]
        : undefined,
    },
    docId: "noDoc",
    datePicker: {},
  });
  const site = state.tmpValues["sites_1"];

  const closeDatePicker = () => {
    dispatchState({
      type: "set",
      prop: "datePicker",
      value: { isVisible: false },
    });
  };

  const isAdmin = isSameOrGreaterThanRole(props.role, "Admin");

  const layout = props.layoutId
    ? getNewestLayoutVersion(props.options.layouts, props.layoutId, isAdmin)
    : null;

  const fetchData = (layoutId, date, site, callback) => {
    if (!site) {
      dispatchState({
        type: "mergeState",
        value: {
          loading: false,
          docId: "noDoc",
        },
      });
      return;
    }
    if (!callback) {
      dispatchState({
        type: "set",
        prop: "loading",
        value: true,
      });
    }
    getWithToken("/docs/withProperties", null, {
      layoutId,
      siteId: site?.id,
      date: date.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
    }).then((res) => {
      if (res.status === 200) {
        const doc = res.data;
        if (callback) {
          callback(doc);
        } else {
          props.addDoc(doc);
          dispatchState({
            type: "mergeState",
            value: {
              loading: false,
              docId: doc.id,
            },
          });
        }
      } else if (res.status === 204) {
        if (callback) {
          callback(null);
        } else {
          dispatchState({
            type: "mergeState",
            value: {
              loading: false,
              docId: "noDoc",
            },
          });
        }
      } else {
        showToast(t("failedToFetchInfo"));
      }
    });
  };

  const copyPreviousDaysData = () => {
    const previousDate = state.date.clone().subtract(1, "days");
    fetchData(props.layoutId, previousDate, site, (doc) => {
      if (doc) {
        props.MERGE_VALUES({
          targetDocId: state.docId,
          doc,
          omitValueKeys: ["date"],
          mergeIndicator: true,
        });
      }
    });
  };

  // TODO save doc on unmount automatically
  useEffect(() => {
    if (props.layoutId) fetchData(props.layoutId, state.date, site);
  }, [props.layoutId, state.date, site]);

  if (!layout)
    return (
      <View style={theming.theme.centeredContainer}>
        <View style={theming.theme.modalListContainer}>
          <Text style={theming.theme.title}>{t("missingLayout")}</Text>
          <StretchButton
            title={t("close")}
            onPress={() => {
              navigate("browse", props.navigation);
            }}
          />
        </View>
      </View>
    );

  const getLeftButton = (doc) => {
    if (doc?.values?.valuesMerged) return null;
    return {
      title: t("copyPreviousDaysData"),
      startIcon: "content-copy",
      onPress: () => copyPreviousDaysData(),
    };
  };

  const renderDatePicker = () => {
    return (
      <View style={theming.theme.row}>
        <IconButton
          icon={"chevron-left"}
          onPress={() => {
            dispatchState({
              type: "set",
              prop: "date",
              value: state.date.clone().subtract(1, "days"),
            });
          }}
        />
        <DatePickerRow
          dateFormat={"DD.MM.YYYY"}
          date={state.date.format("DD.MM.YYYY")}
          setDatePicker={() => {
            dispatchState({
              type: "set",
              prop: "datePicker",
              value: {
                date: state.date.format("DD.MM.YYYY"),
                title: t("date"),
                isVisible: true,
                // timePicker: props.timePicker,
                // minDate: props.minDate,
                // maxDate: props.maxDate,
                dateFormat: "DD.MM.YYYY",
                onConfirm: (year, month, monthNum, day) => {
                  let newDate = moment(
                    `${day}.${monthNum}.${year}`,
                    "D.M.YYYY"
                  );
                  newDate = moment(newDate);
                  dispatchState([
                    {
                      type: "set",
                      prop: "date",
                      value: newDate,
                    },
                    {
                      type: "set",
                      prop: "datePicker",
                      value: { isVisible: false },
                    },
                  ]);
                },
              },
            });
          }}
        />
        <IconButton
          icon={"chevron-right"}
          onPress={() => {
            dispatchState({
              type: "set",
              prop: "date",
              value: state.date.clone().add(1, "days"),
            });
          }}
        />
      </View>
    );
  };
  const renderSitePicker = () => {
    return (
      <View style={theming.theme.vPadding8}>
        <RegisterObjectPicker
          onlyPicker
          item={{
            valueKey: "1",
            type: "pickerObjects",
            layoutId: "pickerObjects/1",
            prop: "name",
            optionsProp: "sites",
            layoutVersion: 1,
          }}
          onClear={() => {
            dispatchState([
              {
                type: "setWithArray",
                prop: ["tmpValues", "sites_1"],
                value: undefined,
              },
              {
                type: "setWithArray",
                prop: ["tmpValues", "customers_1"],
                value: undefined,
              },
            ]);
          }}
          modifyValue={(payload) => {
            if (Array.isArray(payload)) {
              dispatchState(
                payload.reduce(
                  (acc, x) => {
                    acc.push({
                      type: "setWithArray",
                      prop: ["tmpValues", x.valueKey],
                      value: x.value,
                    });
                    return acc;
                  },
                  [
                    {
                      type: "set",
                      prop: "loading",
                      value: true,
                    },
                  ]
                )
              );
            } else {
              dispatchState([
                {
                  type: "setWithArray",
                  prop: ["tmpValues", payload.valueKey],
                  value: payload.value,
                },
                {
                  type: "set",
                  prop: "loading",
                  value: true,
                },
              ]);
            }

            const setUISetting = (valueKey, value) => {
              let prop;
              const split = valueKey.split("_")[0];
              if (split === "customers") prop = "defaultCustomerId";
              else if (split === "sites") prop = "defaultSiteId";
              if (prop && value?.id)
                props.SET_UI_SETTINGS_PROP({ prop, value: value.id });
            };

            if (Array.isArray(payload)) {
              payload.forEach((x) => {
                setUISetting(x.valueKey, x.value);
              });
            } else {
              setUISetting(payload.valueKey, payload.value);
            }
          }}
          values={state.tmpValues}
          disableAdd
        />
      </View>
    );
  };
  return (
    <View style={theming.theme.centeredFlexContainer}>
      <View style={theming.theme.modalListContainer}>
        {renderDatePicker()}
        {renderSitePicker()}
        {state.loading ? (
          <View style={theming.theme.centeredFlexContainer}>
            <ActivityIndicator color={theming.colors.accent} size="large" />
          </View>
        ) : site ? (
          <CreateDocScreen
            navigation={props.navigation}
            navState={
              state.docId !== "noDoc"
                ? null
                : {
                    addDocOnMount: props.layoutId,
                    site,
                    customer: site
                      ? Object.values(state.tmpValues).find(
                          (x) => x?.id === site.customerId
                        )
                      : null,
                    valuesToAdd: [
                      {
                        prop: "date",
                        value: state.date.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
                      },
                    ],
                  }
            }
            onDocAdded={(doc) => {
              dispatchState({
                type: "mergeState",
                value: {
                  loading: false,
                  docId: doc.id,
                },
              });
            }}
            onIdChanged={(newDocId) => {
              dispatchState({
                type: "mergeState",
                value: {
                  loading: false,
                  docId: newDocId,
                },
              });
            }}
            noUrlNav
            docId={state.docId}
            uniqueSite={site.id}
            uniqueDate={state.date.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")}
            screenToGoBackTo=""
            getLeftButton={getLeftButton}
            hideSite
            hideCustomer
            updateOnValueKeysChanged={updateOnValueKeysChanged}
            getPermissions={(doc) =>
              doc?.statusEnum >= 10 ? onlyReadPermissions : null
            }
            hideNameInput
          />
        ) : null}
      </View>

      <Modal
        {...modalPickerOpts}
        isVisible={state.datePicker.isVisible}
        onBackButtonPress={closeDatePicker}
        onBackdropPress={closeDatePicker}
        backdropColor={theming.colors.borderLighter}
        backdropOpacity={0.6}
      >
        <MonthPicker
          {...state.datePicker}
          noToggleOnConfirm
          lang={props.lang}
          dayPicker={true}
          toggle={closeDatePicker}
          onConfirm={state.datePicker.onConfirm}
          noModal
        />
      </Modal>
    </View>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    role: state.userInfo.profile?.role,
    layoutId: ownProps.layoutId || ownProps.route?.params?.layoutId,
    options: state.options,
    lang: i18next.language,
    uiSettings: state.userInfo.uiSettings,
  };
};

export default connect(mapStateToProps, {
  SET_UI_SETTINGS_PROP,
  addDoc,
  MERGE_VALUES,
})(DailySiteDocument);

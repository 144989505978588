import "react";
import {
  TouchableOpacity,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import Icon from "./Icon";
import ButtonGroup from "./ButtonGroup";
import { Tooltip } from "./Tooltip";
import List from "./List";
import IconButton from "./IconButton";
import { showToast } from "../lib/helperFns";

const DropdownIcon = (props) => {
  return props.loadingIcon ? (
    <View
      style={{
        height: 30,
        width: 30,
        align: "center",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator
        color={props.endIconColor ?? props.colors.text}
        size={Platform.OS === "web" ? "small" : "large"}
        focusable={false}
        tabIndex="-1"
      />
    </View>
  ) : (
    <Icon
      name={"chevron-down"}
      size={30}
      color={props.endIconColor ?? props.colors.text}
    />
  );
};

export default function SelectInput(props) {
  const {
    t,
    theme,
    colors,
    value,
    setModalPicker,
    onValueChange,
    prop,
    values,
    hintWithPrefix, // adds hint for every value by combining them with hintWithPrefix + value
    valueTitles,
    wrapperStyle,
    numberOfLines,
    ellipsizeMode,
    invalidValueTitle,
    multi,
    unique,
    hint,
    translationPrefix,
  } = props;

  const _onSelect = (_value) => () => {
    if (!multi || !unique || !value || !value.includes(_value)) {
      if (!(!props.maxCount || !value || value.length < props.maxCount))
        showToast(t("maxAmountAddedInfo"), 5000, "accent");
      else onValueChange(prop, _value, multi);
    }
  };
  const onRemove = (index) => () => {
    onValueChange(prop, null, false, index);
  };
  const onClear = () => {
    onValueChange(prop, undefined, multi);
  };
  const onPress = (ev) => {
    setModalPicker({
      visible: true,
      textProp: "title",
      anchorEl: ev.currentTarget,
      data: values.map((_value, index) => {
        let _title = valueTitles?.[index] ?? _value;
        _title = props.dontTranslate ? _title : t(_title) || _title;
        return {
          title: _title,
          hint: hintWithPrefix ? t(hintWithPrefix + _value) : null,
          action: _onSelect(_value),
          startIcon: props.renderIcons ? _value : null,
          component: props.renderColors ? (
            <View
              style={{
                borderColor: colors.text,
                borderWidth: 2,
                height: 40,
                width: 200,
                backgroundColor: colors[_value],
              }}
            >
              <Text style={theme.text}>{_title}</Text>
            </View>
          ) : null,
        };
      }),
    });
  };

  const listTranslateFn = (value) => {
    if (valueTitles) {
      const valueIndex = values.findIndex((x) => x == value);
      let _title =
        value === undefined
          ? ""
          : valueIndex !== -1
          ? valueTitles[valueIndex]
          : invalidValueTitle
          ? t(invalidValueTitle) || invalidValueTitle
          : "";
      return props.dontTranslate ? _title : t(_title) || _title;
    } else {
      return props.dontTranslate
        ? value
        : t(translationPrefix ? translationPrefix + value : value) || value;
    }
  };

  const translatedValue = listTranslateFn(value);

  if (!multi && values.length < 4) {
    return (
      <ButtonGroup
        theme={theme}
        colors={colors}
        wrapperStyle={wrapperStyle}
        value={value}
        title={props.translatedTitle || t(props.title) || props.title}
        isRadio={true}
        onPress={_onSelect}
        onClear={props.noRemoval ? null : onClear}
        buttons={values.map((_value, index) => {
          let _title = valueTitles?.[index] ?? _value;
          _title = props.dontTranslate
            ? _title
            : t(translationPrefix ? translationPrefix + _title : _title) ||
              _title;
          return {
            title: _title,
            value: _value,
            hint: hintWithPrefix ? t(hintWithPrefix + _value) : null,
          };
        })}
        hint={hint}
      />
    );
  }

  return (
    <View
      pointerEvents={props.disabled ? "none" : "auto"}
      style={{
        width: "100%",
        alignItems: "flex-start",
        opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
        backgroundColor: colors.primary,
      }}
    >
      <View style={theme.titleRow}>
        {props.noRemoval || !onClear ? null : (
          <IconButton
            theme={props.theme}
            loading={false}
            onPress={onClear}
            backgroundColor={props.colors.lightBg}
            icon="close"
            color={props.colors.text}
          />
        )}

        {props.translatedTitle || props.title || props.error ? (
          <TouchableOpacity
            onPress={onPress}
            focusable={false}
            tabIndex="-1"
            style={theme.textWrapper}
          >
            <View style={theme.textContainer}>
              <Text
                style={[
                  theme.boldText,
                  {
                    color: props.error ? "red" : colors.text,
                  },
                ]}
              >
                {(props.translatedTitle ||
                  (props.dontTranslate
                    ? props.title
                    : t(props.title) || props.title)) +
                  (props.error ? ` - ${props.error}` : "")}
              </Text>
            </View>

            {props.hint || props.hintTable ? (
              <View style={theme.hPadding}>
                <Tooltip
                  flex={0}
                  icon
                  tip={props.hint}
                  hintTable={props.hintTable}
                  lang={props.lang}
                />
              </View>
            ) : null}

            {props.multi ? (
              <DropdownIcon
                colors={colors}
                endIconColor={props.endIconColor}
                loading={props.loadingIcon}
              />
            ) : null}
          </TouchableOpacity>
        ) : null}
      </View>

      {multi ? (
        <View style={theme.width100}>
          <List
            data={value}
            theme={theme}
            colors={colors}
            onRemovePress={onRemove}
            translateFn={listTranslateFn}
          />
        </View>
      ) : (
        <View
          style={[
            theme.rightRowContainer,
            {
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
            },
          ]}
        >
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TouchableOpacity style={theme.flexNoBg} onPress={onPress}>
              <Text
                numberOfLines={numberOfLines}
                ellipsizeMode={ellipsizeMode || "tail"}
                // style={[theme.textInput, theme.text]}
                style={[
                  theme.textInput,
                  props.boldText ? theme.boldText : theme.text,
                  {
                    borderWidth: 1,
                    borderColor: colors.lightBg,
                  },
                  Platform.OS === "web"
                    ? { outline: "none", width: "100%" }
                    : { width: "100%" },
                ]}
              >
                {translatedValue}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={props.loadingIcon ? null : onPress}
              focusable={false}
              tabIndex="-1"
            >
              <DropdownIcon
                colors={colors}
                endIconColor={props.endIconColor}
                loading={props.loadingIcon}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
}

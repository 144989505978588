import React from "react";
import { Platform, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";

import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import PassConfInput from "./PassConfInput";
import DualButtons from "./DualButtons";

export default class ForgotPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.passInput = React.createRef();
    this.passConfInput = React.createRef();
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theming) => (
          <>
            <View
              style={
                Platform.OS === "web"
                  ? theming.theme.signInContainer
                  : theming.theme.mobileSignInContainer
              }
            >
              <View style={theming.theme.signInFormContainer}>
                <EmailInput
                  t={this.props.t}
                  onSubmitEditing={() => this.passInput.current?.focus()}
                />

                <PasswordInput
                  t={this.props.t}
                  passStrengthBar={true}
                  ref={this.passInput}
                  onSubmitEditing={() => this.passConfInput.current?.focus()}
                  blurOnSubmit={false}
                />

                <PassConfInput
                  t={this.props.t}
                  ref={this.passConfInput}
                  onSubmitEditing={this.props.onRightButtonPress}
                />
              </View>

              {Platform.OS === "web" ? (
                <DualButtons
                  leftButtonTitle={this.props.t("cancel")}
                  onLeftButtonPress={this.props.onLeftButtonPress}
                  rightButtonTitle={this.props.t("saveAndSignIn")}
                  onRightButtonPress={this.props.onRightButtonPress}
                />
              ) : null}
            </View>

            <View style={theming.theme.ssoLoginContainer}>
              <View style={{ height: Platform.OS === "web" ? 38 : 0 }}></View>
            </View>

            {Platform.OS !== "web" ? (
              <DualButtons
                leftButtonTitle={this.props.t("cancel")}
                onLeftButtonPress={this.props.onLeftButtonPress}
                rightButtonTitle={this.props.t("saveAndSignIn")}
                onRightButtonPress={this.props.onRightButtonPress}
              />
            ) : null}
          </>
        )}
      </ThemeContext.Consumer>
    );
  }
}

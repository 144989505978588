import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View, Text } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "../components/Modal";
import SingleCheckBoxRow from "./SingleCheckBoxRow";
import ButtonGroup from "./ButtonGroup";
import IconButton from "./IconButton";
import ProgressBarElem from "./ProgressBar";

const styles = {
  modal: {
    height: "100%",
  },
  scrollWrapper: { minHeight: 36 },
};
export default function Alert(props) {
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.toggleAlert}
          onBackdropPress={props.toggleAlert}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          animationIn={props.animationIn}
          animationOut={props.animationOut}
          style={styles.modal}
        >
          <View style={theme.modalListContainer}>
            <View style={theme.titleWrapper}>
              <Text style={theme.title}>{props.title} </Text>
              <View style={theme.titleIconWrapper}>
                <IconButton
                  onPress={props.toggleAlert}
                  icon={"close"}
                  disabled={props.loading || props.disabled}
                />
              </View>
            </View>

            {props.text ? (
              <View
                style={[
                  theme.line,
                  {
                    borderBottomColor: colors.borderPrimary,
                  },
                ]}
              />
            ) : null}

            {props.text ? (
              <ScrollView style={styles.scrollWrapper}>
                <View style={theme.padding8}>
                  <Text style={theme.text}>{props.text}</Text>
                </View>
              </ScrollView>
            ) : null}

            {props.text && props.infoText ? (
              <View
                style={[
                  theme.line,
                  {
                    borderBottomColor: colors.borderPrimary,
                  },
                ]}
              />
            ) : null}

            {props.infoText ? (
              <ScrollView style={styles.scrollWrapper}>
                <View style={theme.padding8}>
                  <Text style={theme.caption}>{props.infoText}</Text>
                </View>
              </ScrollView>
            ) : null}

            {props.children}

            {props.dontShowAgainBox ? (
              <>
                <View
                  style={[
                    theme.line,
                    { borderBottomColor: colors.borderPrimary },
                  ]}
                />
                <View style={theme.buttonContainer}>
                  <SingleCheckBoxRow
                    flex={1}
                    boldTitle={true}
                    title={t("dontShowAgain")}
                    checked={dontShowAgain}
                    onCheckBoxPress={() => setDontShowAgain(!dontShowAgain)}
                  />
                </View>
              </>
            ) : null}

            <View
              style={[theme.line, { borderBottomColor: colors.borderPrimary }]}
            />

            {props.progress ? (
              <ProgressBarElem
                color={props.progress === 1 ? colors.success : colors.accent}
                styleAttr={"Horizontal"}
                indeterminate={false}
                progress={props.progress}
              />
            ) : null}

            <View style={theme.modalButtonContainer}>
              <ButtonGroup
                buttons={[
                  props.onLeftButtonPress || props.cancelButton
                    ? {
                        backgroundColor: colors.lightAccent,
                        color: colors.accent,
                        title: props.leftButtonTitle || t("cancel"),
                        onPress: () => {
                          props.toggleAlert();
                          if (props.onLeftButtonPress)
                            props.onLeftButtonPress(dontShowAgain);
                        },
                        loading: props.loading,
                        disabled:
                          props.disableLeftButton ||
                          props.loading ||
                          props.disabled,
                      }
                    : null,
                  props.onMiddleButtonPress
                    ? {
                        title: props.middleButtonTitle,
                        onPress: () => {
                          props.toggleAlert();
                          if (props.onMiddleButtonPress)
                            props.onMiddleButtonPress(dontShowAgain);
                        },
                        loading: props.loading,
                        disabled:
                          props.middleButtonDisabled ||
                          props.loading ||
                          props.disabled,
                      }
                    : null,
                  props.disableRightButton
                    ? null
                    : {
                        title: props.rightButtonTitle || t("yes"),
                        onPress: () => {
                          if (!props.dontCloseOnConfirm) props.toggleAlert();
                          if (props.onRightButtonPress)
                            props.onRightButtonPress(dontShowAgain);
                        },
                        loading: props.loading,
                        disabled: props.loading || props.disabled,
                        iconWhenLoading: props.iconWhenLoading,
                      },
                ]}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import { useEffect, useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Platform,
  FlatList,
  ScrollView,
} from "react-native";
import moment from "moment";
import { useTranslation } from "react-i18next";

import SimpleCheckbox from "../components/SimpleCheckbox";
import DataObjModal from "../components/DataObjModal";
import Modal from "../components/Modal";
import Icon from "../components/Icon";
import InputPicker from "../components/InputPicker";
import SingleCheckBoxRow from "../components/SingleCheckBoxRow";
import TextRow from "../components/TextRow";
import StretchButton from "../components/StretchButton";

import { ThemeContext } from "../theming/theme-context";

import { putWithToken, getWithToken } from "../lib/api";
import { validateEmail } from "../lib/validityCheckers";
import {
  validateSignerEmails,
  errorReport,
  getDocProps,
  getTranslatedText,
  getDocType,
  parseToFileName,
  getPdfLangValueKey,
} from "../lib/functions";
import {
  modalPickerOpts,
  fetchNetInfo,
  showToast,
  navigate,
  replaceTab,
  fullHp,
  fullWp,
} from "../lib/helperFns";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addDocSignature,
  removeSignature,
  setDocProp,
  REMOVE_FROM_DOC_EMAILS,
  PUSH_TO_DOC_EMAILS,
  modifySignatures,
  modifyCreatorSignature,
  REMOVE_CREATOR_SIGNATURE,
} from "../actions/DocsActions";
import { clearModifyDoc } from "../actions/ModifyDocActions";
import {
  signOut,
  ADD_EMAIL_CHECK_BOX,
  UPDATE_EMAIL_CHECK_BOXES,
  REMOVE_EMAIL_CHECK_BOX,
  SET_UI_SETTINGS_PROP,
  MERGE_USER_PROP,
} from "../actions/ProfileActions";
import { getCustomer } from "../reducers/index";
import ButtonGroup from "../components/ButtonGroup";
import IconButton from "../components/IconButton";
import i18next from "i18next";

function RecipientsAndSignersScreen(props) {
  const { t } = useTranslation();
  const { navigation, docId, doc, generateModularPDF } = props;

  if (!doc) {
    return null;
  }

  const { signatures = [] } = doc;

  const emails =
    (props.optionsEmailsProp
      ? props.options[props.optionsEmailsProp]
      : doc.emails) || [];

  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [visibleModal, setVisibleModal] = useState({
    label: t("newRecipient"),
    visible: false,
  });
  const [settingsOpened, setSettingsOpened] = useState(false);

  const fileSignatureRequest = doc?.layoutId === "docLayouts/0";

  const creatorSignatureModal = {
    inputs: [
      {
        key: "authMethod",
        title: t("signAuthMethod"),
        defaultValue: { text: { all: "EIdentify" } },
        options: {
          all: [
            "EIdentify",
            // "EmailCode",
            "Drawing",
            "None",
          ],
        },
        translateText: true,
        translationPrefix: "signAuthMethod",
        type: "picker",
        noContent: true,
        noRemoval: true,
        disabled: fileSignatureRequest,
      },
      {
        key: "title",
        title: t("title"),
        value: getTranslatedText({ all: t("creator") }, props.lang),
        type: "textField",
        validations: [{ fn: "checkIfFilled", errMsg: t("requiredValue") }],
      },
      {
        key: "printName",
        title: t("nameClarification"),
        defaultValue: "",
        type: "textField",
        validations: [{ fn: "checkIfFilled", errMsg: t("requiredValue") }],
        value:
          props.profile.name && props.profile.lName
            ? props.profile.name + " " + props.profile.lName
            : "",
        checksToBeVisible: [
          {
            valueKey: "0",
            compareMethod: "exact",
            validValues: ["Drawing", "None"],
          },
        ],
      },
    ],
    isFetching: false,
    onSave: addCreatorSignatureWithParams,
    visible: false,
  };
  const signatureModal = {
    inputs: [
      {
        key: "authMethod",
        title: t("signAuthMethod"),
        defaultValue: { text: { all: "EIdentify" } },
        options: {
          all: [
            "EIdentify",
            // "EmailCode",
            "Drawing",
            "None",
          ],
        },
        translateText: true,
        translationPrefix: "signAuthMethod",
        type: "picker",
        noContent: true,
        noRemoval: true,
        disabled: fileSignatureRequest,
      },
      {
        key: "title",
        title: t("title"),
        type: "textField",
        validations: [{ fn: "checkIfFilled", errMsg: t("requiredValue") }],
      },
      {
        key: "email",
        title: t("signerEmail"),
        type: "textField",
        validations: [
          { fn: "checkIfFilled", errMsg: t("requiredValue") },
          { fn: "validateEmail", errMsg: t("checkEmail") },
          {
            fn: checkForExistingSignerEmail,
            errMsg: t("emailAlreadyInSigners"),
          },
          {
            fn: checkForCreatorSignature,
            errMsg: t("triedToAddCreatorSignatureToSignatures"),
          },
        ],
        onSaveActions: ["toLowerCase", "removeWhitespace"],
        checksToBeVisible: [
          {
            valueKey: "0",
            compareMethod: "exact",
            validValues: ["EIdentify", "EmailCode"],
          },
        ],
      },
      {
        key: "drawingEmail",
        title: t("signerEmail"),
        type: "textField",
        validations: [
          { fn: "validateEmail", errMsg: t("checkEmail") },
          {
            fn: checkForExistingSignerEmail,
            errMsg: t("emailAlreadyInSigners"),
          },
          {
            fn: checkForCreatorSignature,
            errMsg: t("triedToAddCreatorSignatureToSignatures"),
          },
        ],
        onSaveActions: ["toLowerCase", "removeWhitespace"],
        onSaveKey: "email",
        checksToBeVisible: [
          {
            valueKey: "0",
            compareMethod: "exact",
            validValues: ["Drawing"],
          },
        ],
      },
      {
        key: "printName",
        title: t("nameClarification"),
        defaultValue: "",
        type: "textField",
        validations: [{ fn: "checkIfFilled", errMsg: t("requiredValue") }],
        checksToBeVisible: [
          {
            valueKey: "0",
            compareMethod: "exact",
            validValues: ["Drawing", "None"],
          },
        ],
      },
    ],
    isFetching: false,
    onSave: addDocSignatureWithParams,
    visible: false,
  };
  const checkBoxAddModal = {
    inputs: [
      {
        key: "title",
        title: t("title"),
        defaultValue: "",
        type: "textField",
        validations: [{ fn: "checkIfFilled", errMsg: t("requiredValue") }],
      },
      {
        key: "email",
        title: t("email"),
        defaultValue: "",
        type: "textField",
        validations: [
          { fn: "checkIfFilled", errMsg: t("requiredValue") },
          { fn: "validateEmail", errMsg: t("checkEmail") },
          {
            fn: checkForExistingCheckBoxEmail,
            errMsg: t("emailAlreadyAddedAsCheckbox"),
          },
        ],
        onSaveActions: ["toLowerCase", "removeWhitespace"],
      },
    ],
    isFetching: false,
    onSave: addEmailCheckBox,
    visible: false,
  };
  const [inputPicker, setInputPicker] = useState(signatureModal);

  const pushEmail = (email) => {
    (props.PUSH_TO_DOC_EMAILSFN || props.PUSH_TO_DOC_EMAILS)({ docId, email });
  };

  const toggleInputPicker = () => {
    setInputPicker({ ...inputPicker, visible: false });
  };

  const removeEmail = (email) => {
    (props.REMOVE_FROM_DOC_EMAILSFN || props.REMOVE_FROM_DOC_EMAILS)({
      docId,
      email,
    });
  };

  const handleMount = () => {
    if (!doc) {
      (props.navigateFn || navigate)(props.screenToGoBackTo, navigation);
    } else {
      // set technician as the creator for drawn signature
      if (
        (doc.creatorSignature?.authMethod === "Drawing" ||
          doc.creatorSignature?.authMethod === "None") &&
        props.technician &&
        !doc.creatorSignature.printName
      ) {
        (props.modifyCreatorSignatureFN || props.modifyCreatorSignature)({
          docId,
          newParams: {
            printName: props.technician,
          },
        });
      }

      if (!props.noInitialEmails) {
        // TODO enable this ???
        //if (props.customerEmail) pushEmail(props.customerEmail);
        const emailSelfByDefault = props.uiSettings?.emailSelfByDefault ?? true;
        const emailManagerByDefault =
          props.uiSettings?.emailManagerByDefault ?? true;
        if (emailSelfByDefault && props.userEmail) {
          pushEmail(props.userEmail);
        }
        if (
          emailManagerByDefault &&
          props.managerEmail &&
          props.managerEmail !== props.userEmail
        ) {
          pushEmail(props.managerEmail);
        }

        if (!props.disableSigners && signatures) {
          let removedSignaturesCount = 0;
          for (let index = 0; index < signatures.length; index++) {
            const signature = signatures[index];
            if (signature.preset) {
              if (signature.preset === "customer") {
                if (
                  props.customerEmail == props.userEmail &&
                  doc.creatorSignature
                ) {
                  const curTitle = getTranslatedText(
                    doc.creatorSignature.title,
                    props.lang
                  );
                  const titleToAdd = getTranslatedText(
                    signature.title,
                    props.lang
                  ).toLowerCase();
                  if (!curTitle.includes(titleToAdd)) {
                    (
                      props.modifyCreatorSignatureFN ||
                      props.modifyCreatorSignature
                    )({
                      docId,
                      newParams: {
                        title: {
                          text: {
                            [props.lang]:
                              getTranslatedText(
                                doc.creatorSignature.title,
                                props.lang
                              ) +
                              ", " +
                              getTranslatedText(
                                signature.title,
                                props.lang
                              ).toLowerCase(),
                          },
                        },
                      },
                    });
                    (props.removeSignatureFN || props.removeSignature)({
                      docId,
                      signatureIndex: index,
                    });
                    removedSignaturesCount++;
                  }
                } else if (props.customerEmail) {
                  (props.modifySignaturesFN || props.modifySignatures)({
                    docId,
                    signatureIndex: index - removedSignaturesCount,
                    newParams: { email: props.customerEmail },
                  });
                }
              } else if (signature.preset === "manager") {
                if (
                  props.managerEmail == props.userEmail &&
                  doc.creatorSignature
                ) {
                  const curTitle = getTranslatedText(
                    doc.creatorSignature.title,
                    props.lang
                  );
                  const titleToAdd = getTranslatedText(
                    signature.title,
                    props.lang
                  ).toLowerCase();
                  if (!curTitle.includes(titleToAdd)) {
                    (
                      props.modifyCreatorSignatureFN ||
                      props.modifyCreatorSignature
                    )({
                      docId,
                      newParams: {
                        title: {
                          text: {
                            [props.lang]:
                              getTranslatedText(
                                doc.creatorSignature.title,
                                props.lang
                              ) +
                              ", " +
                              getTranslatedText(
                                signature?.title,
                                props.lang
                              ).toLowerCase(),
                          },
                        },
                      },
                    });
                  }

                  (props.removeSignatureFN || props.removeSignature)({
                    docId,
                    signatureIndex: index,
                  });
                  removedSignaturesCount++;
                } else {
                  props.managerEmail
                    ? (props.modifySignaturesFN || props.modifySignatures)({
                        docId,
                        signatureIndex: index - removedSignaturesCount,
                        newParams: { email: props.managerEmail },
                      })
                    : null;
                }
              } else if (signature.preset === "ewManager") {
                if (
                  props.company?.ewManager?.email == props.userEmail &&
                  doc.creatorSignature
                ) {
                  const curTitle = getTranslatedText(
                    doc.creatorSignature.title,
                    props.lang
                  );
                  const titleToAdd = getTranslatedText(
                    signature.title,
                    props.lang
                  ).toLowerCase();
                  if (!curTitle.includes(titleToAdd)) {
                    (
                      props.modifyCreatorSignatureFN ||
                      props.modifyCreatorSignature
                    )({
                      docId,
                      newParams: {
                        title: {
                          text: {
                            [props.lang]:
                              getTranslatedText(
                                doc.creatorSignature.title,
                                props.lang
                              ) +
                              ", " +
                              getTranslatedText(
                                signature?.title,
                                props.lang
                              ).toLowerCase(),
                          },
                        },
                      },
                    });
                  }

                  (props.removeSignatureFN || props.removeSignature)({
                    docId,
                    signatureIndex: index,
                  });
                  removedSignaturesCount++;
                } else {
                  props.company?.ewManager?.email
                    ? (props.modifySignaturesFN || props.modifySignatures)({
                        docId,
                        signatureIndex: index - removedSignaturesCount,
                        newParams: { email: props.company?.ewManager?.email },
                      })
                    : null;
                }
              }
            }
          }
        }
      }
    }
  };

  const fetchSettings = (fn) => {
    getWithToken(
      "/users/uiSettings?keys=presetEmailCheckBoxes&keys=signDigitally"
    ).then((res) => {
      if (res.status === 200) {
        props.UPDATE_EMAIL_CHECK_BOXES(res.data.presetEmailCheckBoxes);
        props.MERGE_USER_PROP({
          prop: "uiSettings",
          value: res.data,
        });
      }
      if (fn) fn();
    });
  };

  useEffect(() => {
    if (props.role === "Trial") {
      handleMount();
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          fetchSettings(handleMount);
        } else {
          handleMount();
        }
      });
    }
  }, []);

  const removeOrAddToEmails = (email) => {
    if (emails.includes(email)) {
      removeEmail(email);
    } else {
      pushEmail(email);
    }
  };

  function modifySignaturesFromModal(params, index) {
    (props.modifySignaturesFN || props.modifySignatures)({
      docId,
      signatureIndex: index,
      newParams: { ...params, title: { text: { [props.lang]: params.title } } },
    });
    toggleInputPicker();
  }

  const onCheckBoxPress = (email) => {
    if (!props.signDigitally) {
      showToast(t("digitalSignRecipientErr"));
    } else {
      if (email) removeOrAddToEmails(email);
      else showToast(t("premadeEmailNotFound"));
    }
  };

  function setEmailFromModal(email) {
    if (!emails.includes(email)) {
      if (validateEmail(email)) {
        pushEmail(email.toLowerCase().replace(/[\s]/gi, ""));
        setVisibleModal({ visible: false });
      } else {
        setError(t("checkEmail"));
      }
    } else {
      setError(t("emailAlreadyAdded"));
    }
  }

  const setTempTxt = (text) => {
    if (error) setError(false);
    setEmail(text);
  };

  const toggleModalToEmails = () => {
    setVisibleModal({
      label: t("newRecipient"),
      visible: !visibleModal.visible,
      setText: setEmailFromModal,
    });
  };

  const previewDocs = () => {
    const date = moment().format("HH-mm-ss_DD-MM-YYYY");

    if (props.profile.role === "Trial") {
      (props.setDocPropFN || props.setDocProp)({
        docId,
        prop: "docRef",
        value:
          props.profile.name +
          "_" +
          (props.profile ? props.profile.id.split("/")[1] : "0") +
          "_" +
          date,
      });
      (props.setDocPropFN || props.setDocProp)({
        docId,
        prop: "technician",
        value: props.technician,
        fn: "4",
      });
    } else {
      (props.setDocPropFN || props.setDocProp)({
        docId,
        prop: "docRef",
        value:
          parseToFileName(
            doc.name || getDocType(doc.docType, doc.type, props.lang)
          ) +
          "_" +
          date,
      });
      (props.setDocPropFN || props.setDocProp)({
        docId,
        prop: "technician",
        value: props.technician,
        fn: "5",
      });
    }

    const errors = validateSignerEmails(
      props.disableSigners,
      signatures,
      t,
      props.lang
    );
    if (!errors) {
      if (!props.demo && Platform.OS === "web") {
        (props.navigateFn || navigate)(
          encodeURI(`PreviewAndSign?id=${encodeURIComponent(docId)}`),
          navigation,
          {
            generateModularPDF: generateModularPDF,
            generateNewPdf: true,
          }
        );
      } else {
        (props.replaceTabFn || replaceTab)("PreviewAndSign", navigation, {
          generateModularPDF: generateModularPDF,
          generateNewPdf: true,
        });
      }
    } else showToast(errors);
  };

  function addCreatorSignatureWithParams(newSignature) {
    (props.modifyCreatorSignatureFN || props.modifyCreatorSignature)({
      docId: props.docId,
      newParams: {
        ...newSignature,
        email: props.userEmail,
        title: { text: { [props.lang]: newSignature.title } },
      },
    });
    toggleInputPicker();
  }

  function addDocSignatureWithParams(newSignature) {
    (props.addDocSignatureFN || props.addDocSignature)({
      docId: props.docId,
      newSignature: {
        ...newSignature,
        title: { text: { [props.lang]: newSignature.title } },
      },
    });
    toggleInputPicker();
  }

  function addEmailCheckBox(obj) {
    if (props.role === "Trial") {
      props.ADD_EMAIL_CHECK_BOX({ obj });
      toggleInputPicker();
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          putWithToken(
            {
              key: "presetEmailCheckBoxes",
              value: props.presetEmailCheckBoxes
                ? props.presetEmailCheckBoxes.concat([obj])
                : [obj],
            },
            "/users/uiSettings"
          )
            .then(() => {
              props.ADD_EMAIL_CHECK_BOX({ obj });
              toggleInputPicker();
            })
            .catch(() => {
              props.ADD_EMAIL_CHECK_BOX({ obj });
              toggleInputPicker();
            });
        } else {
          props.ADD_EMAIL_CHECK_BOX({ obj });
          toggleInputPicker();
        }
      });
    }
  }

  function removeEmailCheckBox(obj) {
    if (props.role === "Trial") {
      props.REMOVE_EMAIL_CHECK_BOX({ obj });
    } else {
      try {
        fetchNetInfo().then((state) => {
          if (state.isConnected) {
            putWithToken(
              {
                key: "presetEmailCheckBoxes",
                value: props.presetEmailCheckBoxes
                  ? props.presetEmailCheckBoxes.filter(
                      (box) => box.email !== obj.email
                    )
                  : [],
              },
              "/users/uiSettings"
            )
              .then(() => {
                props.REMOVE_EMAIL_CHECK_BOX({ obj });
              })
              .catch(() => {
                props.REMOVE_EMAIL_CHECK_BOX({ obj });
              });
          } else {
            props.REMOVE_EMAIL_CHECK_BOX({ obj });
          }
        });
      } catch (error) {
        errorReport({
          error,
          errorInFn: "removeEmailCheckBox",
          errorInScreen: "RecipientsAndSignersScreen",
        });
      }
    }
  }

  function checkForExistingCheckBoxEmail(email) {
    return !props.presetEmailCheckBoxes?.some((x) => x.email === email);
  }

  function checkForCreatorSignature(email) {
    return email !== props.userEmail;
  }

  function checkForExistingSignerEmail(email, inputs, validationProps) {
    return !signatures.some(
      (x, i) =>
        (!validationProps || i !== validationProps.index) && x.email === email
    );
  }

  function removeDocSignature(index, item) {
    if (item.creatorSignature) {
      (props.REMOVE_CREATOR_SIGNATUREFN || props.REMOVE_CREATOR_SIGNATURE)({
        docId: props.docId,
      });
    } else {
      (props.removeSignatureFN || props.removeSignature)({
        docId: props.docId,
        signatureIndex: index,
      });
    }
  }

  function getEmailCheckBoxes() {
    let arr = [
      { title: t("ownEmail"), email: props.userEmail },
      { title: t("orderer"), email: props.customerEmail },
      { title: t("manager"), email: props.managerEmail },
    ];

    if (props.presetEmailCheckBoxes) {
      props.presetEmailCheckBoxes
        .sort((a, b) =>
          getTranslatedText(a.title, props.lang).localeCompare(
            getTranslatedText(b.title, props.lang),
            undefined,
            {
              numeric: true,
              sensitivity: "base",
            }
          )
        )
        .forEach((x) => {
          arr.push({ ...x, removable: true });
        });
    }
    return arr;
  }

  const handleBackToEditing = () => {
    if (Platform.OS === "web") {
      (props.navigateFn || navigate)(
        `create?docId=${encodeURIComponent(props.doc.id)}`,
        navigation
      );
    } else {
      (props.navigateFn || navigate)("create", props.navigation);
    }
  };

  const setUiSettingsProp = (key, value) => {
    if (props.profile.role !== "Trial") {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          putWithToken(
            {
              key: key,
              value: value,
            },
            "/users/uiSettings"
          ).then(() => {
            props.SET_UI_SETTINGS_PROP({
              prop: key, //`${OS}_${key}`,
              value: value,
            });
          });
        }
      });
    } else {
      props.SET_UI_SETTINGS_PROP({
        prop: key, //`${OS}_${key}`,
        value: value,
      });
    }
  };

  const openSettings = () => {
    setSettingsOpened(true);
  };

  const closeSettings = () => {
    setSettingsOpened(false);
  };

  const signers = props.doc.creatorSignature
    ? [
        {
          ...props.doc.creatorSignature,
          creatorSignature: true,
        },
      ].concat(signatures)
    : signatures;
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={theme.container}>
          <View
            style={
              Platform.OS !== "web"
                ? theme.fill
                : {
                    flex: 1,
                    backgroundColor: colors.primary,
                    width: "100%",
                    maxWidth: 600,
                  }
            }
          >
            {!props.disableSigners && signatures ? (
              <View style={[theme.flex, { flex: 0.6 }]}>
                <View style={theme.titleRow}>
                  <View style={theme.textContainer}>
                    <Text style={theme.title}>{`${t("signers")} ${
                      signers.length
                    }`}</Text>
                  </View>
                  <TouchableOpacity
                    style={theme.textPadding}
                    onPress={openSettings}
                  >
                    <Icon name={"cog"} size={32} color={colors.text} />
                  </TouchableOpacity>
                </View>

                <View style={theme.fatLine} />

                <View style={theme.flex}>
                  <FlatList
                    keyboardShouldPersistTaps="handled"
                    initialNumToRender={10}
                    data={signers}
                    keyExtractor={(item, index) =>
                      "SignaturesList" +
                      getTranslatedText(item.title, props.lang) +
                      index
                    }
                    ItemSeparatorComponent={() => <View style={theme.line} />}
                    ListFooterComponent={() => <View style={theme.line} />}
                    renderItem={({ item, index }) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          name={
                            item.creatorSignature
                              ? "account"
                              : "account-multiple-plus"
                          }
                          size={32}
                          color={colors.accent}
                        />
                        <Icon
                          name={
                            item.authMethod === "Drawing"
                              ? "draw"
                              : item.authMethod === "None"
                              ? "signature-text"
                              : "email"
                          }
                          size={32}
                          color={colors.accent}
                        />
                        <TextRow
                          // disabled={item.creatorSignature}
                          title={getTranslatedText(item.title, props.lang)}
                          value={
                            item.authMethod === "Drawing" ||
                            item.authMethod === "None"
                              ? item.printName
                              : item.printName || item.email || "-"
                          }
                          numberOfLines={1}
                          onPress={() => {
                            const modal = item.creatorSignature
                              ? creatorSignatureModal
                              : signatureModal;
                            setInputPicker({
                              ...modal,
                              validationProps: {
                                index: props.doc.creatorSignature
                                  ? index - 1
                                  : index,
                              },
                              inputs: modal.inputs.map((x) => ({
                                ...x,
                                value: getTranslatedText(
                                  item[x.key],
                                  props.lang
                                ),
                              })),
                              onSave: item.creatorSignature
                                ? modal.onSave
                                : (payload) => {
                                    modifySignaturesFromModal(
                                      payload,
                                      props.doc.creatorSignature
                                        ? index - 1
                                        : index
                                    );
                                  },
                              visible: true,
                            });
                          }}
                        />
                        <TouchableOpacity
                          onPress={() =>
                            removeDocSignature(
                              props.doc.creatorSignature ? index - 1 : index,
                              item
                            )
                          }
                        >
                          <Icon name="close" size={32} color={colors.text} />
                        </TouchableOpacity>
                      </View>
                    )}
                  />
                </View>

                <View style={theme.buttonContainer}>
                  {doc.creatorSignature ? null : (
                    <StretchButton
                      title={t("addOwnSignature")}
                      onPress={() => {
                        setInputPicker({
                          ...creatorSignatureModal,
                          visible: true,
                        });
                      }}
                    />
                  )}
                  <StretchButton
                    title={t("newSigner")}
                    onPress={() => {
                      setInputPicker({
                        ...signatureModal,
                        visible: true,
                      });
                    }}
                  />
                </View>
              </View>
            ) : null}

            <View style={theme.flex}>
              <View style={theme.titleRow}>
                <View style={theme.textContainer}>
                  <Text style={theme.title}>{`${t("docReceivers")} ${
                    emails.length
                  }`}</Text>
                </View>
              </View>

              <View style={theme.fatLine} />

              <View style={theme.flex}>
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  initialNumToRender={10}
                  data={emails}
                  keyExtractor={(item, index) => "EmailList" + item + index}
                  ItemSeparatorComponent={() => <View style={theme.line} />}
                  renderItem={({ item }) => (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          alignSelf: "stretch",
                          justifyContent: "center",
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        <Text style={theme.text}>{item}</Text>
                      </View>

                      <IconButton
                        icon="trash-can"
                        color={colors.accent}
                        onPress={() => {
                          removeEmail(item);
                        }}
                      />
                    </View>
                  )}
                />
              </View>

              <View style={theme.buttonContainer}>
                <ButtonGroup
                  buttons={[
                    {
                      title: t("newCheckbox"),
                      onPress: () => {
                        setInputPicker({
                          ...checkBoxAddModal,
                          visible: true,
                        });
                      },
                    },
                    {
                      title: t("newRecipient"),
                      onPress: () => {
                        toggleModalToEmails();
                      },
                    },
                  ]}
                />
              </View>

              <View style={theme.flex}>
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  initialNumToRender={10}
                  data={getEmailCheckBoxes()}
                  keyExtractor={(item, index) =>
                    "SignaturesList" +
                    getTranslatedText(item.title, props.lang) +
                    index
                  }
                  ItemSeparatorComponent={() => <View style={theme.line} />}
                  ListFooterComponent={() => <View style={theme.line} />}
                  renderItem={({ item }) => (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.removable ? (
                        <View style={{ flexDirection: "row" }}>
                          <TouchableOpacity
                            onPress={() => removeEmailCheckBox(item)}
                          >
                            <Icon
                              name="close"
                              size={32}
                              color={colors.accent}
                            />
                          </TouchableOpacity>
                        </View>
                      ) : null}
                      <SingleCheckBoxRow
                        boldTitle={true}
                        title={getTranslatedText(item.title, props.lang)}
                        checked={emails.includes(item.email)}
                        onCheckBoxPress={() => onCheckBoxPress(item.email)}
                      />
                    </View>
                  )}
                />
              </View>
            </View>

            {props.disableButtons ? null : (
              <View style={theme.buttonContainer}>
                <ButtonGroup
                  buttons={[
                    {
                      backgroundColor: colors.lightAccent,
                      color: colors.accent,
                      title: t("back"),
                      onPress: () => handleBackToEditing(),
                    },
                    {
                      title: t("preview"),
                      onPress: () => previewDocs(),
                      endIcon: "file-eye-outline",
                    },
                  ]}
                />
              </View>
            )}

            <DataObjModal
              err={visibleModal.err}
              label={visibleModal.label}
              visibility={visibleModal.visible}
              toggleModal={toggleModalToEmails}
              setTempObjName={setTempTxt}
              setDataObj={() => visibleModal.setText(email, visibleModal)}
              name={email}
              keyboardType={"email-address"}
              selectTextOnFocus={true}
            />
            <InputPicker
              {...inputPicker}
              inputsOnly
              onToggleModal={toggleInputPicker}
              lang={props.lang}
              fullWidth={Platform.OS === "web" ? props.pageW : fullWp(100)}
              fullHeight={Platform.OS === "web" ? props.pageH : fullHp(100)}
            />
            <Modal
              {...modalPickerOpts}
              isVisible={settingsOpened}
              onBackButtonPress={closeSettings}
              onBackdropPress={closeSettings}
              style={{
                height: "100%",
              }}
            >
              <View style={theme.modalListContainer}>
                <ScrollView>
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 8,
                      alignSelf: "flex-end",
                    }}
                    onPress={closeSettings}
                  >
                    <Icon name={"close"} size={32} color={colors.text} />
                  </TouchableOpacity>

                  {[
                    {
                      key: "emailSelfByDefault",
                      title: t("emailSelfByDefault"),
                      value: props.uiSettings?.emailSelfByDefault ?? true,
                    },
                    {
                      key: "emailManagerByDefault",
                      title: t("emailManagerByDefault"),
                      value: props.uiSettings?.emailManagerByDefault ?? true,
                    },
                    {
                      key: "signDigitally",
                      title: t("digitalSignature"),
                      value:
                        props.uiSettings?.signDigitally ?? props.signDigitally,
                    },
                  ].map((x, i) => (
                    <View key={x.key} style={theme.flex}>
                      <SimpleCheckbox
                        key={"drawerItem" + i}
                        id={"drawerItem" + i}
                        theme={theme}
                        colors={colors}
                        item={x}
                        setValue={setUiSettingsProp}
                      />
                    </View>
                  ))}
                </ScrollView>

                <View style={theme.buttonContainer}>
                  <StretchButton title={t("close")} onPress={closeSettings} />
                </View>
              </View>
            </Modal>
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearModifyDoc,
      signOut,
      REMOVE_EMAIL_CHECK_BOX,
      ADD_EMAIL_CHECK_BOX,
      UPDATE_EMAIL_CHECK_BOXES,
      // doc modify actions
      addDocSignature,
      removeSignature,
      setDocProp,
      REMOVE_FROM_DOC_EMAILS,
      PUSH_TO_DOC_EMAILS,
      modifySignatures,
      modifyCreatorSignature,
      REMOVE_CREATOR_SIGNATURE,
      SET_UI_SETTINGS_PROP,
      MERGE_USER_PROP,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  let docId;
  let docToModify;
  let docLayout;

  if (ownProps.doc) {
    docId = ownProps.doc.id;
    docToModify = ownProps.doc;
    docLayout = state.options.layouts?.[docToModify?.layoutId];
  } else {
    const docProps = getDocProps(
      ownProps.viewUnfinishedLayout,
      state,
      true,
      ownProps.docId ?? ownProps.doc?.id,
      ownProps.layoutId
    );
    docId = docProps.docId;
    docToModify = docProps.docToModify;
    docLayout = docProps.docLayout;
  }

  const navParams =
    Platform.OS == "web"
      ? ownProps?.navigation?.location?.state || {}
      : ownProps?.route?.params || {};
  const _customer = getCustomer(
    docToModify,
    docLayout?.versions?.[docToModify.layoutVersion],
    state.options.customers
  );

  let _company = state.userInfo.company;
  let _profile = state.userInfo.profile;
  let _manager = state.userInfo.manager;
  if (ownProps.demo) {
    _company = ownProps.company;
    _profile = ownProps.profile;
    _manager = ownProps.manager;
  }

  return {
    uiSettings: state.userInfo.uiSettings,
    generateModularPDF: navParams.generateModularPDF,
    doc: docToModify,
    lang:
      (docToModify && docToModify.values[getPdfLangValueKey(_profile)]) ||
      i18next.language,
    customerEmail: _customer?.email || "",
    customerName:
      _customer?.lName && _customer?.name
        ? _customer.lName + " " + _customer.name
        : "",
    docId: docId,
    technician: _profile.name + " " + _profile.lName,
    role: _profile.role,
    presetEmailCheckBoxes: state.userInfo.presetEmailCheckBoxes,
    signDigitally: state.userInfo.uiSettings?.signDigitally ?? true,
    userEmail: _profile.email,
    managerEmail: _manager?.email,
    profile: _profile,
    manager: _manager,
    company: _company,
    options: state.options,
  };
};

const ConnectedRecipientsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientsAndSignersScreen);
export default ConnectedRecipientsScreen;

import React, { useState, useRef, useEffect } from "react";
import { TouchableOpacity, Text, View, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import ButtonGroup from "./ButtonGroup";
import Modal from "./Modal";
import TextInput from "./TextInput";
import Icon from "./Icon";

// type Props = {
//   isDualInput: Boolean,
//   visibility: Boolean,
//   measurementKey: String,
//   measurementKey2: String,
//   title: String,
//   secondaryTitle: String,
//   unit: String,
//   unit2: String,
//   toggleModal: any,
//   addValue: any,
//   addDualInputValues: any,
//   measurementsPerGroup: Boolean,
//   switchMeasOrGroup: Function,
//   dataObjKey: String,
//   dataObj: Object,
//   values: any,
//   values2: any,
// };
export default function ValueInputModal(props) {
  const { t } = useTranslation();
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const textInput1 = useRef(null);
  const textInput2 = useRef(null);

  useEffect(() => {
    if (props.visibility && textInput1?.current) {
      setTimeout(() => {
        textInput1.current?.focus();
      }, 500);
    }
  }, [props.visibility]);

  const resetValues = () => {
    setValue1(null);
    setValue2(null);
  };

  const addDualValues = () => {
    const _value1 = parseValue(value1);
    const _value2 = parseValue(value2);
    props.addDualInputValues(
      _value1,
      _value2,
      props.measurementKey,
      props.measurementKey2
    );
    resetValues();
    textInput1.current?.focus();
  };

  const addValue = () => {
    const _value1 = parseValue(value1);
    props.addValue(_value1, props.measurementKey);
    resetValues();
  };

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  const parseValue = (value) => {
    if (value)
      return roundToTwo(
        parseFloat(
          value
            .toString()
            .replace(/[^0-9.,\s]/g, "")
            .replace(/,/g, ".")
        )
      );
    else return null;
  };

  const renderModalContent = (theme, colors, orientation) => {
    if (props.isDualInput) {
      return (
        <View style={[theme.textInputWrapper, { paddingBottom: 8 }]}>
          <View
            style={[
              theme.valueInputModalInput,
              {
                justifyContent: "space-between",
                borderTopWidth: 1,
              },
            ]}
          >
            <TextInput
              orientation={orientation}
              disableFullscreenUI={false}
              style={[theme.modalTextInput, theme.dualInputModalTextInput]}
              placeholder={"0"}
              placeholderTextColor={"#aaa"}
              keyboardType={"decimal-pad"}
              maxLength={12}
              selectTextOnFocus={true}
              ref={textInput1}
              autoFocus={Platform.OS === "web"}
              value={(value1 || "").toString()}
              blurOnSubmit={false}
              onChangeText={(text) => {
                let value = text.replace(/[^\d.,]/g, "");
                setValue1(value);
              }}
              onSubmitEditing={() => textInput2.current?.focus()}
            />
            <Text style={theme.text}> {props.unit}</Text>

            <View style={[theme.divider, { borderColor: colors.text }]} />

            <TextInput
              orientation={orientation}
              disableFullscreenUI={false}
              style={[theme.modalTextInput, theme.dualInputModalTextInput]}
              placeholder={"0"}
              placeholderTextColor={"#aaa"}
              keyboardType={"decimal-pad"}
              maxLength={12}
              selectTextOnFocus={true}
              ref={textInput2}
              value={(value2 || "").toString()}
              onChangeText={(text) => {
                let value = text.replace(/[^\d.,]/g, "");
                setValue2(value);
              }}
              blurOnSubmit={false}
              onSubmitEditing={addDualValues}
            />
            <Text style={theme.text}> {props.unit2}</Text>
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={[theme.textInputWrapper, { paddingBottom: 8, paddingTop: 8 }]}
        >
          <View style={[theme.valueInputModalInput, { borderTopWidth: 1 }]}>
            <TextInput
              orientation={orientation}
              disableFullscreenUI={false}
              style={[
                theme.modalTextInput,
                { textAlign: "right" },
                Platform.OS === "web" ? { outline: "none" } : {},
              ]}
              placeholder={"0"}
              placeholderTextColor={"#aaa"}
              keyboardType={"decimal-pad"}
              maxLength={12}
              ref={textInput1}
              selectTextOnFocus={true}
              autoFocus={Platform.OS === "web"}
              value={(value1 || "").toString()}
              onChangeText={(text) => {
                let value = text.replace(/[^\d.,]/g, "");
                setValue1(value);
              }}
              blurOnSubmit={false}
              onSubmitEditing={addValue}
            />
            <Text style={theme.text}> {props.unit}</Text>
          </View>
        </View>
      );
    }
  };

  const { worstValue, values } = props.values ?? {
    worstValue: 0,
    values: [],
  };
  const worstValue2 = props.values2?.worstValue || 0;
  const values2 = props.values2?.values || [];
  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visibility}
          onBackButtonPress={props.toggleModal}
          onBackdropPress={props.toggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={[theme.modalInputContainer, { paddingBottom: 0 }]}>
            <View style={{ alignItems: "center", paddingBottom: 8 }}>
              <View
                style={{
                  borderRightWidth: 1,
                  borderRightColor: colors.borderPrimary,
                  borderLeftWidth: 1,
                  borderLeftColor: colors.borderPrimary,
                  flexDirection: "row",
                  justifyContent: props.extraDataObjProps
                    ? "center"
                    : "space-between",
                  alignItems: "center",
                }}
              >
                {props.extraDataObjProps ? null : (
                  <TouchableOpacity
                    onPress={() =>
                      props.switchMeasOrGroup(
                        true,
                        props.measurementKey,
                        props.dataObjKey
                      )
                    }
                  >
                    <View
                      style={[
                        {
                          width: 36,
                          height: 36,
                          borderBottomRightRadius: 8,
                          paddingTop: 0,
                          paddingBottom: 0,
                          backgroundColor: colors.primary,
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      ]}
                    >
                      <Icon name={"arrow-left"} size={24} color={colors.text} />
                    </View>
                  </TouchableOpacity>
                )}

                <View
                  style={[
                    {
                      height: 36,
                      paddingTop: 0,
                      paddingBottom: 0,
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  ]}
                >
                  <Text style={theme.text}>{props.id}</Text>
                  <Text style={theme.text}>
                    {props.isDualInput
                      ? props.title + " / " + props.secondaryTitle
                      : props.title}
                  </Text>
                </View>
                {props.extraDataObjProps ? null : (
                  <TouchableOpacity
                    onPress={() =>
                      props.switchMeasOrGroup(
                        false,
                        props.measurementKey,
                        props.dataObjKey
                      )
                    }
                  >
                    <View
                      style={[
                        {
                          width: 36,
                          height: 36,
                          borderBottomLeftRadius: 8,
                          paddingTop: 0,
                          paddingBottom: 0,
                          backgroundColor: colors.primary,
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      ]}
                    >
                      <Icon
                        name={"arrow-right"}
                        size={24}
                        color={colors.text}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              </View>

              {(values && values.length !== 0) ||
              (values2 && values2.length !== 0) ? (
                <View style={{ flexDirection: "row" }}>
                  <Text style={theme.text}>
                    {values2 && values2.length > 0
                      ? `${t("worstValues")}: `
                      : `${t("worstValue")}: `}
                  </Text>
                  <Text style={theme.text}>
                    {!isNaN(worstValue) ? worstValue + " " + props.unit : "-"}
                  </Text>

                  {props.isDualInput ? (
                    <React.Fragment>
                      <Text style={theme.text}>{" / "}</Text>
                      <Text style={theme.text}>
                        {!isNaN(worstValue2)
                          ? worstValue2 + " " + props.unit2
                          : "-"}
                      </Text>
                    </React.Fragment>
                  ) : null}
                </View>
              ) : null}
            </View>

            {renderModalContent(theme, colors, orientation)}

            <View style={theme.modalButtonContainer}>
              <ButtonGroup
                buttons={[
                  {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: t("close"),
                    onPress: () => props.toggleModal(),
                  },
                  {
                    title: t("clear"),
                    onPress: () => resetValues(),
                  },
                  {
                    title: props.isDualInput ? t("addValues") : t("addValue"),
                    onPress: () => {
                      if (props.isDualInput) addDualValues();
                      else addValue();
                    },
                  },
                ]}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { ThemeContext } from "../theming/theme-context";
import ItemContentRow from "./ItemContentRow";
import {
  getCustomerName,
  getSiteName,
  getTranslatedText,
} from "../lib/functions";

/**
 * Render PickerObjectContent
 * @param {Object} id props.id id
 * @param {Object} inputs pickerObject layout object
 * @param {Object} lang lang to use
 * @param {Object} options options obj containing pickerObjects
 * @param {Object} prop prop to use for finding pickerObject in options
 */
function PickerObjectContent(props) {
  let _index = 0;
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) =>
        props.inputs.map((input) => {
          const inputToUse = input;
          const titleToUse = getTranslatedText(inputToUse.title, props.lang);
          const valueObj =
            props.options[props.prop]?.[props.id] || props.valueObj;
          if (
            inputToUse.noContent ||
            (props.prop === "customers" &&
              valueObj?.companyId &&
              inputToUse.key === "lName") ||
            (props.prop === "customers" &&
              valueObj?.lName &&
              inputToUse.key === "companyId")
          ) {
            return null;
          } else if (inputToUse.isArr) {
            return valueObj?.[inputToUse.key]?.map((arrItem, arrItemIndex) => {
              _index++;
              return (
                <ItemContentRow
                  key={"ItemContents" + _index}
                  colors={colors}
                  theme={theme}
                  index={_index}
                  textLeft={arrItemIndex === 0 ? titleToUse : ""}
                  textRight={arrItem["name"]}
                />
              );
            });
          } else {
            _index++;
            return (
              <ItemContentRow
                key={"ItemContents" + _index}
                colors={colors}
                theme={theme}
                index={_index}
                textLeft={titleToUse}
                textRight={
                  inputToUse.key === "customerId"
                    ? getCustomerName(
                        props.options?.customers?.[valueObj?.[inputToUse.key]]
                      )
                    : inputToUse.key === "siteId"
                    ? getSiteName(
                        props.options?.sites?.[valueObj?.[inputToUse.key]]
                      )
                    : valueObj?.[inputToUse.key]
                }
              />
            );
          }
        })
      }
    </ThemeContext.Consumer>
  );
}

export default PickerObjectContent;

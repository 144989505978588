import React from "react";
import { View, ScrollView, Platform } from "react-native";
import { ThemeContext } from "../theming/theme-context";

import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import PassConfInput from "./PassConfInput";
import DualButtons from "./DualButtons";
import AgreementCheckBoxRow from "./AgreementCheckBoxRow";

export default class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.arr = [];
    this.state = { dynamicIndex: 0 };
    this.scrollview = React.createRef();
    this.passInput = React.createRef();
    this.passConfInput = React.createRef();
  }

  getInputs = (theme) => [
    this.props.thirdParty ? null : (
      <EmailInput
        t={this.props.t}
        style={theme.verticalPadding}
        onSubmitEditing={() => this.passInput.current?.focus()}
      />
    ),

    this.props.thirdParty ? null : (
      <PasswordInput
        t={this.props.t}
        style={theme.verticalPadding}
        label={`${this.props.t("newPassword")} *`}
        passStrengthBar={true}
        ref={this.passInput}
        onSubmitEditing={() => this.passConfInput.current?.focus()}
        blurOnSubmit={false}
      />
    ),

    this.props.thirdParty ? null : (
      <PassConfInput
        t={this.props.t}
        style={theme.verticalPadding}
        label={`${this.props.t("passwordConf")} *`}
        ref={this.passConfInput}
        onSubmitEditing={this.props.onRightButtonPress}
      />
    ),

    <View style={{ padding: 16 }}>
      <AgreementCheckBoxRow
        prop={"marketingEmails"}
        text={this.props.t("marketingEmailAgreement")}
      />
      <AgreementCheckBoxRow
        prop={"contactByPhone"}
        text={this.props.t("phoneCallAgreement")}
      />
      <AgreementCheckBoxRow
        prop={"criticalEmails"}
        text={this.props.t("importantEmailAgreement")}
      />
      <AgreementCheckBoxRow
        prop={"gdpr"}
        text={`${this.props.t("haveReadAndAgree")} `}
      />
      <AgreementCheckBoxRow
        prop={"tos"}
        text={`${this.props.t("haveReadAndAgree")} `}
      />
    </View>,
  ];

  render() {
    let { theme } = this.context;
    return (
      <>
        <View
          style={
            Platform.OS === "web" ? theme.registrationContainer : { flex: 3 }
          }
        >
          <View
            style={
              Platform.OS === "web"
                ? { flex: 5, paddingTop: 20, paddingBottom: 20 }
                : { flex: 5, padding: 20 }
            }
          >
            <ScrollView ref={this.scrollview}>
              {this.getInputs(theme).map((item, key) => (
                //key is the index of the array
                //item is the single item of the array
                <View
                  style={{ flex: 1 }}
                  key={key}
                  onLayout={(event) => {
                    const layout = event.nativeEvent.layout;
                    this.arr[key] = layout.y;
                  }}
                >
                  {item}
                </View>
              ))}
            </ScrollView>
          </View>

          <DualButtons
            leftButtonTitle={this.props.t("cancel")}
            onLeftButtonPress={this.props.onLeftButtonPress}
            rightButtonTitle={this.props.t("saveAndSignIn")}
            onRightButtonPress={this.props.onRightButtonPress}
          />
        </View>
      </>
    );
  }
}

ActivateAccount.contextType = ThemeContext;

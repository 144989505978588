import { useState, useCallback } from "react";
import {
  ScrollView,
  Platform,
  Text,
  View,
  TouchableOpacity,
} from "react-native";
import { useTranslation } from "react-i18next";
import StretchButton from "./StretchButton";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleForm from "./SimpleForm";
import MenuComponent from "./MenuComponent";
import SelectInput from "./SelectInput";
import CrashTestButtons from "./CrashTestButtons";
import AddableList from "./AddableList";
import Icon from "./Icon";
import Modal from "./Modal";
import {
  fullHp,
  fullWp,
  getAppVersion,
  modalPickerOpts,
} from "../lib/helperFns";

const styles = {
  closeButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
};

export default function Settings({
  theme,
  colors,
  role,
  noCloseButton,
  noVersionInfo,
  closeDrawer,
  settings,
  setValue,
  children,
  lang,
  appendChildren,
}) {
  // TODO fetch userInfo and uiSettings
  const { t } = useTranslation();
  const [modalPicker, setModalPicker] = useState({ visible: false });
  const [modalForm, setModalForm] = useState({ visible: false });
  const closeMenu = () => {
    setModalPicker({ visible: false });
  };
  const closeModal = useCallback(
    () => () => {
      setModalForm({ visible: false });
    },
    []
  );
  // {role === "Admin" || role === "SuperAdmin" ? (
  //   <Text style={theme.text}>
  //     {"hermesEnabled" + !!global?.HermesInternal}
  //   </Text>
  // ) : null}
  return (
    <View style={theme.flex}>
      {noCloseButton ? null : (
        <TouchableOpacity style={styles.closeButton} onPress={closeDrawer}>
          <Icon name={"close"} size={32} color={colors.text} />
        </TouchableOpacity>
      )}

      <ScrollView>
        {appendChildren ? null : children}

        {noVersionInfo ? null : (
          <View
            style={{
              padding: Platform.OS !== "web" ? 8 : 24,
            }}
          >
            <Text
              style={[
                theme.text,
                {
                  padding: 8,
                },
              ]}
            >
              {t("versionNum") + " " + getAppVersion()}
            </Text>
          </View>
        )}

        {settings?.map((x, i) => (
          <View
            key={"settings" + i}
            style={{
              padding: Platform.OS !== "web" ? 8 : 24,
            }}
          >
            {x.type === "checkBox" ? (
              <SimpleCheckbox
                theme={theme}
                colors={colors}
                item={x}
                setValue={x.setValue ?? setValue}
              />
            ) : x.type === "button" ? (
              <StretchButton
                backgroundColor={x.backgroundColor}
                color={x.textColor}
                onPress={x.setValue ?? setValue}
                title={x.title}
                icon={x.icon}
              />
            ) : x.type === "select" ? (
              <SelectInput
                t={t}
                theme={theme}
                colors={colors}
                title={x.title}
                value={x.value ?? x.defaultValue}
                setModalPicker={setModalPicker}
                onValueChange={x.setValue ?? setValue}
                prop={x.key}
                values={x.values}
                valueTitles={x.valueTitles}
                noRemoval={x.noRemoval}
                wrapperStyle={{}}
              />
            ) : x.type === "custom" ? (
              x.component()
            ) : x.type === "modalFormList" ? (
              <AddableList
                onAdd={() =>
                  setModalForm({
                    visible: true,
                    inputs: x.inputs,
                    onSave: (values) => {
                      x.onSave(values, closeModal("modalForm"));
                    },
                    onSaveTitle: t(x.onSaveTitle),
                  })
                }
                title={x.title}
                boldTitle
                values={x.values}
                onRefresh={x.onRefresh}
                refreshing={x.refreshing} //fakeRefresh}
                onRemove={x.onRemove}
                getItemText={x.getItemText}
                itemTextProps={x.itemTextProps}
                onItemPress={(text, item) => {
                  setModalForm({
                    visible: true,
                    inputs: x.inputs.map((x) => ({ ...x, value: item[x.key] })),
                    onSave: (values) => {
                      x.onSave(values, closeModal("modalForm"));
                    },
                    onSaveTitle: t(x.onSaveTitle),
                  });
                }}
              />
            ) : null}
          </View>
        ))}

        {role === "Admin" || role === "SuperAdmin" ? (
          <CrashTestButtons theme={theme} colors={colors} />
        ) : null}

        {appendChildren ? children : null}
      </ScrollView>

      {modalForm?.visible ? (
        <Modal
          {...modalPickerOpts}
          isVisible={true}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          onClose={closeModal("modalForm")}
          onBackButtonPress={closeModal("modalForm")}
        >
          {modalForm.loading ? (
            <View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: fullHp(100) * 0.9 - 36,
                width: fullWp(100) * 0.9 - 4,
                zIndex: 1000,
              }}
            >
              <LoadingView title={t("sendingTestEmailInfo")} />
            </View>
          ) : null}

          <SimpleForm
            lang={lang}
            width={fullWp(100)}
            height={fullHp(90)}
            inputs={modalForm.inputs}
            onSave={modalForm.onSave}
            onSaveTitle={modalForm.onSaveTitle || t("save")}
            backButtonTitle={modalForm.backButtonTitle || t("cancel")}
            onBackButtonPress={closeModal("modalForm")}
            // renderHeader={renderFormHeader}
          />
        </Modal>
      ) : null}
      <MenuComponent
        handleClose={closeMenu}
        pageH={fullHp(100)}
        pageW={fullWp(100)}
        isFetching={false}
        rest={modalPicker}
      />
    </View>
  );
}

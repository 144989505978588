import "react";
import { TouchableOpacity, Text, View } from "react-native";
import Icon from "../components/Icon";

export default function ListItem(props) {
  const {
    theme,
    colors,
    title,
    isVisible,
    isSelected,
    noVisibilityToggle,
    handleTitlePress,
    handleSelect,
    handleSetVisible,
    disabled,
    haveData,
    onModifyPress,
    onRemovePress,
    valueIsColor,
  } = props;

  return (
    <View style={theme.rowContainer}>
      {!haveData || disabled || props.removalRestricted ? null : (
        <TouchableOpacity
          onPress={onModifyPress ? handleSelect : onRemovePress}
        >
          <View
            style={[
              {
                minHeight: 36,
                width: 36,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                backgroundColor: colors.lightBg,
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            <Icon
              name={
                onModifyPress
                  ? isSelected
                    ? "select-off"
                    : "select"
                  : "trash-can-outline"
              }
              size={24}
              color={colors.text}
            />
          </View>
        </TouchableOpacity>
      )}

      <TouchableOpacity
        style={
          valueIsColor
            ? [
                theme.flex,
                {
                  backgroundColor: title,
                },
              ]
            : theme.flex
        }
        onPress={handleTitlePress}
        disabled={!!disabled}
      >
        <View style={theme.paddingContainer}>
          <Text style={[theme.boldText, props.titleStyle]}>{title}</Text>
        </View>
      </TouchableOpacity>

      {haveData ? (
        <View
          style={{
            height: "100%",
            flexDirection: "row",
          }}
        >
          {noVisibilityToggle ? null : (
            <TouchableOpacity onPress={handleSetVisible}>
              <View
                style={[
                  {
                    minHeight: 36,
                    width: 72,
                    borderColor: colors.borderLighter,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    backgroundColor: colors.lightBg,
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Icon
                  name={isVisible ? "chevron-up" : "chevron-down"}
                  size={24}
                  color={colors.text}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
      ) : null}
    </View>
  );
}

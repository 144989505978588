import { useState } from "react";
import { View } from "react-native";
import TextInputRow from "./SimpleTextInputRow";
import TextModalButton from "./TextModalButton";
import { ModalButton } from "./ModalButton";
import ObjectList from "./ObjectList";
import ItemContentRow from "./ItemContentRow";

import {
  getCustomerName,
  getReduxLayout,
  getSiteName,
  getTranslatedText,
} from "../lib/functions";

// {
//   item: any,
//   keyIndices: any,
//   options: any,
//   values: any,
//   setTextModal: any,
//   onlineDoc: any,
//   lang: any,
//   theme: any,
//   colors: any,
//   modifyObjectArrItem: any,
//   disabled: any,
//   valueKey: any,
//   deleteFromObjectArr: any,
//   setInputModal: any,
//   noDisabledStyle: Boolean,
// }
export default function PickerObjectsCell(props) {
  const [selected, setSelected] = useState();
  const {
    theme,
    colors,
    lang,
    item,
    keyIndices,
    setTextModal,
    modifyObjectArrItem,
    disabled,
    valueKey,
    deleteFromObjectArr,
    onlineDoc,
    options,
    setInputModal,
    noDisabledStyle,
    title,
  } = props;

  const viewKey = `PickerObjectView${keyIndices}`;
  const key = `PickerObject${keyIndices}`;
  let multiValues = props.values?.[valueKey];
  if (!Array.isArray(multiValues)) multiValues = undefined;

  const pickerObject = getReduxLayout(
    options,
    item.layoutId,
    item.layoutVersion
  );

  let valueObj = {};
  let _id = props.values?.[valueKey]?.id;

  valueObj = options?.[item.optionsProp]?.[_id] || props.values?.[valueKey];

  const renderItemContents = (_item, index, theme, colors) => {
    return pickerObject?.inputs.map((input, i) => {
      const inputToUse = input;
      const titleToUse = getTranslatedText(inputToUse.title, lang);
      const _values =
        options[item.optionsProp]?.[_item.id] ||
        multiValues?.find((x) => x.id === _item.id);
      if (
        inputToUse.noContent ||
        (item.optionsProp === "customers" &&
          _values?.companyId &&
          inputToUse.key === "lName") ||
        (item.optionsProp === "customers" &&
          _values?.lName &&
          inputToUse.key === "companyId")
      ) {
        return null;
      } else if (inputToUse.editableInList) {
        if (inputToUse.type === "textField") {
          return (
            <View key={"ItemContentsView" + i}>
              <View style={[theme.line, { width: "98%" }]} />
              <TextModalButton
                setTextModal={setTextModal}
                onlineDoc={onlineDoc}
                viewKey={"ItemContents" + i}
                title={titleToUse}
                placeholder={_item[inputToUse.key] ?? _values?.[inputToUse.key]}
                multiline={false}
                visible={true}
                value={_item[inputToUse.key] ?? _values?.[inputToUse.key]}
                oldVal={_item}
                isArr={true}
                prop={item.optionsProp}
                idProp={"id"}
                propToSet={inputToUse.key}
                theme={theme}
                colors={colors}
                disabled={disabled}
                fn={(value) => {
                  let newValue = value.replace(/\r?\n|\r/g, "");
                  modifyObjectArrItem({
                    docId: props.docId,
                    valueKey: valueKey,
                    oldVal: _item,
                    value: newValue,
                    idProp: "valueKey",
                    propToSet: inputToUse.key,
                    sortProp: "name",
                  });
                }}
              >
                <TextInputRow
                  key={"FilePickerRowsInput" + index}
                  value={_item[inputToUse.key] ?? _values?.[inputToUse.key]}
                  title={titleToUse}
                  border={false}
                  disabled={disabled || onlineDoc}
                  noDisabledStyle={true}
                  onChangeText={(value) => {
                    let newValue = value.replace(/\r?\n|\r/g, "");
                    modifyObjectArrItem({
                      docId: props.docId,
                      valueKey: valueKey,
                      oldVal: _item,
                      value: newValue,
                      idProp: "valueKey",
                      propToSet: inputToUse.key,
                      sortProp: "name",
                    });
                  }}
                />
              </TextModalButton>
            </View>
          );
        } else return null;
      } else if (inputToUse.isArr) {
        return _values?.[inputToUse.key]?.map((arrItem, arrItemIndex) => (
          <ItemContentRow
            key={"ItemContents" + i}
            colors={colors}
            theme={theme}
            index={i}
            textLeft={arrItemIndex === 0 ? titleToUse : ""}
            textRight={arrItem["name"]}
          />
        ));
      } else {
        return (
          <ItemContentRow
            key={"ItemContents" + i}
            colors={colors}
            theme={theme}
            index={i}
            textLeft={titleToUse}
            textRight={_values?.[inputToUse.key]}
          />
        );
      }
    });
  };

  const getItemText = (_item, _haveData) => {
    const _values =
      options[item.optionsProp]?.[_item.id] ||
      multiValues?.find((x) => x.id === _item.id);
    return _haveData
      ? item.optionsProp === "customers"
        ? getCustomerName(_values)
        : item.optionsProp === "sites"
        ? getSiteName(_values)
        : item.prop
        ? _values?.[item.prop]
        : _values
      : _item;
  };

  return (
    <View key={viewKey} testID={viewKey} style={theme.flex}>
      <ModalButton
        disabled={disabled}
        noDisabledStyle={noDisabledStyle}
        title={title || getTranslatedText(pickerObject?.title, lang)}
        text={item.prop ? valueObj?.[item.prop] : valueObj}
        boldTitle={true}
        hint={props.hint}
        hintTable={props.hintTable}
        onModalPress={() =>
          setInputModal({
            title: title || getTranslatedText(pickerObject?.title, lang),
            inputsOnly: false,
            addOnSave: false,
            onSave: null,
            item,
            multi: true,
            replace: !item.multi,
            id: _id,
            visible: true,
            inputs: pickerObject?.inputs,
            valueKey: valueKey,
          })
        }
        multiModalPickerButton={true}
      />

      <View style={theme.lightBgContainer}>
        <ObjectList
          childKey={key}
          colors={colors}
          theme={theme}
          wrapperPadding={4}
          disabled={disabled}
          data={Array.isArray(multiValues) ? multiValues : []}
          idProp={props.idProp || "valueKey"}
          haveData={Array.isArray(multiValues) && multiValues.length > 0}
          addableProp={item.addableProp}
          getItemText={getItemText}
          renderItemContents={renderItemContents}
          addItemToSelected={(_item) => setSelected(_item)}
          selected={selected}
          onRemovePress={(_oldVal) => {
            deleteFromObjectArr({
              valueKey,
              oldVal: _oldVal || selected,
              docId: props.docId,
              idProp: "valueKey",
            });
            setSelected(null);
          }}
          onCancelPress={() => setSelected(null)}
          removeFromSelected={() => setSelected(null)}
          sortData={true}
          unlimitedWidth={props.unlimitedWidth}
        />
      </View>
    </View>
  );
}

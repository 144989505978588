import React from "react";
import { ScrollView, Text, View } from "react-native";
import { fullHp, fullWp } from "../lib/helperFns";
import { ThemeContext } from "../theming/theme-context";

const styles = {
  margin: { margin: 16 },
  pricingContainer: {},
};
function TOS(props) {
  const theming = React.useContext(ThemeContext);
  const theme = props.lightTheme ? theming.lightTheme : theming.theme;
  return (
    <View
      style={
        props.wrapperStyle || [
          theme.fullPaddingContainer,
          { maxHeight: fullHp(80), maxWidth: fullWp(90) },
        ]
      }
    >
      <ScrollView>
        <Text style={[theme.title, styles.margin]}>Sopimusehdot</Text>
        <Text style={[theme.title, styles.margin]}>Yleistä</Text>
        <Text style={theme.text}>
          Näitä sopimusehtoja (jäljempänä "Sopimusehdot") sovelletaan Document
          Ocean Oy:n ("Palveluntarjoaja") tuottamien ja ylläpitämien
          digitaalisten palveluiden käyttämiseen Palveluntarjoajan ja
          käyttäjäksi rekisteröityneen yritys-asiakkaan (jäljempänä "Asiakas")
          välillä. Asiakkaalle myytävä tuote on käyttöoikeus (Johtaja-roolin
          käyttäjä, myöhempänä “Pääkäyttäjä”) Palveluntarjoajan ohjelmiston
          dokumenttien täyttötyökaluun (jäljempänä “Palvelu”) ja tähän liittyvät
          datamaksut.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          1. Sopimuksen kesto ja irtisanominen
        </Text>
        <Text style={theme.text}>
          Pääkäyttäjän sopimus on toistaiseksi voimassaoleva sekä Pääkäyttäjän
          lisenssityyppi on "Aktiivinen" koko sopimuksen ajan. Pääkäyttäjän
          sopimuksen irtisanomisaika on yksi (1) kalenterikuukausi. Muiden
          käyttäjien irtisanomisaika on yksi (1) päivä. Asiakkaan irtisanoessa
          pääkäyttäjän sopimuksen, muut käyttäjät irtisanotaan yhtä (1) päivää
          ennen pääkäyttäjän sopimuksen loppua. Palveluntarjoaja toimittaa
          kirjallisesta pyynnöstä Docean-sovelluksella tehdyt dokumentit
          Asiakkaalle sopimuksen päättyessä. Jos Asiakas irtisanoo sopimuksen
          sopimusehtojen muutosten vuoksi, sovelletaan jäljellä oleviin
          laskuihin uusittuja sopimusehtoja.
        </Text>
        <Text style={[theme.title, styles.margin]}>2. Maksuehto</Text>
        <Text style={theme.text}>Kymmenen (10) päivää netto.</Text>
        <Text style={[theme.title, styles.margin]}>3 Hinnat</Text>
        <Text style={theme.text}>
          Palvelut veloitetaan laskutushetkellä voimassa olevan hinnaston
          perusteella. Voimassa oleva hinnasto on nähtävillä Palveluntarjoajan
          internet-sivuilla. Palveluntarjoaja pidättää oikeuden muuttaa
          palveluiden hintoja. Hintojen muutoksista ilmoitetaan Asiakkaalle
          laskun yhteydessä ja sähköpostilla. Mikäli Asiakas ei hyväksy
          hinnanmuutosta, on Asiakkaalla oikeus irtisanoa Palvelu sopimusehtojen
          kohdan 1. mukaisesti. Hinnat eivät sisällä arvonlisäveroa.
        </Text>
        <Text style={[theme.title, styles.margin]}>4. Laskutusperuste</Text>
        <Text style={theme.text}>
          Palvelun käyttöoikeuden omaavien käyttäjien suurimman määrän
          perusteella laskettu hinta lisätään laskulle, jos se ylittää Asiakkaan
          lisenssikrediittien määrän. Keskeneräisten dokumenttien siirtämisestä
          valmiiksi laskutetaan hinnaston mukaisesti, jos dokumentin tekijällä
          lisenssityyppi on "Dokumenttikohtainen laskutus". Dokumenttien
          siirtämisestä valmiiksi kertyneet kulut lisätään laskulle, jos se
          ylittää Asiakkaan lisenssikrediittien määrän. Datan siirto ja
          tallennustila hinnoitellaan voimassaolevan hinnaston mukaisesti
          seuraavasti; kun Asiakkaan datakrediitit loppuvat, lisätään aina
          laskulle hinnaston mukainen datakrediittien ennakkomaksu kerrottuna
          niin monesti, että se riittää kattamaan ylimenevät datan siirrosta ja
          tallennustilasta koituvat kulut.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          5. Sopimuksen rikkominen
        </Text>
        <Text style={theme.text}>
          Mikäli Asiakas rikkoo sopimusta Palveluntarjoajalla on oikeus
          kohtuulliseen korvaukseen sopimusrikkeen aiheuttamien lisäkulujen
          kattamiseen.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          6. Sopimuksen purkaminen
        </Text>
        <Text style={theme.text}>
          Asiakkaalla ja Palveluntarjoajalla on oikeus purkaa sopimus, mikäli
          toinen heistä rikkoo olennaisesti sopimusta, eikä tämä ole korjannut
          toimintaansa sopimuksen edellyttämäksi viikon kuluessa kirjallisen
          huomautuksen saatuaan. Palveluntarjoaja voi irtisanoa sopimuksen
          perustellusta syystä välittömästi ilman päättymisilmoituksen
          lähettämistä Asiakkaalle.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          7. Sopimusehtojen muuttaminen
        </Text>
        <Text style={theme.text}>
          Palveluntarjoaja pidättää oikeuden muuttaa sopimus- ja käyttöehtoja.
          Sopimus- ja käyttöehtojen muutoksista ilmoitetaan Asiakkaalle
          sähköpostilla. Mikäli Asiakas ei hyväksy muutoksia, on Asiakkaalla
          oikeus irtisanoa Palvelu kohdan 1. mukaisesti.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          8. Vahingonkorvausvelvollisuus
        </Text>
        <Text style={theme.text}>
          Palvelun maksuttomien osien osalta Palveluntarjoaja ei vastaa mistään
          Asiakkaalle aiheutuvista Palvelun käytöstä johtuvista välittömistä,
          välillisistä tai muista vahingoista, vaikka Palveluntarjoaja olisi
          saanut tiedon kyseisten vahinkojen mahdollisuudesta. Palvelun
          maksullisten osien osalta Palveluntarjoaja vastaa ainoastaan
          tuottamuksellaan Asiakkaalle aiheuttamistaan välittömistä vahingoista,
          Palveluntarjoajan vastuun enimmäismäärä tässä tapauksessa on Asiakkaan
          kyseisestä maksullisesta palvelusta maksama hinta. Palveluntarjoaja ei
          vastaa Asiakkaalle mahdollisesti aiheutuvista välillisistä tai
          epäsuorista vahingoista.
        </Text>
        <Text style={[theme.title, styles.margin]}>Hinnasto:</Text>

        <Text style={styles.pricingContainer}>
          <Text style={theme.boldText}>Kiinteä kk-hinta:</Text>

          <Text style={theme.boldText}>79,00€/kk</Text>
        </Text>

        <Text style={styles.pricingContainer}>
          <Text style={theme.boldText}>Käyttäjät:</Text>

          <Text style={theme.boldText}>15,00€/kk/käyttäjä</Text>
        </Text>

        <Text style={styles.pricingContainer}>
          <Text style={theme.boldText}>Käyttöönotto / tapahtuma:</Text>

          <Text style={theme.boldText}>180,00€</Text>
        </Text>

        <Text style={styles.pricingContainer}>
          <Text style={theme.boldText}>Lisätyöt:</Text>

          <Text style={theme.boldText}>80,00€/h</Text>
        </Text>

        <Text style={styles.pricingContainer}>
          <Text style={theme.boldText}>Sähköiset allekirjoitukset*:</Text>

          <Text style={theme.boldText}>0,50€/kpl</Text>
        </Text>
        <Text style={theme.caption}>
          * jos allekirjoitustapahtumassa joudutaan pyytämään
          pankkitunnistautumista
        </Text>

        <Text style={[theme.title, styles.margin]}>
          Hinnat eivät sisällä arvonlisäveroa.
        </Text>
        <Text style={[theme.title, styles.margin]}>Käyttöehdot</Text>
        <Text style={[theme.title, styles.margin]}>Yleistä</Text>
        <Text style={theme.text}>
          Näitä käyttöehtoja (jäljempänä "Käyttöehdot") sovelletaan Document
          Ocean Oy:n ("Palveluntarjoaja") tuottaman ja ylläpitämän digitaalisten
          palveluiden käyttämiseen Palveluntarjoajan ja käyttäjäksi
          rekisteröityneen (jäljempänä "Käyttäjä") välillä. Käyttöehdot koskevat
          Document Ocean Oy:n digitaalisia palveluja (mukaan lukien mm.
          Docean-verkko- ja mobiilisovelluksen, jäljempänä “Palvelu”).
        </Text>
        <Text style={[theme.title, styles.margin]}>1. Kokeilujakso</Text>
        <Text style={theme.text}>
          Palveluun on mahdollista rekisteröityä kokeilujaksolle. Dokumenttien
          täyttötyökalun kokeilujakso kestää niin kauan, kunnes Käyttäjä on
          luonut kaksi (2) ilmaista dokumenttia. Tämän jälkeen Käyttäjältä
          katkaistaan automaattisesti oikeus luoda uusia dokumentteja.
          Kokeilujakson dokumentteja ei voi allekirjoittaa Palvelussa
          sähköisesti ja kokeilujakson dokumentteja voi lähettää sovelluksen
          sähköpostiominaisuudella vain Käyttäjälle itselleen. Palveluntarjoaja
          pidättää oikeuden katkaista Käyttäjän kokeilujakson ja/tai poistaa
          Käyttäjän palvelusta, jos kokeilujaksoa yritetään väärinkäyttää.
        </Text>
        <Text style={[theme.title, styles.margin]}>2. Käyttöoikeus</Text>
        <Text style={theme.text}>
          Käyttäjällä on käyttöoikeus Palvelun rajoitettuihin osiin vain, jos
          Käyttäjä on yhdistetty Palveluntarjoajalta Palvelun tilanneeseen
          yritykseen ja Käyttäjälle on asetettu käyttöoikeus. Tällöin Käyttäjän
          käyttöoikeus kestää niin kauan, kunnes edellämainittu yritys tai
          Palveluntarjoaja keskeyttää Käyttäjän käyttöoikeuden.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          3. Palveluntarjoajan oikeus muuttaa Palvelun sisältöä
        </Text>
        <Text style={theme.text}>
          Palveluntarjoajalla on oikeus tuottaa Palvelu parhaaksi katsomallaan
          tavalla ja muuttaa milloin tahansa Palvelua, Palvelun sisältöä,
          Palvelun saatavilla oloa ja käyttöön tarvittavalle laitteistolle
          asetettuja vaatimuksia.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          4. Käyttäjän oikeudet, velvollisuudet ja vastuut
        </Text>
        <Text style={theme.text}>
          Käyttäjä on vastuussa Palveluntarjoajan palveluiden käyttämiseen
          tarvittavien laitteiden ja tietoliikenne sekä tiedon siirtoyhteyksien
          hankinnasta, käyttöönotosta ja ylläpidosta sekä käyttökustannuksista.
          Käyttäjä vastaa siitä että käyttämänsä laitteet eivät aiheuta häiriötä
          tai vahinkoa Palveluntarjoajan eikä muille internetin käyttäjille.
          Käyttäjä vastaa itse omien laitteiden sekä tietojärjestelmien tai muun
          vastaavan laitteen suojauksesta. Käyttämällä Palvelua suostut olemaan
          jakamatta, luovuttamatta ja kopioimatta Palveluntarjoajan
          dokumenttipohjia.
        </Text>
        <Text style={theme.text}>Sitoudut myös:</Text>
        <Text style={styles.margin}>
          <Text style={theme.text}>
            {
              "* Olemaan kiertämättä, poistamatta, muuttamatta, deaktivoimatta, heikentämättä tai estämättä mitään Palvelun-sisältösuojauksia.\n"
            }
          </Text>
          <Text style={theme.text}>
            {
              "* Olemaan käyttämättä mitä tahansa robottia tai muuta automaattista tapaa päästäksesi Palveluun\n"
            }
          </Text>
          <Text style={theme.text}>
            {
              "* Olemaan tekemättä käänteistä suunnittelua tai ohjelmiston purkua Palvelulle tai muille tuotteille ja prosesseille, joihin pääsee Palvelun kautta\n"
            }
          </Text>
          <Text style={theme.text}>
            {
              "* Olemaan lisäämättä mitään koodia tai tuotetta Palveluun tai manipuloimatta Palvelun sisältöä millään tavalla\n"
            }
          </Text>
          <Text style={theme.text}>
            {
              "* Olemaan käyttämättä mitä tahansa tiedonlouhinta-, tiedonkeruu- tai poimintamenetelmää. Lisäksi suostut olemaan lataamatta tai lähettämättä mitään materiaalia, joka on suunniteltu keskeyttämään, tuhoamaan tai rajoittamaan Palveluun liittyvän ohjelmiston tai laitteiston tai tietoliikennelaitteiden toimintoja, mukaan lukien ohjelmistovirukset tai mikä tahansa muu tietokonekoodi, tiedostot tai ohjelmat.\n"
            }
          </Text>
        </Text>
        <Text style={theme.text}>
          Lähettämällä tiedostoja tai tuottamalla dokumentteja täyttötyökalulla
          Palveluntarjoajan palvelimelle Käyttäjä hyväksyy seuraavat ehdot:
        </Text>
        <Text style={styles.margin}>
          <Text style={theme.text}>
            {"* Data jota Käyttäjä lähettää, ei ole laitonta\n"}
          </Text>
          <Text style={theme.text}>
            {"* Käyttäjä vastaa tietojen oikeellisuudesta\n"}
          </Text>
          <Text style={theme.text}>
            {
              "* Käyttäjä omistaa käyttöluvat tiedostoihin tai hänellä on rajaton oikeus luovuttaa tiedostot Palveluntarjoajalle\n"
            }
          </Text>
          <Text style={theme.text}>
            {
              "* Käyttäjä vastaa mahdollisesti aiheutuvista seuraamuksista ja kuluista, jotka syntyvät palvelimelle lähetetyistä tiedostoista, mikäli näitä syntyy Käyttäjän tai kolmannen osapuolen toimesta.\n"
            }
          </Text>
        </Text>
        <Text style={[theme.title, styles.margin]}>5. Palvelukatkot</Text>
        <Text style={theme.text}>
          Palveluntarjoajalla on oikeus keskeyttää Palvelu tilapäisesti, mikäli
          se on välttämätöntä sen teknisen muutoksen tai uudistamisen vuoksi tai
          tietoliikenneverkon asennus-, muutos- ja huoltotöiden vuoksi tai
          mikäli lait, asetukset, viranomaisten määräykset, ohjeet tai lausunnot
          tätä edellyttävät. Palveluntarjoaja ei takaa, että ohjelmisto on
          Käyttäjän käytössä keskeytyksettä eikä sitä, miten tietoliikenne- ja
          internet-yhteyden tarjoava taho tai muu palvelun käyttöön vaikuttava
          kolmas taho tuottaa oman palvelunsa.
        </Text>
        <Text style={[theme.title, styles.margin]}>6. Muut ehdot</Text>
        <Text style={[theme.title, styles.margin]}>
          Muutokset käyttöehtoihin
        </Text>
        <Text style={theme.text}>
          Palveluntarjoaja pidättää oikeuden muuttaa näitä käyttöehtoja
        </Text>
        <Text style={[theme.title, styles.margin]}>Palvelu</Text>
        <Text style={theme.text}>
          Hyväksyt, että Palvelun käyttö voi vaatia kolmannen osapuolen
          ohjelmistoja, jotka ovat kolmannen osapuolen lisenssien alaisia.
          Hyväksyt, että voit saada automaattisia päivityksiä Palveluun ja
          Palveluun liittyviin kolmannen osapuolen ohjelmistoihin.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          7. Vahingonkorvausvelvollisuus
        </Text>
        <Text style={theme.text}>
          Palvelun maksuttomien osien osalta Palveluntarjoaja ei vastaa mistään
          Käyttäjälle aiheutuvista Palvelun käytöstä johtuvista välittömistä,
          välillisistä tai muista vahingoista, vaikka Palveluntarjoaja olisi
          saanut tiedon kyseisten vahinkojen mahdollisuudesta. Palvelun
          maksullisten osien osalta Palveluntarjoaja vastaa ainoastaan
          tuottamuksellaan Käyttäjälle aiheuttamistaan välittömistä vahingoista,
          Palveluntarjoajan vastuun enimmäismäärä tässä tapauksessa on Käyttäjän
          kyseisestä maksullisesta palvelusta maksama hinta. Palveluntarjoaja ei
          vastaa Käyttäjälle mahdollisesti aiheutuvista välillisistä tai
          epäsuorista vahingoista.
        </Text>
      </ScrollView>
    </View>
  );
}

export default TOS;

import { useRef, useEffect } from "react";
import {
  TouchableOpacity,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import { setNativeBorderColor, onTextChange } from "../lib/functions";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "./Tooltip";
import TextInput from "./TextInput";
import Icon from "./Icon";
import IconButton from "./IconButton";

export default function SimpleTextInputRow(props) {
  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus(); // Delay focus to allow the keyboard to catch up
      }, 100);
    }
  }, [props.autoFocus]);

  const onKeyPress = (ev) => {
    if (ev.key === "Enter" && props.focusNextInput) {
      props.focusNextInput(props.valueKey);
    }
  };
  const focusedInput = (colors) => {
    setNativeBorderColor(inputRef.current, colors.accent);
  };
  const blurredInput = (colors) => {
    setNativeBorderColor(inputRef.current, colors.borderSecondary);
  };

  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <View
          pointerEvents={props.disabled ? "none" : "auto"}
          style={{
            width: "100%",
            alignItems: "flex-start",
            opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
            backgroundColor: colors.primary,
          }}
        >
          {props.title ? (
            <View style={theme.titleRow}>
              <View style={theme.textWrapper}>
                {props.onClear ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    onPress={props.onClear}
                    backgroundColor={colors.lightBg}
                    icon="close"
                    color={colors.text}
                  />
                ) : null}

                <View style={theme.textContainer}>
                  <Text
                    style={[
                      theme.boldText,
                      {
                        color: props.error ? "red" : colors.text,
                      },
                    ]}
                  >
                    {props.title}
                  </Text>
                </View>
              </View>

              {props.icons ? (
                <View style={theme.row}>
                  {props.icons.map((x) => (
                    <IconButton
                      key={x.icon}
                      theme={theme}
                      loading={x.loading}
                      onPress={x.onPress}
                      disabled={props.disabled}
                      icon={x.icon}
                      backgroundColor={x.backgroundColor ?? colors.accent}
                      color={x.textColor ?? colors.textOnAccent}
                    />
                  ))}
                </View>
              ) : null}

              {props.hint || props.hintTable ? (
                <View style={theme.hPadding}>
                  <Tooltip
                    hintTable={props.hintTable}
                    tip={props.hint}
                    flex={0}
                    icon={true}
                    lang={props.lang}
                  />
                </View>
              ) : null}
            </View>
          ) : null}

          <View
            style={[
              theme.rightRowContainer,
              {
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
              },
            ]}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextInput
                key={props.valueKey}
                valueKey={props.valueKey}
                orientation={orientation}
                disableFullscreenUI={false}
                selectTextOnFocus={props.selectTextOnFocus}
                ref={(ref) => {
                  inputRef.current = ref;
                  props.setCellRef && props.setCellRef(ref);
                }}
                editable={!!(props.disabled ? false : props.editable ?? true)}
                style={[
                  theme.textInput,
                  props.boldText ?? true ? theme.boldText : theme.text,
                  Platform.OS === "web"
                    ? { outline: "none", width: "100%" }
                    : { width: "100%" },
                ]}
                onFocus={() => {
                  focusedInput(colors);
                }}
                onBlur={() => {
                  props.onBlur && props.onBlur();
                  blurredInput(colors);
                }}
                returnKeyType={props.returnKeyType}
                secureTextEntry={props.secureTextEntry ?? false}
                onKeyPress={onKeyPress}
                blurOnSubmit={props.blurOnSubmit}
                onSubmitEditing={(ev) =>
                  props.onSubmitEditing
                    ? props.onSubmitEditing(ev)
                    : props.focusNextInput
                    ? props.focusNextInput(props.valueKey, true)
                    : null
                }
                placeholder={props.placeholder ?? "-"}
                placeholderTextColor={colors.text}
                autoCapitalize={"sentences"}
                maxLength={props.maxLength || (props.numeric ? 15 : null)}
                defaultValue={props.defaultValue}
                autoComplete={
                  props.passwordInput ? "password" : props.autoComplete
                }
                keyboardType={
                  props.passwordInput
                    ? "default"
                    : props.numeric
                    ? "decimal-pad"
                    : props.keyboardType ?? "default"
                }
                value={
                  // number values dont work, need to parse
                  props.value !== undefined && props.value !== null
                    ? typeof props.value !== "string"
                      ? props.value.toString()
                      : props.value
                    : props.defaultValue
                    ? undefined
                    : ""
                }
                // multiline because if first character is from soft number keyboard, the keyboard gets changed to default after typing the first character
                // multiline={true}
                // // numberOfLines to make it a "normal" input without multiline
                // numberOfLines={1}
                onChangeText={(text) => {
                  if (text) {
                    let _value = onTextChange(
                      text,
                      props.numeric,
                      props.maxDecimals,
                      props.maxValue,
                      props.minValue
                    );
                    props.onChangeText(_value);
                    return _value;
                  } else {
                    props.onChangeText("");
                    return "";
                  }
                }}
                handleChangeReturn={props.handleChangeReturn}
              />
              {props.unit ? (
                <TouchableOpacity
                  onPress={props.onUnitPress}
                  disabled={!props.onUnitPress}
                >
                  <Text style={theme.boldText}>{` ${props.unit} `}</Text>
                </TouchableOpacity>
              ) : null}
              {props.endIcon ? (
                props.loadingIcon ? (
                  <View
                    style={{
                      height: 30,
                      width: 30,
                      align: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ActivityIndicator
                      color={props.endIconColor ?? colors.text}
                      size={Platform.OS === "web" ? "small" : "large"}
                      focusable={false}
                      tabIndex="-1"
                    />
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={props.onEndIconPress}
                    disabled={!props.onEndIconPress}
                    focusable={false}
                    tabIndex="-1"
                  >
                    <Icon
                      name={props.endIcon}
                      size={30}
                      color={props.endIconColor ?? colors.text}
                    />
                  </TouchableOpacity>
                )
              ) : null}
            </View>
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Text, TouchableOpacity, View } from "react-native";
import Icon from "./Icon";

function Arrow(props) {
  return (
    <TouchableOpacity onPress={() => props.onArrowPress(props.prev)}>
      <View
        style={[
          {
            width: 36,
            height: 36,
            borderRadius: 4,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: props.colors.primary,
            justifyContent: "center",
            alignItems: "center",
          },
        ]}
      >
        <Icon
          name={props.prev ? "arrow-left" : "arrow-right"}
          size={24}
          color={props.colors.text}
        />
      </View>
    </TouchableOpacity>
  );
}
export default function TitleRowWithArrows(props) {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: props.colors.primary,
        flexDirection: "row",
      }}
    >
      {props.onArrowPress ? (
        <Arrow prev colors={props.colors} onArrowPress={props.onArrowPress} />
      ) : null}
      <View
        style={{
          flex: 1,
          alignItems: "stretch",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Text style={props.theme.title}>{props.title}</Text>
      </View>
      {props.onArrowPress ? (
        <Arrow colors={props.colors} onArrowPress={props.onArrowPress} />
      ) : null}
    </View>
  );
}

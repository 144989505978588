import { useRef } from "react";
import ReactDOM from "react-dom";

export default function Modal(props) {
  const elements = document.getElementsByClassName("doceanmodal");
  const el = elements[elements.length - 1];
  const overlayRef = useRef(null);
  const mouseDownElem = useRef(null);

  // Track if the click started inside the modal
  const handleMouseDown = (e) => {
    mouseDownElem.current = e.target;
  };

  // Handle the mouse up event
  const handleMouseUp = (e) => {
    if (
      overlayRef.current === mouseDownElem.current &&
      overlayRef.current === e.target
    ) {
      // Only close if the mouse started and ended on the overlay
      props.onBackdropPress?.();
    }
  };

  if (props.isVisible) {
    return ReactDOM.createPortal(
      <div className="overlay">
        <div
          ref={overlayRef}
          className="docean-modal-wrapper"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <div
            className="docean-modal-content"
            onClick={(ev) => {
              handleMouseDown(ev);
              ev.stopPropagation();
            }}
          >
            {props.children}
          </div>
        </div>
      </div>,
      el
    );
  } else {
    return null;
  }
}

import { PDFDocument } from "pdf-lib";
import axios from "axios";
import { getWithToken } from "../lib/api";
import { errorReport } from "../lib/functions";

export const combineDocs = async (docIds) => {
  let signedUrls = [];

  for (let i = 0; i < docIds.length; i++) {
    const docId = docIds[i];

    const res = await getWithToken(
      `/docs/file/${encodeURIComponent(docId)}`,
      null,
      {
        redirect: false,
      }
    );

    if (res.status === "200") {
      signedUrls.add(res.data);
    }
  }
};
const combinePDFs = async (urls) => {
  try {
    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];

      const res = await axios.get(url, {
        responseType: "arraybuffer",
      });
      if (res.status !== 200 || !res.data) throw "failed to fetch url";

      const embeddedPdf = await PDFDocument.load(res.data);
      const copiedPages = await pdfDoc.copyPages(
        embeddedPdf,
        embeddedPdf.getPageIndices()
      );
      copiedPages.forEach((page) => pdfDoc.addPage(page));
    }
    const base64String = await pdfDoc.saveAsBase64();
    return base64String;
  } catch (error) {
    errorReport({
      error,
      errorInFn: "combinePDFs",
      errorInScreen: "combinePDFs",
    });
  }
};

export default combinePDFs;

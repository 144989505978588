import { combineReducers } from "redux";
import moment from "moment";

import userInfo from "./UserInfoReducer";
import componentReducer from "./ComponentReducer";
import docs from "../reducers/DocsReducer";
import options from "../reducers/OptionsReducer";
import modifyDoc from "../reducers/ModifyDocReducer";
import projects from "../reducers/ProjectsReducer";
import folders from "../reducers/FolderReducer";

import localForage from "localforage";

import { persistReducer } from "redux-persist";

const userInfoPersistConfig = {
  key: "userInfo",
  storage: localForage,
  blacklist: [
    "token",
    "loginErr",
    "inputs",
    "errors",
    "initialURL",
    "fromScreen",
    "unfinishedDocsLastFetched",
    "completedDocsLastFetched",
    "employeeDocsLastFetched",
    "pendingDocsLastFetched",
    "manualPdfUpdateCount",
    "gpsLocation",
  ],
  //throttle: 1000,
};

const docsPersistConfig = {
  key: "docs",
  storage: localForage,
  // blacklist: [
  //   "unfinishedDocsSortObj",
  //   "completedDocsSortObj",
  //   "employeeDocsSortObj",
  // ],
  //throttle: 1000,
};

const optionsPersistConfig = {
  key: "options",
  storage: localForage,
  blacklist: ["tmpAttachments", "persistedLayouts"],
  //throttle: 1000,
};

const projectsPersistConfig = {
  key: "projects",
  storage: localForage,
  //throttle: 1000,
};

const foldersPersistConfig = {
  key: "folders",
  storage: localForage,
  //throttle: 1000,
};

const actionsThatEndRequest = [
  //'RECEIVE_USER_DATA',
  "RECEIVE_RESPONSE",
  "REFRESH_CUSTOMERS",
  "REMOVE_DOCS",
  "CHANGE_PHONE_NUMBER",
  "REFRESH_OPTIONS",
  "SIGN_OUT",
];
const isFetching = (state = false, action) => {
  if (action.type === "REQUEST_API") {
    return true;
  } else if (actionsThatEndRequest.includes(action.type)) {
    return false;
  }

  return state;
};

const emailSent = (state = null, action) => {
  if (action.type === "RECEIVE_EMAIL_CONF_RESPONSE") {
    const now = new moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    return {
      count: state ? state.count + 1 : 0,
      time: state && state.count === 5 ? now : null,
    };
  } else if (action.type === "RESET_EMAIL_SENT") {
    return null;
  }

  return state;
};

const appReducer = combineReducers({
  modifyDoc,
  isFetching,
  emailSent,
  components: componentReducer,
  docs: persistReducer(docsPersistConfig, docs),
  options: persistReducer(optionsPersistConfig, options),
  userInfo: persistReducer(userInfoPersistConfig, userInfo),
  projects: persistReducer(projectsPersistConfig, projects),
  folders: persistReducer(foldersPersistConfig, folders),
  //nav
});

export default appReducer;

export const getEmailSent = (state) => state.emailSent;
export const getRole = (state) => {
  if (state.userInfo.profile !== null) {
    if (Object.prototype.hasOwnProperty.call(state.userInfo.profile, "role"))
      return state.userInfo.profile.role;
    else return false;
  } else return false;
};

export const getCustomer = (doc, docLayout, customers) => {
  if (doc?.customer) {
    return doc.customer;
  } else if (doc?.values) {
    const found = docLayout?.specialInputs?.find(
      (x) => x.optionsProp === "customers" && !x.secondary
    );

    if (found) {
      const _docCustomer = doc.values[`${found.optionsProp}_${found.valueKey}`];
      const _customer = customers[_docCustomer?.id];
      return _customer;
    }
  } else return {};
};

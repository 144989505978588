import React from "react";
import { ScrollView, Text, View } from "react-native";
import { fullHp, fullWp } from "../lib/helperFns";
import { ThemeContext } from "../theming/theme-context";

const styles = {
  margin: { margin: 16 },
};
function GDPR(props) {
  const theming = React.useContext(ThemeContext);
  const theme = props.lightTheme ? theming.lightTheme : theming.theme;
  return (
    <View
      style={
        props.wrapperStyle || [
          theme.fullPaddingContainer,
          { maxHeight: fullHp(80), maxWidth: fullWp(90) },
        ]
      }
    >
      <ScrollView>
        <Text style={theme.text}>
          Tässä Document Ocean Oy:n (tästä eteenpäin Document Ocean Oy:sta
          saatetaan myös käyttää lyhennystä “Docean Oy” tai sanaa “me”)
          tietosuojatiedotteessa kuvataan rekisterinpitäjänä toimivan Docean
          Oy:n henkilötietojen käsittelytoimia (jäljempänä ”GDPR-Tiedote”).
          GDPR-Tiedote pitää sisällään rekisterinpitäjänä toimivan Docean Oy:n
          selosteen käsittelytoimista, ja se myös informoi Docean Oy:n
          rekisteröityjä (jäljempänä meidän rekisteröityjä voidaan kutsua myös
          sanalla ”sinä”) siitä, kuinka Docean Oy käsittelee heidän
          henkilötietojaan. Täten Docean Oy:n GDPR-Tiedote kattaa mm. EU:n
          yleisen tietosuoja asetuksen (679/2016) artikloissa 13, 14 ja 30
          vaaditut tiedot. Docean Oy takaa, että Tiedote on aina julkisesti,
          läpinäkyvästi ja helposti saatavilla Docean Oy:n internetsivuilla.
        </Text>
        <Text style={theme.text}>
          GDPR-Tiedote pitää sisällään rekisterinpitäjänä toimivan Docean Oy:n
          selosteen käsittelytoimista, ja se myös informoi Docean Oy:n
          rekisteröityjä (jäljempänä meidän rekisteröityjä voidaan kutsua myös
          sanalla ”sinä”) siitä, kuinka Docean Oy käsittelee heidän
          henkilötietojaan. Täten Docean Oy:n GDPR-Tiedote kattaa mm. EU:n
          yleisen tietosuoja asetuksen (679/2016) artikloissa 13, 14 ja 30
          vaaditut tiedot.
        </Text>
        <Text style={theme.text}>
          Docean Oy takaa, että Tiedote on aina julkisesti, läpinäkyvästi ja
          helposti saatavilla Docean Oy:n internetsivuilla.
        </Text>
        <Text style={[theme.title, styles.margin]}>1. REKISTERINPITÄJÄ</Text>
        <Text style={theme.text}>Nimi: Document Ocean Oy</Text>
        <Text style={theme.text}>Y-tunnus: 3086017-5</Text>
        <Text style={theme.text}>Postiosoite: Puolikkotie 8, 02230 Espoo</Text>
        <Text style={[theme.title, styles.margin]}>
          2. REKISTERIASIOISTA VASTAAVA HENKILÖ
        </Text>
        <Text style={theme.text}>Nimi: Joose Seppälä</Text>
        <Text style={theme.text}>Puhelinnumero: +358 452659415</Text>
        <Text style={theme.text}>
          Sähköpostiosoite: joose.seppala@docean.fi
        </Text>
        <Text style={[theme.title, styles.margin]}>
          3. REKISTERÖITYJEN RYHMÄT
        </Text>
        <Text style={theme.text}>
          Docean Oy:n GDPR-Tiedote rekisterinpitäjänä koskee seuraavia
          rekisteröityjen ryhmiä:
        </Text>
        <Text style={theme.text}>
          {`
1. henkilöt, jotka toimivat asiakasyritystemme yhteyshenkilöinä
2. henkilöt, jotka ovat asiakasyritystemme työntekijöitä
3. henkilöt, jotka ovat asiakasyritystemme asiakkaita
4. henkilöt, jotka ovat työntekijöitämme
5. henkilöt, jotka ovat yhteistyökumppaniemme työntekijöitä`}
        </Text>
        <Text style={[theme.title, styles.margin]}>4. HENKILÖTIEDOT</Text>
        <Text style={theme.text}>
          Rekisteröityjen ryhmät 1-2 voi sisältää seuraavia henkilötietoja
        </Text>
        <Text style={theme.text}>
          • yhteystiedot, kuten nimi, puhelinnumerot, sähköpostiosoitteet
        </Text>
        <Text style={theme.text}>
          • mahdolliset rekisteröitymistiedot, kuten salasana ja muu mahdollinen
          yksilöivä tunnus
        </Text>
        <Text style={theme.text}>
          • mahdolliset muut suostumuksella kerätyt tiedot.
        </Text>
        <br />
        <Text style={theme.text}>
          Rekisteröityjen ryhmä 3 voi sisältää seuraavia henkilötietoja
        </Text>
        <Text style={theme.text}>
          • yhteystiedot, kuten nimi, osoite, puhelinnumerot, y-tunnus,
          sähköpostiosoitteet;
        </Text>
        <Text style={theme.text}>
          • mahdolliset muut suostumuksella kerätyt tiedot.
        </Text>
        <br />
        <Text style={theme.text}>
          Rekisteröityjen ryhmät 4-5 voi sisältää seuraavia henkilötietoja
        </Text>
        <Text style={theme.text}>
          • yhteystiedot, kuten nimi, osoite, puhelinnumerot,
          sähköpostiosoitteet
        </Text>
        <Text style={theme.text}>
          • mahdolliset rekisteröitymistiedot, kuten salasana ja muu mahdollinen
          yksilöivä tunnus
        </Text>
        <Text style={theme.text}>
          • mahdolliset muut suostumuksella kerätyt tiedot.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          5. HENKILÖTIETOJEN KÄSITTELYN TARKOITUS
        </Text>
        <Text style={theme.text}>
          Rekisteröityjen ryhmien 1 ja 2 henkilötietoja voidaan käsitellä
          seuraavia tarkoituksia varten:
        </Text>
        <Text style={theme.text}>
          • asiakassuhteiden hallinnointi ja kehittäminen;
        </Text>
        <Text style={theme.text}>• asiakaspalvelu;</Text>
        <Text style={theme.text}>• käyttäjäkokemuksen parantaminen;</Text>
        <Text style={theme.text}>
          • lainsäädännöllisten vaatimusten noudattaminen;
        </Text>
        <Text style={theme.text}>• analysointi ja tilastointi</Text>
        <Text style={theme.text}>
          • käyttäjän henkilöllisyyden varmistaminen
        </Text>
        <Text style={theme.text}>• markkinointi</Text>
        <Text style={theme.text}>
          Näitä tietoja luovutetaan myös Docean Oy:n yhteistyökumppaneille,
          jotka suorittavat Docean Oy:n tuotteiden myyntiä.
        </Text>
        <Text style={theme.text}>
          Rekisteröityjen ryhmän 3 henkilötietoja voidaan käsitellä seuraavia
          tarkoituksia varten:
        </Text>
        <Text style={theme.text}>• osoitetietojen varmistaminen</Text>
        <Text style={theme.text}>• henkilötietojen varmistaminen</Text>
        <Text style={[theme.title, styles.margin]}>
          6. KÄSITTELYN OIKEUSPERUSTE
        </Text>
        <Text style={theme.text}>
          Rekisterinpitäjällä on oikeus käsitellä rekisteröityjen
          henkilötietoja, joko:
        </Text>
        <Text style={theme.text}>
          • rekisteröidyltä saadun suostumuksen perusteella;
        </Text>
        <Text style={theme.text}>
          • sen vuoksi, että käsittely on tarpeen sopimuksen
          täytäntöönpanemiseksi, jossa rekisteröity on osapuolena, tai
          sopimuksen tekemistä edeltävien toimenpiteiden toteuttamiseksi
          rekisteröidyn pyynnöstä; tai
        </Text>
        <Text style={theme.text}>
          • käsittely on tarpeen rekisterinpitäjän lakisääteisen velvoitteen
          noudattamiseksi.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          7. SÄÄNNÖNMUKAISET TIETOLÄHTEET
        </Text>
        <Text style={theme.text}>
          Rekisteröityä koskevat tiedot saadaan säännönmukaisesti:
        </Text>
        <Text style={theme.text}>
          • rekisteröidyltä itseltään palvelumme kautta, puhelimitse,
          kasvotusten, internetin kautta, sähköpostilla tai muulla vastaavalla
          tavalla;
        </Text>
        <Text style={theme.text}>
          • evästeiden tai muiden vastaavien tekniikoiden avulla;
        </Text>
        <Text style={theme.text}>
          • Docean Oy:n muilta suomalaisilta kumppanuusyrityksiltä; tai
        </Text>
        <Text style={theme.text}>
          • VRK/Väestötietojärjestelmästä, Postin osoitetietojärjestelmästä,
          puhelinyhtiöiden yhteystietorekistereistä ja muista vastaavista
          yksityisistä ja julkisista rekistereistä.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          8. HENKILÖTIETOJEN SÄILYTYSAIKA
        </Text>
        <Text style={theme.text}>
          {`
1. Säilytämme rekisteröityjen ryhmän 1 henkilötietoja viiden (5) vuoden ajan asiakassuhteen päättymisen jälkeen.
2. Säilytämme rekisteröityjen ryhmän 2 henkilötietoja yhden (1) vuoden ajan asiakassuhteen päättymisen jälkeen.
3. Säilytämme rekisteröityjen ryhmän 3 mainituista koskevia henkilötietoja kymmenen (10) vuoden ajan, sillä meillä on lakisääteinen velvollisuus säilyttää käyttöönottomittaus-pöytäkirjoja tuon ajanjakson ajan; tai säilytämme henkilötietoja yhden(1) kuukauden asiakassuhteen(asiakasyrityksemme kanssa) päättymisen jälkeen.
4. Säilytämme rekisteröityjen ryhmän 4 henkilötietoja yhden (1) vuoden ajan työsuhteen päättymisen jälkeen.
5. Säilytämme rekisteröityjen ryhmän 5 henkilötietoja yhden (1) kuukauden ajan yhteistyön päättymisen jälkeen.
6. Voimme kuitenkin säilyttää rekisteröityjen ryhmien 3.1) – 3.3) henkilötietoja pidempään pidempään kuin yllä on kuvattu, jos se on tarpeen rikostutkinnan, mahdollisen oikeudenkäynnin tai muiden vastaavien syiden vuoksi.
7. Rekisterinpitäjä tarkastaa joka kuudes (6) kuukausi rekistereissä olevien tietojen tarpeen mukaisuuden.`}
        </Text>
        <Text style={[theme.title, styles.margin]}>
          9. HENKILÖTIETOJEN VASTAANOTTAJIEN RYHMÄT
        </Text>
        <Text style={theme.text}>
          Henkilötietojen vastaanottajiin voivat kuulua seuraavat ryhmät:
        </Text>
        <Text style={theme.text}>
          • Docean Oy:n kumppanuus- ja asiakasyritykset:
        </Text>
        <Text style={theme.text}>• pilvipalveluita tarjoavat tahot;</Text>
        <Text style={theme.text}>
          • kirjanpito-, tilin- ja tietosuojatarkastuspalveluita tarjoavat
          tahot;
        </Text>
        <Text style={theme.text}>
          • tahot, jotka avustavat Docean Oy:tä sen lakisääteisten
          velvollisuuksien toteuttamisessa
        </Text>
        <Text style={theme.text}>• Docean Oy:n asiakasyritykset.</Text>
        <Text style={[theme.title, styles.margin]}>
          11. HENKILÖTIETOJEN SIIRTO EU:N TAI EUROOPAN TALOUSALUEEN ULKOPUOLELLE
        </Text>
        <Text style={theme.text}>
          Henkilötietoja siirretään myös EU:n ulkopuolisiin maihin.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          12. REKISTERÖIDYN OIKEUDET
        </Text>
        <Text style={theme.text}>
          Rekisteröidyllä on oikeus käyttää kaikkia alla lueteltuja oikeuksia.
          Oikeuksia koskevat yhteydenotot tulee tehdä kirjallisina kohdassa 2.
          mainitulle rekisteriasioista vastaavalle henkilölle. Rekisteröidyn
          oikeudet voidaan toteuttaa vasta, kun rekisteröidyn henkilöllisyys on
          varmennettu asianmukaisella tavalla.
        </Text>
        <Text style={[theme.title, styles.margin]}>Tarkastusoikeus</Text>
        <Text style={theme.text}>
          Rekisteröidyllä on oikeus tiedon etsimiseksi tarpeelliset seikat
          ilmoitettuaan saada tietää, mitä häntä koskevia tietoja tähän
          rekisteriin on talletettu tai, ettei rekisterissä ole häntä koskevia
          tietoja. Rekisterinpitäjän tulee samalla ilmoittaa rekisteröidylle
          rekisterin säännönmukaiset tietolähteet sekä mihin rekisterin tietoja
          käytetään ja säännönmukaisesti luovutetaan.
        </Text>

        <Text style={[theme.title, styles.margin]}>
          1. Oikaisu- ja poisto-oikeus
        </Text>
        <Text style={theme.text}>
          Rekisteröidyllä on oikeus vaatia rekisterinpitäjää oikaisemaan
          rekisteröityä koskevat epätarkat, virheelliset ja puutteelliset
          henkilötiedot.
        </Text>
        <Text style={theme.text}>
          Rekisteröity voi vaatia rekisterinpitäjää poistamaan rekisteröityä
          koskevat henkilötiedot, jos:
        </Text>
        <Text style={theme.text}>
          • rekisteröityjä tietoja ei enää tarvita niihin tarkoituksiin, joita
          varten ne kerättiin;
        </Text>
        <Text style={theme.text}>
          • rekisteröity peruuttaa suostumuksensa, eikä käsittelyyn enää ole
          muuta laillista perustetta;
        </Text>
        <Text style={theme.text}>
          • rekisteröityä koskevia henkilötietoja on käsitelty lainvastaisesti;
          tai
        </Text>
        <Text style={theme.text}>
          • henkilötiedot on poistettava unionin oikeuteen tai jäsenvaltion
          lainsäädäntöön perustuvan rekisterinpitäjään sovellettavan
          lakisääteisen velvoitteen noudattamiseksi.
        </Text>
        <Text style={theme.text}>
          Huomattakoon kuitenkin, että rekisterinpitäjän liiketoiminnasta
          johtuen rekisteröityjen oikeus poistaa tai oikaista tietoja on
          rajoitettu koskemaan ainoastaan sellaisia tietoja, joita
          rekisterinpitäjän ei tule sen lainsäädännöllisten velvoitteiden
          perusteella säilyttää.
        </Text>
        <Text style={theme.text}>
          Jollei rekisterinpitäjä hyväksy rekisteröidyn vaatimusta tiedon
          oikaisusta tai poistamisesta, hänen on annettava rekisteröidylle
          asiasta kirjallinen todistus. Todistuksessa on mainittava myös ne
          syyt, joiden vuoksi vaatimusta ei ole hyväksytty. Rekisteröity voi
          saattaa asian tietosuojavaltuutetun käsiteltäväksi.
        </Text>
        <Text style={theme.text}>
          Rekisterinpitäjän on ilmoitettava tiedon oikaisusta tai poistamisesta
          sille, jolle rekisterinpitäjä on luovuttanut tai jolta
          rekisterinpitäjä on saanut virheellisen henkilötiedon.
        </Text>
        <Text style={theme.text}>
          Ilmoitusvelvollisuutta ei kuitenkaan ole, jos ilmoittaminen on
          mahdotonta tai vaatii kohtuutonta vaivaa.
        </Text>
        <Text style={[theme.title, styles.margin]}>2. Rajoittamisoikeus</Text>
        <Text style={theme.text}>
          Rekisteröity voi vaatia rekisterinpitäjää rajoittamaan häntä koskevien
          henkilötietojen käsittelyä, jos:
        </Text>
        <Text style={theme.text}>
          • henkilötiedot eivät ole paikkansapitäviä, jolloin käsittelyä
          rajoitetaan ajaksi, jonka kuluessa rekisterinpitäjä voi varmistaa
          niiden paikkansapitävyyden;
        </Text>
        <Text style={theme.text}>
          • henkilötietojen käsittely on lainvastaista ja rekisteröity vastustaa
          henkilötietojen poistamista ja vaatii sen sijaan niiden käytön
          rajoittamista;
        </Text>
        <Text style={theme.text}>
          • rekisterinpitäjä ei enää tarvitse kyseisiä henkilötietoja käsittelyn
          tarkoituksiin mutta rekisteröity tarvitsee niitä oikeudellisen vaateen
          laatimiseksi, esittämiseksi tai puolustamiseksi; tai
        </Text>
        <Text style={theme.text}>
          • rekisteröity on vastustanut henkilötietojen käsittelyä EU:n yleisen
          tietosuoja- asetuksen 21 artiklan 1 kohdan nojalla odotettaessa sen
          todentamista, syrjäyttävätkö rekisterinpitäjän oikeutetut perusteet
          rekisteröidyn perusteet.
        </Text>
        <Text style={theme.text}>
          Jos rekisterinpitäjä on rajoittanut käsittelyä mainituilla
          perusteilla, rekisterinpitäjän on tehtävä rekisteröidylle ilmoitus,
          ennen kuin käsittelyä koskeva rajoitus poistetaan.
        </Text>
        <Text style={[theme.title, styles.margin]}>3. Vastustamisoikeus</Text>
        <Text style={theme.text}>
          Jos henkilötietoja käsitellään suoramarkkinointia varten,
          rekisteröidyllä on oikeus, milloin tahansa vastustaa häntä koskevien
          henkilötietojen käsittelyä tällaista markkinointia varten, mukaan
          lukien profilointia silloin kun se liittyy tällaiseen
          suoramarkkinointiin
        </Text>
        <Text style={[theme.title, styles.margin]}>3. Siirto-oikeus</Text>
        <Text style={theme.text}>
          Rekisteröidyllä on oikeus saada häntä koskevat henkilötiedot, jotka
          hän on toimittanut rekisterinpitäjälle strukturoidussa muodossa mutta
          Docean Oy ei ole velvollinen siirtämään tietoja toiselle
          palveluntarjoajalle.{" "}
        </Text>
        <Text style={[theme.title, styles.margin]}>
          3. Automatisoidut yksittäispäätökset, profilointi mukaan luettuna
        </Text>
        <Text style={theme.text}>
          Rekisteröidyllä on oikeus olla joutumatta sellaisen päätöksen
          kohteeksi, joka perustuu pelkästään automaattiseen käsittelyyn, kuten
          profilointiin, ja jolla on häntä koskevia oikeusvaikutuksia tai joka
          vaikuttaa häneen vastaavalla tavalla merkittävästi.
        </Text>
        <Text style={theme.text}>
          Rekisteröidyllä ei kuitenkaan ole yllä kuvattua oikeutta, jos päätös:
        </Text>
        <Text style={theme.text}>
          • on välttämätön rekisteröidyn ja rekisterinpitäjän välisen sopimuksen
          tekemistä tai täytäntöönpanoa varten;
        </Text>
        <Text style={theme.text}>
          • on hyväksytty rekisterinpitäjään sovellettavassa unionin oikeudessa
          tai jäsenvaltion lainsäädännössä, jossa vahvistetaan myös
          asianmukaiset toimenpiteet rekisteröidyn oikeuksien ja vapauksien sekä
          oikeutettujen etujen suojaamiseksi; tai
        </Text>
        <Text style={theme.text}>
          • perustuu rekisteröidyn nimenomaiseen suostumukseen.
        </Text>
        <Text style={theme.text}>Oikeus peruuttaa suostumus</Text>
        <Text style={theme.text}>
          Mikäli henkilötietojen käsittelyn oikeusperustana on rekisteröityjen
          antama suostumus, on rekisteröidyillä myös oikeus suostumuksen
          peruuttamiseen.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          13. OIKEUS TEHDÄ VALITUS VALVONTAVIRANOMAISELLE
        </Text>
        <Text style={theme.text}>
          Jokaisella rekisteröidyllä on oikeus tehdä valitus
          valvontaviranomaiselle, jos rekisteröity katsoo, että häntä koskevien
          henkilötietojen käsittelyssä rikotaan tietosuoja-asetusta. Valitus
          voidaan tehdä siinä EU:n jäsenvaltiossa, jossa rekisteröidyn
          vakinainen asuinpaikka tai työpaikka on taikka jossa väitetty
          rikkominen on tapahtunut.
        </Text>
        <Text style={[theme.title, styles.margin]}>14. EVÄSTEET</Text>
        <Text style={theme.text}>
          Palvelu käyttää evästeitä, joiden tarkoituksena on kehittää Palvelua
          ja tehdä siitä käyttäjäystävällisempi. Tämä on tavanomainen toimintapa
          useilla verkkosivuilla. Evästeet ovat pieniä tiedostoja, joita
          verkkosivu tallentaa laitteellesi, kun vierailet verkkosivulla.
          Evästeitä ei käytetä henkilön tunnistamiseen. Voit vapaasti
          hallinnoida evästeiden käyttöä verkkoselaimessasi. Ohjeet tähän löytyy
          esimerkiksi verkko-osoitteesta: aboutcookies.org.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          16. KÄSITTELYN TURVALLISUUS
        </Text>
        <Text style={theme.text}>
          Toteutamme mm. seuraavat tekniset ja organisatoriset toimenpiteet
          varmistaaksemme asianmukaisen turvallisuustason henkilötietojen
          käsittelylle:
        </Text>
        <Text style={theme.text}>
          • Salaamme tietokantamme ja käyttäjien salasanat käyttäen kaikkia
          asianmukaisia keinoja.
        </Text>
        <Text style={theme.text}>
          • Suojaamme järjestelmiemme ja palvelumme luottamuksellisuuden,
          eheyden, käytettävyyden ja vikasietoisuuden käyttäen kaikkia
          asianmukaisia keinoja, kuten mm. tokeneita.
        </Text>
        <Text style={theme.text}>
          • Tietokantamme varmuuskopioidaan säännöllisesti, ja tiedot voidaan
          palauttaa helposti.
        </Text>
        <Text style={theme.text}>
          • Tietojen käsittelymme auditoidaan säännöllisesti, jonka seurauksena
          pystymme takaamaan, että noudatamme kaikkia relevantteja
          tietosuojalakeja.
        </Text>
        <Text style={[theme.title, styles.margin]}>16. PROFILOINTI</Text>
        <Text style={theme.text}>
          Uudet käyttäjät voivat ottaa Docean-palvelun testikäyttöön tekemällä
          itselleen profiilin. Profiili vaatii uuden tilin luomisen.
          Rekisteröinti voidaan tehdä luomalla Doceaniin testikäyttäjä
          syöttämällä sähköposti rekisteröidy-kenttään. Mikäli
          sähköpostiosoitetta ei ole rekisteröity, pääset luomaan
          testikäyttäjän. Jos sähköpostiosoite on rekisteröity jonkin yrityksen
          alle, saat sähköpostiisi linkin, jolla pääset aktivoimaan tilin ja
          luomaan itsellesi salasanan. Koekäyttäjällä pakollisia tietoja ovat
          nimi, sähköposti, puhelin, yritys jossa työskentelee sekä yrityksen
          osoitetiedot sekä sähkötöiden johtaja. Profiilia luodessa täytyy
          tunnistautua verkkopankkitunnuksilla henkilöllisyyden oikeellisuuden
          varmistamiseksi. Testikäyttäjällä verkkopankkitunnistautumista ei
          tehdä.
        </Text>
        <Text style={[theme.title, styles.margin]}>
          17. TIETOSUOJAPERIAATTEET
        </Text>
        <Text style={theme.text}>
          Docean Oy käyttää kaikkia kohtuullisia toimia ylläpitääkseen fyysisiä,
          elektronisia ja hallinnollisia turvatoimia suojatakseen
          henkilökohtaisia tietojasi luvattomalta tai sopimattomalta käytöltä
          mutta huomauttaa myös, että Internet ei ole aina turvallinen väline.
          Docean Oy rajoittaa sinuun liittyvien tietojen saantia niille Docean
          Oy:n henkilöstöön kuuluville, joiden täytyy tietää nämä tiedot
          esimerkiksi heidän vastatessaan kysymyksiisi tai pyyntöihisi.
        </Text>
      </ScrollView>
    </View>
  );
}

export default GDPR;

import "react";
import { TouchableOpacity } from "react-native";

export default function TextModalButton(props) {
  if (props.onlineDoc) {
    return (
      <TouchableOpacity
        style={{ flex: 1 }}
        key={props.viewKey}
        disabled={!!props.disabled}
        onPress={() =>
          props.setTextModal({
            visible: true,
            title: props.title,
            placeholder: props.value,
            multiline: props.multiline,
            valueKey: props.valueKey,
            value: props.value,
            numeric: props.numeric,
            maxDecimals: props.maxDecimals,
            unit: props.unit,
            fn: props.fn,
            closeOnRighButtonPress: props.fn ? true : false,
            getMultiEditValueKeys: props.getMultiEditValueKeys,
            oldVal: props.oldVal,
            isArr: props.isArr,
            idProp: props.idProp,
            propToSet: props.propToSet,
            prop: props.prop,
          })
        }
      >
        {props.children}
      </TouchableOpacity>
    );
  } else return props.children;
}

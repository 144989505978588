import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { store } from "../store";
import axios from "axios"; // Add axios to handle HTTP requests
import { buildVersion } from "./constants";

const getTwoLetterLangCodes = () => {
  const langs = navigator.languages || [];
  return langs
    .map((lang) => lang.split("-")[0])
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((lang) => lang.toLowerCase())
    .filter((lang) => lang.length === 2);
};

const customLanguageDetector = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    const _state = store.getState();
    const userLanguage = _state.userInfo.uiSettings?.language;
    if (userLanguage) {
      callback(userLanguage); // If found in redux, use it
    } else {
      const languages = getTwoLetterLangCodes();
      if (languages.length > 0) {
        if (languages.includes("fi")) {
          callback("fin"); // if finnish is on the list use fin
        } else {
          callback(languages[0]); // Use the first language in the array
        }
      } else {
        callback("en"); // Fallback to English if no languages are found
      }
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export default function initializeI18n() {
  i18n
    .use(customLanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
      supportedLngs: ["fin", "en", "et", "de", "sv"], // list all supported languages
      fallbackLng: "en",
      backend: {
        // Custom load function to handle fallback
        loadPath: (languages) => {
          return `https://fondion-translations.s3.eu-central-1.amazonaws.com/docean-translations-${
            languages[0] === "fin" ? "fi" : languages[0]
          }.json?_=${buildVersion}`;
        },
        request: async (options, url, payload, callback) => {
          const matches = url.match(/docean-translations-([a-z]{2,3}).json/);
          const lng = matches ? matches[1] : "en";

          const loadLocal = async () => {
            // Fallback to local file if error occurs
            const localUrl = `${window.location.origin}/locales/${
              lng === "fi" ? "fin" : lng
            }/translations.json`;
            try {
              const localResponse = await axios.get(localUrl);
              callback(null, {
                status: localResponse.status,
                data: localResponse.data,
              });
            } catch (localError) {
              callback(localError.message, { status: 404 });
            }
          };
          try {
            // Try to fetch from S3
            const response = await axios.get(url);

            if (response.status === 200)
              callback(null, {
                status: response.status,
                data: response.data,
              });
            else {
              await loadLocal();
            }
          } catch {
            await loadLocal();
          }
        },
      },
      ns: ["translations"], // Add this to match your file name
      defaultNS: "translations", // Set this to your default namespace
      keySeparator: false,
      interpolation: {
        escapeValue: false, // React already escapes values
      },
      convertDetectedLanguage: (lng) => {
        return lng === "fi" ? "fin" : lng;
      },
    });
}

import "react";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "../functions/useWindowDimensions";
import AppInfo from "../../../common/screens/AppInfo";
import { ThemeContext } from "../../../common/theming/theme-context";
import HeaderBar from "../components/HeaderBar";

function AppInfoWrapper() {
  let history = useHistory();
  let location = useLocation();
  const { width, height } = useWindowDimensions();

  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div style={theming.theme.mainContainer}>
          <HeaderBar />
          <AppInfo
            navigation={history}
            location={location}
            pageW={width}
            pageH={height}
          />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

export default AppInfoWrapper;

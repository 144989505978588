import "react";
import { Text, View } from "react-native";

export default function ItemContentRow({
  index = 0,
  theme,
  colors,
  textLeft,
  textRight,
}) {
  return (
    <View
      style={{
        flex: 1,
        padding: 8,
        backgroundColor: index % 2 == 0 ? colors.lightBg : colors.lightAccent2,
      }}
    >
      <View style={theme.customerInfo}>
        <Text style={theme.optionTextLeft}>{textLeft}</Text>
        <View style={theme.flexGrow}>
          <Text style={theme.optionTextRight}>{textRight}</Text>
        </View>
      </View>
    </View>
  );
}

import React from "react";
import { View, Platform } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { useTranslation } from "react-i18next";

import EmailInput from "../components/EmailInput";
import PasswordInput from "../components/PasswordInput";
import DualButtons from "../components/DualButtons";
import Picker from "../components/Picker";
import GoogleLogin from "./GoogleLoginButton";
import AppleLoginButton from "./AppleLoginButton";

export default function SignIn(props) {
  const { t } = useTranslation();

  const passInput = React.useRef();

  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <>
          <View
            style={
              Platform.OS === "web"
                ? theming.theme.signInContainer
                : theming.theme.mobileSignInContainer
            }
          >
            <Picker
              t={t}
              theme={theming.theme}
              colors={theming.colors}
              screen={props.screen}
              setScreen={props.setScreen}
            />

            <View style={theming.theme.signInFormContainer}>
              <EmailInput
                t={t}
                onSubmitEditing={() => passInput.current?.focus()}
              />
              <PasswordInput
                t={t}
                ref={passInput}
                label={t("password")}
                passStrengthBar={false}
                onSubmitEditing={props.onRightButtonPress}
              />

              {Platform.OS === "ios" ? (
                <AppleLoginButton
                  handleApiRequestResponse={props.handleApiRequestResponse}
                  setLoading={props.setLoading}
                  stopLoading={props.stopLoading}
                  isFetching={props.isLoading}
                />
              ) : null}

              <GoogleLogin
                handleApiRequestResponse={props.handleApiRequestResponse}
                setLoading={props.setLoading}
                stopLoading={props.stopLoading}
                isFetching={props.isLoading}
              />
            </View>

            {Platform.OS === "web" ? (
              <DualButtons
                isFetching={props.isLoading}
                leftButtonTitle={t("forgotPassword")}
                onLeftButtonPress={props.onLeftButtonPress}
                rightButtonTitle={t("signIn")}
                onRightButtonPress={props.onRightButtonPress}
              />
            ) : null}
          </View>

          <View style={theming.theme.ssoLoginContainer} />

          {Platform.OS !== "web" ? (
            <DualButtons
              leftButtonTitle={t("forgotPassword")}
              onLeftButtonPress={props.onLeftButtonPress}
              rightButtonTitle={t("signIn")}
              onRightButtonPress={props.onRightButtonPress}
              isFetching={props.isLoading}
            />
          ) : null}
        </>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Text, View, FlatList } from "react-native";
import Modal from "./Modal";
import SingleCheckBoxRow from "./SingleCheckBoxRow";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import ButtonGroup from "./ButtonGroup";
import { useTranslation } from "react-i18next";

export default function CheckBoxPicker(props) {
  const { t } = useTranslation();
  const renderListElem = (theme) => {
    return (
      <FlatList
        keyboardShouldPersistTaps="handled"
        initialNumToRender={15}
        data={props.data}
        keyExtractor={(item, index) => "checkBoxPicker" + index + item.valueKey}
        ItemSeparatorComponent={() => <View style={theme.line} />}
        ListEmptyComponent={() => (
          <View style={theme.modalButtonContainer}>
            <Text style={theme.text}>{t("emptyList")}</Text>
          </View>
        )}
        renderItem={({ item }) => {
          const { id, connected } = item;

          return (
            <View
              style={[
                {
                  flex: 1,
                  padding: 8,
                  paddingLeft: 0,
                },
              ]}
            >
              <SingleCheckBoxRow
                boldTitle={true}
                title={id}
                checked={connected}
                onCheckBoxPress={() => props.onCheckBoxPress(item)}
              />
            </View>
          );
        }}
      />
    );
  };
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.toggleModal}
          onBackdropPress={props.toggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={theme.flex}>
            <View style={theme.modalButtonContainer}>
              <Text style={theme.title}>{props.title}</Text>
            </View>
            <View style={theme.flex}>{renderListElem(theme, colors)}</View>
            <View style={theme.buttonContainer}>
              <ButtonGroup
                buttons={[
                  {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: t("close"),
                    onPress: () => props.toggleModal(),
                  },
                  ...props.extraButtons,
                ]}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { useTranslation } from "react-i18next";
import HeaderBar from "./HeaderBar";

export default function PublicHeaderBar() {
  const { t } = useTranslation();
  return (
    <HeaderBar
      linkTo="/"
      paths={[
        {
          pathname: "/companyRegistration",
          title: t("startTrial"),
          highlightButton: true,
          alwaysShow: true,
        },
        {
          pathname: "/pricing",
          title: t("pricing"),
        },
        {
          pathname: "/contact",
          title: t("customerService"),
        },
        {
          pathname: "/signin",
          title: t("signIn"),
          highlightButton: true,
          alwaysShow: true,
        },
      ]}
    />
  );
}

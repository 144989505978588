import moment from "moment";
import { getNewSortObj } from "../lib/functions";

export default function sortBy(field, reverse, primer, alternateField) {
  let key = primer
    ? function (x) {
        return primer(x[field] || x[alternateField]);
      }
    : function (x) {
        return x[field] || x[alternateField];
      };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
}

export function sortWithoutField(reverse, primer) {
  let key = primer
    ? function (x) {
        return primer(x);
      }
    : function (x) {
        return x;
      };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
}

// const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ";
// const alphabetically = (a, b) => {
//   (a = a), (b = b);
//   // Find the first position were the strings do not match
//   let position = 0;
//   while (a[position] === b[position]) {
//     // If both are the same don't swap
//     if (!a[position] && !b[position]) return 0;
//     // Otherwise the shorter one goes first
//     if (!a[position]) return 1;
//     if (!b[position]) return -1;
//     position++;
//   }
//   // Then sort by the characters position
//   return alphabet.indexOf(a[position]) - alphabet.indexOf(b[position]);
// };

const getItemDate = (item, comparable) => {
  if (
    (Object.prototype.hasOwnProperty.call(item, "status") &&
      item.id.startsWith("do")) ||
    item.docRef
  ) {
    return item.date;
  } else {
    return item[comparable];
  }
};
const compareStrings = (a, b, reversed) => {
  if (reversed) {
    return b.localeCompare(a, "swe", { sensitivity: "base", numeric: true });
  } else {
    return a.localeCompare(b, "swe", { sensitivity: "base", numeric: true });
  }
};
const compareDates = (a, b, reversed) => {
  if (reversed) {
    return b - a;
  } else {
    return a - b;
  }
};

const comparableIsDate = (comparable, sortObj) =>
  comparable === "lastModified" ||
  comparable === "date" ||
  sortObj?.type === "date";

const primer = (
  val,
  getColValue,
  isDateCompare,
  comparable,
  sortObj,
  lang,
  sortWithoutTime
) => {
  if (isDateCompare) {
    const _moment = moment(getItemDate(val, comparable) ?? "");
    if (sortWithoutTime) {
      return _moment.set({
        hour: 10,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    } else {
      return _moment;
    }
  } else {
    return (getColValue(val, sortObj, lang) ?? "")
      ?.toString()
      .toUpperCase()
      .replace(/[\s]/gi, "");
  }
};

const comparer = (
  lang,
  a,
  b,
  getColValue,
  isDateCompare,
  sortObj,
  comparable,
  reversed,
  sortWithoutTime
) => {
  return (isDateCompare ? compareDates : compareStrings)(
    primer(
      a,
      getColValue,
      isDateCompare,
      comparable,
      sortObj,
      lang,
      sortWithoutTime
    ),
    primer(
      b,
      getColValue,
      isDateCompare,
      comparable,
      sortObj,
      lang,
      sortWithoutTime
    ),
    reversed
  );
};

const sortWithoutTime = false;
export const sortUsingColData = (
  data,
  columns,
  sortArrIndex,
  keepReverse,
  getColValue,
  getSortOrder,
  lang,
  multiColumnSort
  // sortWithoutTime // ! why was this necessary?
) => {
  const _sortArrIndex = sortArrIndex === -1 ? 0 : sortArrIndex;
  const sortObj = columns[_sortArrIndex];
  const comparable = sortObj?.comparable;
  const reversed = keepReverse ? sortObj?.reversed : !sortObj?.reversed;
  const isDateCompare = comparableIsDate(comparable, sortObj);

  const time = moment().valueOf();

  let fields = [
    {
      sortObj,
      sortedAt: keepReverse ? sortObj?.sortedAt ?? time : time,
      reversed,
      comparable,
      isDateCompare,
    },
  ];

  if (multiColumnSort) {
    // get extra fields to sort with
    for (let i = 0; i < columns.length; i++) {
      const _sortObj = columns[i];
      if (i !== _sortArrIndex && _sortObj?.sorted) {
        const _comparable = _sortObj.comparable;
        const _reversed = _sortObj.reversed;
        const _isDateCompare = comparableIsDate(_comparable, _sortObj);
        fields.push({
          sortedAt: _sortObj.sortedAt ?? time,
          sortObj: _sortObj,
          reversed: _reversed,
          comparable: _sortObj.comparable,
          isDateCompare: _isDateCompare,
        });
      }
    }
    fields.sort((a, b) => compareDates(a.sortedAt, b.sortedAt));
  }

  const compareFields = (a, b) => {
    let result, field;
    for (let i = 0; i < fields.length; i++) {
      result = 0;
      field = fields[i];
      result = comparer(
        lang,
        a,
        b,
        getColValue,
        field.isDateCompare,
        field.sortObj,
        field.comparable,
        field.reversed,
        sortWithoutTime
      );
      if (result !== 0) break;
    }
    return result;
  };

  const orderBySort = (a, b) => {
    // TODO fix nested item sort in folders, should stay under parent but the nested items should get sorted
    // if (a.parentId === b.id) {
    //   return 1;
    // } else if (b.parentId === a.id) {
    //   return -1;
    // }
    if (getSortOrder) {
      const aToCompare = primer(
        a,
        getColValue,
        isDateCompare,
        comparable,
        sortObj,
        lang,
        sortWithoutTime
      );
      const aType = getSortOrder(a, b);
      const bType = getSortOrder(b, a);

      if (!aToCompare) {
        return 1;
      }
      if (aType == 1) {
        if (bType == 1) {
          return compareFields(a, b); // compareFn(aToCompare, bToCompare, reversed);
        }
        return 1;
      }
      if (aType == 2) {
        if (bType == 2) {
          return compareFields(a, b); //compareFn(aToCompare, bToCompare, reversed);
        }
        return 1;
      }
      if (aType == bType) {
        return compareFields(a, b); //compareFn(aToCompare, bToCompare, reversed);
      }
      return aType !== bType ? 1 : -1;
    } else {
      return compareFields(a, b);
    }
  };

  return {
    newArr: data.sort(orderBySort),
    newSortObj: getNewSortObj(
      columns,
      _sortArrIndex,
      reversed,
      multiColumnSort,
      time
    ),
  };
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/app-logo.png";
import IconButton from "../../../common/components/IconButton";
import { ThemeContext } from "../../../common/theming/theme-context";
import {
  capitalizeFirstLetter,
  filterLayouts,
  getNewestLayoutVersion,
  getTranslatedText,
  isArrayWithItems,
  isSameOrGreaterThanRole,
  permissionCheck,
} from "../../../common/lib/functions";
import {
  companyWidePermissions,
  fallbackLangCodes,
  isDevBuild,
} from "../../../common/lib/constants";
import { connect } from "react-redux";
import {
  signOut,
  SET_UI_SETTINGS_PROP,
} from "../../../common/actions/ProfileActions";
import MenuComponent from "../../../common/components/MenuComponent";
import naturalCompare from "../../../common/lib/naturalCompare";
import useWindowDimensions from "../functions/useWindowDimensions";
import { showToast } from "../../../common/lib/helperFns";
import { bindActionCreators } from "redux";
import i18next from "i18next";

function HeaderButton(props) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={
        props.path.highlightButton
          ? isHovered
            ? props.theme.mouseOverHighlightNavigationItem
            : props.theme.mouseOverNavigationItem
          : isHovered
          ? props.theme.mouseOverNavigationItem
          : props.theme.navigationItem
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseUp={props.onNavItemClick(props.path)}
      className={props.width < 800 ? "marginV16" : ""}
    >
      {props.path.title}
    </div>
  );
}

function HeaderBar(props) {
  const theming = React.useContext(ThemeContext);
  const theme = props.lightTheme ? theming.lightTheme : theming.theme;
  const colors = props.lightTheme ? theming.lightColors : theming.colors;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { profile, company } = props;
  const [languageSwitchIsHovered, setLanguageSwitchIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setLanguageSwitchIsHovered(true);
  };

  const handleMouseLeave = () => {
    setLanguageSwitchIsHovered(false);
  };

  const paths = React.useMemo(() => {
    const isAdmin = isSameOrGreaterThanRole(profile?.role, "Admin");
    // TODO get pickerobjects dynamically if backend supports it
    // let pickerObjects = [];
    // if (props.pickerObjects) {
    //   // registry header
    //   pickerObjects.push({
    //     title: t("registers"),
    //     // allowedRoles: ["User", "Manager", "Boss", "Admin", "SuperAdmin"],
    //     subItems: [],
    //   });
    //   Object.entries(
    //     filterLayouts(props.pickerObjects, props.company.id)
    //   ).forEach(([key, cur]) => {
    //     pickerObjects[0].subItems.push({
    //       layoutItem: {
    //         component: "pickerObjBrowse",
    //         item: {
    //           prop: pickerObjectProps[key].optionsProp,
    //         },
    //       },
    //       pathname: encodeURI(`/registry/${key.split("/")[1]}`),
    //       title: getTranslatedText(pickerObjectProps[key].title, props.lang),
    //       // allowedRoles: ["User", "Manager", "Boss", "Admin", "SuperAdmin"],
    //     });
    //   });
    // }
    let paths = props.paths ?? [
      {
        pathname: "/browse",
        title: t("docs"),
      },
      {
        pathname: "/create",
        title: t("docCreation"),
      },
      {
        layoutItem: {
          component: "folders",
          item: { name: "folders" },
        },
        permissionKeys: ["folders"],
        layoutType: "folders",
        pathname: "/folders",
        title: t("folders"),
      },
      // ...pickerObjects,
      {
        title: t("registers"),
        subItems: [
          {
            layoutItem: {
              component: "pickerObjBrowse",
              item: {
                prop: "sites",
              },
            },
            permissionKeys: ["sites"],
            pathname: "/sites",
            title: t("manageSites"),
            allowedRoles: ["User", "Manager", "Boss", "Admin", "SuperAdmin"],
          },
          {
            layoutItem: {
              component: "pickerObjBrowse",
              item: {
                prop: "customers",
              },
            },
            permissionKeys: ["customers"],
            pathname: "/customers",
            title: t("manageCustomers"),
            allowedRoles: ["User", "Manager", "Boss", "Admin", "SuperAdmin"],
          },
          {
            layoutItem: {
              component: "pickerObjBrowse",
              item: {
                prop: "instruments",
              },
            },
            permissionKeys: ["instruments"],
            pathname: "/instruments",
            title: t("Instruments"),
            allowedRoles: ["User", "Manager", "Boss", "Admin", "SuperAdmin"],
          },
          {
            layoutItem: {
              component: "products",
              item: {},
            },
            permissionKeys: ["products"],
            pathname: "/products",
            title: t("Products"),
            allowedRoles: ["Manager", "Boss", "Admin", "SuperAdmin"],
            // extraChecks: [
            //   company?.features?.some((x) => x === 0 || x === 1),
            //   // ! DEPRECATED
            //   //company?.id === "Document_Ocean_Oy" || company?.id === "Antti_Boi_Oy",
            // ],
          },
          {
            layoutItem: {
              component: "docs",
              item: {
                initialArrName: "orderConfirmations",
              },
            },
            pathname: "/browse?arrName=orderConfirmations",
            title: t("orders"),
            allowedRoles: ["Manager", "Boss", "Admin", "SuperAdmin"],
            extraChecks: [
              company?.features?.some((x) => x === 0 || x === 1),
              // ! DEPRECATED
              //company?.id === "Document_Ocean_Oy" || company?.id === "Antti_Boi_Oy",
            ],
          },
        ],
      },
      {
        title: t("projectTool"),
        hideFromHeader: true,
        subItems: [
          {
            layoutItem: {
              component: "projects",
              item: {},
            },
            permissionKeys: ["projects"],
            pathname: "/projects",
            title: t("scheduling"),
            allowedRoles: ["Manager", "Boss", "Admin", "SuperAdmin"],
            // extraChecks: [
            //   company?.features?.some((x) => x === 0),
            //   // ! DEPRECATED
            //   // company?.id === "database_77" ||
            //   //   company?.id === "database_101" ||
            //   //   company?.id === "Loistoset_Oy" ||
            //   //   company?.id === "Document_Ocean_Oy" ||
            //   //   company?.id === "Antti_Boi_Oy",
            // ],
          },
          {
            layoutItem: {
              component: "tasks",
              item: {},
            },
            permissionKeys: ["projects"],
            pathname: "/tasks",
            title: t("tasks"),
            allowedRoles: ["Manager", "Boss", "Admin", "SuperAdmin"],
            // extraChecks: [
            //   company?.features?.some((x) => x === 0 || x === 1),
            //   //company?.id === "Document_Ocean_Oy" || company?.id === "Antti_Boi_Oy",
            // ],
          },
          {
            layoutItem: {
              component: "taskCalendar",
              item: {},
            },
            pathname: "/taskCalendar",
            title: t("calendar"),
            // extraChecks: [
            //   company?.features?.some((x) => x === 0 || x === 1),
            //   //company?.id === "Document_Ocean_Oy" || company?.id === "Antti_Boi_Oy",
            // ],
          },
        ],
      },
      {
        title: t("layouts"),
        pathname: "/layoutEditor",
        allowedRoles: ["Trial", "Manager", "Boss", "Admin", "SuperAdmin"],
        trialNotification: `${t("layoutEditor")} ${t(
          "onlyInFullVersion"
        ).toLowerCase()}`,
      },
      {
        title: t("settingsPanel"),
        pathname: "/profile",
        subItems: [
          {
            pathname: `/company?id=${company?.id}&tab=info`,
            title: t("companyPanel"),
            allowedRoles: ["Manager", "Boss", "Admin", "SuperAdmin"],
          },
          {
            pathname: "/companies",
            title: t("companiesPanel"),
            allowedRoles: ["Admin", "SuperAdmin"],
          },
          {
            pathname: "/appSettings",
            title: t("appSettings"),
            allowedRoles: ["SuperAdmin"],
          },
          {
            pathname: "/profile",
            title: t("profile"),
            allowedRoles: [
              "Trial",
              "User",
              "Manager",
              "Boss",
              "Admin",
              "SuperAdmin",
            ],
          },
          {
            pathname: "/settings",
            title: t("settings"),
            allowedRoles: [
              "Trial",
              "User",
              "Manager",
              "Boss",
              "Admin",
              "SuperAdmin",
            ],
          },
          {
            pathname: "/productivity",
            title: t("productivity"),
            allowedRoles: ["Admin", "SuperAdmin"],
          },
        ],
      },
    ];

    if (props.layouts) {
      const keys = Object.keys(
        filterLayouts(props.layouts, company?.id, props.profile?.role)
      );
      keys.forEach((key) => {
        const layoutWrapper = props.layouts[key];
        const x = getNewestLayoutVersion(props.layouts, key, isAdmin);
        if (isArrayWithItems(x.docValuesVisualization)) {
          paths.push({
            hideFromHeader: true,
            pathname: `/document-statistics?layoutId=${encodeURIComponent(
              x.layoutId
            )}`,
            title: getTranslatedText(layoutWrapper?.type, i18next.language),
          });
        }

        if (
          (isDevBuild && x.layoutId === "layouts/19500-A") ||
          x.usage === "dailyPerSite"
        ) {
          paths.push({
            hideFromHeader: true,
            pathname: `/daily-site-document?layoutId=${encodeURIComponent(
              x.layoutId
            )}`,
            title: getTranslatedText(layoutWrapper?.type, i18next.language),
          });
        }
      });
    }

    const _permissionCheck = (x) => {
      const isAllowedRole =
        !x.allowedRoles || x.allowedRoles.includes(profile?.role);
      const passedExtraChecks = !x.extraChecks || x.extraChecks.every((y) => y);
      const permissionsPassed = permissionCheck(
        companyWidePermissions[props.company?.id],
        x.permissionKeys,
        profile?.role,
        ["read"]
      );
      return isAllowedRole && passedExtraChecks && permissionsPassed;
    };

    let retVal = [];
    for (let i = 0; i < paths.length; i++) {
      const x = paths[i];
      if (x.subItems) {
        let _subItems = [];
        x.subItems.forEach((_x) => {
          if (_permissionCheck(_x)) {
            _subItems.push(_x);
          }
        });
        if (_subItems.length > 0) {
          retVal.push({ ...x, subItems: _subItems });
        }
      } else {
        if (_permissionCheck(x)) {
          retVal.push(x);
        }
      }
    }
    return retVal;
  }, [t, props.layouts, props.paths, profile, company]);

  const [menu, setMenu] = useState({});

  const handleSignOut = () => {
    window.localStorage.removeItem("token");
    props.signOut();
  };

  const onNavItemClick = (path) => (ev) => {
    if (path.trialNotification && props.profile.role === "Trial") {
      showToast(path.trialNotification, 5000, "accent");
      return;
    }
    if (path.subItems) {
      setMenu({
        visible: true,
        textProp: "title",
        anchorEl: ev.currentTarget,
        data: path.subItems
          .map((x) => ({
            title: x.title,
            isNavItem: true,
            action: onNavItemClick(x),
          }))
          .sort((a, b) => naturalCompare(a.title, b.title)),
      });
    } else {
      if (path?.pathname) {
        if (path.pathname && ev.button === 1) {
          window
            .open(`${window.location.origin}${path.pathname}`, "_blank")
            ?.focus();
        } else {
          history.push(path.pathname);
        }
      }
    }
  };

  const getMenuPaths = () => {
    let _arr = paths
      .reduce((arr, x) => {
        if (x.subItems) {
          x.subItems.forEach((_x) => {
            arr.push({
              title: _x.title,
              isNavItem: true,
              action: onNavItemClick(_x),
            });
          });
        } else {
          arr.push({
            title: x.title,
            isNavItem: true,
            action: onNavItemClick(x),
          });
        }
        return arr;
      }, [])
      .sort((a, b) => naturalCompare(a.title, b.title));

    if (!props.paths)
      _arr.push({
        title: t("signOut"),
        action: handleSignOut,
      });
    return _arr;
  };

  function toggleMenu(event) {
    setMenu((_) =>
      _.anchorEl || !event?.currentTarget
        ? {}
        : {
            visible: true,
            textProp: "title",
            anchorEl: event?.currentTarget,
            data: getMenuPaths(),
          }
    );
  }

  return (
    <React.Fragment>
      <div style={theme.webHeader}>
        {props.outsideLink ? (
          <a
            rel="noopener noreferrer"
            href={props.linkTo ?? props.uiSettings?.homeScreen ?? "browse"}
            style={theme.hPadding}
          >
            <img
              className="headerLogo"
              src={props.logo || theming.logo}
              alt={t("logo")}
            />
          </a>
        ) : (
          <Link
            to={props.linkTo ?? props.uiSettings?.homeScreen ?? "browse"}
            style={theme.hPadding}
          >
            {props.smalllogo ? (
              <img
                height="32"
                style={{ paddingLeft: 8, paddingRight: 8, display: "block" }}
                src={props.logo || logo}
                alt={t("logo")}
              />
            ) : (
              <img
                style={{
                  height: 28,
                  paddingLeft: 8,
                  paddingRight: 8,
                  display: "block",
                }}
                className="headerLogo"
                src={theming.logo}
                alt={t("logo")}
              />
            )}
          </Link>
        )}

        {props.children}

        {props.menu ??
          (paths.length > 0 ? (
            <>
              <div style={theme.headerBarItem}>
                {paths.map((path, i) => {
                  return path.hideFromHeader ||
                    width < 625 ||
                    (width < 850 && !path.alwaysShow) ? null : (
                    <HeaderButton
                      key={i + path.title}
                      path={path}
                      onNavItemClick={onNavItemClick}
                      theme={theme}
                      colors={colors}
                      width={width}
                    />
                  );
                })}
              </div>
              <div style={theme.hPadding}>
                <div
                  style={
                    languageSwitchIsHovered
                      ? theme.mouseOverNavigationItem
                      : theme.navigationItem
                  }
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onMouseUp={(event) => {
                    const _languageCodes =
                      props.languageCodes ?? fallbackLangCodes;

                    setMenu({
                      visible: true,
                      textProp: "title",
                      anchorEl: event?.currentTarget,
                      data: i18n.options.supportedLngs
                        .filter((lng) => lng !== "cimode")
                        .map((x) => {
                          const found = _languageCodes.find(
                            (y) => y.code === x
                          );
                          return {
                            title: found
                              ? capitalizeFirstLetter(
                                  t(found.name.toLowerCase())
                                )
                              : x.toUpperCase(),
                            action: () => {
                              i18n.changeLanguage(x);
                              props.SET_UI_SETTINGS_PROP({
                                prop: "language",
                                value: x,
                              });
                            },
                          };
                        }),
                    });
                  }}
                >
                  {(i18next.language === "fin"
                    ? "fi"
                    : i18next.language
                  ).toUpperCase()}
                </div>
              </div>

              <div style={theme.hPadding}>
                <IconButton
                  theme={theme}
                  loading={false}
                  onPress={toggleMenu}
                  icon="menu"
                  color={colors.text}
                />
              </div>
              <MenuComponent handleClose={toggleMenu} rest={menu} />
            </>
          ) : null)}
      </div>
      <div style={theme.webHeaderFiller} />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      SET_UI_SETTINGS_PROP,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    uiSettings: state.userInfo.uiSettings,
    profile: state.userInfo.profile,
    company: state.userInfo.company,
    languageCodes: state.userInfo.uiSettings?.languageCodes,
    layouts: state.options?.layouts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);

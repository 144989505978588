import React from "react";
import { Platform, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import SimpleForm from "./SimpleForm";
// import { fullHp, fullWp } from "../lib/helperFns";
import { useTranslation } from "react-i18next";

function TrialRegister(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState();
  const normalInputs = [
    {
      key: "email",
      title: { all: props.t("email") },
      type: "textField",
      keyboardType: "email-address",
      validations: [
        { fn: "validateEmail", errMsg: { all: props.t("checkEmail") } },
      ],
      onSaveActions: ["toLowerCase", "removeWhitespace"],
      value: props.email,
      required: true,
    },
    {
      key: "password",
      title: { all: props.t("newPassword") },
      type: "textField",
      password: true,
      passwordStrength: true,
      required: true,
    },
    {
      key: "passwordConf",
      title: { all: props.t("passwordConf") },
      type: "textField",
      password: true,
      required: true,
    },
  ];
  const thirdPartyInputs = [];
  const infoInputs = [
    {
      key: "name",
      title: { all: props.t("firstName") },
      type: "textField",
      value: t("john"),
      required: true,
    },
    {
      key: "lName",
      title: { all: props.t("lastName") },
      type: "textField",
      value: t("doe"),
      required: true,
    },
    {
      key: "phone",
      title: { all: props.t("phone") },
      type: "textField",
      validations: [
        { fn: "validatePhoneNumber", errMsg: { all: props.t("checkPhone") } },
      ],
      numeric: true,
    },
    {
      key: "companyInfo",
      title: { all: props.t("company") },
      type: "title",
    },
    {
      key: "companyName",
      title: { all: props.t("name") },
      type: "textField",
      value: "TrialCompany",
      required: true,
    },
    {
      key: "companyId",
      title: { all: props.t("companyId") },
      type: "textField",
      value: "12345-A",
      required: true,
      onSaveActions: ["removeWhitespace"],
    },
    {
      key: "companyAddr",
      title: { all: props.t("address") },
      type: "textField",
      value: t("mockAddress"),
      required: true,
    },
    {
      key: "companyPhone",
      title: { all: props.t("phone") },
      type: "textField",
      validations: [
        { fn: "validatePhoneNumber", errMsg: { all: props.t("checkPhone") } },
      ],
      numeric: true,
    },
    {
      key: "companyPostalCode",
      title: { all: props.t("zipcode") },
      type: "textField",
      value: "01000",
      numeric: true,
      required: true,
    },
    {
      key: "companyCity",
      title: { all: props.t("city") },
      type: "textField",
      value: t("mockCity"),
      required: true,
    },
    {
      key: "ewManager",
      title: { all: props.t("ewManager") },
      type: "title",
    },
    {
      key: "companyEwManagerName",
      title: { all: props.t("firstName") },
      type: "textField",
    },
    {
      key: "companyEwManagerLName",
      title: { all: props.t("lastName") },
      type: "textField",
    },
    {
      key: "companyEwManagerEmail",
      title: { all: props.t("email") },
      type: "textField",
      validations: [
        { fn: "validateEmail", errMsg: { all: props.t("checkEmail") } },
      ],
      onSaveActions: ["toLowerCase", "removeWhitespace"],
    },
    {
      key: "companyEwManagerPhone",
      title: { all: props.t("phone") },
      type: "textField",
      validations: [
        { fn: "validatePhoneNumber", errMsg: { all: props.t("checkPhone") } },
      ],
    },
    {
      key: "marketingEmails",
      title: { all: props.t("marketingEmailAgreement") },
      type: "agreementCheckbox",
      onSaveActions: ["trim"],
    },
    {
      key: "contactByPhone",
      title: { all: props.t("phoneCallAgreement") },
      type: "agreementCheckbox",
      onSaveActions: ["trim"],
    },
    {
      key: "criticalEmails",
      title: { all: props.t("importantEmailAgreement") },
      type: "agreementCheckbox",
      onSaveActions: ["trim"],
    },
    {
      key: "gdpr",
      title: { all: props.t("haveReadAndAgree") },
      type: "agreementCheckbox",
      onSaveActions: ["trim"],
      required: true,
    },
    {
      key: "tos",
      title: { all: props.t("haveReadAndAgree") },
      type: "agreementCheckbox",
      onSaveActions: ["trim"],
      required: true,
    },
  ];

  const formInputs = [
    {
      key: "title1",
      title: { all: props.t("ownInformation") },
      type: "title",
    },
    ...(props.thirdParty ? thirdPartyInputs : normalInputs),
    ...infoInputs,
  ];

  const onRightButtonPress = (_inputs) => {
    setLoading(true);
    props.onRightButtonPress(_inputs, () => setLoading(false));
  };

  return (
    <ThemeContext.Consumer>
      {({ colors }) => (
        <View
          style={
            Platform.OS === "web"
              ? { flex: 1, maxHeight: "calc(100vh - 48px)", padding: 32 }
              : null
          }
        >
          <SimpleForm
            t={props.t}
            inputs={formInputs}
            lang={props.lang}
            onBackButtonPress={props.onLeftButtonPress}
            backButtonTitle={props.leftButtonTitle}
            onSaveTitle={props.rightButtonTitle}
            saveDisabled={loading}
            loading={loading}
            onSave={(values) => {
              setLoading(true);
              onRightButtonPress(values, setLoading);
            }}
            listColor={colors.primary}
            rightBtnIconWhenLoading
            disableAutoFocus
          />
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

export default TrialRegister;

import { Platform } from "react-native";
import { apiRequestWithToken /*, apiRequestWithoutToken*/ } from "../lib/api";
import { requestApiData, receiveResponse } from "../actions/ApiActions";
import {
  setDocProp,
  /*DEPRECATED*/ removePropArrItem,
  /*DEPRECATED*/ setTargetProp,
  /*DEPRECATED*/ setDataValue,
  modifyValueItem,
} from "./DocsActions";
import { clearModifyDocAction } from "../actions/ModifyDocActions";
import { setLicence, signOut } from "../actions/ProfileActions";
import {
  showExpiredTokenToast,
  showToast,
  fetchNetInfo,
} from "../lib/helperFns";
// import moment from "moment";
import i18next from "i18next";

const _requestApiData = (dispatch, setLoading) => {
  if (setLoading) {
    setLoading(true);
  } else {
    dispatch(requestApiData());
  }
};
const _receiveResponse = (dispatch, setLoading) => {
  if (setLoading) {
    setLoading(false);
  } else {
    dispatch(receiveResponse());
  }
};

export const SET_WITH_ARR = (payload) => ({
  type: "SET_WITH_ARR",
  payload,
});
export const ADD_TO_OPTION_ROOT_ARR = (payload) => ({
  type: "ADD_TO_OPTION_ROOT_ARR",
  payload,
});

export const REMOVE_FROM_OPTION_ROOT_ARR = (payload) => ({
  type: "REMOVE_FROM_OPTION_ROOT_ARR",
  payload,
});

export const SET_ATTACHMENTS_PROPS = (payload) => ({
  type: "SET_ATTACHMENTS_PROPS",
  payload,
});

export const SET_COMPANY_LOGO_LAST_MODIFIED = (payload) => ({
  type: "SET_COMPANY_LOGO_LAST_MODIFIED",
  payload,
});

export const UPDATE_ATTACHMENTS = (payload) => ({
  type: "UPDATE_ATTACHMENTS",
  payload,
});

export const PUSH_TO_TMP_ATTACHMENTS = (payload) => ({
  type: "PUSH_TO_TMP_ATTACHMENTS",
  payload,
});

export const REMOVE_TMP_ATTACHMENT = (payload) => ({
  type: "REMOVE_TMP_ATTACHMENT",
  payload,
});

export const ADD_OWNER_TO_TMP_ATTACHMENT = (payload) => ({
  type: "ADD_OWNER_TO_TMP_ATTACHMENT",
  payload,
});

export const REMOVE_OWNER_FROM_TMP_ATTACHMENT = (payload) => ({
  type: "REMOVE_OWNER_FROM_TMP_ATTACHMENT",
  payload,
});

export const SET_TMP_ATTACHMENT_PROP = (payload) => ({
  type: "SET_TMP_ATTACHMENT_PROP",
  payload,
});

export const SET_TMP_ATTACHMENT = (payload) => ({
  type: "SET_TMP_ATTACHMENT",
  payload,
});

export const REMOVE_TMP_ATTACHMENTS = (payload) => ({
  type: "REMOVE_TMP_ATTACHMENTS",
  payload,
});

export const CLEAR_TMP_ATTACHMENTS = (payload) => ({
  type: "CLEAR_TMP_ATTACHMENTS",
  payload,
});

export const ADD_ATTACHMENT = (payload) => ({
  type: "ADD_ATTACHMENT",
  payload,
});

export const setAttachmentsFetched = (payload) => ({
  type: "SET_ATTACHMENTS_FETCHED",
  payload,
});

export const MERGE_TO_ATTACHMENTS_FETCHED = (payload) => ({
  type: "MERGE_TO_ATTACHMENTS_FETCHED",
  payload,
});

export const SET_LAYOUTS = (payload) => ({
  type: "SET_LAYOUTS",
  payload,
});

export const setBaseDocVersion = (payload) => ({
  type: "SET_BASE_DOC_VERSION",
  payload,
});

export const setOptionsChecked = (payload) => ({
  type: "OPTIONS_CHECK",
  payload,
});

const modifyOptionAction = (payload) => ({
  type: "MODIFY_OPTION",
  payload,
});

export const modifyOption =
  (role, payload, modifyValueItemFn) => (dispatch) => {
    const {
      prop,
      isArray,
      currentValue,
      selectedValue,
      valueKey,
      value,
      docId,
      isUserOption,
    } = payload;

    dispatch(requestApiData());

    dispatch(
      modifyOptionAction({
        prop: prop,
        oldValue: selectedValue,
        value: value,
      })
    );
    if (
      (isArray && currentValue?.includes(selectedValue)) ||
      (!isArray && currentValue === selectedValue)
    ) {
      modifyValueItemFn
        ? modifyValueItemFn({
            valueKey: valueKey,
            itemIsArr: isArray,
            value: value,
            oldVal: selectedValue,
            docId: docId,
            replace: true,
          })
        : dispatch(
            modifyValueItem({
              valueKey: valueKey,
              itemIsArr: isArray,
              value: value,
              oldVal: selectedValue,
              docId: docId,
              replace: true,
            })
          );
    }

    fetchNetInfo().then((state) => {
      if (state.isConnected && role !== "Trial") {
        const body = {
          Option: prop,
          Value: selectedValue,
          NewValue: value,
          isUserOption: isUserOption,
        };

        return apiRequestWithToken(body, "/options/modify").then((response) => {
          dispatch(receiveResponse());

          if (response === "expired" || response === "tokenErr") {
            dispatch(signOut());
            showExpiredTokenToast();
          }
        });
      } else {
        dispatch(receiveResponse());
      }
    });
  };

export const addOption = (role, payload, setLoading) => (dispatch) => {
  _requestApiData(dispatch, setLoading);

  dispatch(addModularOption({ prop: payload.prop, value: payload.value }));

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      apiRequestWithToken(
        { option: payload.prop, value: payload.value },
        "/options/add"
      )
        .then((response) => {
          _receiveResponse(dispatch, setLoading);
          if (response.status === 200) {
            /* empty */
          } else if (response === "expired" || response === "tokenErr") {
            dispatch(signOut());
            showExpiredTokenToast();
          }
          _receiveResponse(dispatch, setLoading);
        })
        .catch(() => {
          _receiveResponse(dispatch, setLoading);
        });
    } else {
      _receiveResponse(dispatch, setLoading);
    }
  });
};

const removeOptionAction = (payload) => ({
  type: "REMOVE_OPTION",
  payload,
});

export const removeOption = (role, payload, setLoading) => (dispatch) => {
  _requestApiData(dispatch, setLoading);

  dispatch(removeOptionAction({ prop: payload.prop, oldValue: payload.value }));

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      return apiRequestWithToken(
        {
          Option: {
            [payload.prop]: [payload.value],
          },
          isUserOption: payload.isUserOption,
        },
        "/options/remove"
      ).then((response) => {
        _receiveResponse(dispatch, setLoading);

        if (response === "expired" || response === "tokenErr") {
          dispatch(signOut());
          showExpiredTokenToast();
        }
      });
    } else {
      _receiveResponse(dispatch, setLoading);
    }
  });
};

// ! DEPRECATED
const DEPRECATEDmodifyOptionAction = (payload) => ({
  type: "DEPRECATEDMODIFY_OPTION",
  payload,
});

export const DEPRECATEDmodifyOption = (role, payload) => (dispatch) => {
  dispatch(requestApiData());

  dispatch(
    DEPRECATEDmodifyOptionAction({
      prop: payload.prop,
      index: payload.index,
      value: payload.value,
    })
  );
  if (payload.prop === "protection") {
    dispatch(
      setDataValue({
        docId: payload.docId,
        dataPropName: payload.dataPropName,
        dataObjKey: payload.dataObjKey,
        measurementKey: payload.prop,
        value: payload.value,
      })
    );
  } else {
    if (payload.prop === "measuringDevices" || payload.prop === "standards") {
      const replaceArrIndex = payload.currentValue.indexOf(
        payload.selectedValue
      );
      dispatch(
        setTargetProp({
          docId: payload.docId,
          prop: payload.prop,
          value: payload.value,
          replaceArrIndex,
        })
      );
    } else if (payload.currentValue === payload.selectedValue) {
      dispatch(
        setTargetProp({
          docId: payload.docId,
          prop: payload.prop,
          value: payload.value,
        })
      );
    }
  }

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      const body = {
        Option:
          payload.prop === "protection" ? payload.prop + "s" : payload.prop,
        Value: payload.selectedValue,
        NewValue: payload.value,
      };

      return apiRequestWithToken(body, "/options/modify").then((response) => {
        dispatch(receiveResponse());

        if (response === "expired" || response === "tokenErr") {
          dispatch(signOut());
          showExpiredTokenToast();
        }
      });
    } else {
      dispatch(receiveResponse());
    }
  });
};
// ! DEPRECATED

// ! DEPRECATED
const DEPRECATEDremoveOptionAction = (payload) => ({
  type: "DEPRECATEDREMOVE_OPTION",
  payload,
});

export const DEPRECATEDremoveOption = (role, payload) => (dispatch) => {
  dispatch(requestApiData());

  const propIsArr =
    payload.prop === "measuringDevices" || payload.prop === "standards"
      ? true
      : false;

  dispatch(
    DEPRECATEDremoveOptionAction({ prop: payload.prop, index: payload.index })
  );
  if (payload.currentValue === payload.value) {
    if (propIsArr) {
      dispatch(
        removePropArrItem({
          prop: payload.prop,
          docId: payload.docId,
          value: payload.value,
        })
      );
    } else {
      dispatch(
        setTargetProp({ docId: payload.docId, prop: payload.prop, value: "" })
      );
    }
  }

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      return apiRequestWithToken(
        {
          Option: {
            [propIsArr
              ? payload.prop
              : payload.prop.slice(payload.prop.length - 1) !== "s"
              ? payload.prop + "s"
              : payload.prop]: [payload.value],
          },
        },
        "/options/remove"
      ).then((response) => {
        dispatch(receiveResponse());

        if (response === "expired" || response === "tokenErr") {
          dispatch(signOut());
          showExpiredTokenToast();
        }
      });
    } else {
      dispatch(receiveResponse());
    }
  });
};
// ! DEPRECATED

const modifyCustomerAction = (payload) => ({
  type: "MODIFY_CUSTOMER",
  payload,
});

export const modifyCustomer = (role, payload) => (dispatch) => {
  dispatch(requestApiData());

  dispatch(
    modifyCustomerAction({
      customer: payload.customer,
      customerId: payload.customerId,
    })
  );
  if (payload.currentCustomer === payload.customerName) {
    dispatch(
      setDocProp({
        docId: payload.docId,
        prop: "customer",
        value: payload.customer,
      })
    );
  }

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      return apiRequestWithToken(
        { ...payload.customer, id: payload.customerId },
        "/customers/modify"
      ).then((response) => {
        /*
          if (response.status === 200) {
            if (response.data) {
              dispatch(modifyCustomerIdAction({ oldCustomerId: payload.customerId, newCustomerId: response.data, customerObj: payload.customer }));
              dispatch(receiveResponse());
            }
          }
          */
        if (response === "expired" || response === "tokenErr") {
          dispatch(receiveResponse());
          dispatch(signOut());
          showExpiredTokenToast();
        } else {
          dispatch(receiveResponse());
        }
      });
    } else {
      dispatch(receiveResponse());
    }
  });
};

const removeCustomerAction = (id) => ({
  type: "REMOVE_CUSTOMER",
  id,
});

export const removeCustomer = (role, payload) => (dispatch) => {
  dispatch(requestApiData());

  dispatch(removeCustomerAction(payload.id));

  if (payload.currentCustomer === payload.customerName) {
    dispatch(
      setDocProp({ docId: payload.docId, prop: "customer", value: null })
    );
  }

  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      return apiRequestWithToken(
        { customer: { id: payload.id } },
        "/customers/remove"
      ).then((response) => {
        dispatch(receiveResponse());

        if (response === "expired" || response === "tokenErr") {
          dispatch(signOut());
          showExpiredTokenToast();
        }
      });
    } else {
      dispatch(receiveResponse());
    }
  });
};

export const DEPRECATEDaddOption = (payload) => ({
  type: "DEPRECATEDADD_OPTION",
  payload,
});

export const addModularOption = (payload) => ({
  type: "ADD_MODULAR_OPTION",
  payload,
});

export const updateOptions = (payload) => ({
  type: "UPDATE_OPTIONS",
  payload,
});

export const removeAttachments = (payload) => ({
  type: "REMOVE_ATTACHMENTS",
  payload,
});

const licenceToast = (routeName) => {
  if (routeName == "create" || routeName === "editForm") {
    showToast(i18next.t("docCreationLicenceErr"));
  } else if (routeName == "AttachmentsGallery") {
    showToast(i18next.t("atchLicenceErr"));
  }
};

const licenceExpired = (dispatch) => {
  dispatch(clearModifyDocAction());
  dispatch(setLicence("expired"));
  showToast(i18next.t("docAddLicenceErr"));
};

export const checkOptions =
  (body, profile, navigation, doc, routeName, fn, onlineOnly) => (dispatch) => {
    const { role } = profile;
    dispatch(setOptionsChecked(false));
    fetchNetInfo().then((state) => {
      if (state.isConnected && role !== "Trial") {
        return apiRequestWithToken(
          // {
          //   ...body,
          //   lastModified: {
          //     ...body.lastModified,
          //     company: moment()
          //       .subtract(10, "years")
          //       .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
          //   },
          //   userLastModified: {
          //     ...body.userLastModified,
          //   },
          // },
          body,
          "/options/check"
        ).then(async (response) => {
          if (response.status === 200) {
            // if (response.data.docLayout)
            //   dispatch(
            //     setBaseDocVersion({
            //       layoutId: response.data.docLayout.id,
            //       version: response.data.docLayout.version,
            //       docLayout: response.data.docLayout,
            //     }),
            //   );
            dispatch(
              updateOptions({
                data: response.data,
              })
            );
            if (response.data?.licenceExpired) {
              licenceToast(routeName);
              licenceExpired(dispatch);
            } else if (fn) fn();
            dispatch(setOptionsChecked(true));
          } else if (
            response === "expired" ||
            response === "tokenErr" ||
            response === "forbidden"
          ) {
            dispatch(signOut());
            showExpiredTokenToast();
          } else if (response === "licenceErr") {
            dispatch(setOptionsChecked(true));
            licenceToast(routeName);
            licenceExpired(dispatch);
          } else {
            dispatch(setOptionsChecked(true));
            if (response !== "networkErr") {
              showToast(i18next.t("failedToSync"));
            }
          }
        });
      } else {
        dispatch(setOptionsChecked(true));
        if (profile.licence === "expired") {
          licenceToast(routeName);
        } else if (fn) {
          if (onlineOnly && !state.isConnected) {
            showToast(i18next.t("sharedDocsEditNetError"));
          } else {
            fn();
          }
        }
      }
    });
  };

const addCustomerAction = (payload) => ({
  type: "ADD_CUSTOMER",
  payload,
});

const refreshCustomersAction = (payload) => ({
  type: "REFRESH_CUSTOMERS",
  payload,
});

export const addCustomer =
  ({ obj, docId, prop, role, customerKeys }) =>
  (dispatch) => {
    dispatch(requestApiData());

    let key = "";
    if (customerKeys.length === 0) {
      key = "localCustomers/1";
    } else {
      const ids = customerKeys.map((x) => parseInt(x.split("/")[1]));
      const id = Math.max(...ids) + 1;
      key = "localCustomers/" + id;
    }

    const localObj = { ...obj, local: true };

    fetchNetInfo().then((state) => {
      if (state.isConnected && role !== "Trial") {
        return apiRequestWithToken(obj, "/customers/add")
          .then((response) => {
            if (response.status === 200) {
              dispatch(addCustomerAction({ key: response.data, obj }));
              dispatch(setDocProp({ docId, prop, value: obj }));
              dispatch(receiveResponse());
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
            } else {
              dispatch(addCustomerAction({ key, obj: localObj }));
              dispatch(setDocProp({ docId, prop, value: localObj }));
              dispatch(receiveResponse());
            }
          })
          .catch(() => {
            dispatch(addCustomerAction({ key, obj: localObj }));
            dispatch(setDocProp({ docId, prop, value: localObj }));
            dispatch(receiveResponse());
          });
      } else {
        dispatch(addCustomerAction({ key, obj: localObj }));
        dispatch(setDocProp({ docId, prop, value: localObj }));
        dispatch(receiveResponse());
      }
    });
  };

export const refreshCustomers = (role) => (dispatch) => {
  fetchNetInfo().then((state) => {
    if (state.isConnected && role !== "Trial") {
      dispatch(requestApiData());
      return apiRequestWithToken({}, "/customers/fetch")
        .then((response) => {
          if (response.status === 200) {
            dispatch(refreshCustomersAction(response.data));
          } else if (response === "expired" || response === "tokenErr") {
            dispatch(signOut());
            showExpiredTokenToast();
          } else {
            dispatch(receiveResponse());
          }
        })
        .catch(() => {
          dispatch(receiveResponse());
        });
    } else {
      showToast(i18next.t("checkInternetConnection"));
    }
  });
};

export const refreshOptionsAction = (payload) => ({
  type: "REFRESH_OPTIONS",
  payload,
});

export const refreshOptions =
  (prop, timeStamp, role, callback, setLoading, isUserOption) => (dispatch) => {
    fetchNetInfo().then((state) => {
      if (state.isConnected && role !== "Trial") {
        _requestApiData(dispatch, setLoading);

        return apiRequestWithToken(
          { option: prop, timeStamp, isUserOption },
          "/options/update"
        )
          .then((response) => {
            if (response.status === 200) {
              dispatch(refreshOptionsAction(response.data));
              if (callback) callback(prop, response.data);
            } else if (response.status === 204) {
              _receiveResponse(dispatch, setLoading);
              if (callback) callback(prop);
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
            } else {
              _receiveResponse(dispatch, setLoading);
            }
          })
          .catch(() => {
            _receiveResponse(dispatch, setLoading);
          });
      } else {
        showToast(i18next.t("checkInternetConnection"));
      }
    });
  };

const addPickerObjOptionAction = (payload) => ({
  type: "ADD_PICKER_OBJ_OPTION",
  payload,
});

export const addPickerObjOption =
  (prop, obj, role, keys, addToDoc, setErr, onlineOnly, callback) =>
  (dispatch) => {
    dispatch(requestApiData());

    const onErr = (err) => {
      if (err) {
        setErr(i18next.t("netErrSavedLocally"));
      }
      let key = "";
      // different browsers will have the same id
      const osName =
        Platform.OS === "ios"
          ? Platform.OS + (Platform.isPad ? "pad" : "")
          : Platform.OS;
      if (!keys || keys.length === 0) {
        key = `local${osName}${prop}/1`;
      } else {
        const ids = keys
          .filter(
            (x) => x.startsWith("local") && !isNaN(parseInt(x.split("/")[1]))
          )
          .map((x) => parseInt(x.split("/")[1]));
        const id = ids.length > 0 ? Math.max(...ids) + 1 : 1;
        key = `local${osName}${prop}/${id}`;
      }

      dispatch(
        addPickerObjOptionAction({
          prop,
          key,
          obj: { ...obj, local: true, os: Platform.OS },
        })
      );
      if (addToDoc) {
        addToDoc(key, { ...obj, local: true, os: Platform.OS });
      }
      callback && callback(key, { ...obj, local: true, os: Platform.OS });
      dispatch(receiveResponse());
    };

    fetchNetInfo().then((state) => {
      if (role === "Trial") {
        onErr();
      } else if (state.isConnected) {
        return apiRequestWithToken(obj, `/${prop}/add`)
          .then((response) => {
            if (response.status === 200) {
              const _obj = { ...obj, id: response.data };
              dispatch(
                addPickerObjOptionAction({
                  prop,
                  key: response.data,
                  obj: _obj,
                })
              );
              if (addToDoc) {
                addToDoc(response.data, _obj);
              }
              callback && callback(response.data, _obj);
              dispatch(receiveResponse());
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
            } else {
              onErr(true);
            }
          })
          .catch(() => {
            onErr(true);
          });
      } else {
        if (onlineOnly) {
          setErr(i18next.t("creationToServerFailed"));
        } else {
          onErr(true);
        }
      }
    });
  };

const refreshPickerObjOptionsAction = (payload) => ({
  type: "REFRESH_PICKER_OBJ_OPTIONS",
  payload,
});

export const refreshPickerObjOptions =
  (prop, role, setErr, callback) => (dispatch) => {
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        if (role !== "Trial") {
          dispatch(requestApiData());

          return apiRequestWithToken({}, `/${prop}/fetch`)
            .then((response) => {
              if (response.status === 200) {
                dispatch(
                  refreshPickerObjOptionsAction({
                    prop,
                    data: response.data,
                    //lastModified: response.data?.lastModified,
                  })
                );
                if (callback) callback(prop, response.data);
                dispatch(receiveResponse());
              } else if (response.status !== 204) {
                if (response === "expired" || response === "tokenErr") {
                  dispatch(signOut());
                  showExpiredTokenToast();
                } else {
                  setErr(i18next.t("updateErr"));
                  dispatch(receiveResponse());
                }
              }
            })
            .catch(() => {
              dispatch(receiveResponse());
            });
        }
      } else {
        setErr(i18next.t("checkInternetConnection"));
      }
    });
  };

export const REMOVE_PICKER_OBJ_OPTION = (payload) => ({
  type: "REMOVE_PICKER_OBJ_OPTION",
  payload,
});

export const removeMultiplePickerObjects =
  ({
    role,
    prop,
    ids,
    currentId,
    handleRemoveCurrent,
    handleFailedToRemove,
    callback,
  }) =>
  async (dispatch) => {
    const removeFromOptions = (id) => {
      dispatch(REMOVE_PICKER_OBJ_OPTION({ prop, id }));
    };

    dispatch(requestApiData());
    let failedToRemove = [];

    if (role === "Trial") {
      ids.forEach((id) => dispatch(REMOVE_PICKER_OBJ_OPTION({ prop, id })));
    } else {
      const netState = await fetchNetInfo();
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        if (id.startsWith("local")) {
          removeFromOptions(id);
        } else {
          if (netState.isConnected) {
            const res = await apiRequestWithToken({ id }, `/${prop}/remove`);
            if (res.status === 200) {
              if (currentId === id && handleRemoveCurrent) {
                handleRemoveCurrent(id);
              }
              removeFromOptions(id);
            } else if (res === "expired" || res === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
              break;
            } else {
              failedToRemove.push({ reason: res, id });
            }
          } else {
            failedToRemove.push({ reason: "netErr", id });
          }
        }
      }
      dispatch(receiveResponse());
    }
    if (failedToRemove.length > 0 && handleFailedToRemove) {
      handleFailedToRemove(ids, failedToRemove);
    } else if (callback) {
      callback(ids);
    }
  };

export const removePickerObjOption =
  ({ itemName, role, prop, id, currentId, onRemove, setErr }) =>
  (dispatch) => {
    const removeFromOptions = () => {
      if (currentId === id && onRemove) onRemove(id);
      dispatch(requestApiData());
      dispatch(REMOVE_PICKER_OBJ_OPTION({ prop, id }));
    };

    if (id.startsWith("local") || role === "Trial") {
      removeFromOptions();
      dispatch(receiveResponse());
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          return apiRequestWithToken({ id }, `/${prop}/remove`)
            .then((response) => {
              if (response.status === 200) {
                removeFromOptions();
                dispatch(receiveResponse());
              } else if (response === "expired" || response === "tokenErr") {
                dispatch(signOut());
                showExpiredTokenToast();
              } else if (response.status === 409) {
                setErr(
                  `${itemName}: ${i18next.t("inUseDeletionErr").toLowerCase()}`
                );
                dispatch(receiveResponse());
              } else {
                setErr(i18next.t("removalFailed"));
                dispatch(receiveResponse());
              }
            })
            .catch(() => {});
        } else {
          setErr(i18next.t("checkInternetConnection"));
          dispatch(receiveResponse());
        }
      });
    }
  };

const modifyPickerOptionAction = (payload) => ({
  type: "MODIFY_PICKER_OPTION",
  payload,
});

export const modifyPickerOption =
  (prop, obj, role, id, currentId, addToDoc, setErr, callback) =>
  (dispatch) => {
    const _obj = {
      ...obj,
      relations: obj.relations
        ? obj.relations.map((x) => (typeof x === "string" ? x : x.id))
        : undefined,
    };
    dispatch(requestApiData());

    const onErr = () => {
      setErr(i18next.t("netErrModifiedLocally"));
      dispatch(
        modifyPickerOptionAction({
          prop,
          oldId: id,
          obj: _obj,
        })
      );
      dispatch(receiveResponse());
    };

    fetchNetInfo().then((state) => {
      if (role === "Trial") {
        onErr();
      } else if (state.isConnected) {
        return apiRequestWithToken({ ..._obj, id }, `/${prop}/modify`).then(
          (response) => {
            if (response.status === 200) {
              if (response.data) {
                dispatch(
                  modifyPickerOptionAction({
                    prop,
                    oldId: id,
                    newId: response.data,
                    obj: _obj,
                  })
                );
                if (addToDoc && currentId === id) addToDoc(response.data, _obj);
                else if (callback) callback(response.data, _obj);
              }
              dispatch(receiveResponse());
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(receiveResponse());
              dispatch(signOut());
              showExpiredTokenToast();
            } else {
              onErr(true);
            }
          }
        );
      } else {
        onErr(true);
      }
    });
  };

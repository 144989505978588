import React from "react";
import HeaderBar from "../components/HeaderBar";
import PublicHeaderBar from "../components/PublicHeaderBar";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeContext } from "../../../common/theming/theme-context";
import SignInScreen from "../../../common/screens/SignInScreen";
import routes from "../../../common/lib/LinkRoutes";
// REDUX
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  changeEmail,
  SET_DEEP_LINK_PARAMS,
} from "../../../common/actions/ProfileActions";
import Loading from "./Loading";
// REDUX

function SignInWrapper(props) {
  let history = useHistory();
  const location = useLocation();
  const [navState, setNavState] = React.useState(null);

  const isSignInRoute =
    location.pathname.toLowerCase() === "/signin" ||
    location.pathname.toLowerCase() === "/";

  React.useEffect(() => {
    // TODO rework if using apple auth in web
    if (location.pathname === "/apple/redirect") {
      // const tokenFromUrl = searchParams.get("token");
      // const errorFromUrl = searchParams.get("error");
      // if (tokenFromUrl) {
      //   getWithToken("/users/userData", tokenFromUrl).then((res) => {
      //     if (res.status === 200) {
      //       handleApiRequestResponse(res);
      //     } else if (res === "timeout") {
      //       showToast(t("lostConnectionToServer"));
      //     } else {
      //       showToast(
      //         t("unhandledError") +
      //           (res?.response?.data?.ErrorId
      //             ? ` ${t("errorCode")}: ${res?.response?.data?.ErrorId}`
      //             : "")
      //       );
      //     }
      //   });
      // } else if (errorFromUrl) {
      //   showToast(t("unhandledError"));
      // }
    } else if (location.pathname === "/google/redirect") {
      // const tokenFromUrl = searchParams.get("token");
      // const errorFromUrl = searchParams.get("error");
      // if (tokenFromUrl) {
      //   getWithToken("/users/userData", tokenFromUrl).then((res) => {
      //     if (res.status === 200) {
      //       handleApiRequestResponse(res);
      //     } else if (res === "timeout") {
      //       showToast(t("lostConnectionToServer"));
      //     } else {
      //       showToast(
      //         t("unhandledError") +
      //           (res?.response?.data?.ErrorId
      //             ? ` ${t("errorCode")}: ${res?.response?.data?.ErrorId}`
      //             : "")
      //       );
      //     }
      //   });
      // } else if (errorFromUrl) {
      //   showToast(t("unhandledError"));
      // }
    } else {
      // ! possible routes
      const possibleRoutes = [
        "/signin",
        "/passreset",
        "/activate",
        "/trialregister",
      ];
      // signIn
      // passReset
      // activate
      // trialRegister

      const url = location.pathname + (location.search ?? "");

      const path = decodeURIComponent("/docean.fi" + url);

      const routeObject = routes.find((route) => route.route.match(path));

      if (routeObject) {
        const params = routeObject.route.match(path);
        if (params?.email) props.changeEmail(params.email);
        if (possibleRoutes.includes(location.pathname.toLowerCase())) {
          setNavState({
            deepLinkRoute: location.pathname.substring(1),
            deepLinkParams: params,
            deepLinkScreen: "SignIn",
          });
        }
      }
    }
  }, []);

  const resetDeeplinkParams = React.useCallback(() => {
    setNavState({});
    history.replace("/signIn");
  }, [setNavState]);

  if (!isSignInRoute && !navState) return <Loading />;
  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div style={theming.theme.mainContainer}>
          {props.profile.role ? (
            <HeaderBar menu={false} linkTo="/" />
          ) : (
            <PublicHeaderBar />
          )}
          <div style={theming.theme.homeContainer}>
            <SignInScreen
              navigation={history}
              location={location}
              navState={navState}
              resetDeeplinkParams={resetDeeplinkParams}
            />
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEmail,
      SET_DEEP_LINK_PARAMS,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    profile: state.userInfo.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInWrapper);

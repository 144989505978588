import React, { useRef, useCallback, useContext } from "react";
import { Pressable } from "react-native";
import { Navigation } from "swiper";
import Icon from "./Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { ThemeContext } from "../theming/theme-context";

const SwiperElem = React.forwardRef((props, ref) => {
  const theming = useContext(ThemeContext);
  const nextBtnRef = useRef(null);
  const prevBtnRef = useRef(null);
  const sliderRef = useRef(null);

  const _ref = ref || sliderRef;

  const handlePrev = useCallback(() => {
    if (!_ref.current) return;
    _ref.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!_ref.current) return;
    _ref.current.swiper.slideNext();
  }, []);

  return (
    <Swiper
      modules={[Navigation]}
      id={props.swiperId}
      ref={_ref}
      onSlideChange={(swiper) => {
        if (swiper.isBeginning) {
          prevBtnRef.current.classList.add("displaynone");
        } else {
          prevBtnRef.current.classList.remove("displaynone");
        }

        if (swiper.isEnd) {
          nextBtnRef.current.classList.add("displaynone");
        } else {
          nextBtnRef.current.classList.remove("displaynone");
        }

        if (props.onIndexChanged) props.onIndexChanged(swiper.activeIndex);
      }}
      enabled={true}
      initialSlide={props.initialSlide ?? props.swiperIndex}
      allowTouchMove={false}
      grabCursor={!props.showsButtons}
      onSwiper={props.setSwiper}
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: 0,
        minWidth: 0,
        ...props.style,
        overflowY: "hidden",
      }}
    >
      {props.views.map((x, i) => (
        <SwiperSlide
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "100vw",
            maxHeight: "100vh",
            minHeight: 0,
            minWidth: 0,
            overflowY: "auto",
            ...props.style,
          }}
          key={"RSwiperSlide" + i}
        >
          {x}
        </SwiperSlide>
      ))}

      {props.showsButtons && props.views && props.views.length > 1 ? (
        <Pressable
          ref={prevBtnRef}
          style={[
            props.alternateButtons
              ? theming.theme.alternateSwiperButtonStyle
              : theming.theme.swiperButtonStyle,
            theming.theme.leftSwiperButtonStyle,
          ]}
          onPress={handlePrev}
        >
          <Icon name={"step-backward"} size={24} color={theming.colors.text} />
        </Pressable>
      ) : null}
      {props.showsButtons && props.views && props.views.length > 1 ? (
        <Pressable
          ref={nextBtnRef}
          style={[
            props.alternateButtons
              ? theming.theme.alternateSwiperButtonStyle
              : theming.theme.swiperButtonStyle,
            theming.theme.rightSwiperButtonStyle,
          ]}
          onPress={handleNext}
        >
          <Icon name={"step-forward"} size={24} color={theming.colors.text} />
        </Pressable>
      ) : null}
    </Swiper>
  );
});

export default SwiperElem;

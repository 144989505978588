import React, { useState } from "react";
import ReactDOM from "react-dom";
import { View } from "react-native";
import Table from "./Table";
import {
  useFloating,
  offset,
  shift,
  flip,
  size,
  autoUpdate,
} from "@floating-ui/react-dom";
import { ThemeContext } from "../theming/theme-context";
import Icon from "./Icon";

const styles = {
  tipIcon: {
    height: 24,
    width: 24,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};

export const Tooltip = (props) => {
  const theming = React.useContext(ThemeContext);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);

  // Virtual element for mouse coordinates as anchor
  const virtualEl = tooltipPosition
    ? {
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: tooltipPosition.mouseX,
            y: tooltipPosition.mouseY,
            top: tooltipPosition.mouseY,
            left: tooltipPosition.mouseX,
            right: tooltipPosition.mouseX,
            bottom: tooltipPosition.mouseY,
          };
        },
      }
    : null;

  const { refs, floatingStyles, update } = useFloating({
    strategy: "fixed", // Keep fixed positioning for stability
    placement: "bottom", // Tooltip starts at the top placement
    middleware: [
      offset(props.hintTable ? -20 : 10), // Offset the tooltip a bit from the mouse position
      flip({
        fallbackPlacements: ["bottom", "left", "right", "top"],
      }), // Flip to prevent overflow
      shift(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          // Adjust size based on screen size
          const isSmallScreen = window.innerWidth <= 768; // Change this breakpoint as needed

          // Set maxWidth and maxHeight based on screen size
          Object.assign(elements.floating.style, {
            maxWidth: isSmallScreen ? "100vw" : `${availableWidth * 0.5}px`, // Full width on small screens
            maxHeight: isSmallScreen ? "100vh" : `${availableHeight * 0.5}px`, // Full height on small screens
            overflow: "auto", // Enable scrolling if needed
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    elements: props.hintTable
      ? {
          reference: virtualEl, // Use virtual element as reference
        }
      : undefined,
  });

  const elements = document.getElementsByClassName("doceantooltip");
  const el = elements[elements.length - 1];

  // Track mouse position when hovering over the target
  const onMouseOver = (e) => {
    setTooltipPosition({ mouseX: e.clientX, mouseY: e.clientY });
    if (props.tip || props.hintTable) setTooltipVisible(true); // Show the tooltip
    update(); // Update tooltip position
  };

  const onMouseMove = (e) => {
    if (!tooltipVisible) {
      if (props.tip || props.hintTable)
        setTooltipPosition({ mouseX: e.clientX, mouseY: e.clientY });
      update();
    }
  };

  // Hide tooltip when mouse leaves both target and tooltip
  const onMouseOut = () => setTooltipVisible(false);

  return (
    <>
      <div
        ref={refs.setReference}
        onMouseOver={onMouseOver}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        style={props.style}
      >
        {props.icon ? (
          <View
            style={[styles.tipIcon, { backgroundColor: theming.colors.accent }]}
          >
            <Icon
              name={props.iconName ?? "help"}
              size={22}
              color={theming.colors.textOnAccent}
            />
          </View>
        ) : null}
        {props.children}
      </div>
      {tooltipVisible
        ? ReactDOM.createPortal(
            <div
              className="Tooltip"
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: 1000 }} // Use Floating UI styles
              onMouseEnter={() => setTooltipVisible(true)} // Stay visible while hovering on the tooltip
              onMouseLeave={onMouseOut} // Hide when mouse leaves the tooltip
            >
              {props.tip || ""}
              {props.hintTable ? (
                <Table
                  translate={props.hintTable.translate}
                  head={props.hintTable.head}
                  data={props.hintTable.data}
                  lang={props.lang}
                />
              ) : null}
            </div>,
            el
          )
        : null}
    </>
  );
};

const setDocToBeModified = (docId) => ({
  type: "SET_DOC_TO_BE_MODIFIED",
  docId,
});

export const modifyDoc = (docId) => (dispatch) => {
  dispatch(setDocToBeModified(docId));
};

export const clearModifyDocAction = () => ({
  type: "CLEAR_MODIFY_DOC",
});

export const clearModifyDoc = () => (dispatch) => {
  dispatch(clearModifyDocAction());
};

export const CLEAR_IF_MODIFYING = (docIds) => ({
  type: "CLEAR_IF_MODIFYING",
  docIds,
});

import { useEffect, useRef } from "react";
import { Animated, Keyboard } from "react-native";
import { fullHp, fullWp } from "../lib/helperFns";

const imageContainerFlex = 0.8;
export default function KeyboardAvoidingLogo(props) {
  let { theme, lightLogo } = props;
  const imageContainer = useRef(new Animated.Value(imageContainerFlex)).current;
  const imageHeight = useRef(new Animated.Value(fullHp(20))).current;

  function keyboardDidShow() {
    Animated.timing(imageContainer, {
      duration: 300,
      toValue: 0,
      useNativeDriver: false,
    }).start();
    Animated.timing(imageHeight, {
      duration: 300,
      toValue: 0,
      useNativeDriver: false,
    }).start();
  }

  function keyboardDidHide() {
    Animated.timing(imageContainer, {
      duration: 300,
      toValue: imageContainerFlex,
      useNativeDriver: false,
    }).start();
    Animated.timing(imageHeight, {
      duration: 300,
      toValue: fullWp(90),
      useNativeDriver: false,
    }).start();
  }

  useEffect(() => {
    const keyboardDidShowSub = Keyboard.addListener(
      "keyboardDidShow",
      keyboardDidShow
    );
    const keyboardDidHideSub = Keyboard.addListener(
      "keyboardDidHide",
      keyboardDidHide
    );

    return () => {
      keyboardDidHideSub.remove();
      keyboardDidShowSub.remove();
    };
  }, []);

  return (
    <Animated.View style={[theme.centeredContainer, { flex: imageContainer }]}>
      <Animated.Image
        source={lightLogo}
        resizeMode={"contain"}
        style={{ height: imageHeight, width: fullWp(90) - 64 }}
      />
    </Animated.View>
  );
}

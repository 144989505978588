import { useMemo } from "react";
import { View } from "react-native";
import { CellComponent } from "../internal";
import { divideInputsIntoRows } from "../lib/functions";
import TitleWithTooltip from "./TitleWithTooltip";

export function DividedRowInputs(props) {
  const inputs = useMemo(
    () => divideInputsIntoRows(props.item.inputs, "formRow", "formColumn"),
    [props.item.inputs]
  );

  return (
    <View style={props.theme.flex}>
      <TitleWithTooltip
        title={props.title}
        hint={props.hint}
        hintTable={props.hintTable}
      />

      <View key={props.childKey} style={props.theme.rowContainer}>
        <View style={props.theme.flex}>
          {inputs.map((row, rowIndex) =>
            Array.isArray(row) ? (
              <View
                key={props.childKey + "DividedInputRow" + rowIndex}
                style={props.theme.rowContainer}
              >
                {row.map((input, inputIndex) => {
                  return input.pdfOnly || input.type === "text" ? null : (
                    <View
                      key={props.childKey + "ItemView" + rowIndex + inputIndex}
                      style={{
                        flex: input.flex === 0 ? null : input.flex ?? 1,
                        paddingLeft: inputIndex !== 0 ? 8 : 0,
                        paddingRight: inputIndex !== row.length - 1 ? 8 : 0,
                      }}
                    >
                      <CellComponent
                        {...props}
                        childKey={props.childKey}
                        item={input}
                        cellRef={props.cellRefs?.[input.valueKey]}
                        setCellRef={
                          props.setCellRefFn
                            ? (el) =>
                                props.setCellRefFn(input.valueKey, input, el)
                            : null
                        }
                        valueKey={
                          props.valueKeyPrefix
                            ? props.valueKeyPrefix + "_" + input.valueKey
                            : input.valueKey
                        }
                      />
                    </View>
                  );
                })}
              </View>
            ) : null
          )}
        </View>
      </View>
    </View>
  );
}

import { useEffect, useState } from "react";
import { View, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, wp, hp } from "../lib/helperFns";
import TimePicker from "./TimePicker";
import Modal from "./Modal";
import Calendar from "./Calendar";
import moment from "moment";
import StretchButton from "./StretchButton";
import { useContext } from "react";

const minimumYear = 1900;

// iso date = "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
export default function MonthPicker(props) {
  const { theme, colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("cecember"),
  ];
  const isIsoDate = moment(props.date, moment.ISO_8601, true).isValid();
  const dateFormat = isIsoDate
    ? "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
    : props.dateFormat || (props.timePicker ? "HH:mm DD.MM.YYYY" : "D.M.YYYY");

  const [monthIndex, setMonthIndex] = useState();
  const [year, setYear] = useState();
  const [day, setDay] = useState();
  const [time, setTime] = useState();

  const [showTimePicker, setShowTimePicker] = useState(false);
  const [clockLabelText, setClockLabelText] = useState(moment());

  const [err, setErr] = useState(null);

  useEffect(() => {
    setShowTimePicker(false);
  }, [props.visible]);

  useEffect(() => {
    if (props.date) {
      setMonthIndex(parseInt(moment(props.date, dateFormat).format("M")) - 1);
      setYear(parseInt(moment(props.date, dateFormat).format("YYYY")));
      setDay(parseInt(moment(props.date, dateFormat).format("D")));
      setTime(moment(props.date, dateFormat));
    } else {
      setMonthIndex(
        parseInt(
          props.minDate && moment().isBefore(moment(props.minDate, dateFormat))
            ? moment(props.minDate, dateFormat).format("M")
            : moment().format("M")
        ) - 1
      );
      setYear(
        parseInt(
          props.minDate && moment().isBefore(moment(props.minDate, dateFormat))
            ? moment(props.minDate, dateFormat).format("YYYY")
            : moment().format("YYYY")
        )
      );
      setDay(
        parseInt(
          props.minDate && moment().isBefore(moment(props.minDate, dateFormat))
            ? moment(props.minDate, dateFormat).format("D")
            : moment().format("D")
        )
      );
      setTime(moment());
    }
  }, [props.date, props.minDate, props.maxDate]);

  const checkThatMonthHasDay = () => {
    const daysCount = moment(year + "-" + monthIndex, "YYYY-M").daysInMonth();

    if (day > daysCount) setDay(null);
  };

  const _setMonth = (month, _index) => {
    setMonthIndex(_index);
  };

  const _setYear = (year) => {
    setYear(year);
  };

  const nextMonth = () => {
    const willLoop = monthIndex === 11;
    const newMonth = willLoop ? 0 : monthIndex + 1;
    setMonthIndex(newMonth);
    if (willLoop) {
      setYear(year + 1);
      checkThatMonthHasDay();
    } else {
      checkThatMonthHasDay(newMonth, year + 1);
    }
  };

  const previousMonth = () => {
    const willLoop = monthIndex === 0;
    const newMonth = willLoop ? 11 : monthIndex - 1;
    setMonthIndex(newMonth);
    if (willLoop) {
      setYear(year - 1);
      checkThatMonthHasDay();
    } else {
      checkThatMonthHasDay(newMonth, year - 1);
    }
  };

  const nextYear = (amount = 1) => {
    const newYear = year + amount;
    setYear(newYear);
    checkThatMonthHasDay(undefined, newYear);
  };

  const previousYear = (amount = 1) => {
    const newYear = year - amount >= minimumYear ? year - amount : year;
    setYear(newYear);
    checkThatMonthHasDay(undefined, newYear);
  };

  const _setShowTimePicker = (val) => {
    if (Platform.OS !== "web") {
      //props.toggle();
      setShowTimePicker(val);
    } else {
      setShowTimePicker(val);
    }
  };

  const confirmDate = (_day) => {
    const __day = _day || day;

    if ((props.dayPicker && !__day) || (props.timePicker && !time)) {
      setErr(true);
    } else {
      props.onConfirm(
        year,
        months[monthIndex],
        monthIndex + 1,
        __day,
        props.prop,
        props.timePicker ? moment(time).format("HH:mm") : null,
        true
      );
      if (!props.noToggleOnConfirm) props.toggle();
    }
  };

  const renderContent = () => {
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.primary,
          borderColor: colors.text,
          flex: 1,
          //borderWidth: 1,
        }}
      >
        <Calendar
          pageW={wp(90) - 2}
          pageH={hp(90)}
          showTimePicker={showTimePicker}
          setShowTimePicker={_setShowTimePicker}
          clockLabelText={clockLabelText}
          setClockLabelText={setClockLabelText}
          minDate={props.minDate}
          maxDate={props.maxDate}
          dateFormat={props.dateFormat}
          monthIndex={monthIndex}
          year={year}
          day={day}
          title={props.title}
          prevMonth={previousMonth}
          nextMonth={nextMonth}
          prevYear={previousYear}
          nextYear={nextYear}
          setMonth={_setMonth}
          setYear={_setYear}
          dayPicker={props.dayPicker}
          timePicker={props.timePicker}
          confirmDate={confirmDate}
          err={err || props.err}
          setErr={setErr}
          setDay={setDay}
          setTime={setTime}
          time={time}
          toggle={props.toggle}
          theme={theme}
          colors={colors}
        />

        <View style={theme.modalButtonContainer}>
          <StretchButton
            color={colors.text}
            backgroundColor={colors.secondary}
            title={t("cancel")}
            onPress={props.toggle}
          />
          <View
            style={{
              borderColor: colors.borderPrimary,
              borderRightWidth: 1,
            }}
          />
          <StretchButton
            disabled={props.isFetching}
            loading={props.isFetching}
            title={t("ok")}
            onPress={() => confirmDate()}
          />
        </View>
      </View>
    );
  };
  if (props.timePicker && Platform.OS !== "web" && showTimePicker) {
    return (
      <TimePicker
        value={time}
        textColor={colors.text}
        themeVariant={colors.text === "#fff" ? "dark" : "light"}
        onChange={(event, date) => {
          setShowTimePicker(false);
          if (event.type === "set") {
            if (!props.noToggleOnConfirm) props.toggle();
            props.onConfirm(
              year,
              months[monthIndex],
              monthIndex + 1,
              day,
              props.prop,
              props.timePicker ? moment(date).format("HH:mm") : null
            );
            props.toggle();
          }
        }}
        getClockLabelText={(view, time) => setClockLabelText(time)}
      />
    );
  } else if (props.noModal) {
    return renderContent();
  } else {
    return (
      <Modal
        {...modalPickerOpts}
        isVisible={props.visible}
        onBackButtonPress={props.toggle}
        onBackdropPress={props.toggle}
        backdropColor={colors.borderLighter}
        backdropOpacity={0.6}
      >
        {renderContent()}
      </Modal>
    );
  }
}

import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Text,
  View,
  Platform,
} from "react-native";
import SearchBarElem from "./SearchBarElem";
import Icon from "./Icon";
import { Tooltip } from "./Tooltip";
import RemovalTitleRowWithAlert from "./RemovalTitleRowWithAlert";
import { errorReport } from "../lib/functions";
import { useTranslation } from "react-i18next";
import IconButton from "./IconButton";

function RowTitleButton(props) {
  const {
    theme,
    colors,
    title,
    isVisible,
    isSelected,
    noVisibilityToggle,
    handleTitlePress,
    handleSelect,
    handleSetVisible,
    disabled,
    haveData,
    onModifyPress,
    onRemovePress,
    onDuplicatePress,
    removingData,
    showItemDataOnHover,
    isLocal,
  } = props;
  const { t } = useTranslation();

  // TODO removalRestricted should only disable removal, not the whole selection button since selected item can be edited in some cases
  return (
    <View style={theme.rowContainer}>
      {isLocal ? (
        <Tooltip flex={0} tip={t("onlyLocalSave")}>
          <View
            style={{
              minHeight: 36,
              width: 36,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: colors.lightBg,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon name={"cloud-off-outline"} size={24} color={colors.text} />
          </View>
        </Tooltip>
      ) : null}
      {!haveData || disabled || props.removalRestricted ? null : (
        <TouchableOpacity
          disabled={!!removingData}
          onPress={onModifyPress ? handleSelect : onRemovePress}
        >
          <View
            style={{
              minHeight: 36,
              width: 36,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: colors.lightBg,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {removingData ? (
              <ActivityIndicator size={24} color={colors.text} />
            ) : (
              <Icon
                name={
                  onModifyPress
                    ? isSelected
                      ? "select-off"
                      : "select"
                    : "trash-can-outline"
                }
                size={24}
                color={colors.text}
              />
            )}
          </View>
        </TouchableOpacity>
      )}

      {onDuplicatePress ? (
        <IconButton
          color={colors.text}
          backgroundColor={colors.borderSecondary}
          icon="content-duplicate"
          onPress={onDuplicatePress}
        />
      ) : null}

      <TouchableOpacity
        style={{ flex: 1, minHeight: 36 }}
        onPress={handleTitlePress}
        disabled={!!disabled}
      >
        <View
          style={[
            {
              flexGrow: 1,
              padding: 8,
              backgroundColor: props.colors.primary,
            },
            noVisibilityToggle
              ? {
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }
              : {},
            props.titleWrapperStyle,
          ]}
        >
          <Text style={[theme.boldText, props.titleStyle]} numberOfLines={1}>
            {title}
          </Text>
        </View>
      </TouchableOpacity>

      {haveData ? (
        <View
          style={{
            height: "100%",
            flexDirection: "row",
          }}
        >
          {noVisibilityToggle ? null : (
            <TouchableOpacity
              onPress={(ev) => handleSetVisible(ev, true)}
              onMouseEnter={
                showItemDataOnHover
                  ? (ev) => handleSetVisible(ev, false, true)
                  : null
              }
              onMouseLeave={
                showItemDataOnHover
                  ? (ev) => handleSetVisible(ev, false, false)
                  : null
              }
            >
              <View
                style={[
                  {
                    minHeight: 36,
                    width: 72,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    backgroundColor: colors.lightBg,
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Icon
                  name={isVisible ? "chevron-up" : "chevron-down"}
                  size={24}
                  color={colors.text}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
      ) : null}
    </View>
  );
}

const ObjectListElem = React.memo(
  React.forwardRef((props, ref) => {
    const [dataIsVisible, setDataIsVisible] = useState({
      visibleId: null,
      setFromButton: false,
    });
    const childKey = props.childKey || "";
    let hoverTimeout;

    const _setSelected = (item, index) => {
      if (!props.removalRestricted && props.haveData) {
        props.addItemToSelected(item, index);
      }
    };

    const renderSeparator = () => {
      return (
        <View
          style={{
            paddingBottom: props.separatorPadding ?? 0,
            backgroundColor: props.separatorColor ?? undefined,
          }}
        />
      );
    };

    useEffect(() => {
      let scrollTimeout;
      if (
        props.scrollOnMount &&
        props.scrollPosition?.current &&
        ref?.current?.scrollToOffset
      ) {
        setTimeout(() => {
          try {
            ref?.current?.scrollToOffset({
              offset: props.scrollPosition.current,
              animated: false,
            });
          } catch (error) {
            errorReport({
              error,
              errorInScreen: "ObjectList",
              errorInFn: "scrollToOffset",
              errorParams: {
                ref: !!ref?.current,
              },
              dontShowToast: true,
            });
          }
        }, 500);
      }
      return () => {
        clearTimeout(scrollTimeout);
        clearTimeout(hoverTimeout);
      };
    }, []);

    const listItem = (item, index, isSelected) => {
      const visibleItem = dataIsVisible.visibleId ?? props.visibleItem;

      const handleSetVisible = (ev, setFromButton, onMouseEnter) => {
        if (props.haveData) {
          if (props.showItemDataOnHover) {
            const visibleId = props.idProp ? item[props.idProp] : index;
            if (setFromButton) {
              if (
                dataIsVisible.setFromButton &&
                visibleId === dataIsVisible.visibleId
              ) {
                setDataIsVisible({
                  visibleId: null,
                  setFromButton: false,
                });
              } else {
                setDataIsVisible({
                  visibleId,
                  setFromButton,
                });
              }
            } else if (!dataIsVisible.setFromButton) {
              if (onMouseEnter) {
                hoverTimeout = setTimeout(
                  () =>
                    setDataIsVisible({
                      visibleId,
                      setFromButton,
                    }),
                  300
                );
              } else {
                clearTimeout(hoverTimeout);
                setDataIsVisible({
                  visibleId: null,
                  setFromButton: false,
                });
              }
            }
          } else {
            const visibleId = props.idProp
              ? visibleItem === item[props.idProp]
                ? null
                : item[props.idProp]
              : visibleItem === index
              ? null
              : index;
            props.setVisibleItem(visibleId);
          }
        }
      };

      return (
        <View
          style={[
            {
              flex: 1,
              backgroundColor: props.colors.primary,
              borderWidth: isSelected ? 2 : 0,
              borderColor: props.colors.accent,
              padding: props.itemPadding,
            },
          ]}
        >
          {props.renderTitleRow(isSelected)}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            <RowTitleButton
              theme={props.theme}
              colors={props.colors}
              removalRestricted={props.removalRestricted}
              disabled={props.disabled}
              haveData={props.haveData}
              titleWrapperStyle={props.titleWrapperStyle}
              titleStyle={props.titleStyle}
              title={props.getItemText(item, props.haveData)}
              isVisible={
                props.idProp
                  ? visibleItem === item[props.idProp]
                  : visibleItem === index
              }
              isLocal={props.isLocalCheck ? props.isLocalCheck(item) : false}
              isSelected={isSelected}
              removingData={
                props.removingData && props.removingData.id === item.id
              }
              handleTitlePress={() => {
                if (props.haveData) {
                  if (props.multiSelect && props.selected.length > 0) {
                    if (isSelected) {
                      props.removeFromSelected(item, index);
                    } else {
                      _setSelected(item, index);
                    }
                  } else if (props.haveData && props.setItem) {
                    props.setItem(item);
                  } else if (!isSelected) {
                    _setSelected(item, index);
                  } else if (props.removeFromSelected) {
                    props.removeFromSelected(item, index);
                  }
                }
              }}
              noVisibilityToggle={
                props.openByDefault ||
                props.noHiddenInputs ||
                props.noItemContent
              }
              onModifyPress={props.onModifyPress}
              onRemovePress={() => props.onRemovePress(item)}
              onDuplicatePress={
                props.onDuplicatePress
                  ? () => props.onDuplicatePress(item)
                  : null
              }
              handleSelect={() => {
                if (props.haveData) {
                  if (!isSelected) {
                    _setSelected(item, index);
                  } else if (props.removeFromSelected) {
                    props.removeFromSelected(item, index, props.idProp);
                  }
                }
              }}
              showItemDataOnHover={props.showItemDataOnHover}
              handleSetVisible={handleSetVisible}
            />
          </View>
          {props.openByDefault ??
          (!props.noItemContent &&
            props.haveData &&
            (props.noHiddenInputs ||
              (props.idProp
                ? visibleItem === item[props.idProp]
                : visibleItem === index)))
            ? props.renderItemContents(item, index, props.theme, props.colors)
            : null}
        </View>
      );
    };

    return (
      <FlatList
        {...props.refreshProps}
        onScroll={props.onScroll ? props.onScroll : null}
        ref={ref}
        listKey={"ObjectList" + childKey}
        keyboardShouldPersistTaps="handled"
        initialNumToRender={15}
        data={
          props.haveData
            ? props.searchText
              ? props.searchArr
              : props.sortData
              ? props.data.sort((a, b) => {
                  return (
                    props.getItemText(a, props.haveData) ?? ""
                  ).localeCompare(
                    props.getItemText(b, props.haveData) ?? "",
                    "sw",
                    {
                      numeric: true,
                      sensitivity: "base",
                    }
                  );
                })
              : props.data
            : props.noDataPlaceHolder
            ? []
            : props.getNoDataArr()
        }
        keyExtractor={(item, index) => "InputPicker" + index}
        ItemSeparatorComponent={renderSeparator}
        progressViewOffset={16}
        renderItem={({ item, index }) => {
          const isSelected =
            props.selected &&
            ((props.multiSelect &&
              (props.idProp
                ? props.selected?.some(
                    (x) => x[props.idProp] === item[props.idProp]
                  )
                : props.selected?.includes(item))) ||
              (!props.multiSelect &&
                (props.idProp
                  ? item[props.idProp] === props.selected?.[props.idProp]
                  : item === props.selected)));

          return listItem(item, index, isSelected);
          // TODO swipeable rows
          // if (props.haveData) {
          //   <AppleStyleSwipeableRow
          //     onRemove={() => props.onRemovePress(item)}
          //     rightActions={[
          //       {
          //         title: t("edit"),
          //         bgColor: props.colors.accent,
          //         color: props.colors.textOnAccent,
          //         fn: props.onModifyPress,
          //       },
          //       {
          //         title: isSelected ? t("removeFromSelected") : t("select"),
          //         bgColor: props.colors.darkPrimary,
          //         color: props.colors.text,
          //         fn: () => {
          //           if (!isSelected) {
          //             _setSelected(item, index);
          //           } else if (props.removeFromSelected) {
          //             props.removeFromSelected(item, index);
          //           }
          //         },
          //       },
          //       // {
          //       //   title: t("add"),
          //       //   bgColor: props.colors.secondary,
          //       //   color: props.colors.text,
          //       //   fn: () => _onItemPress(item, props.arrName, true),
          //       // },
          //     ]}
          //   >
          //     {listItem(item, index, isSelected)}
          //   </AppleStyleSwipeableRow>;
          // } else {
          //   return listItem(item, index, isSelected);
          // }
        }}
      />
    );
  })
);

const ObjectList = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { theme, colors } = props;
  const [visibleItem, setVisibleItem] = useState(null);

  //const flatListRef = useRef(null);

  const renderRemovalRow = () => {
    return (
      <RemovalTitleRowWithAlert
        style={{ flex: 1 }}
        onCancelPress={props.onCancelPress}
        onRemovePress={props.onRemovePress}
        onModifyPress={
          props.multiSelect && props.selected.length > 1
            ? null
            : props.onModifyPress
        }
        modifyTitle={props.modifyTitle}
        onSelectAllPress={props.onSelectAllPress}
        allSelected={
          props.multiSelect && props.data.length === props.selected.length
        }
        deletionWarning={
          t("deleteConfirmation") +
          ":\n\n" +
          (props.multiSelect
            ? props.selected.reduce(
                (acc, cur, i) =>
                  acc +
                  props.getItemText(cur, props.haveData) +
                  (i !== props.selected.length - 1 ? "\n" : ""),
                ""
              )
            : props.getItemText(props.selected, props.haveData))
        }
      />
    );
  };

  const renderTitleRow = (isSelected, isHeader) => {
    if (props.staticHeader) {
      if (isHeader && props.selected.length > 0) {
        return renderRemovalRow();
      }
    } else if (isSelected) return renderRemovalRow();
    else return null;
  };

  const renderHeader = (theme, colors) => {
    return (
      <>
        <SearchBarElem
          placeholder={t("search")}
          containerStyle={[
            theme.zeroPaddingAndMargin,
            {
              backgroundColor: colors.primary,
            },
          ]}
          inputContainerStyle={[
            theme.inputContainerStyle,
            {
              backgroundColor: colors.primary,
            },
          ]}
          inputStyle={theme.searchBarInput}
          clearIcon={
            <TouchableOpacity onPress={props.clearSearch}>
              <Icon name={"close"} size={24} color={colors.text} />
            </TouchableOpacity>
          }
          searchIcon={<Icon name={"magnify"} size={24} color={colors.text} />}
          onChangeText={props.search}
          value={props.searchText}
          onClear={props.clearSearch}
        />
        {renderTitleRow(false, true)}
      </>
    );
  };

  const getNoDataArr = () => {
    if (Platform.OS == "web") {
      return [t("emptyList")];
    } else if (props.addableProp) {
      return [t("emptyListRefreshWithGesture")];
    } else if (props.refreshProps) {
      return [t("emptyListRefreshWithGesture2")];
    } else {
      return [t("emptyList")];
    }
  };

  const _setVisibleItem = (id) => {
    if (props.controlledVisibility) {
      props.setVisibleItem(id);
    } else {
      setVisibleItem(id);
    }
  };

  const getList = () => {
    if (Platform.OS === "web") {
      const height = props.pageH
        ? props.pageH * 0.9 -
          (props.multiSelect && props.selected.length > 0 ? 108 : 72)
        : 0;
      return (
        <View
          testID={"modularListContainer"}
          style={{
            flex: 1, // ! maybe breaks stuff
            height,
            minHeight: height,
            // minWidth: props.pageW
            //   ? props.pageW * 0.8 > 700
            //     ? 700
            //     : props.pageW * 0.8 > 700
            //   : 0,
            maxWidth: props.unlimitedWidth ? undefined : 700,
            padding: props.wrapperPadding,
          }}
        >
          <ObjectListElem
            {...props}
            ref={ref}
            theme={theme}
            colors={colors}
            getNoDataArr={getNoDataArr}
            setVisibleItem={_setVisibleItem}
            renderTitleRow={renderTitleRow}
            visibleItem={
              props.controlledVisibility ? props.visibleItem : visibleItem
            }
          />
        </View>
      );
    } else {
      return (
        <View
          testID={"modularListContainer"}
          style={{
            flex: 1,
            backgroundColor: colors.primary,
            padding: props.wrapperPadding,
          }}
        >
          <ObjectListElem
            {...props}
            ref={ref}
            theme={theme}
            colors={colors}
            getNoDataArr={getNoDataArr}
            setVisibleItem={_setVisibleItem}
            renderTitleRow={renderTitleRow}
            visibleItem={
              props.controlledVisibility ? props.visibleItem : visibleItem
            }
          />
        </View>
      );
    }
  };
  return (
    <>
      {/* {(props.multiSelect && props.selected.length > 0) ||
      (!props.multiSelect && props.selected)
        ? renderTitleRow(theme)
        : props.searchBar
        ? renderHeader(theme, colors)
        : null} */}

      {props.searchBar ? renderHeader(theme, colors) : null}

      {getList()}

      {props.renderFooter ? props.renderFooter(theme, colors) : null}
    </>
  );
});

export default ObjectList;

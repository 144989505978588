import { useReducer, useEffect, useRef, useLayoutEffect, useMemo } from "react";
import {
  Text,
  FlatList,
  SectionList,
  BackHandler,
  View,
  Platform,
  ScrollView,
} from "react-native";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";

import ModalWithTitle from "../components/ModalWithTitle";
import SimpleCheckbox from "../components/SimpleCheckbox";
import StretchButton from "../components/StretchButton";
import TextInput from "../components/TextInput";
import TextRow from "../components/TextRow";
import MeasurementRow from "../components/MeasurementRow";
import ValueInputModal from "../components/ValueInputModal";
import DataObjModal from "../components/DataObjModal";
import ModalPicker from "../components/ModalPicker";
import { ModalButton } from "../components/ModalButton";
import CheckBoxPicker from "../components/CheckBoxPicker";
import CellComponent from "../components/CellComponent";
import RemovalTitleRowWithAlert from "../components/RemovalTitleRowWithAlert";
import SimpleModal from "../components/SimpleModal";
import TitleRowWithArrows from "../components/TitleRowWithArrows";

import { showToast } from "../lib/helperFns";
import naturalCompare from "../lib/naturalCompare";
import {
  handleInitialSectionListScroll,
  errorReport,
  getIsRestricted,
  compareRole,
  getTranslatedText,
  getTranslatedUnit,
  getTranslatedOptions,
  getReduxLayout,
} from "../lib/functions";
import ButtonGroup from "../components/ButtonGroup";
import IconButton from "../components/IconButton";

// const renderEditorSwitch = (colors) => {
//   // DEPRECATED / NOT USED BY ANYONE, can toggle between editing and viewing
//   // usage:
//   /* {(props.docLayout.editorSwitch &&
//           compareRole(props.profile?.role, props.docLayout?.editorSwitch))
//           ? renderEditorSwitch(colors)
//           : null} */
//   return (
//     <TouchableOpacity
//       style={{
//         width: 42,
//         height: 42,
//         borderRadius: 18,
//         backgroundColor: colors.accent,
//         alignItems: "center",
//         justifyContent: "center",
//         marginLeft: 8,
//         marginRight: 8,
//       }}
//       onPress={() => {
//         dispatchState({
//           type: "setProp",
//           prop: "multiEdit",
//           value: false,
//         });
//         props.SET_USER_PROP({
//           prop: "editorRole",
//           value: props.editorRole === "User" ? props.profile.role : "User",
//         });
//       }}
//     >
//       <IconTooltip
//         title={
//           props.editorRole === "User"
//             ? t("switchToEditing")
//             : t("switchToFilling")
//         }
//         name={
//           props.editorRole === "User" ? "account-convert" : "account-edit"
//         }
//         size={24}
//         color={"#000"}
//       />
//     </TouchableOpacity>
//   );
// };

const OS = Platform.OS;

const INITIAL_STATE = {
  viewableItems: [],
  changed: [],
  focused: false,
  input: false,
  error: "",
  selected: [],
  dualInputIndex: false,
  valueInputModalVisible: false,
  dataObjModalVisible: false,
  tempDataObjName: "",
  dataObjNameErr: "",
  dataObjNameToChange: "",
  measurementPickerVisible: false,
  datePicker: {
    visible: false,
  },
  textModal: {
    visible: false,
  },
  picker: {
    visible: false,
  },
  checkBoxPicker: {
    visible: false,
  },
};
function measurementObjectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "set":
      return update(state, { $merge: action.payload });
    case "toggleAlert":
      return update(state, { alert: { visible: { $apply: (x) => !x } } });
    case "toggleMeasPicker":
      return update(state, { measurementPickerVisible: { $apply: (x) => !x } });
    case "setProp":
      return update(state, {
        [action.prop]: { $set: action.value },
      });
    case "toggleModal": {
      if (state[action.prop].visible) {
        return update(state, {
          [action.prop]: { visible: { $set: false } },
          setModal: { $set: null },
        });
      }
      break;
    }
    case "setObj":
      return update(state, { [action.prop]: { $set: action.obj } });
    case "toggleBoolean":
      return update(state, { [action.prop]: { $apply: (x) => !x } });
  }
  return state;
}

const MeasurementObjectsScreen = (props) => {
  const { t } = useTranslation();
  const [state, dispatchState] = useReducer(
    measurementObjectsReducer,
    INITIAL_STATE
  );

  // const onViewRef = React.useRef(({ viewableItems, changed }) => {
  //   dispatchState({
  //     type: "set",
  //     payload: {
  //       viewableItems,
  //       changed,
  //     },
  //   });
  // });
  // const viewConfigRef = React.useRef({
  //   minimumViewTime: 50,
  //   viewAreaCoveragePercentThreshold: 0,
  // });
  const mountHandled = useRef(false);
  const listRef = useRef(null);
  const itemHeights = useRef([]);
  const tmpText = useRef({});
  const measurementRefs = useRef({});

  const _options = useMemo(() => {
    return {
      ...props.options,
      gGgLComparablesSlow: props.options.gGgLComparablesSlow || {},
      gGgLComparablesFast: props.options.gGgLComparablesFast || {},
      shortCircuitFormulas: props.options.shortCircuitFormulas || {},
      fuseSizes: props.options.gGgLComparablesFast
        ? Object.keys(props.options.gGgLComparablesFast)
        : [],
    };
  }, [props.options]);

  const setMeasurementRef = (measurementKey, ref) => {
    measurementRefs.current[measurementKey] = ref;
  };

  const setState = (payload) => {
    dispatchState({
      type: "set",
      payload,
    });
  };

  //const dataObj = props.docToModify?.values?.[props.layoutId] ?? [];
  const dataObj = useMemo(
    () =>
      (props.docToModify?.values?.[props.layoutId] ?? []).sort((a, b) =>
        naturalCompare(a?.id, b?.id)
      ),
    [props.docToModify?.values?.[props.layoutId]]
  );

  const sectionsData = useMemo(() => {
    let layoutIndex = 0;
    let sections = [];
    dataObj.forEach((x, i) => {
      const _layoutIndex = layoutIndex;
      layoutIndex += 2 + 1;
      sections.push({
        item: x,
        key: "section" + i,
        data: [{ ...x, layoutIndex: _layoutIndex + 1 }],
        index: i,
        layoutIndex,
      });
    });
    return { sections, count: layoutIndex - 1 };
  }, [dataObj]);

  const creationRestricted = getIsRestricted(
    state.measObj?.restricted,
    "creation",
    props.profile.role
  );

  // useEffect(() => {
  //   // const firstItem =
  //   //   state.viewableItems.length === 1
  //   //     ? state.viewableItems[0].item
  //   //     : state.changed.length === 1
  //   //     ? state.changed[0].item[1]
  //   //     : null;

  //   const firstItem = state.viewableItems?.[0]?.item;
  //   if (
  //     props.visibleData &&
  //     firstItem &&
  //     firstItem.valueKey === props.visibleData
  //   ) {
  //     setShowListHeader(firstItem);
  //   } else if (showListHeader) {
  //     setShowListHeader(null);
  //   }
  // }, [props.visibleData, state.viewableItems]);

  const toggleModalIfNotVisible = (modal) => {
    dispatchState({
      type: "toggleModal",
      prop: modal,
    });
  };

  const toggleTextModal = () => {
    toggleModalIfNotVisible("textModal");
  };

  const togglePicker = () => {
    dispatchState({
      type: "toggleModal",
      prop: "picker",
    });
  };

  const toggleDataIsVisible = () => {
    props.SET_USER_PROP({
      prop: "measurementObjectsOpenByDefault",
      value: !props.measurementObjectsOpenByDefault,
    });
  };

  const handleInitialScroll = () => {
    handleInitialSectionListScroll(
      mountHandled,
      listRef,
      sectionsData,
      props.setVisible,
      props.scrollToValueKeys ? [props.scrollToValueKeys[2]] : null,
      props.scrollPosition
    );
  };

  useEffect(() => {
    if (props.scrollToValueKeys) {
      props.setVisible(props.scrollToValueKeys[1]);
    }
  }, []);

  useLayoutEffect(() => {
    let backHandler;
    if (Platform.OS !== "web") {
      backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackButtonPress
      );
    }
    return () => {
      if (Platform.OS !== "web") {
        backHandler?.remove();
      }
    };
  }, []);

  useLayoutEffect(() => {
    let measObj;
    try {
      measObj = getReduxLayout(
        props.options,
        props.layoutId,
        props.layoutVersion
      );
    } catch {
      props.goBack();
    }
    if (!props.docId) {
      props.goBack();
    }
    setState({
      measurementObject: props.layoutId,
      switchButton: "toggleDataObject",
      specialInputs: props.docLayout?.specialInputs || [],
      measurementObjectVersion: props.layoutVersion,
      measObj: measObj,
      ...measObj,
    });
  }, [props.layoutId, props.layoutVersion, props.docLayout]);

  useEffect(() => {
    if (state.title) {
      setState({
        switchButton: "toggleDataObject",
      });
    }
  }, [state.title]);

  const toggleMeasPicker = () => {
    dispatchState({
      type: "toggleMeasPicker",
    });
  };

  const getNewDataObjKey = (prev, dataObjKey) => {
    const currentDataObjKey = dataObjKey ?? state.inputDataObjKey;
    const keys = dataObj;
    const dataObjIndex = keys.findIndex((x) => x.valueKey == currentDataObjKey);

    let newDataObjIndex = dataObjIndex;
    if (!prev) {
      if (dataObjIndex == keys.length - 1) {
        newDataObjIndex = 0;
      } else {
        newDataObjIndex++;
      }
    } else {
      if (dataObjIndex == 0) {
        newDataObjIndex = keys.length - 1;
      } else {
        newDataObjIndex--;
      }
    }
    const newObj = keys[newDataObjIndex];
    return {
      key: newObj.valueKey,
      name: newObj.id,
      index: newDataObjIndex,
    };
  };

  const toggleDataObject = () => {
    let measurements;
    try {
      measurements = state.specialInputs.filter(
        (x) => x.type === "measurementObjects"
      );
    } catch (error) {
      errorReport({
        error,
        errorInFn: "toggleDataObject",
        errorInScreen: "MeasurementObjectScreen",
      });
      return;
    }
    const currentMeasurement = props.layoutId;

    const currentIndex = measurements.findIndex(
      (x) => x.layoutId === currentMeasurement
    );

    const newIndex =
      currentIndex === measurements.length - 1 ? 0 : currentIndex + 1;

    const newMeasurement = measurements[newIndex];

    if (newMeasurement) {
      props.replaceTab("measurementObjects", props.navigation, {
        layoutId: newMeasurement.layoutId,
        layoutVersion: newMeasurement.layoutVersion,
      });
    } else {
      errorReport({
        error: "missing newMeasurement",
        errorInFn: "toggleDataObject",
        errorInScreen: "MeasurementObjectScreen",
        errorParams: {
          docLayoutId: props.docToModify?.layoutId,
          docLayoutVersion: props.docToModify?.layoutVersion,
          layoutId: props.layoutId,
          layoutVersion: props.layoutVersion,
          currentMeasurement,
          currentIndex,
          measurements,
          newIndex,
          newMeasurement,
        },
      });
    }
  };

  const getNewMeasKey = (prev, measurementKey) => {
    const currentMeasKey = measurementKey ?? state.inputMeasurementKey;

    const measKeys = Object.keys(state.titles || {});

    const currentIndex = measKeys.findIndex((x) => x === currentMeasKey);

    const maxIndex = measKeys.length - 1;

    const getNewIndex = (index) => {
      if (!prev) {
        if (index == maxIndex) {
          return 0;
        } else {
          return index + 1;
        }
      } else {
        if (index == 0) {
          return maxIndex;
        } else {
          return index - 1;
        }
      }
    };

    let newIndex = getNewIndex(currentIndex);
    const isDualInput = checkIfDualInput(measKeys[newIndex]) !== -1;
    if (isDualInput) {
      newIndex = getNewIndex(newIndex);
    }

    const newMeasKey = `${measKeys[newIndex]}`;
    const isPicker = checkIfPicker(newMeasKey);

    const currentValue =
      props.docToModify.values[
        props.layoutId + "_" + state.inputDataObjKey + "_" + newMeasKey
      ]?.worstValue ?? 0;

    return { newMeasKey, isPicker, currentValue };
  };
  const switchMeas = (prev, setModalVisible, measurementKey) => {
    const { newMeasKey, currentValue, isPicker } = getNewMeasKey(
      prev,
      measurementKey
    );

    setState({
      input: true,
      dualInputIndex: checkIfDualInput(newMeasKey),
      modalInputMeasurementKey: newMeasKey,
      modalCurrentValue: currentValue.toString(),
      inputMeasurementKey: newMeasKey,
      measurementIndex: newMeasKey,
      valueInputModalVisible: setModalVisible ? !isPicker : false,
      measurementPickerVisible: setModalVisible ? isPicker : false,
    });
  };

  const switchDataObjKey = (prev, dataObjKey) => {
    const { key, index } = getNewDataObjKey(prev ?? false, dataObjKey);
    const currentValue =
      props.docToModify.values[
        props.layoutId + "_" + key + "_" + state.inputMeasurementKey
      ]?.worstValue ?? 0;

    setState({
      inputDataObjKey: key,
      modalInputDataObjKey: key,
      modalCurrentValue: currentValue.toString(),
      dataObjIndex: index,
    });
    props.setVisible(key);
  };

  const switchMeasOrGroup = (prev, measurementKey, dataObjKey) => {
    if (props.profile.measurementsPerGroup) {
      switchMeas(prev, true, measurementKey);
    } else {
      switchDataObjKey(prev, dataObjKey);
    }
  };

  const toggleValueInputModal = () => {
    if (state.valueInputModalVisible) {
      setState({ valueInputModalVisible: false, extraDataObjProps: null });
    } else {
      setState({ valueInputModalVisible: true });
    }
  };

  const toggleCheckBoxPicker = (valueKey, title) => {
    setState({
      checkBoxPicker: {
        visible: state.checkBoxPicker.visible ? null : valueKey,
        title,
      },
    });
  };

  const onBackButtonPress = () => {
    if (state.input) {
      setState({
        input: false,
        switchButton: "toggleDataObject",
      });
      return true;
    } else {
      props.goBack();
      return true;
    }
  };

  const addValue = (value, measurementKey, modalInputDataObjKey, layoutId) => {
    const extraDataObjProps = state.extraDataObjProps;
    const valueKey = `${
      layoutId ??
      (extraDataObjProps ? extraDataObjProps.extraDataObj : props.layoutId)
    }_${
      extraDataObjProps ? extraDataObjProps.key : modalInputDataObjKey
    }_${measurementKey}`;
    if (((state.measObj.allowZeros && value == 0) || value) && !isNaN(value)) {
      props.addMeasurement({
        docId: props.docId,
        valueKey,
        value: value,
        maxOrMin: extraDataObjProps
          ? getReduxLayout(
              props.options,
              extraDataObjProps.extraDataObj,
              state.measurementObjectVersion
            )?.maxOrMin[measurementKey]
          : state.maxOrMin?.[measurementKey],
      });
    }
  };

  const addDualInputValues = (
    text1,
    text2,
    measurementKey,
    measurementKey2
  ) => {
    addValue(text1, measurementKey, state.modalInputDataObjKey);
    addValue(text2, measurementKey2, state.modalInputDataObjKey);
  };

  const checkIfPicker = (key) => {
    return state.inputsAsPicker
      ? Object.keys(state.inputsAsPicker).includes(key)
      : false;
  };

  const checkIfDualInput = (key) => {
    let index = -1;
    state.dualInputs?.forEach((x, i) => {
      if (key == x.firstInput || key == x.scndInput) index = i;
    });
    return index;
  };

  const addItemToSelected = (key) => {
    if (state.selected.includes(key)) {
      try {
        setState({
          selected: state.selected.filter((x) => x !== key),
        });
      } catch (error) {
        errorReport({
          error,
          errorInFn: "addItemToSelected",
          errorInScreen: "MeasurementObjectsScreen",
        });
      }
    } else {
      setState({
        selected: state.selected.concat(key),
      });
    }
  };

  // render measurement input when a measurement is clicked and focus it
  const setValueInputState = (
    dataObjKey,
    measurementKey,
    dataObjIndex,
    measurementIndex,
    id,
    measurementObjectName,
    currentValue
  ) => {
    const isPicker = checkIfPicker(measurementKey);
    const oldMeasObj = state.measurementObject;

    setState({
      input: true,
      inputDataObjKey: dataObjKey,
      modalInputDataObjKey: dataObjKey,
      inputMeasurementKey: measurementKey,
      modalInputMeasurementKey: measurementKey,
      modalCurrentValue: currentValue,
      focused: false,
      dualInputIndex: checkIfDualInput(measurementKey),
      valueInputModalVisible: !isPicker,
      measurementPickerVisible: isPicker,
      dataObjIndex,
      measurementIndex,
      currentID: id,
      measurementObject: measurementObjectName ?? oldMeasObj,
    });
    props.setVisible(dataObjKey);

    // ! AAAAAAAAAAAAAA
    if (props.profile.measurementsPerGroup) {
      setState({
        title: getTranslatedText(props.title, props.lang),
        switchButton: "switchDataObjKey",
      });
    } else {
      setState({
        title: getTranslatedText(props.title, props.lang),
        switchButton: "switchMeas",
      });
    }
  };

  const removeModularMeasurement = (index, item, dataObjKey, key) => {
    props.removeModularMeasurement({
      docId: props.docId,
      valueKey: dataObjKey,
      maxOrMin: state.maxOrMin?.[key],
      oldVal: item,
    });
    // props.setAlert({
    //   visible: true,
    //   text: t("deleteValue") + ": " + item + "?",
    //   onRightButtonPress: () =>
    //     props.removeModularMeasurement({
    //       docId: props.docId,
    //       valueKey: dataObjKey,
    //       maxOrMin: state.maxOrMin?.[key],
    //       oldVal: item,
    //     }),
    // });
  };

  const onGroupRowPress = (
    dataObjKey,
    measurementKey,
    extraDataObjProps,
    currentValue
  ) => {
    const isPicker = checkIfPicker(measurementKey);
    setState({
      modalInputDataObjKey: dataObjKey,
      modalInputMeasurementKey: measurementKey,
      modalCurrentValue: currentValue,
      measurementPickerVisible: isPicker,
      valueInputModalVisible: !isPicker,
      extraDataObjProps,
    });
  };

  const toggleDataObjModal = () => {
    setState({
      dataObjModalVisible: !state.dataObjModalVisible,
      tempDataObjName: "",
      dataObjNameToChange: "",
    });
  };

  const setTempObjName = (text) => {
    if (text.length > 35) setState({ dataObjNameErr: t("max35chars") });
    else setState({ tempDataObjName: text, dataObjNameErr: "" });
  };

  const getMultiEditValueKeys = (valueKey) => {
    const splitValueKey = valueKey.split("_");
    const _valueKeyEnd = splitValueKey.slice(2, splitValueKey.length);
    const _valueKeys = dataObj.reduce((prev, cur) => {
      prev.push(`${props.layoutId}_${cur.valueKey}_${_valueKeyEnd.join("_")}`);
      return prev;
    }, []);
    return _valueKeys;
  };

  // TODO add a toggle for editing everything, dont use the multimultiEditAllowed;
  const canMultiEdit = () => {
    return state.multiEdit;
  };

  const modifyValue = (payload) => {
    if (canMultiEdit(payload.valueKey)) {
      props.modifyValue({
        ...payload,
        valueKeys: getMultiEditValueKeys(payload.valueKey),
      });
    } else {
      props.modifyValue(payload);
    }
  };

  const duplicateLastRow = () => {
    let lastItem;
    if (dataObj) lastItem = dataObj[dataObj.length - 1];
    props.addToObjectArrWithGeneratedId({
      userId: props.profile.id,
      docId: props.docId,
      valueKey: props.layoutId,
      duplicateValueKey: lastItem?.valueKey,
      value: { ...lastItem },
      idProp: "valueKey",
      sortProp: "id",
      setVisibleData: true,
      type: "MeasObj",
    });
  };

  const addToObjectArrWithGeneratedId = (payload, setVisible) => {
    if (canMultiEdit(payload.valueKey)) {
      props.addToObjectArrWithGeneratedId({
        ...payload,
        userId: props.profile.id,
        valueKeys: getMultiEditValueKeys(payload.valueKey),
        type: "MeasObj",
      });
    } else {
      props.addToObjectArrWithGeneratedId({
        userId: props.profile.id,
        ...payload,
        type: "MeasObj",
        handleNewValueKey: (newValueKey) => {
          if (setVisible) props.setVisible(newValueKey);
        },
      });
    }
  };

  const deleteFromObjectArr = (payload) => {
    if (canMultiEdit(payload.valueKey)) {
      props.deleteFromObjectArr({
        ...payload,
        valueKeys: getMultiEditValueKeys(payload.valueKey),
      });
    } else {
      props.deleteFromObjectArr(payload);
    }
  };

  const noVisibleModals = () => {
    if (
      state.textModal?.visible ||
      state.picker?.visible ||
      state.datePicker?.visible ||
      state.dataObjModalVisible
    )
      return false;
    else return true;
  };

  useEffect(() => {
    if (noVisibleModals() && state.setModal) {
      dispatchState({
        type: "setObj",
        prop: state.setModal.modal,
        obj: state.setModal.props,
      });
    }
  }, [noVisibleModals(), state.setModal]);

  const onAddMultiplePress = (_selected) => {
    props.addMultipleToObjectArrWithGeneratedId({
      type: "MeasObj",
      userId: props.profile.id,
      docId: props.docId,
      valueKey: props.layoutId,
      value: _selected.map((x) => ({ id: x })),
      idProp: "valueKey",
      sortProp: "id",
      amount: _selected.length,
    });
    togglePicker();
  };

  const setDataObj = (
    amount,
    tempDataObjName,
    dataObjNameToChange,
    tempDataObjValueKey,
    moveToAddMultiple
  ) => {
    if (state.textModal.visible) {
      const intAmount = parseInt(amount);
      if (isNaN(intAmount)) {
        setState({ textErr: t("checkValue") });
      } else if (intAmount > 500) {
        setState({ textErr: t("valueTooLarge") });
      }
      // else if (intAmount === 1) {
      //   props.addToObjectArrWithGeneratedId({
      //     docId: props.docId,
      //     valueKey: props.layoutId,
      //     value: { id: state.textModal._tempDataObjName },
      //     idProp: "valueKey",
      //     sortProp: "id",
      //     setVisibleData: true,
      //   });
      //   setState({ dataObjModalVisible: false, textModal: { visible: false } });
      // }
      else {
        props.addMultipleToObjectArrWithGeneratedId({
          type: "MeasObj",
          userId: props.profile.id,
          docId: props.docId,
          valueKey: props.layoutId,
          value: { id: state.textModal._tempDataObjName },
          idProp: "valueKey",
          sortProp: "id",
          amount,
        });

        // TODO maybe deprecate addSequence
        if (state.measObj.addSequence?.length > 1) {
          if (state.measObj.addSequence[1] === "addFromMultiPicker") {
            setState({
              setModal: {
                modal: "picker",
                props: {
                  visible: true,
                  optionsProp: "measObjTitles",
                  title: t("addWithTitles"),
                },
              },
              textModal: { visible: false },
            });
          } else {
            showToast("UNHANDLED ADD SEQUENCE");
          }
        } else {
          setState({ textModal: { visible: false } });
        }
      }
    } else {
      const _tempDataObjName = tempDataObjName ?? state.tempDataObjName;
      const _dataObjNameToChange =
        dataObjNameToChange ?? state.dataObjNameToChange;
      const _tempDataObjValueKey =
        tempDataObjValueKey ?? state.tempDataObjValueKey;
      // Empty input
      if (!/\S/.test(_tempDataObjName)) {
        setState({ dataObjNameErr: t("titleNotFilledErr") });
      }

      // Valid input
      else {
        // Name change
        if (_tempDataObjValueKey) {
          if (_tempDataObjName !== _dataObjNameToChange) {
            props.modifyObjectArrItem({
              docId: props.docId,
              valueKey: props.layoutId,
              idProp: "valueKey",
              oldVal: { valueKey: _tempDataObjValueKey },
              value: _tempDataObjName,
              propToSet: "id",
              sortProp: "id",
            });
          }
        }

        // Add new group
        else {
          if (moveToAddMultiple || state.measObj.addSequence) {
            if (
              moveToAddMultiple ||
              state.measObj.addSequence[0] === "addMultiple"
            ) {
              setState({
                dataObjModalVisible: false,
                setModal: {
                  modal: "textModal",
                  props: {
                    title: t("countToAdd"),
                    visible: true,
                    value: "1",
                    numeric: true,
                    _tempDataObjName: _tempDataObjName,
                    nextSequence: 1,
                  },
                },
              });
            } else {
              showToast("UNHANDLED ADD SEQUENCE");
            }
          } else {
            addToObjectArrWithGeneratedId(
              {
                userId: props.profile.id,
                docId: props.docId,
                valueKey: props.layoutId,
                value: { id: _tempDataObjName },
                idProp: "valueKey",
                sortProp: "id",
                setVisibleData: true,
                type: "MeasObj",
                // docId: props.docId,
                // measurementObjectId: props.layoutId,
                // measurementObject: state.measObj,
                // newObjId: _tempDataObjName,
              },
              true
            );
            setState({
              //visibleData: _tempDataObjName,
              dataObjModalVisible: false,
            });
          }
        }
      }
    }
  };

  const renderRemovalTitleRow = () => {
    const { selected } = state;
    const { docId } = props;
    const allKeys = dataObj.map((x) => x.valueKey);
    const allKeysLength = allKeys.length;
    var deleteWarning = t("deletionConfirmation") + "\n\n";
    selected.forEach(
      (x) => (deleteWarning += dataObj.find((y) => y.valueKey == x)?.id + "\n")
    );
    return (
      <RemovalTitleRowWithAlert
        style={{ flex: 1 }}
        onCancelPress={() => setState({ selected: [] })}
        onRemovePress={() => {
          props.removeMeasObjects({
            docId,
            connectedLayoutId: state.connectedLayoutId,
            measurementObjectId: props.layoutId,
            keysToRemove: selected,
          });
          setState({ selected: [] });
        }}
        onSelectAllPress={() =>
          state.selected.length === allKeysLength
            ? setState({ selected: [] })
            : setState({ selected: allKeys })
        }
        allSelected={state.selected.length === allKeysLength}
        deletionWarning={deleteWarning}
      />
    );
  };

  const getPickerOptions = () => {
    const options =
      state.inputsAsPicker?.[state.modalInputMeasurementKey]?.options;
    const optionsProp =
      state.inputsAsPicker?.[state.modalInputMeasurementKey]?.optionsProp;

    if (options) {
      return {
        prop: optionsProp,
        options: getTranslatedOptions(options, props.lang),
      };
    } else if (optionsProp && props.options[optionsProp]) {
      return {
        prop: optionsProp,
        options: getTranslatedOptions(props.options[optionsProp], props.lang),
      };
    } else return { prop: optionsProp, options: [] };
  };

  const renderMeasurementControls = () => {
    if (state.input) {
      const prev = getNewDataObjKey(true);
      const next = getNewDataObjKey(false);
      const prevMeas = getNewMeasKey(true);
      const nextMeas = getNewMeasKey(false);
      return (
        <View style={props.theme.buttonContainer}>
          <StretchButton
            title={prev?.name}
            disabled={prev.key === state.inputDataObjKey}
            startIcon={"arrow-left-bold"}
            numberOfLines={1}
            onPress={() => {
              switchDataObjKey(true);
              if (!checkIfPicker(state.inputMeasurementKey)) {
                // TODO maybe find out a better way to focus than timeout,
                // TODO autofocus doesn't work since the input doesn't mount again but no timeout doesn't work either
                setTimeout(() => {
                  measurementRefs.current[
                    `${props.layoutId}_${prev.key}_${state.inputMeasurementKey}`
                  ]?.focus();
                }, 100);
              }
            }}
          />
          <StretchButton
            backgroundColor={props.colors.secondary}
            color={props.colors.text}
            title={getTranslatedText(
              state.titles[prevMeas?.newMeasKey],
              props.lang
            )}
            startIcon={"arrow-left"}
            numberOfLines={1}
            onPress={() => {
              switchMeas(true);
              if (!checkIfPicker(prevMeas.newMeasKey)) {
                setTimeout(() => {
                  measurementRefs.current[
                    `${props.layoutId}_${state.inputDataObjKey}_${prevMeas.newMeasKey}`
                  ]?.focus();
                }, 100);
              }
            }}
          />
          <StretchButton
            backgroundColor={props.colors.secondary}
            color={props.colors.text}
            title={getTranslatedText(
              state.titles[nextMeas?.newMeasKey],
              props.lang
            )}
            endIcon={"arrow-right"}
            numberOfLines={1}
            onPress={() => {
              switchMeas();
              if (!checkIfPicker(nextMeas.newMeasKey)) {
                setTimeout(() => {
                  measurementRefs.current[
                    `${props.layoutId}_${state.inputDataObjKey}_${nextMeas.newMeasKey}`
                  ]?.focus();
                }, 100);
              }
            }}
          />
          <StretchButton
            title={next?.name}
            disabled={next.key === state.inputDataObjKey}
            endIcon={"arrow-right-bold"}
            numberOfLines={1}
            onPress={() => {
              switchDataObjKey();
              if (!checkIfPicker(state.inputMeasurementKey)) {
                // TODO maybe find out a better way to focus than timeout,
                // TODO autofocus doesn't work since the input doesn't mount again but no timeout doesn't work either
                setTimeout(() => {
                  measurementRefs.current[
                    `${props.layoutId}_${next.key}_${state.inputMeasurementKey}`
                  ]?.focus();
                }, 100);
              }
            }}
          />
        </View>
      );
    }
  };
  const renderButtons = (theme, colors) => {
    return (
      <View
        style={[theme.buttonContainer, { backgroundColor: colors.primary }]}
      >
        <ButtonGroup
          buttons={[
            {
              backgroundColor: colors.lightAccent,
              color: colors.accent,
              title: t("back"),
              onPress: onBackButtonPress,
            },
            {
              title: t("duplicateLastRow"),
              onPress: duplicateLastRow,
            },
            state.input
              ? {
                  title: props.profile.measurementsPerGroup
                    ? t("measurementsPerGroup")
                    : t("measurementsPerMeasurement"),
                  onPress: () => {
                    props.toggleMeasurementPerGroup(
                      !props.profile.measurementsPerGroup,
                      props.profile
                    );
                  },
                }
              : creationRestricted
              ? null
              : {
                  title: t("addNew"),
                  onPress: toggleDataObjModal,
                },
            props.viewUnfinishedLayout || props.docToModify.online
              ? null
              : {
                  startIcon: "content-save",
                  loading: props.isFetching,
                  disabled: !!(props.demo || props.saveDisabled),
                  title: t("save"),
                  onPress: () =>
                    props.saveDoc({
                      doc: props.docToModify,
                      lastDocRequests: props.lastDocRequests,
                    }),
                },
          ]}
        />
      </View>
    );
  };

  const renderHeader = (theme, colors) => {
    //   <TouchableOpacity
    //   style={{
    //     width: 42,
    //     height: 42,
    //     borderRadius: 18,
    //     backgroundColor: state.multiEdit ? colors.accent : "#000",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     marginLeft: 8,
    //     marginRight: 8
    //   }}
    //   onPress={() => {
    //     dispatchState({
    //       type: "toggleBoolean",
    //       prop: "multiEdit"
    //     });
    //   }}
    // >
    //   <IconTooltip
    //     title={t("editAll")}
    //     name={"circle-edit-outline"}
    //     size={24}
    //     color={state.multiEdit ? "#000" : "#fff"}
    //   />
    // </TouchableOpacity>
    return (
      <View style={theme.header}>
        <View style={theme.flexRow}>
          <IconButton
            icon={"arrow-left"}
            onPress={onBackButtonPress}
            color={props.colors.text}
          />
          <View style={theme.headerTitleContainer}>
            <Text style={theme.headerTitle}>
              {getTranslatedText(state.title, props.lang)}
            </Text>
          </View>
        </View>
        <View
          style={[
            theme.flex,
            theme.hPadding8,
            {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            },
          ]}
        >
          <ButtonGroup
            buttons={[
              (
                state.measObj?.multiEditAllowed
                  ? compareRole(
                      props.editorRole,
                      state.measObj?.multiEditAllowed
                    )
                  : true
              )
                ? {
                    backgroundColor: state.multiEdit
                      ? null
                      : colors.lightAccent,
                    color: state.multiEdit ? null : colors.accent,
                    title: t("editAll"),
                    onPress: () =>
                      dispatchState({
                        type: "toggleBoolean",
                        prop: "multiEdit",
                      }),
                  }
                : null,
              {
                icon: "swap-horizontal",
                onPress: () => {
                  if (state.switchButton === "switchDataObjKey") {
                    switchDataObjKey();
                  } else if (state.switchButton === "switchMeas") {
                    switchMeas();
                  } else if (state.switchButton === "toggleDataObject") {
                    toggleDataObject();
                  }
                },
              },
              state.input
                ? {
                    icon: "cog",
                    onPress: () => setState({ settingsVisible: true }),
                  }
                : {
                    backgroundColor: props.measurementObjectsOpenByDefault
                      ? null
                      : colors.lightAccent,
                    color: props.measurementObjectsOpenByDefault
                      ? null
                      : colors.accent,
                    icon: props.measurementObjectsOpenByDefault
                      ? "eye"
                      : "eye-off",
                    onPress: toggleDataIsVisible,
                  },
            ]}
          />
        </View>
      </View>
    );
  };

  const modalDualInputIndex = checkIfDualInput(state.modalInputMeasurementKey);
  const modalIsDualInput = modalDualInputIndex === -1 ? false : true;

  const valueInputUnit = state.extraDataObjProps
    ? state.extraDataObjProps.unit
    : modalIsDualInput
    ? getTranslatedUnit(
        state.units?.[state.dualInputs[modalDualInputIndex].firstInput],
        props.lang
      )
    : getTranslatedUnit(
        state.units?.[state.modalInputMeasurementKey],
        props.lang
      );

  const valueInputUnit2 = state.extraDataObjProps
    ? state.extraDataObjProps.unit2
    : modalIsDualInput
    ? getTranslatedUnit(
        state.units?.[state.dualInputs[modalDualInputIndex].scndInput],
        props.lang
      )
    : null;

  const valueInputMeasurementKey = state.extraDataObjProps
    ? state.extraDataObjProps.measurementKey
    : modalIsDualInput
    ? state.dualInputs[modalDualInputIndex].firstInput
    : state.modalInputMeasurementKey;

  const valueInputMeasurementKey2 = state.extraDataObjProps
    ? state.extraDataObjProps.measurementKey2
    : modalIsDualInput
    ? state.dualInputs[modalDualInputIndex].scndInput
    : null;

  const renderSectionHeader = ({ section }) => {
    const { id, valueKey } = section.item;
    const selected = state.selected.includes(valueKey);
    const isVisible = props.visibleData === valueKey;

    return (
      <View
        style={OS === "web" ? theme.hPadding8 : null}
        onLayout={saveItemHeight(section.index * 3)}
      >
        <View
          style={[
            props.theme.rowContainer,
            {
              paddingTop: 4,
              paddingBottom:
                props.measurementObjectsOpenByDefault || isVisible ? 0 : 4,
              backgroundColor: colors.lightBg,
            },
          ]}
        >
          {creationRestricted ? null : (
            <>
              <IconButton
                backgroundColor={colors.accent}
                color={colors.textOnAccent}
                icon={selected ? "select-inverse" : "select"}
                size={42}
                onPress={() => addItemToSelected(valueKey)}
              />
              <View
                style={{
                  height: "100%",
                  width: 2,
                }}
              />
              <IconButton
                backgroundColor={colors.accent}
                color={colors.textOnAccent}
                size={42}
                icon="content-duplicate"
                onPress={() => {
                  props.addToObjectArrWithGeneratedId({
                    userId: props.profile.id,
                    docId: props.docId,
                    valueKey: props.layoutId,
                    duplicateValueKey: valueKey,
                    value: { id },
                    idProp: "valueKey",
                    sortProp: "id",
                    setVisibleData: true,
                    type: "MeasObj",
                  });
                }}
              />
            </>
          )}

          <View style={[props.theme.fullHeightFlex, { height: 42 }]}>
            <TextInput
              disableSeparateFullscreen
              key={valueKey}
              style={[
                props.theme.text,
                {
                  flex: 1,
                  alignSelf: "stretch",
                  height: 42,
                  justifyContent: "center",
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: props.colors.primary,
                },
                OS !== "web" ? null : { outline: "none" },
              ]}
              defaultValue={id}
              onChangeText={(text) => {
                tmpText.current[valueKey] = text;
              }}
              onBlur={() => {
                if (tmpText.current[valueKey]) {
                  setDataObj(null, tmpText.current[valueKey], id, valueKey);
                  tmpText.current[valueKey] = undefined;
                }
              }}
            />
          </View>

          <IconButton
            backgroundColor={colors.secondary}
            color={colors.text}
            icon={
              props.measurementObjectsOpenByDefault || isVisible
                ? "chevron-up"
                : "chevron-down"
            }
            height={42}
            width={72}
            onPress={() => {
              if (props.measurementObjectsOpenByDefault) {
                toggleDataIsVisible();
                props.setVisible(valueKey);
              } else {
                props.setVisible(isVisible ? null : valueKey);
              }
            }}
          />
        </View>
      </View>
    );
  };

  const { theme, colors } = props;
  let _dataObj,
    layoutId,
    valueKey,
    measurementKey,
    dualInputIndex,
    isDualInput,
    _id,
    worstValue = 0,
    values = [],
    secondaryId,
    values2,
    worstValue2,
    unit,
    unit2,
    isPicker;

  if (state.input) {
    _dataObj = dataObj.find(
      (innerItem) => innerItem.valueKey == state.inputDataObjKey
    );
    layoutId = props.layoutId;
    valueKey = state.inputDataObjKey;
    measurementKey = state.inputMeasurementKey;

    dualInputIndex = checkIfDualInput(measurementKey);
    isDualInput = dualInputIndex !== -1;

    _id;

    if (isDualInput) {
      _id = `${layoutId}_${valueKey}_${state.dualInputs[dualInputIndex]?.firstInput}`;
      measurementKey = `${state.dualInputs[dualInputIndex]?.firstInput}`;
    } else {
      _id = `${layoutId}_${valueKey}_${measurementKey}`;
    }

    const valueObj = props.docToModify.values[_id] ?? {
      worstValue: 0,
      values: [],
    };

    worstValue = valueObj.worstValue;
    values = valueObj.values;

    secondaryId = null;
    if (
      state.dualInputs &&
      typeof dualInputIndex === "number" &&
      state.dualInputs[dualInputIndex]
    ) {
      secondaryId = `${layoutId}_${valueKey}_${state.dualInputs[dualInputIndex].scndInput}`;
    }

    values2 =
      secondaryId !== null
        ? props.docToModify.values[secondaryId]?.values ?? []
        : [];

    worstValue2 =
      secondaryId !== null
        ? props.docToModify.values[secondaryId]?.worstValue ?? 0
        : 0;

    unit = isDualInput
      ? state.units?.[state.dualInputs[dualInputIndex].firstInput]
      : state.units?.[state.measurementIndex];
    unit = getTranslatedUnit(unit, props.lang);
    unit2 = isDualInput
      ? state.units?.[state.dualInputs[dualInputIndex].scndInput]
      : null;
    unit2 = getTranslatedUnit(unit2, props.lang);

    isPicker = checkIfPicker(state.inputMeasurementKey);
    if (!_dataObj) {
      // TODO error message
      // captureSentryMessage(
      //   `missing data object; innerItem:${JSON.stringify(
      //     innerItem
      //   )}, inputDataObjKey: ${
      //     state.inputDataObjKey
      //   }, dataObj: ${JSON.stringify(dataObj)}`
      // );
    }
  }
  const onViewableItemsChanged = ({ viewableItems }) => {
    if (mountHandled.current) {
      props.handleViewableItemsChanged(viewableItems);
    }
  };
  const getItemLayout = (data, index) => {
    const length = itemHeights.current[index] ?? 0;
    const offset = itemHeights.current
      .slice(0, index)
      .reduce((a, c) => a + (c ?? 0), 0);
    return { length, offset, index: index };
  };
  const saveItemHeight = (index) => (object) => {
    itemHeights.current[index] = object.nativeEvent.layout.height;
  };
  return (
    <View
      testID={"measObjectsInnerContainer"}
      style={{
        flex: 8,
        display: "flex",
      }}
    >
      {renderHeader(props.theme, props.colors)}
      {state.selected.length !== 0
        ? renderRemovalTitleRow(props.theme, props.colors)
        : null}

      {OS === "web" ? renderMeasurementControls() : null}

      {state.input ? (
        props.profile.measurementsPerGroup ? (
          <View
            style={{
              flex: 1,
              padding: 8,
              paddingTop: 8,
              paddingBottom: 8,
              backgroundColor: colors.primary,
            }}
          >
            <FlatList
              listKey={"measurementRowsList"}
              keyboardShouldPersistTaps="handled"
              initialNumToRender={15}
              nestedScrollEnabled
              data={Object.keys(state.titles || {})}
              removeClippedSubviews={false}
              ListHeaderComponent={
                <View>
                  <TitleRowWithArrows
                    theme={theme}
                    colors={colors}
                    title={
                      isDualInput
                        ? getTranslatedText(
                            state.titles?.[
                              state.dualInputs[dualInputIndex].firstInput
                            ],
                            props.lang
                          ) +
                          " / " +
                          getTranslatedText(
                            state.titles?.[
                              state.dualInputs[dualInputIndex].scndInput
                            ],
                            props.lang
                          )
                        : getTranslatedText(
                            state.titles?.[state.inputMeasurementKey],
                            props.lang
                          )
                    }
                  />
                  <MeasurementRow
                    valueKey={valueKey}
                    isPicker={checkIfPicker(state.inputMeasurementKey)}
                    pickerOptions={
                      state.inputsAsPicker?.[state.inputMeasurementKey]?.options
                    }
                    isDualInput={isDualInput}
                    measurementKey={measurementKey}
                    measurementKey2={secondaryId}
                    title={_dataObj.id}
                    unit={unit}
                    unit2={unit2}
                    dataObj={_dataObj}
                    dataObjKey={_id}
                    secondaryDataObjKey={secondaryId}
                    onRemove={removeModularMeasurement}
                    navigation={props.navigation}
                    measurementTitle={getTranslatedText(
                      state.titles?.[state.inputMeasurementKey],
                      props.lang
                    )}
                    value={worstValue}
                    value2={worstValue2}
                    values={values}
                    values2={values2}
                    dataPropName={_id}
                    addValue={addValue}
                    setMeasurementRef={setMeasurementRef}
                    measurementRefs={measurementRefs}
                    autoFocus
                    onGroupRowPress={(currentValue) =>
                      onGroupRowPress(
                        state.inputDataObjKey,
                        state.inputMeasurementKey,
                        null,
                        currentValue
                      )
                    }
                    valueListTitle={true}
                  />
                </View>
              }
              keyExtractor={(item) => item + "MeasurementRow"}
              renderItem={({ item }) => {
                const dualInputIndex = checkIfDualInput(item);
                const listItemIsDualInput =
                  dualInputIndex === -1 ? false : true;

                const rowMeasurementKey = listItemIsDualInput
                  ? state.dualInputs[dualInputIndex].firstInput
                  : item;
                const rowMeasurementKey2 = listItemIsDualInput
                  ? state.dualInputs[dualInputIndex].scndInput
                  : null;

                const _id = `${layoutId}_${valueKey}_${rowMeasurementKey}`;

                const { worstValue = 0, values = [] } = props.docToModify
                  .values[_id] ?? {
                  worstValue: 0,
                  values: [],
                };

                let secondaryId = null;
                if (rowMeasurementKey2) {
                  secondaryId = `${layoutId}_${valueKey}_${rowMeasurementKey2}`;
                }

                const worstValue2 =
                  secondaryId !== null
                    ? props.docToModify.values[secondaryId]?.worstValue ?? 0
                    : 0;

                const values2 =
                  secondaryId !== null
                    ? props.docToModify.values[secondaryId]?.values ?? []
                    : [];

                let unit = state.units?.[rowMeasurementKey];
                unit = getTranslatedUnit(unit, props.lang);
                let unit2 = state.units?.[rowMeasurementKey2] ?? null;
                unit2 = getTranslatedUnit(unit2, props.lang);

                if (item == state.inputMeasurementKey) {
                  return null;
                }
                if (
                  rowMeasurementKey2 == state.inputMeasurementKey ||
                  rowMeasurementKey == state.inputMeasurementKey
                ) {
                  return null;
                }
                if (listItemIsDualInput && item == rowMeasurementKey2) {
                  return null;
                }

                return (
                  <View
                    style={[
                      {
                        backgroundColor: colors.primary,
                        marginTop: 8,
                      },
                    ]}
                  >
                    <TitleRowWithArrows
                      theme={theme}
                      colors={colors}
                      title={
                        listItemIsDualInput
                          ? getTranslatedText(
                              state.titles?.[
                                state.dualInputs[dualInputIndex].firstInput
                              ],
                              props.lang
                            ) +
                            " / " +
                            getTranslatedText(
                              state.titles?.[
                                state.dualInputs[dualInputIndex].scndInput
                              ],
                              props.lang
                            )
                          : getTranslatedText(state.titles?.[item], props.lang)
                      }
                    />
                    <MeasurementRow
                      pickerOptions={
                        state.inputsAsPicker?.[state.inputMeasurementKey]
                          ?.options
                      }
                      title={_dataObj.id}
                      dataObj={_dataObj}
                      onRemove={removeModularMeasurement}
                      navigation={props.navigation}
                      measurementTitle={getTranslatedText(
                        state.titles?.[state.inputMeasurementKey],
                        props.lang
                      )}
                      addValue={addValue}
                      setMeasurementRef={setMeasurementRef}
                      measurementRefs={measurementRefs}
                      isPicker={checkIfPicker(item)}
                      isDualInput={listItemIsDualInput}
                      value={worstValue}
                      values={values}
                      value2={worstValue2}
                      values2={values2}
                      measurementKey={rowMeasurementKey}
                      measurementKey2={rowMeasurementKey2}
                      unit={unit}
                      unit2={unit2}
                      dataObjKey={_id}
                      secondaryDataObjKey={secondaryId}
                      dataPropName={_id}
                      onGroupRowPress={(currentValue) =>
                        onGroupRowPress(
                          state.inputDataObjKey,
                          rowMeasurementKey,
                          null,
                          currentValue
                        )
                      }
                    />
                  </View>
                );
              }}
            />
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              padding: 8,
              paddingTop: 8,
              paddingBottom: 8,
              backgroundColor: colors.primary,
            }}
          >
            <TitleRowWithArrows
              theme={theme}
              colors={colors}
              title={
                isDualInput
                  ? getTranslatedText(
                      state.titles?.[
                        state.dualInputs[dualInputIndex].firstInput
                      ],
                      props.lang
                    ) +
                    " / " +
                    getTranslatedText(
                      state.titles?.[
                        state.dualInputs[dualInputIndex].scndInput
                      ],
                      props.lang
                    )
                  : getTranslatedText(
                      state.titles?.[state.inputMeasurementKey],
                      props.lang
                    )
              }
            />
            <FlatList
              listKey={"measurementRowsList"}
              keyboardShouldPersistTaps="handled"
              initialNumToRender={15}
              nestedScrollEnabled
              data={dataObj}
              removeClippedSubviews={false}
              ListHeaderComponent={
                (values?.length === 0 && values2?.length === 0) || isPicker ? (
                  <View style={{ marginTop: 8 }}>
                    <MeasurementRow
                      isPicker={checkIfPicker(state.inputMeasurementKey)}
                      pickerOptions={
                        state.inputsAsPicker?.[state.inputMeasurementKey]
                          ?.options
                      }
                      isDualInput={isDualInput}
                      measurementKey={measurementKey}
                      measurementKey2={secondaryId}
                      title={_dataObj.id}
                      unit={unit}
                      unit2={unit2}
                      dataObj={_dataObj}
                      dataObjKey={_id}
                      secondaryDataObjKey={secondaryId}
                      onRemove={removeModularMeasurement}
                      navigation={props.navigation}
                      measurementTitle={getTranslatedText(
                        state.titles?.[state.inputMeasurementKey],
                        props.lang
                      )}
                      value={worstValue}
                      value2={worstValue2}
                      values={values}
                      values2={values2}
                      dataPropName={_id}
                      addValue={addValue}
                      setMeasurementRef={setMeasurementRef}
                      measurementRefs={measurementRefs}
                      autoFocus
                      onGroupRowPress={(currentValue) =>
                        onGroupRowPress(
                          state.inputDataObjKey,
                          state.inputMeasurementKey,
                          null,
                          currentValue
                        )
                      }
                      valueListTitle={true}
                      where={"ListHeaderComponent"}
                    />
                  </View>
                ) : (
                  <MeasurementRow
                    isPicker={checkIfPicker(state.inputMeasurementKey)}
                    pickerOptions={
                      state.inputsAsPicker?.[state.inputMeasurementKey]?.options
                    }
                    isDualInput={isDualInput}
                    measurementKey={measurementKey}
                    measurementKey2={secondaryId}
                    title={_dataObj.id}
                    unit={unit}
                    unit2={unit2}
                    dataObj={_dataObj}
                    dataObjKey={_id}
                    secondaryDataObjKey={secondaryId}
                    onRemove={removeModularMeasurement}
                    navigation={props.navigation}
                    measurementTitle={getTranslatedText(
                      state.titles?.[state.inputMeasurementKey],
                      props.lang
                    )}
                    value={worstValue}
                    value2={worstValue2}
                    values={values}
                    values2={values2}
                    dataPropName={_id}
                    addValue={addValue}
                    setMeasurementRef={setMeasurementRef}
                    measurementRefs={measurementRefs}
                    autoFocus
                    onGroupRowPress={(currentValue) =>
                      onGroupRowPress(
                        state.inputDataObjKey,
                        state.inputMeasurementKey,
                        null,
                        currentValue
                      )
                    }
                    valueListTitle={true}
                    where={"ListHeaderComponent"}
                  />
                )
              }
              keyExtractor={(item) => "MeasurementRow" + item.valueKey}
              renderItem={({ item }) => {
                const layoutId = props.layoutId;
                const valueKey = item.valueKey;
                let measurementKey = state.measurementIndex;

                let __id = `${layoutId}_${valueKey}_${measurementKey}`;

                let secondaryId = null;

                const _dualInputIndex = checkIfDualInput(measurementKey);
                const _isDualInput = _dualInputIndex !== -1;

                if (isDualInput) {
                  __id = `${layoutId}_${valueKey}_${state.dualInputs[dualInputIndex]?.firstInput}`;
                  measurementKey = `${state.dualInputs[dualInputIndex]?.firstInput}`;
                } else {
                  __id = `${layoutId}_${valueKey}_${measurementKey}`;
                }

                if (
                  state.dualInputs &&
                  typeof _dualInputIndex === "number" &&
                  state.dualInputs[_dualInputIndex]
                ) {
                  secondaryId = `${layoutId}_${valueKey}_${state.dualInputs[dualInputIndex].scndInput}`;
                }

                const { worstValue = 0, values = [] } = props.docToModify
                  .values[__id] ?? { worstValue: 0, values: [] };

                const values2 =
                  secondaryId !== null
                    ? props.docToModify.values[secondaryId]?.values ?? []
                    : [];
                const worstValue2 =
                  (secondaryId &&
                    (props.docToModify.values[secondaryId]?.worstValue ?? 0)) ||
                  0;

                if (item.valueKey !== state.inputDataObjKey) {
                  return (
                    <View style={{ marginTop: 8 }}>
                      <MeasurementRow
                        pickerOptions={
                          state.inputsAsPicker?.[state.inputMeasurementKey]
                            ?.options
                        }
                        measurementKey={measurementKey}
                        measurementKey2={secondaryId}
                        unit={unit}
                        unit2={unit2}
                        dataObj={item}
                        dataObjKey={__id}
                        secondaryDataObjKey={secondaryId}
                        onRemove={removeModularMeasurement}
                        navigation={props.navigation}
                        measurementTitle={getTranslatedText(
                          state.titles?.[state.inputMeasurementKey],
                          props.lang
                        )}
                        dataPropName={_id}
                        addValue={addValue}
                        setMeasurementRef={setMeasurementRef}
                        measurementRefs={measurementRefs}
                        title={item.id}
                        value={worstValue}
                        value2={worstValue2}
                        values={values}
                        values2={values2}
                        isDualInput={_isDualInput}
                        isPicker={checkIfPicker(measurementKey)}
                        onGroupRowPress={(currentValue) =>
                          onGroupRowPress(
                            item.valueKey,
                            state.inputMeasurementKey,
                            null,
                            currentValue
                          )
                        }
                        where={"renderItem"}
                      />
                    </View>
                  );
                }
              }}
            />
          </View>
        )
      ) : (
        <View
          testID={"measObjectsListContainer"}
          style={[
            OS !== "web" ? props.theme.horizontalPaddingContainer : {},
            {
              display: "flex",
              flex: 8,
              backgroundColor: props.colors.primary,
              paddingTop: 8,
            },
          ]}
        >
          <SectionList
            //viewabilityConfig={viewConfigRef.current}
            //onViewableItemsChanged={onViewRef.current}
            //extraData={showListHeader}
            getItemLayout={getItemLayout}
            // onContentSizeChange={handleInitialScroll}
            listKey={"measObjScreenList"}
            ref={listRef}
            onViewableItemsChanged={onViewableItemsChanged}
            onLayout={handleInitialScroll}
            onScroll={props.handleScroll}
            initialNumToRender={
              props.scrollToValueKeys ? sectionsData.count : 15
            }
            renderSectionFooter={() => <View style={props.theme.listDivider} />}
            keyboardShouldPersistTaps="handled"
            sections={sectionsData.sections}
            removeClippedSubviews={false}
            keyExtractor={(item, index) => item.valueKey + "Row" + index}
            stickySectionHeadersEnabled
            renderSectionHeader={renderSectionHeader}
            renderItem={({ item, index, section }) => {
              const { id, valueKey } = item;
              const isVisible =
                props.measurementObjectsOpenByDefault ||
                props.visibleData === valueKey;

              if (isVisible) {
                return (
                  <View
                    style={{
                      padding: 4,
                      backgroundColor: props.colors.lightBg,
                      margin: OS !== "web" ? 0 : 8,
                      marginBottom: 8,
                      marginTop: 0,
                    }}
                    onLayout={saveItemHeight(section.index * 3 + 1)}
                  >
                    <View
                      style={{
                        padding: 8,
                        paddingTop: 0,
                        backgroundColor: props.colors.primary,
                      }}
                    >
                      {state.extraData?.map((cell, i) => {
                        const _valueKey = `${props.layoutId}_${valueKey}_${cell.valueKey}`;
                        const _values = props.docToModify.values;

                        if (cell.type === "connectedCheckBoxPicker") {
                          const measurementObjectId = props.layoutId;
                          const objValueKey = valueKey;

                          let data = [];

                          if (cell.reverse) {
                            // cell needs a connectedLayoutId from measurementObject layout if it's a reverse connection
                            if (!state.connectedLayoutId) return null;
                            // loop all objects and all measurementSources connections to find connections
                            cell.measurementSources.forEach((x) => {
                              if (_values[x]) {
                                _values[x].forEach((y) => {
                                  const connectedValueKey = x + "_connected";
                                  const connectedIndex = (
                                    _values[connectedValueKey] || []
                                  ).findIndex(
                                    (z) =>
                                      z.valueKey == y.valueKey &&
                                      z.measurementObjectId ===
                                        props.layoutId &&
                                      z.connectedToValueKey == valueKey
                                  );

                                  data.push({
                                    ...y,
                                    connectedLayoutId: props.layoutId,
                                    connectedIndex: connectedIndex ?? -1,
                                    connected: connectedIndex !== -1,
                                  });
                                });
                              }
                            });
                          } else {
                            const connectedValueKey =
                              measurementObjectId + "_connected";

                            cell.measurementSources.forEach((x) => {
                              if (_values[x]) {
                                _values[x].forEach((y) => {
                                  const connectedIndex = (
                                    _values[connectedValueKey] || []
                                  ).findIndex(
                                    (z) =>
                                      z.valueKey == valueKey &&
                                      z.measurementObjectId === x &&
                                      z.connectedToValueKey == y.valueKey
                                  );

                                  data.push({
                                    ...y,
                                    connectedLayoutId: x,
                                    connectedIndex: connectedIndex ?? -1,
                                    connected: connectedIndex !== -1,
                                  });
                                });
                              }
                            });
                          }

                          return (
                            <View
                              key={"ExtraData" + cell.valueKey}
                              style={[
                                props.theme.rowContainer,
                                {
                                  backgroundColor: props.colors.primary,
                                  marginTop: 8,
                                },
                              ]}
                            >
                              <ModalButton
                                title={getTranslatedText(
                                  cell.title,
                                  props.lang
                                )}
                                text={t("select")}
                                onModalPress={() =>
                                  toggleCheckBoxPicker(
                                    valueKey,
                                    `${id} - ${getTranslatedText(
                                      cell.title,
                                      props.lang
                                    )}`
                                  )
                                }
                                border={false}
                              >
                                <CheckBoxPicker
                                  visible={
                                    state.checkBoxPicker.visible === valueKey
                                  }
                                  title={state.checkBoxPicker.title}
                                  toggleModal={() => toggleCheckBoxPicker()}
                                  data={data}
                                  extraButtons={[
                                    {
                                      title: t("addNew"),
                                      onPress: () => {
                                        // TODO handle multiple cell.measurementSources
                                        if (
                                          cell.measurementSources.length === 1
                                        ) {
                                          setState({
                                            checkBoxPicker: { visible: false },
                                            setModal: {
                                              modal: "textModal",
                                              props: {
                                                title: getTranslatedText(
                                                  cell.title,
                                                  props.lang
                                                ),
                                                visible: true,
                                                value: id, // current measurementObjects name
                                                onSave: (_text) => {
                                                  // Empty input
                                                  if (!/\S/.test(_text)) {
                                                    setState({
                                                      textErr:
                                                        t("titleNotFilledErr"),
                                                    });
                                                  } else {
                                                    addToObjectArrWithGeneratedId(
                                                      {
                                                        userId:
                                                          props.profile.id,
                                                        docId: props.docId,
                                                        valueKey:
                                                          cell
                                                            .measurementSources[0],
                                                        value: {
                                                          id: _text,
                                                        },
                                                        idProp: "valueKey",
                                                        sortProp: "id",
                                                        setVisibleData: false,
                                                        type: "MeasObj",
                                                        addMeasurementObjConnection:
                                                          {
                                                            docId: props.docId,
                                                            measurementObjectId:
                                                              cell
                                                                .measurementSources[0],
                                                            measurementObjectToConnectId:
                                                              props.layoutId,
                                                            measurementObjectToConnectValueKey:
                                                              valueKey,
                                                          },
                                                      },
                                                      false
                                                    );
                                                    setState({
                                                      textModal: {
                                                        visible: false,
                                                      },
                                                      setModal: {
                                                        modal: "checkBoxPicker",
                                                        props: {
                                                          visible: true,
                                                        },
                                                      },
                                                    });
                                                  }
                                                },
                                              },
                                            },
                                          });
                                        }
                                      },
                                    },
                                  ]}
                                  onCheckBoxPress={(connectedInfo) => {
                                    const payload = cell.reverse
                                      ? {
                                          docId: props.docId,
                                          measurementObjectId:
                                            state.connectedLayoutId,
                                          measurementObjectValueKey:
                                            connectedInfo.valueKey,
                                          measurementObjectToConnectId:
                                            connectedInfo.connectedLayoutId,
                                          measurementObjectToConnectValueKey:
                                            objValueKey,
                                        }
                                      : {
                                          docId: props.docId,
                                          measurementObjectId:
                                            measurementObjectId,
                                          measurementObjectValueKey:
                                            objValueKey,
                                          measurementObjectToConnectId:
                                            connectedInfo.connectedLayoutId,
                                          measurementObjectToConnectValueKey:
                                            connectedInfo.valueKey,
                                        };
                                    if (connectedInfo.connected) {
                                      props.removeMeasurementObjConnection(
                                        payload
                                      );
                                    } else {
                                      props.addMeasurementObjConnection(
                                        payload
                                      );
                                    }
                                  }}
                                />
                              </ModalButton>
                            </View>
                          );
                        } else if (cell.type === "extraMeasurementRow") {
                          const id = valueKey;

                          let connections;
                          try {
                            connections =
                              _values[
                                cell.measurementSource + "_connected"
                              ]?.filter?.(
                                (connection) =>
                                  connection.measurementObjectId ===
                                    props.layoutId &&
                                  connection.connectedToValueKey == id
                              ) ?? [];
                          } catch (error) {
                            errorReport({
                              error,
                              errorInFn: "connections filter",
                              errorInScreen: "MeasurementObjectsScreen",
                            });
                            return null;
                          }
                          return connections.map((connection) => {
                            const measurementObject = getReduxLayout(
                              props.options,
                              cell.measurementSource,
                              cell.layoutVersion
                            );
                            return cell.measurementKeys.map((i) => {
                              const maxOrMin = measurementObject.maxOrMin[i];
                              const dualInput =
                                measurementObject.dualInputs.find(
                                  (x) => x.firstInput == i || x.scndInput == i
                                );

                              if (dualInput?.scndInput == i) {
                                return null;
                              }
                              const measurementKey = dualInput
                                ? dualInput.firstInput
                                : i;
                              const measurementKey2 = dualInput
                                ? dualInput.scndInput
                                : null;

                              const measKey = dualInput
                                ? dualInput.firstInput
                                : i;
                              const measKey2 = dualInput
                                ? dualInput.scndInput
                                : null;

                              const title = dualInput
                                ? getTranslatedText(
                                    measurementObject.titles[
                                      dualInput.firstInput
                                    ],
                                    props.lang
                                  )
                                : getTranslatedText(
                                    measurementObject.titles[i],
                                    props.lang
                                  );
                              const title2 = dualInput
                                ? getTranslatedText(
                                    measurementObject.titles[
                                      dualInput.scndInput
                                    ],
                                    props.lang
                                  )
                                : "";

                              let unit = dualInput
                                ? measurementObject.units[dualInput.firstInput]
                                : measurementObject.units[i];
                              unit = getTranslatedUnit(unit, props.lang);
                              let unit2 = dualInput
                                ? measurementObject.units[dualInput.scndInput]
                                : "";
                              unit2 = getTranslatedUnit(unit2, props.lang);

                              const _id = `${cell.measurementSource}_${connection.valueKey}_${measKey}`;
                              const _secondaryId = `${cell.measurementSource}_${connection.valueKey}_${measKey2}`;
                              const itemValue = _values[_id];
                              const itemValue2 = _values[_secondaryId];

                              const connectedDataObj = _values[
                                cell.measurementSource
                              ]?.find(
                                (x) => x.valueKey === connection.valueKey
                              );
                              const connectedDataObjTitle = connectedDataObj
                                ? connectedDataObj.id + " - "
                                : "";
                              return (
                                <View
                                  key={"Values" + i.toString()}
                                  style={[
                                    // props.theme.rowContainer,
                                    {
                                      backgroundColor: props.colors.primary,
                                      marginTop: 8,
                                    },
                                  ]}
                                >
                                  <MeasurementRow
                                    title={connectedDataObjTitle + title}
                                    title2={
                                      title2
                                        ? connectedDataObjTitle + title2
                                        : null
                                    }
                                    navigation={props.navigation}
                                    docId={props.docId}
                                    isDualInput={dualInput ? true : false}
                                    measurementKey={measurementKey}
                                    measurementKey2={measurementKey2}
                                    unit={unit}
                                    unit2={unit2}
                                    maxOrMin={maxOrMin}
                                    onRemove={removeModularMeasurement}
                                    dataObjKey={_id}
                                    dataObj={connection}
                                    //                                     dataObj={dataObj.find(
                                    //   (innerItem) => innerItem.valueKey == connection.coonectedToValueKey
                                    // )}
                                    secondaryDataObjKey={_secondaryId}
                                    onGroupRowPress={(currentValue) =>
                                      onGroupRowPress(
                                        connection.valueKey,
                                        i,
                                        {
                                          extraDataObj: cell.measurementSource,
                                          key: connection.valueKey,
                                          unit,
                                          unit2,
                                          measurementKey: measKey,
                                          measurementKey2: measKey2,
                                        },
                                        currentValue
                                      )
                                    }
                                    addValue={(
                                      value,
                                      measurementKey,
                                      valueKey
                                    ) =>
                                      addValue(
                                        value,
                                        measurementKey,
                                        valueKey,
                                        cell.measurementSource
                                      )
                                    }
                                    value={itemValue ? itemValue.worstValue : 0}
                                    values={itemValue ? itemValue.values : []}
                                    value2={itemValue2?.worstValue}
                                    values2={itemValue2?.values}
                                    dataPropName={props.layoutId}
                                    setMeasurementRef={setMeasurementRef}
                                    measurementRefs={measurementRefs}
                                  />
                                </View>
                              );
                            });
                          });
                        } else {
                          let screenToGoBackTo = "create";
                          return (
                            // TODO memoize cellcomponent props
                            <View key={"extraData" + i.toString()}>
                              <CellComponent
                                //{...cell}
                                role={props.editorRole}
                                uiSettings={props.uiSettings}
                                optimize={true}
                                userId={props.profile.id}
                                layoutId={props.layoutId}
                                visibleExtraRowsData={
                                  props.visibleExtraRowsData
                                }
                                key={"CellComponentExtraData" + i}
                                onlineDoc={
                                  props.connectionState === "Connected"
                                }
                                screenToGoBackTo={screenToGoBackTo}
                                urlStart={props.urlStart}
                                theme={props.theme}
                                colors={props.colors}
                                pageH={props.pageH}
                                pageW={props.pageW}
                                fullHeight={props.fullHeight}
                                fullWidth={props.fullWidth}
                                isFetching={props.isFetching}
                                item={cell}
                                setDatePicker={props.setDatePicker}
                                onGroupRowPress={onGroupRowPress}
                                extraCellProps={{
                                  userId: props.profile.id,
                                  valueKey: valueKey,
                                  id,
                                  measurementObjectId: props.layoutId,
                                  connectedLayoutId: state.connectedLayoutId,
                                  fuseSizeOptions: Object.keys(
                                    props.options.gGgLComparablesSlow || {}
                                  ),
                                  ...cell?.sequenceProps,
                                  fuseSize: cell?.sequenceProps
                                    ?.fuseSizeValueKey
                                    ? _values[
                                        `${props.layoutId}_${valueKey}_${cell.sequenceProps.fuseSizeValueKey}`
                                      ]
                                    : null,
                                  fuseType: cell?.sequenceProps
                                    ?.fuseTypeValueKey
                                    ? _values[
                                        `${props.layoutId}_${valueKey}_${cell.sequenceProps.fuseTypeValueKey}`
                                      ]
                                    : null,
                                  addToObjectArrWithGeneratedId:
                                    props.addToObjectArrWithGeneratedId,
                                  modifyObjectArrItem:
                                    props.modifyObjectArrItem,
                                  modifyValue: modifyValue,
                                }}
                                profile={props.profile}
                                measurementObjectId={props.layoutId}
                                measurementObjectIndex={valueKey}
                                docId={props.docId}
                                itemIndex={valueKey}
                                innerItemIndex={_valueKey}
                                valueKeyPrefix={`${props.layoutId}_${valueKey}`}
                                valueKey={cell.valueKey}
                                units={state.units}
                                titles={state.titles}
                                navigation={props.navigation}
                                //values={_values}
                                modifyValueItem={props.modifyValueItem}
                                lang={props.lang}
                                setValueInputState={setValueInputState}
                                options={_options}
                                attachments={props.options?.attachments || {}}
                                setTextModal={props.setTextModal}
                                modifyObjectArrItem={props.modifyObjectArrItem}
                                replaceObjectArrItem={
                                  props.replaceObjectArrItem
                                }
                                addToObjectArr={props.addToObjectArr}
                                deleteFromStringArr={props.deleteFromStringArr}
                                modifyStringArr={props.modifyStringArr}
                                addToStringArr={props.addToStringArr}
                                addToObjectArrWithGeneratedId={
                                  addToObjectArrWithGeneratedId
                                }
                                deleteFromObjectArr={deleteFromObjectArr}
                                modifyValue={modifyValue}
                                getMultiEditValueKeys={
                                  state.multiEdit ? getMultiEditValueKeys : null
                                }
                                navigate={props.navigate}
                                goBack={props.goBack}
                                setInputModal={props.setInputModal}
                              />
                            </View>
                          );
                        }
                      })}

                      {/* Display the measurement values */}
                      {/* {alert(props.layoutId + "_" + valueKey + "_")} */}
                      {Object.keys(state.titles || {}).map((i) => {
                        const currentValue =
                          props.docToModify.values[
                            props.layoutId + "_" + valueKey + "_" + i
                          ]?.worstValue ?? 0;
                        return (
                          <View
                            key={"Values" + i.toString()}
                            style={{
                              flex: 1,
                              backgroundColor: props.colors.primary,
                              marginTop: 8,
                            }}
                          >
                            <TextRow
                              title={getTranslatedText(
                                state.titles?.[i],
                                props.lang
                              )}
                              unit={getTranslatedUnit(
                                state.units?.[i],
                                props.lang
                              )}
                              value={currentValue}
                              onPress={() =>
                                setValueInputState(
                                  valueKey,
                                  i,
                                  index,
                                  i,
                                  id,
                                  null,
                                  currentValue.toString()
                                )
                              }
                            />
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              } else
                return (
                  <View onLayout={saveItemHeight(section.index * 3 + 1)} />
                );
            }}
          />

          {state.error !== "" ? (
            <View>
              <View style={props.theme.line} />
              <View
                style={[
                  props.theme.halfContainer,
                  props.theme.rowContainer,
                  { margin: 16 },
                ]}
              >
                <Text
                  style={[
                    props.theme.text,
                    { textAlign: "center", color: props.colors.error },
                  ]}
                >
                  {state.error}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      )}

      {OS !== "web" ? renderMeasurementControls() : null}
      {OS !== "web" && state.input
        ? null
        : renderButtons(props.theme, props.colors)}

      <ValueInputModal
        unit={valueInputUnit}
        unit2={valueInputUnit2}
        measurementKey={valueInputMeasurementKey}
        measurementKey2={valueInputMeasurementKey2}
        title={
          state.extraDataObjProps
            ? getTranslatedText(
                getReduxLayout(
                  props.options,
                  state.extraDataObjProps.extraDataObj,
                  state.measurementObjectVersion
                )?.titles[valueInputMeasurementKey],
                props.lang
              )
            : getTranslatedText(
                state.titles?.[valueInputMeasurementKey],
                props.lang
              )
        }
        secondaryTitle={
          state.extraDataObjProps
            ? getTranslatedText(
                getReduxLayout(
                  props.options,
                  state.extraDataObjProps.extraDataObj,
                  state.measurementObjectVersion
                )?.titles[valueInputMeasurementKey2],
                props.lang
              )
            : getTranslatedText(
                state.titles?.[valueInputMeasurementKey2],
                props.lang
              )
        }
        id={
          state.extraDataObjProps
            ? props.docToModify.values[
                state.extraDataObjProps.extraDataObj
              ].find((x) => x.valueKey == state.extraDataObjProps.key)?.id ||
              "-"
            : dataObj.find((x) => x.valueKey == state.modalInputDataObjKey)
                ?.id || "-"
        }
        dataObjKey={state.modalInputDataObjKey}
        values={
          state.extraDataObjProps
            ? props.docToModify.values[
                `${state.extraDataObjProps.extraDataObj}_${state.extraDataObjProps.key}_${state.extraDataObjProps.measurementKey}`
              ] ?? null
            : props.docToModify.values[
                `${state.measurementObject}_${state.modalInputDataObjKey}_${
                  state.dualInputs?.[state.dualInputIndex]?.firstInput ??
                  state.inputMeasurementKey
                }`
              ] ?? null
        }
        values2={
          state.extraDataObjProps
            ? props.docToModify.values[
                `${state.extraDataObjProps.extraDataObj}_${state.extraDataObjProps.key}_${state.extraDataObjProps.measurementKey2}`
              ] ?? null
            : props.docToModify.values[
                `${state.measurementObject}_${state.modalInputDataObjKey}_${
                  state.dualInputs?.[state.dualInputIndex]?.scndInput
                }`
              ] ?? null
        }
        isDualInput={
          state.extraDataObjProps
            ? state.extraDataObjProps && state.extraDataObjProps.measurementKey2
            : isDualInput
        }
        // visibility={state.valueInputModalVisible}
        toggleModal={toggleValueInputModal}
        addValue={(value, title) => {
          addValue(value, title, state.modalInputDataObjKey);
        }}
        addDualInputValues={addDualInputValues}
        switchMeasOrGroup={switchMeasOrGroup}
        measurementsPerGroup={props.profile.measurementsPerGroup}
        extraDataObjProps={state.extraDataObjProps ? true : false}
        dataObj={
          state.extraDataObjProps
            ? state.extraDataObjProps.extraDataObj
            : dataObj
        }
      />

      <DataObjModal
        label={t("id")}
        visibility={state.dataObjModalVisible}
        toggleModal={toggleDataObjModal}
        setTempObjName={setTempObjName}
        setDataObj={setDataObj}
        name={state.tempDataObjName}
        err={state.dataObjNameErr}
        maxLength={42}
        blurOnSubmit={false}
        extraButtons={[
          {
            title: t("addMultiple"),
            onPress: () =>
              setDataObj(undefined, undefined, undefined, undefined, true),
          },
        ]}
      />

      <ModalPicker
        onlyPicker={true}
        visible={state.picker?.visible}
        controlledVisibility={true}
        docId={props.docId}
        lastModified={props.options.lastModified}
        isFetching={props.isFetching}
        role={props.profile.role}
        lang={props.lang}
        // pageH={props.pageH}
        // pageW={props.pageW}
        addableProp={true}
        prop={state.picker?.optionsProp}
        boldTitle={true}
        multi={true}
        title={state.picker?.title}
        onAddMultiplePress={onAddMultiplePress}
        toggleModal={togglePicker}
        options={props.options[state.picker?.optionsProp]}
        //modifyValueItem={modifyValueItem}
        //onPress={pickerModify}
        // onRemove={(oldVal, item) =>
        //   deleteFromStringArr({
        //     ...extraModifyProps,
        //     valueKey,
        //     oldVal: oldVal,
        //     docId: props.docId,
        //   })
        // }
        // onAdd={pickerModify}
        // modifyValue={(payload) => {
        //   isMultiPicker ? modifyStringArr(payload) : modifyValue(payload);
        // }}
      />

      <ModalPicker
        {...getPickerOptions()}
        onlyPicker={true}
        navigation={props.navigation}
        boldTitle={false}
        valueInputPicker={true}
        docId={props.docId}
        lastModified={props.options.lastModified}
        isFetching={props.isFetching}
        role={props.profile.role}
        lang={props.lang}
        valueKey={`${props.layoutId}_${state.modalInputDataObjKey}_${valueInputMeasurementKey}`}
        dataPropName={props.layoutId}
        dataObjKey={state.modalInputDataObjKey}
        dataObjName={
          dataObj.find((x) => x.valueKey == state.modalInputDataObjKey)?.id ||
          "-"
        }
        maxOrMin={state.maxOrMin?.[state.inputMeasurementKey]}
        addableProp={true}
        numeric={true}
        border={false}
        visible={state.measurementPickerVisible}
        controlledVisibility={true}
        toggleModal={toggleMeasPicker}
        measurementTitle={getTranslatedText(
          state.titles?.[
            state.modalInputMeasurementKey || state.inputMeasurementKey
          ],
          props.lang
        )}
        measurementKey={valueInputMeasurementKey}
        switchMeasOrGroup={switchMeasOrGroup}
        measurementsPerGroup={props.profile.measurementsPerGroup}
        addMeasurementFn={props.addMeasurement}
        currentValue={state.modalCurrentValue}
      />
      {state.textModal.visible ? (
        <SimpleModal
          title={state.textModal.title}
          placeholder={state.textModal.placeholder || state.textModal.title}
          errMsg={state.textErr}
          visible={true}
          value={state.textModal.value}
          //isFetching={loading.prop}
          onSave={state.textModal.onSave || setDataObj}
          onToggleModal={toggleTextModal}
          closeOnRighButtonPress={false}
          multiline={state.textModal.multiline}
          numeric={state.textModal.numeric}
          blurOnSubmit={false}
          onSubmitEditing
        />
      ) : null}
      {state.settingsVisible ? (
        <ModalWithTitle
          visible={state.settingsVisible}
          onClose={() => setState({ settingsVisible: false })}
          title={t("settings")}
          rightButtonColor={colors.text}
          rightButtonBgColor={colors.secondary}
          rightButtonTitle={t("close")}
          onRightButtonPress={() => setState({ settingsVisible: false })}
        >
          <ScrollView>
            <SimpleCheckbox
              theme={props.theme}
              colors={props.colors}
              item={{
                key: "measurementsPerGroup",
                value: !!props.profile.measurementsPerGroup,
                title: t("measurementsPerGroup"),
              }}
              setValue={() => {
                props.toggleMeasurementPerGroup(
                  !props.profile.measurementsPerGroup,
                  props.profile
                );
              }}
            />
          </ScrollView>
        </ModalWithTitle>
      ) : null}
    </View>
  );
};
// , shouldNotUpdate);
export default MeasurementObjectsScreen;

import "react";
import { FlatList } from "react-native";
import ListItem from "./ListItem";

export default function List({
  theme,
  colors,
  onRemovePress,
  data,
  translateFn,
  colorValues,
  onItemPress,
}) {
  return (
    <FlatList
      keyboardShouldPersistTaps="handled"
      data={data}
      keyExtractor={(item, index) => "List" + index}
      // ItemSeparatorComponent={() => renderTabRowSeparator(theme, colors)}
      renderItem={({ item, index }) => (
        <ListItem
          theme={theme}
          colors={colors}
          title={translateFn ? translateFn(item, index) : item}
          //isVisible={true}
          isSelected={false}
          noVisibilityToggle={true}
          handleTitlePress={onItemPress ? () => onItemPress(item, index) : null}
          // handleSelect={() => null}
          // handleSetVisible={() => null}
          disabled={false}
          haveData={true}
          //onModifyPress={() => null}
          onRemovePress={onRemovePress(index)}
          valueIsColor={colorValues}
        />
      )}
    />
  );
}

import { Suspense, lazy, useState, startTransition } from "react";
import { ErrorBoundary } from "@sentry/react";
import PrivateRoute from "./components/PrivateRoute";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Switch, BrowserRouter, Redirect, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "../../common/store/index";

import {
  ThemeContext,
  themes,
  colors,
} from "../../common/theming/theme-context";

import Loading from "./screens/Loading";
import DemoScreen from "./screens/DemoScreen";
import PricingScreen from "./screens/PricingScreen";
import SignInWrapper from "./screens/SignInWrapper";
import GDPR from "./screens/GDPR";
import TOS from "./screens/TOS";
import Contact from "./screens/Contact";
import ProfileScreenWrapper from "./screens/ProfileScreenWrapper";
import SettingsScreenWrapper from "./screens/SettingsScreenWrapper";
import Authentication from "./screens/Authentication";
import AppInfoWrapper from "./screens/AppInfoWrapper";

import initializeI18n from "../../common/lib/i18n";
import "../../common/theming/Resizable.css";
import DocStatisticsScreenWrapper from "./screens/DocStatisticsScreenWrapper";
import DailySiteDocumentWrapper from "./screens/DailySiteDocumentWrapper";
import { ModalProvider, createModalStack } from "react-native-modalfy";
import CellModal from "../../common/components/CellModal";
import SendDocsModal from "../../common/components/SendDocsModal";

const CompanyRegistrationWrapper = lazy(() =>
  import("./screens/CompanyRegistrationWrapper")
);

const FolderBrowse = lazy(() => import("./screens/FolderBrowseWrapper"));
const EditCompanyWrapper = lazy(() => import("./screens/EditCompanyWrapper"));
const BrowseProjectsWrapper = lazy(() =>
  import("./screens/BrowseProjectsWrapper")
);
const PreviewAndSignWrapper = lazy(() =>
  import("./screens/PreviewAndSignWrapper")
);
const RecipientsAndSignersWrapper = lazy(() =>
  import("./screens/RecipientsAndSignersWrapper")
);
const CreateDocWrapper = lazy(() => import("./screens/CreateDocWrapper"));
const LinkDocFormWrapper = lazy(() => import("./screens/LinkDocFormWrapper"));
const MeasurementObjectsWrapper = lazy(() =>
  import("./screens/MeasurementObjectsWrapper")
);
const ModularItemsWrapper = lazy(() => import("./screens/ModularItemsWrapper"));
const AttachmentsGalleryWrapper = lazy(() =>
  import("./screens/AttachmentsGalleryWrapper")
);
const BrowseWrapper = lazy(() => import("./screens/BrowseWrapper"));
const ProjectWrapper = lazy(() => import("./screens/ProjectWrapper"));
const BrowseInstruments = lazy(() =>
  import("./screens/BrowseInstrumentsWrapper")
);
const BrowseCustomers = lazy(() => import("./screens/BrowseCustomersWrapper"));
const BrowseProducts = lazy(() => import("./screens/BrowseProductsWrapper"));
const BrowseSites = lazy(() => import("./screens/BrowseSitesWrapper"));
const TasksCalendar = lazy(() => import("./screens/TasksCalendarWrapper"));
const AppSettings = lazy(() => import("./screens/AppSettings"));
const LayoutEditor = lazy(() => import("./screens/LayoutEditorWrapper"));
const ProductivityScreen = lazy(() => import("./screens/ProductivityScreen"));
const PartnerScreen = lazy(() => import("./screens/PartnerScreen"));
// import whyDidYouRender from "@welldone-software/why-did-you-render";

const logos = {
  dark: require("./assets/full-logo-dark.png"),
  light: require("./assets/full-logo-light.png"),
  doLogo: require("./assets/app-logo.png"),
  fondionIcon: require("./assets/fondionIcon.png"),
};

const modalConfig = { CellModal, SendDocsModal };

const stack = createModalStack(
  modalConfig,
  // doesn't update on save, need to reload app
  {
    backdropOpacity: 0.6,
    position: "center",
    containerStyle: {
      marginBottom: 32,
    },
    disableFlingGesture: true,
    // containerStyle: {
    //   flex: 1,
    //   alignItems: 'flex-end',
    //   justifyContent: 'flex-end',
    // },
  }
);

// if (__DEV__) {
//   whyDidYouRender(React, {
//     trackAllPureComponents: false, // Track all pure components
//     // trackHooks: true,
//   });
// }

const fontSizeStringToNum = {
  SmallText: 0.8,
  MediumText: 1,
  LargeText: 1.2,
};

const getFontSize = (theme, fontSizeString) => {
  if (fontSizeString !== "MediumText") {
    return Object.entries(theme).reduce((acc, [key, val]) => {
      if (Object.prototype.hasOwnProperty.call(val, "fontSize")) {
        acc[key] = {
          ...val,
          fontSize: val.fontSize * fontSizeStringToNum[fontSizeString],
        };
      } else {
        acc[key] = val;
      }
      return acc;
    }, {});
  } else {
    return theme;
  }
};

const defaultTheme = "light";
function App() {
  const [state, setState] = useState({
    changeFontSize: changeFontSize,
    toggleTheme: () => null,
    logos,
  });

  const handleCSSThemeChange = (selectedTheme, colors) => {
    const themeLink = document.getElementById("flexlayout-theme");

    if (themeLink) {
      if (selectedTheme === "light") {
        themeLink.href = "/flexlayout-light.css";
      } else {
        themeLink.href = "/flexlayout-dark.css";
      }
    }

    // set css variable used by tagify library
    document.documentElement.style.setProperty("--input-color", colors.text);
  };

  function toggleTheme(selectedTheme) {
    const _colors = selectedTheme === "light" ? colors.light : colors.dark;
    handleCSSThemeChange(selectedTheme, _colors);
    setState((_) => ({
      ..._,
      toggleTheme,
      selectedTheme,
      theme: getFontSize(themes[selectedTheme ?? defaultTheme], _.fontSize),
      colors: _colors,
      logo: selectedTheme === "light" ? logos.light : logos.dark,
    }));
  }

  function changeFontSize(newSize) {
    setState((_) => ({
      ..._,
      toggleTheme,
      theme: getFontSize(themes[_.selectedTheme ?? defaultTheme], newSize),
      fontSize: newSize,
    }));
  }

  function onBeforeLift() {
    startTransition(initializeI18n);

    // ! EXCEPTION HANDLER TEST
    // throw new Error('My Sentry error!');
    // Sentry.nativeCrash();
    // ! EXCEPTION HANDLER TEST

    const _state = store.getState();
    const userTheme = defaultTheme; //_state.userInfo.uiSettings?.selectedTheme || defaultTheme;
    const userFontSize = _state.userInfo.profile.fontSize || "MediumText";
    const theme = themes[userTheme ?? defaultTheme];
    const _colors = userTheme === "light" ? colors.light : colors.dark;
    handleCSSThemeChange(userTheme, _colors);
    setState((_) => ({
      ..._,
      toggleTheme,
      theme,
      colors: _colors,
      logo: userTheme === "light" ? logos.light : logos.dark,
      lightLogo: logos.light,
      doLogo: logos.doLogo,
      fontSize: userFontSize,
      selectedTheme: userTheme,
      orientation: 1,
      lightTheme: themes.light,
      lightColors: colors.light,
    }));

    return;
  }

  return (
    <ErrorBoundary fallback={<h5>Something went wrong</h5>}>
      <DndProvider backend={HTML5Backend}>
        <ThemeContext.Provider value={state}>
          <Provider store={store}>
            <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
              <ModalProvider stack={stack}>
                <BrowserRouter>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route path="/AppInfo" component={AppInfoWrapper} />
                      <Route
                        path="/companyRegistration"
                        component={CompanyRegistrationWrapper}
                      />
                      <Route path="/signin" component={SignInWrapper} />
                      <Route path="/trialRegister" component={SignInWrapper} />
                      <Route path="/apple/redirect" component={SignInWrapper} />
                      <Route
                        path="/google/redirect"
                        component={SignInWrapper}
                      />
                      <Route path="/activate" component={SignInWrapper} />
                      <Route path="/passReset" component={SignInWrapper} />
                      <Route path="/contact" component={Contact} />
                      <Redirect from="/gdpr" to="/privacy-policy" />
                      <Route path="/privacy-policy" component={GDPR} />
                      <Route path="/tos" component={TOS} />
                      <Route
                        path="/authentication"
                        component={Authentication}
                      />
                      <Route
                        path="/signDoc"
                        component={PreviewAndSignWrapper}
                      />
                      <Route
                        path="/downloadDoc"
                        component={PreviewAndSignWrapper}
                      />
                      <PrivateRoute
                        path="/tasks"
                        title="tasks"
                        component={ProjectWrapper}
                        allowedRoles={[
                          "Manager",
                          "Boss",
                          "Admin",
                          "SuperAdmin",
                        ]}
                        //allowedFeatures={[0, 1]}
                        //allowedCompanies={["Document_Ocean_Oy", "Antti_Boi_Oy"]}
                      />

                      <PrivateRoute
                        permissionKeys={["projects"]}
                        path="/projects/id=:id"
                        title={"projects"}
                        component={ProjectWrapper}
                        allowedRoles={[
                          "Manager",
                          "Boss",
                          "Admin",
                          "SuperAdmin",
                        ]}
                      />
                      <PrivateRoute
                        title={"projects"}
                        permissionKeys={["projects"]}
                        path={["/projects", "/projects?arrName=:arrName"]}
                        component={BrowseProjectsWrapper}
                        allowedRoles={[
                          "Manager",
                          "Boss",
                          "Admin",
                          "SuperAdmin",
                        ]}
                      />

                      <PrivateRoute
                        title="productivity"
                        path="/productivity"
                        component={ProductivityScreen}
                        allowedRoles={["Admin", "SuperAdmin"]}
                      />
                      <PrivateRoute
                        title="layoutEditor"
                        path="/layoutEditor"
                        component={LayoutEditor}
                        allowedRoles={[
                          "Manager",
                          "Boss",
                          "Admin",
                          "SuperAdmin",
                        ]}
                      />

                      <PrivateRoute
                        title="appSettings"
                        path="/appSettings"
                        component={AppSettings}
                        allowedRoles={["SuperAdmin"]}
                      />

                      <PrivateRoute
                        title="previewScreen"
                        path={[
                          //"/PreviewAndSign/id=:id/docRef=:docRef/docsArrName=:docsArrName/docType=:docType",
                          //"/PreviewAndSign/id=:id/docRef=:docRef/docsArrName=:docsArrName/docType=",
                          "/PreviewAndSign?id=:id&docRef=:docRef&docsArrName=:docsArrName&docType=",
                          "/PreviewAndSign?id=:id&docRef=:docRef&docsArrName=:docsArrName&docType=:docType",
                          "/PreviewAndSign?id=:id",
                          "/PreviewAndSign",
                        ]}
                        component={PreviewAndSignWrapper}
                        editTokenAllowed
                      />
                      <PrivateRoute
                        title="recipientsAndSigners"
                        path={[
                          "/recipientsAndSigners?id=:id",
                          "/recipientsAndSigners",
                        ]}
                        component={RecipientsAndSignersWrapper}
                      />
                      <PrivateRoute
                        title="gallery"
                        path="/attachmentsGallery"
                        component={AttachmentsGalleryWrapper}
                      />
                      <PrivateRoute
                        title="gallery"
                        path="/attachmentsGalleryPicker"
                        component={AttachmentsGalleryWrapper}
                      />
                      <PrivateRoute
                        title="docs"
                        path={["/browse?arrName=:arrName", "/browse"]}
                        component={BrowseWrapper}
                      />

                      <Route path="/ecom" component={PartnerScreen} />
                      <Route path="/fondion" component={PartnerScreen} />

                      <PrivateRoute
                        title="docEditing"
                        path={[
                          //create?form=measurementObjects&layoutId=measurementObjects/1&layoutVersion=1&viewUnfinishedLayout=&valueKey=
                          "/create?docId=:docId&form=:form&layoutId=:layoutId&layoutVersion=:layoutVersion&viewUnfinishedLayout=:viewUnfinishedLayout&valueKey=:valueKey",
                          "/create?docId=:docId&form=:form&standAlone=:standAlone&onlyImages=:onlyImages&offlineAtch=:offlineAtch&allowedTypes=:allowedTypes&single=:single&valueKey=:valueKey",
                          "/create?docId=:docId&form=:form",
                          "/create?docId=:docId",
                          "/create?id=:docId",
                          "/create?pathToAdd=:pathToAdd",
                          "/create",
                        ]}
                        component={CreateDocWrapper}
                      />
                      <PrivateRoute
                        title="docEditing"
                        path={[
                          //create?form=measurementObjects&layoutId=measurementObjects/1&layoutVersion=1&viewUnfinishedLayout=&valueKey=
                          "/editForm?docId=:docId&form=:form&layoutId=:layoutId&layoutVersion=:layoutVersion&viewUnfinishedLayout=:viewUnfinishedLayout&valueKey=:valueKey",
                          "/editForm?docId=:docId&form=:form&standAlone=:standAlone&onlyImages=:onlyImages&offlineAtch=:offlineAtch&allowedTypes=:allowedTypes&single=:single&valueKey=:valueKey",
                          "/editForm?docId=:docId&form=:form",
                          "/editForm?docId=:docId",
                          "/editForm",
                        ]}
                        component={CreateDocWrapper}
                      />
                      <PrivateRoute
                        title="measurements"
                        path="/measurementObjects"
                        component={MeasurementObjectsWrapper}
                      />
                      <PrivateRoute
                        path="/modularItems"
                        component={ModularItemsWrapper}
                      />
                      <PrivateRoute
                        title="profile"
                        path="/profile"
                        component={ProfileScreenWrapper}
                      />
                      <PrivateRoute
                        title="settings"
                        path="/settings"
                        component={SettingsScreenWrapper}
                      />
                      <PrivateRoute
                        title="companyPanel"
                        path={["/company"]}
                        component={EditCompanyWrapper}
                        allowedRoles={[
                          "SuperAdmin",
                          "Admin",
                          "Boss",
                          "Manager",
                          //"User",
                        ]}
                      />
                      <PrivateRoute
                        title="companyPanel"
                        path={["/companies"]}
                        component={EditCompanyWrapper}
                        allowedRoles={["SuperAdmin", "Admin"]}
                      />
                      <PrivateRoute
                        title="products"
                        path="/products"
                        component={BrowseProducts}
                        allowedRoles={[
                          "SuperAdmin",
                          "Admin",
                          "Boss",
                          "Manager",
                        ]}
                      />
                      <PrivateRoute
                        title="calendar"
                        path="/taskCalendar"
                        component={TasksCalendar}
                      />
                      <PrivateRoute
                        title="customers"
                        path="/customers"
                        component={BrowseCustomers}
                      />
                      <PrivateRoute
                        title="instruments"
                        path="/instruments"
                        component={BrowseInstruments}
                      />
                      <PrivateRoute
                        title="sites"
                        path="/sites"
                        component={BrowseSites}
                      />
                      <PrivateRoute
                        title="document-statistics"
                        path={[
                          "/document-statistics",
                          "/document-statistics?layoutId=:layoutId",
                        ]}
                        component={DocStatisticsScreenWrapper}
                      />
                      <PrivateRoute
                        title="daily-site-document"
                        path={[
                          "/daily-site-document",
                          "/daily-site-document?layoutId=:layoutId",
                        ]}
                        component={DailySiteDocumentWrapper}
                      />
                      <PrivateRoute
                        path={"/document"}
                        component={LinkDocFormWrapper}
                        editTokenAllowed
                      />
                      <Route path="/pricing" component={PricingScreen} />
                      <Route path="/demo" component={DemoScreen} />
                      <PrivateRoute
                        title="folders"
                        permissionKeys={["folders"]}
                        path="/folders"
                        component={FolderBrowse}
                        editTokenAllowed
                      />
                      <Route path="/" component={SignInWrapper} />
                    </Switch>
                  </Suspense>
                </BrowserRouter>
              </ModalProvider>
            </PersistGate>
          </Provider>
        </ThemeContext.Provider>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              minWidth: 300,
              maxWidth: "90vw",
              paddingRight: 28,
              backgroundColor: state.colors?.accent,
              color: state.colors?.textOnAccent,
              fontWeight: 600,
              fontSize: "1.4em",
            },
          }}
        />
        <div className="doceanmodal" />
        <div className="doceanpopup" />
        <div className="doceantooltip" />
      </DndProvider>
    </ErrorBoundary>
  );
}

export default App;

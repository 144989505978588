import "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, fullHp } from "../lib/helperFns";
import Modal from "./Modal";
import DualButtons from "./DualButtons";
import TOS from "./TOS-text";

export default (props) => {
  const { t } = useTranslation();
  return (
    <ThemeContext.Consumer>
      {({ colors }) => (
        <Modal
          {...modalPickerOpts}
          deviceHeight={fullHp(100)}
          backdropOpacity={1}
          isVisible={props.isVisible}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View>
            <TOS />
            <DualButtons
              leftButtonDisabled={props.hideAcceptButton}
              leftButtonTitle={t("back")}
              onLeftButtonPress={props.onClose}
              rightButtonTitle={props.rightButtonTitle || t("agree")}
              onRightButtonPress={props.onAccept}
            />
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
};

import { useRef, useState, useEffect } from "react";
import { ActivityIndicator } from "react-native";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import IconButton from "./IconButton";
import { ThemeContext } from "../theming/theme-context";

import { SET_UI_SETTINGS_PROP } from "../actions/ProfileActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  downloadResource,
  downloadSignedUrlAsBase64,
} from "../lib/fileOperations";
import { api } from "../lib/constants";

const documentOptions = {
  cMapUrl: "/cmaps/",
};

const INITIAL_PAGE_WIDTH = 700;
const minScale = 50;
const maxScale = 500;
const scaleAmount = 25;
function PdfDocument(props) {
  const { height, pageW, noClass, hideFooter } = props;
  const [doc, setDoc] = useState(props.doc);
  const [downloading, setDownloading] = useState(false);
  const [pdfComponents, setPdfComponents] = useState(null);
  const [pages, setPages] = useState(null);
  const _INITIAL_PAGE_WIDTH = props.INITIAL_PAGE_WIDTH || INITIAL_PAGE_WIDTH;
  const scale =
    props.pdfscale ??
    (_INITIAL_PAGE_WIDTH > pageW
      ? Math.floor((pageW / _INITIAL_PAGE_WIDTH) * 100)
      : 100);
  const [page, setPage] = useState(1);

  const wrapperRef = useRef();
  const _page = props.page ?? page;
  const pageRefs = useRef({});

  const loadPDF = async () => {
    // Dynamically import react-pdf components
    const { Document, Page, pdfjs } = await import("react-pdf");

    // Set the worker source
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //   "../assets/pdf.worker.min.mjs",
    //   import.meta.url
    // ).toString();
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    // Set the components once they're loaded
    setPdfComponents({ Document, Page });
  };

  const onDocumentLoadSuccess = (ev) => {
    setPages(ev.numPages);
  };

  const onWheel = (e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();

      if (e.deltaY < 0) {
        increaseScale();
      } else if (e.deltaY > 0) {
        decreaseScale();
      }
    }
  };

  const loadData = async () => {
    if (props.src) {
      let res;
      let url = props.src;
      if (url.startsWith(`${api}/attachments`)) {
        // Extract attachment id from the uri
        const parsedUrl = new URL(url);
        const extractedPath = decodeURIComponent(
          parsedUrl.pathname.split("/attachments/")[1]
        );
        res = await downloadResource(
          extractedPath,
          "tmp.pdf",
          "application/pdf",
          null,
          props.customToken,
          true
        );
      } else {
        res = await downloadSignedUrlAsBase64(props.src);
      }
      if (res) setDoc(res);
    }
  };

  useEffect(() => {
    loadData();
  }, [props.src]);

  useEffect(() => {
    loadPDF();
  }, []);

  useEffect(() => {
    wrapperRef.current.addEventListener("wheel", onWheel, { passive: false });

    return () => {
      wrapperRef.current?.removeEventListener("wheel", onWheel, {
        passive: false,
      });
    };
  }, [scale]);

  const increaseScale = () => {
    if (scale <= maxScale) {
      if (scale + scaleAmount > maxScale) {
        props.SET_UI_SETTINGS_PROP({
          prop: "pdfscale",
          value: maxScale,
        });
      } else {
        props.SET_UI_SETTINGS_PROP({
          prop: "pdfscale",
          value: parseInt(scale) + scaleAmount,
        });
      }
    }
  };

  const decreaseScale = () => {
    if (scale >= minScale) {
      if (scale - scaleAmount < minScale) {
        props.SET_UI_SETTINGS_PROP({
          prop: "pdfscale",
          value: minScale,
        });
      } else {
        props.SET_UI_SETTINGS_PROP({
          prop: "pdfscale",
          value: parseInt(scale) - scaleAmount,
        });
      }
    }
  };

  const fitToScreen = () => {
    props.SET_UI_SETTINGS_PROP({
      prop: "pdfscale",
      value:
        _INITIAL_PAGE_WIDTH > pageW
          ? Math.floor((pageW / _INITIAL_PAGE_WIDTH) * 100)
          : 100,
    });
  };

  const prevPageDisabled = _page === 1;
  const nextPageDisabled = _page === pages;
  const minusScaleDisabled = scale <= minScale;
  const plusScaleDisabled = scale >= maxScale;
  const footerHeight = hideFooter ? 0 : 36;
  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div
          ref={wrapperRef}
          style={props.wrapperStyle ?? { width: pageW, height: height }}
        >
          {hideFooter ? null : pages ? (
            <div style={theming.theme.pdfFooter}>
              <>
                <div style={theming.theme.stretchRowContainer}>
                  <IconButton
                    theme={theming.theme}
                    onPress={() => {
                      setPage((_page) => _page - 1);
                      pageRefs.current?.[_page - 2].scrollIntoView(_page - 2);
                    }}
                    icon="chevron-left"
                    color={
                      prevPageDisabled
                        ? theming.colors.disabledText
                        : theming.colors.text
                    }
                    disabled={prevPageDisabled}
                  />

                  <p style={theming.theme.text}>{_page + " / " + pages}</p>

                  <IconButton
                    theme={theming.theme}
                    onPress={() => {
                      setPage((_page) => _page + 1);
                      pageRefs.current?.[_page].scrollIntoView();
                    }}
                    icon="chevron-right"
                    color={
                      nextPageDisabled
                        ? theming.colors.disabledText
                        : theming.colors.text
                    }
                    disabled={nextPageDisabled}
                  />
                </div>

                <div style={theming.theme.stretchRowContainer}>
                  <IconButton
                    theme={theming.theme}
                    onPress={decreaseScale}
                    icon="magnify-minus"
                    color={
                      minusScaleDisabled
                        ? theming.colors.disabledText
                        : theming.colors.text
                    }
                    disabled={minusScaleDisabled}
                  />

                  <p style={theming.theme.text}>{scale + "%"}</p>

                  <IconButton
                    theme={theming.theme}
                    onPress={increaseScale}
                    icon="magnify-plus"
                    color={
                      plusScaleDisabled
                        ? theming.colors.disabledText
                        : theming.colors.text
                    }
                    disabled={plusScaleDisabled}
                  />

                  <IconButton
                    theme={theming.theme}
                    onPress={fitToScreen}
                    icon="fit-to-page-outline"
                    color={theming.colors.text}
                  />
                </div>

                {props.onDownload ? (
                  <IconButton
                    theme={theming.theme}
                    onPress={() => {
                      setDownloading(true);
                      props.onDownload(null, setDownloading);
                    }}
                    icon="download"
                    color={theming.colors.accent}
                    loading={downloading}
                  />
                ) : null}
                {props.openSettings ? (
                  <IconButton
                    theme={theming.theme}
                    onPress={props.openSettings}
                    icon="cog"
                    color={theming.colors.accent}
                  />
                ) : null}
              </>
            </div>
          ) : (
            <div style={{ height: footerHeight, width: pageW }}>
              <ActivityIndicator color={theming.colors.accent} />
            </div>
          )}

          <div
            style={{
              backgroundColor: "#424242",
              height: height - footerHeight,
              width: "100%",
              overflowY: "hidden",
              //alignItems: "center",
              // width: pageW,
              overflow: props.pdfDocumentOverflow ?? "auto",
              alignItems:
                (scale / 100) * _INITIAL_PAGE_WIDTH < pageW ? "center" : null,
            }}
          >
            {doc && pdfComponents ? (
              <pdfComponents.Document
                options={documentOptions}
                style={noClass ? null : theming.theme.pdfDoc}
                file={"data:application/pdf;base64," + doc}
                onLoadSuccess={onDocumentLoadSuccess}
                // onLoadError={(err) => }
                // onSourceError={(err) => }
                loading={
                  <div
                    style={{ height: height - footerHeight, width: pageW }}
                  ></div>
                }
                renderMode={"canvas"} //"svg"}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#424242",
                    height: height - footerHeight,
                    // width: pageW,
                    overflow: "auto",
                    alignItems:
                      (scale / 100) * _INITIAL_PAGE_WIDTH < pageW
                        ? "center"
                        : null,
                  }}
                >
                  {Array.from(
                    new Array(props.page ? 1 : pages),
                    (el, index) => (
                      <div
                        key={`page_${props.page ?? index + 1}`}
                        style={{
                          paddingBottom:
                            !props.page && index + 1 !== pages ? 8 : 0,
                        }}
                      >
                        <pdfComponents.Page
                          inputRef={(ref) =>
                            (pageRefs.current[props.page ?? index] = ref)
                          }
                          //onRenderSuccess={onRenderSuccess}
                          pageNumber={props.page ?? index + 1}
                          width={_INITIAL_PAGE_WIDTH}
                          scale={scale / 100}
                          // customTextRenderer={(textItem) =>
                          //   textItem.str.split(searchText).reduce(
                          //     (strArray, currentValue, currentIndex) =>
                          //       currentIndex === 0
                          //         ? [...strArray, currentValue]
                          //         : [
                          //             ...strArray,
                          //             // eslint-disable-next-line react/no-array-index-key
                          //             <mark key={currentIndex}>
                          //               {searchText}
                          //             </mark>,
                          //             currentValue,
                          //           ],
                          //     []
                          //   )
                          // }
                        />
                      </div>
                    )
                  )}
                </div>
              </pdfComponents.Document>
            ) : null}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SET_UI_SETTINGS_PROP,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    pdfscale: state.userInfo?.uiSettings?.pdfscale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfDocument);

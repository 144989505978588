import "react";
import SwiperElem from "./SwiperElem";

export default function ChartContainer(props) {
  return props.onlyChart || props.dataProps ? (
    <>
      <div
        id={props.valueKey}
        style={{
          backgroundColor: "#fff",
          marginTop: 8,
          height: props.height,
        }}
      >
        {props.chart}
      </div>
      <div style={{ height: 0, overflow: "hidden" }}>
        <div id={"chart" + props.valueKey}>{props.chart}</div>
      </div>
    </>
  ) : (
    <SwiperElem
      showsButtons={true}
      horizontal={true}
      showsPagination={false}
      views={[
        props.views,
        <>
          <div
            id={props.valueKey}
            style={{
              backgroundColor: "#fff",
              marginTop: 8,
              height: props.height,
            }}
          >
            {props.chart}
          </div>
          <div style={{ height: 0, overflow: "hidden" }}>
            <div id={"chart" + props.valueKey}>{props.chart}</div>
          </div>
        </>,
      ]}
    />
  );
}

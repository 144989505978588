import React, { useRef, useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { Pressable, View } from "react-native";
import {
  useFloating,
  shift,
  limitShift,
  flip,
  offset,
  autoUpdate,
  size,
} from "@floating-ui/react-dom";
import IconButton from "./IconButton";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "./Tooltip";
import { setNativeProps } from "../lib/functions";

function MenuItem(props) {
  const ref = useRef(null);
  const setStyles = (hover) => () => {
    setNativeProps(ref.current, {
      backgroundColor: hover
        ? props.theming.colors.lightAccent
        : props.theming.colors.primary,
      color: hover ? props.theming.colors.text : props.theming.colors.text,
    });
  };

  return (
    <Pressable
      ref={ref}
      onMouseEnter={setStyles(true)}
      onMouseLeave={setStyles()}
      className={(props.disabled ? "opacity50 " : "") + "menuItem"}
      style={props.theming.theme.menuItem}
      onMouseUp={(ev) => {
        if (ev.button === 1 && props.isNavItem && !props.disabled)
          props.onClick(ev);
      }}
      onPress={props.disabled ? null : props.onClick}
    >
      {props.children}
    </Pressable>
  );
}

function MenuComponent(props) {
  const theming = useContext(ThemeContext);
  const virtualEl = props.rest?.mouseX
    ? {
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: props.rest.mouseX,
            y: props.rest.mouseY,
            top: props.rest.mouseY,
            left: props.rest.mouseX,
            right: props.rest.mouseX,
            bottom: props.rest.mouseY,
          };
        },
      }
    : null;
  const { refs, floatingStyles } = useFloating({
    middleware: [
      offset(8),
      flip(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          // Change styles, e.g.
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.max(0, availableWidth)}px`,
            maxHeight: `${Math.max(0, availableHeight)}px`,
          });
        },
      }),
      shift({
        limiter: limitShift({
          // Start limiting 5px earlier
          offset: 5,
        }),
      }),
    ],
    strategy: "absolute",
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    elements: {
      reference: virtualEl ?? props.rest.anchorEl,
    },
  });
  const [actionPressed, setActionPressed] = useState(null);

  useEffect(() => {
    if (actionPressed) {
      if (actionPressed.x.action) {
        actionPressed.x.action(actionPressed.ev);
      } else {
        props.rest.action(actionPressed.x);
      }
      if (props.rest?.dontCloseOnAction) {
        setActionPressed(null);
      } else {
        props.handleClose();
      }
    }
  }, [actionPressed]);

  useEffect(() => {
    setActionPressed(null);
  }, [props.rest.visible]);

  React.useEffect(() => {
    if (props.rest.anchorEl) {
      refs.setReference(props.rest.anchorEl);
    }
  }, [props.rest.anchorEl, refs]);

  const overlayRef = useRef(null);
  const mouseDownElem = useRef(null);

  // Track if the click started inside the modal
  const handleMouseDown = (e) => {
    mouseDownElem.current = e.target;
  };

  // Handle the mouse up event
  const handleMouseUp = (e) => {
    if (
      overlayRef.current === mouseDownElem.current &&
      overlayRef.current === e.target
    ) {
      // Only close if the mouse started and ended on the overlay
      props.handleClose();
    }
  };

  if (!props.rest.visible) return null;

  const elements = document.getElementsByClassName("doceanpopup");
  const el = elements[elements.length - 1];
  return ReactDOM.createPortal(
    <div
      ref={overlayRef}
      className="overlay"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="menu" ref={refs.setFloating} style={floatingStyles}>
        <View
          style={theming.theme.menuContainer}
          onClick={(ev) => {
            handleMouseDown(ev);
            ev.stopPropagation();
          }}
        >
          {props.rest.data
            ? props.rest.data.map((x, i) => (
                <Tooltip key={"contextMenuItem" + i} tip={x.hint}>
                  <MenuItem
                    key={"contextMenuItem" + i}
                    theming={theming}
                    disabled={!!(actionPressed || props.disabled || x.disabled)}
                    onClick={(ev) => {
                      ev.persist();
                      setActionPressed({ x, ev });
                    }}
                    onAuxClick={(ev) => {
                      ev.persist();
                      ev.stopPropagation();
                      setActionPressed({ x, ev, onAuxClick: true });
                    }}
                    startIcon={x.startIcon}
                    isNavItem={x.isNavItem}
                  >
                    {x.component || (
                      <>
                        {x.startIcon ? (
                          <IconButton
                            color={theming.colors.text}
                            icon={x.startIcon || "close"}
                            loading={x.id && x.id === props.loadingAction}
                            disabled={
                              !!(actionPressed || props.disabled || x.disabled)
                            }
                            onPress={(ev) => {
                              ev.persist();
                              setActionPressed({ x, ev });
                            }}
                          />
                        ) : null}

                        <span
                          style={{ ...theming.theme.text, color: null }}
                          className="menu-span"
                        >
                          {props.textProp ? x[props.textProp] : x.title}
                        </span>

                        {x.endText ? (
                          <span
                            style={{ ...theming.theme.text, color: null }}
                            className="menu-span"
                          >
                            {x.endText}
                          </span>
                        ) : null}
                      </>
                    )}
                  </MenuItem>
                </Tooltip>
              ))
            : null}
        </View>
      </div>
    </div>,
    el
  );
}

export default MenuComponent;

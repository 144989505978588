import { Platform } from "react-native";
import update from "immutability-helper";
import moment from "moment";
import { errorReport } from "../lib/functions";

const initialErrors = {
  email: "",
  password: "",
  passwordConf: "",
  name: "",
  lName: "",
  phone: "",
  companyName: "",
  companyPhone: "",
  companyEwManagerPhone: "",
  companyEwManagerEmail: "",
  gdpr: "",
};

const initialInputs = {
  email: "",
  password: "",
  passwordConf: "",
  name: "",
  lName: "",
  phone: "",
  companyName: "",
  companyEwManagerName: "",
  companyEwManagerLName: "",
  companyEwManagerPhone: "",
  companyEwManagerEmail: "",
  gdpr: false,
  passScore: "",
};

const INITIAL_STATE = {
  profile: {
    measurementsPerGroup: false,
  },
  company: null,
  errors: initialErrors,
  inputs: initialInputs,
  fromScreen: null,
  initialURL: null,
  fontSize: "MediumText",
  workingHoursDate: {
    month: new moment().format("M"),
    year: new moment().format("YYYY"),
    plainTextMonth: new moment().format("MMMM"),
  },
  dailyHoursItemUnderEdit: { index: 0, new: false },
  workingHours: {},
};

export default function userInfoReducer(state = INITIAL_STATE, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return INITIAL_STATE;
    } else if (action.type === "SIGN_OUT") {
      if (Platform.OS === "web") {
        return {
          ...INITIAL_STATE,
          profile: { id: state.profile?.id },
          company: { id: state.company?.id },
          uiSettings: state.uiSettings,
        };
      } else {
        return {
          ...state,
          profile: update(state.profile, {
            $apply: (x) => {
              if (x.demo) {
                return update(x, {
                  $unset: ["role", "demo", "email"],
                });
              } else {
                return update(x, { $unset: ["role"] });
              }
            },
          }),
        };
      }
    } else if (action.type === "RECEIVE_USER_DATA") {
      const { data, cleanSignIn } = action.payload;

      let newProfile = cleanSignIn
        ? {
            ...INITIAL_STATE.profile,
            ...data.userFromDb,
          }
        : {
            ...state.profile,
            ...data.userFromDb,
            userLastModified:
              data.userLastModified || state.profile?.userLastModified,
          };

      return {
        ...state,
        token: data.auth.token,
        expireDate: data.auth.expireDate,
        profile: newProfile,
        manager: data.manager,
        company: data.company,
        integrations: data.integrations,
        uiSettings: cleanSignIn
          ? data.uiSettings
          : update(data.uiSettings || {}, {
              $merge: state.uiSettings || {},
            }),
      };
    } else if (action.type === "SET_PROFILE") {
      const { profile, manager, company } = action.payload;

      let newProfile = {
        ...INITIAL_STATE.profile,
        ...profile,
      };

      return {
        ...state,
        profile: newProfile,
        manager: manager,
        company: company,
      };
    } else if (action.type === "UPDATE_OPTIONS") {
      const { data = {} } = action.payload;
      const {
        company,
        manager,
        userFromDb,
        auth,
        userLastModified,
        integrations,
      } = data;
      return {
        ...state,
        token: auth?.token || state.token,
        expireDate: auth?.expireDate || state.expireDate,
        profile: userFromDb
          ? {
              ...state.profile,
              ...userFromDb,
              userLastModified:
                userLastModified || state.profile?.userLastModified,
            }
          : update(state.profile || {}, {
              userLastModified: {
                $set: userLastModified || state.profile?.userLastModified,
              },
            }),
        manager: manager || state.manager,
        company: company || state.company,
        integrations: integrations || state.integrations,
      };
    } else if (action.type === "REFRESH_OPTIONS") {
      if (!action.payload.isUserOption) return state;
      return update(state, {
        profile: {
          $auto: {
            userLastModified: {
              $auto: {
                [`options.${action.payload.option}`]: {
                  $set: action.payload.timeStamp,
                },
              },
            },
          },
        },
      });
    } else if (action.type === "SET_COMPANY_TO_EDIT") {
      if (action.payload.updateObj) {
        return update(state, {
          companyToEdit: { $auto: action.payload.updateObj },
        });
      } else {
        return {
          ...state,
          companyToEdit: action.payload,
        };
      }
    } else if (action.type === "ADD_CREDITS") {
      const { type, credits = 0 } = action.payload;

      return update(state, {
        companyToEdit: {
          [type === "data" ? "dataCredits" : "credits"]: {
            $apply: (x) => x + credits,
          },
        },
      });
    } else if (action.type === "TOGGLE_MEASUREMENTS_PER_GROUP") {
      return {
        ...state,
        profile: {
          ...state.profile,
          measurementsPerGroup: action.payload,
        },
      };
    } else if (action.type === "TOGGLE_PROFILE_FONT_SIZE") {
      return {
        ...state,
        profile: {
          ...state.profile,
          fontSize: action.payload,
        },
      };
    } else if (action.type === "CHANGE_EMAIL") {
      return {
        ...state,
        profile: {
          ...state.profile,
          email: action.payload,
        },
      };
    } else if (action.type === "SET_INPUT_VALUE") {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...action.payload,
        },
      };
    } else if (action.type === "RESET_INPUT_VALUE") {
      return {
        ...state,
        inputs: initialInputs,
        errors: initialErrors,
      };
    } else if (action.type === "SET_INPUT_ERROR") {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    } else if (action.type === "RESET_INPUT_ERRORS") {
      return {
        ...state,
        errors: initialErrors,
      };
    } else if (action.type === "CHANGE_PHONE_NUMBER") {
      return {
        ...state,
        profile: {
          ...state.profile,
          phone: action.payload,
        },
      };
    } else if (action.type === "SET_IDENTIFIED") {
      const { identified, name, lName } = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          name,
          lName,
          identified: identified,
        },
      };
    } else if (action.type === "SET_LICENCE") {
      return {
        ...state,
        profile: update(state.profile, { licence: { $set: action.payload } }),
      };
    } else if (action.type === "TOGGLE_THEME") {
      return {
        ...state,
        profile: {
          ...state.profile,
          theme: action.payload,
        },
      };
    } else if (action.type === "SET_INITIAL_URL") {
      return {
        ...state,
        initialURL: action.payload,
      };
    } else if (action.type === "SET_COMPANYLOGO_URL") {
      return {
        ...state,
        companyLogoUrl: action.payload,
      };
    } else if (action.type === "SET_DEEP_LINK_PARAMS") {
      const { deepLinkPath, deepLinkRoute, deepLinkParams, deepLinkScreen } =
        action.payload;

      return {
        ...state,
        deepLinkPath,
        deepLinkRoute,
        deepLinkParams,
        deepLinkScreen,
      };
    } else if (action.type === "ADD_EMAIL_CHECK_BOX") {
      const { obj } = action.payload;

      return {
        ...state,
        presetEmailCheckBoxes: update(state.presetEmailCheckBoxes || [], {
          $push: [obj],
        }),
      };
    } else if (action.type === "UPDATE_EMAIL_CHECK_BOXES") {
      const presetEmailCheckBoxes = action.payload;

      return update(state, {
        presetEmailCheckBoxes: (tmp) =>
          update(tmp || [], {
            $apply: (tmpBoxes) => {
              if (Array.isArray(presetEmailCheckBoxes)) {
                return tmpBoxes
                  .concat(presetEmailCheckBoxes)
                  .filter(
                    (thing, index, self) =>
                      index === self.findIndex((t) => t.email === thing.email)
                  );
              } else {
                return tmp;
              }
            },
          }),
      });
    } else if (action.type === "REMOVE_EMAIL_CHECK_BOX") {
      const { obj } = action.payload;

      return {
        ...state,
        presetEmailCheckBoxes: update(state.presetEmailCheckBoxes || [], {
          $apply: (x) => {
            return x.filter((y) => y.email !== obj.email);
          },
        }),
      };
    } else if (action.type === "SET_DONT_SHOW_AGAIN") {
      const { prop, val } = action.payload;
      return {
        ...state,
        ["dontShowAgain" + prop]: val,
      };
    } else if (action.type === "SET_GPS_LOCATION") {
      const { gpsLocation } = action.payload;
      const date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
      const newCurrentWeather =
        gpsLocation.currentWeather || state.gpsLocation?.currentWeather;
      return {
        ...state,
        gpsLocation: {
          ...gpsLocation,
          currentWeather: update(newCurrentWeather, {
            $auto: { date: { $set: newCurrentWeather ? date : undefined } },
          }),
          date: date,
        },
      };
    } else if (action.type === "REFRESH_DOCS") {
      const { SET_USER_PROP } = action.payload;
      if (SET_USER_PROP) {
        return update(state, {
          [SET_USER_PROP.prop]: { $set: SET_USER_PROP.value },
        });
      } else {
        return state;
      }
    } else if (action.type === "SET_USER_PROP") {
      const { prop, value } = action.payload;
      return update(state, { [prop]: { $set: value } });
    } else if (action.type === "MERGE_USER_PROP") {
      const { prop, value } = action.payload;
      return update(state, {
        [prop]: (x) => update(x || {}, { $merge: value }),
      });
    } else if (action.type === "SET_COMPANY_SETTINGS_PROP") {
      const { prop, value } = action.payload;
      return update(state, {
        company: (tmpCompany) =>
          update(tmpCompany || {}, {
            settings: (tmp) =>
              update(tmp || {}, {
                [prop]: { $set: value },
              }),
          }),
      });
    } else if (action.type === "MERGE_TMP_COMPANY_PROP") {
      const { prop, value } = action.payload;
      return update(state, {
        companyToEdit: (tmpCompany) =>
          update(tmpCompany || {}, {
            [prop]: (x) => update(x || {}, { $merge: value }),
          }),
      });
    } else if (action.type === "SET_TMP_COMPANY_SETTINGS_PROP") {
      const { prop, value } = action.payload;
      return update(state, {
        companyToEdit: (tmpCompany) =>
          update(tmpCompany || {}, {
            settings: (tmp) =>
              update(tmp || {}, {
                [prop]: { $set: value },
              }),
          }),
      });
    } else if (action.type === "SET_UI_SETTINGS_PROP") {
      const { prop, value, isArray } = action.payload;
      return update(state, {
        uiSettings: (tmp) =>
          update(tmp || {}, {
            [prop]: isArray
              ? {
                  $apply: (x = []) => {
                    const index = x.indexOf(value);
                    if (index !== -1)
                      return update(x, { $splice: [[index, 1]] });
                    else return update(x, { $push: [value] });
                  },
                }
              : { $set: value },
          }),
      });
    } else if (action.type === "RESIZE_COLUMNS") {
      const { prop, override, index, columnCount } = action.payload;
      return update(state, {
        uiSettings: (tmp) =>
          update(tmp ?? {}, {
            [prop]: (tmpColumnWidths) =>
              update(
                tmpColumnWidths ??
                  Array.apply(null, Array(columnCount)).map(
                    () => 1 / columnCount
                  ),
                {
                  [index]: {
                    $set: override.newWidth,
                  },
                  [index + 1]: {
                    $set: override.newNextColWidth,
                  },
                }
              ),
          }),
      });
    }

    return state;
  } catch (error) {
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "UserInfoReducer",
        errorParams: {
          type: action?.type,
          payload: `Error: ${error}, Reducer: UserInfoReducer, Action: ${
            action.type
          }, Payload: ${
            action.payload ? JSON.stringify(action.payload) : "no payload"
          }`,
        },
      });
    } else {
      console.warn(
        `Error: ${error}, Reducer: UserInfoReducer, Action: ${
          action.type
        }, Payload: ${
          action.payload ? JSON.stringify(action.payload) : "no payload"
        }`
      );
    }
    return state;
  }
}

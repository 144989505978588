import React, {
  useReducer,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { View, Platform, FlatList } from "react-native";
import update from "immutability-helper";
import moment from "moment";
import ModalInput from "./ModalInput";
import MonthPicker from "./MonthPicker";
import { ThemeContext } from "../theming/theme-context";
import * as validityCheckers from "../lib/validityCheckers";
import { checkInputVisibility } from "../lib/functions";
import { useTranslation } from "react-i18next";
import ButtonGroup from "./ButtonGroup";
import componentReducer from "../reducers/ComponentReducer";

export default function SimpleForm(props) {
  const { t } = useTranslation();
  const { theme, colors } = React.useContext(ThemeContext);
  const [__state, __dispatchState] = useReducer(componentReducer, {
    inputs: null,
  });
  const state = props.state || __state;
  const _dispatchState = props.dispatchState || __dispatchState;

  const inputs =
    props.multiStateProp !== undefined
      ? state[`${props.multiStateProp}_inputs`]
      : state.inputs;
  const [datePicker, setDatePicker] = useState({
    visible: false,
  });
  const refs = useRef({});
  const flatListRef = useRef(null);

  const dispatchState = (action) => {
    if (props.multiStateProp !== undefined) {
      if (Array.isArray(action.prop)) {
        action.prop[0] = `${action.multiStateProp ?? props.multiStateProp}_${
          action.prop[0]
        }`;
      } else {
        action.prop = `${action.multiStateProp ?? props.multiStateProp}_${
          action.prop
        }`;
      }

      _dispatchState(action);
    } else {
      _dispatchState(action);
    }
  };

  const resetInputs = () => {
    dispatchState({
      type: "set",
      prop: "inputs",
      value: props.inputs,
    });
  };

  useEffect(() => {
    resetInputs();
  }, [props.inputs]);

  useLayoutEffect(() => {
    if (props.disableAutoFocus) return;
    if (inputs === props.inputs) {
      focusFirstInput(props.inputs);
    }
  });

  const focusFirstInput = (inputs) => {
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        const nextInput = inputs?.[i];
        if (nextInput?.key) {
          const nextInputVisibility = checkInputVisibility({
            lang: props.lang,
            input: inputs?.[i],
            values: inputs ?? {},
            innerProp: "value",
          });

          if (nextInputVisibility && refs.current?.[nextInput.key]) {
            setTimeout(() => {
              refs.current[nextInput.key]?.focus();
            }, 200);
            return;
          }
        }
      }
    }
  };

  const closeDatePicker = () => {
    setDatePicker({
      ...datePicker,
      visible: false,
    });
  };
  const toggleDatePicker = (
    valueToUse,
    valueKey,
    title,
    visible,
    minDate,
    maxDate,
    timePicker
  ) => {
    setDatePicker({
      ...datePicker,
      minDate: minDate,
      maxDate: maxDate,
      title: title,
      visible: visible ?? !datePicker.visible,
      date: valueToUse,
      valueKey,
      timePicker,
    });
  };
  const onDatePickerConfirm = (
    year,
    month,
    monthIndex,
    day,
    valueKey,
    time,
    closeModal
  ) => {
    if (valueKey) {
      setInputsWithProps(
        valueKey,
        time
          ? moment(
              `${day}.${monthIndex}.${year}T${time}`,
              "DD.M.YYYYTHH:mm"
            ).format("HH:mm DD.MM.YYYY")
          : `${day}.${monthIndex}.${year}`
      );
      if (
        closeModal ||
        (Platform.OS !== "web" && !time) ||
        Platform.OS === "web"
      ) {
        closeDatePicker();
      }
      //focusNextInput(valueKey);
    }
  };

  function deleteFromObjectArr(payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [payload.valueKey]: {
          value: {
            $apply: (x) => {
              const _index = x.findIndex(
                (y) => y[payload.idProp] === payload.oldVal[payload.idProp]
              );
              if (_index !== -1) {
                return update(x, { $splice: [[_index, 1]] });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function modifyObjectArrItem(payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [payload.valueKey]: {
          value: {
            $apply: (x) => {
              const _index = x.findIndex(
                (y) => y[payload.idProp] === payload.oldVal[payload.idProp]
              );
              if (_index !== -1) {
                return update(x, {
                  [_index]: { [payload.propToSet]: { $set: payload.value } },
                });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function addToStringArr(index, payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: {
          value: {
            $apply: (x = []) => {
              const _index = x.findIndex((y) => y === payload.value);
              if (_index === -1) {
                return update(x, { $push: [payload.value] });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function deleteFromStringArr(index, payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: {
          value: {
            $apply: (x = []) => {
              const _index = x.findIndex((y) => y === payload.item);
              if (_index === -1) {
                return x;
              } else {
                return update(x, { $splice: [[_index, 1]] });
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function setInputsWithProps(index, value) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: { value: { $set: value }, error: { $set: "" } },
      },
    });
  }

  function setErrors(index, value, multiStateProp) {
    dispatchState({
      type: "setWithArray",
      prop: ["inputs", parseInt(index), "error"],
      value,
      multiStateProp,
    });
  }

  function onValueSave(item) {
    let newValue = item.value;
    if (
      typeof newValue === "string" &&
      item.onSaveActions &&
      Array.isArray(item.onSaveActions) &&
      item.onSaveActions.length > 0
    ) {
      newValue = item.multiline ? newValue : newValue.replace(/\r?\n|\r/g, "");
      for (let i = 0; i < item.onSaveActions.length; i++) {
        const action = item.onSaveActions[i];
        if (action === "toLowerCase") newValue = newValue.toLowerCase();
        else if (action === "toUpperCase") newValue = newValue.toUpperCase();
        else if (action === "removeWhitespace")
          newValue = newValue.replace(/[\s]/gi, "");
        else if (action === "trim") newValue = newValue.trim();
      }
    }

    return newValue;
  }

  const validateValue = (
    index,
    key,
    returnError,
    _inputs = inputs,
    multiStateProp = props.multiStateProp
  ) => {
    let error;

    const input = _inputs.find((x) => x.key === key);
    let newValue = onValueSave(input);

    const visible = checkInputVisibility({
      lang: props.lang,
      input: input,
      values: _inputs ?? {},
      innerProp: "value",
    });

    if (visible) {
      if (input.key === "passwordConf") {
        const pass2Input = _inputs.find((x) => x.key === "password");
        if (pass2Input && input.value !== pass2Input.value) {
          error = t("passwordsDontMatch");
        }
      }

      if (
        input.validations &&
        Array.isArray(input.validations) &&
        input.validations.length > 0
      ) {
        for (let j = 0; j < input.validations.length; j++) {
          const validation = input.validations[j];

          if (validation && validation.fn) {
            if (typeof validation.fn === "string") {
              if (
                validityCheckers[validation.fn] &&
                !validityCheckers[validation.fn](newValue, true)
              ) {
                error = validation.errMsg;
              }
            } else if (validation.fn && !validation.fn(newValue, _inputs)) {
              error = validation.errMsg;
            }
          }
        }
      }

      //!validityCheckers.checkIfFilled(newValue, true)
      if (input.required && !validityCheckers.checkIfFilled(newValue)) {
        error = t("requiredValue");
      }
    } else {
      newValue = undefined;
    }

    if (error && !returnError) {
      setErrors(index, error, multiStateProp);
    } else if (input.error && !error) {
      setErrors(index, false, multiStateProp);
    }
    return { key: input.key, error, newValue };
  };

  const scrollToIndex = (index) => {
    if (flatListRef.current && index < inputs.length) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index,
        viewPosition: 0.5, // Center the item in the view
      });
    }
  };

  const handleScrollToIndex = (index) => {
    if (index >= inputs.length) {
      index = inputs.length - 1;
    }
    scrollToIndex(index);
  };

  const validateInputs = (_inputs = inputs, multiStateProp, returnErr) => {
    let error = false;
    let errors = {};
    let objectToReturn = {};

    for (let i = 0; i < _inputs.length; i++) {
      const input = _inputs[i];
      if (input.key) {
        const validateRes = validateValue(
          i,
          input.key,
          true,
          _inputs,
          multiStateProp
        );

        if (validateRes.error) {
          error = true;
          errors[i] = validateRes.error;
        } else {
          objectToReturn[input.key] = validateRes.newValue;
        }
      }
    }

    if (error) {
      const errorIndexes = Object.keys(errors);
      if (_inputs[errorIndexes[0]]) {
        handleScrollToIndex(errorIndexes[0]);
      }

      let updateObj = {};

      for (let i = 0; i < errorIndexes.length; i++) {
        updateObj[parseInt(errorIndexes[i])] = {
          error: { $set: errors[errorIndexes[i]] },
        };
      }

      dispatchState({
        type: "setWithUpdateObj",
        prop: "inputs",
        updateObj,
      });
      if (returnErr) return error;
    } else {
      if (returnErr) return error;
      else props.onSave(objectToReturn);
    }
  };

  const onSavePress = () => {
    // this is used in AddAttachmentScreen for saving multiple attachments at the same time
    if (props.multiStateProps) {
      let errorProp = null;
      for (let i = 0; i < props.multiStateProps.length; i++) {
        const multiStateProp = props.multiStateProps[i];
        const error = validateInputs(
          state[`${multiStateProp}_inputs`],
          multiStateProp,
          true
        );
        if (error) errorProp = multiStateProp;
      }

      if (errorProp !== null) props.onSaveValidationError?.(errorProp);
      else props.onSave();
    } else if (Array.isArray(inputs) && inputs.length > 0) {
      validateInputs();
    } else {
      setErrors(0, t("requiredValue"));
    }
  };

  return (
    <View style={theme.modalListContainer}>
      <FlatList
        listKey={"SimpleForm" + props.listKey}
        ref={flatListRef}
        data={props.inputs}
        keyExtractor={(item, index) => `InputPickerModalInput${index}`}
        initialNumToRender={20}
        removeClippedSubviews={false} // Prevents items from unmounting when scrolled out
        ListHeaderComponent={() => (
          <View>
            {props.prependChildren}
            {props.renderHeader && props.renderHeader(theme, colors)}
          </View>
        )}
        ListFooterComponent={<View>{props.children}</View>}
        style={{ backgroundColor: props.listColor || colors.borderLighter }}
        keyboardShouldPersistTaps="handled"
        onScrollToIndexFailed={(info) => {
          // Scroll to the closest valid index if index is out of range
          scrollToIndex(info.highestMeasuredFrameIndex);
        }}
        renderItem={(itemData) => (
          <ModalInput
            t={t}
            key={"InputPickerModalInput" + itemData.index}
            width={props.width}
            height={props.height}
            noDisabledStyle={props.noDisabledStyle}
            theme={theme}
            colors={colors}
            input={itemData.item}
            index={itemData.index}
            inputs={inputs}
            disabled={props.disabled}
            modifyingExistingItem // passed as true so that input.disabled works TODO rework prop or something
            onChange={setInputsWithProps}
            lang={props.lang}
            refs={refs}
            docId={props.docId}
            onSavePress={onSavePress}
            toggleDatePicker={toggleDatePicker}
            profile={props.profile}
            attachments={props.attachments}
            modifyObjectArrItem={modifyObjectArrItem}
            deleteFromObjectArr={deleteFromObjectArr}
            addToStringArr={addToStringArr}
            deleteFromStringArr={deleteFromStringArr}
            onBlur={validateValue}
          />
        )}
      />

      {props.appendChildren}

      {props.hideButtons ? null : (
        <View style={theme.modalButtonContainer}>
          <ButtonGroup
            buttons={[
              props.disableBackButton
                ? null
                : {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: props.backButtonTitle,
                    onPress: () => props.onBackButtonPress(),
                  },
              ...(props.extraButtons ? props.extraButtons : []),
              {
                title: props.onSaveTitle,
                disabled: props.disabled || props.saveDisabled,
                loading: props.loading,
                iconWhenLoading: props.rightBtnIconWhenLoading,
                onPress: () => onSavePress(),
              },
            ]}
          />
        </View>
      )}

      <MonthPicker
        {...datePicker}
        noToggleOnConfirm
        lang={props.lang}
        dayPicker={true}
        toggle={closeDatePicker}
        onConfirm={onDatePickerConfirm}
        prop={datePicker.valueKey}
      />
    </View>
  );
}

import "react";
import { Text, TouchableOpacity, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";

export default function TextRow(
  props = {
    dualRow: Boolean,
    value: String,
    value2: String,
    unit: String,
    unit2: String,
    title: String,
    dataObjKey: String,
    onPress: Function,
  }
) {
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <TouchableOpacity
          style={{
            flex: 1,
            paddingLeft: 8, //wp(2),
            paddingRight: 8, //wp(2),
          }}
          onPress={() => props.onPress()}
          disabled={!!props.disabled}
        >
          <View style={theme.rowContainer}>
            <View style={[theme.halfContainer, theme.rowContainer]}>
              <View style={{ flex: 1 }}>
                {props.dataObjKey ? (
                  <Text style={theme.boldText}>{props.dataObjKey}</Text>
                ) : null}
                {props.title ? (
                  <Text style={theme.text}>{props.title}</Text>
                ) : null}
              </View>
            </View>

            <View
              style={[
                theme.divider,
                {
                  borderRightColor: props.dividerSameAsTextColor
                    ? theme.text.color
                    : colors.borderLighter,
                },
              ]}
            />

            <View
              style={[
                theme.halfContainer,
                theme.rowContainer,
                { flex: props.dualRow ? 0.45 : 1 },
              ]}
            >
              <Text style={theme.text} numberOfLines={props.numberOfLines}>
                {props.value}
              </Text>
              <Text style={theme.text}>{props.unit}</Text>
            </View>
            {props.dualRow ? (
              <View
                style={{
                  flex: 0.1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={theme.text}> / </Text>
              </View>
            ) : null}
            {props.dualRow ? (
              <View
                style={[
                  theme.halfContainer,
                  theme.rowContainer,
                  { flex: 0.45 },
                ]}
              >
                <Text style={theme.text}>{props.value2} </Text>
                <Text style={theme.text}>{props.unit2}</Text>
              </View>
            ) : null}
          </View>
        </TouchableOpacity>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";

export default function StatusText(props) {
  return props.text ? (
    <ThemeContext.Consumer>
      {(theming) => (
        <View>
          <Text style={theming.theme.boldText}>{props.text}</Text>
        </View>
      )}
    </ThemeContext.Consumer>
  ) : null;
}

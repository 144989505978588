import { Platform } from "react-native";
import { errorReport } from "../lib/functions";

export default function modifyDocReducer(state = false, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return false;
    } else if (action.type === "RECEIVE_USER_DATA") {
      return false;
    } else if (action.type === "SET_DOC_TO_BE_MODIFIED") {
      return action.docId || false;
    } else if (action.type === "CHANGE_UNFINISHED_DOC_ID") {
      if (Platform.OS === "web") return state;
      return action.payload.newDocId;
    } else if (action.type === "CLEAR_MODIFY_DOC") {
      return false;
    } else if (action.type === "SIGN_OUT") {
      return false;
    } else if (action.type === "CLEAR_IF_MODIFYING") {
      if (typeof state === "string") {
        if (action.docIds.includes(state)) {
          return false;
        } else {
          return state;
        }
      } else {
        if (action.docIds.includes(state.id)) {
          return false;
        } else {
          return state;
        }
      }
    }

    return state;
  } catch (error) {
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "ModifyDocReducer",
        errorParams: {
          type: action?.type,
          payload: `Error: ${error}, Reducer: ModifyDocReducer, Action: ${
            action.type
          }, Payload: ${action ? JSON.stringify(action) : "no payload"}`,
        },
      });
    } else {
      console.warn(
        `Error: ${error}, Reducer: ModifyDocReducer, Action: ${
          action.type
        }, Payload: ${action ? JSON.stringify(action) : "no payload"}`
      );
    }
    return state;
  }
}

import update from "immutability-helper";
import {
  addMultipleToState,
  errorReport,
  removeMultipleFromArr,
  replaceArrObjWithIdProp,
  setState,
  valueMatches,
} from "../lib/functions";
import unflatten from "../lib/unflatten";

const componentReducerLogic = (state, action) => {
  switch (action.type) {
    case "set": {
      return setState(action, state);
    }
    case "mergeState":
      return update(state, { $merge: action.value });
    case "replaceArrObj":
      return update(state, {
        [action.prop]: {
          $apply: (x) =>
            update(x || [], {
              $apply: (arr) => {
                const index = arr.findIndex((x) =>
                  valueMatches(action, x, action.value)
                );
                if (index !== -1) {
                  return update(arr, { [index]: { $set: action.value } });
                } else {
                  return update(arr, { $push: [action.value] });
                }
              },
            }),
        },
      });
    case "replaceArrObjWithIdProp":
      return replaceArrObjWithIdProp(action, state);
    case "addToArr":
      return update(state, {
        [action.prop]: {
          $apply: (x) =>
            update(x || [], {
              $apply: (arr) => {
                const index = arr.findIndex((x) =>
                  valueMatches(action, x, action.value)
                );
                if (index === -1) {
                  return update(arr, {
                    $push: action.addMultiple ? action.value : [action.value],
                  });
                } else if (action.removeIfExists) {
                  return update(arr, { $splice: [[index, 1]] });
                } else {
                  return [...arr];
                }
              },
            }),
        },
      });
    case "addMultiple":
      return addMultipleToState(action, state);
    case "removeMultipleFromArr":
      return removeMultipleFromArr(action, state);
    case "removeMultipleFromInnerArr":
      try {
        return update(state, {
          [action.prop]: (tmpProp) =>
            update(tmpProp || {}, {
              [action.innerProp]: (tmpArr) =>
                update(tmpArr || [], {
                  $apply: (x) =>
                    update(x || [], {
                      $apply: (arr) => {
                        return arr.filter(
                          (x) =>
                            !action.value.some((y) =>
                              valueMatches(action, x, y)
                            )
                        );
                      },
                    }),
                }),
            }),
        });
      } catch (error) {
        errorReport({
          error,
          errorInFn: "removeMultipleFromInnerArr",
          errorInScreen: "functions",
        });
        return state;
      }
    case "removeFromArr":
      return update(state, {
        [action.prop]: {
          $apply: (x) =>
            update(x || [], {
              $apply: (arr) => {
                const index = arr.findIndex((x) =>
                  valueMatches(action, x, action.value)
                );

                if (index !== -1) {
                  return update(arr, { $splice: [[index, 1]] });
                } else {
                  return arr;
                }
              },
            }),
        },
      });
    case "toggleModal": {
      if (state[action.prop].visible) {
        return update(state, { [action.prop]: { $set: { visible: false } } });
      } else return state;
    }
    case "receiveMessage": {
      return update(state, {
        textModal: { visible: { $set: false } },
      });
    }
    case "mergeInnerProp":
      return update(state, { [action.prop]: { $merge: action.payload } });
    case "setWithArray": {
      return update(
        state,
        unflatten({
          [`${action.prop.join(".")}.$set`]: action.value,
        })
      );
    }
    case "setWithUpdateObj":
      return update(state, { [action.prop]: action.updateObj });
    case "setObjArrayProp": {
      const updateObj = {
        $apply: (arr) => {
          const index = arr.findIndex(
            (x) => x[action.idProp] === action.idValue
          );

          if (index !== -1) {
            return update(arr, {
              [index]: { [action.innerProp]: { $set: action.value } },
            });
          } else {
            return arr;
          }
        },
      };
      return update(
        state,
        Array.isArray(action.prop)
          ? unflatten({
              [`${action.prop.join(".")}.$apply`]: (x) =>
                update(x || [], updateObj),
            })
          : {
              [action.prop]: {
                $apply: (x) => update(x || [], updateObj),
              },
            }
      );
    }
    case "setFormParam": {
      return update(state, {
        formRoute: {
          params: { [action.prop]: { $set: action.value } },
        },
      });
    }
    case "setFormRoute": {
      return update(state, {
        formRoute: {
          route: { $set: action.route },
          params: { $set: action.params || {} },
          history: {
            $push: [
              {
                route: state.formRoute.route,
                params: {
                  ...(state.formRoute.params || {}),
                  viewableItems: action.viewableItems,
                  scrollPosition: action.scrollPosition,
                },
              },
            ],
          },
        },
      });
    }
    case "formGoBack": {
      return update(state, {
        formRoute: {
          $apply: (x) => {
            if (state.formRoute.history.length > 0) {
              const amountToGoBack = action.amount ?? 1;
              return update(x, {
                route: {
                  $set: state.formRoute.history[
                    state.formRoute.history.length - amountToGoBack
                  ]?.route,
                },
                params: {
                  $set: state.formRoute.history[
                    state.formRoute.history.length - amountToGoBack
                  ]?.params,
                },
                history: {
                  $splice: [
                    [
                      state.formRoute.history.length - amountToGoBack,
                      amountToGoBack,
                    ],
                  ],
                },
              });
            } else {
              return { route: null, history: [], params: {} };
            }
          },
        },
      });
    }
    case "replaceTab": {
      return update(state, {
        formRoute: {
          route: { $set: action.route },
          params: { $set: action.params || {} },
        },
      });
    }
    case "setUploadProgress":
      if (state.uploadProgress) {
        return update(state, {
          uploadProgress: {
            itemBeingUploaded: { $set: action.itemBeingUploaded },
            files: {
              [action.fileName]: {
                $apply: (x) =>
                  update(x || {}, {
                    value: { $set: action.value },
                    fileName: { $set: action.fileName },
                    error: { $set: action.error },
                  }),
              },
            },
          },
        });
      } else {
        return state;
      }
    case "increaseProgress":
      return update(state, {
        uploadProgress: {
          files: {
            [action.fileName]: {
              $apply: (x) =>
                update(x || {}, {
                  value: { $apply: (x) => x + action.value },
                }),
            },
          },
        },
      });
    case "paginationResult": {
      return update(state, {
        pageNumber: {
          $apply: (x) =>
            action.value.override ? 2 : action.value.updatePageNum ? x + 1 : x,
        },
        totalResults: { $apply: (x) => action.value.totalResults || x },
        hasMore: {
          $set:
            (action.value.override ? 0 : state.data.length) +
              action.value.data.length !==
            (action.value.totalResults || state.totalResults),
        },
        data: {
          $apply: (_data) =>
            action.value.override
              ? action.value.data
              : action.value.prependData
              ? [...action.value.data, ..._data]
              : [..._data, ...action.value.data],
        },
        loading: { $set: false },
        refreshing: { $set: false },
      });
    }
    case "toggleAlert":
      return update(state, { alert: { visible: { $apply: (x) => !x } } });
    case "reset":
      return action.value;
    default:
      return state;
  }
};
function componentReducerPrimer(state, action) {
  const split = typeof action.prop === "string" ? action.prop.split("/") : [];
  if (split.length > 1) {
    return update(state, {
      [split[0]]: {
        $auto: {
          $apply: (x) =>
            componentReducerLogic(x, { ...action, prop: split[1] }),
        },
      },
    });
  } else {
    return componentReducerLogic(state, action);
  }
}
export default function componentReducer(state = {}, action) {
  if (Array.isArray(action)) {
    let newState = state;
    action.forEach((x) => {
      newState = componentReducerPrimer(newState, x);
    });
    return newState;
  } else {
    return componentReducerPrimer(state, action);
  }
}

import React, { useRef } from "react";
import { View, ActivityIndicator, Text, Pressable } from "react-native";
import { setNativeProps } from "../lib/functions";
import { ThemeContext } from "../theming/theme-context";
import Icon from "./Icon";

const styles = {
  icon: { position: "absolute", left: 0 },
  endIcon: { position: "absolute", right: 4 },
  loadingSpinner: { padding: 2 },
};
function StretchButton(props) {
  const {
    loading,
    iconWhenLoading,
    disabled,
    noDisabledStyle,
    title,
    onPress,
    icon,
    startIcon,
    endIcon,
    topIcon,
  } = props;
  const { theme, colors } = React.useContext(ThemeContext);
  const backgroundColor = props.backgroundColor ?? colors.accent;
  const color = props.color ?? colors.textOnAccent;
  const ref = useRef(null);
  const setStyles = (hover) => () => {
    if (disabled) return;
    setNativeProps(ref.current, {
      backgroundColor:
        !props.backgroundColor || props.backgroundColor === colors.accent
          ? hover
            ? colors.activeAccent
            : colors.accent
          : hover
          ? backgroundColor + "80"
          : backgroundColor,
    });
  };

  return (
    <Pressable
      ref={ref}
      disabled={!!(loading || disabled)}
      type="solid"
      onMouseEnter={setStyles(true)}
      onMouseLeave={setStyles()}
      style={[
        props.buttonStyle,
        theme.stretchButtonContainer,
        props.rounded ? theme.roundedButton : theme.stretchButtonStyle,
        {
          opacity: disabled && !noDisabledStyle ? 0.3 : 1,
          backgroundColor: backgroundColor,
          // flex direction breaks list picker category tabs on mobile for some reason
          flexDirection: props.noFlexDirection
            ? null
            : topIcon
            ? "column"
            : "row",
          height: "100%",
        },
      ]}
      onPress={onPress}
    >
      {startIcon ? (
        <View style={styles.icon}>
          {loading ? (
            <ActivityIndicator style={styles.loadingSpinner} color={color} />
          ) : (
            <Icon name={startIcon} size={24} color={color} />
          )}
        </View>
      ) : null}
      {topIcon ? (
        <View>
          {loading ? (
            <ActivityIndicator style={styles.loadingSpinner} color={color} />
          ) : (
            <Icon name={topIcon} size={22} color={color} />
          )}
        </View>
      ) : null}
      {icon ? (
        <View>
          <Icon name={icon} size={24} color={color} />
          {loading ? (
            <ActivityIndicator style={styles.loadingSpinner} color={color} />
          ) : null}
        </View>
      ) : (
        <Text
          numberOfLines={5}
          style={[
            props.buttonTextStyle ??
              (topIcon ? theme.buttonCaption : theme.buttonTitle),
            {
              width: "100%",
              paddingLeft: startIcon ? 20 : 0,
              paddingRight: endIcon ? 20 : 0,
              textAlign: "center",
              color: color,
            },
          ]}
        >
          {title}
        </Text>
      )}
      {endIcon ||
      (iconWhenLoading && loading && !startIcon && !icon && !topIcon) ? (
        <View style={styles.endIcon}>
          {loading ? (
            <ActivityIndicator style={styles.loadingSpinner} color={color} />
          ) : (
            <Icon name={endIcon} size={props.endIconSize ?? 24} color={color} />
          )}
        </View>
      ) : null}
    </Pressable>
  );
}
export default StretchButton;

import React, {
  useRef,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Platform, BackHandler, View } from "react-native";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { ThemeContext } from "../theming/theme-context";
import {
  moveFile,
  dirs,
  fileExists,
  unlinkFile,
  copyFile,
  saveFile,
  wrap,
  fileUpload,
  readFile,
  downloadResource,
} from "../lib/fileOperations";
import {
  showToast,
  fetchNetInfo,
  goBack,
  handleCameraOpen,
  handlePictureTaken,
  fullHp,
  fullWp,
  getLocation,
  getAppVersion,
} from "../lib/helperFns";

import {
  parseToFileName,
  parseUri,
  getFileUris,
  getImageSize,
  checkAtchDownloadNeed,
  parseAtchId,
  addToIndex,
  subFromIndex,
  base64ToBlob,
  errorReport,
  arrayToObjectWithProp,
  parseLocation,
  generateLocalAtchId,
} from "../lib/functions";

import { getWithToken, putWithToken, apiRequestWithToken } from "../lib/api";

import ProgressInfo from "../components/ProgressInfo";
import PreviewModal from "../components/PreviewModal";
import AttachmentInputs from "../components/AttachmentInputs";

import { validateFileName } from "../lib/validityCheckers";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ADD_ATTACHMENT,
  removeAttachments,
  ADD_OWNER_TO_TMP_ATTACHMENT,
  REMOVE_OWNER_FROM_TMP_ATTACHMENT,
  SET_TMP_ATTACHMENT_PROP,
  SET_TMP_ATTACHMENT,
  REMOVE_TMP_ATTACHMENTS,
  CLEAR_TMP_ATTACHMENTS,
  PUSH_TO_TMP_ATTACHMENTS,
  REMOVE_TMP_ATTACHMENT,
} from "../actions/OptionsActions";
import {
  ADD_TO_OBJECT_ARR,
  REMOVE_OBJECT_ARR_ITEM,
} from "../actions/DocsActions";

import {
  SET_COMPANY_SETTINGS_PROP,
  SET_GPS_LOCATION,
} from "../actions/ProfileActions";

import componentReducer from "../reducers/ComponentReducer";

import Whiteboard from "../../web/src/components/Whiteboard";
// import Whiteboard from "./Whiteboard";

const OS = Platform.OS;
// TODO modify:
// delete?
// TODO make Whiteboard component more generic
// pass in background image in props
// pass a prop if need to combine images
// pass in background image dimensions in props

const svgElemToObj = (x) => ({
  type: x.type, // string
  width: x.width, // float
  height: x.height, // float
  x: x.x, // float
  y: x.y, // float
  x2: x.x2, // float
  y2: x.y2, // float
  rotation: x.rotation, // float
  stroke: x.stroke, // string
  fill: x.fill, // string
  fontSize: x.fontSize, // int
  text: x.text, // string
  scale: x.scale, // int
  strokeWidth: x.strokeWidth, // float
  uiScale: x.uiScale, // float
});

function unique(arr) {
  var u = {},
    a = [];
  for (var i = 0, l = arr.length; i < l; ++i) {
    if (!Object.prototype.hasOwnProperty.call(u, arr[i]?.tmpName)) {
      a.push(arr[i]);
      u[arr[i]?.tmpName] = 1;
    }
  }
  return a;
}

function reducer(state = {}, action) {
  switch (action.type) {
    case "modify":
      return { ...state, [action.key]: action.value };
    default:
      throw new Error();
  }
}

function removeFromDoc(docId, attachmentId) {
  apiRequestWithToken({ docId, attachmentId }, "/attachments/removeFromDoc");
}
const initialState = {
  previewModal: { visible: false },
};
const initialUploadProgress = {
  msg: "",
  progress: 0,
};
function AddAttachmentsScreen(props) {
  const { t } = useTranslation();
  const { theme, colors, setBottomSheet, closeBottomSheet } =
    React.useContext(ThemeContext);
  const [state, dispatchState] = useReducer(componentReducer, initialState);
  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState(0);
  const [fullscreenAtch, setFullscreenAtch] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(initialUploadProgress);
  const [fetchedAttachments, setAttachmentsFetched] = useReducer(reducer, {});
  const [editingAtch, setEditingAtch] = useState({ atch: null, base64: null });
  const [saving, setSaving] = useState(false);
  const [mapIsFullScreen, setMapIsFullScreen] = useState(false);
  const mapState = useRef();
  const tmpAttachmentsHandled = useRef(false);
  const saveStarted = useRef(false);

  const _pageW = OS === "web" ? props.pageW : fullWp(100);
  const _pageH =
    OS === "web" ? props.pageH : fullHp(100) - (props.isConnected ? 40 : 76);
  const _modifyId = props.modifyId;
  const tmpAttachments = React.useMemo(() => {
    return _modifyId
      ? [props.attachments[_modifyId]]
      : props.tmpAttachments ?? props.tmpOptionsAttachments;
  }, [_modifyId, props.tmpAttachments, props.tmpOptionsAttachments]);

  // const tmpAttachments = _modifyId
  //   ? [props.attachments[_modifyId]]
  //   : props.tmpAttachments ?? props.tmpOptionsAttachments;

  const tmpAttachmentsLength = tmpAttachments ? tmpAttachments.length : 0;

  const _goBack = (params, amount) => {
    if (props.goBack) {
      props.goBack(
        props.navigation,
        amount ?? 1 //(props.fromDocumentScanner ? 2 : 1)
      );
    } else {
      goBack(props.navigation);
    }
  };

  const getAtchObj = (_index) => {
    return (
      (state[`${_index}_inputs`]
        ? {
            ...tmpAttachments[_index],
            ...arrayToObjectWithProp(state[`${_index}_inputs`], "key", "value"),
          }
        : tmpAttachments[_index]) ?? {}
    );
  };

  const setPreviewModal = (value) => {
    dispatchState({ type: "set", prop: "previewModal", value });
  };

  const openPreviewModal = useCallback((atch) => {
    setPreviewModal({
      atch: atch,
      visible: true,
    });
  }, []);

  const closePreviewModal = () => {
    dispatchState({
      type: "set",
      prop: "previewModal",
      value: initialState.previewModal,
    });
  };

  const setAttachmentsLoadedWithParams = useCallback(
    (params) => {
      setAttachmentsFetched({ type: "modify", ...params });
    },
    [setAttachmentsFetched]
  );

  const checkIfAttachmentsExists = (attachmentsToAdd) => {
    let error = false;
    let newErrors = {};

    const attachmentsEntries = Object.values(props.attachments);

    for (let i = 0; i < attachmentsEntries.length; i++) {
      const atch = attachmentsEntries[i];

      for (let j = 0; j < attachmentsToAdd.length; j++) {
        const atchToAdd = attachmentsToAdd[j];
        if (
          atch?.id &&
          atch.id.startsWith("local") &&
          atch.id !== atchToAdd.id &&
          atch.name === atchToAdd.name &&
          atch.ext === atchToAdd.ext
        ) {
          error = true;
          newErrors[j] = {
            fileNameErr: t("fileWithSameNameExists"),
          };
        }
      }
    }

    if (error) return newErrors;
    return error;
  };

  const validateAttachmentFields = async (_attachments) => {
    const netState = await fetchNetInfo();

    // TODO handle offline atch props editing
    if (_modifyId && !netState.isConnected) {
      showToast(t("checkInternetConnection"));
      return true;
    }

    const existErrors = checkIfAttachmentsExists(_attachments);

    if (existErrors) {
      for (var k in existErrors) {
        setIndex(parseInt(k));
        break;
      }
      setErrors(existErrors);
      return true;
    } else {
      let error = false;
      let newErrors = { ...errors };

      for (let j = 0; j < _attachments.length; j++) {
        const _attachment = getAtchObj(j);

        if (_attachment.tmpName && !_attachment.name) {
          newErrors[j] = { fileNameErr: t("inputFilename") };
          error = true;
        }
        const validation = validateFileName(_attachment.name);

        if (validation === "invalidChars") {
          newErrors[j] = {
            fileNameErr: t("filenameFormatInfo") + "\n",
          };
          error = true;
        }

        if (
          props.requiredProps &&
          props.requiredProps.includes("desc") &&
          !_attachment.desc
        ) {
          if (!newErrors[j]) newErrors[j] = {};
          newErrors[j].descErr = t("inputDesc");
          error = true;
        }
      }

      if (error) {
        for (var e in newErrors) {
          setIndex(parseInt(e));
          break;
        }
        setErrors(newErrors);
      }
      return error;
    }
  };

  const addAttachmentsScreenErrorReport = (error, dontShowToast, fnNum) => {
    errorReport({
      error,
      errorInScreen: "AddAttachmentsScreen",
      errorInFn: fnNum,
      dontShowToast,
    });
  };

  const handleAddAttachmentScreenBackPress = useCallback(async () => {
    if (mapIsFullScreen) {
      if (Platform.OS === "web") {
        setMapIsFullScreen(false);
      } else {
        closeBottomSheet();
      }
    } else if (fullscreenAtch.id) {
      setFullscreenAtch({ id: null, fetching: false });
      return true;
    } else if (editingAtch.atch) {
      setEditingAtch({});
    } else {
      await onAtchAddCancel();
      return true;
    }
  }, [editingAtch, fullscreenAtch, mapIsFullScreen]);

  const handleNewTmpAttachments = async (attachmentsToAdd) => {
    if (!_modifyId) {
      getLocation(
        props.company?.settings?.mapboxAccessToken,
        props.SET_COMPANY_SETTINGS_PROP,
        props.gpsLocation,
        props.SET_GPS_LOCATION,
        null,
        async (streetValue, locationRes) => {
          try {
            let mergeValues = {};

            if (locationRes) {
              mergeValues = {
                // we can use the value here for street since we pass the street parsing object to GET_LOCATION
                street: streetValue,
                zipcode: parseLocation(locationRes, {
                  types: ["postal_code"],
                  separators: [" "],
                }),
                city: parseLocation(locationRes, {
                  types: ["locality"],
                  separators: [" "],
                }),
                country: parseLocation(locationRes, {
                  types: ["country"],
                  separators: [" "],
                }),
                lat: parseLocation(locationRes, {
                  types: ["lat"],
                  separators: [" "],
                }),
                lng: parseLocation(locationRes, {
                  types: ["lng"],
                  separators: [" "],
                }),
              };
            }

            const _attachments = unique(
              tmpAttachments.concat(attachmentsToAdd || [])
            );

            (props.PUSH_TO_TMP_ATTACHMENTSfn || props.PUSH_TO_TMP_ATTACHMENTS)({
              attachments: attachmentsToAdd,
            });

            for (let index = 0; index < _attachments.length; index++) {
              const item = _attachments[index];

              if (item && item.type.startsWith("image/")) {
                if (item.dimensions && !fetchedAttachments[item.tmpName]) {
                  setAttachmentsLoadedWithParams({
                    key: item.tmpName,
                    value: true,
                  });
                } else if (!item.dimensions) {
                  const sizeRes = await getImageSize(
                    OS == "web"
                      ? `data:${item?.type};base64,${item?.uri}`
                      : item.uri
                  ).catch((error) => {
                    errorReport({
                      error,
                      errorInScreen: "AddAttachmentsScreen",
                      errorInFn: "getImageSize",
                      errorParams: {
                        item,
                      },
                      dontShowToast: true,
                    });
                    setAttachmentsLoadedWithParams({
                      key: item.tmpName,
                      value: true,
                    });
                  });
                  if (sizeRes.width) {
                    const dimensions = sizeRes;
                    mergeValues.dimensions = dimensions;
                    setAttachmentsLoadedWithParams({
                      key: item.tmpName,
                      value: true,
                    });
                  } else {
                    addAttachmentsScreenErrorReport(
                      "Error getting image dimensions: " +
                        JSON.stringify(sizeRes),
                      false,
                      1
                    );
                  }
                }
              } else {
                setAttachmentsLoadedWithParams({
                  key: item.tmpName,
                  value: true,
                });
              }

              (
                props.SET_TMP_ATTACHMENT_PROPfn || props.SET_TMP_ATTACHMENT_PROP
              )({
                fullObj: item,
                merge: true,
                value: mergeValues,
              });
            }

            tmpAttachmentsHandled.current = true;
          } catch {
            /* empty */
            tmpAttachmentsHandled.current = true;
          }
        },
        {
          types: ["route", "street_number"],
          separators: [" "],
        }
      );
    }
  };

  useEffect(() => {
    // save the attachments straight away after they've been modified after mount
    if (
      tmpAttachmentsHandled.current &&
      !saveStarted.current &&
      !props.modifyId &&
      props.saveOnMount
    ) {
      saveStarted.current = true;
      handleSave();
    }
  }, [props.tmpOptionsAttachments]);

  const handleNavAtchChange = async (_navAttachments) => {
    if (!props.customToken) {
      const settingsRes = await getWithToken(
        "/company/settings?keys=mapbox-access-token"
      );
      if (settingsRes.status === 200)
        props.SET_COMPANY_SETTINGS_PROP({
          prop: "mapboxAccessToken",
          value: settingsRes.data["mapbox-access-token"],
        });
    }
    await handleNewTmpAttachments(_navAttachments || []);
    if (props.modifyId || !props.saveOnMount) {
      setIndex(0);
    }
  };

  const handleSave = () => {
    setSaving(true);
  };

  const handleSaveAtch = async () => {
    try {
      // handle modifying already added attachment
      if (_modifyId) {
        if (props.offlineAtch) {
          if (props.onEditAttachment) props.onEditAttachment(getAtchObj(index));
          props.ADD_ATTACHMENT({
            attachment: getAtchObj(index),
          });
          if (props.docId) {
            const newOptionsAtch = getAtchObj(index);
            saveAtchToDoc(getAtchObj(index), newOptionsAtch);
          }
          setSaving(false);
        } else {
          putWithToken(getAtchObj(index), "/attachments/update").then((res) => {
            if (res.status === 200) {
              if (props.onEditAttachment) props.onEditAttachment(res.data);
              props.ADD_ATTACHMENT({ attachment: res.data });
              if (props.docId) {
                const newOptionsAtch = res.data.offlineAtch
                  ? { attachment: { ...res.data, os: OS } }
                  : { attachment: res.data };
                saveAtchToDoc(res.data, newOptionsAtch);
              }
              showToast(t("saved"), 2000, "green");
              setSaving(false);
            } else {
              showToast(t("unhandledError"));
            }
          });
        }
      }
      // handle saving new attachment
      else {
        let validationError;
        if (!(!props.modifyId && props.saveOnMount)) {
          validationError = await validateAttachmentFields(tmpAttachments);
        }
        let failedToSave = [];
        if (validationError) {
          setSaving(false);
        } else {
          for (let j = 0; j < tmpAttachments.length; j++) {
            if (!props.offlineAtch) {
              setUploadProgress({
                msg: "savingAttachment",
                itemBeingUploaded: j + 1,
                progress: 0,
              });
            }
            const res = await handleAddAtchScreenSave(j);

            if (res) {
              failedToSave.push(res);
              addAttachmentsScreenErrorReport(res, true, 2);
            }
          }

          if (failedToSave.length > 0) {
            showToast(
              `${t("attachmentsAddFailed")}:\n${failedToSave.reduce(
                (prev, cur) => prev + cur.name + "\n",
                ""
              )}`
            );
            try {
              (props.REMOVE_TMP_ATTACHMENTSfn || props.REMOVE_TMP_ATTACHMENTS)({
                atchToRemove: tmpAttachments.filter(
                  (x) =>
                    !failedToSave.some(
                      (y) => x.name === y.name && x.ext === y.ext
                    )
                ),
              });
            } catch (error) {
              errorReport({
                error,
                errorInFn: "REMOVE_TMP_ATTACHMENTS",
                errorInScreen: "AddAttachmentsScreen",
              });
            }
            setUploadProgress(initialUploadProgress);
            setSaving(false);
          } else if (props.onSave) {
            // TODO rework whiteboard atch add to work with this
            await clearScannerCache();
            (props.CLEAR_TMP_ATTACHMENTSfn || props.CLEAR_TMP_ATTACHMENTS)();
            _goBack(
              props.screenToGoBackTo.screen,
              props.navigation,
              props.screenToGoBackTo.params
            );
          } else {
            await clearScannerCache();
            (props.CLEAR_TMP_ATTACHMENTSfn || props.CLEAR_TMP_ATTACHMENTS)();
            _goBack(
              props.screenToGoBackTo.screen,
              props.navigation,
              props.screenToGoBackTo.params
            );
          }
        }
      }
    } catch (error) {
      errorReport({
        error: error,
        errorInFn: "handleSaveAtch",
        errorInScreen: "AddAttachmentsScreen",
      });
      setUploadProgress(initialUploadProgress);
      setSaving(false);
    }
  };

  const handleAddAtchScreenSave = async (indexToUse) => {
    try {
      const atch = tmpAttachments[indexToUse];
      const modifiedAtch = getAtchObj(indexToUse) || atch;

      let relations = [];
      let attachedToDocs;
      if (props.relations) {
        relations = [...props.relations];
      }
      if (props.docId) {
        attachedToDocs = [
          {
            docId: props.docId,
            required: true,
          },
        ];
      }
      let newAtch = {
        uri: atch.uri,
        dimensions: atch.dimensions,
        name: parseToFileName(modifiedAtch.name),
        ext: atch.ext,
        desc: modifiedAtch.desc,
        type: atch.type,
        confidential: modifiedAtch.confidential ?? false,
        global: modifiedAtch.global ?? false,
        hideInGallery: props.hideInGallery,
        ...props.nonEditableAtchProps,
        originalUri: atch.originalUri,
        svgElems: atch.svgElems,
        svgStrokes: atch.svgStrokes,
        hidePreview: props.hidePreview,
        relations: relations,
        street: atch.street,
        zipcode: atch.zipcode,
        city: atch.city,
        country: atch.country,
        lat: atch.lat,
        lng: atch.lng,
        attachedToDocs,
      };

      if (!newAtch.global) {
        newAtch.owners = Array.isArray(modifiedAtch.owners)
          ? modifiedAtch.owners
          : [];
        if (!newAtch.owners.includes(props.userId))
          newAtch.owners.push(props.userId);
      }

      const savedRes = await _saveAtchToDb(indexToUse, newAtch);

      return savedRes;
    } catch (error) {
      addAttachmentsScreenErrorReport(error, false, "handleAddAtchScreenSave");
      return error;
    }
  };

  const clearScannerCache = () => {
    const cache = "Camera"; //"RNRectangleScanner";
    return fileExists(`${dirs.CacheDir}/${cache}`)
      .then((exist) => {
        if (exist) {
          return unlinkFile(`${dirs.CacheDir}/${cache}`)
            .then(() => true)
            .catch((err) => {
              addAttachmentsScreenErrorReport(
                "clearScannerCache" + ": " + err,
                true,
                4
              );
              return true;
            });
        } else return true;
      })
      .catch((err) => {
        addAttachmentsScreenErrorReport(err, false, 5);
      });
  };

  const uploadFile = async (
    url,
    itemIndex,
    _fileName,
    id,
    uri,
    uploadMsg = t("savingAttachment"),
    uriIsBase64
  ) => {
    let body;
    if (OS === "web") {
      body = new FormData();
      if (uri) body.append("file", base64ToBlob(uri));
      body.set("id", id);
    } else {
      body = [
        {
          name: "id",
          data: id,
        },
      ];
      if (uri) {
        body.push({
          name: "file",
          filename: _fileName,
          data: uriIsBase64 ? uri : wrap(await parseUri(uri)),
        });
      }
    }

    return fileUpload(url, props.customToken, body, ({ loaded, total }) => {
      setUploadProgress({
        msg: uploadMsg || "savingAttachment",
        itemBeingUploaded: itemIndex + 1,
        progress: loaded / total,
      });
    });
  };

  const saveAttachmentToFiles = async (itemIndex, item, name, id, error) => {
    try {
      // TODO save photo to photo reel too so it is safe in phone memory and can be reused if online connection fails
      const _fileName = `${item.name}.${item.ext}`;
      if (OS === "web") {
        await saveFile(`${dirs.DocumentDir}/${name}.${item.ext}`, item.uri);
      } else {
        await moveFile(
          await parseUri(item.uri),
          `${dirs.DocumentDir}/${name}.${item.ext}`
        );
      }
      if (
        item.originalUri &&
        (item.svgElems.length > 0 || item.svgStrokes.length > 0)
      ) {
        if (!error && !props.offlineAtch) {
          await uploadFile(
            "/attachments/original",
            itemIndex,
            _fileName,
            id,
            item.originalUri
          );
        }
        if (OS === "web") {
          await saveFile(
            `${dirs.DocumentDir}/original_${name}.${item.ext}`,
            item.originalUri
          );
        } else {
          await moveFile(
            await parseUri(item.originalUri),
            `${dirs.DocumentDir}/original_${name}.${item.ext}`
          );
        }
      }
    } catch (error) {
      addAttachmentsScreenErrorReport(error, false, "saveAttachmentToFiles");
      return error;
    }
  };

  const _saveAtchToDb = async (itemIndex, item) => {
    try {
      const _fileName = `${item.name}.${item.ext}`;
      if (props.offlineAtch) {
        await saveAttachmentToFiles(itemIndex, item, item.name);
        return saveAtchToReduxAndDoc(itemIndex, {
          ...item,
          id: generateLocalAtchId(props.attachments, props.userId),
          offlineAtch: true,
          uri: undefined,
          originalUri: undefined,
        });
      } else {
        const netState = await fetchNetInfo();

        if (!netState.isConnected) {
          showToast(t("locallySavedAttachmentSyncInfo"), 7000, "accent");
          await saveAttachmentToFiles(itemIndex, item, item.name);
          return saveAtchToReduxAndDoc(itemIndex, {
            ...item,
            id: generateLocalAtchId(props.attachments, props.userId),
            saveFailed: true,
            uri: undefined,
            originalUri: undefined,
          });
        }

        const _handleErr = async (err) => {
          try {
            const newAtch = {
              ...item,
              id: generateLocalAtchId(props.attachments, props.userId),
              saveFailed: true,
              uri: undefined,
              originalUri: undefined,
            };
            showToast(t("locallySavedAttachmentErrorSyncInfo"), 7000);
            addAttachmentsScreenErrorReport(err, true, 6);
            await saveAttachmentToFiles(
              itemIndex,
              item,
              newAtch.name,
              null,
              true
            );
            return saveAtchToReduxAndDoc(itemIndex, newAtch);
          } catch (error) {
            addAttachmentsScreenErrorReport(
              error,
              false,
              "_saveAtchToDb _handleErr"
            );
            return error;
          }
        };

        const _handleResp = async (res) => {
          try {
            if (
              (OS === "web" && res.status === 200) ||
              (OS !== "web" && res.respInfo.status === 200)
            ) {
              const newAtch =
                typeof res.data === "string" ? JSON.parse(res.data) : res.data;
              await saveAttachmentToFiles(
                itemIndex,
                item,
                parseAtchId(newAtch),
                newAtch.id
              );
              newAtch.uri = undefined;
              return saveAtchToReduxAndDoc(itemIndex, newAtch);
            } else {
              _handleErr(res);
            }
          } catch (error) {
            addAttachmentsScreenErrorReport(
              error,
              false,
              "_saveAtchToDb _handleResp"
            );
            return error;
          }
        };

        const json = JSON.stringify({
          ...item,
          uri: undefined,
          originalUri: undefined,
          version: getAppVersion(),
          os: OS,
        });

        let body;

        if (OS === "web") {
          body = new FormData();
          body.append("file", base64ToBlob(item.uri));
          body.set("json", json);
        } else {
          body = [
            {
              name: "file",
              filename: _fileName,
              data: wrap(await parseUri(item.uri)),
            },
            {
              name: "json",
              data: json,
            },
          ];
        }

        return fileUpload(
          "/attachments",
          props.customToken,
          body,
          ({ loaded, total }) => {
            setUploadProgress({
              msg: "savingAttachment",
              itemBeingUploaded: itemIndex + 1,
              progress: loaded / total,
            });
          }
        )
          .then(_handleResp)
          .catch(_handleErr);
      }
    } catch (error) {
      addAttachmentsScreenErrorReport(error, false, "_saveAtchToDb");
      return error;
    }
  };

  const saveAtchToDoc = (newAtch, newOptionsAtch) => {
    try {
      const newValueAtch = {
        id: newAtch.id,
        desc: newAtch.desc,
        name: newAtch.name,
        offlineAtch: newAtch.offlineAtch,
        hidePreview: newAtch.hidePreview,
        saveFailed: newAtch.saveFailed,
      };
      if (props.onSave) {
        props.onSave(newValueAtch, props.valueKey, newAtch);
      } else {
        if (props.valueKey) {
          const _value = props.values[props.valueKey];
          // replaceObjectArray adds deleted status to docs timestamps
          if (
            !_modifyId &&
            props.single &&
            props.docId &&
            Array.isArray(_value) &&
            _value.length !== 0 &&
            !_value[0].offlineAtch
          ) {
            removeFromDoc(props.docId, _value[0].id);
          }
          if (props.single) {
            props.replaceObjectArrItem({
              type: "Atch",
              docId: props.docId,
              valueKey: `${props.valueKey}`,
              value: newValueAtch,
              replaceSameObj: !!_modifyId,
              oldVal: _value?.[0],
              idProp: "id",
              sortProp: "name",
              state: newOptionsAtch,
            });
          } else {
            props.addToObjectArr({
              type: "Atch",
              docId: props.docId,
              valueKey: `${props.valueKey}`,
              value: newValueAtch,
              idProp: "id",
              sortProp: "name",
              state: newOptionsAtch,
            });
          }
        }
      }
    } catch (error) {
      addAttachmentsScreenErrorReport(error, false, "saveAtchToDoc");
      return error;
    }
  };

  const saveAtchToReduxAndDoc = (itemIndex, newAtch) => {
    try {
      setUploadProgress({
        msg: t("savingToDoc"),
        itemBeingUploaded: itemIndex + 1,
        progress: 1,
      });
      const newOptionsAtch = newAtch.offlineAtch
        ? { attachment: { ...newAtch, os: OS } }
        : { attachment: newAtch };
      props.ADD_ATTACHMENT(newOptionsAtch);

      saveAtchToDoc(newAtch, newOptionsAtch);
    } catch (error) {
      addAttachmentsScreenErrorReport(error, false, "saveAtchToReduxAndDoc");
      return error;
    }
  };

  const onAtchAddCancel = async () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      await deleteMovedNewImgs();
      if (props.onSave) {
        (props.REMOVE_TMP_ATTACHMENTSfn || props.REMOVE_TMP_ATTACHMENTS)({
          atchToRemove: tmpAttachments,
        });
      } else {
        await clearScannerCache();
        (props.CLEAR_TMP_ATTACHMENTSfn || props.CLEAR_TMP_ATTACHMENTS)();
      }
      _goBack(
        props.screenToGoBackTo.screen,
        props.navigation,
        props.screenToGoBackTo.params
      );
    }
  };

  const deleteMovedNewImgs = async () => {
    for (let index = 0; index < tmpAttachments.length; index++) {
      const tmpAtch = tmpAttachments[index];
      if (tmpAtch.newImg) {
        const uri = tmpAtch.uri.startsWith("file://")
          ? tmpAtch.uri.substring(8)
          : tmpAtch.uri;

        const exist = await fileExists(uri);
        if (exist) {
          await unlinkFile(uri);
        }
      }
    }
  };

  // TODO delete from state.modifiedAttachments
  const deleteImg = useCallback(
    (uri, atchIndex) => {
      if (tmpAttachmentsLength === 1 && tmpAttachments[0].uri === uri) {
        (props.REMOVE_TMP_ATTACHMENTfn || props.REMOVE_TMP_ATTACHMENT)(uri);
        _goBack(
          props.screenToGoBackTo.screen,
          props.navigation,
          props.screenToGoBackTo.params
        );
      } else {
        (props.REMOVE_TMP_ATTACHMENTfn || props.REMOVE_TMP_ATTACHMENT)(uri);
        if (atchIndex > 0) {
          setIndex((_index) => _index - 1);
        }
      }
    },
    [
      tmpAttachmentsLength,
      tmpAttachments,
      props.screenToGoBackTo,
      props.navigation,
    ]
  );

  /**
   * Saves edited image/pdf to local file system (base64 to indexed db in web) and s3 through server.
   * Also saves the svg strokes and elements so they can be edited later.
   * If user has no network connection, file is saved locally and editedOffline property is added
   * to the attachment object.
   *
   * Handles attachments with saveFailed property (attachment was never successfully saved to database/s3)
   * by uploading the file and object
   *
   * TODO fetch most recent attachment object and compare fileLastModified to local fileLastModified
   * TODO if fileLastModified from server is newer show a dialog to user so they can decide whether
   * TODO to replace the file in s3 with current
   * @param {Object} Object
   * @returns
   */
  const onWhiteboardSave = async ({
    newUri,
    strokes,
    svgElems,
    attachmentSvgPins,
  }) => {
    // newUri is undefined if user removed all elems and strokes
    try {
      const netState = await fetchNetInfo();
      if (_modifyId) {
        const atch = props.attachments[_modifyId];

        let atchIsLocal, fileName, fileUri, originalFileUri;
        const fileUris = getFileUris(atch);
        atchIsLocal = fileUris.atchIsLocal;
        fileName = fileUris.fileName;
        fileUri = fileUris.fileUri;
        originalFileUri = fileUris.originalFileUri;

        let _newUri;

        if (newUri) {
          if (OS === "web") {
            _newUri = newUri;
          } else {
            _newUri = newUri.substring(8);
          }
        } else {
          if (OS === "web") {
            _newUri = await readFile(originalFileUri);
          } else {
            _newUri = originalFileUri;
          }
        }

        // TODO handle offline save, set localFileModified or something which we
        // TODO can use in sync to update the file
        if (netState.isConnected && !atchIsLocal) {
          try {
            const res = await uploadFile(
              "/attachments/updateFile",
              1,
              fileName,
              atch.id,
              _newUri
            );
            if (
              OS === "web" ? res?.status !== 200 : res?.respInfo.status !== 200
            ) {
              setUploadProgress(initialUploadProgress);
              showToast(t("unhandledError"));
              return;
            }
          } catch (error) {
            errorReport({
              error,
              errorInFn: "onWhiteboardSave updateFile",
              errorInScreen: "AddAttachmentsScreen",
              errorParams: {
                id: atch.id,
              },
            });
            setUploadProgress(initialUploadProgress);
            return;
          }
        }

        try {
          if (newUri) {
            if (OS === "web") {
              await saveFile(fileUri, _newUri);
            } else {
              try {
                await unlinkFile(fileUri);
              } catch {
                /* empty */
              }
              await moveFile(_newUri, fileUri);
            }
          } else {
            if (OS === "web") {
              await saveFile(fileUri, _newUri);
            } else {
              try {
                await unlinkFile(fileUri);
              } catch {
                /* empty */
              }
              await copyFile(_newUri, fileUri);
            }
          }
        } catch (error) {
          errorReport({
            error,
            errorInFn: "onWhiteboardSave after updateFile",
            errorInScreen: "AddAttachmentsScreen",
            errorParams: {
              id: atch.id,
            },
          });
        }

        const _atch = {
          ...atch,
          svgStrokes: strokes,
          svgElems: (svgElems ? svgElems.map(svgElemToObj) : []).concat(
            attachmentSvgPins ? attachmentSvgPins.map(svgElemToObj) : []
          ),
        };

        const updateAtchObj = (newAtch) => {
          const _newAtch = newAtch ?? {
            ..._atch,
            localFileNotSynced: !atchIsLocal,
          };
          props.ADD_ATTACHMENT({
            attachment: _newAtch,
            overrideSvgProps: true,
          });
          setEditingAtch({});
          setUploadProgress(initialUploadProgress);
        };

        if (netState.isConnected) {
          putWithToken(_atch, "/attachments/update", props.customToken)
            .then((res) => {
              if (res.status === 200) {
                updateAtchObj(res.data);
              } else {
                showToast(t("saveFailed2"));
                setUploadProgress(initialUploadProgress);
              }
            })
            .catch((error) => {
              errorReport({
                error,
                errorInFn: "onWhiteboardSave update",
                errorInScreen: "AddAttachmentsScreen",
                errorParams: {
                  id: _atch.id,
                },
              });
              setUploadProgress(initialUploadProgress);
            });
        } else {
          updateAtchObj();
        }
      } else {
        if (newUri) {
          (props.SET_TMP_ATTACHMENTfn || props.SET_TMP_ATTACHMENT)({
            value: {
              ...editingAtch.atch,
              svgStrokes: strokes,
              svgElems: (svgElems ? svgElems.map(svgElemToObj) : []).concat(
                attachmentSvgPins ? attachmentSvgPins.map(svgElemToObj) : []
              ),
              fileLastModified: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
              originalUri: editingAtch.atch.originalUri ?? editingAtch.atch.uri,
              // TODO handle attachment markers
              // attachments: update(editingAtch.attachments || [], {
              //   $apply: (x) => {
              //     const index = x.findIndex((y) => y.id === "original");
              //     if (index === -1) {
              //       return update(x, {
              //         $push: [{ ...editingAtch, id: "original" }],
              //       });
              //     } else return x;
              //   },
              // }),
              uri: newUri,
            },
            index: editingAtch.atch.indexToUse,
          });
        }
        setEditingAtch({});
      }
    } catch (error) {
      errorReport({
        error,
        errorInFn: "onWhiteboardSave",
        errorInScreen: "AddAttachmentsScreen",
        errorParams: {
          id: props.attachments?.[_modifyId],
        },
      });
      setUploadProgress(initialUploadProgress);
    }
  };

  const onTakeAnotherPicture = () => {
    handleCameraOpen((response) => {
      handlePictureTaken({
        response,
        callback: handleNewTmpAttachments,
      });
    }, props.uiSettings?.saveAttachmentsToPhotos);
  };

  const nextAttachment = () => {
    setIndex(addToIndex(index, tmpAttachmentsLength));
  };

  const prevAttachment = () => {
    setIndex(subFromIndex(index, tmpAttachmentsLength));
  };

  /**
   * Function for opening Whiteboard to edit image/pdf
   *
   * Checks that attachment has original copy in s3. If it doesn't, uploads the image as original before opening to edit.
   * Uploads the current image or saved original file (if user has edited the image offline, original file is saved to file system)
   * If attachment was originally added offline, doesn't upload anything. The sync in docs/save or docs/complete will upload the images.
   * @param {Object} atch Attachment object
   */
  const handleSetEditingImage = async (atch) => {
    if (_modifyId) {
      let atchIsLocal,
        fileName,
        fileUri,
        originalFileUri,
        _atch = atch;
      const fileUris = getFileUris(_atch);
      try {
        atchIsLocal = fileUris.atchIsLocal;
        fileName = fileUris.fileName;
        fileUri = fileUris.fileUri;
        originalFileUri = fileUris.originalFileUri;

        if (atchIsLocal) {
          let originalExistsInFileSystem = false;
          try {
            originalExistsInFileSystem = await fileExists(originalFileUri);
          } catch {
            /* empty */
          }

          // if original doesn't exist copy the current file as original
          if (!originalExistsInFileSystem) {
            await copyFile(fileUri, originalFileUri);
          }

          const base64 = await readFile(originalFileUri);
          setEditingAtch({ atch: _atch, base64 });
        } else {
          setUploadProgress({
            msg: t("downloadingFiles"),
          });
          // fetch most recent attachment from server
          let atchRes = await getWithToken("/resource", props.customToken, {
            id: _atch.id,
          });
          if (atchRes.status === 200) {
            _atch = atchRes.data;
            props.ADD_ATTACHMENT({ attachment: _atch });
          }
          // try to download original
          let base64 = await downloadResource(
            _atch.id,
            fileName,
            _atch.type,
            null,
            props.customToken,
            true,
            false,
            "Original",
            true
          );

          // if original doesn't exist upload it to s3
          if (!base64) {
            base64 = await downloadResource(
              _atch.id,
              fileName,
              _atch.type,
              null,
              props.customToken,
              true
            );
            uploadFile(
              "/attachments/original",
              1,
              fileName,
              _atch.id,
              null,
              t("savingOriginalImg")
            )
              .then((res) => {
                if (
                  OS === "web"
                    ? res.status === 204
                    : res.respInfo.status === 204
                ) {
                  setUploadProgress(initialUploadProgress);
                  setEditingAtch({ atch: _atch, base64 });
                } else {
                  setUploadProgress(initialUploadProgress);
                  showToast("error");
                }
              })
              .catch((error) => {
                setUploadProgress(initialUploadProgress);
                errorReport({
                  error,
                  errorInFn: "handleSetEditingImage 1",
                  errorInScreen: "AddAttachmentsScreen",
                  errorParams: {
                    id: _atch.id,
                  },
                });
              });
          } else {
            setUploadProgress(initialUploadProgress);
            setEditingAtch({ atch: _atch, base64 });
          }
        }
      } catch (error) {
        setUploadProgress(initialUploadProgress);
        errorReport({
          error,
          errorInFn: "handleSetEditingImage 2",
          errorInScreen: "AddAttachmentsScreen",
          errorParams: {
            atch,
          },
        });
      }
    } else {
      let base64;
      const atchIsLocal = !atch.id;

      if (atchIsLocal) {
        try {
          base64 =
            Platform.OS === "web"
              ? atch.uri
              : await readFile(await parseUri(atch.uri));
        } catch (error) {
          errorReport({
            error,
            errorInScreen: "AddAttachmentsScreen",
            errorInFn: "handleSetEditingImage 3",
          });
        }
      } else {
        base64 = await downloadResource(
          atch.id,
          `${atch.name}.${atch.ext}`,
          atch.type,
          null,
          props.customToken,
          true
        );
      }

      setEditingAtch({ atch: atch, base64 });
    }
  };

  const _setEditingAtch = useCallback((atch, _index) => {
    dispatchState({
      type: "set",
      prop: "setEditingAtch",
      value: { atch, index: _index },
    });
  }, []);

  const getSource = async (_atch) => {
    return `data:${_atch?.type};base64,${editingAtch.base64}`;
  };

  const handleOnSaveValidationError = (_index) => {
    setIndex(_index);
  };

  useEffect(() => {
    if (_modifyId) {
      checkAtchDownloadNeed(
        props.attachments[_modifyId],
        setAttachmentsLoadedWithParams,
        props.ADD_ATTACHMENT,
        undefined,
        undefined,
        undefined,
        undefined,
        props.customToken,
        true
      );
    }
  }, [_modifyId]);

  useEffect(() => {
    let backhandler;
    if (OS !== "ios" && OS !== "web") {
      backhandler = BackHandler.addEventListener(
        "hardwareBackPress",
        handleAddAttachmentScreenBackPress
      );
    }

    return () => {
      if (OS !== "ios" && OS !== "web") {
        backhandler?.remove();
      }
    };
  }, [handleAddAttachmentScreenBackPress]);

  useEffect(() => {
    handleNavAtchChange(props.navAttachments);
  }, [props.navAttachments]);

  useEffect(() => {
    if (saving) {
      handleSaveAtch();
    }
  }, [saving]);

  useEffect(() => {
    if (state.setEditingAtch) {
      dispatchState({ type: "set", prop: "setEditingAtch", value: null });
      handleSetEditingImage(
        state.setEditingAtch.atch || {
          ...getAtchObj(state.setEditingAtch.index),
          indexToUse: state.setEditingAtch.index,
        }
      );
    }
  }, [state.setEditingAtch]);

  if (uploadProgress.msg || (!props.modifyId && props.saveOnMount)) {
    return (
      <ProgressInfo
        pageH={_pageH}
        pageW={_pageW}
        msg={
          tmpAttachmentsLength > 1
            ? t("savingAttachments")
            : t("savingAttachment")
        }
        progress={uploadProgress.progress}
        multiPartProgress={
          tmpAttachmentsLength > 1
            ? {
                total: tmpAttachmentsLength,
                itemBeingUploaded: uploadProgress.itemBeingUploaded,
              }
            : null
        }
      />
    );
  } else if (editingAtch.atch) {
    return (
      <Whiteboard
        t={t}
        hidePreview={props.hidePreview}
        offlineAtch={props.offlineAtch}
        screenToGoBackTo={props.screenToGoBackTo}
        single={props.single}
        hideInGallery={props.hideInGallery}
        requiredProps={props.requiredProps}
        parentProps={props}
        pageH={_pageH}
        pageW={_pageW}
        fullHeight={props.fullHeight}
        fullWidth={props.fullWidth}
        atch={editingAtch.atch}
        getSource={getSource}
        goBack={() => setEditingAtch({})}
        onSave={onWhiteboardSave}
        modify={_modifyId}
      />
    );
  } else {
    return (
      <View style={theme.flex}>
        <AttachmentInputs
          modifyState={state}
          dispatchModifyState={dispatchState}
          theme={theme}
          colors={colors}
          setBottomSheet={setBottomSheet}
          pageW={_pageW}
          pageH={_pageH}
          tmpAttachments={tmpAttachments}
          tmpAttachmentsLength={tmpAttachmentsLength}
          atch={
            _modifyId ? props.attachments[_modifyId] : tmpAttachments[index]
          }
          modifiedAtch={getAtchObj(index)}
          atchIndex={index}
          fullscreenAtch={fullscreenAtch}
          setFullscreenAtch={setFullscreenAtch}
          attachmentIndex={index}
          atchLoaded={
            _modifyId
              ? fetchedAttachments[tmpAttachments[index]?.id]
              : fetchedAttachments[tmpAttachments[index]?.tmpName]
          }
          hidePreview={props.hidePreview}
          offlineAtch={props.offlineAtch}
          setAttachmentsLoaded={setAttachmentsLoadedWithParams}
          modifyId={_modifyId}
          onDelete={_modifyId ? null : deleteImg}
          setEditingAtch={_setEditingAtch}
          openPreviewModal={openPreviewModal}
          errors={errors}
          nonEditableAtchProps={props.nonEditableAtchProps}
          navigation={props.navigation}
          profile={props.profile}
          lang={props.lang}
          isFetching={props.isFetching}
          users={props.users}
          requiredProps={props.requiredProps}
          fromDocumentScanner={props.fromDocumentScanner}
          single={props.single}
          gpsLocation={props.gpsLocation}
          SET_GPS_LOCATION={props.SET_GPS_LOCATION}
          ADD_ATTACHMENT={props.ADD_ATTACHMENT}
          mapState={mapState}
          onSave={handleSave}
          onSaveValidationError={handleOnSaveValidationError}
          onBackButtonPress={onAtchAddCancel}
          nextAttachment={nextAttachment}
          prevAttachment={prevAttachment}
          mapIsFullScreen={mapIsFullScreen}
          setMapIsFullScreen={setMapIsFullScreen}
          onTakeAnotherPicture={onTakeAnotherPicture}
          cancelButtonTitle={_modifyId ? "close" : null}
          mapboxAccessToken={props.company?.settings?.mapboxAccessToken}
          customToken={props.customToken}
          permissions={props.permissions}
        />

        <PreviewModal
          docId={props.docId}
          ogImage={!_modifyId}
          pageH={_pageH}
          pageW={props.fullWidth * 0.9}
          attachment={state.previewModal.atch || state.previewModal.valuesAtch}
          visible={state.previewModal.visible}
          closeModal={closePreviewModal}
          customToken={props.customToken}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ADD_ATTACHMENT,
      removeAttachments,
      ADD_OWNER_TO_TMP_ATTACHMENT,
      REMOVE_OWNER_FROM_TMP_ATTACHMENT,
      SET_TMP_ATTACHMENT_PROP,
      SET_TMP_ATTACHMENT,
      REMOVE_TMP_ATTACHMENTS,
      CLEAR_TMP_ATTACHMENTS,
      PUSH_TO_TMP_ATTACHMENTS,
      REMOVE_TMP_ATTACHMENT,
      ADD_TO_OBJECT_ARR,
      REMOVE_OBJECT_ARR_ITEM,
      SET_GPS_LOCATION,
      SET_COMPANY_SETTINGS_PROP,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const _profile = ownProps.profile || state.userInfo.profile;
  return {
    company: state.userInfo.company,
    gpsLocation: state.userInfo.gpsLocation,
    uiSettings: state.userInfo.uiSettings,
    tmpOptionsAttachments: state.options.tmpAttachments || [],
    attachments: state.options.attachments || {},
    role: _profile.role,
    profile: _profile,
    isFetching: state.isFetching,
    users: state.options.users,
    userId: _profile.id,
    lastModified: state.options.lastModified,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAttachmentsScreen);

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MaterialCommunityIcon from "react-native-vector-icons/dist/MaterialCommunityIcons";
import InfoFooter from "../components/InfoFooter";
import PublicHeaderBar from "../components/PublicHeaderBar";
import useWindowDimensions from "../functions/useWindowDimensions";
import SimpleForm from "../../../common/components/SimpleForm";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../common/theming/theme-context";
import i18next from "i18next";
import { apiRequestWithoutToken } from "../../../common/lib/api";
import { showToast } from "../../../common/lib/helperFns";

function Contact() {
  const { t } = useTranslation();
  const { colors } = React.useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [loading, setLoading] = React.useState(false);
  const emailMessageInputs = [
    {
      key: "homeName",
      title: { all: t("name") },
      type: "textField",
      onSaveActions: ["trim"],
      required: true,
      autoFocus: false,
    },
    {
      key: "homePhone",
      title: { all: t("phone") },
      type: "textField",
      onSaveActions: ["trim"],
      numeric: true,
      required: true,
      autoFocus: false,
    },
    {
      key: "homeEmail",
      title: { all: t("email") },
      type: "textField",
      onSaveActions: ["trim"],
      required: true,
      autoFocus: false,
      validations: [{ fn: "validateEmail", errMsg: { all: t("checkEmail") } }],
    },
    {
      key: "homeCompany",
      title: { all: t("company") },
      type: "textField",
      onSaveActions: ["trim"],
      required: true,
      autoFocus: false,
    },
  ];
  const handleSave = (obj) => {
    setLoading(true);
    const body = {
      Name: obj.homeName,
      Phone: obj.homePhone,
      Email: obj.homeEmail,
      Company: obj.homeCompany,
    };

    apiRequestWithoutToken(body, "/web/contactme")
      .then((res) => {
        if (res.status === 200) {
          showToast(t("contactFormSent"), 5000, "accent");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formWidth = width > 900 ? width / 2 - 120 : width - 120;
  return (
    <div
      className="infoContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <Helmet>
        <meta
          name="description"
          content={`${t("customerService")} - Fondion Docs`}
        />
        <title>{`${t("customerService")} - Fondion Docs`}</title>
      </Helmet>

      <PublicHeaderBar />

      <div className="info-container">
        <div className="info-card">
          <p className="infoTextMedium">{t("contactUs").toUpperCase()}</p>

          <p className="infoText">{t("withPhone")}</p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 21,
                height: 42,
                width: 42,
                backgroundColor: colors.accent,
                marginRight: 16,
              }}
            >
              <MaterialCommunityIcon
                name={"phone"}
                size={30}
                color={colors.textOnAccent}
              />
            </div>
            <p className="infoTextMedium">
              <strong> 050 362 4466</strong>
            </p>
          </div>

          <p className="infoText">{t("orWithEmail")}</p>

          <p className="infoTextMedium">
            <strong>info@docean.fi</strong>
          </p>
        </div>

        <div className="info-card" style={{ backgroundColor: colors.primary }}>
          <p className="infoTextMedium" style={{ color: colors.text }}>
            {t("leaveContactRequest").toUpperCase()}
          </p>

          <p className="infoText" style={{ color: colors.text }}>
            {t("contactFormInfo")}
          </p>

          <SimpleForm
            lang={i18next.language}
            inputs={emailMessageInputs}
            width={formWidth}
            // height={300}
            saveDisabled={loading}
            disabled={loading}
            onSaveTitle={t("send")}
            onSave={handleSave}
            listColor={colors.primary}
            disableBackButton
            disableAutoFocus
          />

          <p
            className="infoTextMinimal"
            style={{ color: colors.text, marginTop: "1vh" }}
          >
            {t("privacyNotice")}{" "}
            <Link to={"/privacy-policy"} style={{ color: colors.text }}>
              {t("linkToPrivacyPolicy")}
            </Link>
            .
          </p>
        </div>
      </div>
      <InfoFooter />
    </div>
  );
}

export default Contact;

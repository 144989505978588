import React, { forwardRef } from "react";
import { ThemeContext } from "../theming/theme-context";
import { Platform, ScrollView } from "react-native";
import { hp, wp } from "../lib/helperFns";
import VariableNumInputRow from "./VariableNumInputRow";
import DualInputTitle from "./DualInputTitle";
import LineChart from "./LineChart";
import ChartContainer from "./ChartContainer";
import { errorReport, getTranslatedText } from "../lib/functions";
import PieChart from "./PieChart";
import Title from "./Title";

const getChartDataFromValues = (cell, values) => {
  const dataProps = cell.chartData.dataProps;
  let data = [];
  try {
    if (dataProps?.type === "measurementObjects") {
      values[dataProps.layoutId]?.forEach((measObj, index) => {
        dataProps.valueKeys.x.forEach((_valueKey) => {
          const value =
            values[`${dataProps.layoutId}_${measObj.valueKey}_${_valueKey}`];

          if (Array.isArray(value?.values)) {
            value.values.forEach((_val) => {
              data.push({ x: 0, y: 0 });
              data[data.length - 1].x = _val;
            });
          }
        });

        // let index = 0;
        dataProps.valueKeys.y.forEach((_valueKey) => {
          const value =
            values[`${dataProps.layoutId}_${measObj.valueKey}_${_valueKey}`];

          if (Array.isArray(value?.values)) {
            value.values.forEach((_val) => {
              if (!data[index]) data.push({ x: 0, y: 0 });
              data[index].y = _val;
              // index++;
            });
          }
        });
      });
    } else if (dataProps?.type === "values") {
      if (values && dataProps.valueKeys) {
        let index = 0;
        dataProps.valueKeys.x?.forEach((_valueKey) => {
          if (!data[index]) data.push({ x: 0, y: 0 });
          const value = values[_valueKey];
          data[index].x = value ?? 0;
          if (dataProps.valueKeys.xFill?.[index]) {
            data[index].fill = dataProps.valueKeys.xFill[index];
          }
          index++;
        });
        index = 0;
        dataProps.valueKeys.y?.forEach((_valueKey) => {
          if (!data[index]) data.push({ x: 0, y: 0 });
          const value = values[_valueKey];
          data[index].y = value ?? 0;
          if (dataProps.valueKeys.yFill?.[index]) {
            data[index].fill = dataProps.valueKeys.yFill[index];
          }
          index++;
        });
      }
    }
  } catch (error) {
    errorReport({
      error,
      errorInFn: "getChartDataFromValues",
      errorInScreen: "GraphCell",
    });
  }
  // else if (dataProps?.type === "extraRows") {
  // }

  // data.sort((a, b) => a - b);
  return data;
};
const filterChartValues = (_data, chartData) => {
  if (chartData?.ignoreFalsyValues) {
    return _data.filter((x) => x?.[chartData?.ignoreFalsyValuesAxis]);
  } else {
    return _data;
  }
};

const GraphCell = forwardRef((props, ref) => {
  const {
    onlyChart,
    lang,
    item,
    viewKey,
    childKey,
    valueKey,
    disabled,
    extraModifyProps,
    modifyValueItem,
    modifyObjectArrItem,
    _value,
    values,
    type,
  } = props;

  if (
    Platform.OS === "web" &&
    !props.chartRefs?.[valueKey] &&
    props.setChartRef
  ) {
    props.setChartRef(valueKey);
  }

  const dataProps = item.chartData?.dataProps;
  let _data;

  if (dataProps?.type) {
    _data = getChartDataFromValues(item, values);
  } else {
    _data = _value || item.chartData?.preset;
    if (!Array.isArray(_data)) _data = undefined;
  }

  const width = item.chartData?.width || 350;
  const height = item.chartData?.height || 350;
  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <>
          <Title
            title={props.title}
            hint={props.hint}
            disabled={props.disabled}
            noDisabledStyle={props.noDisabledStyle}
          />
          <ChartContainer
            valueKey={valueKey}
            dataProps={dataProps}
            width={width}
            height={height}
            setChartRef={props.setChartRef}
            chart={
              item.chartData?.type === "pie" ? (
                <PieChart
                  ref={ref}
                  {...item.chartData}
                  data={filterChartValues(_data, item.chartData)}
                  lang={lang}
                  colors={colors}
                  valueKey={valueKey}
                  setChartRef={props.setChartRef}
                  width={width}
                  height={height}
                />
              ) : (
                <LineChart
                  ref={ref}
                  {...item.chartData}
                  data={filterChartValues(_data, item.chartData)}
                  lang={lang}
                  colors={colors}
                  valueKey={valueKey}
                  setChartRef={props.setChartRef}
                  fixLabelOverlap={item.chartData?.tickFormatX === "date"}
                  allowZoom={item.chartData?.allowZoom ?? false}
                  allowPan={item.chartData?.allowPan ?? false}
                  domain={
                    props.rangeStart && props.rangeEnd
                      ? {
                          x:
                            item.chartData?.tickFormatX === "date"
                              ? [props.rangeStart, props.rangeEnd]
                              : item.chartData?.domain?.x,
                          y:
                            item.chartData?.tickFormatY === "date"
                              ? [props.rangeStart, props.rangeEnd]
                              : item.chartData?.domain?.y,
                        }
                      : item.chartData?.domain
                  }
                  tickFormatX={item.chartData?.tickFormatX}
                  tickFormatY={item.chartData?.tickFormatY}
                  width={width}
                  height={height}
                />
              )
            }
            onlyChart
            views={
              onlyChart || dataProps
                ? null
                : [
                    <React.Fragment key={"ChartView" + childKey + 1}>
                      <DualInputTitle
                        disabled={disabled}
                        title1={
                          getTranslatedText(
                            item.chartData?.formLabelsX,
                            lang
                          ) || "X"
                        }
                        title2={
                          getTranslatedText(
                            item.chartData?.formLabelsY,
                            lang
                          ) || "Y"
                        }
                        colors={colors}
                        theme={theme}
                        wp={wp}
                        hp={hp}
                      />
                      <ScrollView>
                        {_data?.map((__data, index) => (
                          <VariableNumInputRow
                            key={"ChartInput" + index.toString()}
                            orientation={orientation}
                            selectTextOnFocus={true}
                            itemKey={childKey}
                            numeric={true}
                            disabled={disabled}
                            itemIndex={index}
                            item={__data}
                            units={item.chartData?.units}
                            getTranslatedText={getTranslatedText}
                            onChangeText={(value, index, prop) => {
                              if (type === "chart") {
                                modifyValueItem({
                                  ...extraModifyProps,
                                  index,
                                  valueKey,
                                  value,
                                  docId: props.docId,
                                  idProp: prop,
                                  preset: item.chartData?.preset,
                                  type: "ChartData",
                                });
                              } else {
                                modifyObjectArrItem({
                                  docId: props.docId,
                                  valueKey,
                                  idProp: "id",
                                  oldVal: __data,
                                  value: value,
                                  propToSet: prop,
                                  preset: item.chartData?.preset,
                                  type: "ChartData",
                                });
                              }
                            }}
                            lang={lang}
                            viewKey={viewKey}
                            colors={colors}
                            theme={theme}
                            wp={wp}
                            hp={hp}
                          />
                        ))}
                      </ScrollView>
                    </React.Fragment>,
                  ]
            }
          />
        </>
      )}
    </ThemeContext.Consumer>
  );
});
export default GraphCell;

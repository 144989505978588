import "react";
import MaterialCommunityIcon from "react-native-vector-icons/dist/MaterialCommunityIcons";

export default function Icon(props) {
  return (
    <MaterialCommunityIcon
      name={props.name}
      size={props.size}
      color={props.color}
    />
  );
}

import React, { useState, useEffect } from "react";
import {
  View,
  FlatList,
  Text,
  // ActivityIndicator,
  Platform,
} from "react-native";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import moment from "moment";
import { ThemeContext } from "../theming/theme-context";
import { apiRequestWithToken, getWithToken } from "../lib/api";
import {
  fullWp,
  goBack,
  navigate,
  onDocumentPicker,
  showToast,
} from "../lib/helperFns";
import { mimeToReadable } from "../lib/constants";
import { dirs, fileExists, unlinkFile } from "../lib/fileOperations";
import {
  checkForExistingAttachments,
  errorReport,
  isArrayWithItems,
  parseAtchId,
} from "../lib/functions";
import { Tooltip } from "../components/Tooltip";
import PreviewModal from "../components/PreviewModal";
import TypeSpecificPreview from "../components/TypeSpecificPreview";
import StretchButton from "../components/StretchButton";

function unlinkAttachments(attachments) {
  let unlinkPromises = [];
  for (let i = 0; i < attachments.length; i++) {
    const atch = attachments[i];
    const atchId = parseAtchId(atch);
    const uri = `${dirs.DocumentDir}/${atchId}.${atch.ext}`;
    const thumbnailUri = `${dirs.DocumentDir}/${atchId}_thumbnail.${atch.ext}`;

    unlinkPromises.push(
      fileExists(uri).then((exists) => {
        if (exists)
          return unlinkFile(uri).catch((err) =>
            console.warn("unlinkAttachments", err)
          );
        else return true;
      })
    );
    unlinkPromises.push(
      fileExists(thumbnailUri).then((exists) => {
        if (exists)
          return unlinkFile(thumbnailUri).catch((err) =>
            console.warn("unlinkAttachments", err)
          );
        else return true;
      })
    );
  }
  Promise.all(unlinkPromises);
}

const listGap = { gap: 2 };
const renderID = false;
const PAGE_SIZE = 50; // Number of items to fetch per request

function DescriptionBox({
  atch,
  // pageW,
  // openPreviewModal,
  t,
  theme,
  // colors
}) {
  return (
    <View style={[theme.paddingContainer, { overflow: "hidden" }]}>
      <Tooltip
        tip={`${atch.name}
${mimeToReadable(atch.type) ?? atch.type ?? atch.ext}
${
  atch.lastModified ? moment(atch.lastModified).format("hh:mm DD.MM.YYYY") : "-"
}
${t("desc")}: ${atch.desc ? atch.desc : "-"}`}
      >
        <View>
          {renderID ? (
            <Text style={theme.label} numberOfLines={1}>
              {atch.id}
            </Text>
          ) : null}
          <Text style={theme.label} numberOfLines={1}>
            {atch.name}
          </Text>
          <Text style={theme.label} numberOfLines={1}>
            {`${
              atch.lastModified
                ? moment(atch.lastModified).format("hh:mm DD.MM.YYYY")
                : ""
            } ${mimeToReadable(atch.type) ?? atch.type ?? atch.ext}`}
          </Text>
          <Text style={theme.label} numberOfLines={1}>
            {t("desc") + `: ${atch.desc ? atch.desc : "-"}`}
          </Text>
        </View>
      </Tooltip>
    </View>
  );
}

const INITIAL_STATE = {
  pageNumber: 1,
  hasMore: true,
  data: [],
};
function AttachmentsGalleryScreen(props) {
  const theming = React.useContext(ThemeContext);
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [global, setGlobal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState({});
  const [state, setState] = useState(INITIAL_STATE);

  const changeGlobal = (val) => {
    setState(INITIAL_STATE);
    setGlobal(val);
    fetchData(val, INITIAL_STATE);
  };

  const fetchData = async (_global = global, _state = state) => {
    if (loading || !_state.hasMore) return; // Prevent duplicate requests
    setLoading(true);
    try {
      const response = await getWithToken("/attachments", null, {
        global: _global,
        pageSize: PAGE_SIZE,
        pageNumber: _state.pageNumber,
      });

      // TODO filter on server
      if (isArrayWithItems(props.allowedTypes)) {
        response.data.data = response.data.data.filter((x) =>
          props.allowedTypes.includes(x.type)
        );
      }
      if (props.onlyImages) {
        response.data.data = response.data.data.filter(
          (x) => x.type && x.type.startsWith("image")
        );
      }

      if (response.status === 200) {
        setState((prevState) => ({
          pageNumber: prevState.pageNumber + 1,
          hasMore:
            response.data.data.length >= response.data.totalResults
              ? false
              : true,
          data: [...prevState.data, ...response.data.data],
        }));
      } else {
        showToast(t("unhandledError"));
      }
    } catch {
      showToast(t("unhandledError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch
  }, []);

  const handleAction = (action) => {
    if (action === "filePicker") {
      onDocumentPicker({ _navigate: navigate, navigation: props.navigation });
    } else if (action === "camera") {
      // TODO handle camera
    }
  };

  const _goBack = (params) => {
    if (props.goBack) {
      props.goBack(params);
    } else {
      goBack(params);
    }
  };

  function setPreviewModalWithParams(params) {
    setPreviewModal(params);
  }

  function openPreviewModal(atch) {
    setPreviewModalWithParams({
      atch: atch,
      visible: true,
    });
  }

  function closePreviewModal() {
    setPreviewModal({ isVisible: false });
  }

  function pushToSelected(item) {
    setSelected(update(selected, { $push: [item] }));
  }

  function removeFromSelected(item) {
    const foundIndex = selected.findIndex((x) => x.id === item.id);
    if (foundIndex !== -1) {
      setSelected(update(selected, { $splice: [[foundIndex, 1]] }));
    }
  }

  const attach = (item, dontGoBack) => {
    const _value = props.values?.[props.valueKey];
    if (
      props.valueKey &&
      checkForExistingAttachments(item.id, item.name, _value)
    ) {
      if (props.single) {
        props.replaceObjectArrItem({
          type: "Atch",
          docId: props.docId,
          valueKey: `${props.valueKey}`,
          value: {
            id: item.id,
            desc: item.desc,
            name: item.name,
            offlineAtch: item.offlineAtch,
            hidePreview: item.hidePreview,
          },
          idProp: "id",
          oldVal: _value?.[0],
          sortProp: "name",
          state: { attachment: item },
        });
      } else {
        props.addToObjectArr({
          type: "Atch",
          docId: props.docId,
          valueKey: `${props.valueKey}`,
          value: {
            id: item.id,
            desc: item.desc,
            name: item.name,
            offlineAtch: item.offlineAtch,
            hidePreview: item.hidePreview,
          },
          idProp: "id",
          sortProp: "name",
          state: { attachment: item },
        });
      }
      if (!dontGoBack) _goBack(props.navigation);
    }
  };

  const onItemPress = (item, isSelected) => () => {
    if (isSelected) removeFromSelected(item, global);
    else if (selected.length > 0 && !props.single) {
      pushToSelected(item, global);
    } else {
      if (props.onSelect) {
        props.onSelect([item.id], [item]);
      } else {
        if (!props.standAlone) {
          openPreviewModal(item);
        } else {
          attach(item);
        }
      }
    }
  };

  const handleAttachSelected = (selected) => {
    for (let i = 0; i < selected.length; i++) {
      // only go back on the last one
      attach(selected[i], true);
    }
    _goBack(props.navigation);
  };

  function handleAttachmentsRemove(attachments, _selected) {
    setLoading(true);
    try {
      let onlineAttachmentIds = [];
      let offlineAttachments = [];

      _selected.forEach((item) => {
        if (item.saveFailed || item.offlineAtch || item.base64) {
          offlineAttachments.push(item);
        } else {
          onlineAttachmentIds.push(item.id);
        }
      });

      if (onlineAttachmentIds.length > 0) {
        apiRequestWithToken(
          {
            attachmentIds: onlineAttachmentIds,
            docId: props.docId,
          },
          "/attachments/delete"
        )
          .then((res) => {
            if (res.status === 200) {
              props.removeAttachments({ keysToRemove: onlineAttachmentIds });
              if (res.data.NotDeleted.length > 0) {
                showToast(
                  t("cantDeleteAtchWithDocRelations") +
                    ": " +
                    res.data.NotDeleted.reduce(
                      (prev, cur) =>
                        prev +
                        "\n" +
                        attachments.find((x) => x.id === cur).name,
                      ""
                    )
                );
              }
            } else {
              showToast(t("atchDeleteFailed"));
              setLoading(false);
            }
          })
          .catch(() => {
            showToast(t("atchDeleteFailed"));
            setLoading(false);
          });
      }

      // remove lingering files
      unlinkAttachments(
        onlineAttachmentIds.concat(offlineAttachments.map((x) => x.id))
      );
      // remove offline attachments too
      props.removeAttachments({ keysToRemove: onlineAttachmentIds });
    } catch (error) {
      errorReport({
        error,
        errorInFn: "handleAttachmentsRemove",
        errorInScreen: "AttachmentsGalleryScreen",
      });
      setLoading(false);
    }
  }

  const viewWidth = Platform.OS === "web" ? props.pageW - 24 : fullWp(100) - 8;
  const numColumns = Math.floor(viewWidth / 140);
  const availableSpace = viewWidth;
  const itemSize = availableSpace / numColumns;
  const itemHeight = itemSize + (Platform.OS === "web" ? 58 : 64);
  return (
    <>
      <View style={theming.theme.buttonContainer}>
        <StretchButton
          title={t("ownPlural")}
          color={global ? theming.colors.text : null}
          backgroundColor={global ? theming.colors.secondary : null}
          onPress={() => changeGlobal(false)}
        />
        <StretchButton
          title={t("sharedToCompany")}
          color={global ? null : theming.colors.text}
          backgroundColor={global ? null : theming.colors.secondary}
          onPress={() => changeGlobal(true)}
        />
      </View>
      <View style={theming.theme.padding4Flex}>
        <FlatList
          key={"AtchList" + global + numColumns}
          data={state.data}
          keyExtractor={(item) => item.id.toString()}
          numColumns={numColumns}
          contentContainerStyle={listGap}
          columnWrapperStyle={numColumns !== 1 ? listGap : null}
          scrollEnabled={true}
          getItemLayout={(data, index) => ({
            length: itemHeight,
            offset: itemHeight * index,
            index,
          })}
          renderItem={({ item }) => {
            const isSelected = selected.some((x) => x.id === item.id);
            return (
              <View
                style={{
                  backgroundColor: theming.colors.lightBg,
                  height: itemHeight,
                  width: itemSize,
                  borderWidth: 2,
                  borderColor: isSelected
                    ? theming.colors.accent
                    : theming.colors.primary,
                }}
              >
                <View style={theming.theme.fill}>
                  <TypeSpecificPreview
                    onPress={onItemPress(item, isSelected)}
                    onLongPress={() => {
                      if (
                        !isSelected &&
                        (props.single ? selected.length < 1 : true)
                      ) {
                        pushToSelected(item, global);
                      } else {
                        removeFromSelected(item, global);
                      }
                    }}
                    pageH={itemSize - 4}
                    pageW={itemSize - 4}
                    imgHeight={itemSize - 4}
                    id={item.id}
                    atch={item}
                    forceRectangle
                    noFullScreenButton
                    thumbnail
                    absolutePosition
                    // openPreviewModal={openPreviewModal}
                    // standAlone={standAlone}
                    isSelected={isSelected}
                    dirs={dirs}
                    theme={theming.theme}
                    colors={theming.colors}
                    // setAttachmentsFetched={setAttachmentsFetched}
                    disableNotFoundText
                  />
                  <DescriptionBox
                    t={t}
                    atch={item}
                    pageW={props.pageW}
                    // openPreviewModal={openPreviewModal}
                    theme={theming.theme}
                    colors={theming.colors}
                  />
                </View>
              </View>
            );
          }}
          onEndReached={() => fetchData()}
          onEndReachedThreshold={0.5} // Trigger load more when 50% away from the end
        />
      </View>
      {props.standAlone || selected.length > 0 ? (
        <View
          testID={"groupLevelButtonsContainer"}
          style={theming.theme.buttonContainer}
        >
          {props.standAlone ? (
            <StretchButton
              color={theming.colors.text}
              backgroundColor={theming.colors.secondary}
              title={t("back")}
              onPress={() => _goBack(props.navigation)}
            />
          ) : null}

          {selected.length > 0 ? (
            <StretchButton
              disabled={props.userRole === "Trial"}
              title={
                props.buttonTitle ??
                (props.standAlone ? t("attachToDoc") : t("delete"))
              }
              onPress={() => {
                if (props.standAlone) {
                  if (props.onSelect) {
                    props.onSelect(
                      selected.map((x) => x.id),
                      selected
                    );
                  } else if (props.valueKey) {
                    handleAttachSelected(selected);
                  }
                } else if (!props.standAlone) {
                  handleAttachmentsRemove(state.data, selected);
                }
              }}
            />
          ) : null}
        </View>
      ) : (
        <View style={theming.theme.buttonContainer}>
          {Platform.OS != "web" ? (
            <StretchButton
              endIcon="camera"
              disabled={props.userRole === "Trial"}
              color={theming.colors.text}
              backgroundColor={theming.colors.secondary}
              title={t("addNewPhoto")}
              onPress={() => handleAction("camera")}
            />
          ) : null}
          <StretchButton
            endIcon="file-plus"
            disabled={props.userRole === "Trial"}
            title={t("addNewFile")}
            onPress={() => handleAction("filePicker")}
          />
        </View>
      )}

      <PreviewModal
        pageH={props.fullHeight}
        pageW={props.fullWidth}
        attachment={previewModal.atch || previewModal.valuesAtch}
        visible={previewModal.isVisible}
        closeModal={closePreviewModal}
      />
    </>
  );
}

export default AttachmentsGalleryScreen;

import { useState } from "react";
import { TouchableOpacity, Text, View } from "react-native";
import moment from "moment";
import { getLocation } from "../lib/helperFns";
import DatePickerRow from "./DatePickerRow";
import FilePickerRows from "./FilePickerRows";
import CheckBoxRow from "./CheckBoxRow";
import TextInputRow from "./SimpleTextInputRow";
import MultilineTextInputRow from "./MultilineTextInputRow";
import ModalPicker from "./ModalPicker";
import AgreementCheckBoxRow from "./AgreementCheckBoxRow";
import ObjectList from "./ObjectList";
import PasswordStrength from "./PasswordStrength";
import Icon from "./Icon";
import { Tooltip } from "../components/Tooltip";
import {
  getPasswordScore,
  checkNeedToBeFilledBefore,
  getPickerChoices,
  parseLocation,
  checkInputVisibility,
  getTranslatedText,
  getTranslatedOptions,
  getCustomerName,
  getSiteName,
} from "../lib/functions";
import StretchButton from "./StretchButton";
import { connect } from "react-redux";
import {
  SET_COMPANY_SETTINGS_PROP,
  SET_GPS_LOCATION,
} from "../actions/ProfileActions";
import naturalCompare from "../lib/naturalCompare";

const styles = {
  textInputWrapper: {
    flex: 1,
    width: "100%",
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  textInputContainerStyle: {
    borderBottomWidth: 1,
  },
  paddingContainer: {
    padding: 8,
  },
};
function ModalInput(props) {
  const {
    theme,
    colors,
    input,
    relationCategories,
    index,
    inputs,
    onChange,
    lang,
    refs,
    onSavePress,
    toggleDatePicker,
    docId,
    onlineDoc,
    pageW,
    pageH,
    fullHeight,
    fullWidth,
    profile,
    attachments,
    navigate,
    modifyingExistingItem,
    noDisabledStyle,
    modifyObjectArrItem,
    deleteFromObjectArr,
    addToStringArr, // ! TODO not implemented everywhere
    deleteFromStringArr, // ! TODO not implemented everywhere
    modifyStringArr, // ! TODO not implemented everywhere
    // ! TODO not implemented
    modifyValue,
    // removeValue,
    modifyValueItem,
    isFetching,
    // !
    onDocClick,
    onDocAdd,
    onDocRemove,
    removingData,
    onBlur,
    options,
    t,
    disabled,
  } = props;
  const [secureTextInput, setSecureTextInput] = useState(true);
  const [passScore, setPassScore] = useState();
  const [refreshingLocation, setRefreshingLocation] = useState(false);

  if (input.hidden) return null;

  const inputToUse = inputs?.[index] ?? input;
  const _title =
    getTranslatedText(inputToUse.title, lang) +
    (inputToUse.required ? " *" : "");
  const _error = getTranslatedText(inputToUse.error, lang);
  const _hint = getTranslatedText(inputToUse.hint, lang);
  const visibility = checkInputVisibility({
    lang: lang,
    input: inputToUse,
    values: inputs ?? {},
    innerProp: "value",
  });
  let defaultVal = inputToUse.defaultValue
    ? getTranslatedText(inputToUse.defaultValue, lang)
    : null;
  if (defaultVal === "{date}") {
    defaultVal = moment().format("DD.MM.YYYY");
  }
  const _disabled = !!(disabled || inputToUse.disabled);
  const _noDisabledStyle = !!(noDisabledStyle || inputToUse.noDisabledStyle);
  const _value = inputToUse.value;
  if (!visibility) {
    return null;
  } else if (inputToUse.type === "textField") {
    const _onBlur = () => {
      onBlur && onBlur(index, inputToUse.key);
    };
    const _focusNextInput = (valueKey, calledOnSubmitEditing) => {
      if (inputs) {
        let nextInputRef;
        for (let i = index; i < inputs.length; i++) {
          const nextInput = inputs?.[i + 1];
          if (nextInput?.key) {
            const nextInputVisibility = checkInputVisibility({
              lang: lang,
              input: inputs?.[i + 1],
              values: inputs ?? {},
              innerProp: "value",
            });

            if (nextInputVisibility && refs.current[nextInput.key]) {
              nextInputRef = refs.current[nextInput.key];
              break;
            }
          }
        }
        if (nextInputRef) {
          nextInputRef?.focus();
          _onBlur();
          return;
        } else {
          refs.current[inputToUse.key]?.blur();
        }
      }
      !calledOnSubmitEditing && onSavePress();
    };

    if (!_value && inputs) {
      if (defaultVal) {
        onChange(index, defaultVal);
      }
    }

    if (input.multiline) {
      return (
        <View
          key={"InputWithWarningsView" + index}
          style={styles.textInputWrapper}
          onLayout={(event) => {
            if (refs) {
              refs.current[inputToUse.key + "_y"] =
                event.nativeEvent.layout.y ?? event.nativeEvent.layout.top;
            }
          }}
        >
          <MultilineTextInputRow
            error={_error}
            hint={_hint}
            cellRef={refs.current[inputToUse.key]}
            setCellRef={(ref) => (refs.current[inputToUse.key] = ref)}
            // focusNextInput={this.props.focusNextInput}
            value={_value ?? ""}
            unit={getTranslatedText(input.unit, lang)}
            setTargetProperty={(prop, text) => {
              const textToUse = inputToUse.multiline
                ? text
                : text.replace(/\r?\n|\r/g, "");
              onChange(index, textToUse);
            }}
            onBlur={_onBlur}
            focusNextInput={_focusNextInput}
            title={_title}
            disabled={disabled || (modifyingExistingItem && input.disabled)}
            noDisabledStyle={_noDisabledStyle}
          />
        </View>
      );
    } else {
      const toggleSecureTextInput = () => {
        setSecureTextInput((_val) => !_val);
      };
      return (
        <>
          <View
            key={"InputWithWarningsView" + index}
            style={styles.textInputWrapper}
            onLayout={(event) => {
              if (refs) {
                refs.current[inputToUse.key + "_y"] =
                  event.nativeEvent.layout.y ?? event.nativeEvent.layout.top;
              }
            }}
          >
            <TextInputRow
              autoFocus={input.autoFocus}
              hint={_hint}
              value={_value ?? ""}
              error={_error}
              cellRef={refs.current[inputToUse.key]}
              setCellRef={(ref) => (refs.current[inputToUse.key] = ref)}
              onChangeText={async (text) => {
                const textToUse = inputToUse.multiline
                  ? text
                  : text.replace(/\r?\n|\r/g, "");
                onChange(index, textToUse);
                if (input.passwordStrength)
                  setPassScore(await getPasswordScore(textToUse));
              }}
              disabled={disabled || (modifyingExistingItem && input.disabled)}
              noDisabledStyle={_noDisabledStyle}
              unit={getTranslatedText(input.unit, lang)}
              numeric={input.numeric}
              title={_title}
              maxLength={input.maxLength}
              maxDecimals={input.maxDecimals}
              minValue={input.minValue}
              maxValue={input.maxValue}
              multiline={input.multiline}
              returnKeyType={"next"}
              blurOnSubmit={false}
              loadingIcon={input.location && refreshingLocation}
              focusNextInput={_focusNextInput}
              onBlur={_onBlur}
              secureTextEntry={
                input.password ? secureTextInput.hiddenPass : false
              }
              passwordInput={input.password ? true : null}
              keyboardType={input.keyboardType}
              endIcon={
                input.password
                  ? secureTextInput.hiddenPass
                    ? "eye-off"
                    : "eye"
                  : input.location
                  ? "map-marker-outline"
                  : null
              }
              onEndIconPress={() => {
                if (input.location) {
                  setRefreshingLocation(true);
                  getLocation(
                    props.company?.settings?.mapboxAccessToken,
                    props.SET_COMPANY_SETTINGS_PROP,
                    props.gpsLocation,
                    props.SET_GPS_LOCATION,
                    (refreshing) => {
                      setRefreshingLocation(refreshing);
                    },
                    (value, locationRes) => {
                      if (value) {
                        try {
                          if (inputs) {
                            inputs.forEach((_input, _index) => {
                              if (
                                index !== _index &&
                                _input?.location &&
                                !_input.value
                              ) {
                                onChange(
                                  _index,
                                  parseLocation(locationRes, _input.location)
                                );
                              }
                            });
                          }
                          onChange(index, value);
                        } catch {
                          /* empty */
                        }
                      }
                    },
                    input.location
                  );
                } else if (input.password) {
                  toggleSecureTextInput();
                }
              }}
            />
          </View>
          {input.passwordStrength ? (
            <PasswordStrength
              t={t}
              theme={theme}
              password={_value}
              passScore={passScore}
            />
          ) : null}
        </>
      );
    }
  } else if (inputToUse.type === "title") {
    return (
      <View
        key={"InputWithWarningsView" + index}
        style={styles.textInputWrapper}
      >
        <View
          style={[
            theme.companyModalTitleContainer,
            {
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <View style={{ flex: 1, alignSelf: "stretch" }}>
            <Text style={theme.headerTitle}>{_title}</Text>
          </View>
          {_hint ? <Tooltip flex={0} icon tip={_hint} /> : null}
        </View>
      </View>
    );
  } else if (inputToUse.type === "label") {
    return (
      <View
        key={"InputWithWarningsView" + index}
        style={styles.textInputWrapper}
      >
        <View
          style={[
            theme.companyModalTitleContainer,
            {
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <View style={{ flex: 1, alignSelf: "stretch" }}>
            <Text style={theme.text}>{_title}</Text>
          </View>
          {_hint ? <Tooltip flex={0} icon tip={_hint} /> : null}
        </View>
      </View>
    );
  } else if (inputToUse.type === "pillPicker") {
    const title2ToUse = getTranslatedText(inputToUse.title2, lang);
    return (
      <View key={"PillPickerView" + index} style={theme.modalButtonContainer}>
        <StretchButton
          color={_value ? colors.text : null}
          backgroundColor={_value ? colors.secondary : null}
          onPress={() => onChange(index, false)}
          title={_title}
        />
        <StretchButton
          color={_value ? null : colors.text}
          backgroundColor={_value ? null : colors.secondary}
          onPress={() => onChange(index, true)}
          title={title2ToUse}
        />
      </View>
    );
  } else if (inputToUse.type === "datePicker") {
    const isIsoDate = moment(_value, moment.ISO_8601, true).isValid();
    const dateFormat =
      inputToUse.dateFormat ??
      (inputToUse.timePicker ? "HH:mm DD.MM.YYYY" : "DD.MM.YYYY");
    let __value = isIsoDate
      ? moment(_value /*, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"*/).format(dateFormat)
      : _value ??
        (inputToUse.defaultValue === "{date}"
          ? moment().format(dateFormat)
          : undefined);

    return (
      <View key={"DatePickerView" + index} style={styles.textInputWrapper}>
        <View style={{ flex: 1, backgroundColor: colors.primary }}>
          <DatePickerRow
            border={false}
            borderColor={colors.text}
            setDatePicker={() => {
              if (toggleDatePicker) {
                toggleDatePicker(
                  _value,
                  index,
                  _title,
                  true,
                  inputToUse.minDate,
                  inputToUse.maxDate,
                  inputToUse.timePicker
                );
              }
            }}
            onClear={
              inputToUse.clearable
                ? () => {
                    onChange(index, undefined);
                  }
                : null
            }
            setDate={(date) => {
              onChange(index, moment(date).format(dateFormat));
            }}
            minDate={inputToUse.minDate}
            maxDate={inputToUse.maxDate}
            timePicker={inputToUse.timePicker}
            dateFormat={dateFormat}
            date={__value}
            title={_title}
            disabled={_disabled}
            noDisabledStyle={_noDisabledStyle}
          />
        </View>
      </View>
    );
  } else if (inputToUse.type === "filePicker") {
    return (
      <View key={"filePicker" + index} style={styles.textInputWrapper}>
        <View style={{ flex: 1, backgroundColor: colors.primary }}>
          <FilePickerRows
            // key={key}
            // testID={key}
            userRole={profile.role}
            listKey={"ListKey" + index}
            title={_title}
            noBorder={true}
            onlineDoc={onlineDoc}
            //navigation={navigation}
            navigate={(route, history, params) =>
              navigate(route, history, params, index)
            }
            values={_value}
            valueKey={index}
            //getMultiEditValueKeys={this.props.getMultiEditValueKeys}
            single={false}
            onlyImages={inputToUse.onlyImages}
            removeBlocked={modifyingExistingItem}
            //screenToGoBackTo={screenToGoBackTo}
            // toggleDocSearchModal={() => {
            //   if (toggleDocSearchModal) {
            //     toggleDocSearchModal(valueKey, _title);
            //   }
            // }}
            attachments={attachments}
            allowedTypes={inputToUse.allowedTypes}
            docId={docId}
            offlineAtch={onlineDoc ? false : inputToUse.offlineAtch}
            hidePreview={inputToUse.hidePreview}
            pageH={pageH}
            pageW={pageW}
            fullHeight={fullHeight}
            fullWidth={fullWidth}
            onlyNew={inputToUse.onlyNew}
            //setTextModal={setTextModal}
            modifyObjectArrItem={modifyObjectArrItem}
            deleteFromObjectArr={deleteFromObjectArr}
            renderThumbnail={inputToUse.renderThumbnail}
          />
        </View>
      </View>
    );
  } else if (inputToUse.type === "checkBox") {
    return (
      <View
        key={"InputWithWarningsView" + index}
        style={styles.textInputWrapper}
        ref={(ref) => (refs.current[inputToUse.key] = ref)}
        onLayout={(event) => {
          if (refs) {
            refs.current[inputToUse.key + "_y"] =
              event.nativeEvent.layout.y ?? event.nativeEvent.layout.top;
          }
        }}
      >
        <View style={[theme.rowContainer, { backgroundColor: colors.primary }]}>
          <CheckBoxRow
            //key={key}
            //testID={key}
            disabled={_disabled}
            error={_error}
            longTitle={true}
            title={null}
            firstCheckTitle={_title}
            //secondCheckTitle={_secondCheckTitle || null}
            checked={_value}
            booleanChecked={true}
            boldTitle={true}
            setChecked={(id, value) => {
              if (inputToUse?.setValuesOnChange && value) {
                inputToUse.setValuesOnChange.forEach((x) => {
                  if (x.valueFromKey) {
                    const _index = inputs.findIndex(
                      (y) => y.key === x.valueFromKey
                    );
                    const _indexToSet = inputs.findIndex(
                      (y) => y.key === x.keyToSet
                    );

                    if (_index !== -1) {
                      onChange(_indexToSet ?? index, inputs[_index].value);
                    }
                  }
                });
              }
              inputToUse.fn && inputToUse.fn(value);
              onChange(index, value);
            }}
          />
        </View>
      </View>
    );
  } else if (
    inputToUse.type === "picker" ||
    inputToUse.type === "multiPicker"
  ) {
    // TODO HANDLE multipicker
    const viewKey = `ModalPickerView${inputToUse.key}`;
    const key = `ModalPicker${inputToUse.key}`;
    const filledBeforeChoices = getPickerChoices(
      inputToUse.filledBeforeChoices,
      inputs,
      "",
      lang
    );
    const itemOptions = getTranslatedOptions(inputToUse.options, lang);
    const optionsOptions =
      inputToUse.optionsProp === "users"
        ? options.users.reduce((_options, user) => {
            if (user.email && user.name) {
              _options.push(user.name + " " + user.lName);
            }
            return _options;
          }, [])
        : inputToUse.optionsProp === "customers"
        ? options?.[inputToUse.optionsProp]
          ? Object.values(options?.[inputToUse.optionsProp]).reduce(
              (acc, cur) => {
                acc.push(cur.id);
                return acc;
              },
              []
            )
          : []
        : options?.[inputToUse?.optionsProp];
    let optionsToUse =
      filledBeforeChoices.length < 1
        ? inputToUse.optionsProp && optionsOptions
          ? itemOptions.concat(optionsOptions)
          : itemOptions
        : filledBeforeChoices;

    if (inputToUse.sort) {
      if (inputToUse.translateText) {
        optionsToUse.sort((a, b) => naturalCompare(t(a), t(b)));
      } else {
        optionsToUse.sort(naturalCompare);
      }
    }

    const isMultiPicker = inputToUse.type === "multiPicker";
    const _addableProp =
      inputToUse.optionsProp === "users" ? false : inputToUse.addableProp;
    const pickerModify = (value) => {
      isMultiPicker
        ? addToStringArr(index, {
            valueKey: inputToUse.key,
            value: value,
            docId,
          })
        : onChange(index, value);
    };
    const removeValue = () => {
      onChange(index, "");
    };
    if (!_value && inputs) {
      if (isMultiPicker) {
        if (inputToUse.defaultOptions) {
          modifyValue({
            valueKey: inputToUse.key,
            value: getTranslatedOptions(inputToUse.defaultOptions, lang),
            docId,
          });
        }
      } else {
        if (defaultVal) {
          onChange(index, defaultVal);
        }
      }
    }
    return (
      <View key={viewKey} testID={viewKey} style={styles.textInputWrapper}>
        <View style={{ flex: 1, backgroundColor: colors.primary }}>
          <ModalPicker
            key={key}
            testID={key}
            docId={docId}
            lastModified={options?.lastModified}
            isFetching={isFetching}
            role={profile?.role}
            lang={lang}
            disabled={_disabled}
            noDisabledStyle={false}
            noRemoval={inputToUse.noRemoval}
            pageH={pageH}
            pageW={pageW}
            // openIfNotFilled={inputToUse.openIfNotFilled}
            setValueSequence={inputToUse.setValueSequence}
            // extraCellProps={{ ...extraCellProps, values }}
            listKey={"ListKey" + key}
            onDelete={
              isMultiPicker || inputToUse.noRemoval ? null : removeValue
            }
            onBeforeModalOpen={() => {
              return checkNeedToBeFilledBefore(
                lang,
                inputToUse.needToBeFilledBefore,
                "",
                inputs,
                inputs
              );
            }}
            error={_error}
            translateText={inputToUse.translateText}
            translationPrefix={inputToUse.translationPrefix}
            t={t}
            maxHeight={inputToUse.maxHeight}
            multiPicker={isMultiPicker}
            addableProp={_addableProp}
            prop={inputToUse.optionsProp}
            valueKey={inputToUse.key}
            disableRefresh={!_addableProp}
            boldTitle={true}
            numeric={inputToUse.numeric}
            value={
              isMultiPicker
                ? null
                : inputToUse.optionsProp === "customers"
                ? getCustomerName(options?.customers?.[_value])
                : inputToUse.optionsProp === "sites"
                ? getSiteName(options?.sites?.[_value])
                : _value
            }
            values={isMultiPicker ? _value : null}
            title={_title}
            options={optionsToUse}
            itemOptions={itemOptions}
            modifyValueItem={modifyValueItem}
            onPress={pickerModify}
            nonRemovable={inputToUse.nonRemovable}
            onRemove={(oldVal, item) => {
              deleteFromStringArr(index, {
                item,
                valueKey: item.key,
                oldVal:
                  inputToUse.translateText || inputToUse.getItemText
                    ? item
                    : oldVal,
                docId,
              });
            }}
            onAdd={pickerModify}
            modifyValue={(payload) => {
              isMultiPicker ? modifyStringArr?.(payload) : modifyValue(payload);
            }}
            getItemText={
              inputToUse.optionsProp === "customers"
                ? (x) => getCustomerName(options?.customers?.[x])
                : inputToUse.optionsProp === "sites"
                ? (x) => getSiteName(options?.sites?.[x])
                : inputToUse.getItemText
            }
          />
        </View>
      </View>
    );
  } else if (inputToUse.type === "agreementCheckbox") {
    return (
      <View
        style={{
          flex: 1,
          width: "100%",
          padding: 16,
          flexDirection: "row",
          alignItems: "center",
        }}
        onLayout={(event) => {
          if (refs) {
            refs.current[inputToUse.key + "_y"] =
              event.nativeEvent.layout.y ?? event.nativeEvent.layout.top;
          }
        }}
      >
        <View
          style={[
            theme.companyModalTitleContainer,
            {
              flex: 1,
              width: "100%",
              padding: 8,
              paddingTop: 4,
              paddingBottom: 4,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <AgreementCheckBoxRow
            prop={inputToUse.key}
            text={_title}
            value={_value}
            error={_error}
            setValue={(value) => onChange(index, value)}
          />
        </View>
      </View>
    );
  } else if (inputToUse.type === "relationList") {
    // TODO handle attachment relations
    // TODO adding new item should add a doc with current object as relation
    // TODO only allow editing on online pickerObject
    const renderCategory = (categoryKey, __title, __value) => {
      return (
        <View
          key={"RelationListView" + categoryKey + index}
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{ flex: 1, backgroundColor: colors.primary, padding: 8 }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: colors.darkPrimary,
              }}
            >
              <View
                style={{
                  flex: 1,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                <Text style={theme.boldText}>
                  {getTranslatedText(__title, lang)}
                </Text>
              </View>
              {!inputToUse.creationRestricted && !inputToUse.headerItems ? (
                <TouchableOpacity
                  onPress={
                    _disabled
                      ? null
                      : () => onDocAdd(inputToUse.layoutId, index, categoryKey)
                  }
                  disabled={_disabled}
                  style={[theme.iconButton, { backgroundColor: colors.accent }]}
                >
                  <Icon name={"plus"} size={24} color={colors.textOnAccent} />
                </TouchableOpacity>
              ) : null}
            </View>
            <ObjectList
              colors={colors}
              theme={theme}
              childKey={"RelationList" + categoryKey + index}
              modifyTitle={inputToUse.selectedEditTitle}
              creationRestricted={inputToUse.creationRestricted}
              removalRestricted={inputToUse.removalRestricted}
              openByDefault={inputToUse.openByDefault}
              itemPadding={8}
              titleWrapperStyle={{ backgroundColor: colors.accent }}
              titleStyle={{ ...theme.boldText, color: colors.textOnAccent }}
              disabled={disabled}
              noItemContent
              idProp={"id"}
              data={__value}
              noDataPlaceHolder={true}
              haveData={true}
              titleButtons={true}
              // selected={multiSelect ? multiSelected : selected}
              // removeFromSelected={(_item, _index, _idProp) => {
              //   modifySelected({
              //     type: "remove",
              //     value: null,
              //     idProp: _idProp,
              //     valueKey: _item.valueKey,
              //     index: _index,
              //   });
              // }}
              getItemText={(item) =>
                item.name || getTranslatedText(item.type, lang)
              }
              onRemovePress={(item) => onDocRemove(item, index)}
              removingData={removingData}
              // addItemToSelected is just onClick
              addItemToSelected={onDocClick}
              // setItem={modifyRow}
              // onCancelPress={onCancelPress}
              // onRemovePress={removeRow}
              // renderItemContents={renderItemContents}
              // multiSelect={multiSelect}
              unlimitedWidth
            />
          </View>
        </View>
      );
    };
    const categoryKeys = relationCategories
      ? Object.keys(relationCategories)
      : null;
    let mappedValue = {};
    if (relationCategories && Array.isArray(_value)) {
      _value.forEach((x) => {
        const curRelation = x.relations.find(
          (relation) => relation.relationId === inputToUse.curId
        );
        if (curRelation?.categoryKey) {
          if (!mappedValue[curRelation.categoryKey])
            mappedValue[curRelation.categoryKey] = [];
          mappedValue[curRelation.categoryKey].push(x);
        } else {
          if (!mappedValue.noCat) mappedValue.noCat = [];
          mappedValue.noCat.push(x);
        }
      });
    }
    // TODO enable relation input for every pickerObject
    return (
      <View
        style={{
          flex: 1,
          width: "100%",
          padding: 16,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {relationCategories
          ? categoryKeys.map((x) =>
              renderCategory(x, relationCategories[x], mappedValue[x])
            )
          : null}
        {/* {renderCategory(undefined, t("attachments"), mappedValue.noCat)} */}
      </View>
    );
  } else return null;
}

const mapStateToProps = (state) => {
  return {
    company: state.userInfo.company,
    gpsLocation: state.userInfo.gpsLocation,
  };
};

export default connect(mapStateToProps, {
  SET_GPS_LOCATION,
  SET_COMPANY_SETTINGS_PROP,
})(ModalInput);

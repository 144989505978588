import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useReducer,
  useContext,
} from "react";
import {
  TextInput,
  SectionList,
  View,
  Platform,
  BackHandler,
  Text,
} from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import DocSearchModal from "../components/DocSearchModal";
import CellComponent from "../components/CellComponent";
import RemovalTitleRowWithAlert from "../components/RemovalTitleRowWithAlert";
import SimpleModalPicker from "../components/SimpleModalPicker";
import SimpleModal from "../components/SimpleModal";

import {
  getIsRestricted,
  handleInitialSectionListScroll,
  getTranslatedText,
  isArrayWithItems,
} from "../lib/functions";
import ButtonGroup from "../components/ButtonGroup";
import CollapsableItem from "../components/CollapsableItem";
import IconButton from "../components/IconButton";
import componentReducer from "../reducers/ComponentReducer";

const OS = Platform.OS;

const separatorStyle = {
  width: "100%",
  alignSelf: "center",
  height: 8,
};
const SectionSeparator = () => {
  return <View style={separatorStyle} />;
};
function removeInnerItem(
  layoutId,
  itemValueKey,
  innerItemValueKey,
  docId,
  removeObjectArrInnerItem
) {
  removeObjectArrInnerItem({
    docId,
    valueKey: layoutId,
    itemValueKey,
    innerItemValueKey,
  });
}

const shouldNotUpdate = (prevProps, nextProps) => {
  let shouldUpdate = false;
  shouldUpdate =
    prevProps.modularItemsOpenByDefault !==
      nextProps.modularItemsOpenByDefault ||
    prevProps.profile !== nextProps.profile ||
    prevProps.options !== nextProps.options ||
    prevProps.isFetching !== nextProps.isFetching ||
    prevProps.fetchingDoc !== nextProps.fetchingDoc ||
    prevProps.connected !== nextProps.connected ||
    prevProps.lastDocRequests[prevProps.docId] !==
      nextProps.lastDocRequests[prevProps.docId] ||
    prevProps.docLayout !== nextProps.docLayout ||
    prevProps.saveDisabled !== nextProps.saveDisabled ||
    prevProps.editorRole !== nextProps.editorRole ||
    //prevProps.docToModify.date !== nextProps.docToModify.date ||
    prevProps.modularItem !== nextProps.modularItem ||
    prevProps.docToModify?.values?.[prevProps.layoutId] !==
      nextProps.docToModify?.values?.[nextProps.layoutId] ||
    prevProps.docToModify?.values?.[prevProps.layoutId + "_presetObjects"] !==
      nextProps.docToModify?.values?.[prevProps.layoutId + "_presetObjects"];
  return !shouldUpdate;
};

const ModularItemScreen = React.memo((props) => {
  const isMobile =
    Platform.OS !== "web" || /Mobi|Android/i.test(navigator.userAgent);

  const { theme, colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const mountHandled = useRef(false);
  const listRef = useRef(null);
  const data = props.docToModify?.values?.[props?.layoutId];
  const [state, dispatchState] = useReducer(componentReducer, {
    visibleItems: [],
    modalPicker: { visible: false },
    modalVisible: false,
    dataObjModalTitle: getTranslatedText(props.modularItem.title, props.lang),
    textModal: { visible: false },
    tempTitle: "",
    modularItemToChange: "",
    titleErr: "",
    selected: [],
  });

  const setState = (value) => {
    dispatchState({ type: "mergeState", value });
  };

  const closeModalPicker = () => {
    dispatchState({ type: "toggleModal", prop: "modalPicker" });
  };

  const setModalPicker = (value) => {
    dispatchState({ type: "set", prop: "modalPicker", value });
  };

  const showInnerItemAddModalOnOpen = true;

  const creationRestricted = getIsRestricted(
    props.modularItem.restricted,
    "creation",
    props.profile.role
  );

  const onBackButtonPress = () => {
    props.goBack();
    return true;
  };

  useEffect(() => {
    let backHandler;
    if (Platform.OS !== "web") {
      backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackButtonPress
      );
    }
    return () => {
      if (Platform.OS !== "web") {
        backHandler?.remove();
      }
    };
  }, []);

  const sectionsData = useMemo(() => {
    let layoutIndex = 0;
    let sections = [];
    if (data && Array.isArray(data) && data.length > 0) {
      sections = data.map((x, i) => {
        let sectionLayoutIndex = 0;
        if (i !== 0) {
          sectionLayoutIndex = layoutIndex + 2;
          layoutIndex += 2;
        }
        // TODO rework to have all cells and title rows separately
        let _data = [];
        if (isArrayWithItems(props.modularItem.headerItems)) {
          layoutIndex++;
          _data.push({
            isHeaderItem: true,
            layoutIndex,
            visibilityKey: x.valueKey,
            itemValueKey: `${x.valueKey}`,
          });
        }
        if (x.innerItems) {
          x.innerItems.forEach((innerItem) => {
            layoutIndex++;
            _data.push({
              isItemHeader: true,
              ...innerItem,
              layoutIndex,
              _valueKey: props.layoutId,
              visibilityKey: x.valueKey,
              itemValueKey: `${x.valueKey}`,
              innerItemValueKey: `${innerItem.valueKey}`,
            });
          });
        }
        return {
          item: x,
          key: "section" + i,
          data: _data,
          index: i,
          layoutIndex: sectionLayoutIndex,
        };
      });
      return { sections, count: layoutIndex };
    } else {
      return { sections, count: layoutIndex };
    }
  }, [data]);

  const onToggleTextModal = () => {
    dispatchState({ type: "toggleModal", prop: "textModal" });
  };

  const [docSearchModal, setDocSearchModal] = useState({
    visible: false,
  });

  const toggleDocSearchModal = (valueKey, title) => {
    setDocSearchModal({
      ...docSearchModal,
      visible: !docSearchModal.visible,
      valueKey,
      title,
    });
  };

  const handleInitialScroll = () => {
    if (props.scrollToValueKeys) {
      handleInitialSectionListScroll(
        mountHandled,
        listRef,
        sectionsData,
        props.setVisible,
        props.scrollToValueKeys,
        props.scrollPosition,
        (item) => {
          if (item.isHeaderItem) {
            return (
              item.valueKey === props.scrollToValueKeys.slice(0, 3).join("_")
            );
          } else if (!item.isItemHeader) {
            return (
              item.valueKey === props.scrollToValueKeys.slice(0, 4).join("_")
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!props.docId) {
      props.goBack();
    }
    if (OS !== "web") {
      props.navigation.setOptions({
        headerTitle: getTranslatedText(props.title, props.lang) || t("back"),
      });
    }
  }, []);

  useEffect(() => {
    if (!props.modularItem?.normalizeItems && showInnerItemAddModalOnOpen) {
      const item = data?.find((x) =>
        state.visibleItems?.some((y) => y.valueKey === x.valueKey)
      );

      if (item && (!item.innerItems || item.innerItems.length === 0)) {
        const addInnerItemTitle =
          getTranslatedText(props.addInnerItemTitle, props.lang) || t("addNew");
        setState({
          textModal: {
            visible: true,
            title: addInnerItemTitle,
            onSave: (value) => {
              if (value) {
                props.addToObjectArrInnerItemsWithGeneratedId({
                  userId: props.profile.id,
                  docId: props.docId,
                  valueKey: `${props.layoutId}`,
                  value: { title: value },
                  itemValueKey: item.valueKey,
                  idProp: "valueKey",
                  sortProp: "title",
                  setVisibleData: true,
                });

                onToggleTextModal();
              }
            },
          },
        });
      }
    }
  }, [state.visibleItems]);

  const duplicateItem = (item) => {
    let _item =
      item ?? (isArrayWithItems(data) ? data[data.length - 1] ?? {} : {});
    // TODO also need to duplicate valueKeys starting with the previous rows valueKey
    props.addToObjectArrWithGeneratedId({
      userId: props.profile.id,
      docId: props.docId,
      valueKey: props.layoutId,
      value: _item,
      duplicateValueKey: _item.valueKey,
      idProp: "valueKey",
      sortProp: "title",
      type: "Modular",
    });
  };

  const duplicateInnerItem = (item) => {
    props.addToObjectArrInnerItemsWithGeneratedId({
      userId: props.profile.id,
      docId: props.docId,
      valueKey: props.layoutId,
      value: { title: item.title, valueKey: item.innerItemValueKey },
      duplicateValueKey: item.innerItemValueKey,
      itemValueKey: item.itemValueKey,
      idProp: "valueKey",
      sortProp: "title",
      setVisibleData: true,
    });
  };

  function renderRemovalTitleRow(theme, colors, data = []) {
    const allKeysLength = data.length;
    let deleteWarning = t("deletionConfirmation") + ":\n\n";
    state.selected.forEach(
      (x) =>
        (deleteWarning +=
          data.find((y) => y.valueKey === x.valueKey)?.title + "\n")
    );
    return (
      <RemovalTitleRowWithAlert
        style={theme.flex}
        onCancelPress={() => setState({ selected: [] })}
        onRemovePress={() => {
          props.removeModularItems({
            docId: props.docId,
            valueKey: props.layoutId,
            keysToRemove: state.selected.map((x) => x.valueKey),
          });
          setState({ selected: [] });
        }}
        onSelectAllPress={() =>
          state.selected.length === allKeysLength
            ? setState({ selected: [] })
            : setState({ selected: data })
        }
        allSelected={state.selected.length === allKeysLength}
        deletionWarning={deleteWarning}
      />
    );
  }

  const renderButtons = (theme) => {
    const addItemTitle =
      getTranslatedText(props.addItemTitle, props.lang) || t("addTarget");
    return (
      <View style={theme.buttonContainer}>
        <ButtonGroup
          buttons={[
            {
              backgroundColor: colors.lightAccent,
              color: colors.accent,
              title: t("back"),
              onPress: () => {
                props.goBack();
              },
            },
            {
              title: t("duplicateLastRow"),
              onPress: () => duplicateItem(),
            },
            {
              title: addItemTitle,
              onPress: () => {
                setState({
                  textModal: {
                    visible: true,
                    title: addItemTitle,
                    onSave: (value) => {
                      if (value) {
                        props.addToObjectArrWithGeneratedId({
                          userId: props.profile.id,
                          docId: props.docId,
                          valueKey: `${props.layoutId}`,
                          value: { title: value },
                          idProp: "valueKey",
                          sortProp: "title",
                          type: "Modular",
                          presetObjects: props.modularItem.presetObjects,
                        });

                        onToggleTextModal();
                      }
                    },
                  },
                });
              },
            },
            props.viewUnfinishedLayout || props.docToModify.online
              ? null
              : {
                  startIcon: "content-save",
                  loading: props.isFetching,
                  disabled: props.demo || props.saveDisabled,
                  title: t("save"),
                  onPress: () => props.saveDoc({ calledOnClose: false }),
                },
          ]}
        />
      </View>
    );
  };

  let screenToGoBackTo = "create";

  const renderSectionHeader = (section) => {
    const { title, valueKey } = section.item;
    const selected = state.selected?.some((x) => x.valueKey === valueKey);
    const isVisible =
      props.modularItemsOpenByDefault ||
      state.visibleItems?.some((x) => x.valueKey === valueKey);

    return (
      <CollapsableItem
        isMobile={isMobile}
        theme={theme}
        colors={colors}
        title={title}
        isVisible={props.modularItemsOpenByDefault || isVisible}
        isSelected={selected}
        onSelect={() =>
          dispatchState({
            type: selected ? "removeFromArr" : "addToArr",
            idProp: "valueKey",
            prop: "selected",
            value: { valueKey },
          })
        }
        onDuplicate={() => duplicateItem(section.item)}
        duplicationDisabled={creationRestricted}
        onChangeText={(text) => {
          props.modifyObjectArrItem({
            docId: props.docId,
            valueKey: `${props.layoutId}`,
            idProp: "valueKey",
            oldVal: { valueKey },
            value: text,
            propToSet: "title",
            sortProp: "title",
          });
        }}
        onToggleVisible={() =>
          props.modularItemsOpenByDefault
            ? null
            : dispatchState({
                type: isVisible ? "removeFromArr" : "addToArr",
                idProp: "valueKey",
                prop: "visibleItems",
                value: { valueKey },
              })
        }
        addDisabled={creationRestricted}
        onAddNested={(ev) => {
          // TODO scroll to the added item (added item is always right under the section header when added with empty title)
          props.addToObjectArrInnerItemsWithGeneratedId({
            userId: props.profile.id,
            docId: props.docId,
            valueKey: props.layoutId,
            value: { title: "" },
            itemValueKey: valueKey,
            idProp: "valueKey",
            sortProp: "title",
            setVisibleData: true,
          });
          ev?.stopPropagation?.();
        }}
      />
    );
  };

  const onViewableItemsChanged = ({ viewableItems }) => {
    if (mountHandled.current) {
      props.handleViewableItemsChanged(viewableItems);
    }
  };

  const toggleDataIsVisible = () => {
    props.SET_USER_PROP({
      prop: "modularItemsOpenByDefault",
      value: !props.modularItemsOpenByDefault,
    });
  };

  const renderHeader = (theme) => {
    return (
      <View
        style={isMobile ? theme.componentHeaderMobile : theme.componentHeader}
      >
        <View style={theme.flexRow}>
          <IconButton
            icon={"arrow-left"}
            onPress={onBackButtonPress}
            color={props.colors.text}
            size={48}
            iconSize={28}
          />
          <View style={theme.headerTitleContainer}>
            <Text style={theme.title}>
              {getTranslatedText(props.modularItem.title, props.lang)}
            </Text>
          </View>
        </View>
        <View style={theme.row}>
          {props.modularItem.normalizeItems ? (
            <IconButton
              size={48}
              iconSize={28}
              icon={"cog"}
              onPress={() => {
                const presetValueKey = props.layoutId + "_presetObjects";
                let _data = (
                  props.docToModify?.values?.[presetValueKey] ||
                  props.modularItem.presetObjects ||
                  []
                ).map((x) =>
                  typeof x === "string"
                    ? { title: x }
                    : {
                        ...x,
                      }
                );
                const _title =
                  getTranslatedText(props.addInnerItemTitle, props.lang) ||
                  t("addNew");

                setModalPicker({
                  visible: true,
                  title: getTranslatedText(
                    props.modularItem.innerItemTitle,
                    props.lang
                  ),
                  data: _data,
                  textProp: "title",
                  isFetching: false,
                  clearSelectedOnAction: true,
                  action: (selected) => {
                    const stringsToRemove = selected.map((x) => x.title);
                    props.deleteMultipleFromStringArr({
                      valueKey: presetValueKey,
                      oldValues: stringsToRemove,
                      docId: props.docId,
                      defaultArr: _data.map((x) => x.title),
                    });
                    props.removeMultipleInnerItemsFromMultipleObjectArrays({
                      docId: props.docId,
                      valueKey: props.layoutId,
                      findWithProp: "title",
                      matchWith: stringsToRemove,
                    });

                    _data = _data.filter((x) =>
                      selected.every((_selected) => x.title !== _selected.title)
                    );
                    setModalPicker((x) => ({
                      ...x,
                      data: _data,
                    }));
                  },
                  multiple: true,
                  middleButtonTitle: _title,
                  onMiddleButtonPress: () => {
                    setState({
                      textModal: {
                        visible: true,
                        title: _title,
                        onSave: (value) => {
                          const valueKey = props.layoutId;
                          props.addToStringArr({
                            valueKey: presetValueKey,
                            value: value,
                            docId: props.docId,
                            defaultArr: _data.map((x) => x.title),
                          });
                          props.addInnerItemToMultipleObjectArraysWithGeneratedId(
                            {
                              userId: props.profile.id,
                              docId: props.docId,
                              valueKey: `${valueKey}`,
                              value: { title: value },
                              idProp: "valueKey",
                              sortProp: "title",
                            }
                          );
                          _data = _data.concat([{ title: value }]);
                          setModalPicker((x) => ({
                            ...x,
                            data: _data,
                          }));
                          onToggleTextModal();
                        },
                      },
                    });
                  },
                  rightButtonTitle: t("deleteSelected"),
                });
              }}
            />
          ) : null}

          <IconButton
            size={48}
            iconSize={28}
            icon={props.modularItemsOpenByDefault ? "eye" : "eye-off"}
            onPress={toggleDataIsVisible}
          />
        </View>
      </View>
    );
  };

  // TODO move to next input on mobile keyboard done buttone
  return (
    <View
      testID={"modularDocInnerContainer"}
      style={{
        flex: 8,
        display: "flex",
      }}
    >
      {renderHeader(theme, colors)}
      {state.selected.length !== 0
        ? renderRemovalTitleRow(theme, colors, data)
        : null}

      <View style={theme.padding4Flex}>
        <SectionList
          style={OS !== "web" ? null : theme.textPaddingContainer}
          onViewableItemsChanged={onViewableItemsChanged}
          onScroll={props.handleScroll}
          ref={listRef}
          // onScroll={props.handleScroll}
          sections={sectionsData.sections} //props.layout.items}
          removeClippedSubviews={false}
          // render all the items if there's a need to scroll on mount
          // currently no way to scroll to items if everything hasn't been rendered
          initialNumToRender={props.scrollToValueKeys ? sectionsData.count : 15}
          keyExtractor={(item, index) => "ChecksRow" + index}
          stickySectionHeadersEnabled
          renderSectionFooter={SectionSeparator}
          onLayout={handleInitialScroll}
          renderSectionHeader={({ section }) => renderSectionHeader(section)}
          renderItem={({
            item,
            index,
            // section
          }) => {
            const isVisible =
              props.modularItemsOpenByDefault ||
              state.visibleItems?.some(
                (x) => x.valueKey === item.visibilityKey
              );

            if (!isVisible) return null;

            if (item.isHeaderItem) {
              return (
                <View style={[theme.lightBgBorder4, { borderBottomWidth: 0 }]}>
                  <View style={[theme.padding8, { paddingTop: 0 }]}>
                    {props.modularItem.headerItems.map((cell) => {
                      const valueKeyPrefix = `${props.layoutId}_${item.itemValueKey}`;
                      const valueKey = cell.valueKey;
                      return (
                        <CellComponent
                          key={valueKeyPrefix + "_" + valueKey}
                          layoutId={props.layoutId}
                          uiSettings={props.uiSettings}
                          userId={props.profile.id}
                          visibleExtraRowsData={props.visibleExtraRowsData}
                          lang={props.lang}
                          theme={theme}
                          colors={colors}
                          pageH={props.pageH}
                          pageW={props.pageW}
                          fullHeight={props.fullHeight}
                          fullWidth={props.fullWidth}
                          urlStart={props.urlStart}
                          profile={props.profile}
                          role={props.profile?.role}
                          isFetching={props.isFetching}
                          item={cell}
                          itemIndex={index}
                          valueKey={valueKey}
                          valueKeyPrefix={valueKeyPrefix}
                          navigation={props.navigation}
                          docId={props.docId}
                          options={props.options}
                          cells={props.modularItem?.items}
                          setDatePicker={props.setDatePicker}
                          toggleDocSearchModal={toggleDocSearchModal}
                          screenToGoBackTo={screenToGoBackTo}
                          setTextModal={props.setTextModal}
                          modifyObjectArrItem={props.modifyObjectArrItem}
                          deleteFromObjectArr={props.deleteFromObjectArr}
                          replaceObjectArrItem={props.replaceObjectArrItem}
                          addToObjectArr={props.addToObjectArr}
                          addToObjectArrWithGeneratedId={
                            props.addToObjectArrWithGeneratedId
                          }
                          deleteFromStringArr={props.deleteFromStringArr}
                          modifyStringArr={props.modifyStringArr}
                          addToStringArr={props.addToStringArr}
                          modifyValue={props.modifyValue}
                          navigate={props.navigate}
                          goBack={props.goBack}
                          setInputModal={props.setInputModal}
                        />
                      );
                    })}
                  </View>
                </View>
              );
            }
            // const hasPaddingBottom =
            //   !section.data[index + 1] || section.data[index + 1].isItemHeader;
            return (
              <>
                <View style={[theme.lightBgBorder4]}>
                  <View style={theme.padding8}>
                    <View style={theme.flexRow}>
                      <View style={[theme.lightBgBorder4, theme.flex]}>
                        <TextInput
                          disabled={props.modularItem.normalizeItems}
                          key={
                            item.valueKey +
                            item.itemValueKey +
                            item.innerItemValueKey
                          }
                          style={theme.textFieldPrimaryBg}
                          autoFocus={false}
                          defaultValue={item.title}
                          onChangeText={(text) => {
                            props.modifyObjectArrInnerItem({
                              docId: props.docId,
                              valueKey: props.layoutId,
                              itemValueKey: item.itemValueKey,
                              innerItemValueKey: item.innerItemValueKey,
                              propToModify: "title",
                              sortProp: "title",
                              value: text,
                            });
                          }}
                        />
                      </View>

                      <IconButton
                        size={58}
                        icon="content-duplicate"
                        onPress={() => {
                          duplicateInnerItem(item);
                        }}
                      />

                      {props.modularItem.normalizeItems ? null : (
                        <IconButton
                          size={58}
                          icon="trash-can"
                          onPress={() => {
                            props.setAlert({
                              visible: true,
                              cancelButton: true,
                              text:
                                t("deletionConfirmation") +
                                `:\n\n ${item.title}`,
                              onRightButtonPress: () => {
                                removeInnerItem(
                                  props.layoutId,
                                  item.itemValueKey,
                                  item.innerItemValueKey,
                                  props.docId,
                                  props.removeObjectArrInnerItem
                                );
                              },
                            });
                          }}
                        />
                      )}
                    </View>

                    {props.modularItem.items?.map((cell, innerItemIndex) => {
                      const valueKeyPrefix = `${props.layoutId}_${item.itemValueKey}_${item.valueKey}`;
                      const valueKey = cell.valueKey;

                      return (
                        <CellComponent
                          key={valueKeyPrefix + "_" + valueKey}
                          layoutId={props.layoutId}
                          uiSettings={props.uiSettings}
                          userId={props.profile.id}
                          visibleExtraRowsData={props.visibleExtraRowsData}
                          lang={props.lang}
                          theme={theme}
                          colors={colors}
                          pageH={props.pageH}
                          pageW={props.pageW}
                          fullHeight={props.fullHeight}
                          fullWidth={props.fullWidth}
                          urlStart={props.urlStart}
                          profile={props.profile}
                          role={props.profile?.role}
                          isFetching={props.isFetching}
                          item={cell}
                          itemIndex={index}
                          innerItemIndex={innerItemIndex}
                          valueKey={valueKey}
                          valueKeyPrefix={valueKeyPrefix}
                          navigation={props.navigation}
                          docId={props.docId}
                          options={props.options}
                          cells={props.modularItem?.items}
                          setDatePicker={props.setDatePicker}
                          toggleDocSearchModal={toggleDocSearchModal}
                          screenToGoBackTo={screenToGoBackTo}
                          setTextModal={props.setTextModal}
                          modifyObjectArrItem={props.modifyObjectArrItem}
                          deleteFromObjectArr={props.deleteFromObjectArr}
                          replaceObjectArrItem={props.replaceObjectArrItem}
                          addToObjectArr={props.addToObjectArr}
                          addToObjectArrWithGeneratedId={
                            props.addToObjectArrWithGeneratedId
                          }
                          deleteFromStringArr={props.deleteFromStringArr}
                          modifyStringArr={props.modifyStringArr}
                          addToStringArr={props.addToStringArr}
                          modifyValue={props.modifyValue}
                          navigate={props.navigate}
                          goBack={props.goBack}
                          setInputModal={props.setInputModal}
                        />
                      );
                    })}
                  </View>
                </View>
                <View style={theme.listDivider} />
              </>
            );
          }}
        />
      </View>

      {renderButtons(theme)}

      <SimpleModalPicker
        {...state.modalPicker}
        onToggleModal={closeModalPicker}
      />
      {state.textModal.visible ? (
        <SimpleModal
          visible={true}
          title={state.textModal.title}
          placeholder={state.textModal.placeholder || state.textModal.title}
          errMsg={state.textModal.errMsg}
          value={state.textModal.value}
          onSave={state.textModal.onSave}
          onToggleModal={onToggleTextModal}
          multiline={state.textModal.multiline}
          numeric={state.textModal.numeric}
          closeOnRighButtonPress={false}
          blurOnSubmit={false}
          onSubmitEditing
        />
      ) : null}
      <DocSearchModal
        {...docSearchModal}
        docId={props.docId}
        modifyValueItem={props.modifyValueItem}
        onToggleModal={toggleDocSearchModal}
      />
    </View>
  );
}, shouldNotUpdate);
export default ModularItemScreen;

import { useState, useEffect } from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  Text,
  View,
  FlatList,
} from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, showToast } from "../lib/helperFns";
import { errorReport, isArrayWithItems } from "../lib/functions";
import ButtonGroup from "./ButtonGroup";
import Icon from "./Icon";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const styles = {
  scrollWrapper: { minHeight: 36, maxHeight: 200 },
  titleWrapper: {
    minHeight: 36,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
export default function SimpleModalPicker(props) {
  const { closeModalOnAction = true } = props;
  const { t } = useTranslation();

  const [selected, setSelected] = useState([]);
  const [actionPressed, setActionPressed] = useState(null);

  const handleCleanup = () => {
    setActionPressed(null);
    if (closeModalOnAction) {
      props.onToggleModal();
    }
  };
  const handleAction = async (action, x) => {
    const isAsync = x.isAsyncAction || props.asyncActions;
    if (isAsync) {
      await action(x);
      handleCleanup();
    } else {
      handleCleanup();
      action(x);
    }
  };
  const handleActionPressed = (x) => {
    if (x?.action) {
      handleAction(x.action, x);
    } else if (props.action) {
      handleAction(props.action, x);
    } else {
      showToast(t("missingAction"));
    }
  };

  useEffect(() => {
    if (actionPressed) {
      handleActionPressed(actionPressed.x);
    }
  }, [actionPressed]);

  useEffect(() => {
    if (isArrayWithItems(props.defaultSelected)) {
      setSelected(props.defaultSelected);
    }
  }, [props.defaultSelected]);

  useEffect(() => {
    setActionPressed(null);
  }, [props.visible]);

  const getIsSelected = (item) => {
    return selected.some((x) =>
      props.textProp ? x[props.textProp] === item[props.textProp] : x === item
    );
  };

  const haveData = props.data && props.data.length > 0 ? true : false;
  const data = haveData ? props.data : ["-"];

  const renderListElem = (refreshProps, theme, colors) => (
    <FlatList
      keyboardShouldPersistTaps="handled"
      initialNumToRender={15}
      data={data}
      keyExtractor={(item, index) =>
        "SimpleModalPickerItem" +
        (props.textProp ? item[props.textProp] : item) +
        index
      }
      renderItem={({ item, index }) => {
        const isSelected = getIsSelected(item);

        const isLoading =
          actionPressed?.loading && actionPressed.x.id === item.id;
        return (
          <>
            <View
              style={[
                {
                  flex: 1,
                  padding: 8,
                  backgroundColor: colors.lightBg,
                },
              ]}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  disabled={
                    !!(actionPressed || props.disabled || item.disabled)
                  }
                  onPress={
                    haveData
                      ? (ev) => {
                          if (!props.isFetching) {
                            if (props.multiple) {
                              if (isSelected) {
                                try {
                                  setSelected(
                                    selected.filter((x) =>
                                      props.textProp
                                        ? x[props.textProp] !==
                                          item[props.textProp]
                                        : x !== item
                                    )
                                  );
                                } catch (error) {
                                  errorReport({
                                    error,
                                    errorInFn: "Button press",
                                    errorInScreen: "SimpleModalPicker",
                                  });
                                }
                              } else {
                                setSelected(selected.concat([item]));
                              }
                            } else {
                              ev.persist();
                              setActionPressed({
                                x: item,
                                ev,
                                loading:
                                  item.isAsyncAction || props.asyncActions,
                              });
                            }
                          }
                        }
                      : null
                  }
                >
                  {isLoading || item.startIcon ? (
                    isLoading ||
                    (item.id && item.id === props.loadingAction) ? (
                      <ActivityIndicator color={colors.text} />
                    ) : (
                      <Icon
                        name={item.startIcon}
                        size={24}
                        color={colors.text}
                      />
                    )
                  ) : null}

                  <View
                    style={[
                      theme.optionContainer,
                      {
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        backgroundColor: isSelected
                          ? colors.accent
                          : colors.lightBg,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        theme.optionText,
                        {
                          color: isSelected ? colors.textOnAccent : colors.text,
                        },
                      ]}
                    >
                      {haveData
                        ? props.textProp
                          ? item[props.textProp]
                          : item
                        : item}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {props.data && index !== props.data.length - 1 ? (
              <View style={[theme.line, { borderBottomColor: colors.text }]} />
            ) : null}
          </>
        );
      }}
    />
  );

  const buttonsDisabled = props.isFetching || selected.length === 0;
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={
            props.disableClose
              ? null
              : () => {
                  if (!props.isFetching) {
                    props.onToggleModal();
                  }
                }
          }
          onBackdropPress={
            props.disableClose
              ? null
              : () => {
                  if (!props.isFetching) {
                    props.onToggleModal();
                  }
                }
          }
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={theme.flex}>
            <View style={styles.titleWrapper}>
              <Text
                style={[
                  theme.title,
                  {
                    textAlign: "center",
                    textAlignVertical: "center",
                    fontSize: 20,
                  },
                ]}
              >
                {props.title}
              </Text>
              {props.disableClose ? null : (
                <TouchableOpacity
                  onPress={props.isFetching ? null : props.onToggleModal}
                  style={[
                    {
                      height: 36,
                      width: 36,
                      paddingTop: 0,
                      paddingBottom: 0,
                      backgroundColor: colors.accent,
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 0,
                    },
                  ]}
                >
                  <Icon name="close" size={24} color={colors.textOnAccent} />
                </TouchableOpacity>
              )}
            </View>

            {props.text ? (
              <View
                style={[
                  theme.line,
                  {
                    borderBottomColor: colors.borderPrimary,
                  },
                ]}
              />
            ) : null}

            {props.text ? (
              <View style={theme.paddingContainer}>
                <Text style={theme.text}>{props.text}</Text>
              </View>
            ) : null}

            <View style={theme.flex}>
              {renderListElem(null, theme, colors)}
            </View>

            {props.disableClose &&
            !props.onMiddleButtonPress &&
            !props.multiple ? null : (
              <View style={theme.modalButtonContainer}>
                <ButtonGroup
                  buttons={[
                    props.disableClose
                      ? null
                      : {
                          backgroundColor: colors.lightAccent,
                          color: colors.accent,
                          loading: props.isFetching,
                          disabled: props.isFetching,
                          title: t("cancel"),
                          onPress: () => props.onToggleModal(),
                        },
                    props.onMiddleButtonPress
                      ? {
                          loading: props.isFetching,
                          disabled: props.isFetching,
                          title: props.middleButtonTitle,
                          onPress: () => props.onMiddleButtonPress(),
                        }
                      : null,
                    props.multiple
                      ? {
                          loading: props.isFetching,
                          disabled: buttonsDisabled,
                          title: props.rightButtonTitle || t("ok"),
                          onPress: () => {
                            const _selected = [...selected];
                            if (props.clearSelectedOnAction) setSelected([]);
                            props.action(_selected);
                          },
                        }
                      : null,
                  ]}
                />
              </View>
            )}
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import { useContext, useCallback, useRef, useEffect, useReducer } from "react";
import { View, ScrollView, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import CellComponent from "./CellComponent";
import ButtonGroup from "./ButtonGroup";
import Title from "./Title";
import { fullWp } from "../lib/helperFns";
import docsReducer from "../reducers/DocsReducer";
import ModalfyView from "./ModalfyView";

export default function CellModal(props) {
  const title = props.modal.getParam("title");
  const cellProps = props.modal.getParam("cellProps");
  const cells = props.modal.getParam("cells");

  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const onCloseListener = useRef();
  const actionsRef = useRef([]);
  const [state, dispatchState] = useReducer(docsReducer, {
    unfinishedDocs: { [cellProps.docId]: cellProps.docToModify },
  });

  const handleClose = useCallback(() => {
    const onClose = props.modal.getParam("onClose");
    onClose?.();
  }, []);

  const modifyObjectArrItem = (payload) => {
    const action = { type: "MODIFY_OBJECT_ARR_ITEM", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const deleteFromObjectArr = (payload) => {
    const action = { type: "REMOVE_OBJECT_ARR_ITEM", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const replaceObjectArrItem = (payload) => {
    const action = { type: "REPLACE_OBJECT_ARR_ITEM", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const addToObjectArr = (payload) => {
    const action = { type: "ADD_TO_OBJECT_ARR", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const addToObjectArrWithGeneratedId = (payload) => {
    const action = { type: "ADD_TO_OBJECT_ARR_WITH_GENERATED_ID", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const deleteFromStringArr = (payload) => {
    const action = { type: "DELETE_FROM_STRING_ARR", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const modifyStringArr = (payload) => {
    const action = { type: "MODIFY_STRING_ARR", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const addToStringArr = (payload) => {
    const action = { type: "ADD_TO_STRING_ARR", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };
  const modifyValue = (payload) => {
    const action = { type: "MODIFY_VALUE", payload };
    actionsRef.current.push(action);
    dispatchState(action);
  };

  useEffect(() => {
    const onMount = props.modal.getParam("onMount");
    onMount?.({
      modifyObjectArrItem,
      deleteFromObjectArr,
      replaceObjectArrItem,
      addToObjectArr,
      addToObjectArrWithGeneratedId,
      deleteFromStringArr,
      modifyStringArr,
      addToStringArr,
      modifyValue,
    });

    onCloseListener.current = props.modal.addListener("onClose", handleClose);

    return () => {
      onCloseListener.current?.remove();
      handleClose();
    };
  }, []);

  return (
    <ModalfyView>
      <View
        style={{
          width:
            Platform.OS === "web"
              ? fullWp(100) > 700
                ? 700
                : fullWp(100) - 32
              : undefined,
          borderWidth: 2,
          borderRadius: 4,
          borderColor: theming.colors.lightBg,
          backgroundColor: theming.colors.primary,
        }}
      >
        <Title title={`${title ? title + " - " : ""}${t("addNew")}`} />

        <ScrollView>
          {cells?.map((x) => (
            <CellComponent
              key={x.item.valueKey}
              {...cellProps}
              {...x}
              autoFocus={true}
              overrideValues={state.unfinishedDocs[cellProps.docId].values}
              // item={item}
              // renderSpecificRow={modal.rowValueKey}
              // noHeader
              // disableAdd
              // layoutId={props.layoutId}
              // uiSettings={props.uiSettings}
              // userId={props.profile.id}
              // lang={props.lang}
              // theme={theme}
              // colors={colors}
              // pageH={props.pageH}
              // pageW={props.pageW}
              // fullHeight={props.fullHeight}
              // fullWidth={props.fullWidth}
              // urlStart={props.urlStart}
              // profile={props.profile}
              // role={props.profile?.role}
              // isFetching={props.isFetching}
              // itemIndex={index}
              // innerItemIndex={innerItemIndex}
              // valueKey={valueKey}
              // valueKeyPrefix={valueKeyPrefix}
              // navigation={props.navigation}
              // docId={props.docId}
              // options={props.options}
              // cells={props.modularItem?.items}
              // toggleDocSearchModal={toggleDocSearchModal}
              // screenToGoBackTo={screenToGoBackTo}
              // ! Needed
              // navigate={props.navigate}
              // goBack={props.goBack}
              // setDatePicker={props.setDatePicker}
              // setTextModal={props.setTextModal}
              // setInputModal={props.setInputModal}
              // ! if using local state, these need to be overridden
              modifyObjectArrItem={modifyObjectArrItem}
              deleteFromObjectArr={deleteFromObjectArr}
              replaceObjectArrItem={replaceObjectArrItem}
              addToObjectArr={addToObjectArr}
              addToObjectArrWithGeneratedId={addToObjectArrWithGeneratedId}
              deleteFromStringArr={deleteFromStringArr}
              modifyStringArr={modifyStringArr}
              addToStringArr={addToStringArr}
              modifyValue={modifyValue}
            />
          ))}
        </ScrollView>

        <View
          style={{
            margin: 16,
          }}
        >
          <ButtonGroup
            wrapperStyle={{}}
            buttons={[
              {
                rounded: true,
                title: t("close"),
                onPress: () => props.modal.closeModal(),
                backgroundColor: theming.colors.lightAccent,
                color: theming.colors.accent,
              },
              {
                rounded: true,
                title: t("save"),
                backgroundColor: theming.colors.accent,
                color: theming.colors.textOnAccent,
                onPress: () => {
                  const onSave = props.modal.getParam("onSave");
                  // pass all the actions that were done to the doc and the actual temporary doc
                  onSave?.(
                    actionsRef.current,
                    state.unfinishedDocs[cellProps.docId]
                  );
                  props.modal.closeModal();
                },
              },
            ]}
          />
        </View>
      </View>
    </ModalfyView>
  );
}

import React from "react";
import {
  BackHandler,
  Image as RNImage,
  PanResponder,
  View,
  Platform,
  Animated,
  Dimensions,
} from "react-native";
import update from "immutability-helper";
import Svg, { G, Path, Line, Rect, Defs, Pattern } from "react-native-svg";

import { PDFDocument } from "pdf-lib";

import Pen from "../../../common/tools/pen";
import Point from "../../../common/tools/point";
import {
  unlinkFile,
  dirs,
  readFile,
  saveFile,
  overlayImage,
} from "../../../common/lib/fileOperations";
import {
  fullWp,
  fullHp,
  onDocumentPicker,
  Canvasg,
  resizeImg,
} from "../../../common/lib/helperFns";
import {
  dataURLToBase64,
  getFileExtForResizer,
  parseToFixed,
  roundToVariable,
  handleAttachmentPick,
  getImageSize,
  errorReport,
  getImageDimensions,
} from "../../../common/lib/functions";
import AddAttachmentsScreen from "../../../common/screens/AddAttachmentsScreen";
import AttachmentsGalleryScreen from "../../../common/screens/AttachmentsGalleryScreen";
import PreviewModal from "../../../common/components/PreviewModal";
import AnimatedSvgElem from "./AnimatedSvgElem";
import Pdf from "../../../common/components/Pdf";
import ZoomableView from "../../../common/components/ZoomableView";
import WhiteboardModals from "../../../common/components/WhiteboardModals";
import WhiteboardButtons from "../../../common/components/WhiteboardButtons";
import WhiteboardHeader from "../../../common/components/WhiteboardHeader";
import { ThemeContext } from "../../../common/theming/theme-context";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SET_USER_PROP } from "../../../common/actions/ProfileActions";
import {
  ADD_OWNER_TO_TMP_ATTACHMENT,
  REMOVE_OWNER_FROM_TMP_ATTACHMENT,
  SET_TMP_ATTACHMENT_PROP,
  SET_TMP_ATTACHMENT,
  REMOVE_TMP_ATTACHMENTS,
  CLEAR_TMP_ATTACHMENTS,
  PUSH_TO_TMP_ATTACHMENTS,
  REMOVE_TMP_ATTACHMENT,
} from "../../../common/reducers/OptionsReducer";

const OS = Platform.OS;

const selectRectSize = 26;
const halfSelectRectSize = selectRectSize / 2;

// header, footer, extra padding and bottom tab bar on mobile
const buttonsHeight = 36 + 36 + 36 + (OS !== "web" ? 44 : 0);

const red = "#ff0000";

const oppositeCorners = {
  0: 2,
  1: 3,
  2: 0,
  3: 1,
};
const initialSvgElems = {
  1: {
    rotation: 0,
    width: 60,
    height: 60,
    stroke: red,
  },
  2: {
    rotation: 0,
    width: 80,
    height: 60,
    stroke: red,
  },
  3: {
    rotation: 0,
    width: 60,
    height: 60,
    stroke: red,
  },
  4: {
    rotation: 0,
    width: 80,
    height: 60,
    stroke: red,
  },
  5: { rotation: 0, fontSize: 20, stroke: red },
  6: {
    rotation: 0,
    stroke: red,
    scale: 1,
  },
  7: {
    rotation: 0,
    stroke: red,
    scale: 1,
  },
  100: {
    rotation: 0,
    // width: 20,
    // height: 20,
    stroke: red,
    scale: 1,
  },
  101: {
    rotation: 0,
    // width: 20,
    // height: 20,
    stroke: red,
    scale: 1,
  },
};

const reverseScaleNum = (point, origin, scale) => {
  return (point - origin) * (1 / scale) + origin;
};

const reverseScalePoint = ([pointX, pointY], originX, originY, scale) => {
  return [
    reverseScaleNum(pointX, originX, scale),
    reverseScaleNum(pointY, originY, scale),
  ];
};

const scaleNum = (point, origin, scale) => {
  return (point - origin) * scale + origin;
};

const scalePoint = ([pointX, pointY], originX, originY, scale) => {
  return [scaleNum(pointX, originX, scale), scaleNum(pointY, originY, scale)];
};

const rotatePoint = (pointX, pointY, originX, originY, cos, sin) => {
  const _pointX = pointX - originX;
  const _pointY = pointY - originY;
  return [
    cos * _pointX - sin * _pointY + originX,
    sin * _pointX + cos * _pointY + originY,
  ];
};

const distanceBetweenPoints = (x1, y1, x2, y2) =>
  Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

const whichSideOfLine = (x, y, x1, y1, x2, y2) =>
  (x1 - x) * (y2 - y) - (y1 - y) * (x2 - x);

// const isLeft(Point a, Point b, Point c){
//     return ((b.X - a.X)*(c.Y - a.Y) - (b.Y - a.Y)*(c.X - a.X)) > 0;
// }

// TODO implement minimum size check or flip the elem
const resizeSvgElem = (
  corner,
  mouseX,
  mouseY,
  rotation,
  rotatedConstantPoint,
  rotatedDragPoint,
  initialWidth,
  initialHeight,
  initialScale,
  constantResize,
  scaledOriginX,
  scaledOriginY,
  snapToValue,
  imageSize
) => {
  const angle = -rotation * (Math.PI / 180);
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);

  let _mouseX = mouseX;
  let _mouseY = mouseY;

  let newCX, newCY, newPointB;

  if (constantResize) {
    const mouseDistanceToOppositePoint = distanceBetweenPoints(
      mouseX,
      mouseY,
      rotatedConstantPoint[0],
      rotatedConstantPoint[1]
    );
    const dragDistanceToOppositePoint = distanceBetweenPoints(
      rotatedDragPoint[0],
      rotatedDragPoint[1],
      rotatedConstantPoint[0],
      rotatedConstantPoint[1]
    );
    let resizeAmount = distanceBetweenPoints(
      mouseX,
      mouseY,
      rotatedDragPoint[0],
      rotatedDragPoint[1]
    );

    let lineRotation;

    if (corner === 0) {
      lineRotation = rotation + 135;
    } else if (corner === 1) {
      lineRotation = rotation + 225;
    } else if (corner === 2) {
      lineRotation = rotation - 45;
    } else if (corner === 3) {
      lineRotation = rotation + 45;
    }

    const lineAngle = lineRotation * (Math.PI / 180);
    const lineCos = Math.cos(lineAngle);
    const lineSin = Math.sin(lineAngle);
    const lineA = rotatePoint(
      rotatedConstantPoint[0] - imageSize.width * 2,
      rotatedConstantPoint[1],
      rotatedConstantPoint[0],
      rotatedConstantPoint[1],
      lineCos,
      lineSin
    );
    const lineB = rotatePoint(
      rotatedConstantPoint[0] + imageSize.width * 2,
      rotatedConstantPoint[1],
      rotatedConstantPoint[0],
      rotatedConstantPoint[1],
      lineCos,
      lineSin
    );
    const sideOfLine = whichSideOfLine(
      mouseX,
      mouseY,
      lineA[0],
      lineA[1],
      lineB[0],
      lineB[1]
    );

    if (
      mouseDistanceToOppositePoint < dragDistanceToOppositePoint ||
      sideOfLine < 0
    ) {
      resizeAmount = -resizeAmount;
    }

    const opposite = initialWidth;
    const adjacent = initialHeight;
    let _angle = Math.atan(opposite / adjacent);
    _angle =
      corner === 0 || corner === 2
        ? _angle - rotation * (Math.PI / 180)
        : _angle + rotation * (Math.PI / 180);
    const newOpposite = Math.sin(_angle) * resizeAmount;
    const newAdjacent = Math.cos(_angle) * resizeAmount;

    _mouseX =
      corner === 0 || corner === 3
        ? rotatedDragPoint[0] - newOpposite
        : rotatedDragPoint[0] + newOpposite;
    _mouseY =
      corner === 0 || corner === 1
        ? rotatedDragPoint[1] - newAdjacent
        : rotatedDragPoint[1] + newAdjacent;
    // TODO fix snapping with constant resize, should snap both up or down, now square may became disproportionate
    // if (false && snapToValue) {
    //   _mouseX = roundToVariable(_mouseX, snapToValue); //_mouseX;
    //   _mouseY = roundToVariable(_mouseY, snapToValue); //_mouseY;
    // }
    newCX = (rotatedConstantPoint[0] + _mouseX) / 2;
    newCY = (rotatedConstantPoint[1] + _mouseY) / 2;
    // const _angle = rotation * (Math.PI / 180);
    newPointB = rotatePoint(_mouseX, _mouseY, newCX, newCY, cos, sin);
  } else {
    if (snapToValue) {
      _mouseX = roundToVariable(_mouseX, snapToValue); //_mouseX;
      _mouseY = roundToVariable(_mouseY, snapToValue); //_mouseY;
    }
    newCX = (rotatedConstantPoint[0] + _mouseX) / 2;
    newCY = (rotatedConstantPoint[1] + _mouseY) / 2;
    newPointB = rotatePoint(_mouseX, _mouseY, newCX, newCY, cos, sin);
    newPointB = reverseScalePoint(
      rotatePoint(_mouseX, _mouseY, newCX, newCY, cos, sin),
      newCX,
      newCY,
      initialScale
    );
  }

  let newPointA = rotatePoint(
    rotatedConstantPoint[0],
    rotatedConstantPoint[1],
    newCX,
    newCY,
    cos,
    sin
  );
  newPointA = reverseScalePoint(newPointA, newCX, newCY, initialScale);

  let newWidth = newPointB[0] - newPointA[0];
  let newHeight = newPointB[1] - newPointA[1];

  if (corner === 0) {
    // top left resize
    // bottom right is constant
    newWidth = -newWidth;
    newHeight = -newHeight;
    const addedWidth = newWidth - initialWidth;
    const addedHeight = newHeight - initialHeight;
    newPointA[0] = newPointA[0] - initialWidth - addedWidth;
    newPointA[1] = newPointA[1] - initialHeight - addedHeight;
  } else if (corner === 1) {
    // top right resize
    // bottom left is constant
    newHeight = -newHeight;
    const addedHeight = newHeight - initialHeight;
    newPointA[1] = newPointA[1] - initialHeight - addedHeight;
  } else if (corner === 3) {
    // bottom left resize
    // top right is constant
    newWidth = -newWidth;
    const addedWidth = newWidth - initialWidth;
    newPointA[0] = newPointA[0] - initialWidth - addedWidth;
  }
  return [newPointA, newWidth, newHeight];
};

function calcDistance(x1, y1, x2, y2) {
  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

function middle(p1, p2) {
  return (p1 + p2) / 2;
}

function calcCenter(x1, y1, x2, y2) {
  return {
    x: middle(x1, x2),
    y: middle(y1, y2),
  };
}

class Whiteboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orientation:
        OS === "web" ||
        Dimensions.get("window").width < Dimensions.get("window").height
          ? 1
          : 2,
      formRoute: { route: null, history: [], params: {} },
      tracker: 0,
      currentPoints: [],
      previousStrokes: [[]],
      newStroke: [],
      pen: new Pen(),
      lockZoom: true,
      snapToGrid: false,
      imageSize: {},
      alert: { visible: false },
      //svgElems: [],
      tool: 1,
      textModal: { visible: false },
      previewModal: { visible: false },
      tmpAttachments: [],
      viewZoomLevel: 1,
      zoomableViewEventObject: {
        zoomLevel: 1,
        lastZoomLevel: 1,
        offsetX: 0,
        offsetY: 0,
        lastX: 0,
        lastY: 0,
        lastMovePinch: true,
        originalWidth: 384,
        originalHeight: 569.066650390625,
        distanceLeft: 0,
        distanceRight: 0,
        distanceTop: 0,
        distanceBottom: 0,
      },
    };

    const rewind = props.rewind || function () {};
    const clear = props.clear || function () {};
    this._clientEvents = {
      rewind: rewind(this.rewind),
      clear: clear(this.clear),
    };
    this.dimensionListener;
    this.pdfDoc;
    this.initialValues;
    this.svgRef = React.createRef();
    this.inputEl = React.createRef();
    this.svgElems = [];
    this.attachmentSvgPins = [];
    // this.currentTmpAttachmentIds = props.tmpAttachments.map((x) => x.uri);
    this.tappedSvgElem = {};
    this.scaledRotatedPoints = null;
    this.rotatedPoints = null;
    this.touchedCorner = -1;
    this.line = [0, 0, 0, 0];
    this.panX = new Animated.Value(0);
    this.panY = new Animated.Value(0);
    this.startingPoint = null;
    this.resizeTimeout = null;
    this.prevImageSize = {};
    this._panResponder = PanResponder.create({
      // onMoveShouldSetPanResponder: () => true,
      // onPanResponderGrant: () => {
      //   this.panX.setOffset(this.panX._value);
      //   this.panY.setOffset(this.panY._value);
      // },
      // onPanResponderMove: Animated.event(
      //   [null, { dx: this.panX, dy: this.panY }],
      //   { useNativeDriver: false }
      // ),
      // onPanResponderRelease: () => {
      //   this.panX.flattenOffset();
      //   this.panY.flattenOffset();
      // },

      onStartShouldSetPanResponder: this.handleStartShouldSetSvgPanResponse,
      onStartShouldSetPanResponderCapture:
        this.handleStartShouldSetSvgPanResponse,
      onMoveShouldSetPanResponderCapture:
        this.handleMoveShouldSetSvgPanResponder,

      onMoveShouldSetPanResponder: this.handleMoveShouldSetSvgPanResponder,
      onPanResponderGrant: (evt, gs) => this.onResponderGrant(evt, gs),
      onPanResponderMove: (evt, gs) => this.onResponderMove(evt, gs),
      onPanResponderRelease: (evt, gs) => this.onResponderRelease(evt, gs),
    });
  }

  keyHandler = (event) => {
    const evt = event || window.event;
    let c = evt.keyCode;
    if (c === 46) {
      this.removeTappedElem();
    }
  };

  onBackButtonPress = () => {
    return false;
  };

  getPageW = () => (OS === "web" ? this.props.pageW : fullWp(100));
  getPageH = () =>
    OS === "web"
      ? this.props.pageH
      : fullHp(100) - ((this.props.noBottomBar ? 0 : 44) + 36);

  handleViewSizeChange = (size) => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(
      function () {
        this.getViewSize(size);
      }.bind(this),
      500
    );
  };
  onDimensionsChange = ({ window: { width, height } }) => {
    this.setState({ orientation: width < height ? 1 : 2 });
    this.handleViewSizeChange(this.state.imageSize);
  };
  componentDidMount() {
    if (OS === "web") {
      window.addEventListener("keydown", this.keyHandler);
    } else {
      this.dimensionListener = Dimensions.addEventListener(
        "change",
        this.onDimensionsChange
      );
      this.backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        this.onBackButtonPress
      );
    }

    this.getViewSize();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.pageH !== prevProps.pageH ||
      this.props.pageW !== prevProps.pageW
    ) {
      if (this.getViewSize) {
        this.handleViewSizeChange(prevState.imageSize);
      }
    }
  }

  componentWillUnmount() {
    this.backHandler?.remove();
    this.dimensionListener?.remove();
    if (OS === "web") window.removeEventListener("keydown", this.keyHandler);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.length === 1) {
  //     //this.processTouch(this.state);
  //   } else if (this.state.length === 2) {
  //     //this.processPinch(this.state);
  //   } else if (this.state.saving) {
  //     // setTimeout(function () {
  //     //   requestAnimationFrame(() => _this.capture(_this.state.saving));
  //     // });
  //   }
  // }

  hideTextModal = () => {
    this.setState({ textModal: { visible: false } });
  };

  hideAlert = () => {
    this.setState({ alert: { visible: false } });
  };

  setAlert = (params) => {
    this.setState({ alert: params });
  };

  setTappedElem = (params) => {
    this.tappedSvgElem = params;
    this.forceUpdate();
  };

  toggleLockZoom = () => {
    this.setState({ lockZoom: !this.state.lockZoom });
  };

  flattenNestedArr = (arr, replaceUiScale) => {
    let _arr = [];
    arr.forEach((row, rowIndex) =>
      row.forEach((x) =>
        _arr.push(
          update(x, {
            page: { $set: rowIndex + 1 },
            uiScale: {
              $apply: (x) =>
                replaceUiScale
                  ? this.state.imageSize.scale
                  : x || this.state.imageSize.scale,
            },
          })
        )
      )
    );
    return _arr;
  };

  reduceToNestedArr = (arr) => {
    let _arr = [[]];
    let row = 1;

    arr.forEach((x) => {
      if (x.page > row) {
        const remainder = x.page - row;
        for (let i = 0; i < remainder; i++) {
          _arr.push([]);
        }
        row = x.page;
      }
      _arr[row - 1].push(x);
    });
    return _arr;
  };

  filterElems = (_page) => {
    this.tappedSvgElem = {};
    const _svgElems = this.svgElems[_page - 1];
    if (!this.attachmentSvgPins[_page - 1]) this.attachmentSvgPins.push([]);
    try {
      this.attachmentSvgPins[_page - 1] = _svgElems.filter(
        (x) => x.type === "100" || x.type === "101"
      );
    } catch (error) {
      errorReport({
        error,
        errorInFn: "filterElems1",
        errorInScreen: "Whiteboard",
      });
      this.attachmentSvgPins[_page - 1] = [];
    }

    try {
      this.svgElems[_page - 1] = _svgElems.filter(
        (x) => x.type !== "100" && x.type !== "101"
      );
    } catch (error) {
      errorReport({
        error,
        errorInFn: "filterElems2",
        errorInScreen: "Whiteboard",
      });
      this.svgElems[_page - 1] = [];
    }
  };

  handleSave = () => {
    this.filterElems(1);
    this.setState({ currentPage: 1, saving: 1 }, () => this.capture(1));
  };

  setTextModal = (params) => {
    this.setState({ textModal: params });
  };

  getSource = (atch, uri) =>
    OS === "web" ? `data:${atch?.type};base64,${uri}` : uri;

  getCorrectUiScale = (uiScale, newSize, originalSize) => {
    return uiScale
      ? (newSize || this.state.imageSize).height /
          ((originalSize || this.state.imageSize.original).height * uiScale)
      : 1;
  };

  getResizedUiScale = () => {};

  getViewSize = async (prevImageSize) => {
    try {
      const _height = this.getPageH();
      const _width = this.getPageW();
      // if landscape orientation just minus the padding
      const imageViewHeight =
        OS === "web" || _height > _width
          ? _height - buttonsHeight
          : _height - 36;
      let newSize;
      let originalSize;
      let hasPages = false;
      let pageCount = 1;
      let source = this.props.atch
        ? await this.props.getSource(this.props.atch)
        : null;
      let grid;

      if (this.props.atch) {
        if (this.props.atch.dimensions || this.props.atch.base64) {
          originalSize =
            this.props.atch.dimensions || (await getImageSize(source));
          newSize = getImageDimensions(
            {
              height: originalSize.height,
              width: originalSize.width,
            },
            {
              height: imageViewHeight,
              width: this.getPageW(),
            }
          );
        } else {
          hasPages = true;

          const _source =
            OS === "web"
              ? source
              : `data:application/pdf;base64,${await readFile(
                  source.substring(7)
                )}`;
          this.pdfDoc = await PDFDocument.load(_source, {
            ignoreEncryption: true,
          });

          // Get the first page of the document
          pageCount = this.pdfDoc.getPageCount();

          const pages = this.pdfDoc.getPages();
          const firstPage = pages[0];

          // Get the width and height of the first page
          const { width, height } = firstPage.getSize();

          newSize = getImageDimensions(
            {
              height: height,
              width: width,
            },
            {
              height: imageViewHeight,
              width: this.getPageW(),
            }
          );
          originalSize = { width, height };
        }
      } else {
        originalSize = this.state.imageSize?.original ?? {
          height: this.props.whiteboardHeight ?? imageViewHeight,
          width: this.props.whiteboardWidth ?? this.getPageW() * 0.9,
        };

        newSize = getImageDimensions(originalSize, {
          height: imageViewHeight,
          width: this.getPageW() * 0.8,
        });

        // originalSize = getImageDimensions(originalSize, {
        //   height: imageViewHeight,
        //   width: this.getPageW() * 0.8,
        // });
      }

      let _previousStrokes = [];
      for (let i = 0; i < pageCount; i++) {
        _previousStrokes.push([]);
      }

      if (prevImageSize) {
        const newScale =
          (1 / (prevImageSize.height / prevImageSize.original.height)) *
          (newSize.height / originalSize.height);
        this.svgElems.forEach((row, rowIndex) =>
          row.forEach((x, itemIndex) => {
            this.svgElems[rowIndex][itemIndex] = this.initSvgElem({
              elem: x,
              x: x.x,
              y: x.y,
              scale: newScale,
            });
          })
        );
        this.state.previousStrokes.forEach((row, rowIndex) =>
          row.forEach((x) => {
            if (!x.uiScale) {
              _previousStrokes[rowIndex].push(
                update(x, {
                  uiScale: {
                    $set: prevImageSize.height / prevImageSize.original.height,
                  },
                })
              );
            } else {
              _previousStrokes[rowIndex].push(x);
            }
          })
        );

        this.setState({
          imageSize: {
            ...newSize,
            scale: newSize.height / originalSize.height,
            original: originalSize,
          },
          smallGrid: Math.ceil(grid / 10),
          grid: grid,
          source,
          pageCount,
          hasPages,
          previousStrokes: _previousStrokes,
        });
      } else {
        grid = Math.ceil(1.5 * 80);

        if (this.props.atch?.svgElems) {
          this.svgElems = this.reduceToNestedArr(
            this.props.atch.svgElems.map((x) =>
              this.initSvgElem({
                elem: x,
                x: x.x,
                y: x.y,
                scale: this.getCorrectUiScale(x.uiScale, newSize, originalSize),
              })
            )
          );
        } else {
          for (let i = 0; i < pageCount; i++) {
            this.svgElems.push([]);
          }
        }

        this.setState({
          imageSize: {
            ...newSize,
            scale: newSize.height / originalSize.height,
            original: originalSize,
          },
          smallGrid: Math.ceil(grid / 10),
          grid: grid,
          source,
          previousStrokes: this.props.atch?.svgStrokes
            ? this.reduceToNestedArr(
                this.props.atch.svgStrokes.map((x, strokeIndex) => ({
                  ...x,
                  key: strokeIndex,
                }))
              )
            : _previousStrokes,
          currentPage: 1,
          pageCount,
          hasPages,
          tracker: this.props.atch?.svgStrokes
            ? this.props.atch?.svgStrokes.length
            : 0,
        });
      }
    } catch (error) {
      errorReport({
        error: error,
        errorInScreen: "Whiteboard",
        errorInFn: "getViewSize",
      });
      this.props.goBack();
    }
  };

  logOutZoomState = (event, gestureState, zoomableViewEventObject) => {
    this.setState({ zoomableViewEventObject });
  };

  handleMoveShouldSetPanResponder = () => {
    return this.state.lockZoom;
  };

  rewind = () => {
    if (
      this.state.currentPoints.length > 0 ||
      this.state.previousStrokes[this.state.currentPage - 1].length < 1
    )
      return;
    let strokes = this.state.previousStrokes[this.state.currentPage - 1];
    strokes.pop();

    this.state.pen.rewindStroke();

    this.setState({
      previousStrokes: update(this.state.previousStrokes, {
        [this.state.currentPage - 1]: { $set: [...strokes] },
      }),
      currentPoints: [],
      tracker: this.state.tracker - 1,
    });
  };

  clear = () => {
    let _previousStrokes = [];
    for (let i = 0; i < this.state.pageCount; i++) {
      _previousStrokes.push([]);
    }

    this.setState({
      previousStrokes: _previousStrokes,
      currentPoints: [],
      newStroke: [],
      tracker: 0,
    });
    this.state.pen.clear();
  };

  processPinch = (_state) => {
    const { x1, y1, x2, y2 } = _state;
    const distance = calcDistance(x1, y1, x2, y2);
    const { x, y } = calcCenter(x1, y1, x2, y2);

    if (this.tappedSvgElem.isTapped) {
      const elem =
        this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index];
      this.setState({
        initialMouseX: x,
        initialMouseY: y,
        initialY: elem.y,
        initialX: elem.x,
        initialZoom: elem.scale,
        initialDistance: distance,
        length: 0,
      });
    } else {
      const {
        initialMouseX,
        initialMouseY,
        initialY,
        initialX,
        initialZoom,
        initialDistance,
      } = _state;

      const touchZoom = distance / initialDistance;
      const dx = x - initialMouseX;
      const dy = y - initialMouseY;

      const _x = (initialX + dx - x) * touchZoom + x;
      const _y = (initialY + dy - y) * touchZoom + y;
      const _scale = initialZoom * touchZoom;

      this.svgElems[this.state.currentPage - 1][
        this.tappedSvgElem.index
      ].scale = _scale;
      this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index].x =
        _x;
      this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index].y =
        _y;
    }
  };

  rotate = (x, y, cx, cy, angle) => {
    return [
      (x - cx) * Math.cos(angle) - (y - cy) * Math.sin(angle) + cx,
      (x - cx) * Math.sin(angle) + (y - cy) * Math.cos(angle) + cy,
    ];
  };

  setElemCoordinates = (elem, newX, newY, newWidth, newHeight, newFontSize) => {
    const _newWidth = newWidth ?? elem.width;
    const _newHeight = newHeight ?? elem.height;
    const reverseScale = 1 / elem.scale;
    const rectSize = 8 * (1 / this.state.viewZoomLevel) * reverseScale;
    const halfRectSize = rectSize / 2;

    const rotatePointsY = _newHeight < 0 ? newY + _newHeight : newY;

    elem.tlRect?.setValue({
      x: newX - halfRectSize,
      y: newY - halfRectSize,
    });
    elem.trRect?.setValue({
      x: newX + _newWidth - halfRectSize,
      y: newY - halfRectSize,
    });
    elem.brRect?.setValue({
      x: newX + _newWidth - halfRectSize,
      y: newY + _newHeight - halfRectSize,
    });
    elem.blRect?.setValue({
      x: newX - halfRectSize,
      y: newY + _newHeight - halfRectSize,
    });
    elem.r1Rect?.setValue({
      x: newX + _newWidth / 2 - halfRectSize,
      y: rotatePointsY - 40 * reverseScale - halfRectSize,
    });
    elem.r2Rect?.setValue({
      x: newX + _newWidth / 2 - halfRectSize,
      y: rotatePointsY - halfRectSize,
    });
    elem.rLineRect?.setValue({
      x: newX + _newWidth / 2 - 1 * reverseScale,
      y: rotatePointsY - 40 * reverseScale,
    });

    elem.origin.setValue({
      x: newX + (_newWidth ?? 0) / 2,
      y: newY + (_newHeight ?? 0) / 2,
    });

    if (elem.type === "3") {
      elem.c.setValue({ x: newX + _newWidth / 2, y: newY + _newWidth / 2 });
      elem.r.setValue(_newWidth / 2);
    } else if (elem.type === "4") {
      elem.c.setValue({
        x: newX + _newWidth / 2,
        y: newY + _newHeight / 2,
      });
      elem.r.setValue({ x: _newWidth / 2, y: _newHeight / 2 });
    } else if (elem.type === "5") {
      if (newFontSize) {
        elem.animFontSize.setValue(newFontSize);
        elem.fontSize = newFontSize;
      }
      if (newHeight) {
        elem.textHeight.setValue(OS !== "web" ? newHeight / 2 : newHeight);
      }
    }

    elem.animX.setValue(newX);
    elem.animY.setValue(newY);
    elem.x = newX;
    elem.y = newY;

    if (newWidth && newHeight) {
      elem.width = newWidth;
      elem.height = newHeight;
      elem.animWidth.setValue(newWidth);
      elem.animHeight.setValue(newHeight);
    }
  };
  processTouch = (x, y) => {
    try {
      if (this.touchedCorner !== -1) {
        const elem =
          this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index];
        if (!this.state.initialValues) {
          this.initialValues = {
            initialX: elem.x,
            initialY: elem.y,
            initialMouseX: x,
            initialMouseY: y,
            initialWidth: elem.width,
            initialHeight: elem.height,
            initialRotation: elem.rotation ?? 0,
            initialFontSize: elem.fontSize,
            initialScale: elem.scale,
            length: 0,
          };
        }

        const {
          snapToGrid,
          smallGrid,
          initialX,
          initialY,
          initialWidth,
          initialHeight,
          initialRotation,
          initialFontSize,
          initialScale,
        } = this.initialValues;

        if (elem.type === "6" || elem.type === "7") {
          const newX = snapToGrid ? roundToVariable(x, smallGrid) : x;
          const newY = snapToGrid ? roundToVariable(y, smallGrid) : y;

          const reverseScale = 1 / elem.scale;
          const rectSize = 8 * (1 / this.state.viewZoomLevel) * reverseScale;
          const halfRectSize = rectSize / 2;

          if (this.touchedCorner === 0) {
            elem.animX.setValue(newX);
            elem.animY.setValue(newY);
            elem.x = newX;
            elem.y = newY;
            elem.l1xRect.setValue(newX - halfRectSize);
            elem.l1yRect.setValue(newY - halfRectSize);
          } else {
            elem.animX2.setValue(newX);
            elem.animY2.setValue(newY);
            elem.l2xRect.setValue(newX - halfRectSize);
            elem.l2yRect.setValue(newY - halfRectSize);
            elem.x2 = newX;
            elem.y2 = newY;
          }
        } else {
          // if elem is text or symmetrical resize width and height the same amount but change position the same way
          const constantResize =
            elem.type === "1" ||
            elem.type === "3" ||
            elem.type === "5" ||
            elem.type === "100" ||
            elem.type === "101";

          // top-left
          if (this.touchedCorner === 4) {
            const centerX = initialX + initialWidth / 2;
            const centerY = initialY + initialHeight / 2;
            elem.rotation =
              Math.atan2(x - centerX, -(y - centerY)) * (180 / Math.PI);
            elem.animRotation.setValue(elem.rotation);
          } else {
            try {
              const scaledOriginX =
                (this.scaledRotatedPoints[
                  oppositeCorners[this.touchedCorner]
                ][0] +
                  this.scaledRotatedPoints[this.touchedCorner][0]) /
                2;
              const scaledOriginY =
                (this.scaledRotatedPoints[
                  oppositeCorners[this.touchedCorner]
                ][1] +
                  this.scaledRotatedPoints[this.touchedCorner][1]) /
                2;
              const resized = resizeSvgElem(
                this.touchedCorner,
                x,
                y,
                initialRotation,
                this.rotatedPoints[oppositeCorners[this.touchedCorner]],
                this.rotatedPoints[this.touchedCorner],
                initialWidth,
                initialHeight,
                initialScale,
                constantResize,
                scaledOriginX,
                scaledOriginY,
                snapToGrid ? smallGrid : null,
                this.state.imageSize
              );

              const newWidth = resized[1];
              const newHeight = resized[2];
              let setValues = true;
              if (elem.width % smallGrid === 0) {
                setValues = true;
              } else {
                setValues = true;
              }

              if (setValues) {
                if (elem.type === "100") {
                  elem.x = resized[0][0];
                  elem.y = resized[0][1];
                  // elem.width = resized[1];
                  // elem.height = resized[2];
                  elem.scale = (resized[1] / initialWidth) * initialScale;
                } else {
                  if (newWidth < 2) {
                    /* empty */
                  } else if (newHeight < 2) {
                    // const touchedCornerToOpposite = {
                    //   0: 3,
                    //   1: 2,
                    //   2: 1,
                    //   3: 0,
                    // };
                    // elem.x = resized[0][0];
                    // elem.y = this.rotatedPoints[0][0]; //resized[0][1];
                    // elem.width = newWidth;
                    // elem.height = Math.abs(newHeight);
                    // const { rotatedPoints, scaledRotatedPoints } =
                    //   this.getRotatedPoints(elem);
                    // this.touchedCorner =
                    //   touchedCornerToOpposite[this.touchedCorner];
                    // this.rotatedPoints = rotatedPoints;
                    // this.scaledRotatedPoints = scaledRotatedPoints;
                  } else {
                    this.setElemCoordinates(
                      elem,
                      resized[0][0],
                      resized[0][1],
                      newWidth,
                      newHeight,
                      (resized[1] / initialWidth) * initialFontSize
                    );

                    // elem.x = resized[0][0];
                    // elem.y = resized[0][1];
                    // elem.width = newWidth;
                    // elem.height = newHeight;
                  }
                }
              }
            } catch (error) {
              errorReport({
                error,
                errorInScreen: "Whiteboard",
                errorInFn: "processTouch resize",
                errorParams: {
                  scaledRotatedPoints: this.scaledRotatedPoints,
                  touchedCorner: this.touchedCorner,
                },
              });
            }
          }
        }
      } else {
        const elem =
          this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index];
        if (!this.initialValues) {
          this.initialValues = {
            initialX: elem.x,
            initialY: elem.y,
            initialMouseX: x,
            initialMouseY: y,
            length: 0,
          };
        }
        const { initialMouseX, initialMouseY, initialX, initialY } =
          this.initialValues;
        const dx = x - initialMouseX;
        const dy = y - initialMouseY;
        this.setElemCoordinates(elem, initialX + dx, initialY + dy);

        // this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index].x = snapToGrid
        //   ? roundToVariable(initialX + dx, smallGrid)
        //   : initialX + dx;
        // this.svgElems[this.state.currentPage - 1][this.tappedSvgElem.index].y = snapToGrid
        //   ? roundToVariable(initialY + dy, smallGrid)
        //   : initialY + dy;

        if (this.tappedSvgElem.isTapped) {
          //this.setState();
        }
      }
    } catch (error) {
      errorReport({
        error: error,
        errorInScreen: "Whiteboard",
        errorInFn: "processTouch",
      });
    }
  };

  initSvgElem({ elem, x, y, scale = 1 }) {
    const _x = x * scale;
    const _y = y * scale;
    const _width = elem.width ? elem.width * scale : 0;
    const _height = elem.height ? elem.height * scale : 0;

    const rectSize = 8 * (1 / this.state.viewZoomLevel);
    const halfRectSize = rectSize / 2;
    let _elem = {
      ...elem,
      x: _x,
      y: _y,
      origin: new Animated.ValueXY({
        x: _x + _width / 2,
        y: _y + _height / 2,
      }),
      animX: new Animated.Value(_x),
      animY: new Animated.Value(_y),
      animRotation: new Animated.Value(elem.rotation),
      width: _width,
      height: _height,
    };
    if (elem.width) {
      _elem.animWidth = new Animated.Value(_width);
      _elem.animHeight = new Animated.Value(_height);
    }
    if (_elem.type === "3") {
      _elem.c = new Animated.ValueXY({
        x: _x + _elem.width / 2,
        y: _y + _elem.width / 2,
      });
      _elem.r = new Animated.Value(_elem.width / 2);
    } else if (_elem.type === "4") {
      _elem.c = new Animated.ValueXY({
        x: _x + _elem.width / 2,
        y: _y + _elem.width / 2,
      });
      _elem.r = new Animated.ValueXY({
        x: _elem.width / 2,
        y: _elem.height / 2,
      });
    } else if (_elem.type === "5") {
      _elem.animFontSize = new Animated.Value(elem.fontSize ?? 20);
      _elem.animWidth = new Animated.Value(elem.width ? _width : 60);
      _elem.animHeight = new Animated.Value(elem.height ? _height : 60);
      _elem.textHeight = new Animated.Value(
        elem.height ? (OS !== "web" ? _height / 2 : _height) : 0
      );
    }
    if (_elem.type === "6" || _elem.type === "7") {
      _elem.x2 = elem.x2 * scale;
      _elem.y2 = elem.y2 * scale;
      _elem.animX2 = new Animated.Value(_elem.x2);
      _elem.animY2 = new Animated.Value(_elem.y2);
      _elem.l1xRect = new Animated.Value(_x - halfRectSize);
      _elem.l1yRect = new Animated.Value(_y - halfRectSize);
      _elem.l2xRect = new Animated.Value(_elem.x2 - halfRectSize);
      _elem.l2yRect = new Animated.Value(_elem.y2 - halfRectSize);
      this.touchedCorner = 1;
    } else {
      // if element is not a line, arrow or similar, set selection rectangle points
      _elem.tlRect = new Animated.ValueXY();
      _elem.trRect = new Animated.ValueXY();
      _elem.brRect = new Animated.ValueXY();
      _elem.blRect = new Animated.ValueXY();
      _elem.r1Rect = new Animated.ValueXY();
      _elem.r2Rect = new Animated.ValueXY();
      _elem.rLineRect = new Animated.ValueXY();
    }
    this.setElemCoordinates(_elem, _x, _y);
    return _elem;
  }

  onTouch(evt, gs, onGrant) {
    if (onGrant && this.state.addingSvgElem) {
      const reverseViewZoomLevel =
        OS !== "web" ? 1 : 1 / this.state.viewZoomLevel;
      const touches = evt.nativeEvent.touches;
      const [{ locationX, locationY }] = touches;
      const _x = this.state.snapToGrid
        ? roundToVariable(
            locationX * reverseViewZoomLevel,
            this.state.smallGrid
          )
        : locationX * reverseViewZoomLevel;
      const _y = this.state.snapToGrid
        ? roundToVariable(
            locationY * reverseViewZoomLevel,
            this.state.smallGrid
          )
        : locationY * reverseViewZoomLevel;

      const newElem = this.initSvgElem({
        elem: this.state.addingSvgElem,
        x: _x,
        y: _y,
      });

      this.svgElems[this.state.currentPage - 1].push(newElem);
      this.tappedSvgElem = {
        ...newElem,
        index: this.svgElems[this.state.currentPage - 1].length - 1,
        isTapped: true,
      };

      if (
        newElem.type !== "6"
        // newElem.type === "5" ||
        // newElem.type === "100" ||
        // newElem.type === "101"
      ) {
        this.setState({
          addingSvgElem: null,
        });
      } else {
        this.forceUpdate();
      }
    } else if (this.tappedSvgElem.isTapped) {
      const reverseViewZoomLevel =
        OS !== "web" ? 1 : 1 / this.state.viewZoomLevel;
      const touches = evt.nativeEvent.touches;
      const length = touches.length;
      if (length === 1) {
        const [{ locationX, locationY }] = touches;
        const _x = locationX * reverseViewZoomLevel;
        const _y = locationY * reverseViewZoomLevel;

        this.processTouch(_x, _y, length);
      } else if (length === 2) {
        const [touch1, touch2] = touches;
        this.initialValues = {
          x1: touch1.locationX * reverseViewZoomLevel,
          y1: touch1.locationY * reverseViewZoomLevel,
          x2: touch2.locationX * reverseViewZoomLevel,
          y2: touch2.locationY * reverseViewZoomLevel,
          length,
        };
      }
    } else {
      const reverseViewZoomLevel = 1 / this.state.viewZoomLevel;

      const previousPoint =
        this.state.currentPoints[this.state.currentPoints.length - 1];

      if (onGrant) {
        this.startingPoint =
          OS !== "web"
            ? new Point(evt.nativeEvent.locationX, evt.nativeEvent.locationY)
            : new Point(
                evt.nativeEvent.locationX * reverseViewZoomLevel,
                evt.nativeEvent.locationY * reverseViewZoomLevel
              );
      }

      let newPoint = new Point(
        this.startingPoint.x + gs.dx * reverseViewZoomLevel,
        this.startingPoint.y + gs.dy * reverseViewZoomLevel
      );

      const setPoint =
        OS !== "web" ||
        !previousPoint ||
        Math.abs(previousPoint.x - newPoint.x) > 3 ||
        Math.abs(previousPoint.y - newPoint.y) > 3;
      if (setPoint) {
        this.state.currentPoints.push(newPoint);
        this.setState({
          tracker: this.state.tracker + 1,
        });
      }
    }
  }

  onResponderGrant(evt, gs) {
    this.onTouch(evt, gs, true);
  }

  onResponderMove(evt, gs) {
    this.onTouch(evt, gs);
  }

  lineProperties = (pointA, pointB) => {
    const lengthX = pointB.x - pointA.x;
    const lengthY = pointB.y - pointA.y;
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  controlPointCalc = (current, previous, next, reverse) => {
    const c = current;
    const p = previous ? previous : c;
    const n = next ? next : c;
    const smoothing = 0.2;
    const o = this.lineProperties(p, n);
    const rev = reverse ? Math.PI : 0;

    const x = c.x + Math.cos(o.angle + rev) * o.length * smoothing;
    const y = c.y + Math.sin(o.angle + rev) * o.length * smoothing;

    return [x, y];
  };

  onResponderRelease() {
    this.initialValues = undefined;
    if (this.tappedSvgElem.isTapped) {
      this.touchedCorner = -1;
    } else {
      try {
        if (this.state.currentPoints.length < 1) return;
        let d = "";

        for (let i = 0; i < this.state.currentPoints.length; i++) {
          const e = this.state.currentPoints[i];

          if (i > 0) {
            let cs = this.controlPointCalc(
              this.state.currentPoints[i - 1],
              this.state.currentPoints[i - 2],
              e
            );
            let ce = this.controlPointCalc(
              e,
              this.state.currentPoints[i - 1],
              this.state.currentPoints[i + 1],
              true
            );
            cs = [parseToFixed(cs[0]), parseToFixed(cs[1])];
            ce = [parseToFixed(ce[0]), parseToFixed(ce[1])];
            d = `${d} C ${cs[0]},${cs[1]} ${ce[0]},${ce[1]} ${parseToFixed(
              e.x
            )},${parseToFixed(e.y)}`;
          } else {
            d = `${d} M ${parseToFixed(e.x)},${parseToFixed(e.y)}`;
          }
        }

        let newElement = {
          key: this.state.tracker,
          d: d,
          stroke: this.props.strokeColor,
          strokeWidth: this.props.strokeWidth * 5,
          fill: "none",
        };

        this.state.pen.addStroke(this.state.currentPoints);

        this.setState({
          previousStrokes: update(this.state.previousStrokes, {
            [this.state.currentPage - 1]: { $apply: (x) => [...x, newElement] },
          }),
          currentPoints: [],
          tracker: this.state.tracker + 1,
        });
      } catch (error) {
        errorReport({
          error: error,
          errorInScreen: "Whiteboard",
          errorInFn: "onResponderRelease",
          dontShowToast: true,
        });
      }
    }
  }

  _onLayoutContainer = (e) => {
    this.state.pen.setOffset(e.nativeEvent.layout);
  };

  save = (saveProps) => {
    if (this.props.clearOnSave) {
      this.getViewSize();
    }
    this.setState({ saving: false });
    this.props.onSave(saveProps, this.props.saveProps);
  };

  saveOriginal = async (atch) => {
    if (atch?.originalUri) {
      let newUri = atch.originalUri;
      this.save({
        newUri,
        svgElems: [],
        strokes: [],
        attachmentSvgPins: [],
      });
    } else {
      this.save({});
    }
  };

  // TODO WEB SVG QUALITY IS BAD AFTER SAVING
  capture = async (_page) => {
    try {
      const svgElems = this.svgElems[_page - 1];
      const svgStrokes = this.state.previousStrokes[_page - 1];
      let source = this.props.atch
        ? await this.props.getSource(this.props.atch)
        : null;
      if (OS !== "web") {
        if (
          this.svgElems[this.state.currentPage - 1].length > 0 ||
          this.state.previousStrokes[this.state.currentPage - 1].length > 0
        ) {
          this.svgRef.current.toDataURL(async (base64Svg) => {
            if (this.props.atch) {
              if (this.props.atch.dimensions) {
                const resizedImg = await resizeImg(
                  source,
                  this.state.imageSize.original.width,
                  this.state.imageSize.original.height,
                  getFileExtForResizer(this.props.atch),
                  100,
                  0,
                  dirs.CacheDir,
                  this.props.atch,
                  false
                );
                const resizedOverlay = await resizeImg(
                  base64Svg.replace(/\r?\n|\r/g, ""),
                  this.state.imageSize.original.width,
                  this.state.imageSize.original.height,
                  "PNG",
                  100,
                  0,
                  dirs.CacheDir,
                  { ext: "png" },
                  false,
                  true
                );

                const image = "file://" + resizedImg.path;
                const overlay = "file://" + resizedOverlay.path;

                const path = await overlayImage(image, overlay, { x: 0, y: 0 });
                if (path) {
                  this.save({
                    newUri: path,
                    svgElems: this.flattenNestedArr(this.svgElems, true),
                    strokes: this.flattenNestedArr(this.state.previousStrokes),
                    attachmentSvgPins: this.flattenNestedArr(
                      this.attachmentSvgPins
                    ),
                  });
                }
                // .then((path) => {

                // })
                // .catch((error) => {
                //   errorReport({
                //     error: error,
                //     errorInScreen: "Whiteboard",
                //     errorInFn: "overlayImage",
                //   });
                //   this.setState({ saving: false });
                // });
              } else {
                await this.injectToPdf(
                  _page,
                  base64Svg.replace(/\r?\n|\r/g, "")
                );
              }
            } else if (this.props.returnSvg) {
              if (this.props.onSave) {
                this.save({
                  svgElems: this.flattenNestedArr(this.svgElems),
                  strokes: this.flattenNestedArr(this.state.previousStrokes),
                  imageSize: this.state.imageSize,
                });
              }
            } else {
              const resizedOverlay = await resizeImg(
                base64Svg.replace(/\r?\n|\r/g, ""),
                this.state.imageSize.original.width,
                this.state.imageSize.original.height,
                "PNG",
                100,
                0,
                dirs.CacheDir,
                { ext: "png" },
                false,
                true
              );

              if (this.props.onSave) {
                const svgPath = dirs.CacheDir + "/" + resizedOverlay.name;
                // await saveFile(svgPath, base64Svg);
                const _base64Svg = await readFile(svgPath);
                await unlinkFile(svgPath);
                this.save({
                  base64Svg: dataURLToBase64(_base64Svg),
                });
              }
            }
          });
        } else {
          this.saveOriginal(this.props.atch);
        }
      } else {
        if (svgElems.length > 0 || svgStrokes.length > 0) {
          // get svg base64 using html and canvg lib
          const svg = document.getElementById("mainSvg");
          let canvas = document.createElement("canvas");
          canvas.width = this.state.imageSize.original.width;
          canvas.height = this.state.imageSize.original.height;
          let ctx = canvas.getContext("2d");
          const data = svg.outerHTML; // Get SVG element as HTML code.
          const v = await Canvasg.from(ctx, data);
          await v.render();
          // canvas.width = width; //this.state.imageSize.original.width;
          // canvas.height = height; //this.state.imageSize.original.height;
          const base64Svg = canvas.toDataURL("image/png");

          // Do something with dimensions
          if (this.props.atch) {
            // save svg on top of image
            if (this.props.atch.dimensions) {
              overlayImage([
                {
                  src: source,
                  x: 0,
                  y: 0,
                },
                { src: base64Svg, x: 0, y: 0 },
              ])
                .then((b64) => {
                  this.setState({ saving: false });
                  this.save({
                    newUri: b64.split(",")[1],
                    svgElems: this.flattenNestedArr(this.svgElems, true),
                    strokes: this.flattenNestedArr(this.state.previousStrokes),
                  });
                })
                .catch((err) => {
                  console.warn("overlayImage err", { err });
                  this.setState({ saving: false });
                });
            }
            // save svg in pdf using _page
            else {
              await this.injectToPdf(_page, base64Svg);
            }
          } else if (this.props.returnSvg) {
            if (this.props.onSave) {
              this.save({
                svgElems: this.flattenNestedArr(this.svgElems, true),
                strokes: this.flattenNestedArr(
                  this.state.previousStrokes,
                  true
                ),
                imageSize: this.state.imageSize,
              });
            }
          } else {
            if (this.props.onSave) {
              this.save({
                base64Svg: dataURLToBase64(base64Svg),
              });
            }
          }
        } else {
          this.saveOriginal(this.props.atch);
        }
      }
    } catch (error) {
      errorReport({
        error: error,
        errorInScreen: "Whiteboard",
        errorInFn: "capture",
      });
      this.setState({ saving: false });
    }
  };

  injectToPdf = async (_page, base64Svg) => {
    const pages = this.pdfDoc.getPages();
    const pdfPage = pages[_page - 1];

    const svgPath = dirs.CacheDir + "/whiteboardSvgPng.png";
    await saveFile(svgPath, base64Svg);
    const _base64Svg = await readFile(svgPath);
    await unlinkFile(svgPath);
    const pngImage = await this.pdfDoc.embedPng(
      `data:image/png;base64,${_base64Svg}` //.replace(/\n/g, "")
    );

    pdfPage.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: this.state.imageSize.original.width,
      height: this.state.imageSize.original.height,
    });

    if (_page < this.state.pageCount) {
      this.setState(
        {
          saving: _page + 1,
          currentPage: _page + 1,
        },
        () => {
          this.filterElems(_page + 1);
          this.setState({ saving: _page + 1 }, () => this.capture(_page + 1));
        }
      );
    } else {
      const base64Pdf = await this.pdfDoc.saveAsBase64();

      const path = dirs.CacheDir + "/tmpPdfAtch.pdf";
      await saveFile(path, base64Pdf);

      this.save({
        newUri: OS !== "web" ? "file://" + path : base64Pdf,
        svgElems: this.flattenNestedArr(this.svgElems, true),
        strokes: this.flattenNestedArr(this.state.previousStrokes),
      });
    }
  };

  pointInRectangle = (x, y, r) => {
    var AB = this.vector(r[0], r[1]);
    var AD = this.vector(r[0], r[3]);
    var AM = this.vector(r[0], [x, y]);
    var dotAMAB = this.dot(AM, AB);
    var dotABAB = this.dot(AB, AB);
    var dotAMAD = this.dot(AM, AD);
    var dotADAD = this.dot(AD, AD);

    return (
      0 <= dotAMAB && dotAMAB <= dotABAB && 0 <= dotAMAD && dotAMAD <= dotADAD
    );
  };

  vector = (p1, p2) => {
    return [p2[0] - p1[0], p2[1] - p1[1]];
  };

  dot = (u, v) => {
    return u[0] * v[0] + u[1] * v[1];
  };

  vecEqual = (u, v) => {
    return u[0] == v[0] && u[1] == v[1];
  };

  getRotatedPoints = (elem) => {
    const originX = elem.x + elem.width / 2;
    const originY = elem.y + elem.height / 2;

    const angle = elem.rotation * (Math.PI / 180); // Convert to radians
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);

    const rotatedPoints = [
      rotatePoint(elem.x, elem.y, originX, originY, cos, sin),
      rotatePoint(elem.x + elem.width, elem.y, originX, originY, cos, sin),
      rotatePoint(
        elem.x + elem.width,
        elem.y + elem.height,
        originX,
        originY,
        cos,
        sin
      ),
      rotatePoint(elem.x, elem.y + elem.height, originX, originY, cos, sin),
      // rotatePoint
      rotatePoint(
        elem.x + elem.width / 2,
        elem.y - 40 * (1 / elem.scale),
        originX,
        originY,
        cos,
        sin
      ),
    ];

    return {
      rotatedPoints,
      scaledRotatedPoints: [
        scalePoint(rotatedPoints[0], originX, originY, elem.scale),
        scalePoint(rotatedPoints[1], originX, originY, elem.scale),
        scalePoint(rotatedPoints[2], originX, originY, elem.scale),
        scalePoint(rotatedPoints[3], originX, originY, elem.scale),
        // rotatePoint
        scalePoint(rotatedPoints[4], originX, originY, elem.scale),
      ],
    };
  };

  getTouchedCorner = (x, y, points) =>
    points.findIndex(
      (point) =>
        x >= point[0] - halfSelectRectSize &&
        x <= point[0] - halfSelectRectSize + selectRectSize &&
        y >= point[1] - halfSelectRectSize &&
        y <= point[1] - halfSelectRectSize + selectRectSize
    );

  isTouchInsideSvgElem = (x, y) => {
    let _tappedSvgElemIndex;
    let _tappedSvgElem;
    let _touchedCorner;
    let _rotatedPoints;
    let _scaledRotatedPoints;
    if (
      this.svgElems[this.state.currentPage - 1].length > 0 &&
      this.svgElems[this.state.currentPage - 1].some((elem, i) => {
        if (elem.type === "6" || elem.type === "7") {
          _touchedCorner = this.getTouchedCorner(x, y, [
            [elem.x, elem.y],
            [elem.x2, elem.y2],
            [elem.x2, elem.y2],
            [elem.x, elem.y],
          ]);

          if (
            _touchedCorner !== -1 ||
            this.pointInRectangle(x, y, [
              [elem.x - halfSelectRectSize, elem.y - halfSelectRectSize],
              [elem.x2 + halfSelectRectSize, elem.y2 - halfSelectRectSize],
              [elem.x2 + halfSelectRectSize, elem.y2 - halfSelectRectSize],
              [elem.x - halfSelectRectSize, elem.y + halfSelectRectSize],
            ])
          ) {
            _tappedSvgElemIndex = i;
            _tappedSvgElem = elem;
            return true;
          } else {
            return false;
          }
        } else {
          const { rotatedPoints, scaledRotatedPoints } =
            this.getRotatedPoints(elem);

          _touchedCorner = this.getTouchedCorner(x, y, scaledRotatedPoints);

          if (
            _touchedCorner !== -1 ||
            this.pointInRectangle(x, y, scaledRotatedPoints)
          ) {
            _tappedSvgElemIndex = i;
            _tappedSvgElem = elem;
            _rotatedPoints = rotatedPoints;
            _scaledRotatedPoints = scaledRotatedPoints;
            return true;
          } else {
            return false;
          }
        }
      })
    ) {
      if (_tappedSvgElemIndex !== this.tappedSvgElem.index) {
        this.tappedSvgElem = {
          ..._tappedSvgElem,
          index: _tappedSvgElemIndex,
          isTapped: true,
        };
        this.forceUpdate();
        this.scaledRotatedPoints = _scaledRotatedPoints;
        this.rotatedPoints = _rotatedPoints;
        this.touchedCorner = _touchedCorner;
      } else if (_touchedCorner !== this.touchedCorner) {
        this.scaledRotatedPoints = _scaledRotatedPoints;
        this.rotatedPoints = _rotatedPoints;
        this.touchedCorner = _touchedCorner;
      }
      return true;
    } else {
      if (this.tappedSvgElem.isTapped) {
        this.tappedSvgElem = {};
        this.forceUpdate();
        return false;
      } else {
        return false;
      }
    }
  };

  handleStartShouldSetSvgPanResponse = (evt) => {
    this.isTouchInsideSvgElem(
      evt.nativeEvent.locationX,
      evt.nativeEvent.locationY
    );
    return this.state.lockZoom;
  };

  handleMoveShouldSetSvgPanResponder = () => {
    return this.state.lockZoom;
  };

  handleMoveShouldSetZoomPanResponder = () => {
    return !this.state.lockZoom; // && gestureState.numberActiveTouches === 2;
  };

  onViewZoom = (event, gestureState, zoomableViewEventObject) => {
    this.setState({
      viewZoomLevel: zoomableViewEventObject.zoomLevel,
    });
  };

  onZoomStop = (ref) => {
    this.setState({
      viewZoomLevel: ref.state.scale,
    });
  };

  onPanningStop = (ref) => {
    this.setState({
      viewZoomLevel: ref.state.scale,
    });
  };

  addSvgElem = (type, _props) => {
    if (type === "100") {
      // TODO handle camera
    } else if (type === "101") {
      this.handleAddExternalAtch();
    } else {
      const initialElem = initialSvgElems[type];
      let newElem = {
        type,
        scale: 1,
        rotation: 0,
        ...initialElem,
        ..._props,
      };
      if (initialElem.width) {
        newElem.width = initialElem.width * (1 / this.state.viewZoomLevel);
      }
      if (initialElem.height) {
        newElem.height = initialElem.height * (1 / this.state.viewZoomLevel);
      }
      this.setState({ addingSvgElem: newElem });
      //this.svgElems[this.state.currentPage - 1].push(newElem);
    }
  };

  removeTappedElem = () => {
    if (!isNaN(this.tappedSvgElem.index)) {
      this.svgElems[this.state.currentPage - 1].splice(
        this.tappedSvgElem.index,
        1
      );
      this.tappedSvgElem = {};
      this.forceUpdate();
    }
  };

  setSvgElemCoords = (coords, i, setTappedElem) => {
    // TODO handle text elem box resize when changing text (delete current elem and add a new one with new text?)
    Object.assign(this.svgElems[this.state.currentPage - 1][i], coords);
    if (setTappedElem)
      this.tappedSvgElem = Object.assign(
        this.svgElems[this.state.currentPage - 1][i],
        { ...coords, index: i, isTapped: true }
      );
    this.forceUpdate();
  };

  formNavigate = (route, _history, params) => {
    this.setState({
      formRoute: update(this.state.formRoute, {
        route: { $set: route },
        params: { $set: params || {} },
        history: {
          $push: [
            {
              route: this.state.formRoute.route,
              params: this.state.formRoute.params || {},
            },
          ],
        },
      }),
    });
  };

  formGoBack = (_navigation, amount) => {
    this.setState({
      formRoute: update(this.state.formRoute, {
        $apply: (x) => {
          if (this.state.formRoute.history.length > 0) {
            const amountToGoBack = amount ?? 1;
            return update(x, {
              route: {
                $set: this.state.formRoute.history[
                  this.state.formRoute.history.length - amountToGoBack
                ].route,
              },
              params: {
                $set: this.state.formRoute.history[
                  this.state.formRoute.history.length - amountToGoBack
                ].params,
              },
              history: {
                $splice: [
                  [
                    this.state.formRoute.history.length - amountToGoBack,
                    amountToGoBack,
                  ],
                ],
              },
            });
          } else {
            return { route: null, history: [], params: {} };
          }
        },
      }),
    });
  };

  ADD_OWNER_TO_TMP_ATTACHMENTfn = (payload) => {
    this.setState(ADD_OWNER_TO_TMP_ATTACHMENT(this.state, { payload }));
  };

  REMOVE_OWNER_FROM_TMP_ATTACHMENTfn = (payload) => {
    this.setState(REMOVE_OWNER_FROM_TMP_ATTACHMENT(this.state, { payload }));
  };

  SET_TMP_ATTACHMENT_NAMEfn = () => {
    return null;
  };

  SET_TMP_ATTACHMENT_PROPfn = (payload) => {
    this.setState(SET_TMP_ATTACHMENT_PROP(this.state, { payload }));
  };

  SET_TMP_ATTACHMENTfn = (payload) => {
    this.setState(SET_TMP_ATTACHMENT(this.state, { payload }));
  };

  REMOVE_TMP_ATTACHMENTSfn = (payload) => {
    this.setState(REMOVE_TMP_ATTACHMENTS(this.state, { payload }));
  };

  CLEAR_TMP_ATTACHMENTSfn = (payload) => {
    this.setState(CLEAR_TMP_ATTACHMENTS(this.state, { payload }));
  };

  PUSH_TO_TMP_ATTACHMENTSfn = (payload) => {
    this.setState(PUSH_TO_TMP_ATTACHMENTS(this.state, { payload }));
  };

  REMOVE_TMP_ATTACHMENTfn = (payload) => {
    this.setState(REMOVE_TMP_ATTACHMENT(this.state, { payload }));
  };

  setAttachmentMarker = (valueAttachment, valueKey, newAtch) => {
    const attachmentsToPush = [newAtch];
    this.setState({
      attachments: update(this.state.attachments || [], {
        $push: attachmentsToPush,
      }),
    });
    this.svgElems[this.state.currentPage - 1].push({
      attachmentIds: attachmentsToPush.map((x) => x.id),
      type: "100",
      ...initialSvgElems["100"],
      x: 100,
      y: 100,
      scale: 1,
    });
  };

  handleAddExternalAtch = () => {
    if (OS == "web") {
      this.inputEl.current.click();
    } else {
      onDocumentPicker({
        _navigate: (
          screen,
          navigation,
          {
            attachments,
            valueKey,
            offlineAtch,
            single,
            screenToGoBackTo,
            hideInGallery,
            requiredProps,
          }
        ) =>
          console.warn(
            "whiteboard checkPermission callback",
            screen,
            navigation,
            {
              attachments,
              valueKey,
              offlineAtch,
              single,
              screenToGoBackTo,
              hideInGallery,
              requiredProps,
            }
          ),
        navProps: { hideInGallery: true },
      });
    }
  };

  closePreviewModal = () => {
    this.setState({ previewModal: { visible: false } });
  };

  openPreviewModalWithAtchId = (attachmentId) => {
    this.setState({
      previewModal: {
        visible: true,
        atch: this.state.attachments.find((x) => x.id === attachmentId),
      },
    });
  };

  disableSvgElemAdd = () => {
    this.setState({ addingSvgElem: false });
  };

  setPage = (newPage) => {
    this.setState({
      currentPoints: [],
      newStroke: [],
      pen: new Pen(),
      currentPage: newPage,
    });
  };

  render() {
    const { orientation } = this.state;
    if (this.state.formRoute.route === "addAttachments") {
      return (
        <AddAttachmentsScreen
          // docId={props.docId}
          // docToModify={props.doc}
          // values={props.doc.values}
          offlineAtch={true}
          screenToGoBackTo="create"
          navAttachments={this.state.formRoute.params.attachments}
          fromDocumentScanner={
            this.state.formRoute.params.fromDocumentScanner === true
          }
          single={this.state.formRoute.params.single === true}
          hideInGallery={true}
          requiredProps={this.state.formRoute.params.requiredProps}
          valueKey={this.state.formRoute.params?.valueKey}
          pageW={this.getPageW()}
          pageH={this.getPageH()}
          fullHeight={this.props.fullHeight}
          fullWidth={this.props.fullWidth}
          demo={this.props.demo}
          profile={this.props.profile}
          // TODO save clears all tmopattachments currently
          onSave={this.setAttachmentMarker}
          navigate={this.formNavigate}
          goBack={this.formGoBack}
          //currentTmpAttachmentIds={this.currentTmpAttachmentIds}
          tmpAttachments={this.state.tmpAttachments}
          ADD_OWNER_TO_TMP_ATTACHMENTfn={this.ADD_OWNER_TO_TMP_ATTACHMENTfn}
          REMOVE_OWNER_FROM_TMP_ATTACHMENTfn={
            this.REMOVE_OWNER_FROM_TMP_ATTACHMENTfn
          }
          SET_TMP_ATTACHMENT_NAMEfn={this.SET_TMP_ATTACHMENT_NAMEfn}
          SET_TMP_ATTACHMENT_PROPfn={this.SET_TMP_ATTACHMENT_PROPfn}
          SET_TMP_ATTACHMENTfn={this.SET_TMP_ATTACHMENTfn}
          REMOVE_TMP_ATTACHMENTSfn={this.REMOVE_TMP_ATTACHMENTSfn}
          CLEAR_TMP_ATTACHMENTSfn={this.CLEAR_TMP_ATTACHMENTSfn}
          PUSH_TO_TMP_ATTACHMENTSfn={this.PUSH_TO_TMP_ATTACHMENTSfn}
          REMOVE_TMP_ATTACHMENTfn={this.REMOVE_TMP_ATTACHMENTfn}
        />
      );
    } else if (this.state.formRoute.route === "attachmentsGallery") {
      return (
        <AttachmentsGalleryScreen
          valueKey={this.state.formRoute.params?.valueKey}
          offlineAtch={this.state.formRoute.params.offlineAtch === true}
          onlyImages={this.state.formRoute.params.onlyImages === true}
          single={this.state.formRoute.params.single === true}
          allowedTypes={
            typeof this.state.formRoute.params.allowedTypes === "string"
              ? this.state.formRoute.params.allowedTypes?.split(",")
              : this.state.formRoute.params.allowedTypes
          }
          standAlone={true}
          pageW={this.getPageW()}
          pageH={this.getPageH()}
          fullHeight={this.props.fullHeight}
          fullWidth={this.props.fullWidth}
          // values={props.doc?.values || {}}
          replaceObjectArrItem={() => console.warn("replaceObjectArrItem")}
          addToObjectArr={() => console.warn("addToObjectArr")}
          navigate={this.formNavigate}
          goBack={this.formGoBack}
          modifyValueItem={() => console.warn("modifyValueItem")}
        />
      );
    } else if (this.state.addingImage) {
      /* empty */
    } else {
      if (this.state.imageSize.width) {
        return (
          <ThemeContext.Consumer>
            {({ theme, colors }) => {
              const _height = this.getPageH();
              const _width = this.getPageW();
              return (
                <View
                  style={{
                    flex: 1,
                    height: _height,
                    width: _width,
                    flexDirection: orientation === 1 ? "column" : "row",
                  }}
                >
                  <WhiteboardHeader
                    OS={OS}
                    pageH={_height}
                    pageW={_width}
                    theme={theme}
                    colors={colors}
                    orientation={orientation}
                    tappedSvgElem={this.tappedSvgElem}
                    goBack={this.props.goBack}
                    setTappedElem={this.setTappedElem}
                    handleSave={this.handleSave}
                    pageCount={
                      this.state.hasPages ? this.state.pageCount : null
                    }
                    currentPage={this.state.currentPage}
                    setPage={this.setPage}
                    title={this.props.title}
                  />
                  <Svg
                    style={{
                      height: _height,
                      width: _width,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "#707070",
                    }}
                  >
                    <Defs>
                      <Pattern
                        id="ChessPattern"
                        patternUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="40"
                        height="40"
                      >
                        <Rect
                          x={0}
                          y={0}
                          width="20"
                          height="20"
                          fill={"#ccc"}
                          fillOpacity={1}
                        />
                        <Rect
                          x="20"
                          y="20"
                          width="20"
                          height="20"
                          fill={"#ccc"}
                          fillOpacity={1}
                        />
                      </Pattern>
                    </Defs>

                    <Rect
                      fill="url(#ChessPattern)"
                      x="0"
                      y="0"
                      width={_width}
                      height={_height}
                    />
                  </Svg>
                  <View
                    style={{
                      width:
                        _width -
                        (OS !== "web" ? (orientation === 1 ? 0 : 84) : 0),
                      height:
                        _height -
                        (OS !== "web" ? (orientation === 1 ? 83 : 0) : 63),
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ZoomableView
                      // mobile props
                      minZoom={0.5}
                      zoomStep={0.1}
                      maxZoom={10}
                      initialZoom={this.state.viewZoomLevel}
                      onPanResponderEnd={this.onViewZoom}
                      onMoveShouldSetPanResponder={
                        this.handleMoveShouldSetZoomPanResponder
                      }
                      // web props
                      disabled={this.state.lockZoom}
                      onZoomStop={this.onZoomStop}
                      onPanningStop={this.onPanningStop}
                    >
                      <View
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        {this.props.atch ? (
                          this.props.atch?.dimensions ? (
                            <RNImage
                              resizeMode={"contain"}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: this.state.imageSize.width,
                                height: this.state.imageSize.height,
                                // transform: [
                                //   { rotate: (this.props.rotation || 0) + "deg" },
                                // ],
                              }}
                              source={{
                                uri: this.state.source,
                              }}
                            />
                          ) : (
                            <View
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                //width: this.state.imageSize.width,
                                height: this.state.imageSize.height,
                                overflow: "hidden",
                              }}
                            >
                              <Pdf
                                wrapperStyle={{}}
                                INITIAL_PAGE_WIDTH={this.state.imageSize.width}
                                pdfDocumentOverflow={"hidden"}
                                hideFooter
                                enablePaging
                                page={this.state.currentPage}
                                pageW={this.state.imageSize.width}
                                pageH={this.state.imageSize.height}
                                source={
                                  OS === "web"
                                    ? typeof this.state.source === "string"
                                      ? this.state.source.split(",")[1]
                                      : null
                                    : {
                                        uri: this.state.source.substring(7),
                                      }
                                }
                                onLoadComplete={(err) => {
                                  if (__DEV__)
                                    console.warn("onLoadComplete", err);
                                }}
                                onError={(error) => {
                                  if (__DEV__)
                                    console.warn(
                                      `Whiteboard error loading pdf`,
                                      error
                                    );
                                }}
                                style={{
                                  height: this.state.imageSize.height,
                                  width: this.state.imageSize.width,
                                }}
                              />
                            </View>
                          )
                        ) : (
                          <View
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: this.state.imageSize.width,
                              height: this.state.imageSize.height,
                              backgroundColor: "#fff",
                            }}
                          />
                        )}

                        <>
                          <View
                            style={{
                              width: this.state.imageSize.width,
                              height: this.state.imageSize.height,
                            }}
                          >
                            <View
                              onLayout={this._onLayoutContainer}
                              style={[
                                styles.drawContainer,
                                {
                                  width: this.state.imageSize.width,
                                  height: this.state.imageSize.height,
                                },
                              ]}
                            >
                              <View
                                id={OS !== "web" ? null : "SvgView"}
                                style={styles.svgContainer}
                                //pointerEvents={this.state.lockZoom ? 'none' : 'auto'}
                                {...this._panResponder.panHandlers}
                              >
                                <Svg
                                  id={"mainSvg"}
                                  style={styles.drawSurface}
                                  ref={this.svgRef}
                                >
                                  {/* <Pattern
                                  id="smallGrid"
                                  patternUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width={this.state.smallGrid}
                                  height={this.state.smallGrid}
                                >
                                  <Path
                                    d={`M ${this.state.smallGrid} 0 L 0 0 0 ${this.state.smallGrid}`}
                                    fill="none"
                                    stroke="gray"
                                    strokeWidth="0.5"
                                  />
                                </Pattern>
                                <Pattern
                                  id="grid"
                                  width={this.state.grid}
                                  height={this.state.grid}
                                  patternUnits="userSpaceOnUse"
                                >
                                  <Rect
                                    width={this.state.grid}
                                    height={this.state.grid}
                                    fill="url(#smallGrid)"
                                  />
                                  <Path
                                    d={`M ${this.state.grid} 0 L 0 0 0 ${this.state.grid}`}
                                    fill="none"
                                    stroke="gray"
                                    strokeWidth="1"
                                  />
                                </Pattern>
                                <Rect
                                  width="100%"
                                  height="100%"
                                  fill="url(#grid)"
                                /> */}

                                  <G
                                    scale={
                                      // this.state.imageSize.scale
                                      OS === "web" && this.state.saving
                                        ? 1 / this.state.imageSize.scale
                                        : 1
                                    }
                                  >
                                    {this.state.previousStrokes[
                                      this.state.currentPage - 1
                                    ].map((x) => (
                                      <G
                                        key={x.key}
                                        scale={this.getCorrectUiScale(
                                          x.uiScale
                                        )}
                                      >
                                        <Path
                                          d={x.d}
                                          stroke={x.stroke}
                                          strokeWidth={x.strokeWidth}
                                          fill={x.fill}
                                        />
                                      </G>
                                    ))}
                                    <Path
                                      key={this.state.tracker}
                                      d={this.state.pen.pointsToSvg(
                                        this.state.currentPoints
                                      )}
                                      stroke={this.props.strokeColor}
                                      strokeWidth={this.props.strokeWidth * 5}
                                      fill="none"
                                    />

                                    {this.svgElems[
                                      this.state.currentPage - 1
                                    ].map((x, i) => (
                                      <AnimatedSvgElem
                                        key={i}
                                        index={i}
                                        selected={
                                          this.tappedSvgElem.index === i
                                        }
                                        viewZoomLevel={this.state.viewZoomLevel}
                                        colors={colors}
                                        elem={
                                          this.svgElems[
                                            this.state.currentPage - 1
                                          ][i]
                                        }
                                        setCoords={(coords) =>
                                          this.setSvgElemCoords(coords, i)
                                        }
                                        setParams={(params) =>
                                          this.setState(params)
                                        }
                                        defaultFill={colors.accent}
                                        line={this.line}
                                        setElemCoordinates={
                                          this.setElemCoordinates
                                        }
                                      />
                                    ))}

                                    {this.props.atch ? null : (
                                      <Line
                                        x1={this.state.imageSize.width * 0.1}
                                        x2={
                                          this.state.imageSize.width -
                                          this.state.imageSize.width * 0.1
                                        }
                                        y1={
                                          this.state.imageSize.height -
                                          this.state.imageSize.height * 0.2
                                        }
                                        y2={
                                          this.state.imageSize.height -
                                          this.state.imageSize.height * 0.2
                                        }
                                        stroke={"#000"}
                                        strokeWidth={this.props.strokeWidth + 4}
                                      />
                                    )}
                                  </G>
                                </Svg>
                              </View>
                            </View>
                          </View>
                        </>
                      </View>
                    </ZoomableView>
                  </View>

                  <WhiteboardButtons
                    OS={OS}
                    pageH={_height}
                    pageW={_width}
                    theme={theme}
                    colors={colors}
                    orientation={orientation}
                    tappedSvgElem={this.tappedSvgElem}
                    lockZoom={this.state.lockZoom}
                    svgElem={this.svgElems[this.tappedSvgElem.index]}
                    strokeColor={this.props.strokeColor}
                    strokeWidth={this.props.strokeWidth}
                    clear={this.clear}
                    rewind={this.rewind}
                    setSvgElemCoords={this.setSvgElemCoords}
                    removeTappedElem={this.removeTappedElem}
                    setAlert={this.setAlert}
                    toggleLockZoom={this.toggleLockZoom}
                    setTextModal={this.setTextModal}
                    SET_USER_PROP={this.props.SET_USER_PROP}
                    openPreviewModalWithAtchId={this.openPreviewModalWithAtchId}
                    // svgElemOptionsProps
                    addSvgElem={this.addSvgElem}
                    addingSvgElem={this.state.addingSvgElem}
                    disableSvgElemAdd={this.disableSvgElemAdd}
                    elementsDisabled={this.props.elementsDisabled}
                    allowRotate={this.props.allowRotate}
                  />
                  {OS == "web" ? (
                    <input
                      ref={this.inputEl}
                      style={{ height: 0, width: 0, visibility: "hidden" }}
                      type="file"
                      onChange={handleAttachmentPick({
                        _navigate: this.formNavigate,
                        navProps: {
                          onlyImages: false,
                          navigation: this.props.navigation,
                          valueKey: this.props.valueKey,
                          offlineAtch: false,
                          single: true,
                          hideInGallery: true,
                        },
                        //screenToGoBackTo: props.screenToGoBackTo,
                        //requiredProps: props.requiredProps,
                      })}
                    />
                  ) : null}
                  <WhiteboardModals
                    colors={colors}
                    pageH={_height}
                    pageW={_height}
                    alert={this.state.alert}
                    textModal={this.state.textModal}
                    saving={this.state.saving}
                    hideTextModal={this.hideTextModal}
                    hideAlert={this.hideAlert}
                  />
                  <PreviewModal
                    pageH={_height}
                    pageW={_width * 0.9}
                    attachment={this.state.previewModal.atch}
                    visible={this.state.previewModal.visible}
                    closeModal={this.closePreviewModal}
                  />
                </View>
              );
            }}
          </ThemeContext.Consumer>
        );
      } else {
        return null;
      }
    }
  }
}
const styles = {
  drawContainer: {
    flex: 1,
    display: "flex",
  },
  svgContainer: {
    flex: 1,
  },
  drawSurface: {
    flex: 1,
  },
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ SET_USER_PROP }, dispatch);

const mapStateToProps = (state, ownProps) => {
  return {
    tmpAttachments: state.options.tmpAttachments,
    strokeColor: ownProps.strokeColor ?? state.userInfo.strokeColor ?? "#000",
    strokeWidth: ownProps.strokeWidth ?? state.userInfo.strokeWidth ?? 0.5,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboard);

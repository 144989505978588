import { Platform } from "react-native";
import update, { extend } from "immutability-helper";
import { errorReport, isArrayWithItems, valueMatches } from "../lib/functions";
import { fileSignatureRequest } from "../mocks/fileSignatureRequest";
import { dictPathsUpdateObj } from "./DocsReducer";
import {
  REMOVE_LAYOUT,
  CHANGE_LAYOUTS,
  EDIT_LAYOUT,
  EDIT_LAYOUT_SECTION,
  EDIT_LAYOUT_CELL,
  ADD_SECTION,
  REMOVE_SECTIONS,
  MOVE_SECTIONS,
  REMOVE_CELLS,
  MOVE_CELLS,
  ADD_CELL,
  ADD_LAYOUT_DEPENDENCY,
  EDIT_LAYOUT_MEAS_OBJ,
  RESIZE_ROW_COL,
  UNDO_LAYOUT,
  REDO_LAYOUT,
  DUPLICATE_SECTIONS,
  DUPLICATE_CELLS,
  ADD_SPECIAL_INPUT,
  REMOVE_DEPENDENCIES,
  handleLayoutHistory,
} from "./helpers/LayoutEditorCases";
// const console = require("console");
// const util = require("util");

extend("$auto", function (value, object) {
  return object ? update(object, value) : update({}, value);
});

extend("$autoArray", function (value, object) {
  return object ? update(object, value) : update([], value);
});

const INITIAL_STATE = {
  layoutEditor: {},
  customers: {},
  eProviders: [],
  measuringDevices: [],
  standards: [],
  users: [],
  voltages: [],
  mainFuses: [],
  reasons: [],
  fetchedAttachments: {},
  gGgLComparablesFast: {
    2: 16,
    4: 32,
    6: 46.5,
    10: 85,
    16: 110,
    20: 145,
    25: 180,
    32: 270,
    35: 287,
    40: 315,
    50: 470,
    63: 550,
    80: 840,
    100: 1000,
    125: 1450,
    160: 1600,
    200: 2100,
    250: 2800,
    315: 3700,
    400: 4800,
    500: 6400,
    630: 8500,
  },
  gGgLComparablesSlow: {
    2: 9,
    4: 18,
    6: 28,
    10: 46.5,
    16: 65,
    20: 85,
    25: 110,
    32: 150,
    35: 165,
    40: 190,
    50: 250,
    63: 320,
    80: 425,
    100: 580,
    125: 715,
    160: 950,
    200: 1250,
    250: 1650,
    315: 2200,
    400: 2840,
    500: 3800,
    630: 5100,
  },
  shortCircuitFormulas: {
    "B 0,4s": 5,
    "B 5,0s": 5,
    "C 0,4s": 10,
    "C 5,0s": 10,
    "D 0,4s": 20,
    "D 5,0s": 20,
    "K/G 0,4s": 14,
    "K/G 5,0s": 14,
  },
};

function getMergedLayout(oldLayout = {}, newLayout = {}) {
  const newLayoutIsEditingVersion = newLayout.layoutVersion === -1;
  let updateObj = newLayoutIsEditingVersion
    ? {}
    : {
        type: { $set: newLayout.type || oldLayout.type },
        hiddenFrom: { $set: newLayout.hiddenFrom || oldLayout.hiddenFrom },
        published: { $set: newLayout.published || oldLayout.published },
        lastModified: {
          $set: newLayout.lastModified || oldLayout.lastModified,
        },
        layoutValueLastModified: {
          $set:
            newLayout.layoutValueLastModified ||
            oldLayout.layoutValueLastModified,
        },
        editable: { $set: newLayout.editable || oldLayout.editable },
        global: { $set: newLayout.global || oldLayout.global },
        sharedTo: { $set: newLayout.sharedTo || oldLayout.sharedTo },
        category: { $set: newLayout.category || oldLayout.category },
      };

  updateObj.versions = (tmpVersions) =>
    update(tmpVersions || {}, {
      $merge: newLayout?.versions || {},
    });

  return update(oldLayout, updateObj);
}

function getNewLayoutObj(layouts = {}, payload = {}) {
  const _data = Object.keys(payload).reduce(
    (newLayoutObj, layoutKey) => {
      if (layouts[layoutKey]) {
        newLayoutObj = update(newLayoutObj, {
          [layoutKey]: {
            $set: getMergedLayout(layouts[layoutKey], payload[layoutKey]),
          },
        });
      } else {
        newLayoutObj = update(newLayoutObj, {
          [layoutKey]: { $set: payload[layoutKey] },
        });
      }
      return newLayoutObj;
    },
    // set filesignature doc
    update(layouts, {
      ["docLayouts/0"]: {
        $set: {
          versions: {
            1: fileSignatureRequest,
          },
          type: {
            i18n: "file",
          },
          global: true,
          extraDoc: true,
        },
      },
    })
  );
  return _data;
}

export function getLayouts(state = {}, data) {
  if (data) {
    return getNewLayoutObj(state.layouts, data.layouts);
  } else {
    return {};
  }
}

function mergeArrays(a = [], b = []) {
  let arr = [];

  a.forEach((x) => {
    if (!arr.includes(x)) arr.push(x);
  });

  b.forEach((x) => {
    if (!arr.includes(x)) arr.push(x);
  });

  return arr;
}

function mergeOptions(state = {}, data = {}, cleanSignIn) {
  return Object.entries(data).reduce((obj, [key, value]) => {
    return update(obj, {
      [key]: {
        $apply: (x) => {
          if (!cleanSignIn && Array.isArray(x)) return mergeArrays(x, value);
          else return value;
        },
      },
    });
  }, state);
}

export const ADD_OWNER_TO_TMP_ATTACHMENT = (state, action) => {
  const { index, user } = action.payload;

  const indexToUse = state?.tmpAttachments?.[index]?.owners
    ? state.tmpAttachments[index].owners.findIndex((x) => x === user)
    : -1;
  if (indexToUse === -1) {
    return update(state, {
      tmpAttachments: (tmpTmpAttachments) =>
        update(tmpTmpAttachments || [], {
          [index]: {
            owners: (tmpOwners) =>
              update(tmpOwners || [], {
                $push: [user],
              }),
          },
        }),
    });
  } else return state;
};

export const REMOVE_OWNER_FROM_TMP_ATTACHMENT = (state, action) => {
  const { index, user } = action.payload;

  const indexToUse = state?.tmpAttachments?.[index]?.owners
    ? state.tmpAttachments[index].owners.findIndex((x) => x === user)
    : -1;
  if (indexToUse !== -1) {
    return update(state, {
      tmpAttachments: (tmpTmpAttachments) =>
        update(tmpTmpAttachments || [], {
          [index]: {
            owners: (tmpOwners) =>
              update(tmpOwners || [], {
                $splice: [[indexToUse, 1]],
              }),
          },
        }),
    });
  } else return state;
};

export const SET_TMP_ATTACHMENT_PROP = (state, action) => {
  const { fullObj, index, prop, value, isArray, merge } = action.payload;

  const newState = update(state, {
    tmpAttachments: (tmpTmpAttachments) =>
      update(tmpTmpAttachments || [], {
        $apply: (x) => {
          const _index =
            index ?? x.findIndex((item) => item.tmpName === fullObj.tmpName);
          if (_index === -1) {
            console.warn(
              "SET_TMP_ATTACHMENT_PROP trying to edit missing attachment",
              { _index, fullObj, value }
            );
            return x;
          } else {
            return update(x, {
              [_index]: merge
                ? { $merge: value }
                : { [prop]: isArray ? { $push: [value] } : { $set: value } },
            });
          }
        },
      }),
  });
  return newState;
};

export const SET_TMP_ATTACHMENT = (state, action) => {
  const { index, value } = action.payload;

  const newState = update(state, {
    tmpAttachments: (tmpTmpAttachments) =>
      update(tmpTmpAttachments || [], {
        [index]: { $set: value },
      }),
  });
  return newState;
};

export const REMOVE_TMP_ATTACHMENTS = (state, action) => {
  const { atchToRemove = [] } = action.payload;

  return update(state, {
    tmpAttachments: (tmpTmpAttachments) =>
      update(tmpTmpAttachments || [], {
        $apply: function (x) {
          return x.filter(
            (atch) => !atchToRemove.some((x) => x.uri === atch.uri)
          );
        },
      }),
  });
};

export const CLEAR_TMP_ATTACHMENTS = (state) => {
  return update(state, {
    tmpAttachments: { $set: [] },
  });
};

export const PUSH_TO_TMP_ATTACHMENTS = (state, action) => {
  const { attachments = [] } = action.payload;

  let attachmentsToPush = attachments;
  let removedCount = 0;

  if (state.tmpAttachments) {
    for (let i = 0; i < attachments.length; i++) {
      const atch = attachments[i];

      let index = state.tmpAttachments.findIndex(
        (x) => x.uri === atch.uri && x.tmpName == atch.tmpName
      );
      if (index !== -1) {
        attachmentsToPush = update(attachmentsToPush, {
          $splice: [[i - removedCount, 1]],
        });
        removedCount++;
      }
    }
  }

  return update(state, {
    tmpAttachments: (tmpTmpAttachments) =>
      update(tmpTmpAttachments || [], {
        $push: attachmentsToPush,
      }),
  });
};

export const REMOVE_TMP_ATTACHMENT = (state, action) => {
  return update(state, {
    tmpAttachments: (tmpTmpAttachments) =>
      update(tmpTmpAttachments || [], {
        $apply: (x) => {
          return x.filter((atch) => atch.uri !== action.payload);
        },
      }),
  });
};

const mergeAttachments = (state, attachments) => {
  // handle locally modified attachments with localFileNotSynced
  let newAttachments = state.attachments || {};
  if (typeof attachments === "object") {
    Object.keys(attachments).forEach((x) => {
      newAttachments[x] = {
        ...attachments[x],
        svgElems: state.attachments?.[x]?.localFileNotSynced
          ? state.attachments[x].svgElems
          : attachments[x].svgElems,
        svgStrokes: state.attachments?.[x]?.localFileNotSynced
          ? state.attachments[x].svgStrokes
          : attachments[x].svgStrokes,
        localFileNotSynced:
          attachments[x].localFileNotSynced ??
          state.attachments?.[x]?.localFileNotSynced,
      };
    });
  }

  return newAttachments;
};

const SET_LAYOUTS = (state, action) => {
  const { override, layouts } = action.payload;

  if (override) {
    return update(state, {
      lastModifiedLayouts: {
        $apply: (x) => layouts.lastModifiedLayouts || x,
      },
      lastModifiedEditableLayouts: {
        $apply: (x) => layouts.lastModifiedEditableLayouts || x,
      },
      layouts: {
        $merge: update(layouts.layouts || {}, {
          ["docLayouts/0"]: {
            $set: {
              $set: {
                versions: {
                  1: fileSignatureRequest,
                },
                type: {
                  i18n: "file",
                },
                global: true,
                extraDoc: true,
              },
            },
          },
        }),
      },
    });
  } else {
    return {
      ...state,
      layouts: getLayouts(state, layouts),
      lastModifiedLayouts:
        layouts.lastModifiedLayouts || state.lastModifiedLayouts,
      lastModifiedEditableLayouts:
        layouts.lastModifiedEditableLayouts ||
        state.lastModifiedEditableLayouts,
    };
  }
};
export default function optionsReducer(state = INITIAL_STATE || null, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return INITIAL_STATE;
    } else if (action.type === "SIGN_OUT") {
      if (Platform.OS === "web") {
        return INITIAL_STATE;
      } else {
        return state;
      }
    } else if (action.type === "OPTIONS_CHECK") {
      return update(state, { checking: { $set: action.payload } });
    } else if (action.type === "RECEIVE_USER_DATA") {
      const { data, cleanSignIn } = action.payload;
      const { options = {}, customers } = data;

      // remove metadata deletion in the future
      if (customers) {
        delete customers["@metadata"];
      }
      return {
        ...state,
        ...mergeOptions(state, options.options, cleanSignIn),
        lastModified: options?.lastModified || state.lastModified,
        miscLastModified: options?.miscLastModified || state.miscLastModified,
        // eProviders: data.options?.eProviders,
        // measuringDevices: data.options?.measuringDevices,
        // standards: data.options?.standards,
        // reasons: data.options?.reasons,
        // voltages: data.options?.voltages.sort(naturalCompare),
        // mainFuses: data.options?.mainFuses.sort(naturalCompare),
        // protections: data.options?.protections.sort(naturalCompare),
        attachments: cleanSignIn
          ? data.attachments
          : update(state.attachments || {}, {
              $set: mergeAttachments(state, data.attachments || {}),
            }), //update(state.attachments || {}, { $merge: data.attachments || {} }),
        sites: cleanSignIn
          ? data.sites
          : update(state.sites || {}, { $merge: data.sites || {} }),
        mainContractors: cleanSignIn
          ? data.mainContractors
          : update(state.mainContractors || {}, {
              $merge: data.mainContractors || {},
            }),
        holders: cleanSignIn
          ? data.holders
          : update(state.holders || {}, {
              $merge: data.holders || {},
            }),
        customers: cleanSignIn
          ? customers
          : update(state.customers || {}, { $merge: customers || {} }),

        users: data.users,

        shortCircuitFormulas: options?.shortCircuitFormulas,
        gGgLComparablesSlow: options?.gGgLComparablesSlow,
        gGgLComparablesFast: options?.gGgLComparablesFast,

        // customerLastModified:
        //   data?.customerLastModified || state.customerLastModified,

        // globalLastModified:
        //   data?.globalLastModified || state.globalLastModified,
      };
    } else if (action.type === "UPDATE_OPTIONS") {
      const { data, layoutsData } = action.payload;
      const options = data?.options || {};
      const customers = data?.customers || {};
      // remove metadata deletion in the future
      if (customers) {
        delete customers["@metadata"];
      }

      const newState = {
        ...state,
        ...mergeOptions(state, options.options),
        lastModified: options?.lastModified || state.lastModified,
        miscLastModified:
          options?.miscLastModified &&
          Object.keys(options.miscLastModified).length > 0
            ? options?.miscLastModified
            : state.miscLastModified,
        attachments: update(state.attachments || {}, {
          $set: mergeAttachments(state, data?.attachments || {}),
        }), //update(state.attachments || {}, { $merge: data.attachments || {}, }),
        sites: update(state.sites || {}, { $merge: data?.sites || {} }),
        mainContractors: update(state.mainContractors || {}, {
          $merge: data?.mainContractors || {},
        }),
        holders: update(state.holders || {}, {
          $merge: data?.holders || {},
        }),
        customers: update(state.customers || {}, { $merge: customers }),

        users: data?.users || state.users,
      };
      if (layoutsData)
        return SET_LAYOUTS(newState, { payload: { layouts: layoutsData } });
      return newState;
      //}
    } else if (action.type === "ADD_MODULAR_OPTION") {
      const { prop, value } = action.payload;
      return update(state, {
        [prop]: (tmpProp) => update(tmpProp || [], { $push: [value] }),
      });
    } else if (action.type === "ADD_CUSTOMER") {
      const { key, obj } = action.payload;
      return update(state, { customers: { $merge: { [key]: obj } } });
    } else if (action.type === "REFRESH_CUSTOMERS") {
      let customers = action.payload;
      delete customers["@metadata"];
      return {
        ...state,
        customers: { ...state.customers, ...customers },
      };
    } else if (action.type === "ADD_PICKER_OBJ_OPTION") {
      const { prop, key, obj } = action.payload;
      return update(state, {
        [prop]: (tmp) => update(tmp || {}, { $merge: { [key]: obj } }),
      });
    } else if (action.type === "REMOVE_PICKER_OBJ_OPTION") {
      const { id, prop } = action.payload;
      return update(state, { [prop]: { $unset: [id] } });
    } else if (action.type === "MODIFY_PICKER_OBJ_OPTION") {
      const { id, obj, prop } = action.payload;
      return update(state, { [prop]: { [id]: { $set: obj } } });
    } else if (action.type === "REFRESH_PICKER_OBJ_OPTIONS") {
      const { prop, data } = action.payload;

      if (data) {
        return update(state, {
          [prop]: (tmp) => update(tmp || {}, { $merge: data }),
        });
      }
    } else if (action.type === "MODIFY_PICKER_OPTION") {
      const { prop, oldId, newId, obj } = action.payload;

      return update(state, {
        [prop]:
          oldId && newId && newId !== oldId
            ? {
                $unset: [oldId],
                [newId]: { $set: { ...obj, id: newId } },
              }
            : {
                [oldId]: { $set: { ...obj, id: oldId } },
              },
      });
    } else if (action.type === "REFRESH_OPTIONS") {
      const prop =
        action.payload.option.charAt(0).toLowerCase() +
        action.payload.option.slice(1);
      if (action.payload.isUserOption) {
        return update(state, { [prop]: { $set: action.payload.values } });
      } else {
        return update(state, {
          [prop]: { $set: action.payload.values },
          lastModified: {
            $auto: {
              [action.payload.option]: { $set: action.payload.timeStamp },
            },
          },
        });
      }
    } else if (action.type === "MODIFY_OPTION") {
      const { prop, oldValue, value } = action.payload;
      return update(state, {
        [prop]: {
          $apply: (x) => {
            const index = x.findIndex((y) => y === oldValue);

            if (index !== -1) {
              return update(x, { $splice: [[index, 1, value]] });
            } else return x;
          },
        },
      });
    } else if (action.type === "REMOVE_OPTION") {
      const { prop, oldValue } = action.payload;
      if (state[prop]) {
        return update(state, {
          [prop]: (tmpProp) =>
            update(tmpProp || [], {
              $apply: (x) => {
                const index = x.findIndex((y) => y === oldValue);

                if (index !== -1) {
                  return update(x, { $splice: [[index, 1]] });
                } else return x;
              },
            }),
        });
      }
    } /*DEPRECATED*/ else if (action.type === "DEPRECATEDMODIFY_OPTION") {
      const { prop, index, value } = action.payload;
      const propName =
        prop === "measuringDevices" || prop === "standards" ? prop : prop + "s";
      return update(state, { [propName]: { [index]: { $set: value } } });
    } /*DEPRECATED*/ else if (action.type === "DEPRECATEDREMOVE_OPTION") {
      const { prop, index } = action.payload;
      const propName = prop.slice(prop.length - 1) === "s" ? prop : prop + "s";
      if (state[propName])
        return update(state, {
          [propName]: (tmpProp) =>
            update(tmpProp || [], { $splice: [[index, 1]] }),
        });
    } /*DEPRECATED*/ else if (action.type === "MODIFY_CUSTOMER") {
      const { customerId, customer } = action.payload;
      return update(state, { customers: { [customerId]: { $set: customer } } });
    } /*DEPRECATED*/ else if (action.type === "REMOVE_CUSTOMER") {
      return update(state, { customers: { $unset: [action.id] } });
    } /*DEPRECATED*/ else if (action.type === "MODIFY_CUSTOMER_ID") {
      const { oldCustomerId, newCustomerId, customerObj } = action.payload;

      return update(state, {
        customers: {
          $unset: [oldCustomerId],
          [newCustomerId]: { $set: customerObj },
        },
      });
    } /*DEPRECATED*/ else if (action.type === "DEPRECATEDADD_OPTION") {
      const { prop, value } = action.payload;
      const propName =
        prop === "measuringDevices" || prop === "standards" ? prop : prop + "s";
      return update(state, { [propName]: { $push: [value] } });
    } else if (action.type === "PUSH_TO_TMP_ATTACHMENTS") {
      return PUSH_TO_TMP_ATTACHMENTS(state, action);
    } else if (action.type === "REMOVE_TMP_ATTACHMENT") {
      return REMOVE_TMP_ATTACHMENT(state, action);
    } else if (action.type === "ADD_OWNER_TO_TMP_ATTACHMENT") {
      return ADD_OWNER_TO_TMP_ATTACHMENT(state, action);
    } else if (action.type === "REMOVE_OWNER_FROM_TMP_ATTACHMENT") {
      return REMOVE_OWNER_FROM_TMP_ATTACHMENT(state, action);
    } else if (action.type === "SET_TMP_ATTACHMENT_PROP") {
      return SET_TMP_ATTACHMENT_PROP(state, action);
    } else if (action.type === "SET_TMP_ATTACHMENT") {
      return SET_TMP_ATTACHMENT(state, action);
    } else if (action.type === "REMOVE_TMP_ATTACHMENTS") {
      return REMOVE_TMP_ATTACHMENTS(state, action);
    } else if (action.type === "CLEAR_TMP_ATTACHMENTS") {
      return CLEAR_TMP_ATTACHMENTS(state, action);
    } else if (action.type === "ADD_TO_OPTION_ROOT_ARR") {
      const { arrName, id, prop, value, addMultiple } = action.payload;
      return update(state, {
        [arrName]: {
          [id]: {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    if (
                      !arr.some((x) => valueMatches(action.payload, x, value))
                    ) {
                      return update(arr, {
                        $push: addMultiple ? value : [value],
                      });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          },
        },
      });
    } else if (action.type === "REMOVE_FROM_OPTION_ROOT_ARR") {
      const { arrName, id, prop, value } = action.payload;
      return update(state, {
        [arrName]: {
          [id]: {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) =>
                      valueMatches(action.payload, x, value)
                    );

                    if (index !== -1) {
                      return update(arr, { $splice: [[index, 1]] });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          },
        },
      });
    } else if (action.type === "ADD_ATTACHMENT") {
      const { attachment, overrideSvgProps } = action.payload;

      // return update(state, {
      //   attachments: (_tmpAttachments) =>
      //     update(_tmpAttachments || {}, {
      //       [attachment.id]: { $set: attachment },
      //     }),
      // });
      return update(state, {
        attachments: (_tmpAttachments) =>
          update(_tmpAttachments || {}, {
            [attachment.id]: {
              $apply: (x) => {
                const localFileNotSynced =
                  attachment.localFileNotSynced ?? x?.localFileNotSynced;
                return {
                  ...attachment,
                  svgElems:
                    !overrideSvgProps && localFileNotSynced
                      ? x?.svgElems
                      : attachment.svgElems,
                  svgStrokes:
                    !overrideSvgProps && localFileNotSynced
                      ? x?.svgStrokes
                      : attachment.svgStrokes,
                  localFileNotSynced: localFileNotSynced,
                };
              },
            },
          }),
      });
    } else if (action.type === "UPDATE_ATTACHMENTS") {
      const { attachments } = action.payload;

      return update(state, {
        attachments: () =>
          update(state.attachments || {}, {
            $set: mergeAttachments(state, attachments || {}),
          }),
        // update(_tmpAttachments || {}, {
        //   $merge: attachments,
        // }),
      });
    } else if (action.type === "SET_ATTACHMENTS_PROPS") {
      return update(state, {
        attachments: (_tmpAttachments) =>
          update(
            _tmpAttachments || {},
            action.payload.reduce((prev, cur) => {
              prev[cur.id] = (tmp) =>
                update(tmp || {}, { [cur.prop]: { $set: cur.value } });
              return prev;
            }, {})
          ),
      });
    } else if (action.type === "SET_ATTACHMENTS_FETCHED") {
      const { key, value } = action.payload;

      return update(state, {
        fetchedAttachments: (tmpAttachmentsLoaded) =>
          update(tmpAttachmentsLoaded || {}, {
            [key]: { $set: value },
          }),
      });
    } else if (action.type === "MERGE_TO_ATTACHMENTS_FETCHED") {
      const { objectToMerge } = action.payload;

      return update(state, {
        fetchedAttachments: (tmpAttachmentsLoaded) =>
          update(tmpAttachmentsLoaded || {}, {
            $merge: objectToMerge,
          }),
      });
    } else if (action.type === "REMOVE_ATTACHMENTS") {
      const { keysToRemove = [] } = action.payload;

      return update(state, {
        fetchedAttachments: (tmpAttachmentsLoaded) =>
          update(tmpAttachmentsLoaded || {}, {
            $unset: keysToRemove,
          }),
        attachments: (tmpAttachments) =>
          update(tmpAttachments || {}, {
            $unset: keysToRemove,
          }),
      });
    } else if (action.type === "SET_COMPANY_LOGO_LAST_MODIFIED") {
      return update(state, {
        miscLastModified: (tmpLastModified) =>
          update(tmpLastModified || {}, {
            companyLogo: { $set: action.payload },
          }),
      });
    } else if (action.type === "SET_LAYOUTS") {
      return SET_LAYOUTS(state, action);
    } else if (action.type === "SET_BASE_DOC_VERSION") {
      const { layoutId, version, docLayout } = action.payload;

      return update(state, {
        layouts: (tmp) =>
          update(tmp || {}, {
            [layoutId]: (_tmp) =>
              update(_tmp || {}, {
                versions: (tmpVersions) =>
                  update(tmpVersions || {}, {
                    [version]: { $set: docLayout },
                  }),
              }),
          }),
      });
    } else if (action.type === "UPDATE_PATHS") {
      return update(state, {
        attachments: dictPathsUpdateObj(action.payload),
      });
    } else if (action.type === "UPDATE_OPTIONS_IDS") {
      const { updateArray, prop } = action.payload;

      let idsToRemove = [];
      const updateObj = updateArray.reduce((acc, newIdObj) => {
        idsToRemove.push(newIdObj.oldId);
        acc[newIdObj.id] = { $set: state[prop][newIdObj.oldId] };
        return acc;
      }, {});

      return update(update(state, updateObj), {
        $unset: idsToRemove,
      });
    } else if (action.type === "REMOVE_LAYOUT") {
      return REMOVE_LAYOUT(
        update(state, {
          layouts: { $auto: { $unset: [action.payload.id] } },
        }),
        action
      );
    }
    // EDITOR CASES
    else if (action.type === "SET_LAYOUT_BASE_PROPS") {
      const newState = update(state, {
        layouts: {
          [action.payload.layoutId]: {
            $merge: action.payload.data,
          },
        },
      });
      return newState;
    } else if (action.type === "CHANGE_LAYOUTS") {
      const newState = CHANGE_LAYOUTS(state, action);
      if (isArrayWithItems(action.payload.layouts)) {
        const layoutIds = action.payload.layouts.map((x) => x.layoutId);
        return update(newState, {
          history: {
            $auto: {
              $unset: layoutIds,
            },
          },
          future: {
            $auto: {
              $unset: layoutIds,
            },
          },
        });
      } else {
        return newState;
      }
    } else if (action.type === "EDIT_LAYOUT") {
      const newState = EDIT_LAYOUT(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "EDIT_LAYOUT_SECTION") {
      const newState = EDIT_LAYOUT_SECTION(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "EDIT_LAYOUT_CELL") {
      const newState = EDIT_LAYOUT_CELL(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "ADD_SECTION") {
      const newState = ADD_SECTION(action, state);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "REMOVE_SECTIONS") {
      const newState = REMOVE_SECTIONS(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "MOVE_SECTIONS") {
      const newState = MOVE_SECTIONS(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "REMOVE_CELLS") {
      const newState = REMOVE_CELLS(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "MOVE_CELLS") {
      const newState = MOVE_CELLS(action, state);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "ADD_CELL") {
      const newState = ADD_CELL(action, state);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "ADD_LAYOUT_DEPENDENCY") {
      const newState = ADD_LAYOUT_DEPENDENCY(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "EDIT_LAYOUT_MEAS_OBJ") {
      const newState = EDIT_LAYOUT_MEAS_OBJ(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "RESIZE_ROW_COL") {
      const newState = RESIZE_ROW_COL(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "DUPLICATE_SECTIONS") {
      const newState = DUPLICATE_SECTIONS(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "DUPLICATE_CELLS") {
      const newState = DUPLICATE_CELLS(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "ADD_SPECIAL_INPUT") {
      const newState = ADD_SPECIAL_INPUT(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "REMOVE_DEPENDENCIES") {
      const newState = REMOVE_DEPENDENCIES(state, action);
      return handleLayoutHistory(state, newState, action.payload.layoutId);
    } else if (action.type === "UNDO_LAYOUT") {
      return UNDO_LAYOUT(state, action);
    } else if (action.type === "REDO_LAYOUT") {
      return REDO_LAYOUT(state, action);
    }

    return state;
  } catch (error) {
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "OptionsReducer",
        errorParams: {
          type: action?.type,
          payload: `Error: ${error}, Reducer: OptionsReducer, Action: ${
            action.type
          }, Payload: ${
            action.payload ? JSON.stringify(action.payload) : "no payload"
          }`,
        },
      });
    } else {
      console.error(error);
      console.error(
        `Error: ${error}, Reducer: OptionsReducer, Action: ${
          action.type
        }, Payload: ${
          action.payload ? JSON.stringify(action.payload) : "no payload"
        }`
      );
    }
    return state;
  }
}

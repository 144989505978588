import "react";
import mainLogo from "../assets/full-logo-light.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function InfoFooter(props) {
  const { t } = useTranslation();
  return (
    <div className={"infoFooter"}>
      {props.extraText ? (
        <p className={"infoTextSmall"}>
          <strong>{props.extraText}</strong>
        </p>
      ) : null}

      <img className={"infoFooterLogo"} src={mainLogo} alt="Logo" />

      <p className={"infoTextSmall"}>
        <strong>{t("efficientDocumentation")}</strong>
      </p>

      <p className={"infoTextMinimal"} style={{ color: "#808080" }}>
        <strong>Document Ocean Oy</strong>
      </p>
      <p className={"infoTextMinimal"} style={{ color: "#808080" }}>
        {`${t("companyId")}: 3086017-5`}
      </p>
      <p className={"infoTextMinimal"} style={{ color: "#808080" }}>
        Puolikkotie 8, 02230 Espoo
      </p>
      <p className={"infoTextMinimal"}>
        <Link to={"/privacy-policy"} style={{ color: "#808080" }}>
          {t("privacyStatement")}
        </Link>
      </p>
      <p className={"infoTextMinimal"}>
        <Link to={"/tos"} style={{ color: "#808080" }}>
          {t("agreementAndTOS")}
        </Link>
      </p>
    </div>
  );
}

import { useState } from "react";
import StretchButton from "./StretchButton";
import MenuComponent from "./MenuComponent";

export default function Dropdown(props) {
  const [menu, setMenu] = useState({ visible: false });

  const openMenu = (event) => {
    (props.setMenu || setMenu)({
      visible: true,
      textProp: "title",
      action: props.action,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      data: props.data,
    });
  };

  return (
    <>
      <StretchButton
        loading={props.loading}
        disabled={props.disabled}
        iconWhenLoading={props.iconWhenLoading}
        rounded={props.rounded}
        buttonStyle={props.buttonStyle}
        buttonTextStyle={props.buttonTextStyle}
        title={props.value}
        onPress={openMenu}
      />

      <MenuComponent
        rest={menu}
        handleClose={() => setMenu({ visible: false })}
      />
    </>
  );
}

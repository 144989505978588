import "react";
import { Text, View } from "react-native";

const Title = ({ title, colors, theme, disabled }) => (
  <View
    style={[
      {
        width: "40%",
        backgroundColor: colors.primary,
        margin: 8, //wp(1),
        padding: 8, //wp(2),
      },
    ]}
  >
    <Text style={[theme.boldText, { opacity: disabled ? 0.6 : 1 }]}>
      {title}
    </Text>
  </View>
);

export default function DualInputTitle({
  title1,
  title2,
  colors,
  theme,
  hp,
  wp,
  viewKey,
  disabled,
}) {
  return (
    <View
      key={viewKey}
      testID={viewKey}
      style={[
        {
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        },
      ]}
    >
      <Title
        disabled={disabled}
        colors={colors}
        theme={theme}
        wp={wp}
        hp={hp}
        title={`${title1}`}
      />
      <Title
        disabled={disabled}
        colors={colors}
        theme={theme}
        wp={wp}
        hp={hp}
        title={`${title2}`}
      />
    </View>
  );
}

import { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";
import update from "immutability-helper";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, hp, wp, showToast, fullHp } from "../lib/helperFns";
import { onTextChange } from "../lib/functions";
import { apiRequestWithToken } from "../lib/api";
import { ResizableBox } from "react-resizable";
import StretchButton from "./StretchButton";
import Modal from "./Modal";
import TextInput from "./TextInput";

function simpleModalReducer(
  state = {
    err: "",
    text: "",
  },
  action
) {
  switch (action.type) {
    case "set":
      return update(state, { $merge: action.payload });
  }
  return state;
}
export default function SimpleModal(props) {
  const { t } = useTranslation();
  const [state, dispatchState] = useReducer(simpleModalReducer, {
    err: "",
    text: "",
    height: props.multiline ? 200 : 60,
    width: 300,
  });

  const pageH = props.pageH ?? fullHp(100);

  const setState = (payload) => {
    dispatchState({
      type: "set",
      payload,
    });
  };

  function onResize(event, { size }) {
    setState({
      height: size.height,
      width: size.width,
    });
  }

  const renderResizeHandle = (resizeHandle) => {
    return (
      <span
        className={`react-resizable-handle react-resizable-handle-${resizeHandle}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    );
  };

  const textInput = useCallback((node) => {
    if (node !== null) {
      node?.focus();
    }
  }, []);

  useEffect(() => {
    if (props.visible) {
      setState({
        text: props.value,
        height: props.multiline ? 200 : 60,
        width: 300,
      });
    }
  }, [props.visible]);

  const handleSave = (text) => {
    if (props.fn) {
      props.fn(text);
    } else {
      props.onSave(text);
    }
    setState({
      err: "",
      text: "",
    });
    if ((props.closeOnRighButtonPress ?? true) && props.onToggleModal)
      props.onToggleModal();
  };
  const saveToApi = (text) => {
    apiRequestWithToken(text, props.url).then((res) => {
      if (res.status === 200 || res.status === 204) {
        handleSave(text);
      } else {
        showToast(t("valueSaveFailed"));
      }
    });
  };
  const onSavePress = () => {
    if (props.validateField) {
      const err = props.validateField(state.text);
      if (err) {
        setState({
          err: props.errMsg
            ? props.errMsg || t("checkValue")
            : props.errMessages && props.errMessages[err]
            ? props.errMessages[err]
            : t("checkValue"),
        });
      } else {
        if (props.url) {
          saveToApi(state.text);
        } else {
          handleSave(state.text);
        }
      }
    } else {
      if (props.url) {
        saveToApi(state.text);
      } else {
        handleSave(state.text);
      }
    }
  };

  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.onToggleModal}
          onBackdropPress={props.onToggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={[theme.modalInputContainer, { paddingBottom: 0 }]}>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={[
                  {
                    padding: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={theme.text}>{props.title}</Text>
              </View>
            </View>

            {props.err || state.err ? (
              <Text style={theme.warningLabel}>{props.err || state.err}</Text>
            ) : null}

            <View style={theme.rowContainer}>
              <ResizableBox
                width={state.width}
                height={state.height}
                onResize={onResize}
                handle={renderResizeHandle}
                resizeHandles={["sw"]}
                style={{ flex: 1, height: "100%", width: "100%" }}
                minConstraints={[10, 50]}
                maxConstraints={
                  Platform.OS === "web"
                    ? [Infinity, pageH * 0.85]
                    : [hp(90), wp(90)]
                }
              >
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    height: "100%",
                    width: "100%",
                    paddingBottom: 8,
                    backgroundColor: colors.lightBg,
                  }}
                >
                  <TextInput
                    orientation={orientation}
                    disableFullscreenUI={false}
                    ref={textInput}
                    editable={!props.disabled}
                    style={[
                      theme.textInput,
                      theme.text,
                      Platform.OS === "web"
                        ? { height: "100%", width: "100%", outline: "none" }
                        : {},
                    ]}
                    // style={
                    //   props.multiline
                    //     ? theme.multilineModalTextInput
                    //     : theme.modalTextInput
                    // }
                    placeholder={"-"}
                    placeholderTextColor={"#aaa"}
                    defaultValue={props.value}
                    value={state.text}
                    onChangeText={(text) => {
                      let _value = props.multiline
                        ? text
                        : onTextChange(text, props.numeric, props.maxDecimals);
                      setState({ err: "", text: _value });
                    }}
                    multiline={props.multiline}
                    keyboardType={
                      props.multiline
                        ? "default"
                        : props.keyboardType ??
                          (props.numeric ? "decimal-pad" : "default")
                    }
                    selectTextOnFocus={props.selectTextOnFocus}
                    onSubmitEditing={props.multiline ? null : onSavePress}
                    blurOnSubmit={
                      props.multiline ? null : props.blurOnSubmit ?? true
                    }
                  />
                </View>
              </ResizableBox>
            </View>

            <View style={[theme.modalButtonContainer, { marginTop: 8 }]}>
              <StretchButton
                color={colors.text}
                backgroundColor={colors.secondary}
                title={t("back")}
                onPress={() => {
                  setState({ text: "", err: "" });
                  props.onToggleModal();
                }}
              />
              <StretchButton
                loading={props.isFetching}
                title={t("save")}
                disabled={props.isFetching}
                onPress={onSavePress}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import { useState } from "react";
import RemovalTitleRow from "./RemovalTitleRow";
import Alert from "./AlertModal";
import { useTranslation } from "react-i18next";

export default function RemovalTitleRowWithAlert(props) {
  const { t } = useTranslation();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState("");

  return (
    <>
      <RemovalTitleRow
        {...props}
        handleRemove={() => {
          setAlertText(props.deletionWarning);
          setAlertVisible(true);
        }}
      />
      <Alert
        toggleAlert={() => setAlertVisible(!alertVisible)}
        visible={alertVisible}
        title={t("alert")}
        text={alertText}
        leftButtonTitle={t("cancel")}
        rightButtonTitle={t("yes")}
        cancelButton={true}
        onRightButtonPress={props.onRemovePress}
      />
    </>
  );
}

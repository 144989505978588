import { useState, Fragment } from "react";
import { View, Text, Pressable } from "react-native";
import update from "immutability-helper";
import StretchButton from "./StretchButton";
import IconButton from "./IconButton";
import { Tooltip } from "./Tooltip";
import { ThemeContext } from "../theming/theme-context";

const styles = {
  titleRow: {
    minHeight: 36,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textWrapper: {
    minHeight: 36,
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
  },
  hPadding: { paddingLeft: 8, paddingRight: 8 },
};

export default function ButtonGroup(props) {
  const [loading, setLoading] = useState({});
  const { title, onPress, isRadio, onClear, value, buttons } = props;

  const _buttons = Array.isArray(buttons)
    ? buttons.filter((x) => x !== null)
    : [];
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => {
        return (
          <View style={props.wrapperStyle || theme.flex}>
            {title ? (
              <View style={styles.titleRow}>
                {onClear ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    onPress={() => props.onClear(value)}
                    disabled={props.disabled || !value}
                    backgroundColor={colors.lightBg}
                    icon="trash-can-outline"
                    color={colors.text}
                  />
                ) : null}

                <Pressable
                  style={styles.textWrapper}
                  disabled={!props.onTitlePress}
                  onPress={props.onTitlePress}
                >
                  <View style={styles.textContainer}>
                    <Text
                      style={[
                        theme.boldText,
                        {
                          color: props.error ? colors.error : colors.text,
                        },
                      ]}
                    >
                      {title}
                    </Text>
                  </View>
                </Pressable>
                {props.icons ? (
                  <View style={theme.row}>
                    {props.icons.map((x, i) => {
                      const loadingKey = x.key || `icon${i}`;

                      return (
                        <IconButton
                          key={x.icon}
                          theme={theme}
                          loading={x.loading || loading[loadingKey]}
                          onPress={() => {
                            if (x.setLoadingOnPress) {
                              setLoading((_) =>
                                update(_, { [loadingKey]: { $set: true } })
                              );
                            }
                            x.onPress(null, (value) =>
                              setLoading((_) =>
                                update(_, { [loadingKey]: { $set: value } })
                              )
                            );
                          }}
                          disabled={props.disabled}
                          icon={x.icon}
                          color={x.color}
                          backgroundColor={x.backgroundColor}
                          round={x.round}
                        />
                      );
                    })}
                  </View>
                ) : null}
                {props.hint || props.hintTable ? (
                  <View style={styles.hPadding}>
                    <Tooltip
                      hintTable={props.hintTable}
                      tip={props.hint}
                      flex={0}
                      icon={true}
                      lang={props.lang}
                    />
                  </View>
                ) : null}
              </View>
            ) : null}

            <View style={theme.buttonContainer}>
              {_buttons.map((button, index) => {
                const loadingKey = button.key || index;

                return button ? (
                  <Fragment
                    key={
                      (button.key ||
                        button.value ||
                        button.title ||
                        button.icon) + index
                    }
                  >
                    <StretchButton
                      backgroundColor={
                        button.backgroundColor ||
                        (isRadio
                          ? button.value === value
                            ? colors.accent
                            : colors.lightAccent
                          : colors.accent)
                      }
                      color={
                        button.color ||
                        (isRadio
                          ? button.value === value
                            ? colors.textOnAccent
                            : colors.accent
                          : colors.textOnAccent)
                      }
                      title={button.title}
                      icon={button.icon}
                      topIcon={button.topIcon}
                      startIcon={button.startIcon}
                      endIcon={button.endIcon}
                      loading={button.loading || loading[loadingKey]}
                      disabled={props.disabled || button.disabled}
                      noDisabledStyle={
                        props.noDisabledStyle || button.noDisabledStyle
                      }
                      onPress={() => {
                        if (button.setLoadingOnPress) {
                          setLoading((_) =>
                            update(_, { [loadingKey]: { $set: true } })
                          );
                        }
                        if (button.onPress) {
                          button.onPress(
                            button.value,
                            index,
                            undefined, // used as "haveData" in some places
                            (value) =>
                              setLoading((_) =>
                                update(_, { [loadingKey]: { $set: value } })
                              )
                          );
                        } else {
                          onPress(
                            button.value,
                            index,
                            undefined, // used as "haveData" in some places
                            (value) =>
                              setLoading((_) =>
                                update(_, { [loadingKey]: { $set: value } })
                              )
                          )?.();
                        }
                      }}
                      rounded={button.rounded}
                      iconWhenLoading={button.iconWhenLoading}
                    />
                    {(
                      index === 0
                        ? _buttons.length > 1
                        : index !== _buttons.length - 1
                    ) ? (
                      <View
                        style={{
                          height: "100%",
                          width: button.rounded ? 8 : 2,
                        }}
                      />
                    ) : null}
                  </Fragment>
                ) : null;
              })}
            </View>
            {props.children}
          </View>
        );
      }}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Text, View, TouchableOpacity } from "react-native";
import Icon from "./Icon";

const styles = {
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
  },
};
export default ({ theme, colors, item, setValue }) => {
  const value = item.value ?? item.defaultValue;
  return (
    <TouchableOpacity
      key={item.key}
      style={[theme.flex, styles.wrapper]}
      onPress={() => {
        setValue(item.key, !value);
      }}
    >
      <View style={theme.flex}>
        <Text style={theme.boldText}>{item.title}</Text>
      </View>
      <View style={{ paddingLeft: 8 }}>
        <Icon
          name={value ? "checkbox-marked" : "checkbox-blank"}
          size={24}
          color={value ? colors.accent : colors.text}
        />
      </View>
    </TouchableOpacity>
  );
};

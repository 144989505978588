import "react";
import { View } from "react-native";
import { fullHp, fullWp } from "../lib/helperFns";

export default function ModalfyView(props) {
  return (
    <View
      style={{
        height: fullHp(90),
        width: fullWp(100) > 700 ? 700 : fullWp(100) - 32,
        justifyContent: "center",
      }}
    >
      {props.children}
    </View>
  );
}

import "react";
import { ActivityIndicator, View, Text } from "react-native";
import ProgressBarElem from "./ProgressBar";
import DoceanLogo from "./DoceanLogo";
import { ThemeContext } from "../theming/theme-context";
import { hp, wp } from "../lib/helperFns";

// function openAppStore() {
//   const url =
//     Platform.OS === "ios"
//       ? "https://apps.apple.com/fi/app/docean/id1497580009?l=fi"
//       : "https://play.google.com/store/apps/details?id=fi.docean.docean";
//   Linking.openURL(url);
// }

export default function ProgressInfo(props) {
  const progress = props.progress || 0;
  const parsed = parseInt(progress * 100);
  return (
    <ThemeContext.Consumer>
      {({ theme, colors, logo, lightLogo, selectedTheme }) => {
        const hasProgress =
          !isNaN(progress) && !isNaN(parsed) && progress !== 0;
        return (
          <View
            style={[
              theme.container,
              {
                backgroundColor: colors.primary,
              },
            ]}
          >
            <DoceanLogo
              pageH={props.pageH}
              pageW={props.pageW}
              theme={theme}
              colors={colors}
              logo={
                props.logo
                  ? props.logo
                  : selectedTheme === "light"
                  ? lightLogo
                  : logo
              }
            />

            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <View
                style={{
                  height: hp(30),
                  width: wp(90),
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Text style={theme.title}>{props.msg || "..."}</Text>

                {props.desc ? (
                  <Text style={theme.title}>{props.desc}</Text>
                ) : null}

                <Text style={theme.title}>
                  {props.multiPartProgress
                    ? `${props.multiPartProgress.itemBeingUploaded} / ${props.multiPartProgress.total}`
                    : " "}
                </Text>
                <View style={{ height: 10, width: wp(80) }}>
                  {hasProgress ? (
                    <ProgressBarElem
                      color={colors.accent}
                      styleAttr={"Horizontal"}
                      indeterminate={false}
                      progress={progress}
                    />
                  ) : null}
                </View>
                {props.notLoading ? null : (
                  <ActivityIndicator size="large" color={colors.accent} />
                )}
              </View>
            </View>
          </View>
        );
      }}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Text, View, FlatList } from "react-native";
import IconButton from "./IconButton";
import MultiPickerItem from "./MultiPickerItem";
import { ThemeContext } from "../theming/theme-context";
import moment from "moment";

export default function AddableList(
  props = {
    title: String,
    text: String,
    boldTitle: Boolean,
    onModalPress: Function,
    longTitle: Boolean,
    sideBorders: Boolean,
    disabled: Boolean,
  }
) {
  const getItemText = (item) => {
    const textProps = props.itemTextProps;
    if (textProps) {
      if (Array.isArray(textProps)) {
        let itemText = "";

        textProps.forEach((x, i) => {
          if (x.value) {
            if (x.format) {
              if (x.format === "date") {
                itemText += moment(item[x.prop][x.value]).format("DD.MM.YYYY");
              } else {
                itemText += item[x.prop][x.value];
              }
            } else {
              itemText += item[x.prop][x.value];
            }

            if (i >= 0 && i < textProps.length - 1) {
              itemText += " ";
            }
          } else {
            if (x.format) {
              if (x.format === "date") {
                itemText += moment(item[x.prop]).format("DD.MM.YYYY");
              } else {
                itemText += item[x.prop];
              }
            } else {
              itemText += item[x.prop] || "";
            }

            if (i >= 0 && i < textProps.length - 1) {
              itemText += " ";
            }
          }
        });

        return itemText;
      } else {
        return item[textProps];
      }
    } else {
      return item;
    }
  };

  const renderSeparator = (theme, colors) => {
    return (
      <View style={[theme.line, { borderBottomColor: colors.secondary }]} />
    );
  };

  const renderPlaceHolderItem = (theme) => (
    <View style={theme.rowContainer}>
      <View
        style={[theme.halfContainer, theme.rowContainer, { paddingLeft: 8 }]}
      >
        <Text style={theme.text}>{"-"}</Text>
      </View>
    </View>
  );

  const haveArr = Array.isArray(props.values) && props.values.length > 0;
  const data = haveArr ? props.values : ["-"];
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View
          style={{
            flexGrow: 1,
            backgroundColor: colors.primary,
          }}
        >
          <View
            style={[
              {
                height: 36,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
                borderBottomWidth: 1,
                borderBottomColor: colors.borderLighter,
              },
            ]}
          >
            <Text
              style={[
                props.boldTitle ? theme.boldText : theme.text,
                { paddingLeft: 8, paddingRight: 8 },
              ]}
              numberOfLines={2}
            >
              {props.title}
            </Text>

            <View style={theme.row}>
              {props.disableAdd || !props.onAdd ? null : (
                <IconButton
                  theme={theme}
                  loading={false}
                  onPress={props.onAdd}
                  disabled={props.disabled}
                  backgroundColor={colors.accent}
                  icon="plus"
                  color={
                    props.textColor ? props.textColor : colors.textOnAccent
                  }
                />
              )}

              {props.onRefresh ? (
                <IconButton
                  theme={theme}
                  onPress={props.onRefresh}
                  disabled={props.disabled}
                  backgroundColor={colors.accent}
                  icon="refresh"
                  color={
                    props.textColor ? props.textColor : colors.textOnAccent
                  }
                  loading={props.refreshing}
                />
              ) : null}
            </View>
          </View>

          {props.noList ? null : props.renderList ? (
            props.renderList(data)
          ) : (
            <View style={theme.multiPickerItemsListContainer}>
              <FlatList
                listKey={props.listKey ? props.listKey + "ListContent" : ""}
                data={data}
                keyExtractor={(item, index) =>
                  "multiPicker" + index + getItemText(item)
                }
                ItemSeparatorComponent={() => renderSeparator(theme, colors)}
                renderItem={({ item }) => {
                  if (haveArr) {
                    const text = props.getItemText
                      ? props.getItemText(item)
                      : getItemText(item);
                    return (
                      <MultiPickerItem
                        prop={props.prop}
                        item={item}
                        text={text}
                        theme={theme}
                        colors={colors}
                        onRemove={props.onRemove}
                        onItemPress={props.onItemPress}
                      />
                    );
                  } else {
                    return renderPlaceHolderItem(theme, colors);
                  }
                }}
              />
            </View>
          )}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

// TODO handle translation somehow
export const fileSignatureRequest = {
  currentValueKey: 3,
  specialInputs: [
    {
      valueKey: "1",
      type: "pickerObjects",
      layoutId: "pickerObjects/1",
      optionsProp: "sites",
      prop: "address",
      layoutVersion: 1,
      section: 0,
      position: 1,
    },
    {
      valueKey: "2",
      type: "pickerObjects",
      layoutId: "pickerObjects/3",
      optionsProp: "customers",
      prop: "name",
      layoutVersion: 1,
      section: 0,
      position: 1,
    },
  ],
  sections: [
    {
      type: "rows",
      cells: [
        {
          type: "formTitle",
          title: {
            text: { fin: "Tiedot dokumentin hakua/yksilöintiä varten" },
          },
        },
        {
          type: "formTitle",
          title: {
            text: { fin: "Allekirjoitettava tiedosto (pdf)" },
          },
        },
        // replaces created pdf
        {
          required: true,
          type: "attachment",
          valueKey: "pdfOverride",
          pdfOverride: true,
          onlyNew: true,
          saveOnMount: true,
          hideInGallery: true,
          disableEditing: true,
          onlineOnly: true,
          allowedTypes: ["files", "application/pdf"],
          parentTitle: {
            text: { fin: "Allekirjoitettava tiedosto (pdf)" },
          },
        },
        {
          type: "formTitle",
          title: {
            text: { fin: "Erillisliitteet" },
          },
        },
        {
          type: "attachment",
          valueKey: "3",
          parentTitle: {
            text: { fin: "Erillisliitteet" },
          },
        },
      ],
    },
  ],
};

import "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PublicHeaderBar from "../components/PublicHeaderBar";
import InfoFooter from "../components/InfoFooter";
import Text from "../../../common/components/TOS-text";
import useWindowDimensions from "../functions/useWindowDimensions";

function TOS() {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="company-registration-container">
        <Helmet>
          <meta name="description" content={t("tos")} />
          <title>{t("tos")} - Fondion Docs</title>
        </Helmet>
        <PublicHeaderBar />
        <div className="flex-center">
          <Text lightTheme wrapperStyle={{ width: width * 0.9, padding: 32 }} />
        </div>
        <InfoFooter />
      </div>
    </>
  );
}

export default TOS;

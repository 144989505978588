import { useContext, useRef } from "react";
import { ActivityIndicator, Platform, Pressable } from "react-native";
import Icon from "./Icon";
import { setNativeProps } from "../lib/functions";
import { ThemeContext } from "../theming/theme-context";

function IconButton(props) {
  const { round, loading, disabled, onPress, icon } = props;
  const ref = useRef(null);
  const { theme, colors } = useContext(ThemeContext);
  const backgroundColor = props.backgroundColor;
  const color = props.color;

  const setStyles = (hover) => () => {
    if (disabled) return;
    setNativeProps(ref.current, {
      backgroundColor:
        props.backgroundColor === colors.accent
          ? hover
            ? colors.activeAccent
            : colors.accent
          : hover
          ? backgroundColor + "80"
          : backgroundColor,
    });
  };

  return (
    <Pressable
      ref={ref}
      disabled={!!(loading || disabled)}
      onMouseEnter={setStyles(true)}
      onMouseLeave={setStyles()}
      style={({ pressed }) => [
        round ? theme.roundIconButton : theme.iconButton,
        {
          flex: props.flex,
          width: props.size || props.width || 36,
          height: props.fillHeight ? "100%" : props.size || props.height || 36,
          opacity: disabled ? 0.3 : pressed ? 0.8 : 1,
          backgroundColor: backgroundColor,
          flexDirection: "row",
        },
      ]}
      onMouseDown={Platform.OS === "web" ? (ev) => ev.preventDefault() : null}
      onPress={onPress}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
    >
      {loading ? (
        <ActivityIndicator color={color} />
      ) : (
        <Icon name={icon} size={props.iconSize ?? 24} color={color} />
      )}
    </Pressable>
  );
}
export default IconButton;

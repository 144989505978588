import "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "../../../common/theming/theme-context";

import ProfileScreen from "../../../common/screens/ProfileScreen";

function ProfileScreenWrapper() {
  let history = useHistory();
  const { width, height } = useWindowDimensions();

  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div style={theming.theme.mainContainer}>
          <ProfileScreen navigation={history} pageW={width} pageH={height} />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

export default ProfileScreenWrapper;

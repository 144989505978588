import { useCallback, useEffect, useRef, useReducer, useState } from "react";
import { TouchableOpacity, Text, View, Platform, FlatList } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import Icon from "../components/Icon";
import DropzoneElem from "./DropzoneElem";
import TextInputRow from "./SimpleTextInputRow";
import MultilineTextInputRow from "./MultilineTextInputRow";
import TextModalButton from "./TextModalButton";
import TypeSpecificPreview from "./TypeSpecificPreview";
import ButtonGroup from "./ButtonGroup";
import Title from "./Title";

import PreviewModal from "./PreviewModal";
import {
  showToast,
  onDocumentPicker,
  navigate,
  handleCameraOpen,
  handlePictureTaken,
} from "../lib/helperFns";
import { handleAttachmentPick, errorReport } from "../lib/functions";
import { cameraUsable } from "../lib/constants";
import { dirs } from "../lib/fileOperations";
import RemovalTitleRowWithAlert from "./RemovalTitleRowWithAlert";
import { Tooltip } from "./Tooltip";

const separatorStyle = {
  width: 16,
};
function Separator() {
  return <View style={separatorStyle} />;
}
const emptyArr = [{ name: "-", id: "noArrItem" }];
const rotateButtonStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  height: 32,
  width: 32,
};
const extraButtonStyle = {
  position: "absolute",
  bottom: 0,
  right: 0,
  height: 32,
  width: 32,
};
const offlineButtonStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  height: 32,
  width: 32,
};
const onlyImagesMimes =
  Platform.OS === "web"
    ? ["image/png", "image/jpg", "image/jpeg"]
    : ["image/png", "image/jpg", "image/jpeg", "image/heic", "image/heif"];
const reducer = (state = {}, action) => {
  switch (action.type) {
    case "set":
      return { ...state, ...action.payload };
    case "modify":
      return { ...state, [action.key]: action.value };
    case "close":
      return { visible: false };
    default:
      throw new Error();
  }
};
export default function FilePickerRows(props) {
  const { t } = useTranslation();
  const allowedTypes = props.allowedTypes || [];
  const onlyImages = allowedTypes.length == 0 && props.onlyImages;
  const inputEl = useRef(null);
  const [previewModal, setPreviewModal] = useReducer(reducer, {
    visible: false,
  });
  const [selected, setSelected] = useState([]);

  const [fetchedAttachments, setAttachmentsFetched] = useReducer(reducer, {});

  function setAttachmentsLoadedWithParams(params) {
    setAttachmentsFetched({ type: "modify", ...params });
  }

  const setPreviewModalWithParams = (params) => {
    setPreviewModal({ type: "set", payload: params });
  };

  useEffect(() => {
    if (previewModal.visible) {
      const valuesAtch = props.values?.find(
        (x) => x.id === previewModal?.valuesAtch?.id
      );

      setPreviewModalWithParams({
        valuesAtch: valuesAtch,
      });
    }
  }, [props.values]);

  const _navigate = (route, navigation, params) => {
    if (props.navigate) {
      props.navigate(route, navigation, params);
    } else {
      navigate(route, navigation, params);
    }
  };

  const onAttachmentEdit = (id) => {
    _navigate("addAttachments", props.navigation, {
      modifyId: id,
      valueKey: props.valueKey,
      offlineAtch: props.offlineAtch,
      hidePreview: props.hidePreview,
      screenToGoBackTo: props.screenToGoBackTo,
      single: props.single,
      hideInGallery: props.hideInGallery,
      requiredProps: props.requiredProps,
      saveOnMount: props.saveOnMount,
      pdfOverride: props.pdfOverride,
    });
  };

  const openPreviewModal = (atch) => {
    const valuesAtch = props.values?.find((x) => x.id === atch?.id);

    setPreviewModalWithParams({
      atch: valuesAtch,
      valuesAtch: valuesAtch,
      visible: true,
    });
  };

  const closePreviewModal = () => {
    setPreviewModal({ type: "close" });
  };

  const handleAction = (action) => {
    if (action === "filePicker") {
      if (Platform.OS == "web") {
        inputEl.current.click();
      } else {
        onDocumentPicker({
          _navigate,
          navigation: props.navigation,
          navProps: {
            valueKey: props.valueKey,
            offlineAtch: props.offlineAtch,
            hidePreview: props.hidePreview,
            screenToGoBackTo: props.screenToGoBackTo,
            hideInGallery: props.hideInGallery,
            single: props.single,
            requiredProps: props.requiredProps,
            saveOnMount: props.saveOnMount,
            pdfOverride: props.pdfOverride,
          },
          single: props.single,
          onlyImages: onlyImages,
          allowedTypes: allowedTypes,
        });
      }
    } else if (action === "camera") {
      handleCameraOpen((response) => {
        handlePictureTaken({
          response,
          navProps: {
            fromDocumentScanner: true,
            valueKey: props.valueKey,
            offlineAtch: props.offlineAtch,
            hidePreview: props.hidePreview,
            screenToGoBackTo: props.screenToGoBackTo,
            single: props.single,
            hideInGallery: props.hideInGallery,
            requiredProps: props.requiredProps,
            saveOnMount: props.saveOnMount,
            pdfOverride: props.pdfOverride,
          },
          navigateFn: _navigate,
          navigation: props.navigation,
        });
      }, props.saveAttachmentsToPhotos);
    } else if (action === "pickExisting") {
      if (props.userRole === "Trial") {
        showToast(t("alreadyAttachedTrialWarning"));
      } else {
        _navigate("attachmentsGallery", props.navigation, {
          valueKey: props.valueKey,
          standAlone: true,
          onlyImages: onlyImages,
          offlineAtch: props.offlineAtch,
          hidePreview: props.hidePreview,
          allowedTypes: allowedTypes,
          screenToGoBackTo: props.screenToGoBackTo,
          single: props.single,
          saveOnMount: props.saveOnMount,
          pdfOverride: props.pdfOverride,
        });
      }
    }
  };

  const removeFromArr = (id, extraProps) => {
    props.deleteFromObjectArr({
      docId: props.docId,
      valueKey: props.valueKey,
      oldVal: { id },
      idProp: "id",
      ...extraProps,
    });
    setSelected((_) => _.filter((x) => x.id !== id));
  };
  const handleRemove = useCallback(
    (atch) => {
      if (atch) {
        removeFromArr(atch.id);
      } else {
        errorReport({
          error: "missing attachment object",
          errorInScreen: "FilePickerRows",
          errorInFn: "handleRemove",
          errorParams: {
            valueKey: props.valueKey,
            docId: props.docId,
          },
        });
      }
    },
    [props.onlyNew, props.docId, props.valueKey]
  );

  const onRotate = useCallback(
    (atchUri, atch, currentRotation) => {
      const valuesAtch = atch
        ? props.values?.find((x) => x.id === atch?.id)
        : previewModal.valuesAtch;
      const oldRotation = currentRotation ?? valuesAtch?.rotation ?? 0;
      let newRotation;
      if (oldRotation === 270) newRotation = 0;
      else newRotation = oldRotation + 90;
      props.modifyObjectArrItem({
        docId: props.docId,
        valueKey: props.valueKey,
        oldVal: valuesAtch,
        value: newRotation,
        idProp: "id",
        propToSet: "rotation",
        sortProp: "name",
      });
    },
    [previewModal]
  );

  const setDimensions = useCallback(() => {
    // props.modifyObjectArrItem({
    //   docId: props.docId,
    //   valueKey: props.valueKey,
    //   value: {
    //     ...previewModal.valuesAtch,
    //     dimensions,
    //   },
    //   itemIsArr: true,
    //   idProp: "id",
    //   replace: true,
    // });
  }, [previewModal]);

  const searchForDoc = () => {
    if (props.userRole === "Trial") {
      showToast(t("docAttachingTrialWarning"));
    } else {
      props.toggleDocSearchModal();
    }
  };

  const handleAcceptedFiles = (files) => {
    handleAttachmentPick({
      _navigate: _navigate,
      navProps: {
        onlyImages: onlyImages,
        navigation: props.navigation,
        valueKey: props.valueKey,
        offlineAtch: props.offlineAtch,
        hidePreview: props.hidePreview,
        screenToGoBackTo: props.screenToGoBackTo,
        single: props.single,
        hideInGallery: props.hideInGallery,
        requiredProps: props.requiredProps,
        saveOnMount: props.saveOnMount,
        pdfOverride: props.pdfOverride,
      },
    })(files);
  };

  const haveArr = Array.isArray(props.values) && props.values.length > 0;
  const ownDocsAllowed = allowedTypes.includes("ownDocs");
  const onlyFilesAllowed = allowedTypes.includes("files");
  const imagesAllowed = allowedTypes.some((x) => x.startsWith("image"));
  const onlyOwnDocsAllowed = ownDocsAllowed && allowedTypes.length === 1;
  const data = haveArr ? props.values : emptyArr;
  const itemSize = 160;

  const opened = data.find((x) => x.id === selected[selected.length - 1]?.id);
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <DropzoneElem
          accept={
            allowedTypes.length > 0
              ? allowedTypes
              : onlyImages
              ? onlyImagesMimes.join(",")
              : null
          }
          maxFiles={props.single ? 1 : null}
          defaultBorderColor={colors.darkPrimary}
          handleAcceptedFiles={handleAcceptedFiles}
        >
          <View
            style={{
              flex: 1,
              opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
              backgroundColor: colors.primary,
            }}
          >
            {props.title ? (
              <View style={theme.titleRow}>
                <Title
                  boldTitle
                  title={props.title}
                  icons={
                    props.onRefresh
                      ? [
                          {
                            icon: "refresh",
                            onPress: props.onRefresh,
                            loading: props.refreshing,
                          },
                        ]
                      : null
                  }
                />

                {props.hint || props.hintTable ? (
                  <View style={theme.hPadding}>
                    <Tooltip
                      hintTable={props.hintTable}
                      tip={props.hint}
                      flex={0}
                      icon={true}
                      lang={props.lang}
                    />
                  </View>
                ) : null}
              </View>
            ) : null}

            {Platform.OS == "web" ? (
              <input
                ref={inputEl}
                accept={
                  allowedTypes.length == 0 && onlyImages
                    ? onlyImagesMimes
                    : imagesAllowed
                    ? [...allowedTypes, ...onlyImagesMimes].join(",")
                    : allowedTypes
                }
                multiple={!props.single}
                style={{ height: 0, width: 0, visibility: "hidden" }}
                type="file"
                onChange={(ev) => handleAcceptedFiles(ev?.target?.files)}
              />
            ) : null}

            {props.disabled ? null : (
              <View style={theme.buttonContainer}>
                <ButtonGroup
                  buttons={[
                    props.profile?.role && !props.onlyNew && !onlyOwnDocsAllowed
                      ? {
                          title: t("alreadyAdded"),
                          onPress: () => handleAction("pickExisting"),
                        }
                      : null,
                    !props.offlineAtch && ownDocsAllowed
                      ? {
                          title: onlyOwnDocsAllowed
                            ? t("fetchDoc")
                            : t("document"),
                          onPress: () => searchForDoc(),
                        }
                      : null,
                    !onlyOwnDocsAllowed
                      ? {
                          title: t("file"),
                          onPress: () => handleAction("filePicker"),
                        }
                      : null,
                    cameraUsable &&
                    Platform.OS !== "web" &&
                    !onlyFilesAllowed &&
                    !onlyOwnDocsAllowed &&
                    (allowedTypes.length === 0 || imagesAllowed)
                      ? {
                          title: t("photo"),
                          onPress: () => handleAction("camera"),
                        }
                      : null,
                  ]}
                />
              </View>
            )}

            <FlatList
              key={"AtchList" + props.valueKey}
              listKey={"AtchList" + props.valueKey}
              data={data}
              keyExtractor={(item) => item.id}
              horizontal
              ItemSeparatorComponent={Separator}
              renderItem={({ item }) => {
                if (!item) return null;
                if (item.id !== "noArrItem") {
                  const atch = item.docRef
                    ? item
                    : props.attachments?.[item.id] || item;
                  const atchFetched = fetchedAttachments[item.id];
                  const isSelected =
                    opened?.id === item.id ||
                    selected.some((x) => x.id === item.id);
                  return (
                    <View
                      style={{
                        flex: 1,
                        height: itemSize,
                        width: itemSize,
                        borderWidth: 2,
                        borderColor:
                          isSelected || selected.some((x) => x.id === item.id)
                            ? colors.accent
                            : colors.primary,
                      }}
                    >
                      <TypeSpecificPreview
                        onPress={() => {
                          if (selected.length > 0) {
                            if (isSelected) {
                              setSelected((_selected) =>
                                _selected.filter((x) => x.id !== item.id)
                              );
                            } else {
                              setSelected((_selected) => [..._selected, item]);
                            }
                          } else {
                            openPreviewModal(item);
                          }
                        }}
                        onLongPress={() => {
                          if (isSelected) {
                            setSelected((_selected) =>
                              _selected.filter((x) => x.id !== item.id)
                            );
                          } else {
                            setSelected((_selected) => [..._selected, item]);
                          }
                        }}
                        setAttachmentsFetched={setAttachmentsLoadedWithParams}
                        pageH={itemSize - 4}
                        pageW={itemSize - 4}
                        imgHeight={itemSize - 4}
                        atch={atch}
                        fetchedAttachments={atchFetched}
                        openPreviewModal={openPreviewModal}
                        isSelected={false}
                        dirs={dirs}
                        theme={theme}
                        colors={colors}
                        id={item.id}
                        standAlone
                        forceRectangle
                        rotation={item.rotation}
                        thumbnail={true}
                        onRotate={onRotate}
                        rotateButton={true}
                        absolutePosition
                        offlineButtonStyle={offlineButtonStyle}
                        rotateButtonStyle={rotateButtonStyle}
                        extraButtonStyle={extraButtonStyle}
                        extraButtonIcon={
                          isSelected ? "chevron-up" : "chevron-down"
                        }
                        onExtraButtonPress={() => {
                          if (isSelected) setSelected([]);
                          else setSelected([item]);
                        }}
                        customToken={props.customToken}
                        disabled={props.disabled}
                        ADD_ATTACHMENT={props.ADD_ATTACHMENT}
                      />
                    </View>
                  );
                }
              }}
            />

            {selected.length > 1 ? (
              <RemovalTitleRowWithAlert
                style={theme.flex}
                onCancelPress={() => setSelected([])}
                onRemovePress={() => selected.forEach(handleRemove)}
                onSelectAllPress={() => {
                  if (data.length === selected.length) setSelected([]);
                  else setSelected(data);
                }}
                allSelected={data.length === selected.length}
                deletionWarning={
                  t("deleteConfirmation") +
                  ":\n\n" +
                  selected.reduce(
                    (acc, cur, i) =>
                      acc + cur.name + (i !== selected.length - 1 ? "\n" : ""),
                    ""
                  )
                }
              />
            ) : null}

            {opened ? (
              <View>
                <View
                  style={[
                    theme.rowContainer,
                    theme.halfContainer,
                    theme.textPaddingContainer,
                    {
                      flex: 1,
                      //justifyContent: "space-between",
                    },
                  ]}
                >
                  {props.removeBlocked ? null : (
                    <TouchableOpacity
                      onPress={() => handleRemove(opened)}
                      style={[
                        {
                          height: 32,
                          width: 32,
                          borderRadius: 5,
                          paddingTop: 0,
                          paddingBottom: 0,
                          backgroundColor: colors.accent,
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      ]}
                      disabled={!!props.disabled}
                    >
                      <Icon
                        name="trash-can"
                        size={24}
                        color={colors.textOnAccent}
                      />
                    </TouchableOpacity>
                  )}

                  <TouchableOpacity
                    onPress={() => {
                      openPreviewModal(opened);
                    }}
                    style={{
                      flex: 1,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    <Text style={[theme.text, { flexGrow: 1 }]}>
                      {opened.name}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setSelected([]);
                    }}
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: 4,
                      paddingTop: 0,
                      paddingBottom: 0,
                      backgroundColor: colors.accent,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      name={opened ? "chevron-up" : "chevron-down"}
                      size={24}
                      color={colors.textOnAccent}
                    />
                  </TouchableOpacity>
                </View>
                {opened ? (
                  <View>
                    <View style={[theme.line, { width: "98%" }]} />

                    <TextModalButton
                      setTextModal={props.setTextModal}
                      onlineDoc={props.onlineDoc}
                      title={t("desc")}
                      placeholder={opened.desc}
                      multiline={false}
                      visible={true}
                      value={opened.desc}
                      theme={theme}
                      colors={colors}
                      disabled={props.disabled}
                      fn={(value) => {
                        let newValue = value.replace(/\r?\n|\r/g, "");
                        props.modifyObjectArrItem({
                          docId: props.docId,
                          valueKey: props.valueKey,
                          oldVal: opened,
                          value: newValue,
                          idProp: "id",
                          propToSet: "desc",
                          sortProp: "name",
                        });
                      }}
                      getMultiEditValueKeys={props.getMultiEditValueKeys}
                    >
                      <MultilineTextInputRow
                        disabled={props.disabled || props.onlineDoc}
                        noDisabledStyle
                        // cellRef={this.props.cellRef}
                        // setCellRef={this.props.setCellRef}
                        // focusNextInput={this.props.focusNextInput}
                        value={opened.desc}
                        setTargetProperty={(prop, value) => {
                          props.modifyObjectArrItem({
                            docId: props.docId,
                            valueKey: props.valueKey,
                            oldVal: opened,
                            value: value,
                            idProp: "id",
                            propToSet: "desc",
                            sortProp: "name",
                          });
                        }}
                        title={t("desc")}
                      />
                    </TextModalButton>

                    {props.pdfAttachment ? (
                      <TextModalButton
                        setTextModal={props.setTextModal}
                        onlineDoc={props.onlineDoc}
                        title={t("desc")}
                        placeholder={opened.height}
                        multiline={false}
                        visible={true}
                        value={opened.height}
                        theme={theme}
                        colors={colors}
                        disabled={props.disabled}
                        fn={(value) => {
                          let newValue = value.replace(/\r?\n|\r/g, "");
                          props.modifyObjectArrItem({
                            docId: props.docId,
                            valueKey: props.valueKey,
                            oldVal: opened,
                            value: newValue,
                            idProp: "id",
                            propToSet: "height",
                            sortProp: "name",
                          });
                        }}
                        getMultiEditValueKeys={props.getMultiEditValueKeys}
                      >
                        <TextInputRow
                          value={opened.height}
                          title={t("heightInPDF")}
                          border={false}
                          numeric
                          disabled={props.disabled || props.onlineDoc}
                          noDisabledStyle={true}
                          onChangeText={(value) => {
                            let newValue = value.replace(/\r?\n|\r/g, "");
                            props.modifyObjectArrItem({
                              docId: props.docId,
                              valueKey: props.valueKey,
                              oldVal: opened,
                              value: newValue,
                              idProp: "id",
                              propToSet: "height",
                              sortProp: "name",
                            });
                          }}
                        />
                      </TextModalButton>
                    ) : null}
                  </View>
                ) : null}
              </View>
            ) : null}

            <PreviewModal
              docId={props.docId}
              pageH={props.fullHeight}
              pageW={props.fullWidth}
              attachment={previewModal.atch || previewModal.valuesAtch}
              visible={previewModal.visible}
              closeModal={closePreviewModal}
              rotation={previewModal.valuesAtch?.rotation}
              onRotate={onRotate}
              setDimensions={setDimensions}
              onAttachmentEdit={props.disableEditing ? null : onAttachmentEdit}
              disabled={props.disabled}
              customToken={props.customToken}
            />
          </View>
        </DropzoneElem>
      )}
    </ThemeContext.Consumer>
  );
}

{
  /* <View
style={[
  theme.rowContainer,
  {
    paddingLeft: 8,
    paddingRight: 8,
  },
]}
>
<View style={[theme.halfContainer, theme.rowContainer]}>
  <Text style={theme.text}>{opened.name}</Text>
</View>
</View> */
}

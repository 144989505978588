import "react";
import { Text, View } from "react-native";
import ProgressBarElem from "./ProgressBar";
import { useTranslation } from "react-i18next";

export default function PasswordStrength(props) {
  const { t } = useTranslation();

  const getProgress = () => {
    if (props.passScore?.score == 0 && props.password != "") return 0.2;
    else if (props.passScore?.score == 1) return 0.4;
    else if (props.passScore?.score == 2) return 0.6;
    else if (props.passScore?.score == 3) return 0.8;
    else if (props.passScore?.score == 4) return 1;
    else return 0;
  };

  const getTintColor = (text) => {
    if (props.passScore?.score == 0 && text !== "") return "#B00020";
    else if (props.passScore?.score == 1) return "orange";
    else if (props.passScore?.score == 2) return "blue";
    else if (props.passScore?.score == 3) return "green";
    else if (props.passScore?.score == 4) return "green";
    else return "#ddd";
  };

  const getProgressText = () => {
    if (props.passScore?.score == 0 && props.password !== "")
      return t("veryWeak");
    else if (props.passScore?.score == 1) return t("weak");
    else if (props.passScore?.score == 2) return t("passable");
    else if (props.passScore?.score == 3) return t("great");
    else if (props.passScore?.score == 4) return t("excellent");
    else return "";
  };

  const getInfoText = (theme) => {
    if (props.passScore) {
      const warning = props.passScore.feedback?.warning;
      const suggestions = props.passScore.feedback?.suggestions;
      const warningString = warning ? t(warning) : "";
      return (
        <>
          {warningString ? (
            <Text key={"PassWarningString"} style={theme.label}>
              {warningString}
            </Text>
          ) : null}
          {suggestions?.map((x, i) => {
            const suggestion = t(x);
            if (suggestion) {
              return (
                <Text key={"PassSuggestion" + i} style={theme.label}>
                  {suggestion}
                </Text>
              );
            }
          })}
        </>
      );
    } else {
      return [
        <Text key={"PassSuggestion 1"} style={theme.label}>
          {t("useMultipleWords")}
        </Text>,
        <Text key={"PassSuggestion 2"} style={theme.label}>
          {t("noNeedForSpecialCharacters")}
        </Text>,
      ];
    }
  };

  return props.password ? (
    <View>
      <ProgressBarElem
        color={getTintColor()}
        styleAttr={"Horizontal"}
        indeterminate={false}
        progress={getProgress()}
      />

      <Text style={props.theme.label}>{getProgressText()}</Text>
      <View style={[props.theme.fatLine, { marginTop: 5, marginBottom: 5 }]} />
      {getInfoText(props.theme)}
    </View>
  ) : null;
}

import { memo } from "react";
import { ActivityIndicator } from "react-native";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "./Modal";
import Alert from "./AlertModal";
import SimpleModal from "./SimpleModal";

// const shouldNotUpdate = (prevProps, nextProps) => {
//   return prevProps === nextProps;
// };
//export const CreateDocScreen = React.memo((props) => {
const shouldNotUpdate = (prevProps, nextProps) => {
  const shouldUpdate =
    prevProps.alert !== nextProps.alert ||
    prevProps.textModal !== nextProps.textModal ||
    prevProps.hideTextModal !== nextProps.hideTextModal ||
    prevProps.hideAlert !== nextProps.hideAlert ||
    prevProps.saving !== nextProps.saving ||
    prevProps.pageH !== nextProps.pageH ||
    prevProps.pageW !== nextProps.pageW ||
    prevProps.colors !== nextProps.colors;
  return !shouldUpdate;
};
const WhiteboardModals = memo(
  ({
    alert,
    textModal,
    hideTextModal,
    hideAlert,
    saving,
    pageH,
    pageW,
    colors,
  }) => {
    return (
      <>
        <Alert toggleAlert={hideAlert} {...alert} />
        <Modal {...modalPickerOpts} isVisible={!!saving}>
          <ActivityIndicator size="large" color={colors.accent} />
        </Modal>
        <SimpleModal
          pageH={pageH}
          pageW={pageW}
          title={textModal.title}
          placeholder={""}
          err={textModal.err}
          errMsg={textModal.errMsg}
          visible={textModal.visible}
          value={textModal.value}
          //isFetching={loading.prop}
          onSave={textModal.onSave}
          onToggleModal={hideTextModal}
          closeOnRighButtonPress={textModal.closeOnRighButtonPress ?? false}
          multiline={textModal.multiline}
          numeric={textModal.numeric}
          fn={textModal.fn}
          onSubmitEditing
        />
      </>
    );
  },
  shouldNotUpdate
);

export default WhiteboardModals;

import { Platform } from "react-native";
import moment from "moment";

export const buildVersion = "3.162";

// ! production server api
export const prod = "https://api-prod.docean.fi";
// ! development server api
export const dev = "https://api.docean.fi";
// ! LOCALHOST
// const localhost = "http://10.0.2.2:8080";
// const localhostWeb = "http://localhost:8080/";
// export const api = localhost;
// export const isDevBuild = true;

// __DEV__ is only available when running metro or vite etc.
// so if you want to build a release version with dev stuff use this
export const isDevBuild =
  __DEV__ ||
  window?.location?.hostname === "dev.docean.fi" ||
  window?.location?.hostname === "localhost";

export const api = isDevBuild ? dev : prod;

export const cameraUsable = true;
export const mapEnabled = true;
export const defaultMapCenter = [24.941390671230806, 60.171784874068805];

export const pinSvgPath =
  "M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z";

export const svgElemTypes = {
  1: { rotatable: true, type: "square", icon: "square-outline" },
  2: { rotatable: true, type: "rectangle", icon: "rectangle-outline" },
  3: { rotatable: false, type: "circle", icon: "circle-outline" },
  4: { rotatable: true, type: "ellipse", icon: "ellipse-outline" },
  5: { rotatable: true, type: "text", icon: "format-text" },
  6: { rotatable: false, type: "line", icon: "vector-line" },
  //7: { rotatable: false, type: "arrow", icon: "arrow-bottom-left" },
  // 100: { rotatable: true, type: "imagePin", icon: "camera" },
  // 101: { rotatable: true, type: "filePin", icon: "file-import-outline" },
};

export const mimeToReadableTypes = {
  "audio/aac": [".aac", "AAC audio"],
  "application/x-abiword": [".abw", "AbiWord document"],
  "application/x-freearc": [".arc", "Archive document"],
  "image/avif": [".avif", "AVIF image"],
  "video/x-msvideo": [".avi", "AVI: Audio Video Interleave"],
  "application/vnd.amazon.ebook": [".azw", "Amazon Kindle eBook format"],
  "application/octet-stream": [".bin", "Binary data"],
  "image/bmp": [".bmp", "Bitmap Graphics"],
  "application/x-bzip": [".bz", "BZip archive"],
  "application/x-bzip2": [".bz2", "BZip2 archive"],
  "application/x-cdf": [".cda", "CD audio"],
  "application/x-csh": [".csh", "C-Shell script"],
  "text/css": [".css", "CSS"],
  "text/csv": [".csv", "CSV"],
  "application/msword": [".doc", "Microsoft Word"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
    "Microsoft Word",
  ],
  "application/vnd.ms-fontobject": [".eot", "MS Embedded OpenType fonts"],
  "application/epub+zip": [".epub", "Electronic publication"],
  "application/gzip": [".gz", "GZip"],
  "image/gif": [".gif", "GIF"],
  "text/html": [".html", "HTML"],
  "image/vnd.microsoft.icon": [".ico", "Icon format"],
  "text/calendar": [".ics", "iCalendar format"],
  "application/java-archive": [".jar", "Java Archive"],
  "image/jpg": [".jpg", "JPG image"],
  "image/jpeg": [".jpeg", "JPEG image"],
  "text/javascript": [".js", "JavaScript"],
  "application/json": [".json", "JSON"],
  "application/ld+json": [".jsonld", "JSON-LD"],
  "audio/midi": [".midi", "MIDI"],
  "audio/x-midi": [".midi", "MIDI"],
  "audio/mpeg": [".mp3", "MP3 audio"],
  "video/mp4": [".mp4", "MP4 video"],
  "video/mpeg": [".mpeg", "MPEG Video"],
  "application/vnd.apple.installer+xml": [".mpkg", "Apple Installer Package"],
  "application/vnd.oasis.opendocument.presentation": [
    ".odp",
    "OpenDocument presentation document",
  ],
  "application/vnd.oasis.opendocument.spreadsheet": [
    ".ods",
    "OpenDocument spreadsheet document",
  ],
  "application/vnd.oasis.opendocument.text": [
    ".odt",
    "OpenDocument text document",
  ],
  "audio/ogg": [".oga", "OGG audio"],
  "video/ogg": [".ogv", "OGG video"],
  "application/ogg": [".ogx", "OGG"],
  "audio/opus": [".opus", "Opus audio"],
  "font/otf": [".otf", "OpenType font"],
  "image/png": [".png", "PNG"],
  "application/pdf": [".pdf", "PDF"],
  "application/x-httpd-php": [".php", "PHP"],
  "application/vnd.ms-powerpoint": [".ppt", "Microsoft PowerPoint"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
    "Microsoft PowerPoint",
  ],
  "application/vnd.rar": [".rar", "RAR archive"],
  "application/rtf": [".rtf", "Rich Text Format"],
  "application/x-sh": [".sh", "Bourne shell script"],
  "image/svg+xml": [".svg", "SVG"],
  "application/x-shockwave-flash": [".swf", "Adobe Flash document"],
  "application/x-tar": [".tar", "TAR"],
  "image/tiff": [".tiff", "TIFF"],
  "video/mp2t": [".ts", "MPEG"],
  "font/ttf": [".ttf", "TrueType Font"],
  "text/plain": [".txt", "Text"],
  "application/vnd.visio": [".vsd", "Microsoft Visio"],
  "audio/wav": [".wav", "Waveform Audio Format"],
  "audio/webm": [".weba", "WEBM audio"],
  "video/webm": [".webm", "WEBM video"],
  "image/webp": [".webp", "WEBP image"],
  "font/woff": [".woff", "WOFF"],
  "font/woff2": [".woff2", "WOFF"],
  "application/xhtml+xml": [".xhtml", "XHTML"],
  "application/vnd.ms-excel": [".xls", "Microsoft Excel"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
    "Microsoft Excel",
  ],
  "application/xml": [".xml", "XML"],
  "text/xml": [".xml", "XML"],
  "application/vnd.mozilla.xul+xml": [".xul", "XUL"],
  "application/zip": [".zip", "ZIP archive"],
  "video/3gpp": [".3gp", "3GPP audio/video"],
  "audio/3gpp": [".3gp", "3GPP audio/video"],
  "video/3gpp2": [".3g2", "3GPP2 audio/video"],
  "audio/3gpp2": [".3g2", "3GPP2 audio/video"],
  "application/x-7z-compressed": [".7z", "7-zip archive"],
};

export const mimeToReadable = (type) =>
  mimeToReadableTypes[type]?.[1] ?? "File"; // || mime.getExtension(type);

export const docValueTypes = [
  "Primitive",
  "StringArray",
  "ExtraRow",
  "Atch",
  "MeasObj",
  "Modular",
  "MeasObjCon",
  "ChartData",
  "Measurement",
];

export const formRoutes = [
  "measurementObjects",
  "modularItems",
  "togglableCellsSections",
  "addAttachments",
  "attachmentsGallery",
  "create",
  "editForm",
  "rows",
];

/* list of supported file types */
export const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
];

export const companyWidePermissions = {
  // Antti_Boi_Oy: {
  //   docs: {
  //     // User: ["read", "write"], // "create", "complete", "send", "remove"
  //     // SuperAdmin: ["delete"],
  //   },
  //   projects: {
  //     // User: [], // ! empty array means no access
  //     // Manager: [],
  //     // Boss: [],
  //     // SuperAdmin: [], //["read"],
  //   },
  //   folders: {
  //     // User: [],
  //     // SuperAdmin: [],
  //   },
  // },
};

export const featuresEnum = {
  ProjectManagement: 0,
  SalesTool: 1,
};

export const fireBaseApiKey =
  Platform.OS === "ios"
    ? "15262497572-i34df2oj3ja0sdsnbgs7sb8616mjno9i.apps.googleusercontent.com"
    : "32674389338-e7rvgk9o8q6agf1pc4esei2va1stepcj.apps.googleusercontent.com";

// public enum DocStatus
// {
//     Unfinished = 0,
//     Internal = 1,
//     AttachedDoc = 2,
//     OrderConfirmation = 5,
//     CompletedOrder = 6,
//     Completed = 10,
//     SignaturesPending = 20,
//     DocSendingFailed = 30,
//     SignRequestSendingFailed = 40,
//     Rejected = 50
// }
export const docStatusToArrName = {
  0: "unfinishedDocs",
  1: "completedDocs",
  5: "orderConfirmations",
  6: "completedOrders",
  10: "completedDocs",
  20: "pendingDocs",
  30: "pendingDocs",
  40: "pendingDocs",
  50: "pendingDocs",
};

export const constantSources = {
  company: {
    0: {
      partlyFilledIsValid: true,
      text: {
        text: {
          i18n: "company0",
        },
      },
      source: ["doc", "values", "mainContractors", "name"],
      alternateSources: [["company", "name"]],
    },
    1: {
      text: {
        text: {
          i18n: "company1",
        },
      },
      source: ["doc", "values", "mainContractors", "companyId"],
      alternateSources: [["company", "companyId"]],
    },
    2: {
      text: {
        text: {
          i18n: "company2",
        },
      },
      source: ["doc", "values", "mainContractors", "address"],
      alternateSources: [["company", "address"]],
    },
    3: {
      text: {
        text: {
          i18n: "company3",
        },
      },
      source: ["doc", "values", "mainContractors", "zipcode"],
      alternateSources: [["company", "zipcode"]],
    },
    4: {
      text: {
        text: {
          i18n: "company4",
        },
      },
      source: ["doc", "values", "mainContractors", "city"],
      alternateSources: [["company", "city"]],
    },
    5: {
      text: {
        text: {
          i18n: "company5",
        },
      },
      source: ["doc", "values", "mainContractors", "ewManagerName"],
      alternateSources: [["company", "ewManager", "name"]],
    },
    6: {
      text: {
        text: {
          i18n: "company6",
        },
      },
      source: ["doc", "values", "mainContractors", "ewManagerLName"],
      alternateSources: [["company", "ewManager", "lName"]],
    },
    7: {
      text: {
        text: {
          i18n: "company7",
        },
      },
      source: ["doc", "values", "mainContractors", "ewManagerPhone"],
      alternateSources: [["company", "ewManager", "phone"]],
    },
    8: {
      text: {
        text: {
          i18n: "company8",
        },
      },
      source: ["doc", "values", "mainContractors", "ewManagerEmail"],
      alternateSources: [["company", "ewManager", "email"]],
    },
  },
  userCompany: {
    1: {
      text: {
        text: {
          i18n: "userCompany1",
        },
      },
      source: ["company", "name"],
    },
    2: {
      text: {
        text: {
          i18n: "userCompany2",
        },
      },
      source: ["company", "companyId"],
    },
    3: {
      text: {
        text: {
          i18n: "userCompany3",
        },
      },
      source: ["company", "address"],
    },
    4: {
      text: {
        text: {
          i18n: "userCompany4",
        },
      },
      source: ["company", "zipcode"],
    },
    5: {
      text: {
        text: {
          i18n: "userCompany5",
        },
      },
      source: ["company", "city"],
    },
    6: {
      text: {
        text: {
          i18n: "userCompany6",
        },
      },
      source: ["company", "ewManager", "name"],
    },
    7: {
      text: {
        text: {
          i18n: "userCompany7",
        },
      },
      source: ["company", "ewManager", "lName"],
    },
    8: {
      text: {
        text: {
          i18n: "userCompany8",
        },
      },
      source: ["company", "ewManager", "phone"],
    },
    9: {
      text: {
        text: {
          i18n: "userCompany9",
        },
      },
      source: ["company", "ewManager", "email"],
    },
    10: {
      text: {
        text: {
          i18n: "userCompany10",
        },
      },
      source: ["company", "contactPerson", "phone"],
      alternateSources: [
        ["manager", "phone"],
        ["profile", "phone"],
      ],
    },
    11: {
      text: {
        text: {
          i18n: "userCompany11",
        },
      },
      source: ["company", "contactPerson", "email"],
      alternateSources: [
        ["manager", "email"],
        ["profile", "email"],
      ],
    },
    12: {
      text: {
        text: {
          i18n: "userCompany12",
        },
      },
      source: ["company", "contactPerson", "name"],
      alternateSources: [
        ["manager", "name"],
        ["profile", "name"],
      ],
    },
    13: {
      text: {
        text: {
          i18n: "userCompany13",
        },
      },
      source: ["company", "contactPerson", "lName"],
      alternateSources: [
        ["manager", "lName"],
        ["profile", "lName"],
      ],
    },
    14: {
      text: {
        text: {
          i18n: "companyWebsite",
        },
      },
      source: ["company", "website"],
    },
  },
  profile: {
    0: {
      text: {
        text: {
          i18n: "profile0",
        },
      },
      source: ["profile", "name"],
    },
    1: {
      text: {
        text: {
          i18n: "profile1",
        },
      },
      source: ["profile", "lName"],
    },
    2: {
      text: {
        text: {
          i18n: "profile2",
        },
      },
      source: ["profile", "phone"],
    },
    3: {
      text: {
        text: {
          i18n: "profile3",
        },
      },
      source: ["profile", "email"],
    },
  },
  contactPerson: {
    0: {
      partlyFilledIsValid: true,
      text: {
        text: {
          i18n: "contactPerson0",
        },
      },
      source: ["doc", "values", "mainContractors", "contactPersonName"],
      alternateSources: [
        ["company", "contactPerson", "name"],
        ["manager", "name"],
        ["profile", "name"],
      ],
    },
    1: {
      text: {
        text: {
          i18n: "contactPerson1",
        },
      },
      source: ["doc", "values", "mainContractors", "contactPersonLName"],
      alternateSources: [
        ["company", "contactPerson", "lName"],
        ["manager", "lName"],
        ["profile", "lName"],
      ],
    },
    2: {
      text: {
        text: {
          i18n: "contactPerson2",
        },
      },
      source: ["doc", "values", "mainContractors", "contactPersonPhone"],
      alternateSources: [
        ["company", "contactPerson", "phone"],
        ["manager", "phone"],
        ["profile", "phone"],
      ],
    },
    3: {
      text: {
        text: {
          i18n: "contactPerson3",
        },
      },
      source: ["doc", "values", "mainContractors", "contactPersonEmail"],
      alternateSources: [
        ["company", "contactPerson", "email"],
        ["manager", "email"],
        ["profile", "email"],
      ],
    },
  },
};

function processRelativeTime(number, withoutSuffix, key, isFuture) {
  var format = {
    m: ["minuutti", "minuutin"],
    mm: [number + " minuuttia", number + " minuutin"],
    h: ["tunti", "tunnin"],
    hh: [number + " tuntia", number + " tunnin"],
    d: ["päivä", "päivän"],
    dd: [number + " päivää", number + " päivän"],
    M: ["kuukausi", "kuukauden"],
    MM: [number + " kuukautta", number + " kuukauden"],
    y: ["vuosi", "vuoden"],
    yy: [number + " vuotta", number + " vuoden"],
  };
  return !isFuture ? format[key][0] : format[key][1];
}

export const fiLocale = moment.defineLocale("fi", {
  months:
    "tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu".split(
      "_"
    ),
  monthsShort:
    "tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu".split(
      "_"
    ),
  weekdays:
    "sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai".split(
      "_"
    ),
  weekdaysShort: "su_ma_ti_ke_to_pe_la".split("_"),
  weekdaysMin: "su_ma_ti_ke_to_pe_la".split("_"),
  longDateFormat: {
    LT: "HH.mm",
    LTS: "HH.mm.ss",
    L: "DD.MM.YYYY",
    LL: "Do MMMM[ta] YYYY",
    LLL: "Do MMMM[ta] YYYY, [klo] LT",
    LLLL: "dddd, Do MMMM[ta] YYYY, [klo] LT",
    l: "D.M.YYYY",
    ll: "Do MMM YYYY",
    lll: "Do MMM YYYY, [klo] LT",
    llll: "ddd, Do MMM YYYY, [klo] LT",
  },
  calendar: {
    sameDay: "[tänään] [klo] LT",
    nextDay: "[huomenna] [klo] LT",
    nextWeek: "dddd [klo] LT",
    lastDay: "[eilen] [klo] LT",
    lastWeek: "[viime] dddd[na] [klo] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "%s päästä",
    past: "%s sitten",
    s: "hetki",
    m: processRelativeTime,
    mm: processRelativeTime,
    h: processRelativeTime,
    hh: processRelativeTime,
    d: processRelativeTime,
    dd: processRelativeTime,
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime,
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: "%d.",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

export const layoutCategories = [
  "general",
  "occupationalSafety",
  "hotWork",
  "construction",
  "other",
  "HVAC",
  "meetingMinutes",
  "propertyManagement",
  "asbestosAndDemolition",
  "personnel",
  "electric",
];

export const fallbackLangCodes = [
  { code: "en", name: "English" },
  { code: "fin", name: "Finnish" },
  { code: "sv", name: "Swedish" },
  { code: "no", name: "Norwegian" },
  { code: "et", name: "Estonian" },
  { code: "de", name: "German" },
];

// cell type constants
export const cellTypesWithExtraValueKey = ["datePickerCheckBox"];
// cell types that have constant nested inputs
export const cellTypesWithNestedCells = [
  "radio",
  "radioSelect",
  "dividedInputs",
];
export const cellTypesWithDynamicNestedCells = ["extraRows", "pickerObjects"];
// cell types that have inputs, constant or dynamic
export const cellTypesWithInnerCells = [
  "extraRows",
  "radio",
  "radioSelect",
  "dividedInputs",
  "pickerObjects",
];
export const cellTypesCantBeNested = [
  "extraRows",
  "radio",
  "radioSelect",
  "dividedInputs",
];
export const nonInputCellTypes = [
  "text", // constant text // no valueKey
  "filler", // empty cell // no valueKey
  "formTitle", // constant text thats visible in pdf and form
  "companyLogo",
  "dividedInputs",
  "radioSelect",
];
export const cellTypesWithoutValue = [
  "text", // constant text // no valueKey
  "filler", // empty cell // no valueKey
  "formTitle", // constant text thats visible in pdf and form
  "companyLogo",
  "pdfCheckBox", // constant text thats visible in pdf only
];

import React from "react";
import { ThemeContext } from "../../../common/theming/theme-context";
import HeaderBar from "../components/HeaderBar";

function Loading() {
  const theming = React.useContext(ThemeContext);
  return (
    <>
      <HeaderBar />
      <div style={theming.theme.loadingContent}></div>
    </>
  );
}

export default Loading;

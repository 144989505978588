import "react";
import { View } from "react-native";
import StretchButton from "./StretchButton";

const Picker = (props) => {
  const notSignInOrForgot =
    props.screen !== "signIn" && props.screen !== "verifyPassReset"
      ? true
      : false;

  return (
    <View style={props.theme.inputPickerContainer}>
      <StretchButton
        backgroundColor={notSignInOrForgot ? props.colors.lightBg : null}
        color={notSignInOrForgot ? props.colors.text : null}
        onPress={() => props.setScreen("signIn")}
        title={props.t("loginPage")}
      />
      <StretchButton
        theme={props.theme}
        colors={props.colors}
        backgroundColor={notSignInOrForgot ? null : props.colors.lightBg}
        color={notSignInOrForgot ? null : props.colors.text}
        onPress={() => props.setScreen("verifyActivate")}
        title={props.t("registration")}
      />
    </View>
  );
};
export default Picker;

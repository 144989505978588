import { useEffect, useState } from "react";
import { Platform, Text, View } from "react-native";
import Icon from "./Icon";
import DateTimePicker from "./DateTimePicker";
import TimePicker from "./TimePicker";
import { ThemeContext } from "../theming/theme-context";
import CheckBoxWithTitle from "./CheckBoxWithTitle";
import IconButton from "./IconButton";
import { useTranslation } from "react-i18next";
import { Tooltip } from "./Tooltip";
import TextInput from "./TextInput";
import { tryGetMoment } from "../lib/functions";

const datePickerRowStyle = {
  minHeight: 36,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: 8,
};
const styles = {
  datePickerRow: {
    ...datePickerRowStyle,
    width: "100%",
  },
  datePickerWCheckboxes: {
    ...datePickerRowStyle,
    minWidth: 140,
  },
  titleRow: {
    minHeight: 36,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textWrapper: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
  },
  hPadding: { paddingLeft: 8, paddingRight: 8 },
};

export default function DatePickerRow(props) {
  const { t } = useTranslation();
  const [tmpText, setTmpText] = useState(props.date ? props.date : "-");

  useEffect(() => {
    setTmpText(props.date ? props.date : "-");
  }, [props.date]);

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View
          style={[
            theme.flex,
            {
              backgroundColor: colors.primary,
              opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
            },
          ]}
        >
          {props.title ? (
            <View style={styles.titleRow}>
              <View style={styles.textWrapper}>
                {props.onClear ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    disabled={props.disabled}
                    onPress={props.onClear}
                    backgroundColor={colors.lightBg}
                    icon="trash-can-outline"
                    color={colors.text}
                  />
                ) : null}

                <View style={styles.textContainer}>
                  <Text
                    style={[
                      theme.boldText,
                      {
                        color: props.error ? "red" : colors.text,
                      },
                    ]}
                  >
                    {props.title}
                  </Text>
                </View>
              </View>
              {props.icons ? (
                <View style={theme.row}>
                  {props.icons.map((x) => (
                    <IconButton
                      key={x.icon}
                      theme={theme}
                      loading={x.loading}
                      onPress={x.onPress}
                      disabled={props.disabled}
                      icon={x.icon}
                      backgroundColor={x.backgroundColor ?? colors.accent}
                      color={x.textColor ?? colors.textOnAccent}
                    />
                  ))}
                </View>
              ) : null}
              {props.hint || props.hintTable ? (
                <View style={styles.hPadding}>
                  <Tooltip
                    hintTable={props.hintTable}
                    tip={props.hint}
                    flex={0}
                    icon={true}
                    lang={props.lang}
                  />
                </View>
              ) : null}
            </View>
          ) : null}

          <View
            style={[
              Platform.OS === "web"
                ? theme.row
                : Platform.OS === "ios"
                ? null
                : theme.flex,
              theme.flex,
              { backgroundColor: colors.lightBg },
            ]}
          >
            {props.checkBoxToDisableDate ? (
              <CheckBoxWithTitle
                disabled={props.disabled}
                booleanChecked={true}
                setChecked={() =>
                  props.checkBoxToDisableDateTitleSetChecked?.(
                    props.secondCheckTitle
                      ? true
                      : !props.checkBoxToDisableDateTitleChecked
                  )
                }
                title={props.checkBoxToDisableDateTitle || t("nodate")}
                checked={
                  props.secondCheckTitle
                    ? props.checkBoxToDisableDateTitleChecked === true
                    : props.checkBoxToDisableDateTitleChecked
                }
              />
            ) : null}
            {props.secondCheckTitle ? (
              <CheckBoxWithTitle
                disabled={props.disabled}
                booleanChecked={true}
                setChecked={() =>
                  props.checkBoxToDisableDateTitleSetChecked?.(false)
                }
                title={props.secondCheckTitle}
                checked={props.checkBoxToDisableDateTitleChecked === false}
              />
            ) : null}

            {Platform.OS === "ios" ? (
              <View style={theme.flex}>
                <View style={theme.flex}>
                  <DateTimePicker
                    themeVariant={colors.text === "#fff" ? "dark" : "light"}
                    textColor={colors.text}
                    value={props.date}
                    dateFormat={props.dateFormat}
                    disabled={
                      props.disabled ||
                      (props.checkBoxToDisableDate &&
                        !props.checkBoxToDisableDateTitleChecked)
                    }
                    onChange={(event, date) => {
                      props.setDate(date);
                    }}
                    minimumDate={props.minDate}
                    maximumDate={props.maxDate}
                    //getClockLabelText={(view, time) => setClockLabelText(time)}
                  />
                </View>

                {props.timePicker ? (
                  <>
                    <View
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon
                        size={24}
                        color={colors.accent}
                        name="drag-horizontal"
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <TimePicker
                        value={props.date}
                        dateFormat={props.dateFormat}
                        themeVariant={colors.text === "#fff" ? "dark" : "light"}
                        onChange={(event, date) => {
                          props.setDate(date);
                        }}
                        textColor={colors.text}
                      />
                    </View>
                  </>
                ) : null}
              </View>
            ) : (
              <View
                style={
                  props.checkBoxToDisableDate || props.secondCheckTitle
                    ? styles.datePickerWCheckboxes
                    : styles.datePickerRow
                }
              >
                <>
                  <TextInput
                    disabled={
                      !!props.disabled ||
                      (props.checkBoxToDisableDate &&
                        !props.checkBoxToDisableDateTitleChecked)
                    }
                    style={theme.textFieldLightBg}
                    onChangeText={(text) => setTmpText(text)}
                    onBlur={() => {
                      if (!tmpText) {
                        props.setDate();
                      }
                      const _moment = tryGetMoment(tmpText);
                      if (_moment.isValid()) {
                        props.setDate(
                          _moment.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
                        );
                        setTmpText(
                          _moment.format(
                            props.dateFormat ||
                              (props.timePicker
                                ? "HH:mm DD.MM.YYYY"
                                : "DD.MM.YYYY")
                          )
                        );
                      } else {
                        props.setDate(null);
                        setTmpText("-");
                      }
                    }}
                    value={tmpText}
                    keyboardType="decimal-pad"
                    selectTextOnFocus
                  />

                  <IconButton
                    icon="calendar-month-outline"
                    size={36}
                    color={
                      props.checkBoxToDisableDateTitleChecked ||
                      !props.checkBoxToDisableDate
                        ? colors.text
                        : colors.borderLighter
                    }
                    disabled={!!props.disabled}
                    onPress={() => {
                      if (
                        props.checkBoxToDisableDateTitleChecked ||
                        !props.checkBoxToDisableDate
                      ) {
                        props.setDatePicker();
                      }
                    }}
                  />
                </>
              </View>
            )}
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

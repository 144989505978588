// LIBRARIES
import React from "react";
import {
  View,
  Text,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  SectionList,
  Keyboard,
} from "react-native";
import moment from "moment";
import { withModal } from "react-native-modalfy";
// LIBRARIES

// COMPONENTS
import Icon from "../components/Icon";
import DocSearchModal from "../components/DocSearchModal";
import TitleRow from "../components/TitleRow";
import CellComponent from "../components/CellComponent";
import PickerObjectContent from "../components/PickerObjectContent";
import ButtonGroup from "../components/ButtonGroup";
import { Tooltip } from "../components/Tooltip";
import IconButton from "../components/IconButton";
import ModalWithButtons from "../components/ModalWithButtons";
import TemplateStringInput from "../components/TemplateStringInput";
//import MultiPickerItem from '../components/MultiPickerItem';
// COMPONENTS

// HELPERS
import {
  validatePhoneNumber,
  validateEmail,
  validateFileName,
  validateZipCode,
  validateWithRegex,
} from "../lib/validityCheckers";
import {
  fetchNetInfo,
  showToast,
  showExpiredTokenToast,
} from "../lib/helperFns";
import {
  validateSignerEmails,
  handleInitialSectionListScroll,
  errorReport,
  compareRole,
  getTranslatedText,
  checkInputVisibility,
  getReduxLayout,
  getCustomerName,
  getSiteName,
  isSameOrGreaterThanRole,
} from "../lib/functions";
import { putWithToken, getWithToken } from "../lib/api";
import { isDevBuild } from "../lib/constants";
// HELPERS

const tmpCustomersSpecialInput = {
  type: "pickerObjects",
  layoutId: "pickerObjects/3",
  optionsProp: "customers",
  prop: "name",
  layoutVersion: 1,
};
const tmpSitesSpecialInput = {
  type: "pickerObjects",
  layoutId: "pickerObjects/1",
  optionsProp: "sites",
  prop: "address",
  layoutVersion: 1,
};

const OS = Platform.OS;

const sortByFormRowAndCol = (a, b) => {
  const aRow = a.formRow ?? 0;
  const bRow = b.formRow ?? 0;
  const aCol = a.formColumn ?? 0;
  const bCol = b.formColumn ?? 0;
  if (aRow === bRow) {
    return aCol < bCol ? -1 : 1;
  } else {
    return aRow < bRow ? -1 : 1;
  }
};
// TODO rework into a functional component
export class ModularDocScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docSearchModal: {
        visible: false,
      },
      docRenameModal: {
        visible: false,
      },
      loading: false,
      togglableRowsKeys: {},
      visiblePickerObj: {
        id: null,
        setFromButton: false,
      },
    };

    this.listRef = React.createRef();
    this.mountHandled = React.createRef();
    this._layouts = [];
    this.itemHeights = [];
    //this.scrollToListEnd = this.scrollToListEnd.bind(this);
    //this.scrollToListOffset = this.scrollToListOffset.bind(this);

    this._extraValueKeys = [];
    //Cell types we wont render or edit
    this.ignoredTypes = [
      "text",
      "multiline",
      "pdfCheckBox",
      "filler",
      "pdfValues",
      "companyLogo",
    ];
    this.noValidationTypes = [
      // ! NEED TO IMPLEMENT VALIDATION FOR EXTRAROWS
      "extraRows",
      "dividedInputs",
      "formTitle",
      "extraMeasurementRow",
      "connectedCheckBoxPicker",
    ];
    this.idleTimeout;
    if (!props.docToModify.creatorId) {
      props.SYNC_VALUES_TIMESTAMPS({
        docId: props.docId,
        userId: props.profile.id,
      });
    }
    this._refs = {};
    this.refsKeys = [];
    this.infoHoverTimeout;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.lang !== nextProps.lang ||
      this.props.onlySectionWithValueKey !==
        nextProps.onlySectionWithValueKey ||
      this.state.orientation !== nextState.orientation ||
      this.props.visibleExtraRowsData !== nextProps.visibleExtraRowsData ||
      this.props.profile !== nextProps.profile ||
      this.props.options !== nextProps.options ||
      this.props.isFetching !== nextProps.isFetching ||
      this.props.fetchingDoc !== nextProps.fetchingDoc ||
      this.props.connected !== nextProps.connected ||
      this.state !== nextState ||
      this.props.formRoute !== nextProps.formRoute ||
      this.props.projects !== nextProps.projects ||
      this.props.lastDocRequests[this.props.docId] !==
        nextProps.lastDocRequests[this.props.docId] ||
      this.props.docLayout !== nextProps.docLayout ||
      this.props.saveDisabled !== nextProps.saveDisabled ||
      this.props.editorRole !== nextProps.editorRole ||
      //this.props.docToModify.date !== nextProps.docToModify.date ||
      this.props.docToModify.id !== nextProps.docToModify.id ||
      this.props.docToModify.sharedTo !== nextProps.docToModify.sharedTo ||
      (this.props.docToModify.online &&
        (this.props.docToModify.editingDisabled !==
          nextProps.docToModify.editingDisabled ||
          this.props.connectingSession !== nextProps.connectingSession ||
          this.props.editors !== nextProps.editors ||
          this.props.connectionState !== nextProps.connectionState)) ||
      this._extraValueKeys.some(
        (x) =>
          this.props.docToModify.values[x] !== nextProps.docToModify.values[x]
      ) ||
      this.props.docToModify.projectId !== nextProps.docToModify.projectId ||
      this.props.docToModify.name !== nextProps.docToModify.name
    ) {
      return true;
    } else {
      return false;
    }
  }

  // TODO memoize this when reworking to functional component
  getListData = () => {
    if (this.props.updateOnValueKeysChanged) {
      this.props.updateOnValueKeysChanged.forEach((x) => {
        this._extraValueKeys.push(x);
      });
    }
    let siteInputAdded = false;
    let customerInputAdded = false;
    let layoutIndex = 0;
    let data = [{ index: 0, key: "section0", data: [], layoutIndex }];
    let dataIndex = 0;
    const sections = this.props.docLayout?.sections;
    if (!sections) return;

    let specialInputsAdded = [];

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      if (
        this.props.onlySectionWithValueKey &&
        section.valueKey !== this.props.onlySectionWithValueKey
      ) {
        continue;
      }

      const sectionTitle = getTranslatedText(section.title, this.props.lang);
      let cellsToUse = section.cells;

      if (section.pdfOnly) continue;
      if (
        section.type === "togglableCellsSections" ||
        section.type === "modularItems" ||
        (section.separateScreen && !this.props.onlySectionWithValueKey)
      ) {
        layoutIndex++;
        data[dataIndex].data.push({
          type: "moveToScreen",
          title: sectionTitle
            ? sectionTitle
            : this.getTitleRowTitle(section.layoutId, section.layoutVersion),
          sectionIndex: i,
          navigateParams: [
            section.type,
            section.layoutId,
            section.layoutVersion,
            section.valueKey ?? i,
          ],
          layoutIndex,
        });
        if (section.separateScreen) continue;
      } else if (section.type === "togglableRows") {
        if (!this.getCellValue(section.valueKey)) {
          cellsToUse = section.alternateCells;
        }
        if (!this._extraValueKeys.includes(section.valueKey)) {
          this._extraValueKeys.push(section.valueKey);
        }
      }

      // if first section render header and footer
      if (i === 0) {
        if (this.props.docLayout.headerLayout) {
          cellsToUse =
            this.props.docLayout.headerLayout.cells.concat(cellsToUse);
        }
        if (this.props.docLayout.footerLayout) {
          cellsToUse = cellsToUse.concat(
            this.props.docLayout.footerLayout.cells
          );
        }
      }

      if (section.titleInForm) {
        layoutIndex++;
        data[dataIndex].data.push({
          type: "sectionTitle",
          title: sectionTitle,
          layoutIndex,
          data: {},
        });
      }
      if (section.type === "togglableRows") {
        layoutIndex++;
        data[dataIndex].data.push({
          type: "togglableRows",
          data: {
            valueKey: section.valueKey,
            type: "checkBox",
            title: section.toggleText,
            width: section.toggleText?.width,
          },
          layoutIndex,
        });
      }
      if (Array.isArray(cellsToUse)) {
        const _cellsToUse = [...cellsToUse];
        if (this.props.docLayout.sortFormRows) {
          _cellsToUse.sort(sortByFormRowAndCol);
        }

        _cellsToUse.forEach((cell, cellIndex) => {
          if (
            !this.props.onlySectionWithValueKey &&
            this.props.docLayout.specialInputs
          ) {
            this.props.docLayout.specialInputs.forEach(
              (specialInput, specialInputIndex) => {
                if (specialInput.pdfOnly) {
                  specialInputsAdded.push(specialInputIndex);
                } else if (
                  !specialInputsAdded.includes(specialInputIndex) &&
                  // either add the input if went over the section e.g. needed section is 4
                  // but looping already section 5 or section matches and position matches or
                  // section matches and already went over the position
                  (i > specialInput.section ||
                    (i == specialInput.section &&
                      cellIndex >= specialInput.position) ||
                    // if the specialInput doesn't have indicated position, just render it on the first cell
                    ((specialInput.section === undefined ||
                      specialInput.section === null) &&
                      (specialInput.position === undefined ||
                        specialInput.position === null)))
                ) {
                  if (!siteInputAdded && specialInput.optionsProp === "sites")
                    siteInputAdded = true;
                  if (
                    !customerInputAdded &&
                    specialInput.optionsProp === "customers"
                  )
                    customerInputAdded = true;
                  specialInputsAdded.push(specialInputIndex);
                  layoutIndex++;
                  data[dataIndex].data.push({
                    type: "specialInput",
                    specialInput,
                    layoutIndex,
                  });
                }
              }
            );
          }

          if (cell.type === "extraRows") {
            layoutIndex += 2;
            data.push({
              index: dataIndex + 1,
              key: "section" + dataIndex + 1,
              title: { cellIndex, cell, headerOnly: true },
              data: [
                {
                  cellIndex,
                  cell,
                  noHeader: true,
                  layoutIndex: layoutIndex + 1,
                },
              ],
              layoutIndex,
            });
            layoutIndex += 3;
            data.push({
              index: dataIndex + 2,
              key: "section" + dataIndex + 2,
              data: [],
              layoutIndex,
            });
            dataIndex += 2;
          } else if (!this.ignoredTypes.includes(cell.type) && !cell.pdfOnly) {
            layoutIndex++;
            data[dataIndex].data.push({
              cellIndex,
              cell,
              layoutIndex: layoutIndex,
            });
          }
        });
      }
    }

    // add missing specialInputs to the start
    if (
      !this.props.onlySectionWithValueKey &&
      this.props.docLayout.specialInputs &&
      specialInputsAdded.length !== this.props.docLayout.specialInputs
    ) {
      for (
        let specialInputIndex = 0;
        specialInputIndex < this.props.docLayout.specialInputs.length;
        specialInputIndex++
      ) {
        const specialInput =
          this.props.docLayout.specialInputs[specialInputIndex];
        if (!specialInputsAdded.includes(specialInputIndex)) {
          if (!siteInputAdded && specialInput.optionsProp === "sites") {
            siteInputAdded = true;
            if (this.props.hideSite) continue;
          }
          if (!customerInputAdded && specialInput.optionsProp === "customers") {
            customerInputAdded = true;
            if (this.props.hideCustomer) continue;
          }
          specialInputsAdded.push(specialInputIndex);
          layoutIndex++;
          data[0].data.unshift({
            type: "specialInput",
            specialInput,
            layoutIndex,
          });
        }
      }
    }

    // if doc is created with params from integration partner, add site and customer even if the layout doesn't  have them
    if (this.props.docToModify?.values?.integrationKey) {
      if (!this.props.hideSite && !siteInputAdded) {
        layoutIndex++;
        data[0].data.unshift({
          type: "specialInput",
          specialInput: {
            ...tmpSitesSpecialInput,
            valueKey: this.props.docToModify.values.integrationKey,
            layoutIndex,
          },
        });
      }
      if (!this.props.hideCustomer && !customerInputAdded) {
        layoutIndex++;
        data[0].data.unshift({
          type: "specialInput",
          specialInput: {
            ...tmpCustomersSpecialInput,
            valueKey: this.props.docToModify.values.integrationKey,
          },
          layoutIndex,
        });
      }
    }

    return { sections: data, count: layoutIndex + 1 };
    // this.setState({ sections: data, count: layoutIndex + 1 });
  };

  handleInitialScroll = () => {
    handleInitialSectionListScroll(
      this.mountHandled,
      this.listRef,
      null,
      this.props.setVisible,
      null,
      this.props.scrollPosition
    );
  };
  handleMount = async () => {
    this.props.docLayout?.sections?.forEach((section) => {
      let cellsToUse = section.cells;
      if (
        section.type === "togglableRows" &&
        !this.getCellValue(section.valueKey)
      ) {
        cellsToUse = section.alternateCells;
      }
      if (Array.isArray(cellsToUse)) {
        cellsToUse = [...cellsToUse];
        if (this.props.docLayout.sortFormRows) {
          cellsToUse.sort(sortByFormRowAndCol);
        }
        cellsToUse.forEach((cell) => {
          if (
            !this.ignoredTypes.includes(cell.type) &&
            !cell.pdfOnly &&
            checkInputVisibility({
              lang: this.props.lang,
              input: cell,
              values: this.props.docToModify?.values,
              valueKeyPrefix: cell.valueKey
                ?.split?.("_")
                .slice(0, -1)
                .join("_"),
              role: this.props.profile?.role,
            })
          ) {
            if (
              cell.type === "dividedInputs" &&
              !this.ignoredTypes.includes(cell.type) &&
              !cell.pdfOnly &&
              checkInputVisibility({
                lang: this.props.lang,
                input: cell,
                values: this.props.docToModify?.values,
                valueKeyPrefix: cell.valueKey
                  ?.split?.("_")
                  .slice(0, -1)
                  .join("_"),
                role: this.props.profile?.role,
              })
            ) {
              cell.inputs?.forEach((input) => {
                this._refs[input.valueKey] = {
                  title: input.formTitle || input.title,
                  type: input.type,
                  valueKey: input.valueKey,
                  ref: null,
                };
                this.refsKeys.push(input.valueKey);
              });
            } else if (cell.valueKey) {
              this._refs[cell.valueKey] = {
                title: cell.formTitle || cell.title,
                type: cell.type,
                valueKey: cell.valueKey,
                ref: null,
              };
              this.refsKeys.push(cell.valueKey);
            }

            // _refs.push({
            //   title: cell.title,
            //   type: cell.type,
            //   valueKey: cell.valueKey,
            //   ref: React.createRef(),
            // });
          }
        });
      }
    });
    // this.getListData();
  };

  onOrientationChange = ({ window: { width, height } }) => {
    // portrait = 1
    // landscape = 2
    if (width < height) {
      this.setState({ orientation: 1 });
    } else {
      this.setState({ orientation: 2 });
    }
  };

  componentDidMount() {
    this.onOrientationChange({ window: Dimensions.get("window") });
    this.dimensionsListener = Dimensions.addEventListener(
      "change",
      this.onOrientationChange
    );
    this.handleMount();
  }

  focusNextInput = (valueKey) => {
    const index = this.refsKeys.findIndex((x) => x === valueKey);
    const curCellRef = this._refs[this.refsKeys[index]];
    if (index !== -1) {
      // ! FOCUS ONLY IF ONE OF THE NEXT 3 INPUTS CAN BE FOCUSED
      for (let i = index + 1; i < index + 4; i++) {
        const cellRef = this._refs[this.refsKeys[i]];
        if (cellRef?.ref) {
          if (
            cellRef.type === "textField" ||
            cellRef.type === "multilineField"
          ) {
            if (cellRef.ref.focus) {
              cellRef.ref?.focus();
              break;
            } else if (curCellRef?.ref?.blur) {
              curCellRef.ref.blur();
            }
          } else if (curCellRef?.ref?.blur) {
            curCellRef.ref.blur();
          }
        } else if (curCellRef?.ref?.blur) {
          curCellRef.ref.blur();
        }
      }
      // ! FOCUS ONLY IF THE NEXT INPUT CAN BE FOCUSED
      // const cellRef = this.state[this.refsKeys[index + 1]];
      // if (cellRef?.ref) {
      //   if (cellRef.type === "textField" || cellRef.type === "multilineField") {
      //     if (cellRef.ref.focus) cellRef.ref?.focus();
      //     else if (curCellRef?.ref?.blur) {
      //       curCellRef.ref.blur();
      //     }
      //   } else if (curCellRef?.ref?.blur) {
      //     curCellRef.ref.blur();
      //   }
      // } else if (curCellRef?.ref?.blur) {
      //   curCellRef.ref.blur();
      // }
      // ! FINDS THE NEXT FOCUSABLE INPUT
      // for (let i = index + 1; i < this.refsKeys.length; i++) {
      //   const cellRef = this.state[this.refsKeys[i]];
      //   console.warn("trying to focus", this.refsKeys[i]);
      //   // if (cellRef.type === "datePicker") {
      //   //   //console.warn("toggleDatePicker calling cellRef", cellRef);
      //   //   this.toggleDatePicker(
      //   //     this.props.docToModify.values?.[cellRef.valueKey],
      //   //     cellRef.valueKey,
      //   //     getTranslatedText(cellRef.title, this.props.lang),
      //   //     true
      //   //   );
      //   //   break;
      //   // } else
      //   if (cellRef?.ref) {
      //     console.warn("focusNextInput hasRef", cellRef);
      //     if (
      //       cellRef.type === "textField" ||
      //       cellRef.type === "multilineField"
      //     ) {
      //       console.warn("focusNextInput focus", cellRef.ref.focus);
      //       if (cellRef.ref.focus) cellRef.ref?.focus();
      //       break;
      //     }
      //   }
      // }
    } else if (curCellRef?.ref?.blur) {
      curCellRef.ref.blur();
    }
  };

  setCellRef = (valueKey, cell, _ref) => {
    if (cell && _ref && !this.state[valueKey]?.ref?.current) {
      this._refs[valueKey] = {
        title: cell.formTitle || cell.title,
        type: cell.type,
        valueKey: cell.valueKey,
        ref: _ref,
      };
      // this.setState({
      //   [valueKey]: {
      //     title: cell.formTitle || cell.title,
      //     type: cell.type,
      //     valueKey: cell.valueKey,
      //     ref: _ref,
      //   },
      // });
    }
    // const index = this.state.cellRefs.findIndex(
    //   (x) => x.valueKey === valueKey
    // );
    // if (index !== -1 && this.state.cellRefs[index]?.ref?.current === null) {
    //   this.setState({
    //     cellRefs: update(this.state.cellRefs, {
    //       [index]: { ref: { $set: _ref } },
    //     }),
    //   });
    // }
  };

  // saveDoc = (doc, lastDocRequests, calledOnClose, fn) => {
  //   if (this.props.viewingCompletedDoc) {
  //     console.warn("viewingCompletedDoc save");
  //     if (fn) fn();
  //   } else {
  //     this.props.saveDoc(doc, lastDocRequests, calledOnClose, fn);
  //   }
  // };

  componentWillUnmount() {
    // TODO is save needed on unmount, now React causes multiple unmounts when adding doc
    // if (!this.props.viewUnfinishedLayout && !this.props.docToModify.online) {
    // this.props.saveDoc({
    //   calledOnClose: true,
    // });
    // }
    this.dimensionsListener.remove();
  }

  componentDidUpdate() {
    // TODO memoization helper for list data
    // if (
    //   this.props.docLayout !== prevProps.docLayout ||
    //   this.props.lang !== prevProps.lang
    // ) {
    //   this.getListData();
    // }
  }
  //componentDidUpdate() {
  // if (!this.props.isFetching && !this.state.loading) {
  //   let shouldSaveDoc = true;
  //   if (this.props.lastDocRequests[this.props.docId]) {
  //     var a = moment(this.props.lastDocRequests[this.props.docId].date);
  //     var b = moment(this.props.docToModify.date);
  //     const diff = moment.duration(b.diff(a)).asSeconds();
  //     if (diff < 10) shouldSaveDoc = false;
  //   }
  //   if (shouldSaveDoc) {
  //     this.saveDoc(this.props.docToModify, this.props.lastDocRequests);
  //   }
  // }
  //}

  // onWillBlur = () => {
  //   this.saveDoc(this.props.docToModify, this.props.lastDocRequests);
  // };

  // getOffsetByIndex(index) {
  //   let offset = 0;
  //   if (!isNaN(index)) {
  //     for (let i = 0; i < index; i++) {
  //       const elementLayout = this._layouts[i];
  //       if (elementLayout) {
  //         offset += elementLayout + 16;
  //       }
  //     }
  //   }
  //   return offset;
  // }

  // scrollToListEnd() {
  //   if (this.listRef.scrollToEnd) this.listRef.scrollToEnd();
  // }

  // moveToTitle = (item) => {
  //   const moveTo =
  //     this.props.testID === 'InspectionDoc'
  //       ? inspectionMoveToTitles[item]
  //       : groupLevelMoveToTitles[item];
  //   if (moveTo) {
  //     if (moveTo === 'end') {
  //       this.scrollToListEnd();
  //     } else {
  //       const offset = this.getOffsetByIndex(moveTo);
  //       this.scrollToListOffset(offset);
  //     }
  //   }
  // };

  // getIDFromIndices(sectionIndex, row, column) {
  //   const id = ['s' + sectionIndex, 'r' + row, 'c' + column].join('-');
  //   return id;
  // }

  validateCell = (
    cell,
    valueKeyPrefix,
    missingValues,
    missingOptionalValues,
    invalidated,
    nestedString
  ) => {
    if (cell.pdfOnly || !Object.prototype.hasOwnProperty.call(cell, "valueKey"))
      return;

    const invalidValues = [undefined, null, NaN, ""];
    const values = this.props.docToModify.values;
    const valueKey =
      (valueKeyPrefix ? valueKeyPrefix + "_" : "") + cell.valueKey;
    const cellValue = values[valueKey];

    let validated = true;

    if (cell.validation) {
      const validations = {
        zipcode: validateZipCode,
        phone: validatePhoneNumber,
        file: validateFileName,
        email: validateEmail,
      };

      if (validations[cell.validation]) {
        validated = validations[cell.validation](cellValue);
      }
      // try to use regex with the value
      else {
        validated = validateWithRegex(cell.validation, cellValue);
      }
    }

    const cellHasValue =
      !invalidValues.includes(cellValue) &&
      (Array.isArray(cellValue) ? cellValue.length > 0 : true);
    const cellChecked =
      checkInputVisibility({
        lang: this.props.lang,
        input: cell,
        values,
        valueKeyPrefix,
        role: this.props.profile?.role,
      }) &&
      !cell.optional &&
      !cellHasValue &&
      !this.ignoredTypes.includes(cell.type) &&
      !this.noValidationTypes.includes(cell.type);

    const setValidationString = () => {
      if (cellChecked || !validated) {
        let target = validated
          ? cell.required
            ? missingValues
            : missingOptionalValues
          : cell.required
          ? missingValues
          : invalidated;

        const cellTitle = getTranslatedText(
          cell.formTitle || cell.title,
          this.props.lang
        );

        if (nestedString) {
          target[valueKey] = [
            nestedString,
            (target[valueKey]?.[1] ?? []).concat(cellTitle),
          ];
          // target[nestedString] = (target[nestedString] ?? []).concat(cellTitle);
        } else {
          const str = `- ${cellTitle}`;
          target[valueKey] = [str, null];
        }
      }
    };

    if (cell.type === "datePickerCheckBox") {
      if (
        values[
          (valueKeyPrefix ? valueKeyPrefix + "_" : "") + cell.valueKey + ".5"
        ]
      ) {
        setValidationString();
      }
    } else if (cell.defaultValueKey) {
      if (!cellHasValue) {
        this.validateCell(
          {
            ...cell,
            valueKey: cell.defaultValueKey,
          },
          valueKeyPrefix,
          missingValues,
          missingOptionalValues,
          invalidated,
          nestedString
        );
      }
    } else {
      setValidationString();
    }
  };

  validateCellType = (
    cell,
    valueKeyPrefix,
    missingValues,
    missingOptionalValues,
    invalidated,
    nestedString
  ) => {
    const cellTitle = getTranslatedText(
      cell.formTitle || cell.title,
      this.props.lang
    );
    const _cellTitle = cell.parentTitle
      ? {
          ...(cell.formTitle || cell.title),
          text: {
            [this.props.lang]:
              getTranslatedText(cell.parentTitle, this.props.lang) +
              (cellTitle ? ` ${cellTitle}` : ""),
          },
        }
      : cell.formTitle || cell.title;
    let validateCell = true;
    if (cell.type === "dividedInputs") {
      validateCell = false;

      cell.inputs?.forEach((_cell) => {
        const __cellTitle = getTranslatedText(
          _cell.formTitle || _cell.title,
          this.props.lang
        );
        this.validateCell(
          {
            ..._cell,
            title: {
              ...(_cell.formTitle || _cell.title),
              text: {
                [this.props.lang]:
                  getTranslatedText(_cellTitle, this.props.lang) +
                  (__cellTitle ? ` ${__cellTitle}` : ""),
              },
            },
          },
          valueKeyPrefix,
          missingValues,
          missingOptionalValues,
          invalidated,
          nestedString
        );
      });
    } else if (
      (cell.type === "chart" || cell.type === "graph") &&
      cell.chartData?.dataProps
    ) {
      validateCell = false;
    } else if (
      cell.type === "datePicker" &&
      getTranslatedText(cell.defaultValue, this.props.lang) === "{date}"
    ) {
      validateCell = false;
    }

    if (validateCell) {
      this.validateCell(
        cell.parentTitle
          ? {
              ...cell,
              title: _cellTitle,
            }
          : cell,
        valueKeyPrefix,
        missingValues,
        missingOptionalValues,
        invalidated,
        nestedString
      );
    }
  };

  validateValues() {
    const sections = this.props.docLayout.sections;

    const missingValues = {};
    const missingOptionalValues = {};
    const invalidated = {};

    //The values that are not accepted as valid
    const invalidValues = [undefined, null, NaN, ""];
    const values = this.props.docToModify.values;

    this.props.docLayout.specialInputs?.forEach((item) => {
      if (!item.optional && !item.pdfOnly) {
        if (item.type === "measurementObjects") {
          const value = this.getCellValue(item.layoutId);
          // No instances of the measurement object have been made
          if (value) {
            const measObj = getReduxLayout(
              this.props.options,
              item?.layoutId,
              item.layoutVersion
            );
            const measurementsToBeChecked = measObj?.titles ?? {};
            const extraDataToBeChecked = measObj?.extraData ?? [];

            value.forEach(({ id, valueKey }) => {
              const str = `- ${id}`;

              Object.entries(measurementsToBeChecked).forEach(
                ([key, _value]) => {
                  const _valueKey = `${item.layoutId}_${valueKey}_${key}`;
                  if (!values[_valueKey] || !values[_valueKey]?.worstValue) {
                    missingOptionalValues[_valueKey] = [
                      str,
                      (missingOptionalValues[_valueKey]?.[1] ?? []).concat(
                        getTranslatedText(_value, this.props.lang)
                      ),
                    ];
                    // missingOptionalValues[str] = (
                    //   missingOptionalValues[str] ?? []
                    // ).concat(getTranslatedText(_value, this.props.lang));
                  }
                }
              );

              extraDataToBeChecked.forEach((cell) => {
                if (
                  !cell.optional &&
                  !cell.pdfOnly &&
                  Object.prototype.hasOwnProperty.call(cell, "valueKey")
                ) {
                  this.validateCellType(
                    cell,
                    `${item.layoutId}_${valueKey}`,
                    missingValues,
                    missingOptionalValues,
                    invalidated,
                    str
                  );
                }
              });
            });
          }
        } else {
          const _valueKey = `${item.optionsProp}_${item.valueKey || ""}`;
          const value = this.getCellValue(_valueKey);

          const _title = getTranslatedText(
            getReduxLayout(
              this.props.options,
              item.layoutId,
              item.layoutVersion
            )?.title,
            this.props.lang
          );

          if (invalidValues.includes(value)) {
            // Special picker - invalid value
            missingOptionalValues[_valueKey] = ["- " + _title, null];
            // missingOptionalValues["- " + _title] = null;
          }
        }
      }
    });

    sections.forEach((section, sectionIndex) => {
      const { cells, type, valueKey, alternateCells } = section;
      if (type === "togglableCellsSections") {
        const togglableBase = getReduxLayout(
          this.props.options,
          section.layoutId,
          section.layoutVersion
        );

        togglableBase?.items?.forEach((item) => {
          const innerValueKey = `${sectionIndex}_${section.layoutId}_${item.valueKey}`;

          const nestedString = `- ${getTranslatedText(
            item.title,
            this.props.lang
          )}:`;

          if (!this.getCellValue(innerValueKey)) {
            item.inputs.forEach((cell) => {
              if (Object.prototype.hasOwnProperty.call(cell, "valueKey")) {
                this.validateCellType(
                  {
                    ...cell,
                    title:
                      cell.formTitle ??
                      cell.title ??
                      cell.firstCheckTitle ??
                      cell.unit,
                  },
                  `${sectionIndex}_${section.layoutId}`,
                  missingValues,
                  missingOptionalValues,
                  invalidated,
                  nestedString
                );
              }
            });
          }
        });
      } else if (type === "modularItems") {
        if (!section.pdfOnly) {
          const modularItem =
            getReduxLayout(
              this.props.options,
              section?.layoutId,
              section?.layoutVersion
            ) ?? {};

          const modularItemValue = values[section.layoutId];

          modularItemValue?.forEach((_modularItem) => {
            if (_modularItem.innerItems) {
              _modularItem?.innerItems?.forEach(({ valueKey, title }) => {
                modularItem.items.forEach((cell) => {
                  const nestedString = `- ${
                    _modularItem.title
                  } -> ${getTranslatedText(title, this.props.lang)}:`;

                  this.validateCellType(
                    cell,
                    `${section.layoutId}_${_modularItem.valueKey}_${valueKey}`,
                    missingValues,
                    missingOptionalValues,
                    invalidated,
                    nestedString
                  );
                });
              });
            } else {
              missingOptionalValues[
                `${section.layoutId}_${_modularItem.valueKey}_${valueKey}`
              ] = [`- ${_modularItem.title} ${this.props.t("isEmpty")}`, null];
              // missingOptionalValues[`- ${_modularItem.title} ${this.props.t("isEmpty")}`] = null;
            }
          });
        }
      } else {
        let cellsToUse = cells;

        if (type === "togglableRows" && !this.getCellValue(valueKey)) {
          cellsToUse = alternateCells;
        }

        if (Array.isArray(cellsToUse)) {
          cellsToUse.forEach((cell) => {
            this.validateCellType(
              cell,
              "",
              missingValues,
              missingOptionalValues,
              invalidated
            );
          });
        }
      }
    });

    return {
      missingValues,
      missingOptionalValues,
      invalidated,
    };
  }

  moveToPreview = (canComplete) => {
    if (this.props.docLayout?.replaceCreationAction) {
      if (
        this.props.docLayout.replaceCreationAction.action ===
        "sendDocWithoutCompleting"
      ) {
        this.sendDocWithoutCompleting();
      } else {
        showToast(
          "Missing replaceCreationAction: " +
            this.props.docLayout?.replaceCreationAction
        );
      }
    } else {
      const { id, emails = null, signatures = null } = this.props.docToModify;

      let screenToNavigateTo = "RecipientsAndSigners";

      if (canComplete) {
        if (Array.isArray(emails) && emails.length > 0) {
          const signerErrors = validateSignerEmails(
            false,
            signatures,
            this.props.t,
            this.props.lang
          );
          if (!signerErrors) {
            screenToNavigateTo = "PreviewAndSign";
          } else {
            showToast(signerErrors);
          }
        }
      } else {
        screenToNavigateTo = "PreviewAndSign";
      }

      if (!this.props.demo && OS === "web") {
        this.props.navigate(
          encodeURI(`${screenToNavigateTo}?id=${encodeURIComponent(id)}`),
          this.props.navigation,
          {
            generateModularPDF: true,
            viewUnfinishedLayout: this.props.viewUnfinishedLayout,
          }
        );
      } else {
        this.props.navigate(screenToNavigateTo, this.props.navigation, {
          generateModularPDF: true,
          viewUnfinishedLayout: this.props.viewUnfinishedLayout,
        });
      }
    }
  };

  handleMissingValuePress = (valueKey, sections) => {
    const splitValueKey = valueKey.split("_");
    if (splitValueKey.length === 1) {
      let found,
        index = 0,
        sectionIndex = 0;
      for (let i = 0; i < sections.length; i++) {
        if (found) break;
        const section = sections[i];
        for (let j = 0; j < section.data.length; j++) {
          const item = section.data[j];
          if (item.cell?.valueKey === valueKey) {
            found = item.cell;
            sectionIndex = i;
            index = j;
            break;
          }
        }
      }
      if (found) {
        try {
          this.listRef?.current.scrollToLocation({
            animated: true,
            itemIndex: index,
            viewPosition: 0,
            sectionIndex,
          });
        } catch {
          /* empty */
        }
      }
    } else if (splitValueKey.length > 1) {
      // togglable valueKey scheme is `${sectionValueKey}_${layoutId}_${layoutCell.valueKey}`
      // modular header items         `${layoutId}_${addedItem.valueKey}_${layoutCell.valueKey}`
      // modular inner items          `${layoutId}_${addedItem.valueKey}_${addedItemInnerItemValueKey}_${layoutCell.valueKey}`
      // meas obj                     `${layoutId}_${addedItem.valueKey}_${layoutCell.valueKey}`
      let isTogglableSection = false;
      const layoutSections = this.props.docLayout.sections;
      // todo handle splitValueKey[1] being a valueKey
      const sectionIndex = layoutSections.findIndex((x) => {
        if (splitValueKey[1].startsWith("togglableCellsSections")) {
          if (x.layoutId === splitValueKey[1]) {
            isTogglableSection = true;
            return true;
          } else {
            return false;
          }
        } else {
          return x.layoutId === splitValueKey[0];
        }
      });
      //measurementObjects/1_14u1b_1
      if (sectionIndex !== -1) {
        const section = layoutSections[sectionIndex];
        this.navigateWithParams(
          section.type,
          section.layoutId,
          section.layoutVersion,
          section.valueKey ?? sectionIndex,
          splitValueKey,
          isTogglableSection ? null : splitValueKey[1]
        );
      }
      // TODO handle extrarows etc
    }
  };

  updateCustomStatusInDoc = (newKey) => {
    // call endpoint to progress doc into next status
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        return putWithToken(
          {
            statuses: this.props.docLayout.statuses,
            id: this.props.docToModify.id,
            newKey,
          },
          "/docs/nextCustomStatus"
        ).then((res) => {
          if (res.status === 200) {
            if (res.data) {
              if (res.data.customStatus)
                this.props.setDocProp({
                  docId: this.props.docToModify.id,
                  prop: "customStatus",
                  value: res.data.customStatus,
                });
              if (res.data.path) {
                this.props.setDocProp({
                  docId: this.props.docToModify.id,
                  prop: "path",
                  value: res.data.path,
                });
              }
            }
            showToast(this.props.t("statusUpdated"));
          } else {
            //"Missing folder structure for document"
            showToast(this.props.t("unhandledError"));
          }
        });
      } else {
        showToast(this.props.t("checkInternetConnection"));
      }
    });
  };

  handleExtraDocActions = (action) => {
    switch (action.action) {
      case "moveToNextStatus":
        this.updateCustomStatusInDoc();
        break;
      case "setCustomStatus":
        this.updateCustomStatusInDoc(action.fnProps?.[0]);
        break;
      default:
        console.warn("missing action");
        break;
    }
  };

  handleDocValuesValidation = async (canComplete, sections) => {
    try {
      if (!sections) {
        this.moveToPreview(canComplete);
        return;
      }
      const data = this.validateValues() || {};

      const { missingValues, missingOptionalValues, invalidated } = data;

      const missingValuesEntries = Object.entries(missingValues);
      const missingOptionalValuesEntries = Object.entries(
        missingOptionalValues
      );
      const invalidatedEntries = Object.entries(invalidated);

      const mapToData = (entries, requiredValues) => {
        return entries.map(([valueKey, arr]) => {
          const [key, value] = arr;
          if (value === null || value === undefined) {
            return {
              id: valueKey,
              title: key + (requiredValues ? " *" : ""),
              action: () => this.handleMissingValuePress(valueKey, sections),
            };
          } else {
            return {
              id: valueKey,
              title:
                key +
                (requiredValues ? " *" : "") +
                ["", ...value].join("\n\t\t-- "),
              action: () => this.handleMissingValuePress(valueKey, sections),
            };
          }
        });
      };

      if (!missingValuesEntries.length) {
        if (missingOptionalValuesEntries.length || invalidatedEntries.length) {
          this.props.setModalPicker({
            title: this.props.t("check"),
            visible: true,
            textProp: "title",
            onMiddleButtonPress: invalidated.length
              ? null
              : () => {
                  this.props.closeModalPicker();
                  this.moveToPreview(canComplete);
                },
            middleButtonTitle: this.props.t("createAnyway"),
            data: mapToData(missingOptionalValuesEntries).concat(
              mapToData(invalidatedEntries)
            ),
          });
        } else {
          this.props.closeModalPicker();
          this.moveToPreview(canComplete);
        }
      } else {
        this.props.setModalPicker({
          title: this.props.t("check"),
          visible: true,
          textProp: "title",
          data: mapToData(missingValuesEntries, true)
            .concat(mapToData(invalidatedEntries))
            .concat(mapToData(missingOptionalValuesEntries)),
        });
      }
    } catch (error) {
      errorReport({
        error: error,
        errorInFn: "validateValues",
        errorInScreen: "ModularDoc",
      });
      this.props.closeModalPicker();
      this.moveToPreview(canComplete);
    }
  };

  docSaveCallback =
    (canComplete, sections) => (_doc, _docWasModified, _errorMsg) => {
      if (!_errorMsg) {
        this.handleDocValuesValidation(canComplete, sections);
      }
    };

  handleCreatePdfPress = async (canComplete, sections) => {
    Keyboard.dismiss();
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        this.props.saveDocToDb({
          forceSave: true,
          doc: this.props.docToModify,
          options: this.props.options,
          profile: this.props.profile,
          company: this.props.company,
          manager: this.props.manager,
          t: this.props.t,
          lang: this.props.lang,
          callback: this.docSaveCallback(canComplete, sections),
          navigation: this.props.navigation,
          lastDocSaveDate: this.props.lastDocSaveDate,
        });
      } else {
        showToast(this.props.t("createPdfSaveDocNetworkErr"));
      }
    });
  };

  getCellValue(itemID) {
    return this.props.docToModify.values[itemID];
  }

  onDatePickerConfirm = (
    year,
    month,
    monthIndex,
    day,
    valueKey,
    time,
    closeModal
  ) => {
    if (valueKey) {
      this.props.modifyValue({
        docId: this.props.docId,
        valueKey,
        value: time
          ? moment(
              `${day}.${monthIndex}.${year}T${time}`,
              "DD.M.YYYYTHH:mm"
            ).format("HH:mm DD.MM.YYYY")
          : `${day}.${monthIndex}.${year}`,
      });
      if (closeModal || (OS !== "web" && !time) || OS === "web") {
        this.toggleDatePicker();
      }
      this.focusNextInput(valueKey);
    }
  };

  toggleDocSearchModal = (valueKey, title, defaultSearch) => {
    this.setState({
      docSearchModal: {
        ...this.state.docSearchModal,
        visible: !this.state.docSearchModal.visible,
        valueKey,
        title,
        defaultSearch,
      },
    });
  };

  openProjectPicker = () => {
    return fetchNetInfo().then((state) => {
      if (state.isConnected) {
        this.setState({ refreshingProjects: true });
        let url = `/projects`;
        let body = { type: "unfinished" };
        return getWithToken(url, null, body).then((response) => {
          if (response.status === 200) {
            this.props.REFRESH_PROJECTS({
              data: response.data,
              arrName: "unfinished",
              options: this.props.options,
              lang: this.props.lang,
            });
            this.props.dispatchState({
              type: "mergeState",
              value: {
                setModal: {
                  modal: "picker",
                  props: {
                    visible: true,
                    refreshing: this.state.refreshingProjects,
                    onSelect: (val) => {
                      showToast(
                        this.props.t("unfinishedAttachedToProjectInfo"),
                        5000,
                        "accent"
                      );
                      this.props.setDocProp({
                        docId: this.props.docToModify.id,
                        prop: "projectId",
                        value: val.id,
                      });
                    },
                    addableProp: false,
                    multiPicker: false,
                    value: this.props.docToModify.projectId
                      ? this.getProjectTitleWithId(
                          this.props.docToModify.projectId
                        )
                      : "",
                    getItemText: (item) => this.getProjectTitleWithId(item.id),
                    title: this.props.t("attachToProject"),
                    prop: "projectId",
                    options: response.data ? Object.values(response.data) : [],
                    itemTextProps: [{ prop: "title" }],
                  },
                },
                modalPicker: { visible: false },
              },
            });
            this.setState({ refreshingProjects: false });
          } else if (response.status === 204) {
            this.setState({ refreshingProjects: false });
          } else if (response === "expired" || response === "tokenErr") {
            this.props.signOut();
            showExpiredTokenToast();
          } else if (response === "failure") {
            this.setState({ refreshingProjects: false });
            showToast(
              `${this.props.t("listRefreshFailed")}. ${this.props.t(
                "ifErrContinuesContactSupport2"
              )}`
            );
          } else if (response === "timeout") {
            this.setState({ refreshingProjects: false });
            showToast(this.props.t("lostConnectionToServer"));
          } else {
            this.setState({ refreshingProjects: false });
            showToast(this.props.t("unhandledError"));
          }
        });
      } else {
        showToast(this.props.t("checkInternetConnection"));
      }
    });
  };

  handleInfoOpen = (isVisible, id, valueObj, setFromButton, onMouseEnter) => {
    if (setFromButton) {
      if (this.state.visiblePickerObj.setFromButton && isVisible) {
        this.setState({
          visiblePickerObj: {
            id: null,
            setFromButton: false,
            valueObj,
          },
        });
      } else {
        this.setState({
          visiblePickerObj: {
            id,
            setFromButton,
            valueObj,
          },
        });
      }
    } else if (!this.state.visiblePickerObj.setFromButton) {
      if (onMouseEnter) {
        this.infoHoverTimeout = setTimeout(
          () =>
            this.setState({
              visiblePickerObj: {
                id,
                setFromButton,
                valueObj,
              },
            }),
          300
        );
      } else {
        clearTimeout(this.infoHoverTimeout);
        this.setState({
          visiblePickerObj: {
            id: null,
            setFromButton: false,
            valueObj,
          },
        });
      }
    }
  };

  getSpecialInput(item = {}, index) {
    if (item.type === "measurementObjects") {
      const _itemTitle = getTranslatedText(item.title, this.props.lang);
      const _measObjTitle = getTranslatedText(
        getReduxLayout(this.props.options, item?.layoutId, item.layoutVersion)
          ?.title,
        this.props.lang
      );

      const title = _itemTitle || _measObjTitle;

      return (
        <React.Fragment
          key={"specialInputContainer" + item.section + item.position + index}
        >
          <View style={this.props.theme.listDivider} />
          <TitleRow
            disabled={false}
            docId={this.props.docId}
            handleTitleClick={() =>
              this.changeScreen(
                item.valueKey,
                item.layoutId,
                item.layoutVersion
              )
            }
            title={title}
            screen={"groups"}
          />
        </React.Fragment>
      );
    } else if (
      item.type === "pickerObjects" ||
      item.type === "multiObjPicker" ||
      item.type === "picker" ||
      item.type === "pickerTextarea" ||
      item.type === "pickerTextField" ||
      item.type === "multiPicker"
    ) {
      const _valueKey = `${item.optionsProp}_${item.valueKey || ""}`;
      const multi =
        item.type === "multiObjPicker" || item.type === "multiPicker";
      //const multiValues = this.props.docToModify?.values?.[_valueKey];
      const pickerObject = getReduxLayout(
        this.props.options,
        item.layoutId,
        item.layoutVersion
      );

      let valueObj = {};
      let _id = this.props?.docToModify?.values?.[_valueKey]?.id;

      if (_id) {
        valueObj = this.props.options?.[item.optionsProp]?.[_id];
      }

      if (!valueObj) {
        valueObj = this.props.docToModify.values?.[_valueKey];
      }

      if (!this._extraValueKeys.includes(_valueKey)) {
        this._extraValueKeys.push(_valueKey);
      }

      const _text =
        item.optionsProp === "customers"
          ? getCustomerName(valueObj)
          : item.optionsProp === "sites"
          ? getSiteName(valueObj)
          : item.prop
          ? valueObj?.[item.prop]
          : valueObj;

      const infoVisible = item.openByDefault
        ? !!_id
        : _id && this.state.visiblePickerObj?.id === _id;
      const _itemTitle = getTranslatedText(item.title, this.props.lang);
      const _title = getTranslatedText(pickerObject?.title, this.props.lang);
      return (
        <View
          key={"specialInputContainer" + item.section + item.position + index}
          style={this.props.theme.flex}
        >
          <View style={this.props.theme.lightBgContainer}>
            <ButtonGroup
              title={_itemTitle || _title}
              buttons={
                this.props.customToken
                  ? null
                  : [
                      {
                        value: this.props.t("select"),
                        title: this.props.t("select"),
                        onPress: () => {
                          this.props.setInputModal({
                            title: getTranslatedText(
                              pickerObject?.title,
                              this.props.lang
                            ),
                            inputsOnly: false,
                            addOnSave: false,
                            onSave: null,
                            item,
                            multi,
                            id: _id,
                            visible: !this.props.inputModal.visible,
                            inputs: pickerObject?.inputs,
                            onlineOnly: item.onlineOnly,
                            extraActions: item.extraActions,
                            closeOnSave: true,
                            identifier: item.valueKey,
                          });
                        },
                      },
                      {
                        value: this.props.t("addNew"),
                        title: this.props.t("addNew"),
                        onPress: () =>
                          this.props.setInputModal({
                            title: getTranslatedText(
                              pickerObject?.title,
                              this.props.lang
                            ),
                            inputsOnly: true,
                            addOnSave: true,
                            onSave: null,
                            item,
                            multi,
                            id: _id,
                            visible: !this.props.inputModal.visible,
                            inputs: pickerObject?.inputs,
                            onlineOnly: item.onlineOnly,
                            extraActions: item.extraActions,
                            closeOnSave: true,
                            identifier: item.valueKey,
                          }),
                      },
                    ]
              }
            />

            {_id || _text ? (
              <View style={this.props.theme.row}>
                <IconButton
                  onPress={() => {
                    this.props.modifyValue({
                      valueKey: _valueKey,
                      value: null,
                      docId: this.props.docToModify.id,
                    });
                  }}
                  backgroundColor={this.props.colors.lightBg}
                  color={this.props.colors.text}
                  icon="close"
                />

                {_id && _id.startsWith("local") ? (
                  <Tooltip flex={0} tip={this.props.t("onlyLocalSave")}>
                    <View style={this.props.theme.iconButton}>
                      <Icon
                        name={"cloud-off-outline"}
                        size={24}
                        color={this.props.colors.text}
                      />
                    </View>
                  </Tooltip>
                ) : null}

                <TouchableOpacity
                  style={{
                    height: 36,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flex: 1,
                    alignSelf: "stretch",
                  }}
                  onPress={
                    item.openByDefault
                      ? null
                      : () =>
                          this.handleInfoOpen(infoVisible, _id, valueObj, true)
                  }
                >
                  <Text
                    style={[
                      this.props.theme.boldText,
                      { paddingLeft: 8, paddingRight: 8, flex: 1 },
                    ]}
                    numberOfLines={1}
                  >
                    {_text}
                  </Text>
                  <View style={this.props.theme.iconButton}>
                    <Icon
                      name={infoVisible ? "chevron-up" : "chevron-down"}
                      size={24}
                      color={this.props.colors.text}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>

          {infoVisible ? (
            <PickerObjectContent
              id={_id}
              options={this.props.options}
              inputs={pickerObject?.inputs}
              prop={item.optionsProp}
              lang={this.props.lang}
              valueObj={this.state.visiblePickerObj?.valueObj}
            />
          ) : null}
        </View>
      );
    } else return null;
  }

  /*
            {titleToUse ? (
            <View
              key={'itemsView' + index}
              style={[
                this.props.theme.rowContainer,
                this.props.theme.halfContainer,
                {
                  marginTop: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: this.props.colors.primary,
                },
              ]}>
              <Text style={this.props.theme.boldText}>{titleToUse}</Text>
            </View>
          ) : null}
          */

  getTitleRowTitle = (layoutId, layoutVersion) => {
    return getTranslatedText(
      getReduxLayout(this.props?.options, layoutId, layoutVersion)?.title,
      this.props.lang
    );
  };

  handleTogglableRowsPress = (props) => {
    this.modifyValue(props);
    this.setState({
      togglableRowsKeys: {
        ...this.state.togglableRowsKeys,
        [props.valueKey]: props.value,
      },
    });
  };

  getListItems(item, index) {
    if (item.type === "moveToScreen") {
      return (
        <React.Fragment key={"togglableSectionsContainer" + item.sectionIndex}>
          <View
            testID={"togglableSectionsView"}
            key={"togglableSectionsView" + item.sectionIndex}
            style={this.props.theme.listDivider}
          />
          <TitleRow
            key={"togglableSectionsTitleRow" + item.sectionIndex}
            docId={this.props.docId}
            handleTitleClick={() =>
              this.navigateWithParams(...item.navigateParams)
            }
            title={item.title}
            screen={"groups"}
          />
        </React.Fragment>
      );
    } else if (item.type === "sectionTitle") {
      return item.title ? (
        <View
          style={[
            this.props.theme.rowContainer,
            this.props.theme.halfContainer,
            {
              marginTop: 16,
              paddingLeft: 8,
              paddingRight: 8,
              backgroundColor: this.props.colors.primary,
            },
          ]}
        >
          <Text style={this.props.theme.boldText}>{item.title}</Text>
        </View>
      ) : null;
    } else if (item.type === "togglableRows") {
      return (
        <CellComponent
          uiSettings={this.props.uiSettings}
          profile={this.props.profile}
          userId={this.props.profile.id}
          onlineDoc={this.props.connectionState === "Connected"}
          disabled={this.props.viewingCompletedDoc}
          urlStart={this.props.urlStart}
          theme={this.props.theme}
          colors={this.props.colors}
          pageH={this.props.pageH}
          pageW={this.props.pageW}
          fullHeight={this.props.fullHeight}
          fullWidth={this.props.fullWidth}
          role={this.props.editorRole}
          isFetching={this.props.isFetching}
          navigate={this.props.navigate}
          options={this.props.options}
          navigation={this.props.navigation}
          docId={this.props.docId}
          viewUnfinishedLayout={this.props.viewUnfinishedLayout}
          layoutId={this.props.layoutId}
          setPicker={this.props.setPicker}
          setTextModal={this.props.setTextModal}
          modifyObjectArrItem={this.props.modifyObjectArrItem}
          deleteFromObjectArr={this.props.deleteFromObjectArr}
          replaceObjectArrItem={this.props.replaceObjectArrItem}
          addToObjectArr={this.props.addToObjectArr}
          addToObjectArrWithGeneratedId={
            this.props.addToObjectArrWithGeneratedId
          }
          deleteFromStringArr={this.props.deleteFromStringArr}
          modifyStringArr={this.props.modifyStringArr}
          addToStringArr={this.props.addToStringArr}
          modifyValue={this.props.modifyValue}
          setInputModal={this.props.setInputModal}
          lang={this.props.lang}
          setDatePicker={this.props.setDatePicker}
          setModalPicker={this.props.setModalPicker}
          visibleExtraRowsData={this.props.visibleExtraRowsData}
          gpsLocation={this.props.gpsLocation}
          SET_GPS_LOCATION={this.props.SET_GPS_LOCATION}
          screenToGoBackTo={this.props.screenToGoBackTo}
          cellRefs={this.state}
          setCellRefFn={this.setCellRef}
          focusNextInput={this.focusNextInput}
          toggleDocSearchModal={this.toggleDocSearchModal}
          // common props stop
          item={item.data}
          setCellRef={(el, cell) =>
            this.setCellRef(item.data.valueKey, cell, el)
          }
          cellRef={this.state[item.data.valueKey]?.ref}
          itemIndex={index}
          innerItemIndex={"togglableRows"}
          valueKey={item.data.valueKey}
          modifyValueItem={this.handleTogglableRowsPress}
        />
      );
    } else if (item.type === "specialInput") {
      const specialInput = item.specialInput;
      return this.getSpecialInput(specialInput, index);
    } else {
      const { cell, cellIndex } = item;
      const hasPermission =
        !this.props.permissions || this.props.permissions.Update;
      return (
        <React.Fragment key={"CellInnerView" + index + cellIndex}>
          <CellComponent
            uiSettings={this.props.uiSettings}
            profile={this.props.profile}
            userId={this.props.profile.id}
            onlineDoc={this.props.connectionState === "Connected"}
            urlStart={this.props.urlStart}
            theme={this.props.theme}
            colors={this.props.colors}
            pageH={this.props.pageH}
            pageW={this.props.pageW}
            fullHeight={this.props.fullHeight}
            fullWidth={this.props.fullWidth}
            role={this.props.editorRole}
            isFetching={this.props.isFetching}
            navigate={this.props.navigate}
            options={this.props.options}
            navigation={this.props.navigation}
            docId={this.props.docId}
            viewUnfinishedLayout={this.props.viewUnfinishedLayout}
            layoutId={this.props.layoutId}
            setPicker={this.props.setPicker}
            setTextModal={this.props.setTextModal}
            modifyObjectArrItem={this.props.modifyObjectArrItem}
            deleteFromObjectArr={this.props.deleteFromObjectArr}
            replaceObjectArrItem={this.props.replaceObjectArrItem}
            addToObjectArr={this.props.addToObjectArr}
            addToObjectArrWithGeneratedId={
              this.props.addToObjectArrWithGeneratedId
            }
            deleteFromStringArr={this.props.deleteFromStringArr}
            modifyStringArr={this.props.modifyStringArr}
            addToStringArr={this.props.addToStringArr}
            modifyValue={this.props.modifyValue}
            setInputModal={this.props.setInputModal}
            lang={this.props.lang}
            setDatePicker={this.props.setDatePicker}
            setModalPicker={this.props.setModalPicker}
            visibleExtraRowsData={this.props.visibleExtraRowsData}
            gpsLocation={this.props.gpsLocation}
            SET_GPS_LOCATION={this.props.SET_GPS_LOCATION}
            modifyValueItem={this.props.modifyValueItem}
            screenToGoBackTo={this.props.screenToGoBackTo}
            cellRefs={this.state}
            setCellRefFn={this.setCellRef}
            focusNextInput={this.focusNextInput}
            toggleDocSearchModal={this.toggleDocSearchModal}
            // common props stop
            item={cell}
            setCellRef={(el) => this.setCellRef(cell.valueKey, cell, el)}
            cellRef={this.state[cell.valueKey]?.ref}
            itemIndex={cellIndex}
            innerItemIndex={cellIndex}
            valueKey={cell.valueKey}
            optimize={true}
            // TODO is there a need to disable cells on completed docs
            disabled={!hasPermission} //this.props.viewingCompletedDoc && !cell.internalInfo}
            noDisabledStyle={!hasPermission}
            headerOnly={item.headerOnly}
            noHeader={item.noHeader}
            customToken={this.props.customToken}
          />
        </React.Fragment>
      );
    }
  }

  changeScreen = (valueKey, measObjId, layoutVersion) => {
    if (getReduxLayout(this.props.options, measObjId, layoutVersion)) {
      this.props.navigate("measurementObjects", this.props.navigation, {
        layoutId: measObjId,
        layoutVersion,
        valueKey,
        viewUnfinishedLayout: this.props.viewUnfinishedLayout,
      });
    } else {
      errorReport({
        error: `Missing measurementObject ${measObjId} v.${layoutVersion}`,
        errorInFn: "changeScreen",
        errorInScreen: "ModularDoc",
      });
    }
  };

  navigateWithParams = (
    type,
    layoutId,
    layoutVersion,
    sectionValueKey,
    scrollToValueKeys,
    visibleData
  ) => {
    this.props.navigate(type, this.props.navigation, {
      layoutId,
      layoutVersion,
      valueKey: sectionValueKey,
      viewUnfinishedLayout: this.props.viewUnfinishedLayout,
      scrollToValueKeys,
      visibleData,
    });
  };

  renderFooter(sections) {
    const canComplete =
      !this.props.docLayout?.rolesCanComplete ||
      compareRole(
        this.props.profile?.role,
        this.props.docLayout?.rolesCanComplete
      );
    const hasPermission =
      !this.props.permissions || this.props.permissions.Update;
    return (
      <View
        testID={"modularDocButtonsContainer"}
        style={this.props.theme.buttonContainer}
      >
        <ButtonGroup
          buttons={[
            this.props.onlySectionWithValueKey
              ? {
                  backgroundColor: this.props.colors.lightAccent,
                  color: this.props.colors.accent,
                  title: this.props.t("back"),
                  disabled: this.state.loading,
                  loading: this.state.loading,
                  startIcon: "arrow-left",
                  onPress: () => this.props.goBack(),
                }
              : this.props.getLeftButton
              ? this.props.getLeftButton(this.props.docToModify)
              : {
                  backgroundColor: this.props.colors.lightAccent,
                  color: this.props.colors.accent,
                  title: this.props.t("close"),
                  disabled: this.state.loading,
                  loading: this.state.loading,
                  startIcon: "close",
                  onPress: () => this.props.closeDoc(),
                },
            this.props.viewUnfinishedLayout
              ? null
              : this.props.docLayout?.extraCreationActions
              ? {
                  startIcon: "plus",
                  disabled: this.state.loading,
                  loading: this.state.loading,
                  title: this.props.t("actions"),
                  onPress: () =>
                    this.props.setModalPicker({
                      title: this.props.t("actions"),
                      visible: true,
                      textProp: "title",
                      closeModalOnAction: false,
                      data: [
                        {
                          id: -1,
                          title: this.props.t("createPDF"),
                          action: () =>
                            this.handleCreatePdfPress(canComplete, sections),
                        },
                      ].concat(
                        this.props.docLayout.extraCreationActions.map(
                          (x, i) => ({
                            id: i,
                            title: x.title
                              ? getTranslatedText(x.title, this.props.lang)
                              : this.props.t(x.action),
                            action: () => {
                              this.handleExtraDocActions(x);
                              this.props.setModalPicker({
                                visible: false,
                              });
                            },
                          })
                        )
                      ),
                    }),
                }
              : this.props.customToken || !hasPermission
              ? null
              : {
                  startIcon: "file-document-outline",
                  disabled: this.state.loading,
                  loading: this.state.loading,
                  title: this.props.t("createPDF"),
                  onPress: () =>
                    this.handleCreatePdfPress(canComplete, sections),
                },

            this.props.viewUnfinishedLayout
              ? null
              : this.props.docToModify.online &&
                this.props.connected &&
                !(this.props.connectionState === "Connected")
              ? {
                  startIcon: "content-save",
                  loading: this.props.mergingDoc,
                  disabled: this.props.mergingDoc,
                  title: this.props.t("save"),
                  onPress: () => this.props.mergeDoc(),
                }
              : !(this.props.connectionState === "Connected")
              ? {
                  startIcon: "content-save",
                  loading: this.props.isFetching || this.state.loading,
                  disabled:
                    !hasPermission ||
                    this.props.isFetching ||
                    this.state.loading ||
                    this.props.demo ||
                    !this.props.connected ||
                    this.props.saveDisabled,
                  title: this.props.t("save"),
                  onPress: () => {
                    if (!this.props.connected) {
                      showToast(this.props.t("internetConnectionErrSaveInfo"));
                    } else {
                      this.props.onSaveDocPress();
                    }
                  },
                }
              : {
                  startIcon: "check",
                  loading: this.props.markingAsFilled,
                  title: this.props.t("markAsCompleted"),
                  onPress: () => {
                    if (!this.props.connected) {
                      showToast(this.props.t("internetConnectionErr"));
                    } else {
                      this.props.markAsFilled(this.props.docId);
                    }
                  },
                },
          ]}
        />
      </View>
    );
  }

  getUserName = (userId) => {
    const user = this.props.options.users.find((x) => x.id === userId);
    if (user) {
      return user.name && user.lName ? user.name + " " + user.lName : "";
    } else {
      return "";
    }
  };

  renderEditorSwitch = () => {
    return (
      <TouchableOpacity
        style={{
          marginLeft: 8,
          width: 36,
          height: 36,
          borderRadius: 18,
          backgroundColor: this.props.colors.accent,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => {
          this.props.SET_USER_PROP({
            prop: "editorRole",
            value:
              this.props.editorRole === "User"
                ? this.props.profile.role
                : "User",
          });
        }}
      >
        {/* <IconTooltip
          title={
            this.props.editorRole === "User"
              ? this.props.t("switchToEditing")
              : this.props.t("switchToFilling")
          }
          name={
            this.props.editorRole === "User"
              ? "account-convert"
              : "account-edit"
          }
          size={24}
          color={this.props.colors.textOnAccent}
        /> */}
      </TouchableOpacity>
    );
  };

  handleShareAction = (action) => {
    switch (action.action) {
      case "createBill":
        this.props.dispatchState({
          type: "mergeState",
          value: {
            setModal: {
              modal: "modalForm",
              props: {
                isVisible: true,
                requestUrl: "/createBill",
                inputs: [
                  {
                    title: { all: this.props.t("billInfo") },
                    type: "title",
                    size: "h1",
                  },
                  {
                    key: "price",
                    title: { all: this.props.t("price") },
                    defaultValue: "10",
                    type: "textField",
                    required: true,
                    numeric: true,
                    onSaveActions: ["trim"],
                  },
                  {
                    key: "rowInfo",
                    title: { all: this.props.t("rowComment") },
                    type: "textField",
                    required: true,
                    onSaveActions: ["trim"],
                    // hint: { all: this.props.t("emailSendSecretInfo") },
                  },
                  {
                    key: "message",
                    title: { all: this.props.t("additionalInfo") },
                    type: "textField",
                    required: true,
                    onSaveActions: ["trim"],
                    // hint: { all: this.props.t("emailSendSecretInfo") },
                  },
                ],
                onSave: (value) => {
                  this.props.dispatchState({
                    type: "set",
                    prop: "requesting",
                    value: {
                      url: "/createBill",
                      method: "post",
                      body: value,
                      successStatus: 204,
                      callback: () => {
                        showToast(this.props.t("billCreated"), 3000, "green");
                        // close modal
                        this.props.dispatchState({
                          type: "set",
                          prop: "modalForm",
                          value: {
                            visible: false,
                          },
                        });
                      },
                    },
                  });
                },
              },
            },
            modalPicker: { visible: false },
          },
        });
        break;
      default:
        showToast("missing handling for share action: " + action);
        break;
    }
  };

  sendDocWithoutCompleting = () => {
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        this.props.dispatchState({
          type: "mergeState",
          value: {
            modalPicker: { visible: false },
          },
        });
        this.props.modal.openModal("SendDocsModal", {
          docs: [this.props.docToModify],
          layouts: [this.props.docLayout],
          sendWithMsg: true,
          sendUnfinished: true,
          extraProps: {
            updateCustomStatusInDoc: this.updateCustomStatusInDoc,
          },
        });
      } else {
        showToast(this.props.t("checkInternetConnection"));
      }
    });
  };

  renderShare = () => {
    return (
      <Tooltip tip={this.props.t("toShare")} tipWrapperStyle={{ width: 48 }}>
        <IconButton
          round
          theme={this.props.theme}
          loading={false}
          size={32}
          iconSize={20}
          disabled={this.props.fetchingDoc}
          onPress={() => {
            if (this.props.demo) {
              showToast(this.props.t("shareNotAvailableInDemo"));
            } else {
              let data = [];
              const disableShareActions =
                this.props.docLayout?.disableShareActions || [];
              if (!disableShareActions.includes("shareToUsers")) {
                data.push({
                  id: "shareToUsers",
                  title: this.props.t("shareToUsers"),
                  action: () => {
                    this.props.setModalPicker({
                      title: this.props.t("shareToUsers"), // Jaa muille samanaikaiseen muokkaukseen\n(BETA)",
                      multiple: true,
                      visible: true,
                      action: (users) => {
                        this.props.shareToUsers(users);
                      },
                      textProp: "title",
                      middleButtonTitle: this.props.t("shareToAll"),
                      rightButtonTitle: this.props.t("shareToSelected"),
                      defaultSelected: this.props.docToModify.sharedTo
                        ? this.props.docToModify.sharedTo.map((userId) => {
                            const user = this.props.options.users.find(
                              (x) => x.id === userId
                            );
                            return {
                              id: user.id,
                              title:
                                user.name && user.lName
                                  ? user.name +
                                    " " +
                                    user.lName +
                                    ", " +
                                    user.email
                                  : user.email,
                            };
                          })
                        : null,
                      onMiddleButtonPress: () =>
                        this.props.shareToUsers([], true),
                      data: (this.props.options.users || [])
                        .filter(
                          (user) =>
                            user.email && user.id !== this.props.profile.id
                        )
                        .map((user) => {
                          return {
                            id: user.id,
                            title:
                              user.name && user.lName
                                ? user.name +
                                  " " +
                                  user.lName +
                                  ", " +
                                  user.email
                                : user.email,
                          };
                        }),
                    });
                  },
                });
              }

              if (!disableShareActions.includes("sendDocWithoutCompleting")) {
                data.push({
                  id: "sendDocWithoutCompleting",
                  title: this.props.t("sendDocWithoutCompleting"),
                  action: this.sendDocWithoutCompleting,
                });
              }

              // TODO enable when billing is implemented
              if (
                isDevBuild ||
                isSameOrGreaterThanRole(this.props.profile?.role, "Admin")
              ) {
                data.push({
                  id: "share",
                  title: this.props.t("link"),
                  action: () => {
                    this.props.setState({
                      modalPicker: { visible: false },
                      setModal: {
                        modal: "shareModal",
                        props: { isVisible: true },
                      },
                    });
                  },
                });
              }

              // if (
              //   !disableShareActions.includes("attachToProject") &&
              //   !this.props.docLayout?.noProjectPicker &&
              //   !this.props.viewingCompletedDoc
              // ) {
              //   // TODO if attaching completed doc to project attach it immediately
              //   data.push({
              //     id: "attachToProject",
              //     title: this.props.docToModify.projectId
              //       ? this.props.t("attachedToProject") +
              //         ": " +
              //         this.getProjectTitleWithId(
              //           this.props.docToModify.projectId
              //         )
              //       : this.props.t("attachToProject"),
              //     action: this.openProjectPicker,
              //     isAsyncAction: true,
              //   });
              // }

              if (this.props.docLayout?.extraShareActions) {
                this.props.docLayout.extraShareActions.forEach((action) => {
                  data.push({
                    id: action.action,
                    title: getTranslatedText(action.title, this.props.lang),
                    action: () => this.handleShareAction(action),
                  });
                });
              }

              this.props.setModalPicker({
                title: this.props.t("toShare"), // Jaa muille samanaikaiseen muokkaukseen\n(BETA)",
                visible: true,
                textProp: "title",
                closeModalOnAction: false,
                data,
              });
            }
          }}
          backgroundColor={this.props.colors.accent}
          icon="share-variant"
          color={this.props.colors.textOnAccent}
        />
      </Tooltip>
    );
  };

  renameDoc = (item) => {
    const cleanUpState = () => {
      this.setState({
        docRenameModal: { visible: false },
        newDocName: undefined,
        newDocNameTemplate: undefined,
      });
    };
    if (this.state.newDocName) {
      if (this.props.profile.role === "Trial" || this.props.demo) {
        this.props.setDocProp({
          docId: item.id,
          prop: "name",
          value: this.state.newDocName,
        });
        this.props.modifyValue({
          valueKey: "docNameTemplate",
          value: this.state.newDocNameTemplate,
          docId: item.id,
        });
        cleanUpState();
      } else if (!item?.id?.endsWith("tmp")) {
        // TODO handle completedDocs etc.
        putWithToken(
          { id: item.id, name: this.state.newDocName },
          "/docs/rename"
        ).then((res) => {
          if (res.status === 204) {
            this.props.setDocProp({
              docId: item.id,
              prop: "name",
              value: this.state.newDocName,
            });
            this.props.modifyValue({
              valueKey: "docNameTemplate",
              value: this.state.newDocNameTemplate,
              docId: item.id,
            });
            cleanUpState();
          } else {
            errorReport({
              error: res.status,
              errorInFn: "renameDoc",
              errorInScreen: "FolderBrowse",
              errorParams: {
                id: item.id,
                company: this.props.company?.id,
              },
            });
          }
        });
      }
    } else {
      cleanUpState();
    }
  };

  handleDocRename = () => {
    this.renameDoc(this.props.docToModify);
  };

  setDocNameModal = () => {
    if (
      this.props.docToModify.status === 0 ||
      this.props.docToModify.status === 2
    ) {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          this.setState({
            docRenameModal: {
              visible: true,
            },
          });

          // this.props.setTextModal({
          // visible: true,
          // title: this.props.t("docRenameTitle"),
          // value:
          //   this.state.newDocName ||
          //   this.props.docToModify.name ||
          //   getTranslatedText(this.props.docLayoutType, this.props.lang),
          // fn: (name) => this.renameDoc(this.props.docToModify, name),
          // closeOnRighButtonPress: true,
          // });
        } else {
          showToast(this.props.t("checkInternetConnection"));
        }
      });
    } else {
      showToast(this.props.t("docRenameStatusErr"));
    }
  };
  renderHeader = () => {
    const hasEditorSwitch =
      this.props.docLayout.editorSwitch &&
      compareRole(this.props.profile?.role, this.props.docLayout?.editorSwitch);
    const hasShareButton =
      // TODO should internal docs have share button?
      !this.props.customToken &&
      (this.props.docLayout?.extraShareActions ||
        (this.props.docToModify.status === 0 &&
          (this.props.docToModify.creatorId === this.props.profile.id ||
            this.props.docId.includes(this.props.profile.id))));
    const nameEditingDisabled =
      this.props.customToken ||
      this.props.viewUnfinishedLayout ||
      this.props.fetchingDoc
        ? true
        : false;
    return (
      <>
        <View
          testID={"headerView"}
          style={{
            padding: 8,
            justifyContent: "space-between",
            flexDirection: "row",
            // alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "center",
              paddingRight: 8,
              overflow: "hidden",
            }}
          >
            {this.props.hideNameInput ? null : (
              <Tooltip tip={this.props.t("docRenameTitle")}>
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                  onPress={this.setDocNameModal}
                  disabled={nameEditingDisabled}
                >
                  {nameEditingDisabled ? null : (
                    <View style={{ paddingRight: 8 }}>
                      <Icon
                        name={"pencil"}
                        size={24}
                        color={this.props.colors.accent}
                      />
                    </View>
                  )}
                  <View style={this.props.theme.flex}>
                    <Text style={this.props.theme.boldText} numberOfLines={1}>
                      {this.props.docToModify.name ||
                        getTranslatedText(
                          this.props.docLayoutType,
                          this.props.lang
                        )}
                    </Text>
                    {this.props.permissions ? (
                      <Text style={this.props.theme.caption} numberOfLines={1}>
                        {this.props.t(
                          this.props.permissions.Update
                            ? "updatePermission"
                            : "readPermission"
                        )}
                      </Text>
                    ) : null}
                  </View>
                </TouchableOpacity>
              </Tooltip>
            )}
          </View>
          {hasEditorSwitch ? this.renderEditorSwitch() : null}
          {hasShareButton ? this.renderShare() : null}
        </View>
        <View testID={"headerFatLine"} style={this.props.theme.fatLine} />
      </>
    );
  };

  // getItemLayout = (data, index) => {
  //   const length = this.itemHeights[index];
  //   const offset = this.itemHeights.slice(0, index).reduce((a, c) => a + c, 0);
  //   return { length, offset, index };
  // };

  getProjectTitleWithId = (id) => {
    const project = this.props.projects?.[id];
    if (project) {
      return project.title;
    } else {
      return "";
    }
  };

  onViewableItemsChanged = ({ viewableItems }) => {
    this.props.handleViewableItemsChanged(viewableItems);
  };

  getItemLayout = (data, index) => {
    // index goes up to sections count * 2 + all sections items count
    // if there's 10 sections with 2 items in each section, max index = 40
    // 0 = section header, 1 = section item 1, 2 = section item 2, 3 = section divider
    const length = this.itemHeights[index] ?? 0;
    const offset = this.itemHeights
      .slice(0, index)
      .reduce((a, c) => a + (c ?? 0), 0);
    return { length, offset, index: index };
  };
  saveItemHeight = (index) => (object) => {
    this.itemHeights[index] = object.nativeEvent.layout.height;
  };
  hideDocRenameModal = () => {
    this.setState({
      docRenameModal: { visible: false },
    });
  };

  render() {
    // TODO add project picker, project picker should also add site and customer to doc
    if (
      !this.props.fetchingDoc &&
      (!this.props.docToModify.online ||
        (this.props.docToModify.online && !this.props.connectingSession))
    ) {
      const sectionsData = this.getListData(this.props.docToModify);
      return (
        <View testID={"modularDocInnerContainer"} style={this.props.theme.flex}>
          {this.renderHeader()}
          <View
            testID={"modularListContainer"}
            style={[
              OS !== "web" ? this.props.theme.horizontalPaddingContainer : null,
              {
                display: "flex",
                flex: 8,
                backgroundColor: this.props.colors.primary,
              },
            ]}
          >
            {sectionsData ? (
              <SectionList
                keyboardShouldPersistTaps="handled"
                style={
                  OS !== "web" ? null : this.props.theme.textPaddingContainer
                }
                getItemLayout={this.getItemLayout}
                onViewableItemsChanged={this.onViewableItemsChanged}
                onScroll={this.props.handleScroll}
                testID={"modularList"}
                ref={this.listRef}
                sections={sectionsData.sections} //this.props.docLayout?.sections}
                initialNumToRender={
                  this.props.scrollToValueKeys ? sectionsData.count : 100
                }
                ListFooterComponent={() => (
                  <View style={this.props.theme.listDivider} />
                )}
                onLayout={this.handleInitialScroll}
                //getItemLayout={this.getItemLayout}
                removeClippedSubviews={false}
                renderSectionHeader={({ section }) => (
                  <View
                    onLayout={this.saveItemHeight(
                      section.layoutIndex,
                      sectionsData
                    )}
                  >
                    {section.title?.cell ? (
                      this.getListItems(
                        section.title,
                        0,
                        this.props.docLayout.specialInputs
                      )
                    ) : section.title ? (
                      <View
                        key={section.title}
                        style={{
                          height: 52,
                          backgroundColor: this.props.colors.primary,
                        }}
                      >
                        <Text style={this.props.theme.title}>
                          {section.title}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                )}
                keyExtractor={(item, index) => "modularList_" + index}
                stickySectionHeadersEnabled={OS !== "web"}
                renderItem={({ item, index }) => (
                  <View
                    onLayout={this.saveItemHeight(
                      item.layoutIndex,
                      sectionsData
                    )}
                  >
                    {this.getListItems(
                      item,
                      index,
                      this.props.docLayout.specialInputs
                    )}
                  </View>
                )}
              />
            ) : null}
          </View>

          {this.renderFooter(sectionsData?.sections)}

          {this.state.docSearchModal.visible ? (
            <DocSearchModal
              {...this.state.docSearchModal}
              docId={this.props.docId}
              addToObjectArr={this.props.addToObjectArr}
              onToggleModal={this.toggleDocSearchModal}
              lang={this.props.lang}
            />
          ) : null}

          <ModalWithButtons
            visible={this.state.docRenameModal.visible}
            onToggleModal={this.hideDocRenameModal}
            onSavePress={this.handleDocRename}
          >
            <TemplateStringInput
              t={this.props.t}
              hint={this.props.t("docRenameHint")}
              title={this.props.t("docRenameTitle")}
              valueFieldTitle={this.props.t("realDocName")}
              addTemplateStringInfo={this.props.t("docNameTemplateStringInfo")}
              value={
                this.state.newDocNameTemplate ??
                (this.props.docToModify.values.docNameTemplate ||
                  getTranslatedText(
                    this.props.docLayout.generateName?.text,
                    this.props.lang
                  ) ||
                  this.props.docToModify.name ||
                  getTranslatedText(this.props.docLayoutType, this.props.lang))
              }
              langToEdit={this.props.lang}
              templateStringSources={this.props.templateStringSources}
              doc={this.props.docToModify}
              layout={this.props.docLayout}
              profile={this.props.profile}
              returnReadableString
              onChangeText={(temmplateString, readableValue) => {
                this.setState({
                  newDocName: readableValue?.newValue,
                  newDocNameTemplate: temmplateString,
                });
              }}
            />
          </ModalWithButtons>
        </View>
      );
    } else {
      return (
        <View style={this.props.theme.flex}>
          {this.renderHeader()}
          <View
            testID={"modularListContainer"}
            style={[
              OS !== "web" ? this.props.theme.horizontalPaddingContainer : null,
              {
                display: "flex",
                flex: 8,
                backgroundColor: this.props.colors.primary,
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <ActivityIndicator color={this.props.colors.accent} size="large" />
          </View>
        </View>
      );
    }
  }
}

export default withModal(ModularDocScreen);

import { useEffect, useContext, useReducer } from "react";
import { Text, View, FlatList, ScrollView } from "react-native";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getWithToken } from "../lib/api";
import StretchButton from "../components/StretchButton";
import CellComponent from "../components/CellComponent";
import { ThemeContext } from "../theming/theme-context";
import DateRangeSelector from "../components/DateRangeSelector";
import {
  getNewestLayoutVersion,
  getTranslatedText,
  isArrayWithItems,
  isSameOrGreaterThanRole,
} from "../lib/functions";
import { navigate, showToast } from "../lib/helperFns";
import combinePDFs from "../pdf/combinePDFs";
import RegisterObjectPicker from "../components/RegisterObjectPicker";
import { downloadBase64, downloadSignedUrl } from "../lib/fileOperations";
import Dropdown from "../components/Dropdown";
import Title from "../components/Title";
import { SET_UI_SETTINGS_PROP } from "../actions/ProfileActions";
import { connect } from "react-redux";
import Alert from "../components/AlertModal";
import Settings from "../components/Settings";
import componentReducer from "../reducers/ComponentReducer";

function DocStatisticsScreen(props) {
  const alwaysCurrentPeriod =
    props.uiSettings?.docStatisticsAlwaysCurrentPeriod ?? true;
  const timePeriod = props.uiSettings?.docStatisticsTimePeriod || "months";
  const seedMissingValues = props.uiSettings?.docStatisticsSeedMissingValues;

  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const [state, dispatchState] = useReducer(componentReducer, {
    rangeStart: alwaysCurrentPeriod ? moment().startOf(timePeriod) : undefined,
    rangeEnd: alwaysCurrentPeriod ? moment().endOf(timePeriod) : undefined,
    values: {},
    tmpValues: {
      sites_1: props.uiSettings?.defaultSiteId
        ? { id: props.uiSettings.defaultSiteId }
        : undefined,
    },
    refreshing: true,
    settingsModal: { isVisible: false },
  });

  const closeSettingsModal = () => {
    dispatchState({
      type: "set",
      prop: "settingsModal",
      value: { isVisible: false },
    });
  };

  const isAdmin = isSameOrGreaterThanRole(props.role, "Admin");

  const layout = props.layoutId
    ? getNewestLayoutVersion(props.options.layouts, props.layoutId, isAdmin)
    : null;

  const seedValues = (rangeStart, rangeEnd) => {
    const seededValues = {};

    let seedCount = 1000;

    if (rangeStart && rangeEnd) {
      const daysBetween = rangeEnd.diff(rangeStart, "days") + 1;
      seedCount = daysBetween;
    }
    layout.docValueParses?.forEach((docValueParse) => {
      if (docValueParse) {
        // sum
        if (docValueParse?.parsingMethod === 0) {
          seededValues[docValueParse.valueKey] = Math.random() * (100 - 0) + 0;
        }
        // date values
        else if (docValueParse?.parsingMethod === 1) {
          let max = 100;
          let min = 0;
          seededValues[docValueParse.valueKey] = [];
          if (isArrayWithItems(docValueParse.fromValuesKeys)) {
            const graphCell = layout.docValuesVisualization?.find(
              (x) => x.defaultValueKey === docValueParse.fromValuesKeys[0]
            );
            if (graphCell?.chartData?.domain.y) {
              max = graphCell.chartData.domain.y[1] || 100;
              min = graphCell.chartData.domain.y[0] || 0;
            }
          }

          for (let i = 0; i < seedCount; i++) {
            seededValues[docValueParse.valueKey].push({
              x: (rangeStart ? rangeStart.clone() : moment())
                .add(i + 1, "days")
                .unix(),
              y: Math.random() * (max - min) + min,
            });
          }
        }
      }
    });

    dispatchState([
      {
        type: "mergeState",
        value: {
          dataRangeStart: rangeStart ? rangeStart.clone() : moment(),
          dataRangeEnd: (rangeEnd ? rangeEnd.clone() : moment()).add(
            seedCount,
            "days"
          ),
          documentIds: [],
          refreshing: false,
        },
      },
      {
        type: "set",
        prop: "values",
        value: seededValues,
      },
    ]);
  };

  const fetchData = (layoutId, rangeStart, rangeEnd, site) => {
    getWithToken("/docs/completedDocs/parseValues", null, {
      layoutId,
      rangeStart: rangeStart?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      rangeEnd: rangeEnd?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      siteId: site?.id,
    }).then((res) => {
      if (res.status === 200) {
        if (
          seedMissingValues &&
          (!res.data?.values || Object.keys(res.data.values).length === 0)
        ) {
          seedValues(rangeStart, rangeEnd);
        } else {
          dispatchState([
            {
              type: "mergeState",
              value: {
                dataRangeEnd: res.data.rangeEnd
                  ? moment(res.data.rangeEnd)
                  : undefined,
                dataRangeStart: res.data.rangeStart
                  ? moment(res.data.rangeStart)
                  : undefined,
                documentIds: res.data.documentIds,
                refreshing: false,
              },
            },
            {
              type: "set",
              prop: "values",
              value: res.data.values,
            },
          ]);
        }
      } else {
        showToast(t("failedToFetchInfo"));
      }
    });
  };

  useEffect(() => {
    if (props.layoutId)
      fetchData(
        props.layoutId,
        state.rangeStart,
        state.rangeEnd,
        state.tmpValues["sites_1"]
      );
  }, [
    props.layoutId,
    state.tmpValues["sites_1"],
    state.rangeStart,
    state.rangeEnd,
  ]);

  const handleDocDownload = (useFilter) => {
    setDownloading(true);
    getWithToken(
      "docs/completedDocs/parsedValuesSignedUrls",
      null,
      useFilter
        ? {
            layoutId: props.layoutId,
            rangeStart: state.rangeStart?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            rangeEnd: state.rangeEnd?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            siteId: state.tmpValues["sites_1"]?.id,
            onlyLatest: true,
          }
        : {
            layoutId: props.layoutId,
            onlyLatest: true,
          }
    )
      .then((res) => {
        if (res.status === 200 && res.data && res.data.length > 0) {
          downloadSignedUrl(
            res.data[0].item2,
            res.data[0].item1,
            "application/pdf",
            setDownloading
          );
        } else {
          showToast(t("docNotFound"), 5000, "accent");
          setDownloading(false);
        }
      })
      .catch(() => {
        showToast(t("docNotFound"), 5000, "accent");
        setDownloading(false);
      });
  };

  const handleAllDocsDownload = (useFilter) => {
    setDownloading(true);
    getWithToken(
      "docs/completedDocs/parsedValuesSignedUrls",
      null,
      useFilter
        ? {
            layoutId: props.layoutId,
            rangeStart: state.rangeStart?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            rangeEnd: state.rangeEnd?.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            siteId: state.tmpValues["sites_1"]?.id,
          }
        : {
            layoutId: props.layoutId,
          }
    )
      .then((res) => {
        if (res.status === 200 && res.data && res.data.length > 0) {
          const urls = res.data.map((x) => x.item2);
          return combinePDFs(urls);
        }
      })
      .then((base64String) => {
        if (base64String) {
          const layoutType = getTranslatedText(
            props.options.layouts[props.layoutId].type,
            props.lang
          );

          const rangeStart = (state.rangeStart || state.dataRangeStart).format(
            "D-M-YYYY"
          );
          const rangeEnd = (state.rangeEnd || state.dataRangeEnd).format(
            "D-M-YYYY"
          );

          const fileName = `${layoutType} ${rangeStart}-${rangeEnd}.pdf`;

          downloadBase64(
            base64String,
            fileName,
            "application/pdf",
            true,
            setDownloading
          );
        } else {
          showToast(t("docNotFound"), 5000, "accent");
          setDownloading(false);
        }
      })
      .catch(() => {
        showToast(t("docNotFound"), 5000, "accent");
        setDownloading(false);
      });
  };

  const setDownloading = (value) => {
    dispatchState({ type: "set", prop: "downloading", value });
  };

  if (!layout)
    return (
      <View style={theming.theme.centeredContainer}>
        <View style={theming.theme.modalListContainer}>
          <Text style={theming.theme.title}>{t("missingLayout")}</Text>
          <StretchButton
            title={t("close")}
            onPress={() => {
              navigate("browse", props.navigation);
            }}
          />
        </View>
      </View>
    );

  return (
    <View style={theming.theme.centeredFlexContainer}>
      <View style={theming.theme.flex}>
        <View style={[theming.theme.flex, theming.theme.hPadding8]}>
          <FlatList
            data={layout?.docValuesVisualization}
            extraData={state}
            ListHeaderComponent={
              <View style={theming.theme.primaryBg}>
                <Title
                  title={getTranslatedText(
                    props.options.layouts[props.layoutId]?.type,
                    props.lang
                  )}
                  icons={[
                    {
                      icon: "cog",
                      backgroundColor: theming.colors.primary,
                      textColor: theming.colors.text,
                      onPress: () => {
                        const settings = [
                          {
                            type: "checkBox",
                            key: "docStatisticsAlwaysCurrentPeriod",
                            title: t("currentTimePeriodByDefault"),
                            defaultValue: true,
                            value:
                              props.uiSettings
                                ?.docStatisticsAlwaysCurrentPeriod,
                            setValue: (prop, value) => {
                              props.SET_UI_SETTINGS_PROP({
                                prop: "docStatisticsAlwaysCurrentPeriod",
                                value: value,
                              });
                              dispatchState({
                                type: "setObjArrayProp",
                                prop: ["settingsModal", "settings"],
                                idProp: "key",
                                idValue: "docStatisticsAlwaysCurrentPeriod",
                                innerProp: "value",
                                value,
                              });
                            },
                          },
                          {
                            type: "select",
                            key: "docStatisticsTimePeriod",
                            title: t("timePeriod"),
                            values: ["weeks", "months"],
                            valueTitles: ["week", "month"],
                            defaultValue: timePeriod,
                            value: props.uiSettings?.docStatisticsTimePeriod,
                            noRemoval: true,
                            setValue: (prop, value) => {
                              props.SET_UI_SETTINGS_PROP({
                                prop: "docStatisticsTimePeriod",
                                value: value,
                              });
                              dispatchState({
                                type: "setObjArrayProp",
                                prop: ["settingsModal", "settings"],
                                idProp: "key",
                                idValue: "docStatisticsTimePeriod",
                                innerProp: "value",
                                value,
                              });
                            },
                          },
                        ];
                        if (isAdmin) {
                          settings.push({
                            type: "checkBox",
                            key: "docStatisticsSeedMissingValues",
                            title: "ADMIN ONLY " + "Seed missing values",
                            value:
                              props.uiSettings?.docStatisticsSeedMissingValues,
                            setValue: (prop, value) => {
                              props.SET_UI_SETTINGS_PROP({
                                prop: "docStatisticsSeedMissingValues",
                                value: value,
                              });
                              dispatchState({
                                type: "setObjArrayProp",
                                prop: ["settingsModal", "settings"],
                                idProp: "key",
                                idValue: "docStatisticsSeedMissingValues",
                                innerProp: "value",
                                value,
                              });
                            },
                          });
                        }
                        dispatchState({
                          type: "set",
                          prop: "settingsModal",
                          value: {
                            isVisible: true,
                            title: t("settings"),
                            settings,
                          },
                        });
                      },
                    },
                  ]}
                />
                <View style={theming.theme.vPadding8}>
                  <DateRangeSelector
                    timePeriod={timePeriod}
                    rangeStart={state.rangeStart}
                    rangeEnd={state.rangeEnd}
                    onChange={(value) => {
                      dispatchState({ type: "mergeState", value });
                    }}
                  />
                </View>
                <View style={theming.theme.vPadding8}>
                  <RegisterObjectPicker
                    item={{
                      valueKey: "1",
                      type: "pickerObjects",
                      layoutId: "pickerObjects/1",
                      prop: "name",
                      optionsProp: "sites",
                      layoutVersion: 1,
                    }}
                    modifyValue={(payload) => {
                      if (Array.isArray(payload)) {
                        dispatchState(
                          payload.map((x) => ({
                            type: "setWithArray",
                            prop: ["tmpValues", x.valueKey],
                            value: x.value,
                          }))
                        );
                      } else {
                        dispatchState({
                          type: "setWithArray",
                          prop: ["tmpValues", payload.valueKey],
                          value: payload.value,
                        });
                      }

                      const setUISetting = (valueKey, value) => {
                        let prop;
                        const split = valueKey.split("_")[0];
                        if (split === "customers") prop = "defaultCustomerId";
                        else if (split === "sites") prop = "defaultSiteId";
                        if (prop && value?.id)
                          props.SET_UI_SETTINGS_PROP({ prop, value: value.id });
                      };

                      if (Array.isArray(payload)) {
                        payload.forEach((x) => {
                          setUISetting(x.valueKey, x.value);
                        });
                      } else {
                        setUISetting(payload.valueKey, payload.value);
                      }
                    }}
                    values={state.tmpValues}
                    disableAdd
                  />
                </View>
              </View>
            }
            stickyHeaderIndices={[0]}
            renderItem={(data) => (
              <CellComponent
                theme={theming.theme}
                colors={theming.colors}
                optimize
                valueKey={data.item.valueKey}
                item={data.item}
                overrideValues={state.values}
                rangeStart={(state.rangeStart || state.dataRangeStart)?.unix()}
                rangeEnd={(state.rangeEnd || state.dataRangeEnd)?.unix()}
                disabled
                noDisabledStyle
              />
            )}
          />
        </View>
        <View style={theming.theme.padding8}>
          <View style={theming.theme.row}>
            <StretchButton
              disabled={state.refreshing}
              loading={state.refreshing}
              iconWhenLoading
              title={t("refresh")}
              rounded
              onPress={() => {
                dispatchState({
                  type: "set",
                  prop: "refreshing",
                  value: true,
                });
                fetchData(
                  props.layoutId,
                  state.rangeStart,
                  state.rangeEnd,
                  state.tmpValues["sites_1"]
                );
              }}
            />
            <View style={theming.theme.padding4} />
            <Dropdown
              value={t("download")}
              rounded
              disabled={state.downloading}
              loading={state.downloading}
              iconWhenLoading
              data={[
                {
                  title: t("downloadLatestDoc"),
                  action: () => {
                    handleDocDownload(false);
                  },
                },
                {
                  title: t("downloadLatestDocWithFilter"),
                  action: () => {
                    handleDocDownload(true);
                  },
                },
                {
                  title: t("downloadAllDocsMerged"),
                  action: () => {
                    handleAllDocsDownload(false);
                  },
                },
                {
                  title: t("downloadAllDocsMergedWithFilter"),
                  action: () => {
                    handleAllDocsDownload(true);
                  },
                },
              ]}
            />
          </View>
        </View>
      </View>

      <Alert
        visible={state.settingsModal.isVisible}
        title={state.settingsModal.title}
        leftButtonTitle={t("close")}
        toggleAlert={closeSettingsModal}
        cancelButton
        disableRightButton
      >
        <ScrollView>
          <Settings
            theme={theming.theme}
            colors={theming.colors}
            role={props.role}
            closeDrawer={closeSettingsModal}
            noCloseButton
            noVersionInfo
            settings={state.settingsModal.settings}
            setValue={state.settingsModal.setValue}
          />
        </ScrollView>
      </Alert>
    </View>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    role: state.userInfo.profile?.role,
    layoutId: ownProps.layoutId || ownProps.route?.params?.layoutId,
    options: state.options,
    lang: i18next.language,
    uiSettings: state.userInfo.uiSettings,
  };
};

export default connect(mapStateToProps, {
  SET_UI_SETTINGS_PROP,
})(DocStatisticsScreen);

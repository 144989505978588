import { useState } from "react";
import { Platform, View, TouchableOpacity } from "react-native";
import { useTranslation } from "react-i18next";

import SimpleModal from "../components/SimpleModal";
import RowText from "../components/RowText";

import SettingsScreen from "./SettingsScreen";

import { ThemeContext } from "../theming/theme-context";
import { validatePhoneNumber } from "../lib/validityCheckers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { changePhoneNumber } from "../actions/ProfileActions";

export const Profile = (props) => {
  const { t } = useTranslation();
  const [phoneNumberModalVisible, setPhoneNumberModalVisible] = useState(false);

  const { profile } = props;

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={[theme.flex]}>
          <View
            style={[
              theme.container,
              theme.paddingContainer,
              { backgroundColor: colors.primary },
            ]}
          >
            <SettingsScreen onlyProfileSettings>
              <View
                style={[
                  theme.flex,
                  Platform.OS === "web" ? { width: 400 } : null,
                ]}
              >
                <View style={theme.rowContainer}>
                  <RowText
                    theme={theme}
                    colors={colors}
                    title={t("name")}
                    text={profile.name + " " + profile.lName}
                  />
                </View>

                <View style={[theme.rowContainer, { marginTop: 16 }]}>
                  <RowText
                    theme={theme}
                    colors={colors}
                    title={t("company")}
                    text={props.company.name}
                  />
                </View>

                <View style={[theme.rowContainer, { marginTop: 16 }]}>
                  <RowText
                    theme={theme}
                    colors={colors}
                    title={t("role")}
                    text={profile.role}
                  />
                </View>

                <View style={[theme.rowContainer, { marginTop: 16 }]}>
                  <RowText
                    theme={theme}
                    colors={colors}
                    title={t("email")}
                    text={profile.email}
                  />
                </View>

                <TouchableOpacity
                  style={[theme.rowContainer, { marginTop: 16 }]}
                  onPress={() => setPhoneNumberModalVisible(true)}
                >
                  <RowText
                    theme={theme}
                    colors={colors}
                    title={t("phone")}
                    text={profile.phone ?? "-"}
                  />
                </TouchableOpacity>
              </View>
            </SettingsScreen>
          </View>
          <SimpleModal
            title={t("phone")}
            keyboardType={"phone-pad"}
            placeholder={props.profile.phone || t("phone")}
            errMsg={t("checkValue")}
            visible={phoneNumberModalVisible}
            isFetching={false}
            validateField={(text) => !validatePhoneNumber(text)}
            onSave={(text) => props.changePhoneNumber(text, props.profile)}
            onSubmitEditing
            onToggleModal={() => setPhoneNumberModalVisible(false)}
          />
        </View>
      )}
    </ThemeContext.Consumer>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePhoneNumber,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    profile: state.userInfo.profile,
    company: state.userInfo.company,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

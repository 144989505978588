import { useState, useEffect } from "react";
import { ActivityIndicator } from "react-native";
import { apiRequestWithToken } from "../../../common/lib/api";
import { isDevBuild } from "../../../common/lib/constants";
import routes from "../../../common/lib/LinkRoutes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { ThemeContext } from "../../../common/theming/theme-context";
import StretchButton from "../../../common/components/StretchButton";
import StatusText from "../../../common/components/StatusText";
import HeaderBar from "../components/HeaderBar";

export default function Authentication(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const [statusText, setStatusText] = useState(t("finalizingEIdentify"));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = props.location.pathname + (props.location.search ?? "");

    const path = decodeURIComponent("/docean.fi" + url);

    const routeObject = routes.find((route) => route.route.match(path));

    if (!routeObject) {
      setStatusText(`${t("eIdentifyFailed")}: Wrong path`);
      setLoading(false);
      return;
    }

    const params = routeObject.route.match(path);

    const { code, token, reason, platform, docId, error } = params;
    // const { code, token, reason } = route.match(
    //   decodeURIComponent(props.location.search)
    // );

    if (reason === "signDoc") {
      if (error === "cancel") {
        history.push(`/signDoc?${token}`);
      } else if (error) {
        history.push(`/signDoc?${token}&error=${error}`);
      } else {
        const body = {
          SignDateTime: new moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
          Code: code,
        };

        apiRequestWithToken(
          body,
          isDevBuild ? "/docs/testAddSignature" : "/docs/addSignature",
          token
        )
          .then((response) => {
            if (response.status === 200) {
              let msg = t("signDocSend");
              if (response.data === 20) {
                msg = t("signDocStatus20");
              } else if (response.data === 30) {
                msg = t("signDocStatus30");
              }
              setStatusText(msg);
            } else {
              history.push(`/signDoc?${token}&error=addSignature`);
            }
          })
          .finally(() => setLoading(false));
      }
    } else if (reason === "signIn") {
      if (token && reason) {
        setStatusText(t("nativeAppOpened"));
        window.location.assign(
          `docean://redirect/authentication?code=${code}&state=${token}?${reason}`
        );
      } else {
        setStatusText(t("nativeAppOpened"));
        window.location.assign(
          `docean://redirect/authentication?code=${code}&state=`
        );
      }
    } else if (reason === "completeDoc") {
      if (platform !== "web") {
        setStatusText(t("nativeAppOpened"));
        window.location.assign(
          `docean://redirect/authentication?code=${code}&state=${token}?${reason}?${platform}?${encodeURIComponent(
            docId
          )}`
        );
      } else {
        if (error) {
          history.push({
            pathname: `/PreviewAndSign?id=${encodeURIComponent(docId)}`,
            state: {
              generateNewPdf: true,
              generateModularPDF: true,
            },
          });
        } else {
          history.push({
            pathname: `/PreviewAndSign?id=${encodeURIComponent(
              docId
            )}&reason=${reason}&code=${code}`,
            state: {
              generateNewPdf: true,
              generateModularPDF: true,
            },
          });
        }
      }
    } else {
      setStatusText(t("eIdentifyFailed"));
      setLoading(false);
    }
  }, []);

  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div style={theming.theme.mainContainer}>
          <HeaderBar />

          <div style={theming.theme.homeContainer}>
            <div style={theming.theme.homeBody}>
              <StatusText text={statusText} />

              {loading ? (
                <div style={{ width: "100%", height: "40%", margin: 32 }}>
                  <ActivityIndicator color={theming.colors.text} />
                </div>
              ) : (
                <div style={{ margin: 32 }}>
                  <Link to="/" style={theming.theme.link}>
                    <StretchButton title={t("close")} />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

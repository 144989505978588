import React from "react";
import { Image as RNImage, Platform } from "react-native";
import update from "immutability-helper";
import moment from "moment";
import debounce from "lodash/debounce";
import { modalfy } from "react-native-modalfy";
import naturalCompare from "./naturalCompare";
import {
  fetchNetInfo,
  hp,
  wp,
  showToast,
  navigate,
  showExpiredTokenToast,
  captureSentryError,
  nanoid,
} from "./helperFns";
import sortBy from "./sort";
import {
  wrap,
  fileUpload,
  unlinkFile,
  moveFile,
  saveFile,
  readFile,
  fileExists,
  dirs,
  blobToBase64,
} from "./fileOperations";
import {
  downloadFileWithToken,
  getWithToken,
  putWithToken,
  apiRequestWithToken,
  apiRequestWithoutToken,
} from "../lib/api";

import { companyWidePermissions, constantSources } from "./constants";

import { sortWithoutField } from "./sort";

import { SYNC_VALUES_TIMESTAMPS_FN } from "../reducers/DocsReducer";

import createPdf from "../pdf/pdfCreator";

import { removeAttachments } from "../actions/OptionsActions";

import { store } from "../store";

import { evaluate } from "mathjs";
import i18next from "i18next";
import { findCellInArr } from "../layoutEditor/lib/helpers";

const zxcvbn = () => import("zxcvbn"); // Dynamically import zxcvbn
// const math = require("./math");

// const math = create(all);

//#region layout testing imports
// import createPdfOld from "../pdf/pdfCreatorOld";
// import {
//   docLayouts,
//   measurementObjectsMocks,
//   modularItemsMocks,
//   togglableCellsSectionsMocks,
//   pickerObjectsMocks,
// } from "../lib/constantsOLD";
// import diff from "recursive-diff";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
//#endregion

// const console = require("console");
// const util = require("util");

const OS = Platform.OS;

const _platforms = {
  web: "b",
  android: "a",
  ios: "i",
  windows: "w",
  macos: "m",
};
// TODO ADD FALLBACK IF ROLE ISNT FOUND
const _roles = {
  Demo: 1,
  Trial: 2,
  User: 3,
  Manager: 4,
  Boss: 5,
  Admin: 6,
  SuperAdmin: 7,
};

export function tryGetMoment(searchTerm) {
  return moment(
    searchTerm,
    [
      "D.M.YY",
      "D-M-YY",
      "YY.M.D",
      "YY-M-D",
      "DD.M.YY",
      "DD-M-YY",
      "YY.M.DD",
      "YY-M-DD",
      "D.MM.YY",
      "D-MM-YY",
      "YY.MM.D",
      "YY-MM-D",
      "DD.MM.YY",
      "DD-MM-YY",
      "YY.MM.DD",
      "YY-MM-DD",
      "D.M.YYYY",
      "D-M-YYYY",
      "YYYY.M.D",
      "YYYY-M-D",
      "DD.M.YYYY",
      "DD-M-YYYY",
      "YYYY.M.DD",
      "YYYY-M-DD",
      "D.MM.YYYY",
      "D-MM-YYYY",
      "YYYY.MM.D",
      "YYYY-MM-D",
      "DD.MM.YYYY",
      "DD-MM-YYYY",
      "YYYY.MM.DD",
      "YYYY-MM-DD",
      "L",
      "l",
    ],
    true
  );
}

export const getCustomerName = (customer) => {
  if (customer) {
    if (
      Object.prototype.hasOwnProperty.call(customer, "companyId") &&
      customer.companyId
    ) {
      return customer.name || "";
    } else {
      return (
        `${customer.lName ? customer.lName + " " : ""}` +
        `${customer.name || ""}`
      );
    }
  } else return "";
};

export const getSiteName = (site) => {
  if (site) {
    return site.name && site.address
      ? `${site.name} - ${site.address}`
      : site.name
      ? site.name
      : site.address;
  } else return "";
};
export const integrationSyncProjects = (
  t,
  companyToEditId,
  successColor,
  setLoading
) => {
  getWithToken("/admins/integrationProjectSync", null, {
    db: companyToEditId,
  })
    .then((res) => {
      if (res.status === 204) {
        showToast(
          `${t("projects")} ${t("updated").toLowerCase()}`,
          4000,
          successColor
        );
      } else {
        showToast(t("integrationSyncError"), 5000);
      }
    })
    .finally(() => setLoading(null));
};

export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase());
};

export const canAccessLayout = (role, db, layout, simulateNonAdmin) => {
  const isAdmin = simulateNonAdmin
    ? false
    : isSameOrGreaterThanRole(role, "Admin");
  if (layout) {
    let isSharedToOnlyDb =
      layout.sharedTo &&
      layout.sharedTo.length == 1 &&
      layout.sharedTo.includes(db);

    let canAccess = isAdmin || (!layout.global && isSharedToOnlyDb);
    return canAccess;
  } else {
    return false;
  }
};

export const generateLocalAtchId = () => {
  return (
    "local_attachment_" +
    "user/0" +
    "/" +
    (Platform.OS === "web" ? "web/" : "") +
    nanoid()
  );
};

export const generateLocalDocId = () => {
  return (
    "local_doc_" +
    "user/0" +
    "/" +
    (Platform.OS === "web" ? "web/" : "") +
    nanoid()
  );
};

export const weatherParams = [
  "condition.text",
  "feelslike_c",
  "feelslike_c",
  "feelslike_f",
  "gust_kph",
  "gust_mph",
  "humidity",
  "is_day",
  "last_updated",
  "last_updated_epoch",
  "precip_in",
  "precip_mm",
  "pressure_in",
  "pressure_mb",
  "temp_c",
  "temp_f",
  "uv",
  "vis_km",
  "vis_miles",
  "wind_degree",
  "wind_dir",
  "wind_kph",
  "wind_mph",
];

// MAPBOX
export const reverseGeocode = async (
  accessToken,
  SET_COMPANY_SETTINGS_PROP,
  latitude,
  longitude,
  getCurrentWeather
) => {
  try {
    if (!accessToken) {
      const settingsRes = await getWithToken(
        "/company/settings?keys=mapbox-access-token"
      );
      if (settingsRes.status === 200) {
        accessToken = settingsRes.data["mapbox-access-token"];
        SET_COMPANY_SETTINGS_PROP({
          prop: "mapboxAccessToken",
          value: accessToken,
        });
      }
    }

    const weatherPromise = getCurrentWeather
      ? getWithToken("/location/currentWeather", null, {
          latlng: latitude + "," + longitude,
        })
      : Promise.resolve(null);

    const geocodePromise = fetch(
      `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&access_token=${accessToken}`
    ).then((response) => response.json());

    const [currentWeatherRes, geocodeRes] = await Promise.all([
      weatherPromise,
      geocodePromise,
    ]);

    return {
      coordinates: {
        lat: geocodeRes?.features?.[0]?.geometry?.coordinates?.[1],
        lng: geocodeRes?.features?.[0]?.geometry?.coordinates?.[0],
      },
      components: geocodeRes?.features?.[0]?.properties?.context,
      currentWeather: currentWeatherRes?.data?.current,
    };
  } catch {
    return null;
  }
};
const locationTypeToMapboxType = {
  postal_code: "postcode",
  locality: "place",
  country: "country",
  route: "street_name",
  street_number: "street_number",
};
export const parseLocation = (gpsLocation, cellLocation) => {
  try {
    if (gpsLocation?.components) {
      let types = [];

      for (let i = 0; i < cellLocation.types.length; i++) {
        let type = cellLocation.types[i];

        if (type.startsWith("currentWeather")) {
          let value = byString(gpsLocation, type) || "";
          if (type === "currentWeather.condition.text") {
            value = toCamelCase(value);
            // ! clear replaced here since its used elsewhere also
            if (value === "clear") {
              value = "clearWeather";
            }
            value = i18next.t(value);
          }
          types.push(value);
        } else {
          type = locationTypeToMapboxType[type] || type;

          if (type === "lat") {
            types.push(gpsLocation.coordinates.lat || "");
          } else if (type === "lng") {
            types.push(gpsLocation.coordinates.lng || "");
          } else if (type === "street_name") {
            types.push(gpsLocation.components.address?.street_name || "");
          } else if (type === "street_number") {
            types.push(gpsLocation.components.address?.address_number || "");
          } else {
            types.push(gpsLocation.components[type]?.name || "");
          }
        }
      }
      return types.reduce(
        (prev, cur, index) =>
          prev +
          cur +
          (index === types.length - 1
            ? ""
            : cellLocation.separators?.[index] || " "),
        ""
      );
    }
  } catch (error) {
    errorReport({
      error,
      errorInFn: "parseLocation",
      errorInScreen: "parseLocation",
      errorParams: {
        gpsLocation,
        cellLocation,
      },
    });
    throw error;
  }
};

// GOOGLE
// export const reverseGeocode = async (
//   accessToken,
//   SET_COMPANY_SETTINGS_PROP,
//   latitude,
//   longitude
// ) => {
//   try {
//     const response = await getWithToken("/location/geocode", null, {
//       latlng: latitude + "," + longitude,
//     });

//     const json = response.data;
//     return {
//       coordinates: {
//         lat: json.results[0].geometry.location.lat,
//         lng: json.results[0].geometry.location.lng,
//       },
//       address_components: json.results[0].address_components,
//     };
//   } catch {
//     return null;
//   }
// };

// export const parseLocation = (location, cellLocation) => {
//   try {
//     if (Array.isArray(cellLocation.types)) {
//       let types = [];

//       for (let i = 0; i < cellLocation.types.length; i++) {
//         const type = cellLocation.types[i];
//         if (type.startsWith("currentWeather")) {
//           let value = byString(location, type) || "";
//           if (type === "currentWeather.condition.text") {
//             value = i18next.t(toCamelCase(value));
//           }
//           types.push(value);
//         } else if (location?.address_components) {
//           if (type === "lat") {
//             types.push(location.coordinates.lat || "");
//           } else if (type === "lng") {
//             types.push(location.coordinates.lng || "");
//           } else {
//             const found = location.address_components.find((comp) =>
//               comp.types.includes(type)
//             );
//             if (found) types.push(found.long_name);
//           }
//         }
//       }
//       return types.reduce(
//         (prev, cur, index) =>
//           prev +
//           cur +
//           (index === types.length - 1
//             ? ""
//             : cellLocation.separators?.[index] || " "),
//         ""
//       );
//     }
//   } catch {
//     return "";
//   }
// };
// GOOGLE

export const refreshLogin = async (setLoading, signOut, profile) => {
  try {
    const res = await apiRequestWithToken({}, "/web/refresh");
    if (res.status === 200) {
      setLoading(false, true);
    } else if (res.status === 204) {
      setLoading(false, true);
    } else if (res === "tokenErr" || res === "expired" || res === "invalid") {
      if (profile?.role) {
        showExpiredTokenToast();
        signOut();
      }
      setLoading(false, false);
    } else {
      setLoading(false, false);
    }
  } catch (error) {
    console.error(error);
    if (profile?.role) {
      showExpiredTokenToast();
      signOut();
    }
    setLoading(false, false);
  }
};

export function isLessThanRole(role, targetRole) {
  return _roles[role] < _roles[targetRole];
}

export function isSameOrGreaterThanRole(role, targetRole) {
  return _roles[role] >= _roles[targetRole];
}

export function isGreaterThanRole(role, targetRole) {
  return _roles[role] > _roles[targetRole];
}

export function tryTrim(str) {
  return str ? str.trim() : str;
}

export function getDocLanguage(doc, layout, profile, defaultPDFLanguage) {
  if (!doc) return i18next.language;
  let _profile, _defaultPDFLanguage;

  if (!profile || !defaultPDFLanguage) {
    const state = store.getState();
    _profile = profile ?? state.userInfo?.profile;
    _defaultPDFLanguage =
      defaultPDFLanguage ?? state.userInfo?.uiSettings?.defaultPDFLanguage;
  }
  const langFromDoc = _profile
    ? doc.values[getPdfLangValueKey(_profile)]
    : null;
  // layout may not have the preferred language
  const preferredLanguage =
    langFromDoc || _defaultPDFLanguage || i18next.language;

  if (layout?.languages?.includes(preferredLanguage)) return preferredLanguage;
  else return layout?.languages?.[0] || i18next.language;
}

export async function getPasswordScore(text) {
  const { default: zxcvbnFunc } = await zxcvbn(); // Wait for the dynamic import
  return zxcvbnFunc(text);
}

export async function hash(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export const getReduxLayout = (options, layoutId, layoutVersion) => {
  if (Platform.OS === "web") {
    const returnEditVersion =
      window.location.pathname.startsWith("/layoutEditor");
    return returnEditVersion
      ? options?.layoutEditor?.[layoutId] ||
          options?.layouts?.[layoutId]?.versions?.[-1] ||
          options?.layouts?.[layoutId]?.versions?.[layoutVersion]
      : options?.layouts?.[layoutId]?.versions?.[layoutVersion];
  } else {
    return options?.layouts?.[layoutId]?.versions?.[layoutVersion];
  }
};

export const getNewestLayoutVersion = (layoutsObj, id, returnEditVersion) => {
  const _returnEditVersion =
    Platform.OS === "web" &&
    (returnEditVersion || window.location.pathname.startsWith("/layoutEditor"));
  if (!layoutsObj) return null;
  const layoutWrapper = layoutsObj[id];
  const newestVersion = layoutWrapper
    ? layoutWrapper.versions[Math.max(...Object.keys(layoutWrapper.versions))]
    : null;
  // edit version is always -1 so return that if requested and it exists
  return _returnEditVersion && layoutWrapper?.versions?.[-1]
    ? layoutWrapper.versions?.[-1]
    : newestVersion;
};

export const recursiveFindDependenciesFromLayout = (
  layoutId,
  layoutVersion,
  layouts,
  foundDependencies,
  editorLayouts
) => {
  // TODO handle project dependency in editor
  if (
    layoutId &&
    layoutId !== "project" &&
    layoutVersion &&
    !foundDependencies.some(
      (x) => x.layoutId === layoutId && x.layoutVersion === layoutVersion
    )
  ) {
    foundDependencies.push({ layoutId, layoutVersion });

    const layout =
      (editorLayouts && editorLayouts[layoutId]) ||
      layouts[layoutId]?.versions?.[layoutVersion];

    if (layout) {
      const _foundProperties = [];

      findAllProperties(
        layout,
        ["layoutId", "connectedLayoutId"],
        _foundProperties
      );

      _foundProperties.forEach((x) => {
        recursiveFindDependenciesFromLayout(
          x.obj.connectedLayoutId,
          x.obj.connectedLayoutVersion,
          layouts,
          foundDependencies,
          editorLayouts
        );
        recursiveFindDependenciesFromLayout(
          x.obj.layoutId,
          x.obj.layoutVersion,
          layouts,
          foundDependencies,
          editorLayouts
        );
      });
    }
  }
};

export function findAllPropertiesWithValue(
  obj,
  properties,
  targets,
  result = [],
  parent
) {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      findAllPropertiesWithValue(item, properties, targets, result, obj);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      const targetIndex = properties.indexOf(key);
      if (targetIndex != -1) {
        if (typeof obj[key] == "string" && targets.includes(obj[key]))
          result.push({ value: obj[key], obj, parent });
      }
      findAllPropertiesWithValue(obj[key], properties, targets, result, obj);
    }
  }
  return result;
}

export function findAllProperties(obj, targets, result = [], parent) {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      findAllProperties(item, targets, result, obj);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (targets.includes(key)) result.push({ value: obj[key], obj, parent });
      findAllProperties(obj[key], targets, result, obj);
    }
  }
  return result;
}

export function replaceAllPropertiesWithValue(
  obj,
  properties,
  targets,
  replacements
) {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      replaceAllPropertiesWithValue(item, properties, targets, replacements);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      const targetIndex = properties.indexOf(key);
      if (targetIndex != -1) {
        if (typeof obj[key] == "string" && targets.includes(obj[key]))
          obj[key] = replacements[targetIndex];
      }
      replaceAllPropertiesWithValue(
        obj[key],
        properties,
        targets,
        replacements
      );
    }
  }
}

export function replaceStringsInObject(obj, searchTerms, replacements) {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      replaceStringsInObject(obj[i], searchTerms, replacements);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        for (let i = 0; i < searchTerms.length; i++) {
          obj[key] = obj[key].replace(
            new RegExp(searchTerms[i], "g"),
            replacements[i] || ""
          );
        }
      } else {
        replaceStringsInObject(obj[key], searchTerms, replacements);
      }
    }
  }
}

export function findAllLayoutDependencies(obj, targets, result = []) {
  if (typeof obj === "string") {
    for (const target of targets) {
      if (obj.includes(target)) {
        result.push({ value: target, obj });
        break;
      }
    }
  } else if (Array.isArray(obj)) {
    for (const item of obj) {
      findAllLayoutDependencies(item, targets, result);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (key === "layoutId" && obj[key])
        result.push({ value: obj[key], version: obj.layoutVersion, obj });
      findAllLayoutDependencies(obj[key], targets, result);
    }
  }
  return result;
}

export function getPdfRowHeight(ROW_HEIGHTS, rowNum, pageNumber) {
  return ROW_HEIGHTS[pageNumber]?.[rowNum] ?? 0;
}

export function getPdfRowY(ROW_HEIGHTS, rowNum, pageNumber, returnRowEnd) {
  let y = 0;
  for (let i = 0; i <= rowNum; i++) {
    const _rowHeight = getPdfRowHeight(ROW_HEIGHTS, i, pageNumber);
    y += _rowHeight;
  }

  if (!returnRowEnd) {
    y -= getPdfRowHeight(ROW_HEIGHTS, rowNum, pageNumber) / 2;
  }

  return y;
}

export const validateSignerEmails = (disableSigners, signatures, t, lang) => {
  let error = false;
  let errors = "";

  if (!disableSigners && signatures) {
    for (let index = 0; index < signatures.length; index++) {
      const signature = signatures[index];

      if (
        // TODO don't require email for signers when backend handles signatures with required === false and no filled email
        // signature.required !== false &&
        signature.authMethod !== "Drawing" &&
        signature.authMethod !== "None" &&
        !signature.email
      ) {
        error = true;
        if (!errors) {
          errors +=
            i18next.t("signersMissingEmails") +
            `: ${getTranslatedText(signature.title, lang)}`;
        } else {
          errors += `, ${getTranslatedText(signature.title, lang)}`;
        }
      } else if (
        signatures.some((x, j) => j !== index && x.email === signature.email)
      ) {
        error = true;
        if (!errors) {
          errors += i18next.t("duplicateSignerEmails") + `: ${signature.email}`;
        } else {
          errors += `\n${i18next.t("duplicateSignerEmails")}: ${
            signature.email
          }`;
        }
      }
    }
  }

  if (error) return errors;
  else return false;
};

// export const onUiSettingsChanged = (value, key) => {
//   fetchNetInfo().then((state) => {
//     if (state.isConnected) {
//         putWithToken(
//           {
//             key,
//             value,
//           },
//           "/users/uiSettings",
//         );
//     }
//   });
// };

/**
 * Erases attachments from file storage and redux
 * @param {Array} attachments array of attachment objects to be removed
 * @param {Function} callback function to call after erasure
 */
export const eraseAttachments = (attachments, callback) => {
  let unlinkPromises = [];
  for (let i = 0; i < attachments.length; i++) {
    const atch = attachments[i];
    const atchId = parseAtchId(atch);
    const uri = `${dirs.DocumentDir}/${atchId}.${atch.ext}`;
    const originalUri = `${dirs.DocumentDir}/original_${atchId}.${atch.ext}`;
    const thumbnailUri = `${dirs.DocumentDir}/${atchId}_thumbnail.${atch.ext}`;

    unlinkPromises.push(
      fileExists(uri).then((exists) => {
        if (exists) return unlinkFile(uri).catch(() => {});
        else return true;
      })
    );
    unlinkPromises.push(
      fileExists(originalUri).then((exists) => {
        if (exists) return unlinkFile(originalUri).catch(() => {});
        else return true;
      })
    );
    unlinkPromises.push(
      fileExists(thumbnailUri).then((exists) => {
        if (exists) return unlinkFile(thumbnailUri).catch(() => {});
        else return true;
      })
    );
  }
  return Promise.all(unlinkPromises).finally(() => {
    store.dispatch(
      removeAttachments({ keysToRemove: attachments.map((x) => x.id) })
    );
    callback && callback(attachments);
  });
};

export const getInitialPickerObjCols = (layout) => {
  let cols = [];

  try {
    if (layout) {
      const nameInput = layout.inputs.find((x) => x.key === "name");
      const lNameInput = layout.inputs.find((x) => x.key === "lName");
      if (nameInput && lNameInput) {
        cols.push({
          title: { all: i18next.t("name") },
          comparable: ["name", "lName"],
          reversed: false,
          sorted: false,
        });
      } else if (nameInput) {
        cols.push({
          title: nameInput.title,
          comparable: nameInput.key,
          reversed: false,
          sorted: false,
        });
      }
      for (let i = 0; i < layout.inputs.length; i++) {
        const input = layout.inputs[i];
        if (input.key !== "name" && input.key !== "lName") {
          cols.push({
            title: input.title,
            comparable: input.key,
            reversed: false,
            sorted: false,
          });
        }
        if (cols.length === 3) break;
      }
    } else {
      cols.push({
        title: { all: i18next.t("name") },
        comparable: ["name", "lName"],
        reversed: false,
        sorted: false,
      });
    }
  } catch (error) {
    errorReport({
      error: error,
      errorInScreen: "getInitialPickerObjCols",
      errorInFn: "getInitialPickerObjCols",
      errorMsg: i18next.t("columnGenerationFailed"),
    });
  }

  return cols;
};

export const arrToQueryString = (arr, idProp, generateText) => {
  return arr.reduce(
    (acc, cur, index) =>
      `${acc}${index !== 0 ? "&" : ""}${idProp}=${encodeURIComponent(
        generateText ? generateText(cur) : cur
      )}`,
    ""
  );
};
export const permissionCheck = (
  permissionObject,
  permissionKeys,
  role = "User",
  neededPermissions
) => {
  if (
    Array.isArray(permissionKeys) &&
    permissionKeys.every(
      (permissionKey) =>
        Array.isArray(permissionObject?.[permissionKey]?.[role]) &&
        neededPermissions.some(
          (x) => !permissionObject[permissionKey][role].includes(x)
        )
    )
  ) {
    return false;
  } else return true;
};
export const handleInitialSectionListScroll = (
  mountHandled,
  listRef,
  sectionsData,
  setVisibleData,
  valueKeysToCompareTo,
  scrollPosition,
  compareFn
) => {
  if (!mountHandled.current) {
    if (valueKeysToCompareTo) {
      let found,
        index = 0,
        sectionIndex = 0;
      for (let i = 0; i < sectionsData.sections.length; i++) {
        if (found) break;
        const section = sectionsData.sections[i];
        for (let j = 0; j < section.data.length; j++) {
          const item = section.data[j];
          if (
            item?.valueKey &&
            (compareFn
              ? compareFn(item)
              : valueKeysToCompareTo.includes(item.valueKey))
          ) {
            found = item;
            sectionIndex = i;
            index = j;
            break;
          }
        }
      }
      if (found) {
        // try {
        setTimeout(() => {
          listRef?.current.scrollToLocation({
            animated: true,
            itemIndex: index,
            viewPosition: 0,
            sectionIndex,
          });
          mountHandled.current = true;
        }, 500);
      } else {
        mountHandled.current = true;
      }
    } else if (scrollPosition) {
      try {
        setTimeout(() => {
          listRef.current?._wrapperListRef?._listRef?.scrollToOffset({
            offset: scrollPosition,
            animated: false,
          });
          mountHandled.current = true;
        }, 1000);
      } catch {
        /* empty */
      }
      // const index = viewableItems[0].index;
      // const sectionIndex = viewableItems[0].section.index;
      // try {
      //   listRef?.current?.scrollToLocation({
      //     animated: true,
      //     itemIndex: index,
      //     viewPosition: 0,
      //     sectionIndex,
      //   });
      //   mountHandled.current = true;
      // } catch (error) {
      // }
    } else {
      mountHandled.current = true;
    }
  }
};

export const allSettled = (promises) => {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value) => ({ state: "fulfilled", value }))
        .catch((reason) => ({ state: "rejected", reason }))
    )
  );
};
export function sortByTwoProps(a, b, prop1, prop2) {
  if (a[prop1] === b[prop1]) {
    return a[prop2] < b[prop2] ? -1 : 1;
  } else {
    return a[prop1] < b[prop1] ? -1 : 1;
  }
}
export function divideInputsIntoRows(inputs, prop1, prop2, noSort) {
  let _inputs = [];
  let curRowIndex = -1;
  if (inputs) {
    let itemInputs = [...inputs];
    if (!noSort) itemInputs.sort((a, b) => sortByTwoProps(a, b, prop1, prop2));
    return itemInputs.reduce((acc, input, i) => {
      const prevInput = itemInputs[i - 1];
      if (noSort || input[prop1] !== prevInput?.[prop1]) {
        acc.push([]);
        curRowIndex++;
      }
      acc[curRowIndex].push(input);
      return acc;
    }, _inputs);
  } else return _inputs;
}

function getPickerObjectsAtchValueKeys(cell, prefix = "", _doc, options) {
  const _valueKey = prefix + cell.valueKey;
  const pickerObject = getReduxLayout(
    options,
    cell.layoutId,
    cell.layoutVersion
  );

  const cellValues = _doc.values[_valueKey];

  if (!cellValues) return [];

  let atchCells;
  try {
    atchCells = pickerObject.inputs.filter(
      (input) => input.type === "filePicker"
    );
  } catch (error) {
    errorReport({
      error,
      errorInFn: "getPickerObjectsAtchValueKeys",
      errorInScreen: "functions",
    });
  }

  if (atchCells.length > 0) {
    let _atchValueKeys = [];

    cellValues.forEach((x) =>
      atchCells.forEach((atchCell) =>
        _atchValueKeys.push(
          `${prefix}${cell.valueKey}_${x.valueKey}_${atchCell.key}`
        )
      )
    );

    return _atchValueKeys;
  } else {
    return [];
  }
}

function getExtraRowsAtchValueKeys(
  cell,
  prefix = "",
  _doc,
  getLayoutAtchValueKeys
) {
  const extraRows = _doc.values[prefix + cell.valueKey];
  if (!isArrayWithItems(extraRows)) return [];

  let _atchValueKeys = [];

  cell.inputs?.forEach((input) => {
    if (
      input.type === "attachment" ||
      input.type === "multiAttachment" ||
      input.type === "pdfAttachment" ||
      input.type === "pickerObjects"
    ) {
      extraRows.forEach((x) => {
        if (input.type === "pickerObjects") {
          getLayoutAtchValueKeys(
            [input],
            `${prefix}${cell.valueKey}_${x.valueKey}_`
          );
        } else {
          _atchValueKeys.push(
            `${prefix}${cell.valueKey}_${x.valueKey}_${input.valueKey}`
          );
        }
      });
    }
  });

  return _atchValueKeys;
}

export function getAttachmentsFromValues(doc) {
  let attachments = [];

  if (doc?.values && typeof doc.values === "object") {
    Object.entries(doc.values).forEach(([key, value]) => {
      if (isArrayWithItems(value)) {
        value.forEach((x) => {
          if (
            x &&
            typeof x === "object" &&
            typeof x.id === "string" &&
            (x.id.startsWith("attachment") ||
              x.id.startsWith("local_attachment"))
          ) {
            attachments.push({ ...x, valueKey: key });
          }
        });
      }
    });
  }

  return attachments;
}
export function getAttachmentIdsFromValues(doc) {
  let ids = [];

  if (doc?.values && typeof doc.values === "object") {
    Object.entries(doc.values).forEach(([value]) => {
      if (isArrayWithItems(value)) {
        value.forEach((x) => {
          if (
            x &&
            typeof x === "object" &&
            typeof x.id === "string" &&
            x.id.startsWith("attachment")
          ) {
            ids.push(x.id);
          }
        });
      }
    });
  }

  return ids;
}

export function getAtchValueKeys(
  _doc,
  docLayout,
  lang,
  role,
  options,
  filterAtchValueKeys
) {
  let atchValueKeys = [];
  let graphCells = [];

  if (_doc?.id && docLayout) {
    const pushToAtchValueKeys = (key) => {
      if (!atchValueKeys.includes(key)) {
        atchValueKeys.push(key);
      }
    };

    const concatToAtchValueKeys = (keys) => {
      keys.forEach((key) => pushToAtchValueKeys(key));
    };

    const getLayoutAtchValueKeys = (cells, prefix, section) => {
      cells.forEach((layoutCell) => {
        if (layoutCell) {
          if (layoutCell.type === "extraRows") {
            concatToAtchValueKeys(
              getExtraRowsAtchValueKeys(
                layoutCell,
                prefix,
                _doc,
                getLayoutAtchValueKeys
              )
            );
          } else if (layoutCell.type === "pickerObjects") {
            concatToAtchValueKeys(
              getPickerObjectsAtchValueKeys(layoutCell, prefix, _doc, options)
            );
          } else if (
            layoutCell.type === "attachment" ||
            layoutCell.type === "multiAttachment" ||
            layoutCell.type === "pdfAttachment"
          ) {
            if (filterAtchValueKeys && section.filterBy) {
              if (
                layoutCell.valueKey === section.filterBy.valueKey &&
                validations[section.filterBy.compareMethod](
                  _doc.values[`${prefix}${layoutCell.valueKey}`],
                  section.filterBy.validValues,
                  lang,
                  role,
                  section.filterBy.roles
                )
              ) {
                pushToAtchValueKeys(`${prefix}${layoutCell.valueKey}`);
              }
            } else {
              pushToAtchValueKeys(`${prefix}${layoutCell.valueKey}`);
            }
          } else if (
            layoutCell.type === "chart" ||
            layoutCell.type === "graph"
          ) {
            graphCells.push(layoutCell);
          }
        }
      });
    };

    docLayout.sections?.forEach((section, sectionIndex) => {
      const { layoutId, layoutVersion, type, valueKey } = section;
      if (type === "rows") {
        getLayoutAtchValueKeys(section.cells, "", section);
      } else if (type === "togglableRows") {
        if (_doc.values[valueKey]) {
          getLayoutAtchValueKeys(section.cells, "", section);
        } else {
          getLayoutAtchValueKeys(section.alternateCells, "", section);
        }
      } else {
        const layout = getReduxLayout(options, layoutId, layoutVersion);
        const sectionValueKey = valueKey ?? sectionIndex;

        if (type === "togglableCellsSections") {
          let _cells = [];
          layout.items?.forEach((togglableCell) => {
            if (Array.isArray(togglableCell.inputs)) {
              togglableCell.inputs.forEach((item) => {
                _cells.push(item);
              });
            }
          });
          getLayoutAtchValueKeys(
            _cells,
            `${sectionValueKey}_${layoutId}_`,
            section
          );
        } else if (type === "modularItems") {
          const modularItems = _doc?.values?.[layoutId];

          modularItems?.forEach((modularItem) => {
            if (layout?.headerItems) {
              getLayoutAtchValueKeys(
                layout.headerItems,
                `${layoutId}_${modularItem.valueKey}_`,
                section
              );
            }
            modularItem?.innerItems?.forEach((innerItem) => {
              if (layout?.items) {
                getLayoutAtchValueKeys(
                  layout.items,
                  `${layoutId}_${modularItem.valueKey}_${innerItem?.valueKey}_`,
                  section
                );
              }
            });
          });
        } else if (type === "measurementObjects") {
          let measurementObjects = _doc?.values?.[layoutId];

          if (filterAtchValueKeys && section.filterBy) {
            measurementObjects = filterBy(
              measurementObjects,
              section.filterBy,
              _doc,
              layoutId,
              lang,
              role,
              layout
            );
          }
          measurementObjects?.forEach((measurementObject) => {
            getLayoutAtchValueKeys(
              layout.extraData,
              `${layoutId}_${measurementObject.valueKey}_`,
              section
            );
          });
        }
      }
    });
  } else {
    atchValueKeys = _doc.atchValueKeys || [];
  }

  return { atchValueKeys, graphCells };
}

export function getDocAttachments(doc, _attachments) {
  const _doc = doc;
  const atchObjects = _attachments;

  let attachmentsMissing = [];
  let attachmentsToSave = [];
  let newAttachments = [];
  let newAtchLoaded = {};

  if (_doc?.values) {
    const atchValueKeys = getAtchValueKeys(_doc);

    if (atchValueKeys) {
      for (let i = 0; i < atchValueKeys.length; i++) {
        const atchArr = _doc.values[atchValueKeys[i]];
        if (atchArr && Array.isArray(atchArr) && atchArr.length > 0) {
          for (let j = 0; j < atchArr.length; j++) {
            const atch = atchArr[j];
            if (atch) {
              const optionsAtch = atchObjects?.[atch.id];
              if (optionsAtch?.saveFailed) {
                attachmentsToSave.push({
                  ...optionsAtch,
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
              }
              if (atch.offlineAtch || optionsAtch?.saveFailed) {
                newAttachments.push({
                  ...optionsAtch,
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
                newAtchLoaded[atch.id] = "full";
              } else if (atch.base64) {
                newAttachments.push({
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
                newAtchLoaded[atch.id] = "full";
              } else if (optionsAtch) {
                newAttachments.push({
                  ...optionsAtch,
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
                newAtchLoaded[atch.id] = false;
              } else if (atch.docRef) {
                newAttachments.push({
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
                newAtchLoaded[atch.id] = false;
              } else if (!optionsAtch) {
                attachmentsMissing.push({
                  ...atch,
                  valueKey: atchValueKeys[i],
                });
              }
            }
          }
        }
      }

      return {
        attachmentsMissing,
        attachmentsToSave,
        newAttachments,
        newAtchLoaded,
      };
    }
  }

  return {
    attachmentsMissing,
    attachmentsToSave,
    newAttachments,
    newAtchLoaded,
  };
}

export function isArrayWithItems(a) {
  return Array.isArray(a) && a.length > 0;
}
export function getFileExtForResizer(item) {
  if (item.ext === "jpg" || item.ext === "jpeg") return "JPEG";
  else return item.ext.toUpperCase();
}

export function getAtchUri(atch, thumbnail) {
  const atchId = parseAtchId(atch);
  return (
    dirs.DocumentDir +
    "/" +
    (atch.docRef
      ? `${atchId}.pdf`
      : thumbnail
      ? `${atchId}_thumbnail.${atch.ext}`
      : `${atchId}.${atch.ext}`)
  );
}
/**
 * Downloads file from server if it doesn't exist in cache
 *
 * Sets atchLoaded to true when file has been loaded.
 *
 * @param {object} atch attachment object.
 * @param {boolean} thumbnail boolean, whether to download original size file or thumbnail.
 */
export function downloadAttachmentFile(
  atch,
  thumbnail,
  setAtchLoaded,
  docId,
  forceDownload,
  deepLinkToken
) {
  const atchId = parseAtchId(atch);
  const fileName = atch.docRef
    ? `${atchId}.pdf`
    : thumbnail
    ? `${atchId}_thumbnail.${atch.ext}`
    : `${atchId}.${atch.ext}`;

  return downloadFileWithToken({
    id: atch.id,
    fileName,
    setFileLoaded: setAtchLoaded,
    body: atch.docRef
      ? {
          DocId: atch.id,
          DocRef: atch.docRef,
        }
      : {
          DocId: docId,
          AttachmentId: atch.id,
          Thumbnail: thumbnail,
        },
    url: atch.docRef ? "/docs/getSinglePdf" : "/attachments/get",
    forceDownload,
    deepLinkToken,
  });
}

export function getFileUris(atch) {
  const atchIsLocal = !!(
    !atch.id ||
    atch.id.startsWith("local") ||
    atch.offlineAtch ||
    atch.saveFailed
  );
  const atchId = parseAtchId(atch);
  const fileName = atch.docRef
    ? `${atchId}.pdf`
    : atchIsLocal
    ? `${atch.name}.${atch.ext}`
    : `${atchId}.${atch.ext}`;
  const fileUri = `${dirs.DocumentDir}/${fileName}`;
  const originalFileUri = atchIsLocal
    ? `${dirs.DocumentDir}/original_${atch.name}.${atch.ext}`
    : `${dirs.DocumentDir}/original_${atchId}.${atch.ext}`;
  return { atchIsLocal, atchId, fileName, fileUri, originalFileUri };
}

export async function parseUri(uri) {
  if (uri.startsWith("file://")) {
    return uri.substring(8);
  } else if (uri.startsWith("content")) {
    try {
      let arr = uri.split("/");
      const readafile = await readFile(uri);
      await saveFile(`${dirs.CacheDir}/${arr[arr.length - 1]}`, readafile);
      return `${dirs.CacheDir}/${arr[arr.length - 1]}`;
    } catch (err) {
      console.error(err);
      return uri;
    }
  } else {
    return uri;
  }
}

export async function syncAtchToDb({
  itemIndex,
  atch,
  valueAttachment,
  totalItems,
  setProgress,
  docId,
  valueKey,
  ADD_ATTACHMENT,
  REMOVE_OBJECT_ARR_ITEM,
  ADD_TO_OBJECT_ARR,
  REMOVE_ATTACHMENTS,
  screen,
  uploadMsg,
  customToken,
}) {
  const sendErrorReport = (error, dontShowToast, errorParams) => {
    errorReport({
      error,
      errorInScreen: screen,
      errorInFn: "syncAtchToDb",
      errorMsg: i18next.t("failedToUploadAttachment") + `: ${atch?.name}`,
      dontShowToast,
      docId: docId,
      errorParams,
    });
  };

  try {
    let fileName, fileUri, originalFileUri;
    const fileUris = getFileUris(atch);
    fileName = fileUris.fileName;
    fileUri = fileUris.fileUri;
    originalFileUri = fileUris.originalFileUri;

    const _handleErr = (error, errorParams) => {
      sendErrorReport(error, false, errorParams);
      setProgress({
        msg: "",
        progress: 0,
      });
    };

    const uploadOriginal = async (newAtch) => {
      let originalFile;
      let originalExistsInS3;
      const existRes = await getWithToken("/attachments/exists", customToken, {
        id: newAtch.id,
        original: true,
      });
      if (existRes?.status === 200 && existRes.data) {
        originalExistsInS3 = true;
      }

      if (!originalExistsInS3) {
        try {
          originalFile = await readFile(originalFileUri);
        } catch {
          /* empty */
        }
        if (originalFile) {
          try {
            let body;
            if (Platform.OS === "web") {
              body = new FormData();
              body.append("file", base64ToBlob(originalFile));
              body.set("id", newAtch.id);
            } else {
              body = [
                {
                  name: "file",
                  filename: `${newAtch.name}.${newAtch.ext}`,
                  data: `data:${newAtch.type};base64,${originalFile}`,
                },
                {
                  name: "id",
                  data: newAtch.id,
                },
              ];
            }

            const originalRes = await fileUpload(
              "/attachments/original",
              customToken,
              body,
              ({ loaded, total }) => {
                setProgress({
                  upload: true,
                  msg: "uploadingAttachments",
                  progress: loaded / total,
                  multiPartProgress: {
                    total: totalItems,
                    itemBeingUploaded: itemIndex + 1,
                  },
                });
              }
            );
            if (
              (Platform.OS === "web" && originalRes.status === 204) ||
              (Platform.OS !== "web" && originalRes.respInfo.status === 204)
            ) {
              const _id = parseAtchId(newAtch);
              const newOriginalFileUri = `${dirs.DocumentDir}/original_${_id}.${atch.ext}`;
              try {
                if (originalFileUri !== newOriginalFileUri) {
                  await moveFile(originalFileUri, newOriginalFileUri);
                }
              } catch (error) {
                sendErrorReport(error, true, {
                  atchId: atch.id,
                  originalFileUri,
                  newOriginalFileUri,
                  msg: "failed to move original file",
                });
              }
            }
          } catch (error) {
            sendErrorReport(error, true, {
              atchId: atch.id,
              originalFileUri,
              msg: "failed to upload original file",
            });
          }
        }
      }
    };

    // if attachment file and original file have been saved before
    // but the attachment has been edited offline localFileNotSynced = true
    if (atch.localFileNotSynced) {
      try {
        let newAtch = atch;
        // upload object
        try {
          const updateRes = await putWithToken(atch, "/attachments/update");
          if (updateRes.status === 200) {
            newAtch = { ...newAtch, ...updateRes.data };
          } else {
            showToast(i18next.t("saveFailed2"));
            setProgress({});
          }
        } catch (error) {
          errorReport({
            error,
            errorInFn: "syncAtchToDb 1",
            errorInScreen: "AddAttachmentsScreen",
            errorParams: {
              atch,
            },
          });
          setProgress({});
        }

        // upload file
        try {
          const file = await readFile(fileUri);
          let body;
          if (OS === "web") {
            body = new FormData();
            body.append("file", base64ToBlob(file));
            body.set("id", atch.id);
          } else {
            body = [
              {
                name: "file",
                filename: fileName,
                data: "data:" + atch.type + ";base64," + file,
              },
              {
                name: "id",
                data: atch.id,
              },
            ];
          }

          const uploadRes = await fileUpload(
            "/attachments/updateFile",
            null,
            body,
            ({ loaded, total }) => {
              setProgress({
                upload: true,
                msg: uploadMsg || "uploadingAttachments",
                itemBeingUploaded: itemIndex + 1,
                progress: loaded / total,
              });
            }
          );
          if (
            Platform.OS === "web"
              ? uploadRes.status !== 200
              : uploadRes.respInfo.status !== 200
          ) {
            _handleErr(uploadRes);
          } else {
            let _newAtch =
              typeof uploadRes.data === "string"
                ? JSON.parse(uploadRes.data)
                : uploadRes.data;
            if (_newAtch) {
              newAtch = { ...newAtch, ..._newAtch };
            }
            await uploadOriginal(newAtch);

            ADD_ATTACHMENT({
              attachment: {
                ...newAtch,
                saveFailed: false,
                localFileNotSynced: false,
                fileLastModified:
                  newAtch.fileLastModified ??
                  (atch.fileLastModified
                    ? moment(atch.fileLastModified)
                        .add("seconds", 1)
                        .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
                    : moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")),
              },
            });
            if (atch.id !== newAtch.id) {
              REMOVE_ATTACHMENTS({
                keysToRemove: [atch.id],
              });
            }
          }
        } catch (error) {
          errorReport({
            error,
            errorInFn: "syncAtchToDb 2",
            errorInScreen: "AddAttachmentsScreen",
            errorParams: {
              atch,
            },
          });
          setProgress({});
        }
      } catch (error) {
        _handleErr(error);
      }
    } else {
      try {
        const _handleResp = async (resp) => {
          if (
            (Platform.OS === "web" && resp.status !== 200) ||
            (Platform.OS !== "web" && resp.respInfo.status !== 200)
          ) {
            _handleErr(resp);
          } else {
            const newAtch =
              typeof resp.data === "string" ? JSON.parse(resp.data) : resp.data;
            const _id = parseAtchId(newAtch);

            // upload original image as well
            await uploadOriginal(newAtch);

            try {
              await moveFile(
                fileUri,
                `${dirs.DocumentDir}/${_id}.${newAtch.ext}`
              );
            } catch (error) {
              sendErrorReport(error, true, {
                atchId: atch.id,
                newId: _id,
                fileUri,
                newUri: `${dirs.DocumentDir}/${_id}.${newAtch.ext}`,
                msg: "failed to move file in sync",
              });
            }

            if (docId && valueKey) {
              const oldValueAtch = valueAttachment || atch;
              const newValueAtch = valueAttachment
                ? { ...valueAttachment, id: newAtch.id, saveFailed: false }
                : {
                    id: newAtch.id,
                    name: newAtch.name,
                    desc: newAtch.desc,
                    saveFailed: false,
                  };

              REMOVE_OBJECT_ARR_ITEM({
                type: "Atch",
                valueKey: valueKey,
                oldVal: oldValueAtch,
                docId,
                idProp: "id",
              });
              ADD_TO_OBJECT_ARR({
                type: "Atch",
                valueKey: valueKey,
                value: newValueAtch,
                docId,
                sortProp: "name",
                idProp: "id",
              });
            }
            ADD_ATTACHMENT({ attachment: newAtch });
            if (atch.id !== newAtch.id) {
              REMOVE_ATTACHMENTS({
                keysToRemove: [atch.id],
              });
            }
            return { newAtch };
          }
        };

        let file;
        try {
          file = await readFile(fileUri);
        } catch (error) {
          _handleErr(error, {
            atchId: atch.id,
            fileUri,
            msg: "no file found in attachment sync",
          });
        }

        if (file) {
          let body;
          if (OS === "web") {
            body = new FormData();
            body.append("file", base64ToBlob(file));
            body.set("json", JSON.stringify({ ...atch, localId: atch.id }));
          } else {
            body = [
              {
                name: "file",
                filename: `${atch.name}.${atch.ext}`,
                data: wrap(await parseUri(fileUri)),
              },
              {
                name: "json",
                data: JSON.stringify({ ...atch, localId: atch.id }),
              },
            ];
          }
          return fileUpload("/attachments", null, body, ({ loaded, total }) => {
            setProgress({
              upload: true,
              msg: "uploadingAttachments",
              progress: loaded / total,
              multiPartProgress: {
                total: totalItems,
                itemBeingUploaded: itemIndex + 1,
              },
            });
          })
            .then(_handleResp)
            .catch(_handleErr);
        }
      } catch (error) {
        _handleErr(error);
      }
    }
  } catch (error) {
    sendErrorReport(error);
  }
}

export async function syncAttachmentsToDb({
  attachments,
  valueAttachments,
  callback,
  profile,
  setProgress,
  docId,
  REMOVE_OBJECT_ARR_ITEM,
  ADD_TO_OBJECT_ARR,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENTS,
  screen,
  t,
  customToken,
}) {
  try {
    if (profile.role !== "Trial") {
      for (let i = 0; i < attachments.length; i++) {
        const atch = attachments[i];
        setProgress({
          msg: "uploadingAttachments",
          progress: 0,
          multiPartProgress: {
            total: attachments.length,
            itemBeingUploaded: i + 1,
          },
        });
        await syncAtchToDb({
          itemIndex: i,
          atch,
          valueAttachment: valueAttachments?.[i],
          totalItems: attachments.length,
          // from props
          setProgress,
          docId,
          valueKey: atch.valueKey,
          ADD_ATTACHMENT,
          REMOVE_OBJECT_ARR_ITEM,
          ADD_TO_OBJECT_ARR,
          REMOVE_ATTACHMENTS,
          screen,
          t,
          customToken,
        });
      }
    }
    setProgress({
      progress: 0,
      msg: "",
    });
    callback && callback();
  } catch (error) {
    errorReport({
      error,
      errorInScreen: "CreateDocScreen",
      errorInFn: "syncDocAttachments",
      errorMsg: i18next.t("failedToUploadAttachment"),
      docId: docId,
    });
    callback && callback();
  }
}

export async function syncDocAttachments({
  netState,
  doc,
  options,
  callback,
  profile,
  setProgress,
  REMOVE_OBJECT_ARR_ITEM,
  ADD_TO_OBJECT_ARR,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENTS,
  screen,
  t,
  customToken,
}) {
  // TODO handle attachemnt resource 410 => delete attachment from doc, redux & file storage
  if (doc?.id && profile.role !== "Trial" && !doc.id.includes("demo")) {
    try {
      if (netState.isConnected) {
        const atchObjects = options.attachments;

        let attachmentsToFetch = [];
        let attachmentsToSave = [];
        let valueAttachments = [];

        if (doc?.values) {
          const attachments = getAttachmentsFromValues(doc);

          if (attachments.length > 0) {
            attachments.forEach((atch) => {
              if (atch) {
                const optionsAtch = atchObjects?.[atch.id];
                if (optionsAtch) {
                  if (!optionsAtch.offlineAtch) {
                    const needsSync = !!(
                      (typeof optionsAtch.id === "string" &&
                        optionsAtch.id.startsWith("local")) ||
                      optionsAtch.saveFailed ||
                      optionsAtch.localFileNotSynced
                    );

                    if (needsSync) {
                      attachmentsToSave.push({
                        ...optionsAtch,
                        valueKey: atch.valueKey,
                      });
                      valueAttachments.push(atch);
                    } else {
                      attachmentsToFetch.push({
                        ...optionsAtch,
                        valueKey: atch.valueKey,
                      });
                    }
                  }
                }
              }
            });
          }
        }

        let fetchedAttachmentObjects;

        if (attachmentsToFetch.length > 0) {
          const attachmentsToFetchRes = await getWithToken(
            "/resource/multiple",
            customToken,
            {
              ids: attachmentsToFetch.map((x) => x.id),
            }
          );

          if (attachmentsToFetchRes.status === 200) {
            fetchedAttachmentObjects = attachmentsToFetchRes.data;
            if (fetchedAttachmentObjects.length !== attachmentsToFetch.length) {
              // if some attachments were not found in db, delete them from doc, redux and file cache
              let notFoundAttachments = [];

              attachmentsToFetch.forEach((x) => {
                if (!fetchedAttachmentObjects.some((y) => y.id === x.id)) {
                  notFoundAttachments.push(x);
                  // remove from doc
                  REMOVE_OBJECT_ARR_ITEM({
                    type: "Atch",
                    valueKey: x.valueKey,
                    oldVal: x,
                    docId: doc.id,
                    idProp: "id",
                  });
                }
              });

              // erase removes attachments from file storage and redux
              await eraseAttachments(notFoundAttachments);
            }
          }
        }

        if (attachmentsToSave.length > 0) {
          syncAttachmentsToDb({
            attachments: attachmentsToSave,
            valueAttachments,
            profile,
            callback,
            setProgress,
            docId: doc.id,
            REMOVE_OBJECT_ARR_ITEM,
            ADD_TO_OBJECT_ARR,
            ADD_ATTACHMENT,
            REMOVE_ATTACHMENTS,
            screen,
            t,
            customToken,
          });
        } else {
          callback && callback();
        }
      } else {
        callback && callback();
      }
    } catch (error) {
      errorReport({
        error,
        errorInScreen: "CreateDocScreen",
        errorInFn: "syncDocAttachments",
        errorMsg: i18next.t("failedToUploadAttachment"),
        docId: doc?.id,
      });
      callback && callback();
    }
  } else {
    callback && callback();
  }
}

const isImageAtch = (atch) =>
  (atch.type && atch.type.startsWith("image")) || atch.dimensions;
export const isPreviewableAtch = (atch) =>
  isImageAtch(atch) || atch.type === "application/pdf" || atch.docRef;

// ! DEPRECATE AFTER EVERYTHING IS FETCHED WITH SIGNED URLS
export const checkAtchDownloadNeed = (
  atch,
  setAtchLoaded,
  ADD_ATTACHMENT,
  docId,
  ogImage,
  downloadIfNotPreviewable,
  thumbnail,
  token,
  dontDownloadFile
) => {
  // if (atch.base64) {
  //   const dimensions = await getImageSize(
  //     `data:image/${atch.ext};base64,${atch.base64}`
  //   );
  //   props.setDimensions(dimensions);
  //   setAtchLoaded({ key: atch.id, value: true });
  // } else
  if (
    /*atchLoaded !== 'no content' && */ !ogImage &&
    atch &&
    !atch.offlineAtch &&
    !atch.saveFailed &&
    !atch.id.startsWith("local")
  ) {
    return fetchNetInfo().then((state) => {
      if (state.isConnected) {
        if (!atch.docRef) {
          return getWithToken("/resource", token, {
            id: atch.id,
            ref: 3,
          }).then((res) => {
            let __atch;
            if (res.status === 200) {
              if (ADD_ATTACHMENT) ADD_ATTACHMENT({ attachment: res.data });
              __atch = { ...atch, ...res.data };
            } else {
              __atch = atch;
            }
            const fileChanged =
              (!atch.fileLastModified && __atch.fileLastModified) ||
              (atch.fileLastModified &&
                __atch.fileLastModified &&
                moment(__atch.fileLastModified).diff(
                  moment(atch.fileLastModified)
                ) > 0);

            if (
              !dontDownloadFile &&
              (downloadIfNotPreviewable ||
                (thumbnail ? isImageAtch(atch) : isPreviewableAtch(__atch)))
            ) {
              return downloadAttachmentFile(
                __atch,
                thumbnail,
                setAtchLoaded,
                docId,
                fileChanged,
                token
              );
            } else {
              setAtchLoaded?.({ key: atch.id, value: true });
            }
          });
        } else {
          if (
            downloadIfNotPreviewable ||
            (thumbnail ? isImageAtch(atch) : isPreviewableAtch(atch))
          ) {
            return downloadAttachmentFile(
              { ...atch, ext: "pdf" },
              thumbnail,
              setAtchLoaded,
              docId,
              false,
              token
            );
          } else {
            setAtchLoaded?.({ key: atch.id, value: true });
          }
        }
      } else {
        if (!atch.ext) {
          showToast(
            `${i18next.t("fileDownloadFailed")}: ${i18next.t(
              "checkInternetConnection"
            )}`
          );
        } else {
          if (
            downloadIfNotPreviewable ||
            (thumbnail ? isImageAtch(atch) : isPreviewableAtch(atch))
          ) {
            return downloadAttachmentFile(
              atch,
              thumbnail,
              setAtchLoaded,
              docId,
              false,
              token
            );
          } else {
            setAtchLoaded?.({ key: atch.id, value: true });
          }
        }
      }
    });
  } else {
    setAtchLoaded?.({ key: atch.id, value: true });
  }
};

// const debounce = (func, timeout = 300) => {
//   let timer;
//   return (...args) => {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       func.apply(this, args);
//     }, timeout);
//   };
// };

export const useDebounce = (callback, timeout = 1000) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timeout);
  }, []);

  return debouncedCallback;
};

export const updateValueKeys = (layout, obj, arr) => {
  let newValueKey = layout.currentValueKey ?? 1;
  let innerCellWidths;

  const _updateValueKeys = (arr) => {
    if (arr) {
      for (let j = 0; j < arr.length; j++) {
        const cell = arr[j];
        const oldValueKey = cell.valueKey;
        newValueKey++;
        cell.valueKey = newValueKey.toString();
        if (cell.inputs) {
          const cellInnerCellWidths = layout.innerCellWidths?.[oldValueKey];
          if (cellInnerCellWidths) {
            innerCellWidths = update(innerCellWidths, {
              $auto: {
                [cell.valueKey]: { $set: cellInnerCellWidths },
              },
            });
          }
          _updateValueKeys(cell.inputs);
        }
      }
    }
  };

  if (obj) {
    _updateValueKeys(obj.cells);
    _updateValueKeys(obj.alternateCells);
    _updateValueKeys(obj.headerLayout?.cells);
    _updateValueKeys(obj.itemsHeaderLayout?.cells);
    _updateValueKeys(obj.itemLayout?.cells);
    return { obj, newValueKey, innerCellWidths };
  } else if (arr) {
    _updateValueKeys(arr);
    return { arr, newValueKey, innerCellWidths };
  }
};
export const getSectionIndexWithProp = (prop) => {
  if (prop === "extraData") return -3;
  else if (prop === "items") return -4;
  else if (prop === "headerItems") return -5;
  else if (prop === "docValuesVisualization") return -6;
};
export const getLayoutCellsArray = (layout, sectionIndex, cellsProp) => {
  if (sectionIndex === -1) return layout?.headerLayout?.cells || [];
  else if (sectionIndex === -2) return layout?.footerLayout?.cells || [];
  else if (sectionIndex === -3) return layout?.extraData || [];
  else if (sectionIndex === -4) return layout?.items || [];
  else if (sectionIndex === -5) return layout?.headerItems || [];
  else if (sectionIndex === -6) return layout?.docValuesVisualization || [];
  else if (cellsProp === "alternateCells")
    return layout.sections?.[sectionIndex]?.alternateCells || [];
  else if (cellsProp === "headerLayout")
    return layout.sections?.[sectionIndex]?.headerLayout?.cells || [];
  else if (cellsProp === "itemsHeaderLayout")
    return layout.sections?.[sectionIndex]?.itemsHeaderLayout?.cells || [];
  else if (cellsProp === "itemLayout")
    return layout.sections?.[sectionIndex]?.itemLayout?.cells || [];
  else return layout.sections?.[sectionIndex]?.cells || [];
};
export const getLayoutSection = (layout, sectionValueKey) => {
  if (sectionValueKey === -1) return layout?.headerLayout;
  else if (sectionValueKey === -2) return layout?.footerLayout;
  else if (sectionValueKey === -3) return layout?.extraData;
  else if (sectionValueKey === -4) return layout?.items;
  else if (sectionValueKey === -5) return layout?.headerItems;
  else if (sectionValueKey === -6) return layout?.docValuesVisualization;
  else return layout.sections.find((x) => x.valueKey === sectionValueKey);
};
export const getLayoutColumnsProp = (layout, sectionIndex, cellsProp) => {
  if (sectionIndex === -1) return "headerLayout";
  else if (sectionIndex === -2) return "footerLayout";
  else if (sectionIndex === -3) return "extraData";
  else if (sectionIndex === -4) return "items";
  else if (sectionIndex === -5) return "headerItems";
  else if (sectionIndex === -6) return "docValuesVisualization";
  else if (cellsProp === "headerLayout")
    return `${layout.sections[sectionIndex].valueKey}_headerLayout`;
  else if (cellsProp === "itemsHeaderLayout")
    return `${layout.sections[sectionIndex].valueKey}_itemsHeaderLayout`;
  else if (cellsProp === "itemLayout")
    return `${layout.sections[sectionIndex].valueKey}_itemLayout`;
  else return sectionIndex;
  // TODO add itemsheaderlayout etc. somehow separate the pdf ones from layout extradata etc.
};
export const getSectionUpdateObj = (layout, sectionValueKey, updateObj) => {
  if (sectionValueKey === -1) {
    return {
      headerLayout: {
        $auto: updateObj,
      },
    };
  } else if (sectionValueKey === -2) {
    return {
      footerLayout: {
        $auto: updateObj,
      },
    };
  } else if (layout.sections) {
    const sectionIndex = layout.sections.findIndex(
      (x) => x.valueKey === sectionValueKey
    );
    if (sectionIndex !== -1) {
      return {
        sections: {
          [sectionIndex]: {
            $auto: updateObj,
          },
        },
      };
    } else {
      throw "missing section";
    }
  }
};
export const getCellsUpdateObj = (sectionIndex, cellsProp, updateObj) => {
  if (sectionIndex === -1) {
    return {
      headerLayout: {
        $auto: { cells: { $autoArray: updateObj } },
      },
    };
  } else if (sectionIndex === -2) {
    return {
      footerLayout: {
        $auto: { cells: { $autoArray: updateObj } },
      },
    };
  } else if (sectionIndex === -3) {
    return {
      extraData: {
        $autoArray: updateObj,
      },
    };
  } else if (sectionIndex === -4) {
    return {
      items: {
        $autoArray: updateObj,
      },
    };
  } else if (sectionIndex === -5) {
    return {
      headerItems: {
        $autoArray: updateObj,
      },
    };
  } else if (sectionIndex === -6) {
    return {
      docValuesVisualization: {
        $autoArray: updateObj,
      },
    };
  } else if (cellsProp === "alternateCells") {
    return {
      sections: {
        $autoArray: {
          [sectionIndex]: {
            alternateCells: { $autoArray: updateObj },
          },
        },
      },
    };
  } else if (cellsProp === "headerLayout") {
    return {
      sections: {
        $autoArray: {
          [sectionIndex]: {
            headerLayout: { $auto: { cells: { $autoArray: updateObj } } },
          },
        },
      },
    };
  } else if (cellsProp === "itemsHeaderLayout") {
    return {
      sections: {
        $autoArray: {
          [sectionIndex]: {
            itemsHeaderLayout: { $auto: { cells: { $autoArray: updateObj } } },
          },
        },
      },
    };
  } else if (cellsProp === "itemLayout") {
    return {
      sections: {
        $autoArray: {
          [sectionIndex]: {
            itemLayout: { $auto: { cells: { $autoArray: updateObj } } },
          },
        },
      },
    };
  } else
    return {
      sections: {
        $autoArray: {
          [sectionIndex]: {
            $auto: cellsProp
              ? { [cellsProp]: { $autoArray: updateObj } }
              : { cells: { $autoArray: updateObj } },
          },
        },
      },
    };
};
export const findParentCellsInSection = (section, valueKeysWithInnerWidths) => {
  const findInArr = (arr) => {
    if (arr) {
      for (let j = 0; j < arr.length; j++) {
        const cell = arr[j];
        if (cell.inputs) {
          valueKeysWithInnerWidths.push(cell.valueKey);
        }
      }
    }
  };

  findInArr(section.cells);
  findInArr(section.alternateCells);
  findInArr(section.headerLayout?.cells);
  findInArr(section.itemsHeaderLayout?.cells);
  findInArr(section.itemLayout?.cells);

  return valueKeysWithInnerWidths;
};

const replaceValueKeysInArr = (arr, currentValueKey, replacedValueKeys) => {
  if (Array.isArray(arr))
    return arr.map((x) => {
      let newObj = x;

      if (x.valueKey) {
        currentValueKey.current++;
        replacedValueKeys[x.valueKey] = currentValueKey.current.toString();
        newObj = update(x, {
          valueKey: { $set: currentValueKey.current.toString() },
        });
      }

      if (x.inputs) {
        newObj = update(newObj, {
          inputs: {
            $set: replaceValueKeysInArr(
              x.inputs,
              currentValueKey,
              replacedValueKeys
            ),
          },
        });
      }

      return newObj;
    });
  else return arr;
};
export const replaceArrayValueKeys = (array, currentValueKey = 0) => {
  const _currentValueKey = { current: currentValueKey };
  let replacedValueKeys = {};

  const updateCellsProp = (obj, prop, cellsProp) => {
    if (cellsProp) {
      if (obj[prop]?.[cellsProp]) {
        return update(obj, {
          [prop]: {
            [cellsProp]: {
              $apply: (x) =>
                replaceValueKeysInArr(x, _currentValueKey, replacedValueKeys),
            },
          },
        });
      } else return obj;
    } else {
      if (obj[prop]) {
        return update(obj, {
          [prop]: {
            $apply: (x) =>
              replaceValueKeysInArr(x, _currentValueKey, replacedValueKeys),
          },
        });
      } else return obj;
    }
  };

  let _array = [];

  for (let i = 0; i < array?.length; i++) {
    _currentValueKey.current++;
    replacedValueKeys[array[i].valueKey] = _currentValueKey.current.toString();
    _array.push(
      update(array[i], {
        valueKey: { $set: _currentValueKey.current.toString() },
      })
    );

    _array[i] = updateCellsProp(_array[i], "cells");
    _array[i] = updateCellsProp(_array[i], "alternateCells");
    _array[i] = updateCellsProp(_array[i], "headerLayout", "cells");
    _array[i] = updateCellsProp(_array[i], "itemsHeaderLayout", "cells");
    _array[i] = updateCellsProp(_array[i], "itemLayout", "cells");
  }

  return {
    array: _array,
    currentValueKey: _currentValueKey.current,
    replacedValueKeys,
  };
};

const findInnerCell = (parentCell, valueKey, innerCellPath, parents) => {
  if (parentCell.inputs) {
    for (let i = 0; i < parentCell.inputs.length; i++) {
      if (parentCell.inputs[i].valueKey === valueKey) {
        parents.push(parentCell);
        return {
          parentCell: parentCell,
          innerCellPath: innerCellPath + `.[${i}]`,
          innerCellProp: "inputs",
          found: parentCell.inputs[i],
        };
      } else if (parentCell.inputs[i].inputs) {
        const found = findInnerCell(
          parentCell.inputs[i],
          valueKey,
          innerCellPath + `.[${i}].inputs`,
          parents
        );
        if (found) {
          parents.push(parentCell);
          return found;
        }
      }
    }
  } else {
    return null;
  }
};
export const findLayoutCell = (layout, valueKey, returnParentCell) => {
  let found,
    sectionIndex,
    sectionValueKey,
    cellIndex,
    innerCellPath,
    innerCellProp,
    parentCell,
    parentValueKey,
    cellsProp,
    container,
    parents;

  const findInArr = (i, _container, arr, _sectionValueKey, _cellsProp) => {
    if (!found && arr) {
      for (let j = 0; j < arr.length; j++) {
        parents = [];
        const cell = arr[j];
        if (cell.valueKey === valueKey) {
          sectionIndex = i;
          sectionValueKey = _sectionValueKey;
          cellIndex = j;
          cellsProp = _cellsProp;
          found = cell;
          container = _container;
          parents = undefined;
          break;
        } else if (cell.inputs) {
          const innerObj = findInnerCell(cell, valueKey, "inputs", parents);

          if (innerObj?.found) {
            sectionIndex = i;
            sectionValueKey = _sectionValueKey;
            cellIndex = j; // cell index needs to always be the top level cells index
            cellsProp = _cellsProp;
            // innerCellIndex = innerObj.innerCellIndex;
            innerCellPath = innerObj.innerCellPath;
            innerCellProp = innerObj.innerCellProp;
            found = returnParentCell
              ? { ...innerObj.found, parentCell: innerObj.parentCell }
              : innerObj.found;
            parentCell = innerObj.parentCell; //{ ...innerObj.parentCell, cellIndex: j, sectionIndex: i };
            parentValueKey = innerObj.parentCell.valueKey;
            container = _container;
            break;
          }
        }
      }
    }
  };
  findInArr(
    -1,
    layout.headerLayout,
    layout.headerLayout?.cells,
    -1,
    "headerLayout"
  );
  findInArr(
    -2,
    layout.footerLayout,
    layout.footerLayout?.cells,
    -2,
    "footerLayout"
  );
  findInArr(-3, layout, layout.extraData, -3, "extraData");
  findInArr(-4, layout, layout.items, -4, "items");
  findInArr(-5, layout, layout.headerItems, -5, "headerItems");
  findInArr(
    -6,
    layout,
    layout.docValuesVisualization,
    -6,
    "docValuesVisualization"
  );
  // TODO handle Cells
  // TODO handle AlternateCells
  // TODO handle ItemLayout.cells
  // TODO handle ItemsHeaderLayout?.Cells

  if (!found) {
    for (let i = 0; i < layout.sections?.length; i++) {
      const section = layout.sections[i];
      findInArr(i, section, section.cells, section.valueKey, "cells");
      findInArr(
        i,
        section,
        section.alternateCells,
        section.valueKey,
        "alternateCells"
      );
      findInArr(
        i,
        section,
        section.headerLayout?.cells,
        section.valueKey,
        "headerLayout"
      );
      findInArr(
        i,
        section,
        section.itemsHeaderLayout?.cells,
        section.valueKey,
        "itemsHeaderLayout"
      );
      findInArr(
        i,
        section,
        section.itemLayout?.cells,
        section.valueKey,
        "itemLayout"
      );
    }
  }
  return {
    found,
    sectionIndex,
    sectionValueKey,
    cellIndex,
    // innerCellIndex,
    innerCellPath,
    innerCellProp,
    parentCell,
    parents,
    parentValueKey,
    cellsProp,
    container,
    layoutType: layout?.layoutType,
  };
};

// export const getCellsColumnWidth = (cells) => {
//   // props with multiple columns:
//   // - direction: "row"
//   // - type: "dualCheckBox"
//   // - type: "dualCheckBoxText"
//   // - type: "chart"?
//   let columnCount = 0;
//   for (let i = 0; i < cells.length; i++) {
//     const cell = cells[i];
//     columnCount = (cell.column || 1) + (cell.width || 1) - 1;
//   }
//   return columnCount;
// };

/**
 *  props with multiple columns:
 *  - direction: "row"
 *  - type: "dualCheckBox"
 *  - type: "dualCheckBoxText"
 *  - type: "chart"?
 * @param {*} cell
 * @returns
 */
export function getCellWidthInColumns(cell) {
  const cellWidth = cell.width ?? 1;
  if (cell.direction === "row") {
    // titleWidth = 2, width = 1 = 3;
    // titleWidth = 0, width = 3 = 3;
    // titleWidth = 1, width = 1 = 2;
    // titleWidth = 3, width = 3 = 4;
    return Math.max(
      (cell.formTitleOnly ? 0 : cell.titleWidth ?? 1) + 1,
      cellWidth
    );
  } else if (
    // cell.type === "checkBox" ||
    cell.type === "dualCheckBox"
  ) {
    // TODO handle no checkTitles
    // TODO width should only be one at least in checkboxtext if direction !== "row" and width is just 1
    let _width =
      (cell.formTitleOnly ? 0 : cell.titleWidth ?? 1) +
      (cell.firstCheckBoxWidth ?? 1) +
      (cell.secondCheckBoxWidth ?? 1);
    return Math.max(cellWidth, _width);
  } else return cellWidth;
}

export const getRowColumnCount = (cells) => {
  let columnCount = 0;
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    const cellWidth = getCellWidthInColumns(cell);
    columnCount += cellWidth;

    const cellColumn = cell.column || 1;
    // TODO does this break something?
    // if column count would be 3 but cell column is 5, the columnCount should be 5 if cell width is 1 or if cells width is 2 then the count would be 6 etc.
    if (cellColumn - 1 + cellWidth > columnCount)
      columnCount = cellColumn - 1 + cellWidth;
  }
  return columnCount;
};
export const arrayToObjectWithProp = (a, keyProp, valueProp) => {
  let o = {};
  if (Array.isArray(a)) {
    for (let i = 0; i < a.length; i++) {
      const e = a[i];
      o[e[keyProp]] = e[valueProp];
    }
  }
  return o;
};
export const arrayToObject = (a) => {
  let o = {};
  for (let i = 0; i < a.length; i++) {
    const e = a[i];
    o[e.id] = e;
  }
  return o;
};
export const arrToObj = (arr, _obj) => {
  var result = _obj || {};
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      result[arr[i].id] = arr[i];
    }
  }
  return result;
};

export const setNativeProps = (el, props) => {
  if (el) {
    // Apply each style individually
    if (Platform.OS === "web") {
      Object.keys(props).forEach((key) => {
        el.style[key] = props[key];
      });
    } else {
      Object.keys(props).forEach((key) => {
        el.setNativeProps?.({
          style: {
            [key]: props[key],
          },
        });
      });
    }
  }
};

export const setNativeBorderColor = (el, borderColor) => {
  if (el) {
    if (Platform.OS === "web") {
      el.style.borderColor = borderColor;
    } else {
      el.setNativeProps?.({
        style: {
          borderColor,
        },
      });
    }
  }
};

export const setNativeBorder = (el, borderStyle, borderWidth, borderColor) => {
  if (el) {
    if (Platform.OS === "web") {
      el.style.border = `${borderWidth}px ${borderStyle} ${borderColor}`;
    } else {
      el.setNativeProps?.({
        style: {
          borderWidth,
          borderStyle,
          borderColor,
        },
      });
    }
  }
};

export const setNativeBackgroundColor = (el, color) => {
  if (el) {
    if (Platform.OS === "web") {
      el.style.backgroundColor = color;
    } else {
      el.setNativeProps?.({
        style: {
          backgroundColor: color,
        },
      });
    }
  }
};

export const createUnfinishedPdf = async (
  item,
  {
    setLoading,
    options,
    lang,
    company,
    chartUris,
    setProgress,
    profile,
    SET_LAYOUTS,
  }
) => {
  const layoutsRes = await getInitialLayouts(options?.lastModifiedLayouts);
  SET_LAYOUTS({
    layouts: layoutsRes,
  });
  const _baseDoc = layoutsRes.layouts?.[item.layoutId];
  const docLayout = _baseDoc.versions[item.layoutVersion];

  await createPdf({
    options,
    lang,
    company,
    chartUris,
    setProgress,
    signDigitally: false,
    profile,
    doc: item,
    content: docLayout,
  })
    .then((pdf) => {
      const fileName =
        getDocType(item.docType, item.type, lang) +
        "_" +
        moment().format("HH-mm-ss_DD-MM-YYYY");
      if (Platform.OS === "web") {
        // const _file = base64ToBlob(pdf.base64String, "application/pdf;base64");
        // _file.lastModifiedDate = new Date();
        // _file.name = fileName;
        // let fileURL = URL.createObjectURL(_file);
        // window.open(fileURL, "_blank", "noopener");
        // URL.revokeObjectURL(fileURL);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const blob = base64ToBlob(pdf.base64String, "application/pdf;base64"),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.target = "_blank";
        a.rel = "noopener";
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const filterLayoutsArr = (layouts, companyId, returnHidden) => {
  return layouts?.filter((val) => {
    if (val.global || (val.sharedTo && val.sharedTo?.includes(companyId))) {
      if (
        returnHidden ||
        !val.hiddenFrom ||
        (val.hiddenFrom && !val.hiddenFrom?.includes(companyId))
      ) {
        return true;
      }
    }
  });
};
export const filterLayouts = (layouts = {}, companyId, role) => {
  let data = {};
  Object.entries(layouts)?.forEach(([key, val]) => {
    if (
      //__DEV__ ||
      (role && isSameOrGreaterThanRole(role, "Admin")) ||
      val.global ||
      (val.sharedTo && val.sharedTo?.includes(companyId))
    ) {
      if (
        !val.hiddenFrom ||
        (val.hiddenFrom && !val.hiddenFrom?.includes(companyId))
      ) {
        data[key] = val;
      }
    }
  });
  return data;
};

export const databaseLayoutToLocal = (layoutWrapper) => {
  return {
    ...layoutWrapper,
    layout: undefined,
    versions: {
      [layoutWrapper.layoutVersion]: {
        ...layoutWrapper.layout,
        lastModified: layoutWrapper.lastModified,
        layoutValueLastModified: layoutWrapper.layoutValueLastModified,
        layoutType: layoutWrapper.layoutType,
        databaseId: layoutWrapper.id,
        layoutId: layoutWrapper.layoutId,
        editable: layoutWrapper.editable,
      },
    },
  };
};

export const reduceDatabaseLayoutsToLocal = (layoutsObj, databaseLayouts) => {
  let localLayouts;

  if (databaseLayouts) {
    databaseLayouts.forEach((layoutWrapper) => {
      if (
        moment(layoutWrapper.lastModified).isAfter(
          moment(layoutsObj.lastModifiedLayouts)
        )
      )
        layoutsObj.lastModifiedLayouts = layoutWrapper.lastModified;

      localLayouts = layoutsObj.layouts;

      if (
        !localLayouts[layoutWrapper.layoutId] ||
        layoutWrapper.layoutVersion >
          localLayouts[layoutWrapper.layoutId].layoutVersion
      ) {
        localLayouts[layoutWrapper.layoutId] = {
          ...layoutWrapper,
          layout: undefined,
          versions: localLayouts[layoutWrapper.layoutId]
            ? { ...localLayouts[layoutWrapper.layoutId].versions }
            : {},
        };
      }

      localLayouts[layoutWrapper.layoutId].versions[
        layoutWrapper.layoutVersion
      ] = {
        ...layoutWrapper.layoutValue,
        editable: layoutWrapper.editable,
        lastModified: layoutWrapper.lastModified,
        layoutValueLastModified: layoutWrapper.layoutValueLastModified,
        layoutType: layoutWrapper.layoutType,
        databaseId: layoutWrapper.id,
        layoutId: layoutWrapper.layoutId,
        category: layoutWrapper.category,
      };
    });
  }
};

export function getInitialLayouts(
  lastModifiedLayouts,
  endpoint = "/layouts/check",
  customToken
) {
  return fetchNetInfo().then((netState) => {
    if (netState.isInternetReachable) {
      return apiRequestWithToken(
        { lastModifiedLayouts: lastModifiedLayouts },
        endpoint,
        customToken
      ).then((response) => {
        if (response?.status === 200) {
          const layouts = {
            layouts: {},
            lastModifiedLayouts:
              lastModifiedLayouts ||
              moment({ year: 2020 }).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
          };

          reduceDatabaseLayoutsToLocal(layouts, response.data);
          return layouts;
        } else {
          errorReport({
            error: response?.status,
            errorInFn: "getInitialLayouts",
            errorInScreen: "getInitialLayouts",
            errorMsg: i18next.t("failedToFetchFormLayouts"),
            dontNavigate: true,
            errorParams: {
              lastModifiedLayouts: lastModifiedLayouts,
            },
          });
          return {};
        }
      });
    } else {
      return {};
    }
  });
}

// ! DEPRECATED ?
export const findLayoutCellB = (valueKey, values, options, lang, doc1) => {
  let itemName;
  let innerItemName;
  let layoutType;
  const docLayout = getReduxLayout(options, doc1.layoutId, doc1.layoutVersion);
  let found;
  let path;

  const splitValueKey = valueKey.split("_");
  if (valueKey.startsWith("measurement")) {
    let layoutInfo;
    layoutInfo = docLayout.specialInputs?.find(
      (_specialInput) => _specialInput.layoutId === splitValueKey[0]
    );
    if (!layoutInfo) {
      layoutInfo = docLayout.sections.find(
        (section) => section.layoutId === splitValueKey[0]
      );
    }
    const layout = getReduxLayout(
      options,
      layoutInfo.layoutId,
      layoutInfo.layoutVersion
    );
    if (layout) {
      layoutType = getTranslatedText(layout.title, lang);
      if (splitValueKey.length === 1) {
        found = {
          type: "textField",
          title: { text: { all: i18next.t("id") } },
          value: "asdf",
        };
      } else {
        //if (splitValueKey.length === 3) {
        itemName = values[splitValueKey[0]]?.find(
          (x) => x.valueKey === splitValueKey[1]
        )?.id;
        if (layout.titles && layout.titles[splitValueKey[2]]) {
          found = {
            type: "measurementRow",
            title: { text: layout.titles[splitValueKey[2]] },
          };
        } else {
          const _found = findCellInArr(
            layout?.extraData,
            splitValueKey.slice(2).join("_"),
            values,
            lang
          );
          found = _found.found;
          path = _found.path;
          // found = layout?.extraData.find(
          //   (cell) => cell.valueKey === splitValueKey[2]
          // );
        }
      }
    }
  } else if (valueKey.startsWith("modular")) {
    const layoutInfo = docLayout.sections.find(
      (section) => section.layoutId === splitValueKey[0]
    );
    const layout = getReduxLayout(
      options,
      layoutInfo.layoutId,
      layoutInfo.layoutVersion
    );
    if (layout) {
      layoutType = getTranslatedText(layout.title, lang);

      if (splitValueKey.length === 1) {
        found = {
          type: "textField",
          title: { text: { fin: i18next.t("id") } },
          value: "asdf",
        };
      } else {
        itemName = values[splitValueKey[0]]?.find(
          (x) => x.valueKey === splitValueKey[1]
        )?.title;

        let innerItem;

        for (let i = 0; i < values[splitValueKey[0]].length; i++) {
          const item = values[splitValueKey[0]][i];
          if (item.valueKey === splitValueKey[1]) {
            innerItem = item?.innerItems?.find(
              (innerItem) => innerItem.valueKey === splitValueKey[2]
            );
            if (innerItem) break;
          }
        }

        // const layoutItem = layout.items.find(
        //   (x) => x.valueKey === splitValueKey[3]
        // );

        let _found = findCellInArr(
          layout.items,
          splitValueKey.slice(3).join("_"),
          values,
          lang
        );
        const layoutItem = _found.found;
        if (innerItem && layoutItem) {
          innerItemName = innerItem.title;
          found = layoutItem;
        } else {
          let __found = findCellInArr(
            layout.headerItems,
            splitValueKey.slice(2).join("_"),
            values,
            lang
          );
          found = __found.found;
          path = _found.path;
          // found = layout.headerItems.find(
          //   (x) => x.valueKey === splitValueKey[2]
          // );
        }
      }
    }
  } else if (
    splitValueKey.length > 1 &&
    splitValueKey[1].startsWith("togglable")
  ) {
    const layoutInfo = docLayout.sections.find(
      (section) => section.layoutId === splitValueKey[1]
    );
    const layout = getReduxLayout(
      options,
      layoutInfo.layoutId,
      layoutInfo.layoutVersion
    );
    if (layout) {
      layoutType = getTranslatedText(layout.title, lang);

      for (let i = 0; i < layout?.items?.length; i++) {
        if (found) break;
        const item = layout?.items[i];
        let _found = findCellInArr(
          item.inputs,
          splitValueKey.slice(2).join("_"),
          values,
          lang
        );
        found = _found.found;
        path = _found.path;
        //found = item.items.find((cell) => cell.valueKey === splitValueKey[2]);
      }
    }
  } else {
    for (let index = 0; index < docLayout.sections.length; index++) {
      if (found) break;
      const section = docLayout.sections[index];
      if (section.type === "rows") {
        let _found = findCellInArr(section?.cells, valueKey, values, lang);
        found = _found.found;
        path = _found.path;
      }
    }
  }

  if (found) {
    if (found)
      return {
        layoutType,
        itemName,
        innerItemName,
        path,
        cell: update(found, {
          title: {
            $apply: (x) => x || found.formTitle || found.parentTitle,
          },
          valueKey: { $set: valueKey },
        }),
      };
  } else return { cell: {} };
};
export const getNewSortObj = (
  sortObj,
  sortArrIndex,
  reversed,
  multiColumnSort,
  time
) => {
  try {
    const newSortObj = sortObj.map((x, i) => {
      if (i === sortArrIndex) {
        return {
          ...x,
          sorted: true,
          reversed,
          sortedAt: time,
        };
      } else {
        return { ...x, sorted: multiColumnSort ? x.sorted : false };
      }
    });
    return newSortObj;
  } catch (error) {
    errorReport({
      error,
      errorInFn: "getNewSortObj",
      errorInScreen: "BrowseProjectsList",
      errorParams: {
        sortObj,
        sortArrIndex,
        reversed,
      },
    });
    return sortObj;
  }
};

export const sortUsingSortObj = (
  arrToSort,
  comparable,
  _sortObj,
  sortArrIndex,
  reversed,
  keepReverse,
  options,
  lang
) => {
  const primeText = (text) =>
    getTranslatedText(text, lang)
      .toString()
      .toUpperCase()
      .replace(/[\s]/gi, "");

  const compareText = (a, b) => {
    const aToCompare = primeText(a);
    const bToCompare = primeText(b);
    return reversed
      ? bToCompare.localeCompare(aToCompare)
      : aToCompare.localeCompare(bToCompare);
  };
  const primer = (a, b) => {
    if (comparable === "docType") {
      return compareText(a.type || a.docType, b.type || b.docType);
    } else if (Array.isArray(comparable)) {
      return compareText(
        getColumnValue(comparable, a, options),
        getColumnValue(comparable, b, options)
      );
    } else {
      const splitProps = comparable.split(".");

      if (splitProps[0] === "target") {
        return compareText(a.target[splitProps[1]], b.target[splitProps[1]]);
      } else {
        return compareText(a[splitProps[0]] ?? "", b[splitProps[0]] ?? "");
      }
    }
  };

  const newSortObj = keepReverse
    ? null
    : getNewSortObj(_sortObj, sortArrIndex, reversed);
  if (!comparable || comparable === "date") {
    return {
      sortedArr: arrToSort.sort(
        sortBy("date", reversed, function (a) {
          return moment(a);
        })
      ),
      newSortObj,
    };
  } else {
    return {
      sortedArr: arrToSort.sort(primer),
      newSortObj,
    };
  }
};

export const addToIndex = (curIndex, listLength) => {
  const maxIndex = listLength - 1;
  if (curIndex === maxIndex) {
    return 0;
  } else {
    return curIndex + 1;
  }
};

export const subFromIndex = (curIndex, listLength) => {
  const maxIndex = listLength - 1;
  if (curIndex === 0) {
    return maxIndex;
  } else {
    return curIndex - 1;
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uncapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const base64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = Buffer.from(b64Data, "base64").toString("binary");
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const sendToModify = ({ profile, item, navigation, prevPathname }) => {
  if (
    profile.role === "Trial" || profile.role === "User"
      ? item.id.includes(profile.id) ||
        item.creatorId === profile.id ||
        (item.sharedTo && item.sharedTo.includes(profile.id))
      : true
  ) {
    if (Platform.OS !== "web") {
      navigate("create", navigation, {
        docId: item.id,
        prevPathname,
      });
    } else {
      //modifyDoc(item.id);
      navigate("create?docId=" + encodeURIComponent(item.id), navigation, {
        prevPathname,
      });
    }
  } else {
    showToast(i18next.t("noPermissionToOpenDoc"));
  }
};

const resendDoc = (props) => {
  const { item, signOut, UPDATE_DOC_STATUS } = props;
  fetchNetInfo().then((state) => {
    if (state.isConnected) {
      apiRequestWithToken({ docId: item.id }, "/docs/resend")
        .then((response) => {
          if (response.status === 200) {
            const docStatus = parseInt(response.data);
            let msg = i18next.t("completeDocSent");
            let msgColor = "green";

            if (docStatus === 20) {
              msg = i18next.t("signingLinkSent");
            } else if (docStatus === 30) {
              msg = i18next.t("emailSendFailed");
              msgColor = "red";
            } else if (docStatus === 40) {
              msg = i18next.t("signingLinkSendFailed");
              msgColor = "red";
            } else if (docStatus === 50) {
              msg = i18next.t("signerRejectedDoc");
              msgColor = "red";
            }
            UPDATE_DOC_STATUS?.({
              docId: item.id,
              status: docStatus,
            });
            showToast(msg, 3000, msgColor);
          } else if (response === "expired" || response === "tokenErr") {
            signOut();
            showExpiredTokenToast();
          } else if (response === "failure") {
            errorReport({
              error: response?.status,
              errorInFn: "resendDoc1",
              errorInScreen: "resendDoc",
            });
          } else if (response === "timeout") {
            showToast(i18next.t("lostConnectionToServer"));
          } else {
            showToast(i18next.t("unhandledError"));
          }
        })
        .catch((error) => {
          errorReport({
            error,
            errorInFn: "resendDoc2",
            errorInScreen: "resendDoc",
          });
        });
    } else {
      showToast(i18next.t("checkInternetConnection"));
    }
  });
};

export const previewPdf = ({
  item,
  arrName,
  hideModals,
  navigation,
  prevPathname,
  customToken,
}) => {
  hideModals();
  fetchNetInfo().then((state) => {
    if (state.isConnected) {
      if (Platform.OS === "web") {
        let pathname = "/PreviewAndSign";
        let search = `?id=${encodeURIComponent(
          item.id
        )}&docRef=${encodeURIComponent(
          item.docRef
        )}&docsArrName=${encodeURIComponent(
          arrName
        )}&docType=${encodeURIComponent(item.docType || "")}`;
        if (customToken) {
          search += `&token=${customToken}`;
        }

        navigation.push({
          pathname,
          search,
          state: { prevPathname },
        });
      } else {
        navigate("PreviewAndSign", navigation, {
          pdfToFetch: item,
          prevPathname,
          customToken,
        });
      }
    } else {
      showToast(i18next.t("checkInternetConnection"));
    }
  });
};

// TODO broken, could be used for multi day docs
// const sendExtraDocs = ({
//   item,
//   options,
//   lang,
//   profile,
//   setSendExtraDocsScreen,
// }) => {
//   let docs = [];

//   for (let i = 0; i < item.extraDocs.length; i++) {
//     const extraDoc = item.extraDocs[i];
//     const docLayoutInfo = options.layouts[extraDoc.layoutId];
//     const docLayout = docLayoutInfo.versions[extraDoc.layoutVersion];

//     let noFilter = true;
//     let filteredItems = [];
//     const section = docLayout.sections.find((x) => x.filterBy);
//     if (section && section.filterBy.target === "items") {
//       noFilter = false;
//       const { layoutId, layoutVersion } = section;
//       const layout = options?.layouts?.[layoutId]?.versions?.[layoutVersion];

//       const _filterBy = section.filterBy;
//       filteredItems = filterBy(
//         item.values[layoutId],
//         _filterBy,
//         item,
//         layoutId,
//         lang,
//         profile.role,
//         layout,
//         true
//       );
//     }

//     if (noFilter || (filteredItems && filteredItems.length > 0)) {
//       docs.push({
//         ...item,
//         ...extraDoc,
//         revisionFromDoc: item.id,
//         id: generateLocalDocId(),
//         status: 0,
//         type: docLayoutInfo.type,
//       });
//     }
//   }

//   if (docs.length > 0) {
//     setSendExtraDocsScreen({
//       isVisible: true,
//       docs,
//     });
//   } else {
//     setTimeout(() => showToast(i18next.t("noRemaksFoundInDocToSend")), 1000);
//   }
// };

/**
 * Creates a revision or copy from doc, revision only adds revisionFromDoc param to doc
 *
 * @param {object} copyDoc whether to create a copy of the doc or a revision
 * @param {object} props t,
    item,
    profile,
    addDoc,
    saveDocToDb,
    navigation,
    hideModals,
    lastDocRequests,
    options,
    company,
    prevPathname
 */
export const revisionFromDoc = (props, copyDoc) => {
  try {
    const {
      t,
      item,
      profile,
      addDoc,
      saveDocToDb,
      navigation,
      hideModals,
      lastDocRequests,
      options,
      company,
      prevPathname,
    } = props;
    // TODO fetch fresh doc from database before creating revision?
    const isTrial = profile.role === "Trial" ? true : false;
    if (
      permissionCheck(
        companyWidePermissions[company?.id],
        ["docs"],
        profile?.role,
        ["create"]
      )
    ) {
      // TODO fetch trial doc creation status from server
      if (isTrial && profile.completedDocsCount >= 2) {
        showToast(i18next.t("trialDocCreationLimitInfo"));
      } else {
        if (
          !item.type ||
          (item.type && !item.layoutId && !item.layoutVersion) ||
          item.layoutId === "docLayouts/0"
        ) {
          showToast(i18next.t("revisionCannotBeMadeFromDoc"));
        } else {
          // TODO IN THE FUTURE NEED TO FETCH LAYOUT HERE IF IT'S NOT FOUND

          const docLayout =
            options?.layouts?.[item.layoutId]?.versions?.[item.layoutVersion];
          let tmpDoc = {
            ...item,
            id: generateLocalDocId(),
            technician: profile.name + " " + profile.lName,
            status: 0,
            statusEnum: 0,
            creatorId: profile.id,
            sharedTo: [],
            timestamps: undefined,
            statuses: item.statuses ?? [],
            customStatus: docLayout?.statuses
              ? docLayout.statuses[0]
              : {
                  key: "",
                  title: { all: "" },
                },
            creatorSignature: item.creatorSignature
              ? {
                  ...item.creatorSignature,
                  signed: false,
                  authId: undefined,
                  rejectionComment: undefined,
                  signedAt: undefined,
                  name: undefined,
                  printName: undefined,
                }
              : undefined,
            signatures: Array.isArray(item.signatures)
              ? item.signatures.map((x) => ({
                  ...x,
                  signed: false,
                  authId: undefined,
                  rejectionComment: undefined,
                  signedAt: undefined,
                  name: undefined,
                  printName: undefined,
                }))
              : undefined,
          };

          if (!item.statuses) {
            let syncObj = SYNC_VALUES_TIMESTAMPS_FN(profile.id, tmpDoc, true);
            tmpDoc = update(tmpDoc, {
              creatorId: syncObj.creatorId,
              timestamps: (x) => update(x || {}, syncObj.timestamps),
            });
          }

          if (copyDoc) {
            tmpDoc.copiedFromDoc = item.id;
          } else {
            tmpDoc.revisionFromDoc = item.id;
          }

          fetchNetInfo().then((state) => {
            if (state.isConnected) {
              addDoc(tmpDoc);
              const isMobile =
                OS !== "web" || /Mobi|Android/i.test(navigator.userAgent);
              const prevPathname = isMobile
                ? "browse"
                : props.navigation?.location?.pathname +
                  (props.navigation?.location?.search || "");
              saveDocToDb({
                doc: tmpDoc,
                lastDocRequests: lastDocRequests,
                fn: (newDocId) => {
                  sendToModify({
                    profile,
                    item: { ...tmpDoc, id: newDocId ?? tmpDoc.id },
                    navigation,
                    prevPathname,
                  });
                  hideModals();
                },
                options: options,
                profile: profile,
                t: t,
                navigation: navigation,
              });
            } else {
              addDoc(tmpDoc);
              showToast(i18next.t("docSaveFailedSavedLocally"));
              sendToModify({
                profile,
                item: tmpDoc,
                navigation,
                prevPathname,
              });
              hideModals();
            }
          });
        }
      }
    } else {
      showToast(i18next.t("noPermissionToCreateDocs"));
    }
  } catch (error) {
    errorReport({
      error,
      errorInFn: "revisionFromDoc",
      errorInScreen: "functions",
    });
  }
};

/**
 * Downloads file from server if it doesn't exist in cache
 *
 * Sets atchLoaded to true when file has been loaded.
 *
 * @param {object} props item,
    profile,
    navigation,
    hideModals,
    setState,
    prevPathname,
    ADD_INTERNAL_DOC,
    SET_DONT_SHOW_AGAIN,
 * @param {boolean} dontShowAgain
 */
export const previewForm = (props, dontShowAgain) => {
  try {
    const {
      // TODO use translations
      // t,
      item,
      profile,
      navigation,
      hideModals,
      setState,
      ADD_INTERNAL_DOC,
      SET_DONT_SHOW_AGAIN,
      prevPathname,
      callback,
    } = props;
    if (SET_DONT_SHOW_AGAIN && typeof dontShowAgain === "boolean") {
      SET_DONT_SHOW_AGAIN({ prop: "InternalDoc", val: dontShowAgain });
    }

    // TODO May create duplicate internals for some reason, maybe a double click or something
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        setState &&
          setState({
            modalPickerFetching: true,
          });
        getWithToken("/docs/internalDoc", null, { id: item.id })
          .then((getResponse) => {
            if (getResponse.status === 204) {
              return apiRequestWithToken(
                {
                  docId: item.id,
                  receiverId: profile.id,
                  senderId: profile.id,
                  status: 1,
                },
                "/docs/move"
              ).then((postResponse) => {
                if (postResponse.status === 204) {
                  return getWithToken("/docs/internalDoc", null, {
                    id: item.id,
                  }).then((getResponse2) => {
                    if (getResponse2.status === 200) {
                      ADD_INTERNAL_DOC(getResponse2.data);
                      if (callback) {
                        callback({ id: getResponse2.data.id });
                      } else {
                        sendToModify({
                          profile,
                          item: { id: getResponse2.data.id },
                          navigation,
                          prevPathname,
                        });
                      }
                      hideModals();
                    } else {
                      throw getResponse2;
                    }
                  });
                } else {
                  throw postResponse;
                }
              });
            } else if (getResponse.status === 200) {
              ADD_INTERNAL_DOC(getResponse.data);
              if (callback) {
                callback({ id: getResponse.data.id });
              } else {
                sendToModify({
                  profile,
                  item: { id: getResponse.data.id },
                  navigation,
                  prevPathname,
                });
              }
              hideModals();
            } else {
              throw getResponse;
            }
          })
          .catch(() => {
            showToast(i18next.t("docCopyFailed"));
            setState &&
              setState({
                modalPickerFetching: false,
              });
          })
          .finally(
            () =>
              setState &&
              setState({
                modalPickerFetching: false,
              })
          );
      } else {
        showToast(i18next.t("checkInternetConnection"));
        setState &&
          setState({
            modalPickerFetching: false,
          });
      }
    });
  } catch (error) {
    errorReport({
      error,
      errorInFn: "previewForm",
      errorInScreen: "functions",
    });
  }
};

export const setPreviewFormAlert = (props) => {
  const { mergeState, dontShowAgainInternalDoc } = props;
  if (dontShowAgainInternalDoc) {
    previewForm(props, true);
  } else {
    mergeState({
      setModal: {
        modal: "alert",
        props: {
          dontShowAgainBox: true,
          visible: true,
          title: i18next.t("alert"),
          text: i18next.t("internalDocCreationInfo"),
          middleButtonDisabled: true,
          leftButtonTitle: i18next.t("cancel"),
          rightButtonTitle: i18next.t("ok"),
          cancelButton: true,
          onMiddleButtonPress: null,
          onRightButtonPress: (dontShowAgain) =>
            previewForm(props, dontShowAgain),
        },
      },
      modalPicker: { visible: false },
    });
  }
};

const copyDocToOtherUser = ({
  item,
  receiverId,
  profile,
  hideModals,
  setState,
}) => {
  try {
    if (!item.type || (item.type && !item.layoutId && !item.layoutVersion)) {
      showToast(i18next.t("docCantBeMoved"), 3000, "accent");
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          setState({
            modalPickerFetching: true,
          });
          apiRequestWithToken(
            {
              docId: item.id,
              receiverId: receiverId,
              senderId: profile.id,
            },
            "/docs/move"
          )
            .then((response) => {
              if (response.status === 204) {
                hideModals();
                showToast(i18next.t("docCopied"));
              } else if (response === "gone") {
                showToast(i18next.t("docToMoveDoesntExist"));
              } else {
                showToast(i18next.t("docCopyFailed"));
              }
            })
            .catch(() => {
              showToast(i18next.t("docCopyFailed"));
              setState({
                modalPickerFetching: false,
              });
            })
            .finally(() =>
              setState({
                modalPickerFetching: false,
              })
            );
        } else {
          showToast(i18next.t("checkInternetConnection"));
          setState({
            modalPickerFetching: false,
          });
        }
      });
    }
  } catch (error) {
    errorReport({
      error,
      errorInFn: "copyDocToOtherUser",
      errorInScreen: "functions",
    });
  }
};

const setUserPickerModal = ({
  item,
  profile,
  options,
  hideModals,
  setState,
}) => {
  try {
    setState({
      setModal: {
        modal: "modalPicker",
        props: {
          title: i18next.t("chooseRecipient"),
          visible: true,
          textProp: "title",
          action: (user) =>
            copyDocToOtherUser({
              item,
              receiverId: user.id,
              profile,
              hideModals,
              setState,
            }),
          data: options.users
            .filter((x) => x.email)
            .map((user) => {
              return {
                id: user.id,
                title:
                  user.name && user.lName
                    ? user.name + " " + user.lName + ", " + user.email
                    : user.email,
              };
            }),
        },
      },
      modalPicker: { visible: false },
    });
  } catch (error) {
    errorReport({
      error,
      errorInFn: "setUserPickerModal",
      errorInScreen: "functions",
    });
  }

  //setState();
};

export const getCompletedDocOnPressActions = (props) => {
  try {
    const { item } = props;
    const canUpdate = !props.permissions || props.permissions.Update;
    let actions = [];

    if (canUpdate) {
      actions.push({
        action: () => revisionFromDoc(props, true),
        title: i18next.t("copy"),
      });
    }
    if (canUpdate) {
      actions.push({
        action: () => revisionFromDoc(props),
        title: i18next.t("revision"),
      });
    }

    if (canUpdate && item.atchValueKeys) {
      actions.push({
        action: () => setUserPickerModal(props),
        title: i18next.t("copyToAnotherUser"),
      });
    }
    if (canUpdate && item.type && item.atchValueKeys) {
      actions.push({
        action: () => setPreviewFormAlert(props),
        title: i18next.t("viewCompletedDoc"),
      });
    }
    if (item.status === 10) {
      actions.push({
        action: () => previewPdf(props),
        title: i18next.t("viewPDF"),
      });
    }
    // TODO broken, could be used for multi day docs
    // if (item.extraDocs) {
    //   actions.push({
    //     action: () => sendExtraDocs(props.item,
    //       props.options,
    //       props.lang,
    //       props.profile,
    //       props.setSendExtraDocsScreen
    //     ),
    //     title: i18next.t("sendExtraDocs"),
    //   });
    // }
    if (canUpdate) {
      actions.push({
        action: () => {
          const { openModal } = modalfy();
          openModal("SendDocsModal", { docs: [item] });
        },
        title: i18next.t("sendWithEmail"),
      });
    }
    return actions;
  } catch (error) {
    errorReport({
      error,
      errorInFn: "getCompletedDocOnPressActions",
      errorInScreen: "functions",
    });
    return [];
  }
};

export const onItemPress = (props) => {
  try {
    const { ev, item, arrName, setState } = props;
    if (arrName === "employeeDocs" || arrName === "completedDocs") {
      setState({
        modalPicker: {
          mouseX: ev?.clientX - 2,
          mouseY: ev?.clientY - 4,
          title: i18next.t("selectAction"),
          visible: true,
          textProp: "title",
          data: getCompletedDocOnPressActions(props),
        },
      });
    } else if (arrName === "pendingDocs") {
      let actions = [
        {
          action: () => revisionFromDoc(props),
          title: i18next.t("createRevision"),
        },
        {
          action: () => revisionFromDoc(props, true),
          title: i18next.t("createCopy"),
        },
        {
          action: () => previewPdf(props),
          title: i18next.t("inspect"),
        },
      ];
      const modalPickerProps = {
        mouseX: ev?.clientX - 2,
        mouseY: ev?.clientY - 4,
        visible: true,
        title: i18next.t("selectAction"),
        textProp: "title",
      };
      if (item.status === 50) {
        const signerThatRejected = item.signatures.find((x) =>
          x.rejectionComment ? true : false
        );
        modalPickerProps.title = `${i18next.t("signerRejectedDoc")}\n\n${
          signerThatRejected.email
        }: ${signerThatRejected.rejectionComment}`;
      } else if (
        item.status === 20 ||
        item.status === 30 ||
        item.status === 40
      ) {
        let title;
        if (item.status === 20 || item.status === 40) {
          title =
            i18next.t("signatureLinkResendConfirmation") +
            ":\n\n" +
            item.signatures.reduce((acc, sign) => acc + sign.email + "\n", "");
        } else if (item.status === 30) {
          title =
            i18next.t("signatureRecipientsConfirmation") +
            ":\n\n" +
            item.emails.reduce((acc, email) => acc + email + "\n", "");
        }
        actions.push({
          action: () => {
            setState({
              setModal: {
                modal: "alert",
                props: {
                  //...state.alert,
                  visible: true,
                  title: i18next.t("alert"),
                  text: title,
                  middleButtonDisabled: true,
                  leftButtonTitle: i18next.t("close"),
                  rightButtonTitle: i18next.t("send"),
                  cancelButton: true,
                  onMiddleButtonPress: null,
                  onRightButtonPress: () => resendDoc(props),
                },
              },
              modalPicker: { visible: false },
            });
          },
          title: i18next.t("resendEmail"),
        });
        // setState({
        //   alert: {
        //     //...state.alert,
        //     visible: true,
        //     title: i18next.t("alert"),
        //     text: "?",
        //     middleButtonDisabled: true,
        //     leftButtonTitle: i18next.t("close"),
        //     rightButtonTitle: i18next.t("send"),
        //     cancelButton: true,
        //     onMiddleButtonPress: null,
        //     onRightButtonPress: () => resendDoc(props),
        //   },
        // });
      }
      modalPickerProps.data = actions;
      setState({
        modalPicker: modalPickerProps,
      });
    } else if (
      item &&
      (arrName === "unfinishedDocs" ||
        arrName === "orderConfirmations" ||
        arrName === "completedOrders")
    ) {
      if (props.middleClick) {
        window
          .open(
            `${window.location.origin}/create?docId=${encodeURIComponent(
              item.id
            )}`,
            "_blank"
          )
          ?.focus();
      } else {
        sendToModify(props);
      }
    }
  } catch (error) {
    errorReport({
      error,
      errorInFn: "onItemPress",
      errorInScreen: "functions",
    });
  }
};

export const dataURLToBase64 = (dataURL) => {
  let b64;
  const index = dataURL.startsWith("data") ? dataURL.indexOf(",") : -1;
  if (index !== -1) {
    b64 = dataURL.substring(index + 1, dataURL.length);
  } else {
    b64 = dataURL;
  }
  return b64;
};

export const getImageSize = (filePath) => {
  if (Platform.OS === "web") {
    let _filePath = filePath;
    const index = filePath.startsWith("data") ? filePath.indexOf(",") : -1;
    if (index !== -1) {
      _filePath = filePath.substring(index + 1, filePath.length);
    }
    const objectURL = URL.createObjectURL(base64ToBlob(_filePath));
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(objectURL);
        resolve({
          height: img.naturalHeight ?? img.height,
          width: img.naturalWidth ?? img.width,
        });
      };
      img.onerror = (err) => reject(err);
      img.src = objectURL;
    });
  } else {
    return new Promise((resolve, reject) =>
      RNImage.getSize(
        filePath,
        (width, height) => resolve({ width, height }),
        (err) => reject(err)
      )
    );
  }
};

const onlyImagesExts =
  Platform.OS === "web"
    ? ["png", "jpg", "jpeg"]
    : ["png", "jpg", "jpeg", "heic"];
export const filesToAtchObjects = async (files, setUri, onlyImages) => {
  let attachments = [];
  let notAllowedAttachments = [];
  for (let i = 0; i < files.length; i++) {
    const fileObj = files[i];
    let obj = {};
    let splitFileName = fileObj.name.split(".");
    obj.ext = splitFileName.pop().toLowerCase();
    obj.name = splitFileName.join("");
    obj.tmpName = obj.name;
    obj.type = fileObj.type;
    obj.file = fileObj;

    if (onlyImages && !onlyImagesExts.includes(obj.ext)) {
      notAllowedAttachments.push(obj);
    } else {
      if (setUri) {
        //obj.uri = await convertHeic(fileObj);
        obj.uri = await blobToBase64(fileObj);
      }
      attachments.push(obj);
    }

    if (notAllowedAttachments.length > 0) {
      showToast(
        `${i18next.t("only")} ${
          Platform.OS === "web"
            ? "'png', 'jpg' ja 'jpeg'"
            : "'png', 'jpg', 'jpeg', 'heif ja 'heic'"
        } ${i18next.t(
          "imageTypeErrorSentenceEnd"
        )}: ${notAllowedAttachments.reduce(
          (acc, cur) => (acc += `\n${cur.name}.${cur.ext}`),
          ""
        )}`
      );
    }
  }
  return attachments;
};

export const handleAttachmentPick =
  ({ _navigate, navProps }) =>
  async (files) => {
    if (files) {
      const attachments = await filesToAtchObjects(
        files,
        true,
        navProps.onlyImages
      );
      if (Array.isArray(attachments) && attachments.length > 0) {
        _navigate("addAttachments", navProps.navigation, {
          attachments,
          ...navProps,
        });
      }
    }
  };

export const getAttachmentsFromDoc = (doc) => {
  let attachments = [];
  if (doc.values) {
    const valuesEntries = Object.entries(doc.values);
    for (let i = 0; i < valuesEntries.length; i++) {
      const [key, value] = valuesEntries[i];

      if (
        Array.isArray(value) &&
        value.length > 0 &&
        (value[0]?.id?.includes("attachment") || value[0]?.docRef)
      ) {
        attachments.push({ valueKey: key, attachments: value });
      }
    }
  }
  return attachments;
};

export const getPickerObj = (layoutId, options) => {
  return getNewestLayoutVersion(options?.layouts, layoutId);
};

export const idToReadable = (id) => {
  return id
    .split("/")
    .slice(1)
    .join("/")
    .replace(/ss/g, "s")
    .replace(/web/g, "w");
};

export const getPdfLangValueKey = (profile) => {
  let userIdPart;
  if (profile?.id) {
    const split = profile?.id.split("/");
    if (split[1]) userIdPart = split[1];
    else userIdPart = profile?.id;
  } else {
    userIdPart = "0";
  }
  return `u${userIdPart}_pdflang`;
};

export const genNewValKey = (currentValueKey, userId) => {
  let userIdPart;
  if (userId) {
    const split = userId.split("/");
    if (split[1]) userIdPart = split[1];
    else userIdPart = userId;
  } else {
    userIdPart = "0";
  }
  return `${currentValueKey}u${userIdPart}${nanoid()}${
    _platforms[Platform.OS]
  }`;
};

export const getStickyIndices = (_listData, _visibleData) => {
  let index;
  if (_listData && _visibleData) {
    let index = _listData.findIndex((x) => x.valueKey === _visibleData);
    if (index !== -1) {
      return [index];
    }
  }
  return index;
};

export function errorReport({
  error,
  errorInScreen,
  errorInFn,
  errorParams,
  errorMsg,
  msgTimeout,
  msgColor,
  navigateTo,
  navigateFn,
  navigation,
  dontNavigate = false,
  dontShowToast = false,
  docId,
}) {
  if (__DEV__) {
    console.error(
      `errorReport ${errorInScreen} ${errorInFn || ""}`,
      errorParams,
      error
    );
  } else {
    captureSentryError(error);
    apiRequestWithToken(
      {
        dateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
        docId,
        errorParams,
        errorInScreen,
        errorInFunction: errorInFn,
        error: error
          ? "stringified: " +
            JSON.stringify(error) +
            ", parsed: " +
            stringifyError(error) +
            ", pure: " +
            error
          : "error is null",
      },
      "/errors/add"
    );
  }
  if (!dontShowToast) {
    showToast(
      errorMsg || i18next.t("unhandledError"),
      msgTimeout || 5000,
      msgColor
    );
  }
  if (!dontNavigate && navigateFn && navigateTo) {
    navigateFn(navigateTo, navigation);
  }
}

const matchWithPropsArr = (a, b, arr) => arr.every((x) => a[x] === b[x]);

export const setState = (action, state) => {
  return update(state, { [action.prop]: { $set: action.value } });
};
export const replaceArrObjWithIdProp = (action, state) => {
  return update(state, {
    [action.prop]: {
      $apply: (x) =>
        update(x || [], {
          $apply: (arr) => {
            const index = arr.findIndex((x) =>
              valueMatches(action, x, action.oldValue)
            );
            if (index !== -1) {
              return update(arr, { [index]: { $set: action.newValue } });
            } else {
              return update(arr, { $push: [action.newValue] });
            }
          },
        }),
    },
  });
};
export const addMultipleToState = (action, state) => {
  return update(state, {
    [action.prop]: {
      $apply: (x) =>
        update(x || [], {
          $apply: (arr) => {
            let arrToPush = [];
            action.value.forEach((y) => {
              if (!arr.some((x) => valueMatches(action, x, y))) {
                arrToPush.push(y);
              }
            });
            return update(arr, {
              $push: arrToPush,
            });
          },
        }),
    },
  });
};
export const removeMultipleFromArr = (action, state) => {
  try {
    return update(state, {
      [action.prop]: {
        $apply: (x) =>
          update(x || [], {
            $apply: (arr) => {
              return arr.filter(
                (x) => !action.value.some((y) => valueMatches(action, x, y))
              );
            },
          }),
      },
    });
  } catch (error) {
    errorReport({
      error,
      errorInFn: "removeMultipleFromArr",
      errorInScreen: "functions",
    });
    return state;
  }
};

export const valueMatches = (action, a, b) =>
  action.idProp
    ? a[action.idProp] === b[action.idProp]
    : action.idProps
    ? matchWithPropsArr(a, b, action.idProps)
    : a === b;

export const onTextChange = (
  text,
  numeric,
  maxDecimals,
  maxValue
  // minValue
) => {
  let _value = "";
  if (numeric) {
    let decimalIndex = null;
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (/[0-9]/g.test(char)) {
        if (maxDecimals && decimalIndex !== null) {
          if (i - decimalIndex < maxDecimals + 1) {
            _value += char;
          }
        } else {
          _value += char;
        }
      } else if (char === ",") {
        if (decimalIndex === null) _value += ".";
        decimalIndex = i;
      } else if (char === ".") {
        if (decimalIndex === null) _value += char;
        decimalIndex = i;
      } else if (char === "-" && i === 0) {
        _value += char;
      } else if (char === "+" && i === 0) {
        _value += char;
      }
    }

    if (maxValue && Number(_value) > maxValue) {
      _value = maxValue.toString();
    }
    // if (minValue && Number(_value) < minValue) {
    //   _value = minValue.toString();
    // }
  } else {
    _value = text.replace(/\r?\n|\r/g, "");
  }
  return _value;
};

export const isFilled = (val) => {
  if (typeof val === "boolean") {
    return true;
  } else if (Array.isArray(val)) {
    if (val.length > 0) return true;
    else return false;
  } else {
    return val !== "" && val !== undefined && val !== null;
  }
};

/**
 * Validates cell values, params for each fn: val, validValues, lang, role, roles
 */
export const validations = {
  exact: (val, validValues, lang) => {
    return getTranslatedOptions(validValues, lang).includes(val);
  },

  isNot: (val, validValues, lang) => {
    return !getTranslatedOptions(validValues, lang).includes(val);
  },

  greaterThan: (val, validValues, lang) =>
    +val > +getTranslatedOptions(validValues, lang)[0],

  greaterThanRole: (val, validValues, lang, role, roles) =>
    role > _roles[roles[0]],

  lessThan: (val, validValues, lang) =>
    +val < +getTranslatedOptions(validValues, lang)[0],

  lessThanRole: (val, validValues, lang, role, roles) =>
    role < _roles[roles[0]],

  filled: (val) => isFilled(val),
  notFilled: (val) => !isFilled(val),

  isEmpty: (val) => val === undefined || val === null,
  isNotEmpty: (val) => val !== undefined && val !== null,

  isTruthy: (val) => !!val,

  isFalsy: (val) => !val,

  endsWith: (val, validValues, lang) =>
    getTranslatedOptions(validValues, lang).some(
      (x) => typeof val === "string" && val.endsWith(x)
    ),

  docStatus: (val, validValues, lang, role, roles, status) =>
    getTranslatedOptions(validValues, lang).includes(status),

  startsWith: (val, validValues, lang) =>
    getTranslatedOptions(validValues, lang).some(
      (x) => typeof val === "string" && val.startsWith(x)
    ),

  doesNotStartWith: (val, validValues, lang) =>
    getTranslatedOptions(validValues, lang).every(
      (x) => !(typeof val === "string" && val.startsWith(x))
    ),
};

export function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

export function filterBy(
  items,
  _filterBy,
  doc,
  layoutId,
  lang,
  role,
  layout,
  checkIfFilled
) {
  if (isArrayWithItems(items)) {
    try {
      items = items.filter((item) => {
        if (_filterBy.extraRowsValueKey) {
          return doc.values[
            `${layoutId}_${item.valueKey}_${_filterBy.valueKey}`
          ]?.some((input) => {
            const extraDataCell = layout.extraData.find(
              (x) => x.valueKey === _filterBy.valueKey
            );

            let keysToCheck = [];

            if (checkIfFilled && extraDataCell?.inputs) {
              for (let i = 0; i < extraDataCell.inputs.length; i++) {
                const inputRow = extraDataCell.inputs[i];
                for (let j = 0; j < inputRow.length; j++) {
                  const input = inputRow[j];
                  if (
                    input.valueKey &&
                    input.valueKey !== _filterBy.extraRowsValueKey
                  ) {
                    keysToCheck.push(input.valueKey);
                  }
                }
              }
            }

            const valueKeyPrefix = `${layoutId}_${item.valueKey}_${_filterBy.valueKey}_${input.valueKey}`;
            return validations[_filterBy.compareMethod](
              doc.values[`${valueKeyPrefix}_${_filterBy.extraRowsValueKey}`],
              _filterBy.validValues,
              lang,
              role,
              _filterBy.roles
            ) && keysToCheck.length > 0
              ? keysToCheck.some((key) =>
                  isFilled(doc.values[`${valueKeyPrefix}_${key}`])
                )
              : true;
          });
        } else {
          return validations[_filterBy.compareMethod](
            doc.values[`${layoutId}_${item.valueKey}_${_filterBy.valueKey}`],
            _filterBy.validValues,
            lang,
            role,
            _filterBy.roles
          );
        }
      });
    } catch (error) {
      errorReport({
        error,
        errorInFn: "filterBy",
        errorInScreen: "functions",
      });
    }

    return items;
  } else {
    return items;
  }
}

export function convertToValidFilename(string) {
  return string.replace(/[/|\\:*?"<>]/g, " ");
}

export const getIsRestricted = (arr, restriction, role) => {
  if (arr) {
    const found = arr.find((x) => x.restriction === restriction);
    if (found) {
      if (found.roles) {
        return found.roles.includes(role);
      } else {
        return true;
      }
    } else return false;
  } else return false;
};

export const compareRole = (_role, obj) => {
  const { role, compareMethod } = obj;
  const intRole = _roles[_role] || 0;

  const validations = {
    equalOrGreaterThanRole: () => intRole >= _roles[role],
    greaterThanRole: () => intRole > _roles[role],
    lessThanRole: () => intRole < _roles[role],
  };

  if (compareMethod) {
    return validations[compareMethod](role);
  } else {
    throw "No compareMethod";
  }
};

export function sum(a) {
  let s = 0;
  for (let i = 0; i < a.length; i++) {
    s += a[i];
  }
  return s;
}

export function roundToFixed(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export const constParseToFixed = (value) => {
  return roundToTwo(parseFloat(value)).toString();
};

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};

export const parseToFixed = (value) => {
  return roundToTwo(parseFloat(value));
};

export function getPercentuals(number) {
  var percentage = 100 / number;
  var somaPercentual = [];
  for (let i = 0; i < number; i++) {
    if (i < number - 1) somaPercentual.push(roundToFixed(percentage, 4));
    else
      somaPercentual.push(
        roundToFixed(
          100 -
            somaPercentual.reduce((a, b) => {
              return a + b;
            }, 0),
          4
        )
      );
  }
  return somaPercentual;
}

export const mergeLayoutSources = (sources, arr, options) => {
  if (arr && options) {
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];

      const layoutObjSources = getReduxLayout(
        options,
        obj.layoutId,
        obj.layoutVersion
      )?.sources;

      if (layoutObjSources) {
        const keys = Object.keys(layoutObjSources);
        if (obj.valueKey && obj.optionsProp) {
          const innerSourceUpdateObj = {
            type: { $set: options.layouts[obj.layoutId].type },
            source: (tmp) =>
              update(tmp || [], {
                $apply: (x) => {
                  const propIndex = x.findIndex((y) => y === obj.optionsProp);
                  if (propIndex !== -1) {
                    return update(x, {
                      [propIndex]: {
                        $set: `${obj.optionsProp}_${obj.valueKey}`,
                      },
                    });
                  } else {
                    return x;
                  }
                },
              }),
          };
          const updateObj = Object.keys(
            layoutObjSources[obj.optionsProp]
          ).reduce((prev, cur) => {
            prev[cur] = innerSourceUpdateObj;
            return prev;
          }, {});

          const newLayoutObj = update(layoutObjSources, {
            [obj.optionsProp]: updateObj,
          });
          sources = update(sources, {
            [`${obj.optionsProp}/${obj.valueKey}`]: {
              $set: newLayoutObj[obj.optionsProp],
            },
          });
        } else if (obj.type === "measurementObjects") {
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            sources = update(sources, {
              [`${obj.layoutId}/${key}`]: { $set: layoutObjSources[key] },
            });
          }
        } else {
          sources = update(sources, {
            [`${obj.type}`]: { $set: layoutObjSources },
          });
        }
      }
    }
  }
  return sources;
};

export const mergeConstantSources = (specialInputs) => {
  if (Array.isArray(specialInputs)) {
    let updateObj = {};
    Object.entries(constantSources).forEach(
      ([constantSourceKey, constantSource]) => {
        Object.entries(constantSource).forEach(([objKey, obj]) => {
          obj.source.forEach((prop, i) => {
            const specialInput = specialInputs.find(
              (x) => x.optionsProp === prop
            );
            if (specialInput) {
              updateObj[constantSourceKey] = {
                ...updateObj[constantSourceKey],
                [objKey]: {
                  source: {
                    [i]: {
                      $set: `${specialInput.optionsProp}_${specialInput.valueKey}`,
                    },
                  },
                },
              };
            }
          });

          if (Array.isArray(obj.alternateSources)) {
            obj.alternateSources.forEach((alternateSource, firstIndex) => {
              alternateSource.forEach((prop, secondIndex) => {
                const specialInput = specialInputs.find(
                  (x) => x.optionsProp === prop
                );
                if (specialInput) {
                  updateObj[constantSourceKey] = {
                    ...updateObj[constantSourceKey],
                    [objKey]: {
                      ...updateObj[constantSourceKey]?.[objKey],
                      alternateSources: {
                        ...updateObj[constantSourceKey]?.[objKey]
                          ?.alternateSources,
                        [firstIndex]: {
                          [secondIndex]: {
                            $set: `${specialInput.optionsProp}_${specialInput.valueKey}`,
                          },
                        },
                      },
                    },
                  };
                }
              });
            });
          }
        });
      }
    );
    // TODO translate test translation stuff, should add translation to somewhere here

    return update(constantSources, updateObj);
  } else {
    return constantSources;
  }
};

export const mergeSources = (docLayout, options) => {
  let sources = mergeConstantSources(docLayout?.specialInputs);
  sources = mergeLayoutSources(sources, docLayout?.specialInputs, options);
  sources = mergeLayoutSources(sources, docLayout?.sections, options);
  return sources;
};

export const getWorstValues = ({
  arr,
  measurementKey,
  extraData,
  count,
  reverse,
  compare,
  comparableField,
  comparables,
}) => {
  const sortedValues = arr.sort(
    sortWithoutField(reverse, (a) => {
      if (compare) {
        if (comparableField) {
          return a[measurementKey].worstValue / a[comparableField].worstValue;
        } else {
          const objTypesObj = extraData.find((x) => x.key === a.key);
          return (
            a[measurementKey].worstValue /
            getGuideLineValue(objTypesObj, comparables)
          );
        }
      } else {
        return a[measurementKey].worstValue;
      }
    })
  );

  let arrToReturn = [];

  const _count = count || count === 0 ? count : 0;
  for (let i = 0; i < _count; i++) {
    if (sortedValues[i] && sortedValues[i][measurementKey].worstValue) {
      arrToReturn.push({
        key: sortedValues[i].key,
        worstValue: constParseToFixed(
          sortedValues[i][measurementKey].worstValue
        ),
        extraValues: Object.keys(sortedValues[i]).map((x) => {
          return constParseToFixed(sortedValues[i][x].worstValue) || "";
        }),
        extraData: extraData.find((x) => x.key === sortedValues[i].key),
      });
    }
  }

  if (arrToReturn.length !== _count) {
    const arrToReturnLength = arrToReturn.length;
    for (let i = 0; i < _count - arrToReturnLength; i++) {
      arrToReturn.push({
        key: "",
        worstValue: "",
        extraValues: null,
        extraData: null,
      });
    }
  }

  return arrToReturn;
};

const getWorstValueObj = (
  measurementObjectId,
  measurementObject,
  connectedMeasurementObject,
  obj,
  extraDataObj,
  values
) => {
  let connectedObjKey = null;
  let objToReturn = {
    id: obj.key,
  };

  if (measurementObject.connectedLayoutId) {
    const connectedObjects =
      values[`${measurementObject.connectedLayoutId}_connected`] || [];

    const connectedObj = connectedObjects.find(
      (x) =>
        x.connectedToValueKey == obj.valueKey &&
        x.measurementObjectId === measurementObjectId
    );

    if (connectedObj) {
      connectedObjKey = connectedObj.valueKey;
    }
  }

  if (obj) {
    Object.keys(obj).forEach(
      (x) => (objToReturn[x] = constParseToFixed(obj[x].worstValue) ?? "")
    );
  }

  if (connectedObjKey) {
    const valueKeyPrefix = `${measurementObject.connectedLayoutId}_${connectedObjKey}_`;

    const connectedMeasObj = values[measurementObject.connectedLayoutId].find(
      (x) => x.valueKey === connectedObjKey
    );

    if (connectedMeasObj?.id) {
      objToReturn.connectedLayoutId = connectedMeasObj.id;
    }

    if (connectedMeasurementObject) {
      Object.keys(connectedMeasurementObject.titles).forEach((x) => {
        objToReturn["connectedObj_" + x] = values[valueKeyPrefix + x] ?? "";
      });
    }
  }

  if (extraDataObj) {
    Object.keys(extraDataObj).forEach(
      (x) => (objToReturn[x] = extraDataObj[x] ?? "")
    );
  }

  return objToReturn;
};

const getGuideLineValue = (
  objTypes,
  extraDataToCheckValueKeys,
  comparables
) => {
  const type = objTypes.type ?? objTypes[extraDataToCheckValueKeys?.[0]];
  const size = objTypes.size ?? objTypes[extraDataToCheckValueKeys?.[1]];
  if (type === "gG 0,4s" || type === "gL 0,4s")
    return (comparables?.[0]?.[size] || 0) * 1.25;
  else if (type === "gG 5,0s" || type === "gL 5,0s")
    return (comparables?.[1]?.[size] || 0) * 1.25;
  else return (comparables?.[2]?.[type] || 0) * size * 1.25;
};

function getComparedObject(
  worstObj,
  measurementKey,
  arrObj,
  extraDataObj,
  comparables,
  extraDataToCheckValueKeys,
  compare
) {
  if (worstObj) {
    if (
      compare(
        worstObj[measurementKey].worstValue /
          getGuideLineValue(
            extraDataObj,
            extraDataToCheckValueKeys,
            comparables
          ),

        arrObj[measurementKey].worstValue /
          getGuideLineValue(
            extraDataObj,
            extraDataToCheckValueKeys,
            comparables
          )
      )
    ) {
      return { worstObj: arrObj, extraDataObj };
    }
  } else {
    return { worstObj: arrObj, extraDataObj };
  }
}

const getGreaterThan = (a, b) => a > b;
const getLesserThan = (a, b) => a < b;

export const getWorstValueWithComparables = ({
  measurementObjectId,
  measurementObject,
  connectedMeasurementObject,
  values,
  reverse,
  arr,
  measurementKey,
  extraData,
  comparables,
  extraDataToCheckValueKeys,
  extraDataToCheckMethod,
  validExtraData,
}) => {
  let worstObj = null;
  let extraDataObj = null;

  arr.forEach((arrObj) => {
    const tmpExtraDataObj = extraData.find((x) => x.key === arrObj.key);
    if (arrObj[measurementKey].worstValue) {
      if (extraDataToCheckMethod === "endsWith") {
        if (
          tmpExtraDataObj[extraDataToCheckValueKeys?.[0]]?.endsWith(
            validExtraData
          )
        ) {
          const comparedObject = getComparedObject(
            worstObj,
            measurementKey,
            arrObj,
            tmpExtraDataObj,
            comparables,
            extraDataToCheckValueKeys,
            reverse ? getLesserThan : getGreaterThan
          );
          if (comparedObject) {
            worstObj = comparedObject.worstObj;
            extraDataObj = comparedObject.extraDataObj;
          }
        }
      } else {
        const comparedObject = getComparedObject(
          worstObj,
          measurementKey,
          arrObj,
          tmpExtraDataObj,
          comparables,
          extraDataToCheckValueKeys,
          reverse ? getLesserThan : getGreaterThan
        );
        if (comparedObject) {
          worstObj = comparedObject.worstObj;
          extraDataObj = comparedObject.extraDataObj;
        }
      }
    }
  });

  if (worstObj && worstObj.key) {
    return getWorstValueObj(
      measurementObjectId,
      measurementObject,
      connectedMeasurementObject,
      worstObj,
      extraDataObj,
      values
    );
  } else return {};
};

export const getSpecialInputsProps = (values, content, options) => {
  let _worstValues = {};
  let _values = values;

  if (Array.isArray(content?.specialInputs)) {
    content.specialInputs.forEach((specialInput) => {
      if (specialInput.type === "measurementObjects") {
        try {
          _worstValues[specialInput.layoutId] = getWorstValuesWithProps(
            values,
            options?.layouts,
            specialInput.layoutId,
            specialInput.layoutVersion,
            options
          );
        } catch {
          /* empty */
        }
      } else if (specialInput.optionsProp && specialInput.valueKey.toString()) {
        _values = update(_values, {
          [`${specialInput.optionsProp}_${specialInput.valueKey}`]: {
            $apply: (x) => {
              if (x?.id) {
                const option = options[specialInput.optionsProp]?.[x.id];
                if (option) {
                  return {
                    ...x,
                    ...option,
                  };
                } else {
                  return x;
                }
              } else {
                return x;
              }
            },
          },
        });
      }
    });
  }
  return { _worstValues, _values };
};

/**
 * Modify source-property for all template strings within sources object
 * @param {Object} sources sources object
 * @param {Object} props props containing all the values for the sources
 */
export function replaceAlternateSources(sources, props) {
  const fetchDataFromProps = (arr = []) => {
    return arr.reduce((obj, prop) => {
      return obj?.[prop] ? obj[prop] : undefined;
    }, props);
  };

  if (!sources) return null;

  const getValidSource = (obj) => {
    let validSource = null;

    let originalIsValid;
    if (obj?.[0]?.partlyFilledIsValid) {
      originalIsValid = Object.values(obj).some((innerObj) => {
        const valid = fetchDataFromProps(innerObj?.source);
        return valid !== undefined;
      });
    } else {
      originalIsValid = Object.values(obj).every((innerObj) => {
        const valid = fetchDataFromProps(innerObj?.source);
        return valid !== undefined;
      });
    }

    if (originalIsValid) {
      validSource = "original";
    } else {
      const alternateSourcesLength =
        Object.values(obj)[0].alternateSources.length;
      for (let i = 0; i < alternateSourcesLength; i++) {
        const isValid = Object.values(obj)[
          obj?.[0]?.partlyFilledIsValid ? "some" : "every"
        ]((innerObj) => {
          return (
            fetchDataFromProps(innerObj?.alternateSources?.[i]) !== undefined
          );
        });

        if (isValid) {
          validSource = i;
          break;
        }
      }
    }

    return validSource;
  };

  const applyTemplateStringValue = (args) => {
    return args ? fetchDataFromProps(args) ?? "" : "";
  };

  const newSource = update(sources, {
    $apply: function (_oldSources) {
      return Object.keys(_oldSources).reduce((oldSources, source) => {
        return update(oldSources, {
          $apply: function (x) {
            return update(x, {
              [source]: {
                $apply: (obj) => {
                  const useAlternatives = Object.values(obj)[0]
                    ?.alternateSources?.length
                    ? true
                    : false;
                  let validSource = useAlternatives
                    ? getValidSource(obj)
                    : "original";

                  return Object.keys(obj).reduce((_innerObj, key) => {
                    return update(_innerObj, {
                      [key]: {
                        $apply: function (_obj = {}) {
                          return update(_obj, (__obj) => {
                            return {
                              ...__obj,
                              alternateSources: undefined,
                              source: applyTemplateStringValue(
                                validSource == "original"
                                  ? __obj.source
                                  : __obj?.alternateSources?.[validSource]
                              ),
                            };
                          });
                        },
                      },
                    });
                  }, obj);
                },
              },
            });
          },
        });
      }, _oldSources);
    },
  });
  return newSource;
}

export const parseAtchId = (atch) => {
  if (!atch || !atch.id) return "";
  if (atch.offlineAtch || atch.saveFailed) return atch.name;
  return atch.id.replace(/\//g, "-");
};

export const projectIntoValues = (project, users) => {
  let workers = [];
  let delays = [];
  let globals = [];

  let values = {
    projectTitle: project.title,
    projectDesc: project.desc,
    projectStart: project.start,
    projectEnd: project.end,
    customers_2: project.customer,
    sites_1: project.site,
  };

  values["project"] = [];
  [...project.tasks]
    ?.sort((a, b) => moment(a.start).diff(moment(b.start)))
    .forEach((task) => {
      if (task.global) {
        if (task.completion?.completed) {
          globals.push(`${task.title || "-"} - toimitettu`);
        } else {
          globals.push(task.title || "-");
        }
      }
      if (task.workers) {
        task.workers.forEach((x) => {
          if (!workers.includes(x)) {
            workers.push(x);
          }
        });
      }

      const group = project.groups?.find((x) => x.dbId === task.groupId);

      if (group) {
        let index = values["project"].findIndex(
          (x) => x.valueKey === group.dbId
        );
        if (index === -1) {
          index = values["project"].length;
          values["project"].push({
            title: group.title || "-",
            valueKey: group.dbId,
            innerItems: [],
          });
        }
        values["project"][index].innerItems.push({
          title: task.title || "-",
          valueKey: task.id,
        });
        let completedTasks;
        try {
          completedTasks = group.tasks.filter(
            (x) => project.tasks.find((y) => y.id === x).completion?.completed
          );
        } catch (error) {
          errorReport({
            error,
            errorInFn: "projectIntoValues",
            errorInScreen: "functions",
          });
        }
        values[`project_${group.dbId}_1`] = parseInt(
          (completedTasks.length / group.tasks.length) * 100
        );
        const prefix = `project_${group.dbId}_${task.id}_`;
        values[prefix + 1] = task.desc;
        values[prefix + 2] = task.start;
        values[prefix + 3] = task.end;
        values[prefix + 4] = task.global;
        values[prefix + 5] = task.workers.map((id) => {
          const user = users.find((x) => x.id === id);
          if (user) {
            return user.name && user.lName
              ? user.name + " " + user.lName
              : user.email;
          } else {
            return "";
          }
        });

        values[prefix + 6] = task.delays.map((item) => {
          let string = "";
          if (item.prevEnd && item.newEnd) {
            const duration = moment.duration(
              moment(item.newEnd).diff(moment(item.prevEnd))
            );
            const days = Math.floor(duration.asDays());
            string = `${item.reason || "-"}. ${days}pv`;
          }
          delays.push(`${task.title || "-"}: ${string}`);
          return string;
        });
        values[prefix + 7] = task.completed;
      }
    });

  values.projectHasGlobals = globals.length > 0;
  values.projectGlobals = globals;
  values.projectHasDelays = delays.length > 0;
  values.projectDelays = delays;
  values.projectWorkersCount = workers.length;
  values.projectStatus = project.status;

  return values;
};

export function getDocProps(
  viewUnfinishedLayout,
  state,
  returnDocLayout,
  docId,
  layoutId
) {
  let props = {};

  if (viewUnfinishedLayout) {
    const editorLayoutId = layoutId;
    props.docId = `${editorLayoutId}_tmp`;
    props.docToModify = state.docs.unfinishedDocs?.[props.docId];
  } else if (docId) {
    props.docId = docId;
    props.docToModify = state.docs.unfinishedDocs?.[props.docId];
  } else if (docId == "noDoc") {
    return props;
  } else if (typeof state.modifyDoc === "string") {
    props.docId = state.modifyDoc;
    props.docToModify = state.docs.unfinishedDocs?.[props.docId];
  } else {
    props.docId = state.modifyDoc?.id;
    props.docToModify = state.docs.unfinishedDocs?.[props.docId];
  }

  // TODO editor crashes if layouts aren't fetched before opening page
  if (returnDocLayout) {
    if (viewUnfinishedLayout) {
      const layout = state.options.layoutEditor[layoutId];
      props.docLayout = {
        id: layout.id,
        type: layout.type,
        versions: {
          [1]: layout,
        },
      };
    } else {
      props.docLayout = state.options.layouts?.[props.docToModify?.layoutId];
    }
  }
  return props;
}

export const modifyValueItemWithPreviousValue = ({
  currentVal,
  newVal,
  oldVal,
  itemIsArr,
  remove,
  replace,
  replaceArr,
  idProp,
  index = -1,
  preset,
  setProp,
}) => {
  if (index !== -1) {
    if (idProp) {
      return update(currentVal || preset, {
        [index]: { [idProp]: { $set: newVal } },
      });
    } else {
      if (remove) {
        return update(currentVal || preset, { $splice: [[index, 1]] });
      } else if (setProp) {
        return update(currentVal || preset, {
          [index]: { [setProp]: { $set: newVal } },
        });
      } else {
        return update(currentVal || preset, { [index]: { $set: newVal } });
      }
    }
  } else if (itemIsArr) {
    if (!currentVal) {
      if (remove) return [];
      else return [newVal];
    }

    let _index = -1;

    if (idProp) {
      _index = currentVal.findIndex((x) =>
        x[idProp] === newVal?.[idProp] || oldVal
          ? x[idProp] === oldVal[idProp]
          : false
      );
    } else {
      _index = currentVal.indexOf(replace && oldVal ? oldVal : newVal);
    }

    if (remove) {
      if (_index !== -1) return update(currentVal, { $splice: [[_index, 1]] });
    } else {
      if (Array.isArray(currentVal) && !replaceArr) {
        if (_index === -1) {
          return addToArr(newVal, currentVal, idProp);
        } else if (replace) {
          return update(currentVal, { [_index]: { $set: newVal } });
        } else if (setProp) {
          return update(currentVal, {
            [_index]: { [setProp]: { $set: newVal } },
          });
        } else return currentVal;
      } else {
        return [newVal];
      }
    }
  } else {
    if (remove) return null;
    return newVal;
  }
};

export const getColumnValue = (_comparable, item, options) => {
  if (item.docType) {
    if (_comparable[2] === "customers") {
      return item?.customer?.[_comparable[_comparable.length - 1]] || "";
    } else if (_comparable[2] === "sites") {
      const sourceToTarget = {
        address: "address",
        zipcode: "zipcode",
        city: "targetPlace",
        jobNumber: "jobNumber",
        desc: "jobDescription",
        name: "installationTarget",
      };
      return (
        item?.target?.[sourceToTarget[_comparable[_comparable.length - 1]]] ||
        ""
      );
    } else {
      return "";
    }
  } else {
    let _value = "";
    const specialInput = options.layouts?.[item.layoutId]?.versions?.[
      item.layoutVersion
    ]?.specialInputs?.find(
      (_specialInput) => _specialInput?.optionsProp === _comparable[2]
    );
    if (specialInput && item.values) {
      const _id =
        item.values?.[`${specialInput.optionsProp}_${specialInput.valueKey}`]
          ?.id;
      _value =
        options?.[specialInput.optionsProp]?.[_id]?.[
          _comparable[_comparable.length - 1]
        ];
    }

    return _value || "";
  }
};

export function stringifyError(err) {
  if (!err) return "No err";
  if (typeof err === "string") return err;
  var plainObject = {};
  Object.getOwnPropertyNames(err).forEach(function (key) {
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject);
}

export const rgbToHex = (rgb) => {
  if (!rgb) return null;
  const { r, g, b } = rgb;
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const hexToRgb = (hex) => {
  if (!hex) return null;
  hex = hex.replace(/^#/, "");
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return { r, g, b };
};

export function byString(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (o) {
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    } else return;
  }
  return o;
}

export function isNumeric(str) {
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const getDocType = (docType, type, lang) => {
  if (docType) {
    return i18next.t("groupLevelDoc");
  } else {
    return getTranslatedText(type, lang);
  }
};

export const parseToFileName = (string) => {
  // allow space as well
  return string.replace(/[^\p{L}0-9\-_ ]/gu, " "); // Allow all Unicode letters
  // return string.replace(/\./g, "-").replace(/[^\p{L}0-9\-_]/gu, "_"); // Allow all Unicode letters
};

export function getChangedTextWithTemplateSources(text, lang, sources) {
  return text.replace(/{.*}/g, function (match) {
    let _text = match;
    _text = _text.substring(1);
    _text = _text.substring(0, _text.length - 1);

    let source = _text;
    Object.keys(sources).some((sourcesOuterKey) =>
      Object.keys(sources[sourcesOuterKey]).some((sourcesInnerKey) => {
        if (
          getTranslatedText(
            sources[sourcesOuterKey][sourcesInnerKey].text,
            lang
          ) === _text
        ) {
          source = `${sourcesOuterKey}_${sourcesInnerKey}`;
          return true;
        } else {
          return false;
        }
      })
    );
    return `{${source}}`;
  });
}

const blacklistedTranslateKeys = [
  "size",
  "font",
  "underlined",
  "align",
  "width",
];
function getFirstKey(obj) {
  if (Object.prototype.hasOwnProperty.call(obj, "en")) return "en";
  for (let key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      !blacklistedTranslateKeys.includes(key)
    ) {
      return key; // Return the first key
    }
  }
  return undefined; // If the object is empty
}
export function getTranslatedText(title, lang, dontReturnUniversal) {
  if (!title) return "";
  if (typeof title === "string") return title;
  if (title?.text?.[lang]) return title.text[lang];
  if (title?.[lang]) return title[lang];
  if (!dontReturnUniversal && title?.text?.all) return title.text.all;
  if (!dontReturnUniversal && title?.all) return title.all;
  if ((title.text || title).i18n) return i18next.t((title.text || title).i18n);
  return (title.text || title)?.[getFirstKey(title.text || title)] || "";
}

export function getTranslatedTextWithFallback(languages, obj, lang) {
  let title = getTranslatedText(obj, lang);

  if (title) return title;

  if (isArrayWithItems(languages)) {
    for (let i = 0; i < languages.length; i++) {
      title = getTranslatedText(obj, languages[i]);
      if (title) return title;
    }
  }
}

export function getTranslatedOptions(options, lang, dontReturnUniversal) {
  if (!options) return [];
  if (Array.isArray(options)) return options;
  if (options?.[lang]) return options[lang];
  if (!dontReturnUniversal && options?.all) return options.all;
  return options[getFirstKey(options)] || [];
}

export function getTranslatedUnit(unit, lang, dontReturnUniversal) {
  if (!unit) return "";
  else if (typeof unit === "string") return unit;
  else if (unit?.[lang]) return unit[lang];
  else if (unit?.text?.[lang]) return unit.text[lang];
  else if (!dontReturnUniversal && unit?.text?.all) return unit.text.all;
  else if (!dontReturnUniversal && unit?.all) return unit.all;
  if ((unit.text || unit).i18n) return i18next.t((unit.text || unit).i18n);
  return (unit.text || unit)?.[getFirstKey(unit.text || unit)] || "";
}

export function getImageDimensions(dimensions, maxImgSize = {}) {
  let width = 0;
  let height = 0;

  let atchWidth = dimensions?.width || 1;
  let atchHeight = dimensions?.height || 1;

  const maxWidth = maxImgSize.width || wp(90);
  const maxHeight = maxImgSize.height || hp(90);

  height = maxHeight;
  width = (atchWidth / atchHeight) * maxHeight;

  if (width > maxWidth) {
    width = maxWidth;
    height = (atchHeight / atchWidth) * maxWidth;
  }

  return { height: Math.ceil(height), width: Math.ceil(width) };
}

export const getValueKeysToRemove = (valueKeyPrefixes, values) => {
  return Object.keys(values).filter((x) =>
    valueKeyPrefixes.map((y) => x.startsWith(y)).some((z) => z === true)
  );
};

export function searchObj(obj, value, path = "") {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (
      typeof obj[key] === "string" &&
      obj[key].toLowerCase().replace(/[\s]/gi, "").search(value) !== -1
    ) {
      return { path: path, prop: key, match: obj[key], docRef: obj.docRef };
    } else if (
      (obj[key] && typeof obj[key] === "object") ||
      (obj[key] && Array.isArray(obj[key]))
    ) {
      const found = searchObj(obj[key], value, path + "." + key);
      if (found) return { ...found, docRef: obj.docRef };
    }
  }
}

export function isEven(value) {
  if (value % 2 == 0) return true;
  else return false;
}

export function addToArr(el, arr, prop) {
  if (prop)
    return update(arr, {
      $splice: [[findLocWithProp(el, arr, prop) + 1, 0, el]],
    });
  else return update(arr, { $splice: [[findLoc(el, arr) + 1, 0, el]] });
}

function findLocWithProp(el, arr, prop, st, en) {
  st = st || 0;
  en = en || arr.length;
  var pivot = parseInt(st + (en - st) / 2, 10);
  if (en - st <= 1 || arr[pivot][prop] === el[prop]) return pivot;
  if (naturalCompare(arr[pivot][prop], el[prop]) < 0) {
    return findLocWithProp(el, arr, prop, pivot, en);
  } else {
    return findLocWithProp(el, arr, prop, st, pivot);
  }
}

function findLoc(el, arr, st, en) {
  st = st || 0;
  en = en || arr.length;
  var pivot = parseInt(st + (en - st) / 2, 10);
  if (en - st <= 1 || arr[pivot] === el) return pivot;
  if (naturalCompare(arr[pivot], el) < 0) {
    return findLoc(el, arr, pivot, en);
  } else {
    return findLoc(el, arr, st, pivot);
  }
}

/**
 * Dynamically get options for a picker based on values given by user
 * @param {Array} filledBeforeChoices An array of cells containing the checks
 * @param {Object} values Values given by the user, { valueKey: value }
 */
export function getPickerChoices(
  filledBeforeChoices,
  values,
  valueKeyPrefix = "",
  lang = i18next.language
) {
  try {
    // return empty array if cells is not an array, values are empty or no values given at all

    if (!Array.isArray(filledBeforeChoices) || !values) {
      return [];
    }

    const currentChecks =
      (filledBeforeChoices.find &&
        filledBeforeChoices.find((x) => x?.filledBeforeChoices)
          ?.filledBeforeChoices) ||
      filledBeforeChoices;

    for (let i = 0; i < currentChecks?.length || 0; i++) {
      const { valueKey, compareMethod, checks, options } = currentChecks[i];

      const validValues = getTranslatedOptions(
        currentChecks[i].validValues,
        lang
      );

      const _valueKey = (valueKeyPrefix ? valueKeyPrefix + "_" : "") + valueKey;

      if (valueKey && compareMethod) {
        const validations = {
          exact: (val) => validValues.includes(val),
          greaterThan: (val) => +val > +validValues[0],
          lessThan: (val) => +val < +validValues[0],

          isTruthy: (val) => !!val,

          isFalsy: (val) => !val,
        };

        const value = values[_valueKey];

        if (validations[compareMethod](value)) {
          if (options) {
            return getTranslatedOptions(options, lang);
          } else {
            // Get an array of options via recursion
            const options = getPickerChoices(
              checks,
              values,
              valueKeyPrefix,
              lang
            );
            const optionsToUse = getTranslatedOptions(options, lang);
            // If the checks were fulfilled and we get back options quit loop and return them
            if (optionsToUse.length >= 1) return options;
          }
        }
      }
    }

    // Return empty array if no matches
    return [];
  } catch (error) {
    const errorStr = `Error: ${error} in getPickerChoices -function.`;
    if (!__DEV__)
      apiRequestWithoutToken(
        {
          dateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
          error: errorStr,
          errorInScreen: "PickerChoices",
          errorInFunction: "getPickerChoices",
        },
        "/errors/add"
      );
    showToast(i18next.t("unhandledError"));
    return ["Internal error at getPickerChoices"];
  }
}

// TODO use the exported validations object
export const cellValidations = (props) => {
  if (!props) return false;
  const { val, check, doc, lang = i18next.language } = props;
  if (!check) return false;

  const { roles, compareMethod, validValues = [] } = check;

  const validations = {
    exact: (val) => {
      return getTranslatedOptions(validValues, lang).includes(val);
    },

    isNot: (val) => !getTranslatedOptions(validValues, lang).includes(val),

    greaterThan: (val) => +val > +getTranslatedOptions(validValues, lang)[0],

    greaterThanRole: (val, validValues, lang, role) => role > _roles[roles[0]],

    lessThan: (val) => +val < +getTranslatedOptions(validValues, lang)[0],

    lessThanRole: (val, validValues, lang, role) => role < _roles[roles[0]],

    filled: (val) => isFilled(val),
    notFilled: (val) => !isFilled(val),

    isEmpty: (val) => val === undefined || val === null,
    isNotEmpty: (val) => val !== undefined && val !== null,

    isTruthy: (val) => !!val,

    isFalsy: (val) => !val,

    endsWith: () =>
      getTranslatedOptions(validValues, lang).some(
        (x) => typeof val === "string" && val.endsWith(x)
      ),

    docStatus: () =>
      getTranslatedOptions(validValues, lang).includes(doc?.status),

    startsWith: (val) =>
      getTranslatedOptions(validValues, lang).some(
        (x) => typeof val === "string" && val.startsWith(x)
      ),

    doesNotStartWith: (val) =>
      getTranslatedOptions(validValues, lang).every(
        (x) => !(typeof val === "string" && val.startsWith(x))
      ),
  };

  const result = validations[compareMethod](val);

  return result;
};

// TODO use only validations[compareMethod], remove duplicate validations objects
export function validateChecks(props) {
  if (!props) return false;
  const {
    lang = i18next.language,
    checks,
    values,
    valueKeyPrefix = "",
    innerProp,
    role,
  } = props;

  if (!checks || !values) return false;

  if (!Array.isArray(checks)) return true;

  const _role = _roles[role] || 0;

  return checks.length === 0
    ? false
    : checks.every((check) => {
        const { valueKey, compareMethod, validValues = [], roles } = check;
        const _valueKey =
          (valueKeyPrefix ? valueKeyPrefix + "_" : "") + valueKey;

        if ((_valueKey || valueKey === 0) && compareMethod) {
          return validations[compareMethod](
            innerProp ? values[_valueKey]?.[innerProp] : values[_valueKey],
            validValues,
            lang,
            _role,
            roles
          );
        } else {
          return false;
        }
      });
}

const checkFunction = (
  cell,
  lang,
  role,
  values,
  valueKeyPrefix,
  innerProp,
  doc
) => {
  const { valueKey, compareMethod, validValues = [] } = cell;

  const _valueKey = (valueKeyPrefix ? valueKeyPrefix + "_" : "") + valueKey;

  const validations = {
    exact: (val) => {
      return getTranslatedOptions(validValues, lang).includes(val);
    },

    isNot: (val) => !getTranslatedOptions(validValues, lang).includes(val),

    greaterThanRole: () =>
      getTranslatedOptions(validValues, lang)?.some(
        (x) => _roles[x] > _roles[role]
      ),
    lessThanRole: () =>
      getTranslatedOptions(validValues, lang)?.some(
        (x) => _roles[x] < _roles[role]
      ),

    greaterThan: (val) => +val > +getTranslatedOptions(validValues, lang)[0],
    lessThan: (val) => +val < +getTranslatedOptions(validValues, lang)[0],

    filled: (val) => isFilled(val),
    notFilled: (val) => !isFilled(val),

    isTruthy: (val) => !!val,

    isFalsy: (val) => !val,

    isEmpty: (val) => val === undefined || val === null,
    isNotEmpty: (val) => val !== undefined && val !== null,

    endsWith: (val) =>
      getTranslatedOptions(validValues, lang).some(
        (x) => typeof val === "string" && val.endsWith(x)
      ),

    docStatus: () =>
      getTranslatedOptions(validValues, lang).includes(doc?.status),

    startsWith: (val) =>
      getTranslatedOptions(validValues, lang).some(
        (x) => typeof val === "string" && val.startsWith(x)
      ),

    doesNotStartWith: (val) =>
      getTranslatedOptions(validValues, lang).every(
        (x) => !(typeof val === "string" && val.startsWith(x))
      ),

    textIncludes: (val) =>
      getTranslatedOptions(validValues, lang)?.some((x) =>
        getTranslatedText(val, lang).includes(x)
      ),

    includes: (val) => {
      const values = getTranslatedOptions(val, lang);
      return Array.isArray(values)
        ? getTranslatedOptions(validValues, lang)?.some((x) =>
            values.includes(x)
          )
        : false;
    },
  };

  if (!compareMethod) return true;
  if (_valueKey || valueKey === 0) {
    const value = innerProp
      ? values[_valueKey]?.[innerProp]
      : values[_valueKey];

    const check = validations[compareMethod](value);

    return check;
  } else {
    return false;
  }
};

/**
 * Check if an input should be visible
 * @param {Array} Object = lang, input, values, valueKeyPrefix, innerProp
 */
export function checkInputVisibility(props) {
  if (!props) return false;
  const {
    lang = i18next.language,
    input,
    values,
    valueKeyPrefix = "",
    innerProp,
    doc,
    role,
  } = props;

  if (!input || !values) return false;

  if (!Array.isArray(input.checksToBeVisible)) return true;

  if (input.visibleIfSomeChecks) {
    // TODO is this loop needed
    // checksToBeVisible can be an empty object
    // let isVisible = false;
    // for (let i = 0; i < input.checksToBeVisible.length; i++) {
    //   if (input.checksToBeVisible[i].compareMethod)
    //     isVisible = checkFunction(
    //       input,
    //       lang,
    //       role,
    //       values,
    //       valueKeyPrefix,
    //       innerProp,
    //       doc
    //     );
    //   if (isVisible) break;
    // }
    return input.checksToBeVisible.some((check) =>
      checkFunction(check, lang, role, values, valueKeyPrefix, innerProp, doc)
    );
  } else {
    return input.checksToBeVisible.every((check) =>
      checkFunction(check, lang, role, values, valueKeyPrefix, innerProp, doc)
    );
  }
}

// TODO rework
export function checkNeedToBeFilledBefore(
  lang,
  needToBeFilledBefore,
  valueKeyPrefix,
  cells,
  values
) {
  if (!needToBeFilledBefore) return true;

  try {
    if (
      Array.isArray(needToBeFilledBefore) &&
      needToBeFilledBefore.length > 0
    ) {
      let notFilled = false;
      let itemsThatArentFilled = [];

      for (let i = 0; i < needToBeFilledBefore.length; i++) {
        const j = needToBeFilledBefore[i];

        cells.forEach((itemToCheck) => {
          if (
            itemToCheck &&
            itemToCheck.valueKey == j &&
            checkInputVisibility({
              lang,
              input: itemToCheck,
              values,
              valueKeyPrefix,
            }) &&
            !isFilled(values[(valueKeyPrefix ? valueKeyPrefix + "_" : "") + j])
          ) {
            notFilled = true;
            itemsThatArentFilled.push(
              getTranslatedText(itemToCheck.title, lang)
            );
          }
        });
      }

      if (notFilled) {
        const alertStr = itemsThatArentFilled.join("\n");
        showToast(
          i18next.t("fieldsToFillFirst") + ":\n\n" + alertStr,
          3000,
          "accent"
        );
        return false;
      } else {
        return true;
      }
    } else return true;
  } catch (error) {
    errorReport({
      error,
      errorInFn: "checkNeedToBeFilledBefore",
      errorInScreen: "functions",
    });
    return true;
  }
}

export function checkForExistingAttachments(id, fileName, arr, returnFound) {
  let error = false;

  if (Array.isArray(arr)) {
    arr.forEach((x) => {
      if (x.id === id) error = true;
    });
  }

  if (returnFound) {
    if (error) {
      return fileName;
    } else return false;
  } else {
    if (error) {
      showToast(
        `${i18next.t("attachmentAlreadyAttached")}: "${fileName}"`,
        3000,
        "accent"
      );
      return false;
    } else return true;
  }
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export function getWorstValuesWithProps(
  values,
  measurementObjects,
  measurementObjectId,
  measurementObjectVersion,
  options
) {
  const measurementObject = getReduxLayout(
    options,
    measurementObjectId,
    measurementObjectVersion
  );
  let worstValues = {};
  let extraDatasArr = [];
  let measurementsArr = [];
  const getWorstValuesData = measurementObject?.getWorstValues;

  if (getWorstValuesData) {
    const measurementKeys = Object.keys(measurementObject.titles);
    const keys = Object.keys(getWorstValuesData);

    const objects = values?.[measurementObjectId];

    if (Array.isArray(objects) && objects.length > 0) {
      for (let i = 0; i < objects.length; i++) {
        const object = objects[i];

        if (Array.isArray(measurementKeys) && measurementKeys.length > 0) {
          let measurements = {
            key: object.id,
            valueKey: object.valueKey,
          };
          for (let j = 0; j < measurementKeys.length; j++) {
            const measurementKey = measurementKeys[j];
            const valueKey = `${measurementObjectId}_${object.valueKey}_${measurementKey}`;
            const measurement = values[valueKey];

            measurements[measurementKey] = {
              worstValue: measurement ? measurement.worstValue : 0,
              values: measurement ? [...measurement.values] : [],
            };
          }
          measurementsArr.push(measurements);
        }

        if (
          Array.isArray(measurementObject.extraData) &&
          measurementObject.extraData.length > 0
        ) {
          let extraDatas = {
            key: object.id,
            valueKey: object.valueKey,
          };
          for (let j = 0; j < measurementObject.extraData.length; j++) {
            const extraDataCell = measurementObject.extraData[j];

            if (extraDataCell.valueKey) {
              const valueKey = `${measurementObjectId}_${object.valueKey}_${extraDataCell.valueKey}`;
              const extraData = values[valueKey] ?? "";

              extraDatas[extraDataCell.valueKey] = extraData;
            }
          }
          extraDatasArr.push(extraDatas);
        }
      }
    }

    if (Array.isArray(keys) && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        const getWorstValuesArr = getWorstValuesData[key];

        if (Array.isArray(getWorstValuesArr)) {
          for (let j = 0; j < getWorstValuesArr.length; j++) {
            const fn = getWorstValuesArr[j].fn;

            if (fn && getWorstValuesArr[j].fnProps) {
              const fnProps = update(getWorstValuesArr[j].fnProps, {
                measurementObject: { $set: measurementObject },
                connectedMeasurementObject: {
                  $set: measurementObjects?.[
                    measurementObject?.connectedLayoutId
                  ]?.[measurementObject?.connectedLayoutVersion],
                },
                measurementObjectId: { $set: measurementObjectId },
                values: { $set: values },
                measurementKey: { $set: key },
                extraData: { $set: extraDatasArr },
                arr: { $set: measurementsArr },
                comparables: {
                  $set: (getWorstValuesArr[j].fnProps.comparables || []).map(
                    (x) => {
                      if (options[x]) return options[x];
                      else throw "Missing options " + x;
                    }
                  ),
                },
              });
              let _worstValue;
              switch (fn) {
                case "getWorstValues":
                  _worstValue = getWorstValues(fnProps);
                  break;
                case "getWorstValueWithComparables":
                  _worstValue = getWorstValueWithComparables(fnProps);
                  break;
                default:
                  _worstValue = 0;
                  break;
              }
              worstValues = update(worstValues, {
                [key]: (tmpKey) =>
                  update(tmpKey || [], { $push: [_worstValue] }),
              });
            }
          }
        }
      }
    }
  }

  return worstValues;
}

export function setNewGroupsWithLineCount({
  userId,
  measurementObjectId,
  id,
  valueKey,
  lineCount,
  lineCountValueKey,
  fuseSize,
  fuseSizeValueKey,
  fuseType,
  fuseTypeValueKey,
  addRcd,
  docId,
  connectedLayoutId,
  modifyValue,
  modifyObjectArrItem,
  addToObjectArrWithGeneratedId,
}) {
  let connectMeasObject = addRcd === i18next.t("yes");

  // add values from picker sequence
  modifyValue([
    {
      valueKey: `${measurementObjectId}_${valueKey}_${fuseTypeValueKey}`,
      value: fuseType,
      docId,
    },
    {
      valueKey: `${measurementObjectId}_${valueKey}_${fuseSizeValueKey}`,
      value: fuseSize,
      docId,
    },
    {
      valueKey: `${measurementObjectId}_${valueKey}_${lineCountValueKey}`,
      value: lineCount == "3" ? "1" : "2",
      docId,
    },
  ]);

  // ADD_TO_OBJECT_ARR_WITH_GENERATED_ID
  const _lineCount = parseInt(lineCount);
  let modifyObjectArrItemPayload =
    _lineCount > 1
      ? {
          userId,
          docId,
          valueKey: measurementObjectId,
          oldVal: { id, valueKey },
          value: id.endsWith(" 1") ? id : id + " 1",
          idProp: "valueKey",
          propToSet: "id",
          sortProp: "id",
          duplicateItemFor: _lineCount - 1,
          type: "MeasObj",
        }
      : null;
  if (connectMeasObject) {
    // add connected meas obj and connection to it
    addToObjectArrWithGeneratedId({
      userId,
      docId,
      valueKey: connectedLayoutId,
      value: { id: id },
      idProp: "valueKey",
      sortProp: "id",
      type: "MeasObj",
      addMeasurementObjConnection: {
        docId: docId,
        measurementObjectId: connectedLayoutId,
        measurementObjectToConnectId: measurementObjectId,
        measurementObjectToConnectValueKey: valueKey,
      },
      modifyObjectArrItem: modifyObjectArrItemPayload,
    });
  } else if (modifyObjectArrItemPayload) {
    modifyObjectArrItem(modifyObjectArrItemPayload);
  }
}

function getGroupGuideLineValue(fnProps, valueKeyPrefix, values, options) {
  const type = values[valueKeyPrefix + "_" + fnProps[0]];
  const size = values[valueKeyPrefix + "_" + fnProps[1]];

  if (type === "gG 0,4s" || type === "gL 0,4s") {
    return (
      options?.gGgLComparablesFast?.[size] ??
      "0" + " / " + (options.gGgLComparablesFast?.[size] ?? 0) * 1.25
    );
  } else if (type === "gG 5,0s" || type === "gL 5,0s") {
    return (
      options?.gGgLComparablesSlow?.[size] ??
      "0" + " / " + (options?.gGgLComparablesSlow?.[size] ?? 0) * 1.25
    );
  } else {
    const calculated = (options?.shortCircuitFormulas?.[type] ?? 0) * size;
    return (
      (isNaN(calculated) ? "0" : calculated) +
      " / " +
      (isNaN(calculated) ? "0" : calculated * 1.25)
    );
  }
}

export const roundToVariable = (val, variable) =>
  Math.ceil(parseToInt(val) / variable) * variable;

// "extraRows_13_19"
// "extraRows string + extraRows cell valueKey + cells valueKey"
const getDynamicCellValues = (
  values,
  valuesKeys,
  valueKeyPrefix,
  splitValKey
) => {
  let extraRowValues = [];
  valuesKeys.forEach((valueKey) => {
    if (
      valueKey.startsWith(
        valueKeyPrefix ? valueKeyPrefix + "_" + splitValKey[1] : splitValKey[1]
      ) &&
      valueKey.endsWith(splitValKey[2])
    ) {
      extraRowValues.push(values[valueKey] || 0);
    }
  });
  return extraRowValues;
};
export function getCalculatedTextValues(
  fnProps,
  valueKeyPrefix,
  values,
  defaultNum
) {
  let _valueKeyPrefix = valueKeyPrefix;
  const valuesKeys = Object.keys(values);
  let _values = [];
  if (Array.isArray(fnProps)) {
    fnProps.forEach((x) => {
      let splitValKey = x.toString().split("_");
      if (splitValKey[0] === "main") {
        _valueKeyPrefix = "";
        splitValKey = splitValKey.slice(1);
      }
      if (splitValKey[0] === "pickerObjects") {
        let pickerObjValues = [];
        valuesKeys.forEach((valueKey) => {
          if (
            valueKey.startsWith(
              _valueKeyPrefix
                ? _valueKeyPrefix + "_" + splitValKey[1]
                : splitValKey[1]
            ) &&
            valueKey.endsWith(splitValKey[2])
          ) {
            pickerObjValues.push(values[valueKey] || 0);
          }
        });

        for (let i = 0; i < pickerObjValues.length; i++) {
          const pickerObjValueArr = pickerObjValues[i];

          for (let j = 0; j < pickerObjValueArr.length; j++) {
            const pickerObject = pickerObjValueArr[j];
            const pickerObjectValKeys = splitValKey.slice(3);
            for (let k = 0; k < pickerObjectValKeys.length; k++) {
              _values.push(pickerObject[pickerObjectValKeys[k]]);
            }
          }
        }
      } else if (splitValKey[0] === "extraRows") {
        _values = _values.concat(
          getDynamicCellValues(values, valuesKeys, _valueKeyPrefix, splitValKey)
        );
      } else if (splitValKey[0] === "layout") {
        let tmpValues = [];
        const prefix = splitValKey[1];
        valuesKeys.forEach((valueKey) => {
          if (
            valueKey.startsWith(prefix + "_") &&
            valueKey.endsWith(splitValKey[2])
          ) {
            tmpValues.push(values[valueKey] || 0);
          }
        });
        _values.push(
          tmpValues.reduce((a, b) => parseToFixed(a) + parseToFixed(b), 0)
        );
      } else if (splitValKey.length === 2) {
        valuesKeys.forEach((valueKey) => {
          if (
            valueKey.startsWith(
              _valueKeyPrefix
                ? _valueKeyPrefix + "_" + splitValKey[0]
                : splitValKey[0]
            ) &&
            valueKey.endsWith(splitValKey[1])
          ) {
            const _value = values[valueKey];
            _values.push(_value?.worstValue || _value || 0);
          }
        });
      } else {
        const _value = values[_valueKeyPrefix ? _valueKeyPrefix + "_" + x : x];
        _values.push(_value?.worstValue || _value || (defaultNum ?? 0));
      }
    });
  }
  return _values;
}

function parseToInt(val) {
  const parsed = parseInt(val);
  if (isNaN(parsed)) {
    return 0;
  } else {
    return parsed;
  }
}

const sumEquation = (fnProps, valueKeyPrefix, values) => {
  const foundValues = getCalculatedTextValues(fnProps, valueKeyPrefix, values);

  const result = foundValues.reduce(
    (a, b) => parseToFixed(a) + parseToFixed(b),
    0
  );
  return isNaN(result) ? "-" : parseToFixed(result);
};
const getMeasObjWorstValue = (fnProps, valueKeyPrefix, values) => {
  const measObjects = values[fnProps[1]];
  let worstValue;
  let worstObj;
  if (measObjects) {
    const isMax = fnProps[0] === "max";
    const compareFn = isMax ? Math.max : Math.min;
    for (let i = 0; i < measObjects.length; i++) {
      const x = measObjects[i];
      const value = values[`${fnProps[1]}_${x.valueKey}_${fnProps[2]}`];
      if (value !== undefined && Number(value) > 0) {
        worstValue = compareFn(
          worstValue ?? (isMax ? -Infinity : Infinity),
          value
        );
        if (worstValue === Number(value)) {
          worstObj = x;
        }
      }
    }
  }
  return { worstValue, worstObj };
};

export const evaluateCellEquation = (
  fn,
  fnProps,
  maxDecimals,
  valueKeyPrefix,
  values,
  options
) => {
  try {
    switch (fn) {
      case "x * sqrt(y)": {
        const foundValues = getCalculatedTextValues(
          fnProps,
          valueKeyPrefix,
          values
        );
        const result = foundValues[0] * Math.sqrt(foundValues[1]);
        return isNaN(result) ? "-" : parseToFixed(result);
      }
      case "sum": {
        return sumEquation(fnProps, valueKeyPrefix, values, options);
      }
      case "sum*x*y": {
        const foundValues = getCalculatedTextValues(
          fnProps,
          valueKeyPrefix,
          values
        );
        let result = foundValues.reduce((a, b, i) => {
          if (i < foundValues.length - 2)
            return parseToFixed(a) + parseToFixed(b);
          else return a;
        }, 0);
        result =
          parseToFixed(result) *
          parseToFixed(foundValues[foundValues.length - 1]) *
          parseToFixed(foundValues[foundValues.length - 2]);
        return isNaN(result) ? "-" : parseToFixed(result);
      }
      case "sumWithTax": {
        const sum = sumEquation(fnProps, valueKeyPrefix, values, options);
        return parseToFixed(sum * 1.24);
      }
      case "x*y": {
        const foundValues = getCalculatedTextValues(
          fnProps,
          valueKeyPrefix,
          values,
          1
        );
        const result = foundValues[0] * foundValues[1];
        return isNaN(result) ? "-" : parseToFixed(result);
      }
      case "*everyOther": {
        const foundValues = getCalculatedTextValues(
          fnProps,
          valueKeyPrefix,
          values
        );
        let result = 0;
        for (let index = 0; index < foundValues.length / 2; index++) {
          const a = foundValues[index + index];
          const b = foundValues[index + index + 1];
          const fixedA = parseToFixed(a);
          const fixedB = parseToFixed(b);
          result += (isNaN(fixedA) ? 0 : fixedA) * (isNaN(fixedB) ? 0 : fixedB);
        }
        return isNaN(result) ? "-" : parseToFixed(result);
      }
      case "x*1.43": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values);
        const value = parseToInt(v[0]);
        const result = value * 1.43;
        return isNaN(value) ? "-" : parseToFixed(result);
      }
      case "x*0.01-1.01": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values);
        const value = parseToInt(v[0]);
        const result = value * 0.01 - 1.01;
        return isNaN(value) ? "-" : parseToFixed(result);
      }
      case "roundTo5": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values);
        const result = Math.ceil(parseToInt(v[0]) / 5) * 5;
        return isNaN(result) ? "-" : result;
      }
      case "getGroupGuideLineValue": {
        return getGroupGuideLineValue(fnProps, valueKeyPrefix, values, options);
      }
      case "measObjSum": {
        // fnProps = ["measurementObjects/1", "39"]
        const measObjects = values[fnProps[0]];
        if (measObjects) {
          const v = getCalculatedTextValues(
            measObjects.map((x) => `${fnProps[0]}_${x.valueKey}_${fnProps[1]}`),
            valueKeyPrefix,
            values
          );
          return v.reduce((sum, cur) => {
            sum += parseToInt(cur);
            return sum;
          }, 0);
        } else {
          return 0;
        }
      }
      case "measObjCount": {
        // fnProps = ["measurementObjects/1", "39"]
        const measObjects = values[fnProps[0]];
        if (measObjects) {
          return measObjects.length;
        } else {
          return 0;
        }
      }
      case "measObjFilterIds": {
        // fnProps = ["measurementObjects/1", "39"]
        const measObjects = values[fnProps[0]];
        if (measObjects) {
          let result = [];
          for (let i = 0; i < measObjects.length; i++) {
            const x = measObjects[i];
            if (values[`${fnProps[0]}_${x.valueKey}_${fnProps[1]}`] === true) {
              result.push(x.id);
            }
          }
          if (result.length > 0) {
            return result.join(", ");
          } else return "-";
        } else {
          return "-";
        }
      }
      case "measObjCountBools": {
        // fnProps = ["measurementObjects/1", "39"]
        const measObjects = values[fnProps[0]];
        if (measObjects) {
          const v = getCalculatedTextValues(
            measObjects.map((x) => `${fnProps[0]}_${x.valueKey}_${fnProps[1]}`),
            valueKeyPrefix,
            values
          );
          let trues;
          try {
            trues = v.filter((x) => x === true);
          } catch (error) {
            errorReport({
              error,
              errorInFn: "measObjCountBools",
              errorInScreen: "functions",
            });
          }
          return trues.length;
        } else {
          return 0;
        }
      }
      case "measObjWorstValue": {
        // fnProps = ["max","measurementObjectlayoutId", "valueKey for worst value", "valueKey for extraData"]
        const { worstValue, worstObj } = getMeasObjWorstValue(
          fnProps,
          valueKeyPrefix,
          values,
          options
        );
        if (worstObj) {
          return worstObj.id + " " + worstValue;
        }
        return "0";
      }
      case "measObjWorstValueExtraData": {
        // fnProps = ["max","measurementObjectlayoutId", "valueKey for worst value", "valueKey for extraData"]
        // first find worst value then return that measObjects extraData with fnProps[3]
        const { worstObj } = getMeasObjWorstValue(
          fnProps,
          valueKeyPrefix,
          values,
          options
        );
        if (worstObj) {
          return values[`${fnProps[1]}_${worstObj.valueKey}_${fnProps[3]}`];
        }
        return "";
      }
      case "addDaysToCurDate": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values);
        const value = parseToInt(v[0]);
        return isNaN(value)
          ? "-"
          : moment().add(value, "days").format("DD.MM.YYYY");
      }
      case "voltageDrop": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values, 0);
        // index  valueKey  excelCell
        // 0      7         B5 = vaihemäärä
        // 1      8         C5 = resistiivisyys (oletus 25)
        // 2      9         D5 = pituus / m
        // 3      10        E5 = Poikkipinta mm2
        // 4      11        F5 = Cos fii (oletus 0,8)
        // 5      12        G5 = sinfii
        // 6      6         H5 = sulakekoko
        // 7      13        I5 = reaktanssi
        // B5*(C5*D5/E5*F5+I5*D5*G5)/1000*H5
        //= vaihemäärä * (resistiivisyys * pituus / poikkipinta * cosfii + reaktanssi * pituus * sinfii) / 1000 * sulakekoko
        if (v.every((x) => x)) {
          return parseToFixed(
            ((v[0] * (((v[1] * v[2]) / v[3]) * v[4] + v[7] * v[2] * v[5])) /
              1000) *
              v[6]
          );
        } else {
          return 0;
        }
      }
      case "voltageDropPercent": {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values, 0);
        //=voltageDrop / voltagetInput
        if (v[0] && typeof v[1] === "object") {
          return parseToFixed((v[0] / v[1].worstValue) * 100);
        } else {
          return 0;
        }
      }
      default: {
        const v = getCalculatedTextValues(fnProps, valueKeyPrefix, values, 0);

        // replace the values in the equation with values from v
        const equation = fn.replace(/{(.*?)}/g, function (match) {
          const fnPropsIndex = match.slice(1, -1) - 1;
          return v[fnPropsIndex] ?? 0;
        });

        let res;

        try {
          res = evaluate(equation);
        } catch (error) {
          console.error(error);
          res = 0;
        }
        if (isNaN(res)) res = 0;
        if (maxDecimals) {
          return roundToFixed(res, maxDecimals);
        }
        return res;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export function getDocId(doc, emptyValueAsEmpty) {
  if (doc?.id) {
    return idToReadable(doc.id);
  } else return emptyValueAsEmpty ? "" : "-";
}

export function getEmptyCellChar(cell) {
  if (
    cell?.emptyValueAsEmpty ||
    cell?.type === "checkBox" ||
    cell?.type === "dualCheckBox"
  ) {
    return "";
  } else {
    return "-";
  }
}

function getValueFromValues(valueKey, cell, doc) {
  if (cell?.addableRowsProps) {
    return doc?.values?.[cell.addableRowsProps.valueKey]?.[
      cell.addableRowsProps.rowIndex
    ]?.[valueKey];
  } else if (valueKey) {
    if (cell?.valueKeyPrefix) {
      return doc?.values?.[`${cell.valueKeyPrefix}_${valueKey}`];
    } else {
      return doc?.values?.[valueKey];
    }
  }
}
export const cellTypesWithoutText = ["checkBox", "pdfCheckBox"];
export function replaceTemplateString(
  textToUse,
  _value,
  _textProp,
  cell = {},
  sources,
  doc,
  docLayout,
  profile
) {
  // TODO measObj e.g. values don't work
  let textReplacedWithValue = false;
  let addValueToText = false;
  // let _valueKey = null;
  let setBoolVal = false;

  if (_textProp !== "text") {
    addValueToText = true;
  }

  // TODO a random case where the text uses a value from somewhere else but there is still a valueKey needed doesn't work
  const newValue = textToUse.replace(/{(.*?)}/g, function (match) {
    if (match === "{docType}") {
      textReplacedWithValue = true;

      // layout doesn't have type inside versions so we can't use the layout here
      // docLayout && profile
      //     ? getDocType(
      //         docLayout.docType,
      //         docLayout.type,
      //         getDocLanguage(doc, docLayout, profile)
      //       )
      //     :
      return (
        getTranslatedText(doc?.type, getDocLanguage(doc, docLayout, profile)) ||
        (cell.emptyValueAsEmpty ? "" : "-")
      );
    } else if (match === "{docId}") {
      textReplacedWithValue = true;
      return (
        getDocId(doc, cell.emptyValueAsEmpty) ||
        (cell.emptyValueAsEmpty ? "" : "-")
      );
    } else if (match === "{date}") {
      textReplacedWithValue = true;
      return moment().format(cell.dateFormat || "DD.MM.YYYY");
    } else if (match === "{creationDate}") {
      textReplacedWithValue = true;
      // TODO use dateformat for defaultValue too
      return moment(doc?.createdAt).format(cell.dateFormat || "DD.MM.YYYY");
    } else if (match === "{value}") {
      addValueToText = true;
      let __value;
      if (Array.isArray(_value)) {
        if (_value.length > 0) {
          const valuesSep = cell.valuesSep || ",";
          __value = _value.join(`${valuesSep} `);
        }
      } else {
        __value = _value;
      }
      return __value ?? getEmptyCellChar(cell);
    } else if (match.startsWith("{valueKey")) {
      const sliced = match.slice(1, -1).split("_");

      if (sliced[1] === cell.valueKey) textReplacedWithValue = true;

      // if (cellTypesWithoutText.includes(cell.type)) {
      //   _valueKey = sliced[1];
      //   return null;
      // } else {
      const __value =
        getValueFromValues(sliced[1], cell, doc) ?? getEmptyCellChar(cell);

      if (Array.isArray(__value)) {
        return __value.join(cell.valuesSep ?? ", ");
      } else return __value;
      // }
    } else if (match === "{userName}") {
      return profile.name + " " + profile.lName;
    } else {
      const [template, key] = match.slice(1, -1).split("_");

      if (sources?.[template]) {
        textReplacedWithValue = true;
        const templateObject = sources[template]?.[key];

        if (templateObject) {
          const result = templateObject.source;

          if (Array.isArray(result)) {
            return result.join(cell.valuesSep ?? ", ");
          } else {
            if (cellTypesWithoutText.includes(cell.type)) {
              setBoolVal = true;
              return result;
            } else {
              return (
                result.toString() ||
                (templateObject.hideIfEmpty ? "" : getEmptyCellChar(cell))
              );
            }
          }
        } else {
          errorReport({
            error: "Wrong templateString in cell",
            errorInScreen: "pdfCreator",
            errorInFn: "functions",
            errorParams: {
              cell,
            },
            dontShowToast: true,
          });
        }
      } else {
        return getEmptyCellChar(cell);
      }
    }
  });

  // if (newValue === "true" || newValue === "false") {
  //   setBoolVal = true;
  // }

  return {
    addValueToText,
    // _valueKey,
    textReplacedWithValue,
    setBoolVal,
    newValue,
  };
}

// export function replaceTemplateString(
//   textToUse,
//   _value,
//   _textProp,
//   cell = {},
//   sources,
//   doc
// ) {
//   let addValueToText = false;
//   let _valueKey = null;
//   let setBoolVal = false;

//   if (_textProp !== "text") {
//     addValueToText = true;
//   }

//   const newValue = textToUse.replace(/{(.*?)}/g, function (match) {
//     if (match === "{docId}") {
//       return (
//         getDocId(doc, cell.emptyValueAsEmpty) ||
//         (cell.emptyValueAsEmpty ? "" : "-")
//       );
//     } else if (match === "{date}") {
//       return moment().format(cell.dateFormat || "DD.MM.YYYY");
//     } else if (match === "{value}") {
//       addValueToText = true;
//       let __value;
//       if (Array.isArray(_value)) {
//         if (_value.length > 0) {
//           const valuesSep = cell.valuesSep || ",";
//           __value = _value.join(`${valuesSep} `);
//         }
//       } else {
//         __value = _value;
//       }
//       return __value ?? getEmptyCellChar(cell);
//     } else if (match.startsWith("{valueKey")) {
//       const sliced = match.slice(1, -1).split("_");

//       if (cellTypesWithoutText.includes(cell.type)) {
//         _valueKey = sliced[1];
//         return null;
//       } else {
//         const __value =
//           getValueFromValues(sliced[1], cell) ?? getEmptyCellChar(cell);

//         if (Array.isArray(__value)) {
//           return __value.join(cell.valuesSep ?? ", ");
//         } else return __value;
//       }
//     } else {
//       const [template, key] = match.slice(1, -1).split("_");

//       if (sources[template]) {
//         const templateObject = sources[template]?.[key];

//         if (templateObject) {
//           const result = templateObject.source;

//           if (Array.isArray(result)) {
//             return result.join(cell.valuesSep ?? ", ");
//           } else {
//             if (cellTypesWithoutText.includes(cell.type)) {
//               setBoolVal = true;
//               return result;
//             } else {
//               return (
//                 result.toString() ||
//                 (templateObject.hideIfEmpty ? "" : getEmptyCellChar(cell))
//               );
//             }
//           }
//         } else {
//           throw "Wrong templateString in cell: " + JSON.stringify(cell);
//         }
//       } else {
//         return getEmptyCellChar(cell);
//       }
//     }
//   });

//   return { addValueToText, _valueKey, setBoolVal, newValue };
// }

//#region layout testing
// function iterate(layoutId, obj, path = "", keys) {
//   if (!obj) return;
//   Object.keys(obj).forEach((key) => {
//     const val = obj[key];
//     let type = typeof val;
//     if (type === "number") {
//       if (Number.isSafeInteger(val)) {
//         type = "integer";
//       } else {
//         type = "float";
//       }
//     }
//     let keyString = "";

//     if (Array.isArray(val)) {
//       val.forEach((x, i) => {
//         if (typeof x === "object") {
//           iterate(layoutId, x, `${path ? path + "." : ""}${key}[0]`, keys);
//         } else {
//           keys[`${path ? path + "." : ""}${key}[${i}]`] = typeof x;
//         }
//       });
//     } else if (type === "object") {
//       if (keys.hasOwnProperty(`${path ? path + "." : ""}${key}`)) {
//         keys[`${path ? path + "." : ""}${key}` + "_" + type] = type;
//       }
//       iterate(layoutId, val, `${path ? path + "." : ""}${key}`, keys);
//     } else {
//       keyString = (path ? path + "." : "") + key;

//       if (keys.hasOwnProperty(keyString)) {
//         if (!keys[keyString].split(",").includes(type)) {
//           keys[keyString] = keys[keyString] + "," + type;
//         }
//       } else {
//         keys[keyString] = type;
//       }
//     }
//   });
// }

// function getUniqueKeys(layouts, keys = {}) {
//   Object.values(layouts).forEach((x) => {
//     const versions = Object.values(x.versions);
//     versions.forEach((version, i) => {
//       iterate(getTranslatedText(x.type, i18next.language) + i, version, "", keys);
//     });
//   });
//   return Object.keys(keys).map((x) => {
//     return [x, keys[x]];
//   });
// }

// const getFullValueKey = (valueKey, valueKeyPrefix) =>
//   valueKeyPrefix ? `${valueKeyPrefix}_${valueKey}` : valueKey;

// const tempSeedValue = { oldValue: 1, newValue: 1 };
// const seedCellData = (doc, cell, valueKeyPrefix, options, testing) => {
//   if (cell.type === "text") {
//     // constant text // no valueKey
//   } else if (cell.type === "textField") {
//     // single row text input
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//       cell.isNumeric || cell.numeric
//         ? testing
//           ? tempSeedValue.newValue.toString()
//           : tempSeedValue.oldValue.toString()
//         : "Lorem ipsum " + cell.valueKey.split("_").pop();
//     if (testing) tempSeedValue.newValue++;
//     else tempSeedValue.oldValue++;
//   } else if (cell.type === "multilineField") {
//     // multi row text input
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//       cell.isNumeric || cell.numeric ? "1" : "Lorem ipsum multiline";
//   } else if (cell.type === "checkBox") {
//     // checkBox in form and pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = true;
//   } else if (cell.type === "filler") {
//     // empty cell // no valueKey
//   } else if (cell.type === "dualCheckBox") {
//     // dualCheckBox in form and pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = true;
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix) + ".5"] = true;
//   } else if (cell.type === "dualCheckBoxText") {
//     // dualCheckBox in form, text in pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = true;
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix) + ".5"] = true;
//   } else if (cell.type === "pdfCheckBox") {
//   } else if (cell.type === "pdfAttachment") {
//     // same as attachment but displays atch in pdf
//     // change attachment id to a downloaded one
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//       {
//         id: "attachment_user/1/ss/609",
//         name: "1f877fad-e0d5-491f-9471-86ab1f0147b9",
//         hidePreview: false,
//       },
//     ];
//   } else if (cell.type === "attachment") {
//     // attachment picker in form, attachment name in pdf
//     // change attachment id to a downloaded one
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//       {
//         id: "attachment_user/1/ss/609",
//         name: "1f877fad-e0d5-491f-9471-86ab1f0147b9",
//         hidePreview: false,
//       },
//     ];
//   } else if (cell.type === "multiAttachment") {
//     // multi attachment picker in form, attachment name in pdf
//     // change attachment id to a downloaded one
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//       {
//         id: "attachment_user/1/ss/609",
//         name: "1f877fad-e0d5-491f-9471-86ab1f0147b9",
//         hidePreview: false,
//       },
//     ];
//   } else if (cell.type === "datePicker") {
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = moment(
//       "1992-10-10",
//       "YYYY-MM-DD"
//     ).format("YYYY-MM-DDTHH:mm:ssZ");
//   } else if (cell.type === "datePickerCheckBox") {
//     // datePicker with checkBox that needs to be filled before date can be filled
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix) + ".5"] = true;
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = moment(
//       "1992-10-10",
//       "YYYY-MM-DD"
//     ).format("YYYY-MM-DDTHH:mm:ssZ");
//   } else if (cell.type === "picker") {
//     // picker in form, text in pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//       "Lorem ipsum picker " +
//       (cell.valueKey ? cell.valueKey.split("_").pop() : "");
//   } else if (
//     cell.type === "pickerTextarea" ||
//     cell.type === "pickerTextField"
//   ) {
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//       "Lorem ipsum picker " + cell.valueKey.split("_").pop();
//   } else if (cell.type === "multiPicker") {
//     // multiPicker in form, text in pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//       "Lorem ipsum mpicker1 " + cell.valueKey.split("_").pop(),
//       "Lorem ipsum mpicker2 " + cell.valueKey.split("_").pop(),
//     ];
//   } else if (cell.type === "companyLogo") {
//   } else if (cell.type === "formTitle") {
//     // constant text thats visible in pdf and form
//   } else if (cell.type === "checkBoxText") {
//     // checkBox in form, text in pdf
//     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = true;
//   } else if (cell.type === "extraRows") {
//     // dynamically addable rows
//     for (let i = 0; i < 3; i++) {
//       const newValueKey = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//         { valueKey: newValueKey },
//       ];
//       doc.currentValueKey =
//         typeof newValueKey === "string"
//           ? parseInt(newValueKey.split("u")[0])
//           : newValueKey;
//       cell.inputs?.forEach((x) => {
//         seedCellData(
//           doc,
//           x,
//           getFullValueKey(`${cell.valueKey}_${newValueKey}`, valueKeyPrefix),
//           options,
//           testing
//         );
//       });
//     }
//   } else if (cell.type === "dividedInputs") {
//     // cells on the same row
//     cell.inputs?.forEach((x) => {
//       seedCellData(doc, x, valueKeyPrefix, options, testing);
//     });
//   }
//   // if (cell.type === "calculatedText") {
//   //   // text calculated with fn & fnProps
//   //   if (cell.valueKey) {
//   //     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//   //       cell.isNumeric || cell.numeric
//   //         ? testing
//   //           ? tempSeedValue.newValue.toString()
//   //           : tempSeedValue.oldValue.toString()
//   //         : "Lorem ipsum " + cell.valueKey.split("_").pop();
//   //     if (testing) tempSeedValue.newValue++;
//   //     else tempSeedValue.oldValue++;
//   //   }
//   // }
//   else if (cell.type === "radio") {
//     // inputs with only one value, all inputs rendered in pdf
//     if (cell.inputs)
//       seedCellData(
//         doc,
//         cell.inputs[0],
//         valueKeyPrefix + "_" + cell.valueKey,
//         options,
//         testing
//       );
//   } else if (cell.type === "radioSelect") {
//     // inputs with only one value, only input with filled value rendered in pdf
//     if (cell.inputs)
//       seedCellData(
//         doc,
//         cell.inputs[0],
//         valueKeyPrefix + "_" + cell.valueKey,
//         options,
//         testing
//       );
//   } else if (cell.type === "pickerObjects") {
//     // pickerObjects cell e.g. customers or sites
//     const pickerObject = getReduxLayout(
//       options,
//       cell.layoutId,
//       cell.layoutVersion
//     );

//     const _values =
//       options[cell.optionsProp] && Object.values(options[cell.optionsProp]);
//     if (_values) {
//       const pickerObjects = Object.values(options[cell.optionsProp]);
//       doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//         pickerObjects[0],
//         pickerObjects[1],
//       ];
//     } else {
//     }
//   } else if (cell.type === "chart") {
//     // ! Deprecated - use graph
//     // TODO add some chart data
//   } else if (cell.type === "graph") {
//     // TODO add some chart data
//   } else if (cell.type === "creatorSignatureDrawing") {
//     // placement for creators signature if the signature is a drawing
//   }
//   // "extraMeasurementRow", // only in use in measurementObjects - added manually if layout is type measurementObjects
//   // "connectedCheckBoxPicker", // only in use in measurementObjects - added manually if layout is type measurementObjects
//   // "togglableCells", // ! only in use in deprecated togglableCellsSections

//   // if (cell.type === "pdfAttachment") {
//   //   // change attachment id to a downloaded one
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//   //     {
//   //       id: "attachment_user/1/ss/609",
//   //       name: "1f877fad-e0d5-491f-9471-86ab1f0147b9",
//   //       hidePreview: false,
//   //     },
//   //   ];
//   // } else if (cell.type === "attachment" || cell.type === "multiAttachment") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//   //     {
//   //       id: "attachment_user/1/ss/609",
//   //       name: "1f877fad-e0d5-491f-9471-86ab1f0147b9",
//   //       hidePreview: false,
//   //     },
//   //   ];
//   // } else if (cell.type === "chart" || cell.type === "graph") {
//   //   // TODO add some chart data
//   // } else if (
//   //   cell.type === "checkBox" ||
//   //   cell.type === "checkBoxText" ||
//   //   cell.type === "dualCheckBox" ||
//   //   cell.type === "dualCheckBoxText"
//   // ) {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = true;
//   // } else if (cell.type === "textField") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//   //     cell.isNumeric || cell.numeric
//   //       ? testing
//   //         ? tempSeedValue.newValue.toString()
//   //         : tempSeedValue.oldValue.toString()
//   //       : "Lorem ipsum " + cell.valueKey.split("_").pop();
//   //   if (testing) tempSeedValue.newValue++;
//   //   else tempSeedValue.oldValue++;
//   // } else if (cell.type == "multilineField") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//   //     cell.isNumeric || cell.numeric ? "1" : "Lorem ipsum multiline";
//   // } else if (cell.type === "datePicker" || cell.type === "datePickerCheckBox") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = moment(
//   //     "1992-10-10",
//   //     "YYYY-MM-DD"
//   //   ).format("YYYY-MM-DDTHH:mm:ssZ");
//   // } else if (cell.type === "picker") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] =
//   //     "Lorem ipsum picker " + cell.valueKey.split("_").pop();
//   // } else if (cell.type === "multiPicker") {
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//   //     "Lorem ipsum mpicker1 " + cell.valueKey.split("_").pop(),
//   //     "Lorem ipsum mpicker2 " + cell.valueKey.split("_").pop(),
//   //   ];
//   // } else if (cell.type === "extraRows") {
//   //   for (let i = 0; i < 3; i++) {
//   //     const newValueKey = genNewValKey(doc.currentValueKey + 1, "user/1");
//   //     doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//   //       { valueKey: newValueKey },
//   //     ];
//   //     doc.currentValueKey =
//   //       typeof newValueKey === "string"
//   //         ? parseInt(newValueKey.split("u")[0])
//   //         : newValueKey;
//   //     cell.inputs?.forEach((x) => {
//   //       seedCellData(
//   //         doc,
//   //         x,
//   //         getFullValueKey(`${cell.valueKey}_${newValueKey}`, valueKeyPrefix),
//   //         options,
//   //         testing
//   //       );
//   //     });
//   //   }
//   // } else if (cell.type === "dividedInputs") {
//   //   cell.inputs?.forEach((x) => {
//   //     seedCellData(doc, x, valueKeyPrefix, options, testing);
//   //   });
//   // } else if (cell.type === "radioSelect" || cell.type === "radio") {
//   //   if (cell.inputs)
//   //     seedCellData(
//   //       doc,
//   //       cell.inputs[0],
//   //       valueKeyPrefix + "_" + cell.valueKey,
//   //       options,
//   //       testing
//   //     );
//   // } else if (cell.type === "pickerObjects") {
//   //   const pickerObject = getReduxLayout(
//   //     options,
//   //     cell.layoutId,
//   //     cell.layoutVersion
//   //   );

//   //   const pickerObjects = Object.values(options[cell.optionsProp]);
//   //   doc.values[getFullValueKey(cell.valueKey, valueKeyPrefix)] = [
//   //     pickerObjects[0],
//   //     pickerObjects[1]
//   //   ]
//   // }
// };

// export const seedDocData = (
//   secondVersion,
//   options,
//   layout,
//   layoutId,
//   layoutVersion,
//   testing
// ) => {
//   tempSeedValue.oldValue = 1;
//   tempSeedValue.newValue = 1;
//   const doc = {
//     id:
//       "doc_" + 1 + "/" + (OS === "web" ? "web/" : "") + (secondVersion ? 2 : 1),
//     date: new moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
//     currentValueKey: 1,
//     values: {},
//     technician: "Matti Meikäläinen",
//     layoutId: layoutId,
//     layoutVersion: layoutVersion,
//     status: 0,
//   };

//   layout.sections?.forEach((section, sectionIndex) => {
//     if (section.type === "rows") {
//       section.cells.forEach((cell) => {
//         seedCellData(doc, cell, "", options, testing);
//       });
//     } else if (section.type === "togglableRows") {
//       doc.values[section.valueKey.toString()] = true;

//       // TODO test with both alternate and cells
//       section.cells.forEach((cell) => {
//         seedCellData(doc, cell, "", options, testing);
//       });
//       section.alternateCells.forEach((cell) => {
//         seedCellData(doc, cell, "", options, testing);
//       });
//     } else if (section.type === "modularItems") {
//       const layout = section.noOptionsLayout
//         ? null
//         : options.layouts[section.layoutId].versions[section.layoutVersion];

//       const newValueKey = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId] = [
//         { title: "modularItem1", valueKey: newValueKey },
//       ];
//       doc.currentValueKey =
//         typeof newValueKey === "string"
//           ? parseInt(newValueKey.split("u")[0])
//           : newValueKey;

//       const newValueKey2 = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId].push({
//         title: "modularItem2",
//         valueKey: newValueKey2,
//       });
//       doc.currentValueKey =
//         typeof newValueKey2 === "string"
//           ? parseInt(newValueKey2.split("u")[0])
//           : newValueKey2;

//       layout?.headerItems?.forEach((cell, i) => {
//         seedCellData(
//           doc,
//           cell,
//           `${section.layoutId}_${newValueKey}`,
//           options,
//           testing
//         );
//       });
//       layout?.headerItems?.forEach((cell, i) => {
//         seedCellData(
//           doc,
//           cell,
//           `${section.layoutId}_${newValueKey2}`,
//           options,
//           testing
//         );
//       });

//       const newValueKey3 = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId][0].innerItems = [
//         { title: "modularInnerItem1", valueKey: newValueKey3 },
//       ];
//       doc.currentValueKey =
//         typeof newValueKey3 === "string"
//           ? parseInt(newValueKey3.split("u")[0])
//           : newValueKey3;

//       const newValueKey4 = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId][0].innerItems.push({
//         title: "modularInnerItem2",
//         valueKey: newValueKey4,
//       });
//       doc.currentValueKey =
//         typeof newValueKey4 === "string"
//           ? parseInt(newValueKey4.split("u")[0])
//           : newValueKey4;

//       layout?.headerItems?.forEach((cell, i) => {
//         seedCellData(
//           doc,
//           cell,
//           `${section.layoutId}_${newValueKey}_${newValueKey3}`,
//           options,
//           testing
//         );
//       });
//       layout?.items?.forEach((cell, i) => {
//         seedCellData(
//           doc,
//           cell,
//           `${section.layoutId}_${newValueKey}_${newValueKey4}`,
//           options,
//           testing
//         );
//       });
//     } else if (section.type === "measurementObjects") {
//       const layout =
//         options?.layouts?.[section.layoutId]?.versions?.[section.layoutVersion];

//       const newValueKey = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId] = [
//         { id: "measObj1", valueKey: newValueKey },
//       ];
//       doc.currentValueKey =
//         typeof newValueKey === "string"
//           ? parseInt(newValueKey.split("u")[0])
//           : newValueKey;

//       const newValueKey2 = genNewValKey(doc.currentValueKey + 1, "user/1");
//       doc.values[section.layoutId].push({
//         id: "measObj2",
//         valueKey: newValueKey2,
//       });
//       doc.currentValueKey =
//         typeof newValueKey2 === "string"
//           ? parseInt(newValueKey2.split("u")[0])
//           : newValueKey2;

//       if (layout?.titles) {
//         Object.keys(layout.titles).forEach((x, i) => {
//           doc.values[`${section.layoutId}_${newValueKey}_${x}`] = {
//             worstValue: 10 + i,
//             values: [10 + i],
//           };
//         });
//         Object.keys(layout.titles).forEach((x, i) => {
//           doc.values[`${section.layoutId}_${newValueKey2}_${x}`] = {
//             worstValue: 11 + i,
//             values: [11 + i],
//           };
//         });
//       }
//       if (layout?.extraData) {
//         layout.extraData.forEach((cell, i) => {
//           seedCellData(
//             doc,
//             cell,
//             `${section.layoutId}_${newValueKey}`,
//             options,
//             testing
//           );
//         });
//         layout.extraData.forEach((cell, i) => {
//           seedCellData(
//             doc,
//             cell,
//             `${section.layoutId}_${newValueKey2}`,
//             options,
//             testing
//           );
//         });
//       }
//     } else if (section.type === "togglableCellsSections") {
//       const layout =
//         options?.layouts?.[section.layoutId]?.versions?.[section.layoutVersion];
//       layout?.items?.forEach((togglableCell) => {
//         togglableCell?.items?.forEach((cell) => {
//           seedCellData(
//             doc,
//             cell,
//             `${section.valueKey || sectionIndex}_${section.layoutId}`,
//             options,
//             testing
//           );
//         });
//         togglableCell?.inputs?.forEach((cell) => {
//           seedCellData(
//             doc,
//             cell,
//             `${section.valueKey || sectionIndex}_${section.layoutId}`,
//             options,
//             testing
//           );
//         });
//       });
//     } else if (section.type === "signatures") {
//     }
//   });

//   return doc;
// };

// const deleteField = (obj, field) => {
//   if (obj !== null && typeof obj === "object") {
//     Object.keys(obj).forEach((key) => {
//       if (key === field) {
//         delete obj[key];
//       } else if (Array.isArray(obj[key])) {
//         obj[key].forEach((x) => {
//           deleteField(x, field);
//         });
//       } else if (typeof obj[key] === "object") {
//         deleteField(obj[key], field);
//       }
//     });
//   }
// };

// const saveLayoutZip = false;
// const generatePDFLayouts = async (
//   zip,
//   _props,
//   oldProps,
//   layouts,
//   oldLayouts,
//   secondVersion,
//   testing
// ) => {
//   // TODO need to seed the doc with old layout and use that for both new and old pdfCreator to see if there are any valueKey mismatches
//   const _layouts = {};

//   const layoutKeys = Object.keys(oldLayouts);

//   for (let i = 0; i < layoutKeys.length; i++) {
//     // for (let i = 0; i < 1; i++) {
//     const key = layoutKeys[i];

//     continue;

//     // THESE WORK BETTER IN NEW PDF CREATOR
//     // "docLayouts/17",
//     // "docLayouts/20",
//     // "docLayouts/52",
//     // "docLayouts/107",
//     // "docLayouts/114",

//     // const needToCheck = ["docLayouts/20", "docLayouts/107", "docLayouts/114"];

//     // continue;
//     // if (!needToCheck.some((x) => key === x)) continue;
//     // if (key !== "docLayouts/6") continue;
//     // if (key !== "docLayouts/109") continue;
//     // if (key !== "docLayouts/121") continue;
//     // if (key !== "docLayouts/129") continue;

//     // if (key !== "docLayouts/45") continue;
//     // if (key !== "docLayouts/56") continue;
//     // if (key !== "docLayouts/102") continue;
//     // if (key !== "docLayouts/53") continue;
//     // if (key === "docLayouts/49") continue;
//     // if (key !== "docLayouts/6") continue;
//     if (key === "docLayouts/0") continue;
//     if (key === "docLayouts/1004") continue;
//     if (key === "docLayouts/1005") continue;
//     if (key === "docLayouts/1001") continue;
//     if (key.startsWith("layouts/")) continue;

//     _layouts[key] = {
//       versions: {},
//     };

//     const layout = layouts[key];
//     if (!layout?.versions) {
//       continue;
//     }
//     const layoutVersionKeys = Object.keys(layout.versions);

//     for (let j = 0; j < layoutVersionKeys.length; j++) {
//       const layoutVersion = layoutVersionKeys[j];

//       // const layoutVersion =
//       // testing && layoutVersionKeys.some((x) => x.endsWith("new"))
//       //   ? layoutVersionKeys.find((x) => x.endsWith("new"))
//       //   : Math.max(...layoutVersionKeys.filter((x) => !x.includes("new")));

//       const _docLayout = layout.versions[layoutVersion];

//       // OLD
//       const oldlayout = oldLayouts[key];
//       const oldlayoutVersionKeys = Object.keys(oldlayout.versions);

//       const oldlayoutVersion = layoutVersion;
//       // testing && oldlayoutVersionKeys.some((x) => x.endsWith("new"))
//       //   ? oldlayoutVersionKeys.find((x) => x.endsWith("new"))
//       //   : Math.max(...oldlayoutVersionKeys.filter((x) => !x.includes("new")));

//       const old_docLayout = oldlayout.versions[oldlayoutVersion];

//       // use the old layout for getting doc values so we know if the values have disrepancies
//       const _doc = seedDocData(
//         secondVersion,
//         oldProps.options,
//         old_docLayout,
//         key,
//         layoutVersion,
//         testing
//       );

//       let pdfData;
//       if (testing) {
//         pdfData = await createPdf({
//           ..._props,
//           testing: testing,
//           doc: _doc,
//           lang: i18next.language,
//           content: _docLayout,
//           sources: _docLayout.sources,
//         });
//       } else {
//         pdfData = await createPdfOld({
//           ..._props,
//           testing: testing,
//           doc: _doc,
//           lang: i18next.language,
//           content: _docLayout,
//           sources: _docLayout.sources,
//         });
//       }

//       if (saveLayoutZip) {
//         zip.file(
//           `${key.split("/").pop()}_${layoutVersion}_${
//             testing ? "new" : "old"
//           }.pdf`,
//           pdfData.base64String,
//           {
//             base64: true,
//           }
//         );
//       }

//       _layouts[key].versions[
//         layoutVersion.toString().includes("new")
//           ? layoutVersion.split("_")[0]
//           : layoutVersion
//       ] = pdfData.actions;

//       // for (let j = 0; j < layoutVersionKeys.length; j++) {
//       // const layoutVersion = layoutVersionKeys[j];
//       // }
//     }
//   }

//   deleteField(_layouts, "imageData");
//   deleteField(_layouts, "cell");
//   deleteField(_layouts, "checkBox");
//   return _layouts;
// };

// const reduceLayoutVersioningToLayoutDict = (layouts) => {
//   const _layouts = {};

//   const layoutKeys = Object.keys(layouts);

//   for (let i = 0; i < layoutKeys.length; i++) {
//     // for (let i = 0; i < 1; i++) {
//     const key = layoutKeys[i];
//     _layouts[key] = {
//       versions: {},
//     };

//     const layout = layouts[key];
//     const layoutVersionKeys = Object.keys(layout.versions);

//     const layoutVersion = Math.max(...layoutVersionKeys);
//     const _layout = layout.versions[layoutVersion];

//     _layouts[key] = _layout;
//   }
//   return _layouts;
// };

// export const generateActionsFromDocLayouts = async (_props, layouts1) => {
//   var zip = new JSZip();
//   // docLayouts,
//   // measurementObjectsMocks,
//   // modularItemsMocks,
//   // togglableCellsSectionsMocks,
//   // pickerObjectsMocks

//   const newLayouts = {
//     ...layouts1,
//     "docLayouts/0": docLayouts["docLayouts/0"],
//   };

//   const oldProps = {
//     ..._props,
//     options: {
//       ..._props.options,
//       layouts: {
//         ...docLayouts,
//         ...measurementObjectsMocks,
//         ...modularItemsMocks,
//         ...togglableCellsSectionsMocks,
//         ...pickerObjectsMocks,
//       },
//       // docLayouts: docLayouts,
//       // measurementObjects: measurementObjectsMocks,
//       // modularItems: modularItemsMocks,
//       // togglableCellsSections: togglableCellsSectionsMocks,
//       // pickerObjects: pickerObjectsMocks,
//       // measurementObjects: reduceLayoutVersioningToLayoutDict(
//       //   measurementObjectsMocks
//       // ),
//       // modularItems: reduceLayoutVersioningToLayoutDict(modularItemsMocks),
//       // togglableCellsSections: reduceLayoutVersioningToLayoutDict(
//       //   togglableCellsSectionsMocks
//       // ),
//       // pickerObjects: reduceLayoutVersioningToLayoutDict(pickerObjectsMocks),
//     },
//   };

//   const pdfLayoutsNew = await generatePDFLayouts(
//     zip,
//     // {
//     //   ..._props,
//     //   options: {
//     //     ..._props.options,
//     //     docLayouts: docLayouts,
//     //     measurementObjects: measurementObjectsMocks,
//     //     modularItems: modularItemsMocks,
//     //     togglableCellsSections: togglableCellsSectionsMocks,
//     //     pickerObjects: pickerObjectsMocks,
//     //     // measurementObjects: reduceLayoutVersioningToLayoutDict(
//     //     //   measurementObjectsMocks
//     //     // ),
//     //     // modularItems: reduceLayoutVersioningToLayoutDict(modularItemsMocks),
//     //     // togglableCellsSections: reduceLayoutVersioningToLayoutDict(
//     //     //   togglableCellsSectionsMocks
//     //     // ),
//     //     // pickerObjects: reduceLayoutVersioningToLayoutDict(pickerObjectsMocks),
//     //   },
//     // },
//     // docLayouts,
//     _props,
//     oldProps,
//     newLayouts,
//     docLayouts,
//     false,
//     true
//   );

//   const pdfLayoutsOld = await generatePDFLayouts(
//     zip,
//     oldProps,
//     oldProps,
//     docLayouts,
//     docLayouts,
//     false,
//     false
//   );
//   // const pdfLayouts3 = await generatePDFLayouts(
//   //   zip,
//   //   {
//   //     ..._props,
//   //     options: {
//   //       ..._props.options,
//   //       docLayouts: docLayouts,
//   //       measurementObjects: measurementObjectsMocks,
//   //       modularItems: modularItemsMocks,
//   //       togglableCellsSections: togglableCellsSectionsMocks,
//   //       pickerObjects: pickerObjectsMocks,
//   //       // measurementObjects: reduceLayoutVersioningToLayoutDict(
//   //       //   measurementObjectsMocks
//   //       // ),
//   //       // modularItems: reduceLayoutVersioningToLayoutDict(modularItemsMocks),
//   //       // togglableCellsSections: reduceLayoutVersioningToLayoutDict(
//   //       //   togglableCellsSectionsMocks
//   //       // ),
//   //       // pickerObjects: reduceLayoutVersioningToLayoutDict(pickerObjectsMocks),
//   //     },
//   //   },
//   //   docLayouts,
//   //   true
//   // );

//   if (saveLayoutZip) {
//     const zipBlobl = await zip.generateAsync({ type: "blob" });
//     saveAs(zipBlobl, `PDFs_${moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")}.zip`);
//   }

//   const oldKeys = {
//     docLayouts: getUniqueKeys(docLayouts).sort((a, b) => naturalCompare(a, b)),
//     measurementObjectsMocks: getUniqueKeys(measurementObjectsMocks).sort(
//       (a, b) => naturalCompare(a, b)
//     ),
//     modularItemsMocks: getUniqueKeys(modularItemsMocks).sort((a, b) =>
//       naturalCompare(a, b)
//     ),
//     togglableCellsSectionsMocks: getUniqueKeys(
//       togglableCellsSectionsMocks
//     ).sort((a, b) => naturalCompare(a, b)),
//     pickerObjectsMocks: getUniqueKeys(pickerObjectsMocks).sort((a, b) =>
//       naturalCompare(a, b)
//     ),
//   };

//   const differingLayouts = Object.keys(pdfLayoutsOld).reduce((acc, cur) => {
//     const _diff = diff.getDiff(pdfLayoutsNew[cur], pdfLayoutsOld[cur], true);
//     if (_diff.length > 0) {
//       acc[cur] = _diff;
//     }
//     return acc;
//   }, {});
//   c("pdf layouts 2", {
//     oldKeys,
//     docLayouts: {
//       docLayoutsNew: layouts1,
//       docLayoutsOld: docLayouts,
//       docLayoutsNewCount: Object.keys(layouts1).length,
//       docLayoutsOldCount: Object.keys(docLayouts).length,
//       docLayoutsDiff: Object.keys(layouts1).reduce((prev, cur) => {
//         prev[cur] = diff.getDiff(layouts1[cur], docLayouts[cur], true);
//         return prev;
//       }, {}),
//     },
//     measurementObjects: {
//       measurementObjectsOld: measurementObjectsMocks,
//       measurementObjectsNew: _props.options.layouts,
//       diff: diff.getDiff(_props.options.layouts, measurementObjectsMocks, true),
//     },
//     modularItems: {
//       modularItemsOld: modularItemsMocks,
//       modularItemsNew: _props.options.layouts,
//       diff: Object.keys(modularItemsMocks).reduce((prev, cur) => {
//         prev[cur] = diff.getDiff(
//           _props.options.layouts[cur],
//           modularItemsMocks[cur],
//           true
//         );
//         return prev;
//       }, {}),
//     },
//     togglableCellsSections: {
//       modularItemsOld: togglableCellsSectionsMocks,
//       modularItemsNew: _props.options.layouts,
//       diff: Object.keys(togglableCellsSectionsMocks).reduce((prev, cur) => {
//         prev[cur] = diff.getDiff(
//           _props.options.layouts[cur],
//           togglableCellsSectionsMocks[cur],
//           true
//         );
//         return prev;
//       }, {}),
//     },
//     pickerObjects: {
//       modularItemsOld: pickerObjectsMocks,
//       modularItemsNew: _props.options.layouts,
//       diff: Object.keys(pickerObjectsMocks).reduce((prev, cur) => {
//         prev[cur] = diff.getDiff(
//           _props.options.layouts[cur],
//           pickerObjectsMocks[cur],
//           true
//         );
//         return prev;
//       }, {}),
//     },
//     pdfLayouts: {
//       pdfLayoutsNew,
//       pdfLayoutsOld,
//       diff: diff.getDiff(pdfLayoutsNew, pdfLayoutsOld, true),
//       // pdfLayouts3,
//       // diff2: diff.getDiff(pdfLayoutsOld, pdfLayouts3, true),
//     },
//     differingLayouts: differingLayouts,
//     differingLayoutsCount: Object.keys(differingLayouts).length,
//   });
// };
//#endregion

//#region layout upload stuff
// function onlyUnique(value, index, self) {
//   return self.indexOf(value) === index;
// }

// export function uniqueArray3(a) {
//   return a.filter(onlyUnique);
// }

// export function getLayoutDependencies(layouts, docLayouts) {
//   let res = {};

//   const keys = Object.keys(layouts);

//   for (let i = 0; i < keys.length; i++) {
//     const layoutId = keys[i];

//     const dependencies = getSingleLayoutDependencies(layoutId, docLayouts);
//     res = update(res, {
//       [layoutId]: {
//         $auto: {
//           $set: {
//             ...layouts[layoutId],
//             sharedTo: (layouts[layoutId].sharedTo || []).concat(
//               dependencies.sharedTo
//             ),
//             hiddenFrom: (layouts[layoutId].hiddenFrom || []).concat(
//               dependencies.hiddenFrom
//             ),
//             global: layouts[layoutId].global || dependencies.global,
//             inDocs: dependencies.inDocs,
//           },
//         },
//       },
//     });
//     res[layoutId].sharedTo = res[layoutId].global
//       ? undefined
//       : uniqueArray3(res[layoutId].sharedTo);
//     res[layoutId].hiddenFrom = uniqueArray3(res[layoutId].hiddenFrom);
//   }
//   return res;
// }

// export function getSingleLayoutDependencies(layoutId, docLayouts) {
//   let res = {
//     sharedTo: [],
//     hiddenFrom: [],
//     global: false,
//   };

//   const docIds = Object.keys(docLayouts);

//   for (let j = 0; j < docIds.length; j++) {
//     const docId = docIds[j];

//     const baseLayout = docLayouts[docId];

//     const versionsKeys = Object.keys(baseLayout.versions);

//     for (let k = 0; k < versionsKeys.length; k++) {
//       const version = versionsKeys[k];

//       const docLayout = baseLayout.versions[version];

//       const jsonLayout = JSON.stringify(docLayout);

//       const searchRes = jsonLayout.search(layoutId);

//       if (searchRes !== -1) {
//         res = update(res, {
//           sharedTo: {
//             $push:
//               res.global || baseLayout.global ? [] : baseLayout.sharedTo || [],
//           },
//           hiddenFrom: { $push: baseLayout.hiddenFrom || [] },
//           global: { $set: res.global || baseLayout.global },
//           inDocs: {
//             $autoArray: { $push: [{ id: docId, global: baseLayout.global }] },
//           },
//         });
//       }
//     }
//   }

//   res.sharedTo = uniqueArray3(res.sharedTo);
//   res.hiddenFrom = uniqueArray3(res.hiddenFrom);

//   return res;
// }
//#endregion

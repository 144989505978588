import { Platform } from "react-native";
import update from "immutability-helper";
import sortBy from "../lib/sort";
import moment from "moment";
import {
  getNewSortObj,
  getColumnValue,
  modifyValueItemWithPreviousValue,
  errorReport,
  isArrayWithItems,
} from "../lib/functions";

export const targetPropArrs = ["measuringDevices", "standards"];

const INITIAL_STATE = {
  unfinished: {},
  completed: [],
  groupsVisible: true,
  inputsVisible: true,
};

const removeObjects = (type, ids, unfinished, completed) => {
  if (type === "completed") {
    let newDocs = [];
    newDocs = completed.filter((x) => {
      return !ids.includes(x.id);
    });

    return newDocs;
  } else {
    const newDocs = update(unfinished, { $unset: ids });
    return newDocs;
  }
};

function getDate() {
  return moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
}

function sortObjects(
  state,
  arrName,
  searchArr,
  _sortObj,
  sortArrIndex,
  sortObjProp,
  comparable,
  reversed,
  options,
  lang,
  arrToSort,
  keepReverse
) {
  if (arrName === "unfinished") {
    if (keepReverse) return state;
    else {
      return update(state, {
        [sortObjProp]: {
          $set: getNewSortObj(_sortObj, sortArrIndex, reversed),
        },
      });
    }
  } else if (!comparable || comparable === "date") {
    return update(state, {
      [searchArr]: {
        $apply: function (x) {
          return (arrToSort || x).sort(
            sortBy("date", reversed, function (a) {
              return moment(a);
            })
          );
        },
      },
      [sortObjProp]: {
        $set: getNewSortObj(_sortObj, sortArrIndex, reversed),
      },
    });
  } else if (Array.isArray(comparable)) {
    return update(state, {
      [searchArr]: {
        $apply: function (x) {
          return (arrToSort || x).sort((a, b) => {
            const aToCompare = getColumnValue(comparable, a, options)
              .toString()
              .toUpperCase()
              .replace(/[\s]/gi, "");

            const bToCompare = getColumnValue(comparable, b, options)
              .toString()
              .toUpperCase()
              .replace(/[\s]/gi, "");

            if (reversed) {
              return bToCompare.localeCompare(aToCompare);
            } else {
              return aToCompare.localeCompare(bToCompare);
            }
          });
        },
      },
      [sortObjProp]: {
        $set: getNewSortObj(_sortObj, sortArrIndex, reversed),
      },
    });
  } else {
    const splitProps = comparable.split(".");
    return update(state, {
      [searchArr]: {
        $apply: function (x) {
          return (arrToSort || x).sort(function (a, b) {
            const aToCompare = a[splitProps[0]]
              ? a[splitProps[0]].toString().toUpperCase().replace(/[\s]/gi, "")
              : "";
            const bToCompare = b[splitProps[0]]
              ? b[splitProps[0]].toString().toUpperCase().replace(/[\s]/gi, "")
              : "";

            if (reversed) {
              return bToCompare.localeCompare(aToCompare);
            } else {
              return aToCompare.localeCompare(bToCompare);
            }
          });
        },
      },
      [sortObjProp]: {
        $set: getNewSortObj(_sortObj, sortArrIndex, reversed),
      },
    });
  }
}

function reformatProjectGroups(groups) {
  return groups.map((group, index) =>
    update(group, {
      id: { $set: index },
      dbId: { $set: group.id },
    })
  );
}
function reformatProjectTasks(tasks, groups) {
  return tasks.map((task) =>
    update(task, {
      row: {
        $set: groups.findIndex((group) => group.tasks.includes(task.id)),
      },
    })
  );
}
function reformatProject(project) {
  const newGroups = project.groups
    ? reformatProjectGroups(Object.values(project.groups))
    : [];

  return update(project, {
    groups: {
      $set: newGroups,
    },
    tasks: {
      $set: project.tasks
        ? reformatProjectTasks(Object.values(project.tasks), newGroups)
        : [],
    },
  });
}

export default function projectsReducer(state = INITIAL_STATE, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return INITIAL_STATE;
    } else if (action.type === "SIGN_OUT") {
      if (Platform.OS === "web") {
        return INITIAL_STATE;
      } else {
        return state;
      }
    } else if (action.type === "SET_STATE_PROP") {
      const { prop, val } = action.payload;
      return update(state, { [prop]: { $set: val } });
    } else if (action.type === "REFRESH_PROJECTS") {
      const { data, arrName, options, lang } = action.payload;
      if (arrName === "unfinished") {
        return update(state, {
          [arrName]: {
            $set: data.reduce((prev, cur) => {
              prev[cur.id] = reformatProject(cur);
              return prev;
            }, {}),
          },
        });
      } else {
        const searchArr = arrName;
        const sortObjProp = arrName + "SortObj";

        const _sortObj = state[sortObjProp];

        let sortArrIndex =
          isArrayWithItems(_sortObj) && _sortObj.findIndex((x) => x.sorted);
        if (sortArrIndex === -1) {
          sortArrIndex = 0;
        }

        const comparable = _sortObj?.[sortArrIndex]?.comparable;
        const reversed = _sortObj?.[sortArrIndex]?.reversed;

        try {
          const sortedData = sortObjects(
            state,
            arrName,
            searchArr,
            _sortObj,
            sortArrIndex,
            sortObjProp,
            comparable,
            reversed,
            options,
            lang,
            data
          );
          return sortedData;
        } catch (error) {
          throw (
            error +
            ", Props: " +
            JSON.stringify({ sortObjProp, _sortObj, sortArrIndex })
          );
        }
      }
    } else if (action.type === "SET_INITIAL_PROJECT") {
      const { id, project } = action.payload;

      return update(state, {
        unfinished: {
          [id]: {
            $set: reformatProject(project),
          },
        },
      });
    } else if (action.type === "ADD_PROJECT") {
      const { projectId } = action.payload;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            $set: {
              id: projectId,
              name: "",
              groups: [],
              tasks: [],
            },
          },
        }),
      };
    } else if (action.type === "ADD_GROUP") {
      const { projectId, group } = action.payload;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            groups: (tmpGroups) =>
              update(tmpGroups || [], {
                $push: [
                  update(group, {
                    dbId: { $set: group.id },
                    id: { $set: tmpGroups.length },
                  }),
                ],
              }),
          },
        }),
      };
    } else if (action.type === "ADD_TASK") {
      const { projectId, groupId, task } = action.payload;
      const groupIndex = state.unfinished[projectId].groups.findIndex(
        (x) => x.dbId === groupId
      );
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            groups: (tmpGroups) =>
              update(tmpGroups || [], {
                [groupIndex]: {
                  tasks: { $push: [task.id] },
                },
              }),
            tasks: (tmpTasks) =>
              update(tmpTasks || [], {
                $push: [update(task, { row: { $set: groupIndex } })],
              }),
          },
        }),
      };
    } else if (action.type === "MOVE_PROJECT") {
      const { oldId, source, destination, newObj } = action.payload;

      let newObjects;
      if (destination === "unfinished") {
        newObjects = update(state[destination], {
          [oldId]: { $set: newObj || state[source][oldId] },
        });
      } else {
        newObjects = update(state[destination], {
          $push: [newObj || state[source][oldId]],
        });
      }

      const removed = removeObjects(
        source,
        [oldId],
        state.unfinished,
        state.completed
      );

      return {
        ...state,
        [source]: removed,
        [destination]: newObjects,
      };
    } else if (action.type === "SET_PROJECTS_SORT_OBJECTS") {
      const { arrName, sortObj } = action.payload;

      const sortObjProp = arrName + "SortObj";

      return update(state, {
        [sortObjProp]: {
          $set: sortObj,
        },
      });
    } else if (action.type === "SORT_PROJECTS") {
      const { arrName, sortArrIndex, options, keepReverse, lang } =
        action.payload;

      const searchArr = arrName;
      const sortObjProp = arrName + "SortObj";

      const _sortObj = state[sortObjProp];

      const comparable = _sortObj?.[sortArrIndex]?.comparable;
      const reversed = !_sortObj?.[sortArrIndex]?.reversed;

      return sortObjects(
        state,
        arrName,
        searchArr,
        _sortObj,
        sortArrIndex,
        sortObjProp,
        comparable,
        reversed,
        options,
        lang,
        null,
        keepReverse
      );
    } else if (action.type === "SET_PROJECT_PROP") {
      const { id, prop, value, remove, isArr } = action.payload;

      if (!id) throw "SET_PROJECT_PROP" + "missing id";
      const updateObj = {
        [id]: {
          [prop]: {
            $apply: (currentVal) =>
              modifyValueItemWithPreviousValue({
                currentVal: currentVal,
                newVal: value,
                oldVal: undefined,
                itemIsArr: isArr,
                remove: remove,
                replace: false,
                replaceArr: false,
                idProp: false,
                index: undefined,
                preset: undefined,
                setProp: false,
              }),
          },
          date: { $set: getDate() },
        },
      };
      return update(state, {
        unfinished: updateObj,
      });
    } else if (action.type === "SET_GROUP_PROP") {
      const { projectId, id, prop, value, remove, isArr } = action.payload;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            groups: (tmpGroups) =>
              update(tmpGroups || [], {
                $apply: (x) => {
                  const index = x.findIndex((_obj) => _obj.dbId === id);
                  if (index !== -1) {
                    return update(x, {
                      [index]: {
                        [prop]: {
                          $apply: (currentVal) =>
                            modifyValueItemWithPreviousValue({
                              currentVal: currentVal,
                              newVal: value,
                              oldVal: undefined,
                              itemIsArr: isArr,
                              remove: remove,
                              replace: false,
                              replaceArr: false,
                              idProp: false,
                              index: undefined,
                              preset: undefined,
                              setProp: false,
                            }),
                        },
                      },
                    });
                  }
                },
              }),
          },
        }),
      };
    } else if (action.type === "SET_TASK_PROP") {
      const {
        projectId,
        id,
        prop,
        value,
        remove,
        isArr,
        propToSet,
        idProp,
        oldVal,
      } = action.payload;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            tasks: (tmpTasks) =>
              update(tmpTasks || [], {
                $apply: (x) => {
                  const index = x.findIndex((_obj) => _obj.id === id);
                  if (index !== -1) {
                    return update(x, {
                      [index]: {
                        [prop]: {
                          $apply: (currentVal) =>
                            modifyValueItemWithPreviousValue({
                              currentVal: currentVal,
                              newVal: value,
                              oldVal: oldVal,
                              itemIsArr: isArr,
                              remove: remove,
                              replace: false,
                              replaceArr: false,
                              idProp: idProp,
                              index: undefined,
                              preset: undefined,
                              setProp: propToSet,
                            }),
                        },
                      },
                    });
                  } else {
                    return x;
                  }
                },
              }),
          },
        }),
      };
    } else if (action.type === "SET_MODIFY_PROJECT") {
      const { id, completed } = action.payload;
      return update(state, {
        modify: {
          $set: { id, completed },
        },
      });
    } else if (action.type === "SET_PROJECT") {
      const { id, project } = action.payload;
      return update(state, {
        unfinished: {
          [id]: {
            $set: project,
          },
        },
      });
    } else if (action.type === "DELETE_PROJECT") {
      const { type, ids } = action.payload;
      const removed = removeObjects(
        type,
        ids,
        state.unfinished,
        state.completed
      );
      return {
        ...state,
        [type]: removed,
      };
    } else if (action.type === "DELETE_GROUP") {
      const { projectId, id } = action.payload;
      let groupIndex;
      let newGroups;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            groups: (tmpGroups) =>
              update(tmpGroups || [], {
                $apply: (x) => {
                  groupIndex = x.findIndex((_obj) => _obj.dbId === id);
                  if (groupIndex !== -1) {
                    newGroups = update(x, { $splice: [[groupIndex, 1]] }).map(
                      (group, index) =>
                        update(group, {
                          id: { $set: index },
                        })
                    );
                    return newGroups;
                  } else {
                    return x;
                  }
                },
              }),
            tasks: (tmpTasks) =>
              update(tmpTasks || [], {
                $apply: (x) => {
                  return reformatProjectTasks(
                    x.filter((task) => task.row !== groupIndex),
                    newGroups
                  );
                },
              }),
          },
        }),
      };
    } else if (action.type === "DELETE_TASK") {
      const { projectId, id } = action.payload;
      return {
        ...state,
        unfinished: update(state.unfinished, {
          [projectId]: {
            tasks: (tmpTasks) =>
              update(tmpTasks || [], {
                $apply: (x) => {
                  const index = x.findIndex((_obj) => _obj.id === id);
                  if (index !== -1) {
                    return update(x, { $splice: [[index, 1]] });
                  } else {
                    return x;
                  }
                },
              }),
          },
        }),
      };
    }

    return state;
  } catch (error) {
    const errorStr = `Error: ${error}, Reducer: ProjectsReducer, Action: ${
      action.type
    }, Payload: ${
      action.payload
        ? JSON.stringify(
            action.payload?.options
              ? Object.keys(action.payload).reduce((obj, key) => {
                  if (key !== "options") obj[key] = action.payload[key];
                  return obj;
                }, {})
              : action.payload
          )
        : "no payload"
    }`;
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "ProjectsReducer",
        errorParams: {
          type: action?.type,
          payload: errorStr,
        },
      });
    } else {
      console.warn(errorStr);
    }
    return state;
  }
}

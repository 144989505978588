import { useCallback, useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { ThemeContext } from "../theming/theme-context";

const baseStyle = {
  flex: 1,
  display: "flex",
  borderStyle: "solid",
  height: "100%",
};

export default function DropzoneElem(props) {
  const theming = useContext(ThemeContext);
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.handleAcceptedFiles(acceptedFiles);
    },
    [props.handleAcceptedFiles]
  );
  let dropZoneProps = {
    onDrop,
    noClick: true,
    noDragEventsBubbling: true,
  };
  if (props.accept) dropZoneProps.accept = props.accept;
  if (props.maxFiles) dropZoneProps.maxFiles = props.maxFiles;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropZoneProps);

  const style = useMemo(
    () => ({
      ...{ ...baseStyle, borderColor: props.defaultBorderColor },
      ...(isDragActive
        ? {
            borderStyle: "solid",
            borderColor: theming.colors.success,
          }
        : {}),
      ...(isDragAccept
        ? {
            borderStyle: "solid",
            borderColor: theming.colors.accent,
          }
        : {}),
      ...(isDragReject
        ? {
            borderStyle: "solid",
            borderColor: theming.colors.error,
          }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div
      {...getRootProps({
        style,
      })}
    >
      <input {...getInputProps()} />
      {props.children}
    </div>
  );
}

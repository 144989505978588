import {
  useRef,
  useLayoutEffect,
  useEffect,
  useReducer,
  useMemo,
  useContext,
} from "react";
import {
  BackHandler,
  Image,
  View,
  TouchableOpacity,
  Platform,
  Text,
} from "react-native";
import queryString from "query-string";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import Icon from "../components/Icon";
import StretchButton from "../components/StretchButton";
import ProgressInfo from "../components/ProgressInfo";
import InputPicker from "../components/InputPicker";
import ListPicker from "../components/ListPicker";
import ListPickerItem from "../components/ListPickerItem";
import SimpleModal from "../components/SimpleModal";
import Alert from "../components/AlertModal";
import MonthPicker from "../components/MonthPicker";
import SimpleModalPicker from "../components/SimpleModalPicker";
import ModalPicker from "../components/ModalPicker";
import SimpleForm from "../components/SimpleForm";

// FORM SCREENS
import ModularDoc from "./ModularDoc";
import MeasurementObjectsScreen from "./MeasurementObjectsScreen";
import ModularItemScreen from "./ModularItemScreen";
import AddAttachmentsScreen from "./AddAttachmentsScreen";
import AttachmentsGalleryScreen from "./AttachmentsGalleryScreen";
// FORM SCREENS

import moment from "moment";
import { ThemeContext } from "../theming/theme-context";

import {
  showToast,
  navigate,
  replaceTab,
  fetchNetInfo,
  modalPickerOpts,
  setNetInfoListener,
  setNavigationListener,
  fullHp,
  fullWp,
} from "../lib/helperFns";
import {
  getSpecialInputsProps,
  mergeSources,
  replaceAlternateSources,
  permissionCheck,
  getInitialLayouts,
  errorReport,
  genNewValKey,
  getDocProps,
  getTranslatedText,
  getReduxLayout,
  generateLocalDocId,
  isSameOrGreaterThanRole,
  getPdfLangValueKey,
} from "../lib/functions";
import {
  apiRequest,
  apiRequestWithToken,
  getWithToken,
  putWithToken,
} from "../lib/api";
import { companyWidePermissions, formRoutes } from "../lib/constants";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SYNC_VALUES_TIMESTAMPS_FN,
  MODIFY_VALUE as MODIFY_VALUEFN,
} from "../reducers/DocsReducer";
import {
  REFRESH_DOCS,
  addDoc,
  saveDocToDb,
  modifyValueItem,
  setDocProp,
  updateLastDocRequests,
  UPDATE_LAST_INTERNAL_DOC_SAVES,
  REMOVE_DOCS,
  // modify actions
  SET_DOC_VALUES,
  MODIFY_OBJECT_ARR_ITEM,
  REMOVE_OBJECT_ARR_ITEM,
  REPLACE_OBJECT_ARR_ITEM,
  ADD_TO_OBJECT_ARR,
  ADD_TO_OBJECT_ARR_WITH_GENERATED_ID,
  ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID,
  ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID,
  ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID,
  MODIFY_OBJECT_ARR_INNER_ITEM,
  REMOVE_OBJECT_ARR_INNER_ITEM,
  REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS,
  DELETE_FROM_STRING_ARR,
  DELETE_MULTIPLE_FROM_STRING_ARR,
  MODIFY_STRING_ARR,
  ADD_TO_STRING_ARR,
  MODIFY_VALUE,
  REMOVE_USER_FROM_SHARED_TO,
  // measurementObjectActions
  removeMeasObjects,
  addMeasurement,
  removeModularMeasurement,
  ADD_MEASUREMENT_OBJ_CONNECTION,
  REMOVE_MEASUREMENT_OBJ_CONNECTION,
  // modular item actions
  removeModularItems,
  // ! WILL BE DEPRECATED
  SYNC_VALUES_TIMESTAMPS,
} from "../actions/DocsActions";
// import { SET_DOC_PROP } from "../reducers/DocsReducer";
import { modifyDoc, clearModifyDoc } from "../actions/ModifyDocActions";
import {
  setOptionsChecked,
  ADD_ATTACHMENT,
  refreshPickerObjOptions,
  removePickerObjOption,
  addPickerObjOption,
  modifyPickerOption,
  checkOptions,
  updateOptions,
  SET_LAYOUTS,
} from "../actions/OptionsActions";
import {
  SET_DEEP_LINK_PARAMS,
  SET_GPS_LOCATION,
  signOut,
  toggleMeasurementPerGroup,
  SET_USER_PROP,
} from "../actions/ProfileActions";
import { requestApiData, receiveResponse } from "../actions/ApiActions";
import { REFRESH_PROJECTS } from "../actions/ProjectActions";
import i18next from "i18next";
import ShareModal from "../components/ShareModal";
import componentReducer from "../reducers/ComponentReducer";

const OS = Platform.OS;

const getData = (propsLayouts, companyId, lang, role) => {
  const isAdmin = role === "Admin" || role === "SuperAdmin";
  let data = {};

  if (propsLayouts) {
    Object.entries(propsLayouts)?.forEach(([key, val]) => {
      if (
        val.layoutType === "docLayouts" &&
        //__DEV__ ||
        val.published &&
        (isAdmin ||
          val.global ||
          (val.sharedTo && val.sharedTo?.includes(companyId)))
      ) {
        if (
          !val.extraDoc &&
          (!val.hiddenFrom || !val.hiddenFrom.includes(companyId)) &&
          (!val.hideFromList || !val.hideFromList.includes(companyId))
        ) {
          data[key] = val;
        }
      }
    });
  }

  const ordered = Object.keys(data)
    .sort((a, b) =>
      getTranslatedText(data[a].type, lang).localeCompare(
        getTranslatedText(data[b].type, lang),
        lang
      )
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  return ordered;
};

const CREATE_INITIAL_STATE = {
  editors: null,
  alert: { visible: false },
  formRoute: { route: null, history: [], params: {} },
  reconnecting: { reconnecting: false },
  connected: true,
  creatingDoc: null,
  loadingLayouts: false,
  // modals
  picker: { visible: false },
  textModal: { visible: false },
  datePicker: { visible: false },
  taskInputs: { visible: false },
  modalPicker: {
    visible: false,
  },
  inputModal: {
    visible: false,
  },
  modalForm: { isVisible: false },
  progress: {
    msg: "",
    progress: 0,
  },
  requesting: {},
};

export const CreateDocScreen = (props) => {
  const { theme, colors, orientation, logo, logos } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [state, dispatchState] = useReducer(componentReducer, {
    ...CREATE_INITIAL_STATE,
    connectingSession:
      props.docId && !props.lastDocRequests?.[props.docId]?.oldValues,
    reconnecting: { reconnecting: false },
  });
  const inputAttachments = useRef(null);
  const fullHeight = OS === "web" ? props.fullHeight : fullHp(100);
  const fullWidth = OS === "web" ? props.fullWidth : fullWp(100);

  const mountInProgress = useRef(false);
  const lastDocSaveDate = useRef(false);

  const scrollPosition = useRef(0);
  const viewableItems = useRef();

  const prevDocId = useRef(null);

  const setStateProp = (prop, value) => {
    dispatchState({
      type: "set",
      prop: prop,
      value: value,
    });
  };

  let formRouteParams, formRoute;
  if (OS !== "web" || props.noUrlNav) {
    formRouteParams = state.formRoute.params;
    formRoute = state.formRoute.route;
  } else {
    let search;
    if (props.navigation) {
      if (props.navigation?.location?.search)
        search = props.navigation.location.search;
      else search = props.navigation.location.pathname.split("?")[1];
      formRouteParams = queryString.parse(search, { parseBooleans: true });
      formRoute = formRouteParams.route;
    }
  }

  useEffect(() => {
    // for custom request set:
    // state.requesting.url,
    // state.requesting.method,
    // state.requesting.body,
    // state.requesting.successStatus
    // state.requesting.callback
    if (state.requesting.url) {
      fetchNetInfo().then((netState) => {
        if (netState.isConnected) {
          apiRequest(
            state.requesting.url,
            state.requesting.method,
            state.requesting.body
          )
            .then((res) => {
              if (res.status === state.requesting.successStatus) {
                state.requesting.callback?.(res.data);
              } else {
                showToast(t("unhandledError"));
              }
            })
            .catch((error) => {
              errorReport({
                error,
                errorInFn: "state.requesting",
                errorInScreen: "CreateDocScreen",
                errorParams: {
                  url: state.requesting.url,
                  method: state.requesting.method,
                  body: state.requesting.body,
                  successStatus: state.requesting.successStatus,
                },
              });
            })
            .finally(() => {
              setStateProp("requesting", {});
            });
        } else {
          showToast(t("checkInternetConnection"));
        }
      });
    }
  }, [state.requesting]);

  useLayoutEffect(() => {
    if (props.navParams?.addDocOnMount) {
      onListDocPress(props.navParams.addDocOnMount);
    } else if (
      props.demo &&
      props.modifyDocState &&
      !props.modifyDocState?.demo
    ) {
      props.clearModifyDoc();
    } else if (!props.demo && props.modifyDocState?.demo) {
      props.clearModifyDoc();
    }
  }, []);

  const setState = (value) => {
    dispatchState({
      type: "mergeState",
      value,
    });
  };

  const mergeInnerProp = (prop, payload) => {
    dispatchState({ type: "mergeInnerProp", prop, payload });
  };

  const closeModal = (modal) => () => {
    mergeInnerProp(modal, { isVisible: false });
  };
  const setInputModal = (value) => {
    setStateProp("inputModal", value);
  };

  const setModalPicker = (value) => {
    setStateProp("modalPicker", value);
  };

  const closeModalPicker = () => {
    mergeInnerProp("modalPicker", { visible: false });
  };

  const closePicker = () => {
    setStateProp("picker", { visible: false });
  };
  const setPicker = (value) => {
    setStateProp("picker", value);
  };

  const setFormParam = (prop, value) => {
    if (OS !== "web" || props.noUrlNav) {
      dispatchState({
        type: "setFormParam",
        prop,
        value,
      });
    } else {
      let pathname = props.navigation.location.pathname;
      props.navigation.replace({
        pathname: pathname,
        search: getNavUrlParams(
          {
            [prop]: value,
          },
          formRouteParams
        ),
      });
    }
  };

  const setVisibleData = (key) => {
    setFormParam("visibleData", key);
  };

  const toggleModalIfNotVisible = (modal) => {
    dispatchState({
      type: "toggleModal",
      prop: modal,
    });
  };

  const toggleTextModal = () => {
    if (!state.loading) {
      toggleModalIfNotVisible("textModal");
    }
  };

  const setTextModal = (value) => {
    setStateProp("textModal", value);
  };

  const toggleDatePicker = () => {
    if (!state.loading) {
      toggleModalIfNotVisible("datePicker");
    }
  };

  const setDatePicker = (value) => {
    setStateProp("datePicker", value);
  };

  const toggleAlert = () => {
    if (!state.loading) {
      toggleModalIfNotVisible("alert");
    }
  };

  const setAlert = (value) => {
    setStateProp("alert", value);
  };

  // MOVE TO A REF
  const handleScroll = (event) => {
    if (OS !== "web" || props.noUrlNav) {
      scrollPosition.current = event.nativeEvent.contentOffset.y;
    } else {
      localStorage.setItem("scrollPosition", event.nativeEvent.contentOffset.y);
    }
    // dispatchState({
    //   type: "setFormParam",
    //   prop: "scrollPosition",
    //   value: event.nativeEvent.contentOffset.y,
    // });
  };

  // MOVE TO A REF
  const handleViewableItemsChanged = (_viewableItems) => {
    viewableItems.current = _viewableItems;
    // dispatchState({
    //   type: "setFormParam",
    //   prop: "viewableItems",
    //   value: viewableItems,
    // });
  };

  const getNavUrlParams = (params, newParams = {}) => {
    let _previousSearchParams = {};
    if (OS === "web") {
      const location = props.navigation.location;
      let search;
      if (location.search) search = location.search;
      else search = location.pathname.split("?")[1];
      let searchParams = new URLSearchParams(search);
      _previousSearchParams = Object.fromEntries(searchParams);
    }

    // TODO may still be broken with editor routing
    const obj = { ..._previousSearchParams, ...newParams, ...params };
    delete obj.navigation;
    delete obj.attachments;

    return queryString.stringify(obj);
  };

  const formNavigate = (route, _history, params) => {
    if (formRoutes.includes(route)) {
      if (params.attachments) {
        inputAttachments.current = params.attachments;
      }
      if (OS !== "web" || props.noUrlNav) {
        dispatchState({
          type: "setFormRoute",
          route: route,
          params: params,
          scrollPosition: scrollPosition.current,
          viewableItems: viewableItems.current,
        });
      } else {
        let pathname = props.navigation.location.pathname;
        const searchStartIndex = pathname?.indexOf("?");
        if (searchStartIndex !== -1) {
          pathname = pathname.substring(0, searchStartIndex);
        }
        _history.push({
          pathname: pathname,
          search: getNavUrlParams(params, {
            docId: props.docId,
            route,
          }),
        });
      }
    } else {
      (props.navigateFn || navigate)(route, _history, params);
    }
  };

  const formGoBack = (_navigation, amount) => {
    if (OS !== "web" || props.noUrlNav) {
      dispatchState({
        type: "formGoBack",
        amount,
      });
    } else {
      props.navigation.go(-(amount ?? 1));
    }
  };

  const formReplaceTab = (route, _history, params) => {
    if (formRoutes.includes(route)) {
      if (params.attachments) {
        inputAttachments.current = params.attachments;
      }
      if (OS !== "web" || props.noUrlNav) {
        dispatchState({
          type: "replaceTab",
          route: route,
          params: params,
        });
      } else {
        let pathname = props.navigation.location.pathname;
        _history.replace({
          pathname: pathname,
          search: getNavUrlParams(params, {
            docId: props.docId,
            route,
          }),
        });
      }
    } else {
      (props.navigateFn || navigate)(route, _history, params);
    }
  };

  const onBackButtonPress = () => {
    if (formRoute) {
      formGoBack();
      return true;
    } else {
      return false;
    }
  };

  const onDatePickerConfirm = (
    year,
    month,
    monthIndex,
    day,
    valueKey,
    time,
    closeModal
  ) => {
    if (valueKey) {
      props.MODIFY_VALUE({
        docId: props.docId,
        valueKey,
        value: time
          ? moment(
              `${day}.${monthIndex}.${year}T${time}`,
              "DD.M.YYYYTHH:mm"
            ).format("HH:mm DD.MM.YYYY")
          : `${day}.${monthIndex}.${year}`,
      });
      if (closeModal || (OS !== "web" && !time) || OS === "web") {
        toggleDatePicker();
      }
      //focusNextInput(valueKey);
    }
  };

  let data = useMemo(() => {
    return getData(
      props.options.layouts,
      props.companyId,
      props.lang,
      props.profile.role
    );
  }, [props.options.layouts, props.companyId, props.lang]);

  const onIdChanged = (newDocId) => {
    props.onIdChanged?.(newDocId);
    // update own tab
    props.updateTabConfig?.(
      { id: props.tabId },
      { config: { docId: newDocId, item: { id: newDocId } } }
    );
    // update preview screen tab
    props.updateTabConfig?.(
      { id: "preview" },
      { config: { docId: newDocId } },
      true,
      true
    );
  };

  const handleDocMount = () => {
    if (props.docId && props.doc && props.docLayout) {
      const langValueKey = getPdfLangValueKey(props.profile);

      const langFromDoc = props.doc.values[langValueKey];

      let _lang =
        props.PDFLang || langFromDoc || props.defaultPDFLanguage || props.lang;

      if (
        props.docLayout.languages &&
        !props.docLayout.languages.includes(_lang)
      ) {
        const handleLangPicked = (lang) => {
          let _state = { unfinishedDocs: { [props.doc.id]: props.doc } };
          _state = MODIFY_VALUEFN(_state, {
            payload: {
              docId: props.doc.id,
              valueKey: langValueKey,
              value: lang,
            },
          });
          props.MODIFY_VALUE({
            docId: props.doc.id,
            valueKey: langValueKey,
            value: lang,
          });

          const _docObj = _state.unfinishedDocs[props.doc.id];

          if (!props.demo && (!props.permissions || props.permissions.Update)) {
            props.saveDocToDb({
              doc: _docObj,
              lastDocRequests: props.lastDocRequests,
              onLicenceErr: () => {
                clearDoc();
              },
              forceSave: true,
              options: props.options,
              profile: props.profile,
              company: props.company,
              manager: props.manager,
              t: t,
              setProgress: setProgress,
              lang: props.lang,
              navigation: props.navigation,
              onIdChanged,
              customToken: props.customToken,
              noUrlNav: props.noUrlNav,
              uniqueSite: props.uniqueSite,
              uniqueDate: props.uniqueDate,
              lastDocSaveDate: lastDocSaveDate,
            });
          }
        };

        if (props.docLayout.languages.length === 1) {
          handleLangPicked(props.docLayout.languages[0]);
        } else {
          setState({
            modalPicker: {
              visible: true,
              title: t("docDisplayLangPickerTitle"),
              text: t("PDFCreationLangErrorInfoText"),
              textProp: "title",
              disableClose: true,
              data: props.docLayout.languages?.map((_value) => {
                return {
                  title: _value.toUpperCase(),
                  action: () => {
                    handleLangPicked(_value);
                  },
                };
              }),
            },
          });
        }
      } else if (
        !props.demo &&
        (!props.permissions || props.permissions.Update)
      ) {
        props.saveDocToDb({
          doc: props.doc,
          lastDocRequests: props.lastDocRequests,
          onLicenceErr: () => {
            clearDoc();
          },
          forceSave: true,
          options: props.options,
          profile: props.profile,
          company: props.company,
          manager: props.manager,
          t: t,
          setProgress: setProgress,
          lang: props.lang,
          navigation: props.navigation,
          onIdChanged,
          customToken: props.customToken,
          noUrlNav: props.noUrlNav,
          uniqueSite: props.uniqueSite,
          uniqueDate: props.uniqueDate,
          lastDocSaveDate: lastDocSaveDate,
        });
      }
    }

    mountInProgress.current = false;
  };

  const syncData = async () => {
    let optionsRes;
    if (isSameOrGreaterThanRole(props.profile.role, "Demo")) {
      props.setOptionsChecked();
      optionsRes = await apiRequestWithToken(
        {
          lastModified: props.options.lastModified,
          userLastModified: props.profile?.userLastModified,
        },
        "/options/check"
      );
      props.setOptionsChecked(true);
    } else {
      optionsRes = { data: {} };
    }

    const layoutsRes = await getInitialLayouts(
      props.options?.lastModifiedLayouts,
      undefined,
      props.customToken
    );

    props.updateOptions({ data: optionsRes.data, layoutsData: layoutsRes });
  };

  const handleMount = async () => {
    if (mountInProgress.current) return;
    mountInProgress.current = true;
    const netInfo = await fetchNetInfo();
    if (!netInfo.isConnected) {
      handleDocMount();
      return;
    }

    await syncData();

    handleDocMount();
  };

  // const callCheckOptions = () => {
  //   return;
  //   // const body = {
  //   //   lastModified: props.options.lastModified,
  //   //   userLastModified: props.profile?.userLastModified,
  //   // };
  //   // props.checkOptions(
  //   //   body,
  //   //   props.profile,
  //   //   props.navigation,
  //   //   props.doc,
  //   //   props.options.layouts
  //   // );
  // };

  const setIntegrationParamsToDoc = (_newDoc, _tmpValues) => {
    const docLayout =
      props.options.layouts[_newDoc?.layoutId]?.versions?.[
        _newDoc.layoutVersion
      ];

    const customerValueKey =
      docLayout.specialInputs?.find((x) => x.optionsProp === "customers")
        ?.valueKey || _tmpValues.integrationKey;
    let sitesValueKey =
      docLayout.specialInputs?.find((x) => x.optionsProp === "sites")
        ?.valueKey || _tmpValues.integrationKey;

    const updateObj = {
      [`customers_${customerValueKey}`]: {
        $set: _tmpValues.customer,
      },
      [`sites_${sitesValueKey}`]: {
        $set: _tmpValues.site,
      },
      integrationKey: {
        $set: _tmpValues.integrationKey,
      },
    };
    if (_tmpValues.valuesToAdd) {
      _tmpValues.valuesToAdd.forEach((x) => {
        updateObj[x.prop] = { $set: x.value };
      });
    }
    _newDoc = update(_newDoc, {
      values: updateObj,
    });

    if (_tmpValues.integrationKey) {
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: null,
        deepLinkRoute: null,
        deepLinkParams: null,
        deepLinkScreen: null,
      });
    }

    addDoc(_newDoc);
  };

  const addRegisterObjectsToDoc = async (
    newDoc,
    site,
    customer,
    valuesToAdd,
    integrationKey
  ) => {
    // update local values
    const _modifyOption = (_prop, obj, _id) => {
      props.modifyPickerOption(
        _prop,
        obj,
        props.role,
        _id,
        undefined,
        undefined,
        showToast
      );
    };

    const noCustomer = !customer || Object.values(customer).every((x) => !x);
    const noSite = !site || Object.values(site).every((x) => !x);

    if (!noCustomer) {
      let customerId = customer.id;
      // update the customer with the params
      const existingCustomerId = Object.keys(props.options.customers).find(
        (x) => {
          const _customer = props.options.customers[x];
          return (
            _customer.companyId === customer.companyId &&
            _customer.name === customer.name
          );
        }
      );
      if (!existingCustomerId) {
        const addRes = await apiRequestWithToken(customer, `/customers/add`);
        if (addRes.status === 200) {
          customerId = addRes.data;
        }
      }
      _modifyOption("customers", customer, customerId);
    }

    if (!noSite) {
      let siteId = site.id;
      // match site with address and job number
      const existingSiteId = Object.keys(props.options.sites).find((x) => {
        const _site = props.options.sites[x];
        return (
          _site.address === site.address && _site.jobNumber === site.jobNumber
        );
      });
      if (!existingSiteId) {
        const addRes = await apiRequestWithToken(site, `/sites/add`);
        if (addRes.status === 200) {
          siteId = addRes.data;
        }
      }
      _modifyOption("sites", site, siteId);
    }

    if (noSite && noCustomer) return newDoc;
    setIntegrationParamsToDoc(newDoc, {
      integrationKey,
      customer: customer,
      site: site,
      valuesToAdd,
    });
  };

  const getIntegrationParams = async (_newDoc) => {
    const integrationKey = props.deepLinkRoute;
    const splitParams = props.deepLinkParams.params.split("&");

    if (splitParams) {
      const customerProps = {
        customer_name: "name",
        customer_bid: "companyId",
        customer_address: "address",
        customer_zip: "zipcode",
        customer_city: "city",
        customer_phone: "phone",
        customer_email: "email",
        customer_contactPerson: "contactPerson",
        customer_id: "id",
      };
      const siteProps = {
        site_name: "name",
        site_work_number: "jobNumber",
        site_address: "address",
        site_zip: "zipcode",
        site_city: "city",
        site_description: "desc",
        site_id: "id",
        project_id: "id",
      };

      let customerFromIntegration = {};
      let siteFromIntegration = {};

      for (let i = 0; i < splitParams.length; i++) {
        const x = splitParams[i];
        const splitParam = decodeURIComponent(x).split("=");

        const regex = /\+/g;

        if (customerProps[splitParam[0]]) {
          customerFromIntegration.fromIntegration =
            integrationKey.toUpperCase();
          customerFromIntegration[customerProps[splitParam[0]]] = splitParam[1]
            ? splitParam[1].replace(regex, " ")
            : "";
        } else if (siteProps[splitParam[0]]) {
          siteFromIntegration.fromIntegration = integrationKey.toUpperCase();
          siteFromIntegration[siteProps[splitParam[0]]] = splitParam[1]
            ? splitParam[1].replace(regex, " ")
            : "";
        }
      }

      await addRegisterObjectsToDoc(
        _newDoc,
        siteFromIntegration,
        customerFromIntegration,
        null,
        integrationKey
      );
    }
    // TODO what if split fails
  };

  const onInputModalSave = (id, rest) => {
    if (
      state.inputModal?.item?.optionsProp &&
      state.inputModal?.item?.valueKey
    ) {
      let fileInputs;
      try {
        fileInputs = state.inputModal.inputs.filter(
          (x) => x.type === "filePicker"
        );
      } catch (error) {
        errorReport({
          error,
          errorInFn: "onInputModalSave",
          errorInScreen: "CreateDocScreen",
        });
      }
      if (state.inputModal?.multi) {
        const valueKey =
          state.inputModal.valueKey ??
          `${state.inputModal.item.optionsProp}_${state.inputModal.item.valueKey}`;
        (state.inputModal.replace
          ? replaceObjectArrItem
          : addToObjectArrWithGeneratedId)({
          type: "PickerObj", //capitalizeFirstLetter(state.inputModal.item.optionsProp),
          userId: props.profile.id,
          docId: props.docId,
          valueKey: valueKey,
          value: Object.keys(rest).reduce(
            (prev, cur) => {
              if (cur !== "id") {
                if (fileInputs.every((x) => x.key !== cur)) {
                  prev[cur] = rest[cur];
                }
              }
              return prev;
            },
            { id }
          ),
          oldVal: props.doc.values[valueKey]?.[0],
          idProp: "valueKey",
          sortProp: state.inputModal.item.sortProp,
          state:
            fileInputs.length > 0
              ? {
                  actions: fileInputs.reduce((acc, fileInput) => {
                    if (rest[fileInput.key]) {
                      rest[fileInput.key].forEach((atch) => {
                        acc.push({
                          action: "addToObjectArrWithCurrentValueKey",
                          payload: {
                            type: "Atch",
                            value: {
                              id: atch.id,
                              docRef: atch.docRef,
                              name: atch.name,
                              desc: atch.desc,
                              hideInGallery: atch.hideInGallery,
                              hidePreview: atch.hidePreview,
                            },
                            sortProp: "name",
                            idProp: "id",
                            valueKey: `${state.inputModal.valueKey}_currentValueKey_${fileInput.key}`,
                            docId: props.docId,
                            // docId: props.docId,
                            // valueKey: `${state.inputModal.valueKey}_currentValueKey_${fileInput.key}`,
                            // value: rest[fileInput.key],
                          },
                        });
                      });
                    }
                    return acc;
                  }, []),
                }
              : null,
          distinct: true,
        });
      } else {
        let payload = [
          {
            docId: props.docId,
            valueKey:
              state.inputModal.valueKey ??
              `${state.inputModal.item.optionsProp}_${state.inputModal.item.valueKey}`,
            value: { ...rest, id },
          },
        ];

        const getAdditionalObject = (idProp, optionsProp) => {
          if (rest[idProp]) {
            const obj = props.options[optionsProp][rest[idProp]];
            if (obj) {
              let valueKey = "";
              const specialInput = props.docLayout?.specialInputs?.find(
                (x) => x.optionsProp === optionsProp
              );
              if (specialInput) {
                valueKey = specialInput.valueKey;
              } else if (props.doc.values.integrationKey) {
                valueKey = props.doc.values.integrationKey;
              } else if (rest.fromIntegration) {
                valueKey = rest.fromIntegration.toLowerCase();
                payload.push({
                  docId: props.docId,
                  valueKey: "integrationKey",
                  value: valueKey,
                });
              }

              if (valueKey) {
                payload.push({
                  docId: props.docId,
                  valueKey: `${optionsProp}_${valueKey}`,
                  value: { ...obj },
                });
              }
            }
          }
        };

        getAdditionalObject("siteId", "sites");
        getAdditionalObject("customerId", "customers");

        props.MODIFY_VALUE(payload);
      }
    }
  };

  const onInputModalRemove = (id) => {
    if (
      state.inputModal?.item?.optionsProp &&
      state.inputModal?.item?.valueKey
    ) {
      if (state.inputModal?.multi) {
        /* empty */
      } else {
        props.MODIFY_VALUE({
          docId: props.docId,
          valueKey: `${state.inputModal.item.optionsProp}_${state.inputModal.item.valueKey}`,
          value: { id },
          oldVal: { id },
        });
      }
    }
  };

  const handleDocAdded = (_newDoc, demo) => {
    props.addDoc(_newDoc);
    setState({
      creatingDoc: null,
      paramsToAddToNewDoc: null,
    });
    saveDoc({
      docObj: _newDoc,
      forceSave: true,
      fn: (newDocId) => {
        const docId = newDocId || _newDoc.id;
        if (!props.onDocAdded && !demo && OS === "web") {
          navigate(
            "create?docId=" + encodeURIComponent(docId),
            props.navigation,
            {
              prevPathname: props.navParams?.prevPathname,
            }
          );
        } else {
          if (props.onDocAdded) {
            props.onDocAdded({ ..._newDoc, id: docId }, props.tabConfig);
          } else {
            props.modifyDoc({ id: docId, demo });
          }
        }
      },
    });
  };

  const addDoc = (_newDoc) => {
    let tmpDoc = _newDoc;
    let syncObj = SYNC_VALUES_TIMESTAMPS_FN(props.profile.id, tmpDoc, true);
    tmpDoc = update(tmpDoc, {
      timestamps: (x) => update(x || {}, syncObj.timestamps),
    });
    if (props.navParams?.docProps) {
      tmpDoc = { ...tmpDoc, ...props.navParams.docProps };
    }
    if (props.demo || tmpDoc.demo || tmpDoc.id.startsWith("doc_demo")) {
      handleDocAdded(tmpDoc, true);
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          handleDocAdded(tmpDoc);
        } else {
          showToast(t("docSavedLocally"), 3000, "accent");
          handleDocAdded(tmpDoc);
        }
      });
    }
  };

  const onFileSignatureRequestPress = (id) => {
    if (props.profile.role === "Trial") {
      showToast(t("demoFeatureDisabled"));
    } else {
      onListDocPress(id);
    }
  };
  const onListDocPress = (id) => {
    if (
      permissionCheck(
        companyWidePermissions[props.companyId],
        ["docs"],
        props.profile?.role,
        ["create"]
      )
    ) {
      setStateProp("creatingDoc", id);
    } else {
      showToast(t("noPermissionToCreateDocs"));
    }
  };

  const createDoc = async (id) => {
    const isTrial = props.profile.role === "Trial" ? true : false;
    const unfinishedDocsLength = Object.keys(props.unfinishedDocs).length;

    const newId = generateLocalDocId();

    let _newDoc = {
      id: newId,
      technician: props.technician,
      date: new moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
    };

    if (
      !props.demo &&
      isTrial &&
      (props.profile.completedDocsCount >= 2 ||
        unfinishedDocsLength >= 2 ||
        props.profile.completedDocsCount + unfinishedDocsLength >= 2)
    ) {
      if (
        props.profile.completedDocsCount < 3 &&
        unfinishedDocsLength < 3 &&
        props.profile.completedDocsCount + unfinishedDocsLength < 3
      ) {
        showToast(t("trialSaveDocLimit"), 5000);
      }
      _newDoc.demo = true;
    }

    const _baseDoc = props.options.layouts[id];
    const newestVersionID = Math.max(...Object.keys(_baseDoc.versions));
    const docLayout = _baseDoc.versions[newestVersionID];

    let _values = {};

    if (docLayout.specialInputs) {
      for (let i = 0; i < docLayout.specialInputs.length; i++) {
        const specialInput = docLayout.specialInputs[i];
        // TODO does this work
        if (specialInput.type === "measurementObjects") {
          const measObj =
            props.options.layouts?.[specialInput.layoutId]?.versions?.[
              specialInput.layoutVersion
            ];

          measObj?.presetObjects?.forEach((presetObject) => {
            let newValueKey = genNewValKey(
              (_newDoc?.currentValueKey ?? 0) + 1,
              props.profile.id
            );

            _values = update(_values, {
              [specialInput.layoutId]: (tmpMeasObjValues) =>
                update(tmpMeasObjValues || [], {
                  $push: [
                    update(presetObject, {
                      valueKey: { $set: `${newValueKey}` },
                    }),
                  ],
                }),
            });

            _newDoc = update(_newDoc, {
              currentValueKey: {
                $set: (_newDoc?.currentValueKey ?? 0) + 1,
              },
            });
          });
        } else {
          // place pickerObjects from navParams into doc values
          const paramsToAddToNewDoc =
            props.paramsToAddToNewDoc ||
            (OS !== "web"
              ? state.paramsToAddToNewDoc
              : props.navParams?.paramsToAddToNewDoc);
          if (paramsToAddToNewDoc) {
            if (paramsToAddToNewDoc.projectId) {
              _newDoc.projectId = paramsToAddToNewDoc.projectId;
            }
            if (paramsToAddToNewDoc.pickerObjects) {
              const optionsProps = Object.keys(
                paramsToAddToNewDoc.pickerObjects
              );

              for (let j = 0; j < optionsProps.length; j++) {
                const optionsProp = optionsProps[j];
                const splitOptionsProp = optionsProp.split("_");
                let _optionsProp = splitOptionsProp[0];
                if (!_optionsProp.endsWith("s")) _optionsProp += "s";

                if (
                  specialInput.optionsProp === _optionsProp &&
                  (splitOptionsProp.length > 1
                    ? splitOptionsProp[1] === specialInput.valueKey
                    : true)
                ) {
                  const pickerObjectId =
                    paramsToAddToNewDoc.pickerObjects[optionsProp];
                  let value;
                  if (props.profile.role === "Trial" || !state.connected) {
                    value =
                      props.options[specialInput.optionsProp]?.[pickerObjectId];
                  } else {
                    const valueFromDb = await getWithToken("/resource", null, {
                      id: pickerObjectId,
                      ref: 4,
                    });

                    if (valueFromDb.status === 200) {
                      value = valueFromDb.data;
                    } else {
                      value =
                        props.options[specialInput.optionsProp]?.[
                          pickerObjectId
                        ];
                    }
                  }

                  if (value) {
                    const valueKey = `${specialInput.optionsProp}_${
                      splitOptionsProp[1] || specialInput.valueKey
                    }`;

                    _values = update(_values, {
                      [valueKey]: {
                        $set: value,
                      },
                    });
                  }
                }
              }
            }
          }
        }
      }
    }

    _newDoc = {
      creatorId: props.profile.id,
      type: _baseDoc.type,
      layoutId: id,
      layoutVersion: newestVersionID,
      values: _values,
      status: 0,
      //values: mockMsObjects,
      creatorSignature: docLayout.creatorSignature
        ? {
            ...docLayout.creatorSignature,
            authMethod: docLayout.creatorSignature.authMethod || "EIdentify",
            email: props.profile.email,
          }
        : null,
      signatures: docLayout.signatures || [],
      // signatures: mockSignatures,
      // crossplatform save merge check fails if this is removed
      customStatus: docLayout.statuses
        ? docLayout.statuses[0]
        : {
            key: "",
            title: { all: "" },
          },
      ..._newDoc,
    };

    // TODO SYNC TIMESTAMPS AFTER ALL VALUES HAVE BEEN ADDED
    if (props.navParams?.pathToAdd) {
      _newDoc.path = props.navParams.pathToAdd;
      if (OS !== "web") {
        props.navigation.setParams({
          pathToAdd: null,
        });
      } else {
        let state = { ...props.navigation.location.state };
        delete state.pathToAdd;
        props.navigation.replace({ ...props.navigation.location, state });
      }
    } else if (props.deepLinkParams?.pathToAdd) {
      _newDoc.path = props.deepLinkParams.pathToAdd;
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: null,
        deepLinkRoute: null,
        deepLinkParams: null,
        deepLinkScreen: null,
      });
    } else if (props.pathToAdd) {
      _newDoc.path = props.pathToAdd;
    }

    if (
      (props.deepLinkRoute === "ecom" || props.deepLinkRoute === "fondion") &&
      props.deepLinkParams
    ) {
      await getIntegrationParams(_newDoc);
    } else if (props.navParams?.site || props.navParams?.customer) {
      await addRegisterObjectsToDoc(
        _newDoc,
        props.navParams.site,
        props.navParams.customer,
        props.navParams.valuesToAdd,
        "nav"
      );
    } else {
      addDoc(_newDoc);
    }
  };

  const clearDoc = () => {
    if (props.onCloseDoc) {
      props.onCloseDoc(props.tabId);
    } else {
      props.clearModifyDoc();
      let _route;
      if (props.navParams?.prevPathname) {
        _route = props.navParams.prevPathname;
        if (_route.startsWith("/")) _route = _route.substring(1);
        if (_route.endsWith("/")) _route = _route.slice(0, -1);
      } else {
        _route = "browse";
      }
      if (OS === "web") {
        (props.replaceTabFn || replaceTab)(_route, props.navigation);
      } else {
        (props.navigateFn || navigate)(_route, props.navigation);
      }
      // else {
      //   (props.navigateFn || navigate)("browse", props.navigation);
      // }
    }
  };

  const mergeDoc = () => {
    props.saveDocToDb({
      doc: props.doc,
      lastDocRequests: null,
      forceSave: true,
      options: props.options,
      profile: props.profile,
      company: props.company,
      manager: props.manager,
      t: t,
      setProgress: setProgress,
      lang: props.lang,
      navigation: props.navigation,
      onIdChanged,
      customToken: props.customToken,
      noUrlNav: props.noUrlNav,
      uniqueSite: props.uniqueSite,
      uniqueDate: props.uniqueDate,
      lastDocSaveDate: lastDocSaveDate,
    });
  };

  const setProgress = (payload) => {
    setState({ progress: payload });
  };

  const onSaveDocPress = () => {
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        saveDoc({});
      } else {
        showToast(t("checkInternetConnection"));
      }
    });
  };

  const saveDoc = ({ calledOnClose, fn, forceSave, docObj }) => {
    if (
      !props.demo &&
      !props.viewUnfinishedLayout &&
      (!props.permissions || props.permissions.Update)
    ) {
      const _docObj = docObj || props.doc;
      props.saveDocToDb({
        doc: _docObj,
        //lastDocRequests: props.lastDocRequests,
        fn,
        forceSave,
        options: props.options,
        profile: props.profile,
        company: props.company,
        manager: props.manager,
        t: t,
        setProgress: setProgress,
        lang: props.lang,
        navigation: props.navigation,
        onIdChanged,
        customToken: props.customToken,
        callback: (doc, docWasModified, error) => {
          // if calledOnClose we can allow closing the doc
          if (calledOnClose && error === "licenceErr") {
            fn?.();
          }
        },
        noUrlNav: props.noUrlNav,
        uniqueSite: props.uniqueSite,
        uniqueDate: props.uniqueDate,
        lastDocSaveDate: lastDocSaveDate,
      });
    } else {
      if (fn) fn();
    }
  };

  const closeDoc = () => {
    saveDoc({ calledOnClose: true, fn: clearDoc, forceSave: true });
  };

  const saveText = (text) => {
    props.MODIFY_VALUE({
      docId: props.docId,
      valueKey: state.textModal.valueKey,
      value: text,
      valueKeys: state.textModal.getMultiEditValueKeys
        ? state.textModal.getMultiEditValueKeys(state.textModal.valueKey)
        : null,
    });
    toggleModalIfNotVisible("textModal");
  };

  const handleConnection = (method, payload, offlineDocFn) => {
    offlineDocFn(payload);
  };

  const addToObjectArrWithGeneratedId = (payload) => {
    handleConnection("AddToObjectArrWithGeneratedId", payload, () => {
      props.ADD_TO_OBJECT_ARR_WITH_GENERATED_ID(payload);
      // TODO genNewValKey doesn't work anymore with nanoid, need to handle in reducer
      handleExtraAction(props.docId, {
        ...payload,
        lang: props.lang,
        currentValueKey: genNewValKey(
          (props.doc.currentValueKey ?? 0) + 1,
          props.profile.id
        ),
      });
    });
  };

  const renameDocRequest = (doc, name) => {
    if (name) {
      if (
        props.profile.role === "Trial" ||
        props.demo ||
        props.viewUnfinishedLayout
      ) {
        props.setDocProp({
          docId: doc.id,
          prop: "name",
          value: name,
        });
      } else {
        // TODO handle completedDocs etc.
        putWithToken({ id: doc.id, name }, "/docs/rename").then((res) => {
          if (res.status === 204) {
            props.setDocProp({
              docId: doc.id,
              prop: "name",
              value: name,
            });
          } else {
            errorReport({
              error: res.status,
              errorInFn: "renameDoc",
              errorInScreen: "FolderBrowse",
              errorParams: {
                id: doc.id,
                company: props.company?.id,
              },
            });
          }
        });
      }
    }
  };

  const replaceObjectArrItem = (payload) => {
    if (formRouteParams?.pdfOverride) {
      renameDocRequest(props.doc, payload.value.name);
    }
    handleConnection(
      "ReplaceObjectArrItem",
      payload,
      props.REPLACE_OBJECT_ARR_ITEM
    );
  };

  const addToObjectArr = (payload) => {
    handleConnection("AddToObjectArr", payload, () => {
      props.ADD_TO_OBJECT_ARR(payload);
      // TODO genNewValKey doesn't work anymore with nanoid, need to handle in reducer
      handleExtraAction(props.docId, {
        ...payload,
        currentValueKey: genNewValKey(
          (props.doc.currentValueKey ?? 0) + 1,
          props.profile.id
        ),
      });
    });
  };

  const handleStateAction = (docId, payload, action) => {
    if (action.action === "modifyValueWithCurrentValueKey") {
      props.MODIFY_VALUE({
        ...action.payload,
        valueKey: action.payload.valueKey.replace(
          "currentValueKey",
          payload.currentValueKey
        ),
      });
    } else if (action.action === "addToObjectArrWithCurrentValueKey") {
      addToObjectArr({
        ...action.payload,
        valueKey: action.payload.valueKey.replace(
          "currentValueKey",
          payload.currentValueKey
        ),
      });
    } else if (action.action === "modifyValue") {
      props.MODIFY_VALUE(action.payload);
    } else {
      throw "No handler for action: " + action.action;
    }
  };
  const handleExtraAction = (docId, payload) => {
    if (payload.state) {
      if (payload.state.actions) {
        payload.state.actions.forEach((action) => {
          handleStateAction(docId, payload, action);
        });
      }
    }
  };

  const shareToUsers = (users, global) => {
    let newSharedTo = global
      ? props.options.users.reduce((prev, cur) => {
          if (cur.email) {
            prev.push(cur.id);
          }
          return prev;
        }, [])
      : [...users.map((user) => user.id), props.profile.id];

    putWithToken(
      { id: props.doc.id, users: newSharedTo },
      "/docs/shareToUsers"
    ).then((res) => {
      if (res.status === 204) {
        props.addDoc({
          ...props.doc,
          sharedTo: newSharedTo,
        });
        toggleModalIfNotVisible("modalPicker");
      } else {
        showToast(t("shareErr"));
      }
    });
  };

  const markAsFilled = (id) => {
    setStateProp("markingAsFilled", id);
  };

  useEffect(() => {
    handleMount();
    let unsubscribeNetworkListener;
    if (OS !== "web") {
      unsubscribeNetworkListener = setNetInfoListener((state) => {
        setStateProp("connected", state.isConnected);
      });
    }
    return () => {
      if (OS !== "web") {
        unsubscribeNetworkListener();
      } else {
        // clean up webs scroll position
        localStorage.setItem("scrollPosition", 0);
      }
    };
  }, []);

  useEffect(() => {
    if (state.creatingDoc) {
      createDoc(state.creatingDoc);
    }
  }, [state.creatingDoc]);

  useEffect(() => {
    if (Platform.OS !== "web" && prevDocId.current !== props.docId) {
      prevDocId.current = props.docId;

      // handle mount in mobile in here because the bottom tab stays mounted
      // only do mount logic again if docId has changed
      if (props.docId) handleMount();
    }
  }, [props.docId]);

  const handleFocus = (_docId, _navParams) => {
    syncData();
    if (_navParams?.docId && _docId !== _navParams.docId) {
      props.modifyDoc(_navParams.docId);
      props.navigation.setParams({
        docId: null,
      });
    } else if (_navParams?.createNew) {
      props.clearModifyDoc();
      props.navigation.setParams({
        createNew: null,
      });
    }
    if (_navParams?.paramsToAddToNewDoc) {
      setState({
        paramsToAddToNewDoc: _navParams?.paramsToAddToNewDoc,
      });
      props.navigation.setParams({
        paramsToAddToNewDoc: null,
      });
    }
  };

  useEffect(() => {
    let unsubscribeFocusListener;
    if (OS !== "web") {
      unsubscribeFocusListener = setNavigationListener(
        props.navigation,
        "focus",
        () => handleFocus(props.docId, props.navParams)
      );
    }
    return () => {
      if (OS !== "web") {
        unsubscribeFocusListener();
      }
    };
  }, [props.docId, props.navParams]);

  useEffect(() => {
    if (props.navParams?.reason === "completeDoc") {
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: props.navParams.reason,
        deepLinkRoute: props.navParams.reason,
        deepLinkParams: props.navParams,
        deepLinkScreen: "PreviewAndSign",
      });
    }
    let backHandler;
    if (OS !== "web") {
      backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackButtonPress
      );
    }
    return () => {
      if (OS !== "web") {
        backHandler.remove();
      }
    };
  }, [state.formRoute]);

  useEffect(() => {
    if (props.deepLinkParams) {
      if (props.deepLinkRoute === "ecom") {
        showToast(t("ecomParamsToBeSavedToNextDoc"), 8000, "accent");
      } else if (props.deepLinkRoute === "fondion") {
        verifyFondionParams();
      }
    }
  }, [props.deepLinkRoute, props.deepLinkParams]);

  const verifyFondionParams = async () => {
    if (props.uiSettings?.skipIntegrationTenantVerify) {
      showToast(t("fondionParamsToBeSavedToNextDoc"), 5000, "accent");
      return;
    }
    const splitParams = props.deepLinkParams.params.split("&");
    for (let i = 0; i < splitParams.length; i++) {
      const x = splitParams[i];
      const splitParam = decodeURIComponent(x).split("=");
      const regex = /\+/g;

      if (splitParam[0] === "tenant_id") {
        const tenantId = splitParam[1].replace(regex, " ");

        const tenantVerifyRes = await getWithToken(
          "/company/verifyIntegrationTenantId",
          null,
          { tenantId, provider: props.deepLinkRoute }
        );

        if (tenantVerifyRes.status === 204) {
          showToast(t("fondionParamsToBeSavedToNextDoc"), 5000, "accent");
          return;
        } else {
          showToast(t("fondionIntegrationTenantIdNotFound"), 10000, "red");
          props.SET_DEEP_LINK_PARAMS({
            deepLinkPath: null,
            deepLinkRoute: null,
            deepLinkParams: null,
            deepLinkScreen: null,
          });
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (state.markingAsFilled) {
      apiRequestWithToken({ id: state.markingAsFilled }, "/docs/markAsFilled")
        .then((res) => {
          if (res.status === 204) {
            showToast(t("docMarkedAsCompleted"), 3000, "green");
            props.REMOVE_DOCS({
              docIds: [state.markingAsFilled],
            });
            setStateProp("markingAsFilled", false);
            clearDoc();
          } else {
            showToast(t("markingAsCompletedFailed"));
            setStateProp("markingAsFilled", false);
          }
        })
        .catch(() => setStateProp("markingAsFilled", false));
    }
  }, [state.markingAsFilled]);

  const noVisibleModals = !(
    state.textModal?.visible ||
    state.datePicker?.visible ||
    state.taskInputs?.visible ||
    state.modalPicker?.visible ||
    state.inputModal?.visible ||
    state.picker?.visible ||
    state.modalForm?.visible ||
    state.shareModal?.isVisible
  );

  useEffect(() => {
    if (noVisibleModals && state.setModal) {
      setState({
        setModal: null,
        [state.setModal.modal]: state.setModal.props,
      });
    }
  }, [noVisibleModals, state.setModal]);

  const templateStringSources = useMemo(() => {
    if (props.docLayout) {
      let _values = props.doc?.values || {};

      const specialInputsProps = getSpecialInputsProps(
        _values,
        props.docLayout,
        props.options,
        props.lang
      );

      _values = specialInputsProps._values;
      const worstValues = specialInputsProps._worstValues;

      const mergedSources = mergeSources(props.docLayout, props.options);

      return (
        replaceAlternateSources(mergedSources, {
          ...props,
          doc: {
            ...props.doc,
            values: _values,
          },
          worstValues,
        }) || {}
      );
    } else {
      return {};
    }
    // TODO why does this change when doc changes
  }, [props.doc, props.docLayout, props.options, props.lang]);

  const getContent = (
    type,
    docType,
    createDoc,
    navigation,
    theme,
    colors,
    orientation,
    logo
  ) => {
    const urlStart = props.urlStart;

    const propsToSend = {
      formRoute: state.formRoute,
      navigation,
      logo,
      shareToUsers,
      mergeInnerProp,
      setInputModal,
      inputModal: state.inputModal,
      SET_GPS_LOCATION: props.SET_GPS_LOCATION,
      setDocProp: props.setDocProp,
      signOut: props.signOut,
      REFRESH_PROJECTS: props.REFRESH_PROJECTS,
      closeDoc: closeDoc,
      clearModifyDoc: props.clearModifyDoc,
      connected: state.connected,
      editors: state.editors,
      screenToGoBackTo: urlStart,
      viewingCompletedDoc: props.viewingCompletedDoc,
      viewUnfinishedLayout: props.viewUnfinishedLayout,
      options: props.options,
      docId: props.docId,
      docLayout: props.docLayout,
      docLayoutType: props.docLayoutType,
      projects: props.projects,
      templateStringSources: templateStringSources,
    };
    const formParams = {
      theme,
      colors,
      t,
      // screen params
      uiSettings: props.uiSettings,
      scrollPosition:
        OS !== "web" || props.noUrlNav
          ? formRouteParams?.scrollPosition
          : localStorage.getItem("scrollPosition"),
      viewableItems: formRouteParams?.viewableItems,
      urlStart: urlStart,
      connectingSession: state.connectingSession,
      visibleData: formRouteParams?.visibleData,
      visibleExtraRowsData: formRouteParams?.visibleExtraRowsData,
      markingAsFilled: state.markingAsFilled,
      // screen fns
      handleScroll: handleScroll,
      handleViewableItemsChanged,
      saveDoc: saveDoc,
      setAlert: setAlert,
      toggleAlert: toggleAlert,
      toggleDatePicker: toggleDatePicker,
      setDatePicker: setDatePicker,
      setVisible: setVisibleData,
      setTextModal: setTextModal,
      navigate: formNavigate,
      goBack: formGoBack,
      replaceTab: formReplaceTab,
      setInputModal: setInputModal,
      markAsFilled: markAsFilled,
      setModalPicker: setModalPicker,
      closeModalPicker: closeModalPicker,
      setPicker: setPicker,
      closePicker: closePicker,
      // props params
      docToModify: props.doc,
      pageH: props.pageH,
      pageW: props.pageW,
      lang: props.lang,
      profile: props.profile,
      company: props.company,
      editorRole: props.editorRole,
      isFetching: props.isFetching,
      lastDocRequests: props.lastDocRequests,
      gpsLocation: props.gpsLocation,
      modifyValueItem: props.modifyValueItem,
      fullHeight: fullHeight,
      fullWidth: fullWidth,
      demo: props.demo, // TODO add props.viewUnfinishedLayout here?
      viewUnfinishedLayout: props.viewUnfinishedLayout,
      layoutId: formRouteParams?.layoutId ?? props.layoutId,
      // props fns
      SET_USER_PROP: props.SET_USER_PROP,
      // signalR fns
      modifyObjectArrItem: props.MODIFY_OBJECT_ARR_ITEM,
      deleteFromObjectArr: props.REMOVE_OBJECT_ARR_ITEM,
      replaceObjectArrItem: replaceObjectArrItem,
      addToObjectArr: addToObjectArr,
      addToObjectArrWithGeneratedId: addToObjectArrWithGeneratedId,
      addMultipleToObjectArrWithGeneratedId:
        props.ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID,
      deleteFromStringArr: props.DELETE_FROM_STRING_ARR,
      deleteMultipleFromStringArr: props.DELETE_MULTIPLE_FROM_STRING_ARR,
      modifyStringArr: props.MODIFY_STRING_ARR,
      addToStringArr: props.ADD_TO_STRING_ARR,
      modifyValue: props.MODIFY_VALUE,
      // form params
      scrollToValueKeys: formRouteParams?.scrollToValueKeys,
    };

    if (
      !docType &&
      !type /*|| (props.deepLinkScreen && props.deepLinkRoute)*/ &&
      !props.viewUnfinishedLayout
    ) {
      if (props.navParams?.addDocOnMount) {
        return (
          <ProgressInfo
            pageH={props.pageH}
            pageW={props.pageW}
            msg={t(props.navParams.progressMsg || "creatingDoc")}
          />
        );
      }
      const _width = props.width ?? (props.pageW ?? fullWp(100)) * 0.8;
      return (
        <View style={theme.flex}>
          <ListPicker
            t={t}
            setValue={onListDocPress}
            data={data}
            refreshing={state.loadingLayouts}
            lang={props.lang}
            globalLogo={logos.fondionIcon}
            theme={theme}
            colors={colors}
            itemSpinner={state.creatingDoc}
            title={t("chooseDocToAdd")}
            width={_width}
            role={props.profile?.role}
            languageCodes={props.languageCodes}
            db={props.company?.id}
            headerItem={
              props.disableFileSigning ? null : (
                <ListPickerItem
                  theme={theme}
                  colors={colors}
                  title={t("sendFileSignatureRequest")}
                  item="docLayouts/0"
                  itemSpinner={state.creatingDoc}
                  startLogo={logos.fondionIcon}
                  icon={"lead-pencil"}
                  setValue={onFileSignatureRequestPress}
                />
              )
            }
          />
        </View>
      );
    } else if (formRoute === "measurementObjects") {
      return (
        <MeasurementObjectsScreen
          {...formRouteParams}
          {...formParams}
          measurementObjectsOpenByDefault={
            props.measurementObjectsOpenByDefault
          }
          options={props.options}
          removeMeasObjects={props.removeMeasObjects}
          addMeasurement={props.addMeasurement}
          removeModularMeasurement={props.removeModularMeasurement}
          addMeasurementObjConnection={props.ADD_MEASUREMENT_OBJ_CONNECTION}
          removeMeasurementObjConnection={
            props.REMOVE_MEASUREMENT_OBJ_CONNECTION
          }
          toggleMeasurementPerGroup={props.toggleMeasurementPerGroup}
          docId={props.docId}
          docLayout={props.docLayout}
          screenToGoBackTo={props.urlStart}
          navigation={props.navigation}
        />
      );
    } else if (formRoute === "modularItems") {
      const modularItem =
        getReduxLayout(
          props.options,
          formParams.layoutId,
          formRouteParams?.layoutVersion
        ) || {};
      return (
        <ModularItemScreen
          {...formRouteParams}
          {...formParams}
          modularItemsOpenByDefault={props.modularItemsOpenByDefault}
          docId={props.docId}
          docLayout={props.docLayout}
          modularItem={modularItem}
          title={modularItem.title}
          addItemTitle={modularItem.addItemTitle}
          addInnerItemTitle={modularItem.addInnerItemTitle}
          modifyObjectArrInnerItem={props.MODIFY_OBJECT_ARR_INNER_ITEM}
          removeObjectArrInnerItem={props.REMOVE_OBJECT_ARR_INNER_ITEM}
          removeMultipleInnerItemsFromMultipleObjectArrays={
            props.REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS
          }
          addToObjectArrInnerItemsWithGeneratedId={
            props.ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID
          }
          addInnerItemToMultipleObjectArraysWithGeneratedId={
            props.ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID
          }
          removeModularItems={props.removeModularItems}
          options={props.options}
          screenToGoBackTo={props.urlStart}
          navigation={props.navigation}
        />
      );
    } else if (formRoute === "addAttachments") {
      return (
        <AddAttachmentsScreen
          layoutId={formParams.layoutId}
          isConnected={state.connected}
          docId={props.docId}
          docToModify={props.doc}
          values={props.doc.values}
          offlineAtch={
            props.profile.role === "Trial" ||
            formRouteParams.offlineAtch === true
          }
          hidePreview={formRouteParams.hidePreview === true}
          screenToGoBackTo="create"
          navAttachments={inputAttachments.current}
          fromDocumentScanner={formRouteParams.fromDocumentScanner === true}
          single={formRouteParams.single === true}
          saveOnMount={
            props.saveAttachmentsOnMount || formRouteParams.saveOnMount === true
          }
          pdfOverride={formRouteParams.pdfOverride === true}
          hideInGallery={formRouteParams.hideInGallery === true}
          requiredProps={formRouteParams.requiredProps}
          valueKey={formRouteParams?.valueKey}
          modifyId={formRouteParams?.modifyId}
          replaceObjectArrItem={replaceObjectArrItem}
          addToObjectArr={addToObjectArr}
          addToObjectArrWithGeneratedId={addToObjectArrWithGeneratedId}
          navigate={formNavigate}
          goBack={formGoBack}
          pageW={props.pageW}
          pageH={props.pageH}
          fullHeight={fullHeight}
          fullWidth={fullWidth}
          modifyValueItem={props.modifyValueItem}
          demo={props.demo} // TODO add props.viewUnfinishedLayout here?
          profile={props.profile}
          cancelButtonTitle={formRouteParams?.modifyId ? "close" : "cancel"}
          relations={props.relations}
          customToken={props.customToken}
        />
      );
    } else if (formRoute === "attachmentsGallery") {
      return (
        <AttachmentsGalleryScreen
          layoutId={formParams.layoutId}
          valueKey={formRouteParams?.valueKey}
          offlineAtch={formRouteParams.offlineAtch === true}
          hidePreview={formRouteParams.hidePreview === true}
          onlyImages={formRouteParams.onlyImages === true}
          single={formRouteParams.single === true}
          allowedTypes={
            typeof formRouteParams.allowedTypes === "string"
              ? formRouteParams.allowedTypes?.split(",")
              : formRouteParams.allowedTypes
          }
          standAlone={true}
          values={props.doc?.values || {}}
          replaceObjectArrItem={replaceObjectArrItem}
          addToObjectArr={addToObjectArr}
          navigate={formNavigate}
          goBack={formGoBack}
          pageW={props.pageW}
          pageH={props.pageH}
          fullHeight={fullHeight}
          fullWidth={fullWidth}
          modifyValueItem={props.modifyValueItem}
          docId={props.docId}
        />
      );
    } else if (type || formRoute === "rows") {
      if (!props.docLayout)
        return (
          <View style={theme.padding32}>
            <Text style={theme.title}>{t("missingDocLayout")}</Text>
          </View>
        );
      return (
        <ModularDoc
          testID={"Modular"}
          {...propsToSend}
          {...formParams}
          t={t}
          addDoc={props.addDoc}
          fetchingDoc={false}
          mergeDoc={mergeDoc}
          SYNC_VALUES_TIMESTAMPS={props.SYNC_VALUES_TIMESTAMPS}
          onSaveDocPress={onSaveDocPress}
          dispatchState={dispatchState}
          goBack={formGoBack}
          saveDocToDb={props.saveDocToDb}
          lastDocSaveDate={lastDocSaveDate}
          manager={props.manager}
          onlySectionWithValueKey={
            formRoute === "rows" ? formRouteParams.valueKey : undefined
          }
          customToken={props.customToken}
          permissions={props.permissions}
          setState={setState}
          getLeftButton={props.getLeftButton}
          hideSite={props.hideSite}
          hideCustomer={props.hideCustomer}
          hideNameInput={props.hideNameInput}
          disabled={props.disabled}
          updateOnValueKeysChanged={props.updateOnValueKeysChanged}
        />
      );
    }
  };

  return (
    <>
      {OS !== "web" ? (
        !state.connected ? (
          <TouchableOpacity
            onPress={() => showToast(t("internetConnectionErrSaveInfo"))}
          >
            <View
              style={[
                {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 8,
                  backgroundColor: colors.error,
                  width: fullWp(100),
                },
              ]}
            >
              <Image
                source={logo}
                resizeMode={"contain"}
                style={{ height: 36, width: 150 }}
              />
              <Icon name="alert" size={24} color={colors.accent} />
            </View>
          </TouchableOpacity>
        ) : !props.docType && !props.type && OS !== "web" ? (
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
                backgroundColor: colors.primary,
                width: fullWp(100),
              },
            ]}
          >
            <Image
              source={logo}
              resizeMode={"contain"}
              style={{ height: 36, width: 150 }}
            />
          </View>
        ) : null
      ) : null}
      <View style={theme.createContainer}>
        {getContent(
          props.type,
          props.docType,
          createDoc,
          props.navigation,
          theme,
          colors,
          orientation,
          logo,
          logos.fondionIcon
        )}
        {props.onClose &&
        !props.docType &&
        !props.type /*|| (props.deepLinkScreen && props.deepLinkRoute)*/ &&
        !props.viewUnfinishedLayout ? (
          <View style={theme.buttonContainer}>
            <StretchButton
              backgroundColor={colors.secondary}
              color={colors.text}
              title={t("close")}
              onPress={props.onClose}
            />
          </View>
        ) : null}
        {state.progress?.msg ? (
          <Modal
            {...modalPickerOpts}
            isVisible={true}
            backdropColor={colors.borderLighter}
            backdropOpacity={0.6}
          >
            <ProgressInfo
              pageH={fullHeight}
              pageW={fullWidth}
              msg={t(state.progress.msg || "loading")}
              progress={state.progress.progress}
              multiPartProgress={state.progress.multiPartProgress}
            />
          </Modal>
        ) : null}
        {state.inputModal.inputs ? (
          <InputPicker
            {...props.options}
            options={props.options}
            pageH={OS === "web" ? props.fullHeight : fullHp(100)}
            pageW={OS === "web" ? props.fullWidth : fullWp(100)}
            fullHeight={OS === "web" ? props.fullHeight : fullHp(100)}
            fullWidth={OS === "web" ? props.fullWidth : fullWp(100)}
            doc={props.doc}
            values={props.doc?.values || {}}
            role={props.profile.role}
            docId={props.docId}
            lang={props.lang}
            identifier={state.inputModal.identifier}
            title={state.inputModal.title}
            prop={state.inputModal.item.optionsProp}
            titleProp={state.inputModal.item.prop}
            inputs={state.inputModal.inputs}
            visible={state.inputModal.visible}
            currentId={state.inputModal.id}
            inputsOnly={state.inputModal.inputsOnly}
            addOnSave={state.inputModal.addOnSave}
            isFetching={false}
            multi={state.inputModal.multi}
            extraActions={state.inputModal.extraActions}
            onlineOnly={state.inputModal.onlineOnly}
            closeOnSave={state.inputModal.closeOnSave}
            onSave={state.inputModal.onSave || onInputModalSave}
            onRemove={onInputModalRemove}
            onToggleModal={() =>
              setInputModal({ ...state.inputModal, visible: false })
            }
            refreshPickerObjOptions={props.refreshPickerObjOptions}
            removePickerObjOption={props.removePickerObjOption}
            addPickerObjOption={props.addPickerObjOption}
            modifyPickerOption={props.modifyPickerOption}
            REFRESH_DOCS={props.REFRESH_DOCS}
            setDocProp={props.setDocProp}
            profile={props.profile}
            gpsLocation={props.gpsLocation}
            SET_GPS_LOCATION={props.SET_GPS_LOCATION}
            disableRemoval={
              props.company?.settings?.disablePickerObjectsEditingInDoc
            }
            disableModifying={
              props.company?.settings?.disablePickerObjectsEditingInDoc
            }
          />
        ) : null}
        {props.doc ? (
          <>
            <Alert {...state.alert} toggleAlert={toggleAlert} />
            <MonthPicker
              noToggleOnConfirm
              lang={props.lang}
              prop={state.datePicker.valueKey}
              dayPicker={true}
              visible={state.datePicker.visible}
              title={state.datePicker.title}
              toggle={toggleDatePicker}
              setDatePicker={setDatePicker}
              onConfirm={onDatePickerConfirm}
              date={state.datePicker.date}
              timePicker={state.datePicker.timePicker}
            />
            <SimpleModalPicker
              onToggleModal={closeModalPicker}
              getItemText={(item) => item[state.modalPicker.textProp]}
              isFetching={props.isFetching || state.modalPicker?.loading}
              {...state.modalPicker}
            />
            <SimpleModal
              pageH={props.pageH}
              pageW={props.pageW}
              title={state.textModal.title}
              placeholder={state.textModal.placeholder || state.textModal.title}
              err={state.textModal.err}
              errMsg={state.textModal.errMsg}
              visible={state.textModal.visible}
              value={state.textModal.value}
              //isFetching={loading.prop}
              onSave={state.textModal.onSave || saveText}
              onToggleModal={toggleTextModal}
              closeOnRighButtonPress={
                state.textModal.closeOnRighButtonPress ?? false
              }
              multiline={state.textModal.multiline}
              numeric={state.textModal.numeric}
              maxDecimals={state.textModal.maxDecimals}
              fn={state.textModal.fn}
              onSubmitEditing
            />
          </>
        ) : null}
      </View>
      {state.picker?.visible ? (
        <ModalPicker
          onlyPicker
          noItemList
          visible={true}
          controlledVisibility={true}
          toggleModal={closePicker}
          docId={props.docId}
          lastModified={props.options.lastModified}
          isFetching={props.isFetching}
          role={props.profile.role}
          lang={props.lang}
          pageH={fullHeight}
          pageW={fullWidth}
          boldTitle
          getItemText={state.picker.getItemText}
          itemTextProps={state.picker.itemTextProps}
          refreshOnOpen={state.picker.refreshOnOpen}
          onRefreshList={state.picker.onRefreshList}
          onSelect={state.picker.onSelect}
          noRemoval={state.picker.noRemoval}
          // openIfNotFilled={state.picker.openIfNotFilled}
          setValueSequence={state.picker.setValueSequence}
          extraCellProps={state.picker.extraCellProps}
          onDelete={state.picker.onDelete}
          onBeforeModalOpen={state.picker.onBeforeModalOpen}
          multiPicker={state.picker.multiPicker}
          addableProp={state.picker.addableProp}
          prop={state.picker.optionsProp}
          valueKey={state.picker.valueKey}
          disableRefresh={!state.picker.addableProp}
          numeric={state.picker.numeric}
          value={state.picker.isMultiPicker ? null : state.picker.value}
          values={state.picker.isMultiPicker ? state.picker.value : null}
          title={state.picker.title}
          options={
            state.picker.optionsProp
              ? (props.options[state.picker.optionsProp] ?? []).concat(
                  state.picker.itemOptions ?? []
                )
              : state.picker.options
          }
          itemOptions={state.picker.itemOptions}
          modifyValueItem={state.picker.modifyValueItem}
          onPress={state.picker.onPress}
          onRemove={state.picker.onRemove}
          onAdd={state.picker.onAdd}
          modifyValue={state.picker.modifyValue}
        />
      ) : null}
      {state.shareModal?.isVisible ? (
        <ShareModal
          resourceId={props.docId}
          isVisible={state.shareModal?.isVisible}
          onCloseModal={() => setState({ shareModal: { isVisible: false } })}
        />
      ) : null}
      {state.modalForm?.isVisible ? (
        <Modal
          {...modalPickerOpts}
          isVisible={true}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          onBackButtonPress={closeModal("modalForm")}
        >
          <View
            style={{
              flex: 1,
              borderWidth: 2,
              borderColor: colors.accent,
            }}
          >
            {state.modalForm.loading ? (
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: fullHp(100) * 0.9 - 36,
                  width: fullWp(100) * 0.9 - 4,
                  zIndex: 1000,
                }}
              >
                <LoadingView title={t("sendingTestEmailInfo")} />
              </View>
            ) : null}

            <SimpleForm
              lang={props.lang}
              width={fullWp(90)}
              height={fullHp(90)}
              inputs={state.modalForm.inputs}
              saveDisabled={state.requesting.url === state.modalForm.requestUrl}
              onSave={state.modalForm.onSave}
              onSaveTitle={state.modalForm.onSaveTitle || t("save")}
              backButtonTitle={state.modalForm.backButtonTitle || t("cancel")}
              onBackButtonPress={closeModal("modalForm")}
              // renderHeader={renderFormHeader}
            />
          </View>
        </Modal>
      ) : null}
    </>
  );
};
//}, shouldNotUpdate);
//export function CreateDocScreen(props)

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SET_USER_PROP,
      toggleMeasurementPerGroup,
      modifyDoc,
      addDoc,
      saveDocToDb,
      checkOptions,
      updateOptions,
      setOptionsChecked,
      SET_LAYOUTS,
      requestApiData,
      receiveResponse,
      SET_DEEP_LINK_PARAMS,
      refreshPickerObjOptions,
      removePickerObjOption,
      addPickerObjOption,
      modifyPickerOption,
      modifyValueItem,
      clearModifyDoc,
      SET_GPS_LOCATION,
      setDocProp,
      signOut,
      REFRESH_PROJECTS,
      updateLastDocRequests,
      UPDATE_LAST_INTERNAL_DOC_SAVES,
      REMOVE_DOCS,
      REFRESH_DOCS,
      // modify actions
      ADD_ATTACHMENT,
      SET_DOC_VALUES,
      MODIFY_VALUE,
      ADD_TO_STRING_ARR,
      MODIFY_STRING_ARR,
      DELETE_FROM_STRING_ARR,
      DELETE_MULTIPLE_FROM_STRING_ARR,
      ADD_TO_OBJECT_ARR,
      REPLACE_OBJECT_ARR_ITEM,
      MODIFY_OBJECT_ARR_ITEM,
      REMOVE_OBJECT_ARR_ITEM,
      ADD_TO_OBJECT_ARR_WITH_GENERATED_ID,
      ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID,
      ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID,
      ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID,
      MODIFY_OBJECT_ARR_INNER_ITEM,
      REMOVE_OBJECT_ARR_INNER_ITEM,
      REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS,
      addMeasurement,
      removeModularMeasurement,
      ADD_MEASUREMENT_OBJ_CONNECTION,
      REMOVE_MEASUREMENT_OBJ_CONNECTION,
      REMOVE_USER_FROM_SHARED_TO,
      removeMeasObjects,
      removeModularItems,
      // ! WILL BE DEPRECATED
      SYNC_VALUES_TIMESTAMPS,
    },
    dispatch
  );

const emptyLocation = {};
const mapStateToProps = (state, ownProps) => {
  const navParams =
    OS == "web"
      ? ownProps.navState ?? ownProps?.navigation?.location?.state
      : ownProps.navState ?? ownProps?.route?.params;

  const urlStart =
    OS === "web"
      ? ownProps.navigation?.location?.pathname.startsWith("/internal")
        ? "editForm"
        : "create"
      : "create";

  const { docId, docToModify, docLayout } = getDocProps(
    ownProps.viewUnfinishedLayout,
    state,
    true,
    ownProps.docId,
    ownProps.layoutId
  );

  let _company = state.userInfo.company;
  let _profile = state.userInfo.profile;
  let _manager = state.userInfo.manager;
  if (ownProps.demo) {
    _company = ownProps.company;
    _profile = ownProps.profile;
    _manager = ownProps.manager;
  }

  const companyId = _profile.role === "Trial" ? "TrialAccounts" : _company?.id;

  const layout = ownProps.viewUnfinishedLayout
    ? docLayout?.versions?.[1]
    : docLayout?.versions?.[docToModify?.layoutVersion];

  const permissions = ownProps.getPermissions
    ? ownProps.getPermissions(docToModify, docLayout)
    : ownProps.permissions;

  return {
    permissions,
    urlStart: urlStart,
    deepLinkScreen: state.userInfo.deepLinkScreen,
    deepLinkRoute: state.userInfo.deepLinkRoute,
    deepLinkParams: state.userInfo.deepLinkParams,
    gpsLocation: state.userInfo.gpsLocation || emptyLocation,
    profile: _profile,
    manager: _manager,
    company: _company,
    technician: _profile.name + " " + _profile.lName,
    companyId: companyId,
    uiSettings: state.userInfo.uiSettings,
    saveAttachmentsOnMount: state.userInfo.uiSettings?.saveAttachmentsOnMount,
    editorRole: state.userInfo.editorRole || _profile.role,
    measurementObjectsOpenByDefault:
      state.userInfo.measurementObjectsOpenByDefault,
    modularItemsOpenByDefault: state.userInfo.modularItemsOpenByDefault,
    options: state.options,
    docId: docId,
    doc: docToModify,
    viewingCompletedDoc:
      !ownProps.viewUnfinishedLayout &&
      docToModify?.status != 0 &&
      docToModify?.status != 2,
    docLayout: layout,
    docLayoutType: docLayout?.type,
    unfinishedDocs: state.docs?.unfinishedDocs,
    docType: docToModify?.docType,
    type: docToModify?.type,
    lastDocRequests: state.docs.lastDocRequests || {},
    lastInternalDocSaves: state.docs.lastInternalDocSaves,
    projects: state.projects.unfinished,
    modifyDocState: state.modifyDoc,
    navParams,
    lang:
      (docToModify && docToModify.values[getPdfLangValueKey(_profile)]) ||
      i18next.language,
    defaultPDFLanguage: state.userInfo?.uiSettings?.defaultPDFLanguage,
    languageCodes: state.userInfo.uiSettings?.languageCodes,
    isFetching: state.isFetching,
  };
};

CreateDocScreen.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "CreateDocScreen",
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocScreen);

import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Platform,
  TouchableOpacity,
  Image,
  Pressable,
  ActivityIndicator,
} from "react-native";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SkeletonLoadingView from "./SkeletonLoadingView";
import Pdf from "./Pdf";
import ZoomableView from "./ZoomableView";
import { apiRequestWithoutToken, getWithToken } from "../lib/api";
import {
  checkAtchDownloadNeed,
  getImageDimensions,
  parseAtchId,
} from "../lib/functions";
import {
  hp,
  wp,
  fullHp,
  fullWp,
  showToast,
  // setNetInfoListener,
} from "../lib/helperFns";
import { api } from "../lib/constants";
import { downloadResource, readFile } from "../lib/fileOperations";
import Icon from "./Icon";

const OS = Platform.OS;

const styles = {
  wrapper: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  imageWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
};

function _TypeSpecificPreview(props) {
  const {
    atch,
    fetchedAttachments,
    fullscreenAtch,
    openPreviewModal,
    setAttachmentsFetched,
    standAlone,
    isSelected,
    dirs,
    theme,
    colors,
    // thumbnailFlex,
    thumbnail,
    noFullScreenButton,
    imgHeight,
    maxImgSize,
    ogImage,
    onDelete,
    onRotate,
    rotation,
    deleteButton,
    rotateButton,
    onAttachmentEdit,
    pageH,
    pageW,
    absolutePosition,
  } = props;
  const { t } = useTranslation();
  // const [isOffline, setIsOffline] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [customUri, setCustomUri] = useState(null);

  const rotated = rotation === 90 || rotation === 270;
  const atchId = parseAtchId(atch);

  const fileName = atch
    ? atch.docRef
      ? `${atchId}.pdf`
      : atch.offlineAtch ||
        atch.saveFailed ||
        fullscreenAtch?.id ||
        fetchedAttachments == "full" ||
        !atch.dimensions
      ? `${atchId}.${atch.ext}`
      : `${atchId}_thumbnail.${atch.ext}`
    : "";
  const filePath = `${dirs.DocumentDir}/${fileName}`;

  // React.useEffect(() => {
  //   let unsubscribeNetworkListener;
  //   if (OS !== "web") {
  //     unsubscribeNetworkListener = setNetInfoListener((state) => {
  //       setIsOffline(!state.isConnected);
  //     });
  //   }
  //   return () => {
  //     if (OS !== "web") {
  //       unsubscribeNetworkListener();
  //     }
  //   };
  // }, [setIsOffline]);

  async function getBase64() {
    const base64 = await readFile(filePath);
    setCustomUri(
      atch?.docRef
        ? `data:application/pdf;base64,${base64}`
        : `data:${atch?.type};base64,${base64}`
    );
  }
  const getWithCustomToken = () => {
    if (atch)
      getWithToken(
        `${api}/attachments/${encodeURIComponent(
          atch.id
        )}?redirect=false&returnType=${thumbnail ? "Thumbnail" : "Regular"}`,
        props.customToken
      ).then((res) => {
        if (res.status === 200) setCustomUri(res.data);
        else setAttachmentsFetched?.({ key: atch.id, value: "no content" });
      });
  };

  const getDoc = async () => {
    await checkAtchDownloadNeed(
      atch,
      setAttachmentsFetched,
      props.ADD_ATTACHMENT,
      props.docId,
      props.ogImage,
      true,
      thumbnail,
      props.customToken
    );
    getBase64();
  };

  useEffect(() => {
    // try to fetch atch if it doesn't exist
    if (atch && !atch.type && !atch?.docRef) {
      checkAtchDownloadNeed(
        atch,
        setAttachmentsFetched,
        props.ADD_ATTACHMENT,
        props.docId,
        props.ogImage,
        undefined,
        thumbnail,
        props.customToken,
        true
      );
    } else if (atch?.docRef) {
      getDoc();
    } else if (props.customToken) {
      getWithCustomToken();
    }
    // TODO use raven url when docs have been moved to s3
    else if (OS == "web") {
      if (atch?.offlineAtch || atch?.saveFailed) {
        getBase64();
      }
    }
  }, [atch, fetchedAttachments]);

  const stopPropagation = (ev) => {
    if (OS === "web") {
      ev.stopPropagation();
    }
  };

  const buttonSize = 36;
  const buttonStyle = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primary,
    height: buttonSize,
    flex: 1,
    borderColor: colors.accent,
    borderWidth: 2,
    alignSelf: "stretch",
  };
  const iconSize = 24;
  const hasButtons =
    !absolutePosition &&
    (atch?.dimensions ||
      atch?.base64 ||
      atch?.type == "application/pdf" ||
      atch?.docRef)
      ? true
      : false;

  const _maxImgSize =
    maxImgSize ??
    (OS === "web"
      ? {
          width: pageW ? pageW * 0.9 : fullWp(90),
          height: pageH ? pageH * 0.9 : fullHp(90),
        }
      : { width: wp(90), height: hp(90) });

  const buttonRowHeight = hasButtons ? buttonSize : 0;
  const _imgHeight = imgHeight ?? maxImgSize?.height ?? hp(46); // - buttonRowHeight; //wp(46);
  const _imgWidth = imgHeight ?? maxImgSize?.width ?? hp(46); // - buttonRowHeight; //wp(46);
  const _width = isSelected ? _imgWidth - 4 : _imgWidth;
  const _height = (isSelected ? _imgHeight - 4 : _imgHeight) - buttonRowHeight;

  const dimensions = props.forceRectangle
    ? {
        height: imgHeight,
        width: imgHeight,
        objectFit: "cover",
      }
    : fullscreenAtch?.id
    ? getImageDimensions(
        rotated
          ? {
              height: atch?.dimensions?.width,
              width: atch?.dimensions?.height,
            }
          : {
              height: atch?.dimensions?.height,
              width: atch?.dimensions?.width,
            },
        {
          height: _maxImgSize.height - buttonRowHeight,
          width: _maxImgSize.width,
        }
      )
    : OS === "web"
    ? getImageDimensions(
        rotated
          ? {
              height: atch?.dimensions?.width,
              width: atch?.dimensions?.height,
            }
          : {
              height: atch?.dimensions?.height,
              width: atch?.dimensions?.width,
            },
        maxImgSize
          ? {
              height: maxImgSize.height - buttonRowHeight,
              width: maxImgSize.width,
            }
          : { height: _height, width: _width }
      )
    : { height: _height, width: _width };

  const renderFullScreenButton = () => {
    if (openPreviewModal) {
      return (
        <TouchableOpacity
          style={[buttonStyle, props.fullScreenButtonStyle]}
          onPress={(event) => {
            stopPropagation(event);
            openPreviewModal(atch);
          }}
        >
          <Icon
            name={fullscreenAtch?.id ? "fullscreen-exit" : "fullscreen"}
            size={iconSize}
            color={colors.text}
          />
        </TouchableOpacity>
      );
    } else return null;
  };

  const renderButtons = (noRotateButton, noEditButton) => {
    const canDownload =
      !props.disabled &&
      !thumbnail &&
      !ogImage &&
      !atch?.saveFailed &&
      !atch?.offlineAtch &&
      atch?.id;
    return (
      <View
        style={
          absolutePosition
            ? {
                position: "absolute",
                bottom: 0,
                left: 0,
                height: "100%",
                width: "100%",
              }
            : theme.buttonContainer
        }
      >
        {deleteButton ? (
          <TouchableOpacity
            style={[buttonStyle, props.deleteButtonStyle]}
            onPress={(event) => {
              stopPropagation(event);
              if (onDelete) {
                onDelete(atch?.uri, atch);
              }
            }}
          >
            <Icon
              name={props.deleteIconName ?? "trash-can"}
              size={iconSize}
              color={colors.text}
            />
          </TouchableOpacity>
        ) : null}
        {!props.disabled && (atch?.offlineAtch || atch?.saveFailed) ? (
          <TouchableOpacity
            style={[buttonStyle, props.offlineButtonStyle]}
            onPress={(event) => {
              stopPropagation(event);
              showToast(t("savedLocally"));
            }}
          >
            <Icon
              style={theme.zeroPaddingAndMargin}
              name={"cloud-off-outline"}
              size={iconSize}
              color={colors.text}
            />
          </TouchableOpacity>
        ) : null}
        {!props.disabled &&
        (!props.permissions || props.permissions.Update) &&
        !noEditButton &&
        (atch?.dimensions || atch?.type == "application/pdf") &&
        onAttachmentEdit ? (
          <TouchableOpacity
            style={buttonStyle}
            onPress={(event) => {
              stopPropagation(event);
              props.onAttachmentEdit();
            }}
          >
            <Icon name={"pencil-outline"} size={iconSize} color={colors.text} />
          </TouchableOpacity>
        ) : null}
        {!props.disabled &&
        !thumbnail &&
        !noFullScreenButton &&
        (fullscreenAtch?.id || standAlone)
          ? renderFullScreenButton()
          : null}
        {!props.disabled && !noRotateButton && rotateButton && onRotate ? (
          <TouchableOpacity
            style={[buttonStyle, props.rotateButtonStyle]}
            onPress={(event) => {
              stopPropagation(event);
              onRotate(atch.uri, atch, rotation);
            }}
          >
            <Icon name={"rotate-right"} size={iconSize} color={colors.text} />
          </TouchableOpacity>
        ) : null}

        {canDownload ? (
          <TouchableOpacity
            style={buttonStyle}
            onPress={(event) => {
              stopPropagation(event);
              if (setDownloading) setDownloading(true);

              downloadResource(
                atch.id,
                `${atch.name}.${atch.ext}`,
                `image/${atch.ext === "png" ? "png" : "jpeg"}`,
                setDownloading,
                props.customToken
              );
            }}
          >
            {downloading ? (
              <ActivityIndicator size={"large"} color={colors.text} />
            ) : (
              <Icon name={"download"} size={iconSize} color={colors.text} />
            )}
          </TouchableOpacity>
        ) : null}

        {canDownload && OS !== "web" ? (
          <TouchableOpacity
            style={buttonStyle}
            disabled={downloading}
            onPress={(event) => {
              stopPropagation(event);
              if (setDownloading) setDownloading(true);

              downloadResource(
                atch.id,
                `${atch.name}.${atch.ext}`,
                `image/${atch.ext === "png" ? "png" : "jpeg"}`,
                setDownloading,
                props.customToken,
                false,
                true
              );
            }}
          >
            {downloading ? (
              <ActivityIndicator size={"large"} color={colors.text} />
            ) : (
              <Icon
                name={"share-variant"}
                size={iconSize}
                color={colors.text}
              />
            )}
          </TouchableOpacity>
        ) : null}
        {props.onExtraButtonPress ? (
          <TouchableOpacity
            style={[buttonStyle, props.extraButtonStyle]}
            onPress={props.onExtraButtonPress}
          >
            <Icon
              name={props.extraButtonIcon}
              size={iconSize}
              color={colors.text}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  if (!atch || ((props.customToken || atch?.docRef) && !customUri)) {
    return (
      <Pressable
        onPress={props.onPress}
        onLongPress={props.onLongPress}
        style={theme.container}
      >
        <SkeletonLoadingView
          width={dimensions.width}
          height={dimensions.height}
          colors={colors}
          fullscreen={fullscreenAtch?.id}
        />
        {renderButtons(true, true)}
      </Pressable>
    );
  } else if (fetchedAttachments === "no content") {
    return (
      <Pressable
        onPress={props.onPress}
        onLongPress={props.onLongPress}
        style={styles.wrapper}
      >
        <View
          style={{
            height: dimensions.height,
            width: dimensions.width,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            name={
              atch?.type === "application/pdf"
                ? "file-pdf-box"
                : atch?.type == "file-video-outline"
                ? "file-video-outline"
                : "file-document-outline"
            }
            size={dimensions.width * 0.8}
            color={colors.text}
          />
          {thumbnail ? null : (
            <Text style={theme.boldText}>{t("failedToPreview")}</Text>
          )}
        </View>

        {renderButtons(true, true)}
      </Pressable>
    );
  } else if (
    atch.type?.startsWith("image") ||
    atch?.base64 ||
    atch?.dimensions
  ) {
    let uri;
    if (customUri) uri = customUri;
    else if (OS === "web") {
      if (atch?.base64 || atch?.uri)
        uri = `data:${atch?.type};base64,${atch?.base64 || atch?.uri}`;
      else
        uri = `${api}/attachments/${encodeURIComponent(
          atch.id
        )}?redirect=true&returnType=${
          thumbnail && (!atch?.type || atch?.thumbnailSize > 0)
            ? "Thumbnail"
            : "Regular"
        }`;
    } else {
      if (atch?.base64) uri = `data:${atch?.type};base64,${atch?.base64}`;
      else if (atch?.uri) uri = atch.uri;
      else if (atch?.saveFailed || atch?.offlineAtch)
        uri = `file://${filePath}`;
      else
        uri = `${api}/attachments/${encodeURIComponent(
          atch.id
        )}?redirect=true&returnType=${
          thumbnail && (!atch?.type || atch?.thumbnailSize > 0)
            ? "Thumbnail"
            : "Regular"
        }`;
    }

    return (
      <Pressable
        style={styles.wrapper}
        onPress={OS === "web" ? props.onPress : undefined}
        onLongPress={OS === "web" ? props.onLongPress : undefined}
      >
        <ZoomableView
          disableCompletely={thumbnail}
          disabled={thumbnail}
          onPress={props.onPress}
          onLongPress={props.onLongPress}
        >
          <View
            style={
              OS === "web"
                ? rotateButton && rotated
                  ? {
                      ...styles.imageWrapper,
                      height: rotated ? dimensions.height : dimensions.width,
                      width: rotated ? dimensions.width : dimensions.height,
                    }
                  : styles.imageWrapper
                : styles.imageWrapper
            }
          >
            <Image
              resizeMode={"contain"}
              style={
                OS === "web"
                  ? rotateButton
                    ? {
                        transform: `rotate(${rotation || 0}deg)`,
                        height: rotated ? dimensions.width : dimensions.height,
                        width: rotated ? dimensions.height : dimensions.width,
                      }
                    : dimensions
                  : rotateButton
                  ? {
                      transform: [{ rotate: (rotation || 0) + "deg" }],
                      height: rotated ? dimensions.width : dimensions.height,
                      width: rotated ? dimensions.height : dimensions.width,
                    }
                  : dimensions
              }
              source={{
                uri:
                  uri +
                  (!customUri && !ogImage && atch.fileLastModified
                    ? `&_=${atch.fileLastModified}`
                    : ""),
              }}
              onError={() => {
                if (setAttachmentsFetched) {
                  setAttachmentsFetched({ key: atch.id, value: "no content" });
                }
              }}
            />
          </View>
        </ZoomableView>

        {renderButtons(false, false, uri)}
      </Pressable>
    );
  } else if (!thumbnail && (atch?.type == "application/pdf" || atch?.docRef)) {
    let uri;
    if (customUri) uri = customUri;
    else if (OS === "web") {
      if (atch?.base64 || atch?.uri)
        uri = `data:${atch?.type};base64,${atch?.base64 || atch?.uri}`;
      else
        uri = `${api}/attachments/${encodeURIComponent(
          atch.id
        )}?redirect=true&returnType=${thumbnail ? "Thumbnail" : "Regular"}`;
    } else {
      if (customUri) uri = customUri;
      else if (atch?.base64) uri = `data:${atch?.type};base64,${atch?.base64}`;
      else if (atch?.uri) uri = atch.uri;
      else if (atch?.saveFailed || atch?.offlineAtch)
        uri = `file://${filePath}`;
      else
        uri = `${api}/attachments/${encodeURIComponent(
          atch.id
        )}?redirect=true&returnType=${thumbnail ? "Thumbnail" : "Regular"}`;
    }

    return (
      <>
        <Pressable
          onPress={props.onPress}
          onLongPress={props.onLongPress}
          //style={wrapperStyle}
          disabled={!thumbnail}
          style={[
            theme.container,
            {
              overflow: "hidden",
              height: _maxImgSize.height - 36,
              width: _maxImgSize.width,
            },
          ]}
        >
          <Pdf
            pageW={_maxImgSize.width}
            pageH={_maxImgSize.height - 36}
            source={
              OS === "web"
                ? typeof uri === "string"
                  ? uri.split(",")[1]
                  : null
                : {
                    uri: uri,
                  }
            }
            src={uri}
            loadingOwnAtchs={
              <SkeletonLoadingView size={_height} colors={colors} />
            }
            onError={(error) => {
              if (!__DEV__)
                apiRequestWithoutToken(
                  {
                    dateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
                    error: `TypeSpecificPreview failed to load pdf: ${JSON.stringify(
                      error
                    )}`,
                  },
                  "/errors/add"
                );
              else {
                showToast(t("pdfLoadErr"));
              }
            }}
            style={theme.pdf}
            customToken={props.customToken}
          />
        </Pressable>
        {renderButtons(true)}
      </>
    );
  }
  // else if (
  //   false &&
  //   Platform.OS === "web" &&
  //   SheetJSFT.includes(getMimeExtension(atch.type))
  // ) {
  //   return (
  //     <SpreadsheetPreview
  //       height={pageH ?? hp(90)}
  //       width={pageW ?? wp(90)}
  //       attachment={atch}
  //       ADD_ATTACHMENT={props.ADD_ATTACHMENT}
  //     />
  //   );
  // }
  else {
    return (
      <Pressable
        onPress={props.onPress}
        onLongPress={props.onLongPress}
        style={styles.wrapper}
      >
        <View style={styles.imageWrapper}>
          <View
            style={[
              { alignItems: "center", justifyContent: "center" },
              dimensions,
            ]}
          >
            <Icon
              name={
                atch?.type === "application/pdf"
                  ? "file-pdf-box"
                  : atch?.type == "file-video-outline"
                  ? "file-video-outline"
                  : "file-document-outline"
              }
              size={dimensions.width * 0.8}
              color={colors.text}
            />
            {props.disableNotFoundText ? null : (
              <Text
                style={theme.label}
                numberOfLines={thumbnail ? 1 : undefined}
              >
                {thumbnail ? `${atch.name}.${atch.ext}` : t("failedToPreview")}
              </Text>
            )}
          </View>
        </View>

        {renderButtons(true)}
      </Pressable>
    );
  }
}

const TypeSpecificPreview = React.memo(_TypeSpecificPreview);
export default TypeSpecificPreview;

import { Platform } from "react-native";
import update from "immutability-helper";

import { receiveResponse } from "../actions/ApiActions";
import { setLicence, signOut } from "../actions/ProfileActions";
import {
  CLEAR_IF_MODIFYING,
  clearModifyDocAction,
  clearModifyDoc,
} from "../actions/ModifyDocActions";
import {
  REMOVE_OBJECT_ARR_ITEM as REMOVE_OBJECT_ARR_ITEMFn,
  ADD_TO_OBJECT_ARR as ADD_TO_OBJECT_ARRFn,
  DOC_SAVE_MERGE as DOC_SAVE_MERGEFn,
} from "../reducers/DocsReducer";
import { ADD_ATTACHMENT, removeAttachments } from "../actions/OptionsActions";
import { SYNC_VALUES_TIMESTAMPS_FN } from "../reducers/DocsReducer";

import { putWithToken, apiRequestWithToken } from "../lib/api";
import {
  getDocLanguage,
  getTranslatedText,
  parseToFileName,
  syncDocAttachments,
  validateChecks,
} from "../lib/functions";
import {
  fetchNetInfo,
  showExpiredTokenToast,
  showToast,
} from "../lib/helperFns";
import {
  errorReport,
  replaceTemplateString,
  getSpecialInputsProps,
  mergeSources,
  replaceAlternateSources,
} from "../lib/functions";
import i18next from "i18next";

/**
 * Merge values and timestamps from a doc object to a doc with targetDocId
 * @param {Any} payload array of objects with id and newPath
 */
export const MERGE_VALUES = (payload) => ({
  type: "MERGE_VALUES",
  payload,
});
/**
 * Updates paths in all doc types, attachments and folders
 * @param {Any} payload array of objects with id and newPath
 */
export const UPDATE_PATHS = (payload) => ({
  type: "UPDATE_PATHS",
  payload,
});

//WILL BE DEPRECATED
export const SYNC_VALUES_TIMESTAMPS = (payload) => ({
  type: "SYNC_VALUES_TIMESTAMPS",
  payload,
});

export const TRACK_PRODUCTIVITY = (payload, userId) => ({
  type: "TRACK_PRODUCTIVITY",
  payload,
  userId,
});

export const ADD_TO_DOC_ROOT_ARR = (payload) => ({
  type: "ADD_TO_DOC_ROOT_ARR",
  payload,
});

export const REMOVE_FROM_DOC_ROOT_ARR = (payload) => ({
  type: "REMOVE_FROM_DOC_ROOT_ARR",
  payload,
});

export const RECEIVE_DOCS = (payload) => ({
  type: "RECEIVE_DOCS",
  payload,
});
export const REMOVE_USER_FROM_SHARED_TO = (payload) => ({
  type: "REMOVE_USER_FROM_SHARED_TO",
  payload,
});
export const ADD_MEASUREMENT_OBJ_CONNECTION = (payload, onlineDoc) => ({
  type: "ADD_MEASUREMENT_OBJ_CONNECTION",
  payload,
  onlineDoc,
});
export const REMOVE_MEASUREMENT_OBJ_CONNECTION = (payload, onlineDoc) => ({
  type: "REMOVE_MEASUREMENT_OBJ_CONNECTION",
  payload,
  onlineDoc,
});
export const MODIFY_OBJECT_ARR_ITEM = (payload, onlineDoc) => ({
  type: "MODIFY_OBJECT_ARR_ITEM",
  payload,
  onlineDoc,
});
export const REMOVE_OBJECT_ARR_ITEM = (payload, onlineDoc) => ({
  type: "REMOVE_OBJECT_ARR_ITEM",
  payload,
  onlineDoc,
});
export const REPLACE_OBJECT_ARR_ITEM = (payload, onlineDoc) => ({
  type: "REPLACE_OBJECT_ARR_ITEM",
  payload,
  onlineDoc,
});
export const ADD_TO_OBJECT_ARR = (payload, onlineDoc) => ({
  type: "ADD_TO_OBJECT_ARR",
  payload,
  onlineDoc,
});
export const ADD_TO_OBJECT_ARR_WITH_GENERATED_ID_ACTION = (
  payload,
  onlineDoc
) => ({
  type: "ADD_TO_OBJECT_ARR_WITH_GENERATED_ID",
  payload,
  onlineDoc,
});

export const ADD_TO_OBJECT_ARR_WITH_GENERATED_ID =
  (payload, fn) => (dispatch) => {
    dispatch(ADD_TO_OBJECT_ARR_WITH_GENERATED_ID_ACTION(payload));
    fn && fn();
  };

export const ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID = (
  payload,
  onlineDoc
) => ({
  type: "ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID",
  payload,
  onlineDoc,
});
export const ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID = (
  payload,
  onlineDoc
) => ({
  type: "ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID",
  payload,
  onlineDoc,
});
export const ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID = (
  payload,
  onlineDoc
) => ({
  type: "ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID",
  payload,
  onlineDoc,
});
export const MODIFY_OBJECT_ARR_INNER_ITEM = (payload, onlineDoc) => ({
  type: "MODIFY_OBJECT_ARR_INNER_ITEM",
  payload,
  onlineDoc,
});
export const REMOVE_OBJECT_ARR_INNER_ITEM = (payload, onlineDoc) => ({
  type: "REMOVE_OBJECT_ARR_INNER_ITEM",
  payload,
  onlineDoc,
});
export const REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS = (
  payload,
  onlineDoc
) => ({
  type: "REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS",
  payload,
  onlineDoc,
});
export const DELETE_FROM_STRING_ARR = (payload, onlineDoc) => ({
  type: "DELETE_FROM_STRING_ARR",
  payload,
  onlineDoc,
});
export const DELETE_MULTIPLE_FROM_STRING_ARR = (payload, onlineDoc) => ({
  type: "DELETE_MULTIPLE_FROM_STRING_ARR",
  payload,
  onlineDoc,
});
export const MODIFY_STRING_ARR = (payload, onlineDoc) => ({
  type: "MODIFY_STRING_ARR",
  payload,
  onlineDoc,
});
export const ADD_TO_STRING_ARR = (payload, onlineDoc) => ({
  type: "ADD_TO_STRING_ARR",
  payload,
  onlineDoc,
});

export const MODIFY_VALUE = (payload) => ({
  type: "MODIFY_VALUE",
  payload,
});

export const requestApiData = () => ({
  type: "REQUEST_API",
});

export const RESET_TMP_DOC = (payload) => ({
  type: "RESET_TMP_DOC",
  payload,
});

export const PUSH_TO_DOC_EMAILS = (payload) => ({
  type: "PUSH_TO_DOC_EMAILS",
  payload,
});

export const REMOVE_FROM_DOC_EMAILS = (payload) => ({
  type: "REMOVE_FROM_DOC_EMAILS",
  payload,
});

export const REPLACE_SIGNATURES = (payload) => ({
  type: "REPLACE_SIGNATURES",
  payload,
});

export const REPLACE_CREATOR_SIGNATURE = (payload) => ({
  type: "REPLACE_CREATOR_SIGNATURE",
  payload,
});

export const modifySignatures = (payload) => ({
  type: "MODIFY_DOC_SIGNATURE",
  payload,
});

export const removeSignature = (payload) => ({
  type: "REMOVE_DOC_SIGNATURE",
  payload,
});

export const addDocSignature = (payload) => ({
  type: "ADD_DOC_SIGNATURE",
  payload,
});

export const REMOVE_CREATOR_SIGNATURE = (payload) => ({
  type: "REMOVE_CREATOR_SIGNATURE",
  payload,
});

export const modifyCreatorSignature = (payload) => ({
  type: "MODIFY_CREATOR_SIGNATURE",
  payload,
});

export const mergeMeasurementDoc = (payload) => ({
  type: "MERGE_MEASUREMENT_DOC",
  payload,
});

export const unmergeMeasurementDoc = (payload) => ({
  type: "UNMERGE_MEASUREMENT_DOC",
  payload,
});

export const addDoc = (payload) => ({
  type: "ADD_DOC",
  payload,
});

export const ADD_INTERNAL_DOC = (payload) => ({
  type: "ADD_INTERNAL_DOC",
  payload,
});

export const setDocProp = (payload) => ({
  type: "SET_DOC_PROP",
  payload,
});

export const SET_MULTIPLE_DOCS_PROPS = (payload) => ({
  type: "SET_MULTIPLE_DOCS_PROPS",
  payload,
});

export const SET_DOC_VALUES = (payload) => ({
  type: "SET_DOC_VALUES",
  payload,
});

export const setFuseTypeOrSize = (payload) => ({
  type: "SET_FUSE_TYPE_OR_SIZE",
  payload,
});

export const setRcdTested = (payload) => ({
  type: "SET_RCD_TESTED",
  payload,
});

export const setGroupGrounded = (payload) => ({
  type: "SET_GROUP_GROUNDED",
  payload,
});

export const setRcdType = (payload) => ({
  type: "SET_RCD_TYPE",
  payload,
});

export const setRcdUse = (payload) => ({
  type: "SET_RCD_USE",
  payload,
});

export const addRcdProtectedGroup = (payload) => ({
  type: "ADD_RCD_PROTECTED_GROUP",
  payload,
});

export const removeRcdProtectedGroup = (payload) => ({
  type: "REMOVE_RCD_PROTECTED_GROUP",
  payload,
});

export const SET_SORT_OBJECTS = (payload) => ({
  type: "SET_SORT_OBJECTS",
  payload,
});

export const sortDocs = (payload) => ({
  type: "SORT_DOCS",
  payload,
});

export const setTargetProp = (payload) => ({
  type: "SET_TARGET_PROP",
  payload,
});

export const removePropArrItem = (payload) => ({
  type: "REMOVE_PROP_ARR_ITEM",
  payload,
});

export const setDataValue = (payload) => ({
  type: "SET_DATA_VALUE",
  payload,
});

export const setDataValueAndValues = (payload) => ({
  type: "SET_DATA_VALUE_AND_VALUES",
  payload,
});

export const setNewDataObj = (payload) => ({
  type: "SET_NEW_DATA_OBJ",
  payload,
});

export const setGroupsWithLineCount = (payload) => ({
  type: "SET_GROUPS_WITH_LINE_COUNT",
  payload,
});

export const changeDataObjName = (payload) => ({
  type: "CHANGE_DATA_OBJ_NAME",
  payload,
});

export const modifyValueItem = (payload) => ({
  type: "MODIFY_VALUE_ITEM",
  payload,
});

export const REFRESH_DOCS = (payload) => ({
  type: "REFRESH_DOCS",
  payload,
});

export const CHANGE_UNFINISHED_DOC_ID = (payload) => ({
  type: "CHANGE_UNFINISHED_DOC_ID",
  payload,
});

export const REMOVE_DOCS = (payload) => ({
  type: "REMOVE_DOCS",
  payload,
});

export const removeDataObjects = (payload) => ({
  type: "REMOVE_DATA_OBJECTS",
  payload,
});

export const removeMeasurement = (payload) => ({
  type: "REMOVE_MEASUREMENT",
  payload,
});

export const updateLastDocRequests = (payload) => ({
  type: "UPDATE_LAST_DOC_REQUESTS",
  payload,
});

export const UPDATE_LAST_INTERNAL_DOC_SAVES = (payload) => ({
  type: "UPDATE_LAST_INTERNAL_DOC_SAVES",
  payload,
});

export const removeModularItems = (payload) => ({
  type: "REMOVE_MODULAR_ITEMS",
  payload,
});

export const removeMeasObjects = (payload) => ({
  type: "REMOVE_MEAS_OBJECTS",
  payload,
});

export const addMeasurement = (payload) => ({
  type: "ADD_MODULAR_MEASUREMENT",
  payload,
});

export const removeModularMeasurement = (payload) => ({
  type: "REMOVE_MODULAR_MEASUREMENT",
  payload,
});

export const DOC_SAVE_MERGE = (payload) => ({
  type: "DOC_SAVE_MERGE",
  payload,
});

const handleDocIdUpdated = (newDocId, onIdChanged, navigation, noUrlNav) => {
  // if server returns a new id
  // - web: replace docId in url
  // - mobile: call modifyDoc with new id
  if (!noUrlNav && Platform.OS === "web") {
    const pathname = navigation.location.pathname.split("?")[0];
    navigation.replace(`${pathname}?docId=${encodeURIComponent(newDocId)}`);
  }
  onIdChanged?.(newDocId);
};

export const saveDocToDb =
  ({
    doc,
    lastDocRequests,
    fn,
    onLicenceErr,
    forceSave,
    userId,
    options,
    profile,
    company,
    manager,
    t,
    setProgress,
    callback,
    navigation,
    onIdChanged,
    customToken,
    noUrlNav,
    uniqueSite,
    uniqueDate,
    lastDocSaveDate,
  }) =>
  (dispatch) => {
    let docWasModified = false;

    if (lastDocSaveDate) {
      if (lastDocSaveDate.current === doc.date) {
        fn && fn();
        callback && callback(doc, docWasModified);
        return;
      }
      lastDocSaveDate.current = doc.date;
    }

    if (
      doc.demo ||
      (doc.id && (doc.id.startsWith("doc_demo") || doc.id.endsWith("tmp")))
    ) {
      fn && fn();
      callback && callback(doc, docWasModified, "demoDoc");
      return;
    }
    try {
      let _doc = doc;
      if (!_doc.creatorId) {
        let syncObj = SYNC_VALUES_TIMESTAMPS_FN(userId, doc);
        // dispatch(addDoc);
        _doc = update(doc, {
          creatorId: syncObj.creatorId,
          timestamps: (x) => update(x || {}, syncObj.timestamps),
        });
      }
      const errMsg = i18next.t("docSaveFailed");
      if (forceSave || lastDocRequests?.[_doc.id]?.saved !== _doc.date) {
        fetchNetInfo().then((state) => {
          if (state.isConnected) {
            dispatch(requestApiData());

            try {
              const docLayoutObject = options?.layouts?.[_doc.layoutId];
              const docLayout = docLayoutObject?.versions[_doc.layoutVersion];

              if (
                !doc.id.startsWith("local") &&
                (docLayout?.generateName || _doc.values.docNameTemplate)
              ) {
                let templateStringSources;
                let _values = _doc.values;

                const docLang = getDocLanguage(doc, docLayout, profile);
                const specialInputsProps = getSpecialInputsProps(
                  _values,
                  docLayout,
                  options,
                  docLang
                );

                _values = specialInputsProps._values;
                const worstValues = specialInputsProps._worstValues;

                if (!docLayout) {
                  errorReport({
                    error: `missing docLayout ${_doc?.id}, ${_doc?.layoutId}, ${_doc?.layoutVersion}`,
                    errorInFn: "saveDocToDb",
                    errorInScreen: "saveDocToDb",
                    dontShowToast: true,
                  });
                }

                const mergedSources = mergeSources(docLayout, options);

                templateStringSources =
                  replaceAlternateSources(mergedSources, {
                    options,
                    profile,
                    company,
                    manager,
                    doc: {
                      ..._doc,
                      values: _values,
                    },
                    worstValues,
                  }) || {};

                let docNameFromTemplate = _doc.values.docNameTemplate;

                if (
                  // only generate name is user hasn't overridden it
                  !docNameFromTemplate &&
                  docLayout.generateName &&
                  (docLayout.generateName.checks
                    ? validateChecks({
                        docLang,
                        checks: docLayout.generateName.checks,
                        values: doc.values,
                        role: profile?.role,
                      })
                    : true)
                ) {
                  docNameFromTemplate = getTranslatedText(
                    docLayout.generateName.text,
                    docLang
                  );
                }

                if (docNameFromTemplate) {
                  docNameFromTemplate = replaceTemplateString(
                    docNameFromTemplate,
                    null,
                    "text",
                    undefined,
                    templateStringSources,
                    _doc,
                    docLayout,
                    profile
                  ).newValue;

                  docNameFromTemplate = parseToFileName(docNameFromTemplate);

                  if (docNameFromTemplate !== _doc.name) {
                    if (profile?.role === "Trial") {
                      _doc.name = docNameFromTemplate;
                      dispatch(
                        setDocProp({
                          docId: _doc.id,
                          prop: "name",
                          value: docNameFromTemplate,
                        })
                      );
                    } else {
                      putWithToken(
                        { id: _doc.id, name: docNameFromTemplate },
                        "/docs/rename"
                      ).then((res) => {
                        if (res.status === 204) {
                          _doc.name = docNameFromTemplate;
                          dispatch(
                            setDocProp({
                              docId: _doc.id,
                              prop: "name",
                              value: docNameFromTemplate,
                            })
                          );
                        }
                      });
                    }
                  }
                }
              }
            } catch (error) {
              errorReport({
                error,
                errorInFn: "saveDocToDb",
                errorInScreen: "saveDocToDb",
                dontShowToast: true,
                errorParams: {
                  doc,
                  lastDocRequests: !!lastDocRequests,
                  fn: !!fn,
                  onLicenceErr: !!onLicenceErr,
                  forceSave: !!forceSave,
                  userId: !!userId,
                  options: !!options,
                  profile: !!profile,
                  company: !!company,
                  manager: !!manager,
                  t: !!t,
                  setProgress: !!setProgress,
                },
              });
            }

            const _saveDoc = () => {
              return apiRequestWithToken(
                {
                  doc: _doc,
                  uniqueSite,
                  uniqueDate,
                },
                "/docs/save",
                customToken
              )
                .then(async (response) => {
                  if (response.status === 200) {
                    docWasModified = true;

                    const _docId = response.data.newDocId || _doc.id;

                    if (response.data.newDocId) {
                      dispatch(
                        CHANGE_UNFINISHED_DOC_ID({
                          oldDocId: _doc.id,
                          newDocId: response.data.newDocId,
                        })
                      );
                      handleDocIdUpdated(
                        response.data.newDocId,
                        onIdChanged,
                        navigation,
                        noUrlNav
                      );
                    }

                    const _payload = {
                      rootValues: response.data.rootValues,
                      values: response.data.values,
                      timestamps: response.data.timestamps,
                      currentValueKey: response.data.currentValueKey,
                      date: response.data.date, //_doc.date, //
                      docId: _docId,
                    };

                    dispatch(DOC_SAVE_MERGE(_payload));

                    fn && fn(response.data.newDocId);

                    if (callback) {
                      let _state = {
                        unfinishedDocs: { [_docId]: { ..._doc, id: _docId } },
                      };
                      const _stateAfterMerge = DOC_SAVE_MERGEFn(_state, {
                        payload: _payload,
                      });
                      callback(
                        _stateAfterMerge.unfinishedDocs[_docId],
                        docWasModified,
                        null,
                        response.data.newDocId
                      );
                    }

                    dispatch(receiveResponse());
                  } else if (response.status === 204) {
                    dispatch(receiveResponse());
                    fn && fn();
                    callback && callback(_doc, docWasModified);
                  } else if (response.status === 400) {
                    showToast(errMsg);
                    dispatch(receiveResponse());
                    fn && fn();
                    callback && callback(_doc, docWasModified, "error");
                  } else if (response.status === 423) {
                    showToast(i18next.t("docAlreadyCompleted"), 3000, "accent");
                    dispatch(clearModifyDoc());
                    dispatch(
                      REMOVE_DOCS({
                        docIds: [_doc.id],
                      })
                    );
                    callback && callback(_doc, docWasModified, "completedDoc");
                  } else if (response === "forbidden") {
                    dispatch(receiveResponse());
                    showToast(i18next.t("docSaveLicenceErr"));
                    onLicenceErr && onLicenceErr();
                    callback && callback(_doc, docWasModified, "forbidden");
                  } else if (
                    response === "expired" ||
                    response === "tokenErr"
                  ) {
                    dispatch(signOut());
                    showExpiredTokenToast();
                  } else if (response === "licenceErr") {
                    // TODO should show company frozen error if company is frozen
                    dispatch(clearModifyDocAction());
                    dispatch(setLicence("expired"));
                    showToast(i18next.t("docAddLicenceErr"));
                    onLicenceErr && onLicenceErr();
                    callback && callback(_doc, docWasModified, "licenceErr");
                  } else {
                    if (response !== "networkErr") {
                      showToast(errMsg);
                    }
                    dispatch(receiveResponse());
                    fn && fn();
                    callback && callback(_doc, docWasModified, "error");
                  }
                })
                .catch(() => {
                  showToast(errMsg);
                  dispatch(receiveResponse());
                  fn && fn();
                  callback && callback(_doc, docWasModified, "error");
                });
            };

            let addCalls = [];
            let removeCalls = [];

            const handleAttachmentsSynced = () => {
              if (addCalls.length > 0 || removeCalls.length > 0) {
                let _state = { unfinishedDocs: { [_doc.id]: _doc } };
                removeCalls.forEach((payload) => {
                  _state = REMOVE_OBJECT_ARR_ITEMFn(_state, { payload });
                  dispatch(REMOVE_OBJECT_ARR_ITEM(payload));
                });
                addCalls.forEach((payload) => {
                  _state = ADD_TO_OBJECT_ARRFn(_state, { payload });
                  dispatch(ADD_TO_OBJECT_ARR(payload));
                });
                _doc = _state.unfinishedDocs[_doc.id];
                _saveDoc();
              } else {
                _saveDoc();
              }
            };

            const _ADD_ATTACHMENT = (payload) => {
              dispatch(ADD_ATTACHMENT(payload));
            };
            const _REMOVE_ATTACHMENTS = (payload) => {
              dispatch(removeAttachments(payload));
            };
            const _setProgress = (payload) => {
              setProgress && setProgress(payload);
            };
            syncDocAttachments({
              netState: state,
              doc: _doc,
              options: options,
              callback: handleAttachmentsSynced,
              profile: profile,
              setProgress: _setProgress,
              REMOVE_OBJECT_ARR_ITEM: (payload) => removeCalls.push(payload),
              ADD_TO_OBJECT_ARR: (payload) => addCalls.push(payload),
              ADD_ATTACHMENT: _ADD_ATTACHMENT,
              REMOVE_ATTACHMENTS: _REMOVE_ATTACHMENTS,
              screen: "saveDocToDb",
              t: t,
              customToken,
            });
          } else {
            //showToast(errMsg);
            fn && fn();
            callback && callback(_doc, docWasModified, "networkErr");
          }
        });
      } else {
        fn && fn();
        callback && callback(_doc, docWasModified, "notSaved");
      }
    } catch {
      showToast(i18next.t("docSaveFailed"));
      dispatch(receiveResponse());
      fn && fn();
      callback && callback(doc, docWasModified, "error");
    }
  };

export const addDocToDb =
  (doc, lastDocRequests, calledOnClose, fn, forceSave) => (dispatch) => {
    try {
      const errMsg = i18next.t("docSaveFailed");
      if (lastDocRequests?.[doc.id]?.saved !== doc.date || forceSave) {
        fetchNetInfo().then((state) => {
          if (state.isConnected) {
            dispatch(requestApiData());
            apiRequestWithToken(doc, "/docs/add")
              .then(async (response) => {
                if (response.status === 200) {
                  dispatch(
                    updateLastDocRequests({
                      id: doc.id,
                      obj: { saved: doc.date },
                    })
                  );
                  dispatch(receiveResponse());
                  fn && fn();
                } else if (response.status === 400) {
                  showToast(errMsg);
                  dispatch(receiveResponse());
                  fn && fn();
                } else if (response === "expired" || response === "tokenErr") {
                  dispatch(signOut());
                  showExpiredTokenToast();
                } else if (response === "licenceErr") {
                  // TODO should show company frozen error if company is frozen
                  dispatch(clearModifyDocAction());
                  dispatch(setLicence("expired"));
                  showToast(i18next.t("docAddLicenceErr"));
                } else {
                  showToast(errMsg);
                  dispatch(receiveResponse());
                  fn && fn();
                }
              })
              .catch(() => {
                showToast(errMsg);
                dispatch(receiveResponse());
                fn && fn();
              });
          } else {
            showToast(errMsg);
            fn && fn();
          }
        });
      } else {
        fn && fn();
      }
    } catch {
      showToast(i18next.t("docSaveFailed"));
      dispatch(receiveResponse());
      fn && fn();
    }
  };

export const removeAlreadyDeletedDocs = (docType, docIds) => (dispatch) => {
  const _docIds = Object.keys(docIds).filter((x) =>
    Platform.OS === "web" ? docIds[x] === "mobile" : docIds[x] === "web"
  );

  if (_docIds.length > 0) {
    dispatch(REMOVE_DOCS({ docIds: _docIds }));

    dispatch(CLEAR_IF_MODIFYING(_docIds));

    // if (_docIds.includes(modiFyDoc)) {
    //   dispatch(clearModifyDocAction());
    // }

    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        dispatch(requestApiData());
        let url = "/docs/syncRemoved";
        return apiRequestWithToken({ DocsToDelete: _docIds }, url).then(
          (response) => {
            if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
            } else {
              dispatch(receiveResponse());
            }
          }
        );
      }
    });
  }
};

export const removeDocs =
  (docType, docIds, callback, onError) => (dispatch) => {
    let url = "";
    if (docType === "unfinishedDocs") url = "/docs/remove";
    else {
      callback && callback(false);
      return;
    }

    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        dispatch(requestApiData());

        return apiRequestWithToken({ DocsToDelete: docIds }, url)
          .then((response) => {
            if (response.status === 200) {
              dispatch(CLEAR_IF_MODIFYING(docIds));
              // if (docIds.includes(modiFyDoc)) {
              //   dispatch(clearModifyDocAction());
              // }
              dispatch(REMOVE_DOCS({ docIds }));
              callback && callback(true);
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              showExpiredTokenToast();
              onError && onError();
            } else if (response === "failure") {
              dispatch(receiveResponse());
              showToast(i18next.t("docRemoveFailed"));
              onError && onError();
            } else if (response === "timeout") {
              dispatch(receiveResponse());
              showToast(i18next.t("lostConnectionToServer"));
              onError && onError();
            } else {
              dispatch(receiveResponse());
              showToast(i18next.t("docRemoveFailed"));
              onError && onError();
            }
          })
          .catch((err) => {
            showToast(i18next.t("unhandledError") + ": " + err);
            onError && onError();
          });
      } else {
        showToast(i18next.t("checkInternetConnection"));
        onError && onError();
      }
    });
  };

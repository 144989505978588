import { forwardRef } from "react";
import { VictoryAxis, VictoryPie, VictoryChart } from "victory";

export default forwardRef((props, ref) => {
  const _data = Array.isArray(props.data)
    ? props.data.map((item) => {
        return {
          ...item,
          x: item.x,
          y: parseFloat(item.y),
        };
      })
    : [];
  const total = _data.reduce((sum, item) => sum + item.y, 0); // Calculate total sum

  const height = props.height ?? 350;
  const width = props.width ?? 350;
  return (
    <VictoryChart ref={ref} width={width} height={height}>
      <VictoryPie
        data={_data}
        // ! doesn't work very well with different sizes
        // labelRadius={height / 4}
        labels={({ datum }) => {
          return (
            datum.l ||
            (total > 0 ? `${((datum.y / total) * 100).toFixed(1)}%` : undefined)
          );
        }}
        style={{
          data: {
            fill: ({ datum }) => {
              return datum?.fill;
            },
            stroke: "#fff", // Optional stroke around each slice
            strokeWidth: 2,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{ axis: { stroke: "none" }, tickLabels: { fill: "none" } }} // Hide Y-axis
      />
    </VictoryChart>
  );
});

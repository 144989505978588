import "react";
import { Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "./Modal";
import ButtonGroup from "./ButtonGroup";

export default function ModalWithButtons(props) {
  const { t } = useTranslation();

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.onToggleModal}
          onBackdropPress={props.onToggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={[theme.flex, { justifyContent: "space-between" }]}>
            {props.title ? (
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={[
                    {
                      padding: 8,
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  ]}
                >
                  <Text style={theme.text}>{props.title}</Text>
                </View>
              </View>
            ) : null}

            {props.children}

            <View style={[theme.modalButtonContainer, { marginTop: 8 }]}>
              <ButtonGroup
                buttons={[
                  {
                    backgroundColor: colors.lightAccent,
                    color: colors.accent,
                    title: t("back"),
                    onPress: () => {
                      props.onToggleModal();
                    },
                  },
                  props.onSavePress
                    ? {
                        icon: "content-save",
                        title: t("save"),
                        disabled: props.isFetching,
                        loading: props.isFetching,
                        onPress: () => props.onSavePress(),
                      }
                    : null,
                ]}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Platform } from "react-native";
import queryString from "query-string";
import useWindowDimensions from "../functions/useWindowDimensions";
import { useHistory } from "react-router-dom";

import DailySiteDocument from "../../../common/screens/DailySiteDocument";

function DailySiteDocumentWrapper() {
  let history = useHistory();
  const { width, height } = useWindowDimensions();

  let urlParams;
  let layoutId;
  if (Platform.OS === "web") {
    let search;
    if (history?.location?.search) search = history.location.search;
    else search = history.location.pathname.split("?")[1];
    urlParams = queryString.parse(search, { parseBooleans: true });
    layoutId = urlParams.layoutId;
  }

  return (
    <DailySiteDocument
      navigation={history}
      pageW={width}
      pageH={height}
      layoutId={layoutId}
    />
  );
}

export default DailySiteDocumentWrapper;

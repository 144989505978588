import { useState } from "react";
import { View, FlatList } from "react-native";
import ListPickerItem from "../components/ListPickerItem";

import {
  canAccessLayout,
  getTranslatedText,
  isArrayWithItems,
} from "../lib/functions";
import { fallbackLangCodes, layoutCategories } from "../lib/constants";
import StretchButton from "./StretchButton";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SET_DONT_SHOW_AGAIN,
  SET_UI_SETTINGS_PROP,
} from "../actions/ProfileActions";
import IconButton from "./IconButton";
import Alert from "./AlertModal";

const _layoutCategories = [...layoutCategories, "selected"];
const _defaultCustomCategories = ["selected", "everything"];
const forcedDatabase = __DEV__ ? null : null;
function ListPicker({
  t,
  db,
  data = {},
  refreshing = false,
  // onRefresh = () => null, // TODO rework
  setValue,
  lang,
  globalLogo,
  icon,
  itemSpinner,
  theme,
  colors,
  role,
  headerItem,
  languageCodes,
  layoutPickerCategories,
  layoutPickerCustomCategories,
  layoutPickerCustomSelection,
  SET_UI_SETTINGS_PROP,
  dontShowAgainLayoutPickerEdit,
  SET_DONT_SHOW_AGAIN,
}) {
  const [editing, setEditing] = useState(false);
  const [alert, setAlert] = useState({ visible: false });
  const filterWithCategory = isArrayWithItems(layoutPickerCategories);
  const _languageCodes = languageCodes ?? fallbackLangCodes;

  const _data = forcedDatabase
    ? Object.keys(data).filter(
        (x) =>
          !data[x].extraDoc &&
          data[x].sharedTo.includes(forcedDatabase) &&
          (!filterWithCategory ||
            editing ||
            layoutPickerCategories.includes(data[x].category) ||
            (layoutPickerCategories.includes("selected") &&
              isArrayWithItems(layoutPickerCustomSelection) &&
              layoutPickerCustomSelection.includes(x)))
      )
    : role === "Admin" || role === "SuperAdmin"
    ? Object.keys(data).filter(
        (x) =>
          !filterWithCategory ||
          editing ||
          layoutPickerCategories.includes(data[x].category) ||
          (layoutPickerCategories.includes("selected") &&
            isArrayWithItems(layoutPickerCustomSelection) &&
            layoutPickerCustomSelection.includes(x))
      )
    : Object.keys(data).filter(
        (x) =>
          !data[x].extraDoc &&
          (!filterWithCategory ||
            editing ||
            layoutPickerCategories.includes(data[x].category) ||
            (layoutPickerCategories.includes("selected") &&
              isArrayWithItems(layoutPickerCustomSelection) &&
              layoutPickerCustomSelection.includes(x)))
      );

  // TODO move styles to theme
  const renderHeader = () => {
    const haveCustomColumns = isArrayWithItems(layoutPickerCustomCategories);
    const everythingTagIsVisible =
      haveCustomColumns && layoutPickerCustomCategories.includes("everything");
    return (
      <View style={{ backgroundColor: colors.primary }}>
        {headerItem ? headerItem : null}

        <View style={theme.rowContainer}>
          <View style={theme.flexWrapRow}>
            {_layoutCategories.map((category) => {
              const translatedCategory = t(category);
              const isSelected =
                layoutPickerCategories &&
                layoutPickerCategories.includes(category);
              const isInCustomColumns =
                haveCustomColumns &&
                layoutPickerCustomCategories.includes(category);
              const isVisible = editing || isInCustomColumns;
              if (isVisible)
                return (
                  <View key={category}>
                    <StretchButton
                      rounded
                      noFlexDirection
                      endIcon={
                        editing
                          ? isInCustomColumns
                            ? "check-circle"
                            : "checkbox-blank-circle-outline"
                          : null
                      }
                      buttonStyle={theme.hPadding8}
                      buttonTextStyle={theme.buttonCaption}
                      title={translatedCategory}
                      color={
                        editing || !isSelected
                          ? colors.text
                          : colors.textOnAccent
                      }
                      backgroundColor={
                        editing || !isSelected
                          ? colors.secondary
                          : colors.accent
                      }
                      onPress={() => {
                        if (editing) {
                          SET_UI_SETTINGS_PROP({
                            prop: "layoutPickerCustomCategories",
                            value: category,
                            isArray: true,
                          });
                        } else {
                          SET_UI_SETTINGS_PROP({
                            prop: "layoutPickerCategories",
                            value: category,
                            isArray: true,
                          });
                        }
                      }}
                    />
                  </View>
                );
              return null;
            })}

            {editing || everythingTagIsVisible ? (
              <View>
                <StretchButton
                  rounded
                  noFlexDirection
                  endIcon={
                    editing
                      ? everythingTagIsVisible
                        ? "check-circle"
                        : "checkbox-blank-circle-outline"
                      : null
                  }
                  buttonStyle={theme.hPadding8}
                  buttonTextStyle={theme.buttonCaption}
                  title={t("everything")}
                  color={
                    editing || filterWithCategory
                      ? colors.text
                      : colors.textOnAccent
                  }
                  backgroundColor={
                    editing || filterWithCategory
                      ? colors.secondary
                      : colors.accent
                  }
                  onPress={() => {
                    if (editing) {
                      SET_UI_SETTINGS_PROP({
                        prop: "layoutPickerCustomCategories",
                        value: "everything",
                        isArray: true,
                      });
                    } else {
                      SET_UI_SETTINGS_PROP({
                        prop: "layoutPickerCategories",
                        value: undefined,
                      });
                    }
                  }}
                />
              </View>
            ) : null}
          </View>

          <View style={theme.flexShrink}>
            <IconButton
              size={42}
              iconSize={28}
              icon={editing ? "cog" : "cog-outline"}
              onPress={() => {
                if (dontShowAgainLayoutPickerEdit) {
                  setEditing((_) => !_);
                } else {
                  setAlert({
                    dontShowAgainBox: true,
                    visible: true,
                    title: t("attention"),
                    text: t("layoutPickerInfo"),
                    leftButtonTitle: t("cancel"),
                    rightButtonTitle: t("ok"),
                    cancelButton: true,
                    onRightButtonPress: (val) => {
                      SET_DONT_SHOW_AGAIN({
                        prop: "LayoutPickerEdit",
                        val,
                      });
                      setEditing((_) => !_);
                    },
                  });
                }
              }}
            />
          </View>
        </View>
      </View>
    );
  };
  return (
    <View style={theme.halfContainer}>
      <View style={theme.listWrapper}>
        <FlatList
          ListHeaderComponent={renderHeader} // Header at the top
          stickyHeaderIndices={[0]} // Make the header sticky
          style={theme.fill}
          data={_data}
          initialNumToRender={15}
          keyExtractor={(id) => id}
          // onRefresh={onRefresh}
          refreshing={refreshing}
          ItemSeparatorComponent={<View style={theme.padding4} />}
          renderItem={({ item, index }) => {
            const layoutWrapper = data[item];
            const layout =
              layoutWrapper.versions[
                Math.max(...Object.keys(layoutWrapper.versions))
              ];

            let title = getTranslatedText(
              layoutWrapper.title ?? layoutWrapper.type,
              lang
            );

            return (
              <ListPickerItem
                editing={editing}
                isSelected={
                  editing
                    ? isArrayWithItems(layoutPickerCustomSelection) &&
                      layoutPickerCustomSelection.includes(item)
                    : false
                }
                itemIndex={index}
                theme={theme}
                colors={colors}
                title={title}
                subtitle={
                  layout.languages
                    ? `${layout.languages
                        .map((x) => {
                          const found = _languageCodes.find(
                            (y) => y.code === x
                          );
                          return found ? found.name : x ? x.toUpperCase() : "-";
                        })
                        .join(", ")}`
                    : null
                }
                item={item}
                itemSpinner={itemSpinner}
                startLogo={
                  !canAccessLayout(role, forcedDatabase ?? db, data[item], true)
                    ? globalLogo
                    : null
                }
                icon={icon}
                setValue={setValue}
                onSelect={(item) => {
                  SET_UI_SETTINGS_PROP({
                    prop: "layoutPickerCustomSelection",
                    value: item,
                    isArray: true,
                  });
                }}
              />
            );
          }}
        />
        {editing ? (
          <View style={theme.buttonContainer}>
            <StretchButton
              title={t("save")}
              onPress={() => setEditing(false)}
            />
          </View>
        ) : null}
      </View>

      <Alert toggleAlert={() => setAlert({ visible: false })} {...alert} />
    </View>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SET_UI_SETTINGS_PROP,
      SET_DONT_SHOW_AGAIN,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    layoutPickerCategories: state.userInfo.uiSettings?.layoutPickerCategories,
    layoutPickerCustomCategories:
      state.userInfo.uiSettings?.layoutPickerCustomCategories ||
      _defaultCustomCategories,
    layoutPickerCustomSelection:
      state.userInfo.uiSettings?.layoutPickerCustomSelection,
    dontShowAgainLayoutPickerEdit: state.userInfo.dontShowAgainLayoutPickerEdit,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPicker);

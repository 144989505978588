import { useEffect, useState, useRef } from "react";
import { Platform, TouchableOpacity, Text, View, FlatList } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, fetchNetInfo } from "../lib/helperFns";
import Icon from "./Icon";
import SearchBarElem from "./SearchBarElem";
import Modal from "./Modal";
import InputWithWarnings from "./InputWithWarnings";
import { apiRequestWithToken } from "../lib/api";

import moment from "moment";
import { getTranslatedText, errorReport, getDocType } from "../lib/functions";
import StretchButton from "./StretchButton";

function DocSearchModal(props) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [statustext, setStatusText] = useState("");
  const [docName, setDocName] = useState("");
  const [docDesc, setDocDesc] = useState("");
  const [doc, setDoc] = useState(null);
  const docDescRef = useRef(null);
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (loading) {
      search(loading);
    }
  }, [loading]);

  const fetchDefaultSearch = () => {
    if (props.defaultSearch) {
      setLoading(getTranslatedText(props.defaultSearch, props.lang));
    }
  };

  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        fetchDefaultSearch();
        searchBarRef.current?.focus();
      }, 500);
    }
  }, [props.visible, searchBarRef.current]);

  const search = (_searchText) => {
    // TODO search only from completed docs
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        return apiRequestWithToken(
          { SearchWord: _searchText.toLowerCase().replace(/[\s]/gi, "") },
          "/docs/search"
        )
          .then((response) => {
            if (response.status === 200) {
              if (Array.isArray(response.data) && response.data.length < 1) {
                setStatusText(t("noDocsFound"));
              } else {
                response.data.sort((a, b) => moment(b.date) - moment(a.date));
                setItems(response.data);
              }
              setLoading(null);
            } else {
              errorReport({
                error: response,
                errorInScreen: "DocSearchModal",
                errorInFn: "search",
                errorMsg: t("unhandledError"),
              });
              setLoading(null);
            }
          })
          .catch((error) => {
            errorReport({
              error,
              errorInScreen: "DocSearchModal",
              errorInFn: "search catch",
              errorMsg: t("unhandledError"),
            });
            setLoading(null);
          });
      } else {
        setStatusText(t("checkInternetConnection"));
        setLoading(null);
      }
    });
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const setItem = () => {
    if ((props.valueKey || props.valueKey == 0) && props.docId) {
      props.addToObjectArr({
        type: "Atch",
        value: {
          id: doc.id,
          docRef: doc.docRef,
          name: docName || doc.docRef,
          desc: docDesc,
        },
        sortProp: "name",
        idProp: "id",
        valueKey: props.valueKey,
        docId: props.docId,
      });
      setDoc(null);
      setDocName("");
      setDocDesc("");
      props.onToggleModal();
    } else {
      errorReport({
        error: "Missing valueKey in DocSearchModal setItem",
        errorInScreen: "DocSearchModal",
        errorInFn: "setItem",
        errorMsg: t("unhandledError"),
      });
    }
  };

  const getDocInputs = (theme) => {
    return [
      <View key={"InputWithWarningsView1"} style={theme.rowTextInputWrapper}>
        <InputWithWarnings
          selectTextOnFocus={true}
          value={docName}
          placeholder={doc.docRef}
          placeholderTextColor={"#aaa"}
          style={theme.modalTextInput}
          onChangeText={(text) => {
            const textToUse = text.replace(/\r?\n|\r/g, "");
            if (statustext) setStatusText("");
            setDocName(textToUse);
          }}
          onSubmitEditing={() => {
            docDescRef.current?.focus();
          }}
          blurOnSubmit={false}
          warnings={false}
          label={t("name")}
        />
      </View>,
      <View key={"InputWithWarningsView22"} style={theme.rowTextInputWrapper}>
        <InputWithWarnings
          ref={docDescRef}
          value={docDesc}
          style={theme.modalTextInput}
          onChangeText={(text) => {
            const textToUse = text.replace(/\r?\n|\r/g, "");
            if (statustext) setStatusText("");
            setDocDesc(textToUse);
          }}
          onSubmitEditing={setItem}
          blurOnSubmit={true}
          warnings={true}
          label={t("desc")}
        />
      </View>,
    ];
  };

  const renderHeader = (theme, colors) => {
    return (
      <SearchBarElem
        ref={searchBarRef}
        autoFocus={Platform.OS === "web" ? true : false}
        placeholder={t("search")}
        containerStyle={[
          theme.zeroPaddingAndMargin,
          { backgroundColor: colors.primary, height: 36 },
        ]}
        inputContainerStyle={[
          theme.inputContainerStyle,
          { backgroundColor: colors.primary, height: 36 },
        ]}
        inputStyle={theme.searchBarInput}
        clearIcon={
          <TouchableOpacity onPress={() => clearSearch()}>
            <Icon name={"close"} size={24} color={colors.text} />
          </TouchableOpacity>
        }
        searchIcon={<Icon name={"magnify"} size={24} color={colors.text} />}
        onChangeText={(text) => {
          if (statustext) setStatusText("");
          setSearchText(text);
        }}
        onSubmitEditing={() => setLoading(searchText)}
        showLoading={loading}
        value={searchText}
        onClear={clearSearch}
        loadingProps={{
          color: colors.accent,
        }}
      />
    );
  };

  const renderSeparator = (theme, colors) => {
    return (
      <View style={[theme.line, { borderBottomColor: colors.secondary }]} />
    );
  };

  const onDocPress = (item) => {
    if (props.onDocPress) {
      props.onDocPress(item);
    } else {
      setDoc(item);
    }
  };

  const renderListElem = (theme, colors) => {
    return doc ? (
      getDocInputs(theme)
    ) : (
      <FlatList
        keyboardShouldPersistTaps="handled"
        initialNumToRender={15}
        data={items}
        keyExtractor={(item, index) => "SearchedDocs" + index}
        ItemSeparatorComponent={() => renderSeparator(theme, colors)}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => onDocPress(item)}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                margin: 8, //wp(4),
                padding: 8, //wp(2),
                borderWidth: 1,
                borderColor: colors.accent,
              }}
            >
              <View style={[theme.rowContainer]}>
                <Text style={theme.text}>{`${t("type")}: `}</Text>
                <Text style={theme.text}>
                  {getDocType(item.docType, item.type, props.lang) ||
                    item.docRef}
                </Text>
              </View>
              <View style={[theme.rowContainer]}>
                <Text style={theme.text}>{`${t("date")}: `}</Text>
                <Text style={theme.text}>
                  {moment(item.date).format("HH:mm DD.MM.YYYY")}
                </Text>
              </View>
              <View style={[theme.rowContainer]}>
                <Text style={theme.text}>{`${t("foundValue")}: `}</Text>
                <Text style={theme.text}>{item.match}</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
      />
    );
  };
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.onToggleModal}
          onBackdropPress={props.onToggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View
            style={
              Platform.OS === "web"
                ? {
                    maxHeight: "90vh",
                    overflow: "hidden",
                    backgroundColor: colors.primary,
                  }
                : theme.modalListContainer
            }
          >
            {statustext ? (
              <Text style={[theme.title, { padding: 8, color: colors.text }]}>
                {statustext}
              </Text>
            ) : null}
            {renderHeader(theme, colors)}
            {Platform.OS === "web" ? (
              <View
                testID={"modularListContainer"}
                style={{
                  flex: 8,
                  display: "block",
                  overflowY: "auto",
                }}
              >
                {renderListElem(theme, colors)}
              </View>
            ) : (
              renderListElem(theme, colors)
            )}

            <View>
              <View
                style={[theme.line, { borderBottomColor: colors.secondary }]}
              />
              <View style={theme.modalButtonContainer}>
                <StretchButton
                  title={doc ? t("cancel") : t("close")}
                  onPress={doc ? () => setDoc(null) : props.onToggleModal}
                />
                <StretchButton
                  title={doc ? t("attach") : t("search")}
                  disabled={loading}
                  onPress={doc ? setItem : () => setLoading(searchText)}
                />
              </View>
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

export default DocSearchModal;

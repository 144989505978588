import "react";

export default function TimePicker(props) {
  // Generate an array for hours (12 or 24-hour format)
  const hours = [...Array(24).keys()].map((h) => String(h).padStart(2, "0"));
  // Generate an array for minutes
  const minutes = [...Array(60).keys()].map((m) => String(m).padStart(2, "0"));

  // Handle hour change
  const handleHourChange = (e) => {
    if (props.onChange) {
      const newDate = props.value.clone();
      newDate.hours(e.target.value);
      props.onChange(newDate);
    }
  };

  // Handle minute change
  const handleMinuteChange = (e) => {
    if (props.onChange) {
      const newDate = props.value.clone();
      newDate.minutes(e.target.value);
      props.onChange(newDate);
    }
  };

  return (
    <div className="time-picker">
      <select value={props.value.format("HH")} onChange={handleHourChange}>
        {hours.map((h) => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </select>
      :
      <select value={props.value.format("mm")} onChange={handleMinuteChange}>
        {minutes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>
    </div>
  );
}

import "react";
import PdfDocument from "../components/PdfDocument";

export default function Pdf(props) {
  return (
    <PdfDocument
      noClass={true}
      page={props.page}
      doc={props.source}
      src={props.src}
      height={props.pageH}
      width={props.pageW}
      pageW={props.pageW}
      hideFooter={props.hideFooter}
      wrapperStyle={props.wrapperStyle}
      INITIAL_PAGE_WIDTH={props.INITIAL_PAGE_WIDTH}
      pdfDocumentOverflow={props.pdfDocumentOverflow}
      openSettings={props.openSettings}
      onDownload={props.onDownload}
      customToken={props.customToken}
    />
  );
}

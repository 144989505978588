import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { ThemeContext } from "../../common/theming/theme-context";
import Icon from "../../common/components/Icon";
import CheckBoxElem from "../../common/components/CheckBoxElem";
import { withTranslation } from "react-i18next";

import GDPR from "./GDPR";
import TOSModal from "./TOSModal";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setInputValues,
  setInputErr,
} from "../../common/actions/ProfileActions";

class AgreementCheckBoxRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gdprModal: false,
      tosModal: false,
    };
  }

  onValueChange = (prop, value) => {
    if (this.props.setValue) {
      this.props.setValue(value ?? !this.props.value);
    } else {
      this.props.setInputValues({ [prop]: value ?? !this.props.inputs[prop] });
      this.props.setInputErr({ [prop]: "" });
    }
  };

  onModalClose = () => {
    this.setState({ gdprModal: false, tosModal: false });
  };

  onModalAccept = () => {
    this.onValueChange(this.props.prop, true);
    this.setState({ gdprModal: false, tosModal: false });
  };

  render() {
    let { selectedTheme, theme, colors } = this.context;
    const { prop, text } = this.props;
    return (
      <View style={theme.agreementCheckBoxRow}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
          }}
        >
          <Text
            numberOfLines={4}
            style={[
              theme.text,
              {
                color:
                  this.props.error || this.props.errors[prop]
                    ? "red"
                    : colors.text,
              },
            ]}
          >
            {text}
            {prop === "gdpr" ? (
              <Text
                style={[
                  theme.boldText,
                  {
                    color: selectedTheme === "dark" ? colors.accent : "#0000EE",
                  },
                ]}
                onPress={() => this.setState({ gdprModal: true })}
              >
                {" "}
                {this.props.t("privacyStatementPossessive").toLowerCase()}
              </Text>
            ) : null}
            {prop === "tos" ? (
              <Text
                style={[
                  theme.boldText,
                  {
                    color: selectedTheme === "dark" ? colors.accent : "#0000EE",
                  },
                ]}
                onPress={() => this.setState({ tosModal: true })}
              >
                {" "}
                {this.props.t("agreementAndTOS").toLowerCase()}
              </Text>
            ) : null}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.onValueChange(prop);
          }}
        >
          <CheckBoxElem
            containerStyle={{ padding: 0, margin: 0 }}
            checkedColor={colors.accent}
            size={36}
            checked={this.props.value ?? this.props.inputs[prop]}
            uncheckedIcon={
              <Icon name="checkbox-blank" size={36} color={colors.checkBox} />
            }
            checkedIcon={
              <Icon name="checkbox-marked" size={36} color={colors.accent} />
            }
            onPress={() => {
              this.onValueChange(prop);
            }}
          />
        </TouchableOpacity>
        {prop === "gdpr" ? (
          <GDPR
            isVisible={this.state.gdprModal}
            onGDPRClose={this.onModalClose}
            onGDPRAccept={this.onModalAccept}
          />
        ) : null}
        {prop === "tos" ? (
          <TOSModal
            isVisible={this.state.tosModal}
            onClose={this.onModalClose}
            onAccept={this.onModalAccept}
          />
        ) : null}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setInputValues,
      setInputErr,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  inputs: state.userInfo.inputs,
  errors: state.userInfo.errors,
});

AgreementCheckBoxRow.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AgreementCheckBoxRow));

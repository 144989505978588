import "react";
import { TouchableOpacity, Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import Icon from "./Icon";

export default function TitleRow(
  props = {
    title: String,
    screen: String,
    handleTitleClick: Function,
    disabled: Boolean,
  }
) {
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <TouchableOpacity
          onPress={() => props.handleTitleClick(props.screen)}
          style={{ flex: 1, opacity: props.disabled ? 0.6 : 1 }}
          disabled={!!props.disabled}
        >
          <View
            style={[
              theme.rowContainer,
              {
                paddingTop: 8,
                paddingBottom: 8,
                backgroundColor: colors.accent,
              },
            ]}
          >
            <View
              style={{
                paddingLeft: 8,
              }}
            >
              <Text style={[theme.boldText, { color: colors.textOnAccent }]}>
                {props.title}
              </Text>
            </View>
            <View style={{ paddingRight: 8 }}>
              <Icon
                name={"arrow-right"}
                size={24}
                color={colors.textOnAccent}
              />
            </View>
          </View>
        </TouchableOpacity>
      )}
    </ThemeContext.Consumer>
  );
}

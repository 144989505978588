import { useState, useRef } from "react";
import { Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import TextInput from "./TextInput";
import IconButton from "./IconButton";
import { Tooltip } from "./Tooltip";
import { fullHp } from "../lib/helperFns";

export default function MultilineTextInputRow(props) {
  const [focused, setFocused] = useState(false);
  const input = useRef(null);
  const [inputHeight, setInputHeight] = useState(null);
  const maxInputHeight = fullHp(50);

  const handleContentSizeChange = (event) => {
    const { height } = event.nativeEvent.contentSize;
    if (height !== inputHeight) {
      setInputHeight(height < maxInputHeight ? height : maxInputHeight);
    }
  };

  const handleTextChange = (text) => {
    props.setTargetProperty(props.prop, text);

    // Forcefully trigger height update when text changes
    setTimeout(() => {
      // Wait for content to adjust and re-check size
      handleContentSizeChange({ nativeEvent: { contentSize: { height: 0 } } });
    }, 0);
  };

  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <View
          pointerEvents={props.disabled ? "none" : "auto"}
          style={[
            {
              flex: 1,
              opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
              alignItems: "flex-start",
            },
          ]}
        >
          {props.title ? (
            <View style={theme.titleRow}>
              <View style={theme.textWrapper}>
                {props.onClear ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    onPress={props.onClear}
                    backgroundColor={colors.lightBg}
                    icon="close"
                    color={colors.text}
                  />
                ) : null}

                <View style={theme.textContainer}>
                  <Text
                    style={[
                      theme.boldText,
                      { color: props.error ? "red" : colors.text },
                    ]}
                  >
                    {props.title}
                  </Text>
                </View>
              </View>

              {props.icons ? (
                <View style={theme.row}>
                  {props.icons.map((x) => (
                    <IconButton
                      key={x.icon}
                      theme={theme}
                      loading={x.loading}
                      onPress={x.onPress}
                      disabled={props.disabled}
                      icon={x.icon}
                      backgroundColor={x.backgroundColor ?? colors.accent}
                      color={x.textColor ?? colors.textOnAccent}
                    />
                  ))}
                </View>
              ) : null}

              {props.hint || props.hintTable ? (
                <View style={theme.hPadding}>
                  <Tooltip
                    hintTable={props.hintTable}
                    tip={props.hint}
                    flex={0}
                    icon={true}
                    lang={props.lang}
                  />
                </View>
              ) : null}
            </View>
          ) : null}

          <View
            style={[
              theme.rowContainer,
              {
                backgroundColor: colors.lightBg,
                borderColor: focused ? colors.accent : colors.lightBg,
                borderWidth: 1,
              },
            ]}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: "100%",
                width: "100%",
              }}
            >
              <TextInput
                orientation={orientation}
                disableFullscreenUI={false}
                ref={(ref) => {
                  input.current = ref;
                  if (props.setCellRef) props.setCellRef(ref);
                }}
                editable={!props.disabled}
                style={[
                  theme.textInput,
                  theme.text,
                  {
                    width: "100%",
                    outline: "none",
                    height: inputHeight ? Math.max(40, inputHeight) : 40, // Use 40 as a fallback if height is not set yet
                    maxHeight: maxInputHeight, // Limit max height here
                  },
                ]}
                onContentSizeChange={handleContentSizeChange}
                placeholder={"-"}
                placeholderTextColor={colors.text}
                value={props.value}
                onFocus={() => {
                  if (!props.disabled) setFocused(true);
                }}
                onBlur={() => {
                  props.onBlur && props.onBlur();
                  setFocused(false);
                }}
                onChangeText={handleTextChange}
                multiline={true}
                autoFocus={props.autoFocus}
              />
              {props.unit ? (
                <Text style={theme.boldText}>{` ${props.unit} `}</Text>
              ) : null}
            </View>
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

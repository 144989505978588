import "react";
import { TouchableOpacity, Text, View } from "react-native";
import Icon from "./Icon";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "../components/Tooltip";
import StretchButton from "./StretchButton";
import { useTranslation } from "react-i18next";

export function ModalButton(
  props = {
    title: String,
    text: String,
    boldTitle: Boolean,
    onModalPress: Function,
    longTitle: Boolean,
    sideBorders: Boolean,
    disabled: Boolean,
  }
) {
  const { t } = useTranslation();

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={theme.flexContainer}>
          <View style={theme.rowContainer}>
            <View style={theme.rowContainer}>
              {props.onDelete && props.text ? (
                <TouchableOpacity
                  onPress={() => props.onDelete(props.text)}
                  disabled={!!props.disabled}
                >
                  <View
                    style={[
                      {
                        minHeight: 36,
                        width: 36,
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        backgroundColor: colors.lightBg,
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: props.disabled ? 0.6 : 1,
                      },
                    ]}
                  >
                    <Icon
                      name={"trash-can-outline"}
                      size={24}
                      color={colors.text}
                    />
                  </View>
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity
                testID={props.testID}
                accessibilityLabel={props.testID}
                style={theme.flex}
                onPress={props.onTextPress}
                disabled={!props.onTextPress}
              >
                <View
                  style={[
                    //theme.halfContainer,
                    theme.rowContainer,
                    {
                      flex: props.longTitle ? 2.5 : 1,
                      opacity:
                        props.disabled && !props.noDisabledStyle ? 0.6 : 1,
                    },
                  ]}
                >
                  <Text
                    style={[
                      props.boldTitle ?? true ? theme.boldText : theme.text,
                      {
                        paddingLeft: 8,
                        paddingRight: 8,
                        color: props.error ? "red" : colors.text,
                      },
                    ]}
                    //numberOfLines={2}
                  >
                    {props.title}
                  </Text>
                  {props.onTextPress ? (
                    <Icon
                      name={props.textEndIcon || "chevron-down"}
                      size={24}
                      color={colors.text}
                    />
                  ) : null}
                </View>
              </TouchableOpacity>
              {props.hint || props.hintTable ? (
                <View style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <Tooltip
                    hintTable={props.hintTable}
                    tip={props.hint}
                    flex={0}
                    icon={true}
                    lang={props.lang}
                  />
                </View>
              ) : null}
            </View>

            <StretchButton
              title={
                props.multiModalPickerButton
                  ? t("select")
                  : props.text || t("select")
              }
              onPress={props.onModalPress}
              disabled={!!props.disabled}
              noDisabledStyle={props.noDisabledStyle}
              loading={props.isFetching}
              endIcon={"chevron-right"}
            />
          </View>
          {props.children}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

import { useEffect } from "react";
import { Animated, Platform } from "react-native";
import {
  Defs,
  Marker,
  Path,
  Line,
  Ellipse,
  Circle,
  Rect,
  G,
  Text,
} from "react-native-svg";
import { svgElemTypes, pinSvgPath } from "../../../common/lib/constants";

const selectRectSize = 8;

const AnimatedG = Animated.createAnimatedComponent(G);
const AnimatedRect = Animated.createAnimatedComponent(Rect);
const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const AnimatedEllipse = Animated.createAnimatedComponent(Ellipse);
const AnimatedText = Animated.createAnimatedComponent(Text);
const AnimatedLine = Animated.createAnimatedComponent(Line);

//const elem1 = `<g font-size="11" font-weight="400" stroke="#000" stroke-linecap="square"><path d="M-12-1v24h24V-1h-24" fill-rule="evenodd" stroke-linejoin="bevel" fill="#fff"/><path d="M-2 17l4-4m3-1l4-4m-11 5l4 4m3-9l4 4m-18 0l4-4m2 7h6m1-5h6M-9 8l4 4m5 0v6M7 7v6m-17-3h6m-3-3v6" stroke-miterlimit="2" fill="none"/></g>`;

const AnimatedSvgElem = (props) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      const elem = document.getElementById("Svg" + props.index);
      if (elem) {
        const rect = elem.getBoundingClientRect();
        props.setElemCoordinates(
          props.elem,
          props.elem.x,
          props.elem.y,
          rect.width,
          rect.height
        );
      }
    }
  }, []);

  const reverseScale = 1 / props.elem.scale;
  const rectSize = selectRectSize * (1 / props.viewZoomLevel) * reverseScale;
  const strokeWidth = 2 * reverseScale;
  const resizeStrokeWidth = 1 * reverseScale;

  const rotatable = svgElemTypes[props.elem.type].rotatable;
  return (
    <>
      <AnimatedG
        rotation={props.elem.animRotation}
        // style={{
        //   transform: [
        //     {
        //       rotate: props.elem.animRotation.interpolate({
        //         inputRange: [0, 360],
        //         outputRange: ["0deg", "360deg"],
        //       }),
        //     },
        //   ],
        // }}
        scale={props.elem.scale}
        originX={props.elem.origin.x}
        originY={props.elem.origin.y}
      >
        {/* <SvgCss
          xml={elem1}
          x={props.elem.x}
          y={props.elem.y}
          originX={props.elem.x + props.elem.width / 2}
          originY={props.elem.y + props.elem.height / 2}
        /> */}
        {props.elem.type === "1" || props.elem.type === "2" ? (
          <AnimatedRect
            x={props.elem.animX}
            y={props.elem.animY}
            width={props.elem.animWidth}
            height={props.elem.animHeight}
            stroke={props.elem.stroke}
            strokeWidth={strokeWidth}
            fill={props.elem.fill}
            fillOpacity={props.elem.fill ? 1 : 0}
            // stroke="rgb(0,0,0)"
          />
        ) : props.elem.type === "3" ? (
          [
            <AnimatedCircle
              key="Circle"
              cx={props.elem.c.x}
              cy={props.elem.c.y}
              r={props.elem.r}
              stroke={props.elem.stroke}
              strokeWidth={strokeWidth}
              fill={props.elem.fill}
              fillOpacity={props.elem.fill ? 1 : 0}
            />,
            <AnimatedRect
              key="CircleR"
              x={props.elem.animX}
              y={props.elem.animY}
              width={props.elem.animWidth}
              height={props.elem.animHeight}
              fillOpacity={0}
            />,
          ]
        ) : props.elem.type === "4" ? (
          [
            <AnimatedEllipse
              key="El"
              cx={props.elem.c.x}
              cy={props.elem.c.y}
              rx={props.elem.r.x}
              ry={props.elem.r.y}
              stroke={props.elem.stroke}
              strokeWidth={strokeWidth}
              fill={props.elem.fill}
              fillOpacity={props.elem.fill ? 1 : 0}
            />,
            <AnimatedRect
              key="ElR"
              x={props.elem.animX}
              y={props.elem.animY}
              width={props.elem.animWidth}
              height={props.elem.animHeight}
              fillOpacity={0}
            />,
          ]
        ) : props.elem.type === "5" ? (
          <AnimatedG x={props.elem.animX} y={props.elem.animY}>
            <AnimatedText
              key="Text"
              collapsable={false}
              id={"Svg" + props.index}
              onLayout={(object) => {
                const { width, height } = object.nativeEvent.layout;
                if (!props.elem.width || !props.elem.height) {
                  props.setElemCoordinates(
                    props.elem,
                    props.elem.x,
                    props.elem.y,
                    width,
                    height
                  );
                }
              }}
              fill={props.elem.stroke}
              fontSize={props.elem.animFontSize}
              fontWeight="bold"
              x={0}
              y={props.elem.textHeight}
            >
              {props.elem.text}
            </AnimatedText>
            <AnimatedRect
              key="TextR"
              x={0}
              y={0}
              width={props.elem.animWidth}
              height={props.elem.animHeight}
              fillOpacity={0}
            />
          </AnimatedG>
        ) : props.elem.type === "6" ? (
          <AnimatedLine
            x1={props.elem.animX}
            y1={props.elem.animY}
            x2={props.elem.animX2}
            y2={props.elem.animY2}
            stroke={props.elem.stroke}
            strokeWidth="2"
          />
        ) : props.elem.type === "7" ? (
          <>
            <Defs>
              <Marker
                id="Arrow"
                //viewBox="0 0 10 10"
                refX="0"
                refY="1"
                markerUnits="strokeWidth"
                markerWidth="12"
                markerHeight="12"
                orient="auto"
              >
                <Path
                  d={`M 0 0 L ${strokeWidth} ${
                    strokeWidth / 2
                  } L 0 ${strokeWidth} z`}
                  fill={props.elem.stroke}
                />
              </Marker>
            </Defs>

            <AnimatedLine
              x1={props.elem.animX}
              y1={props.elem.animY}
              x2={props.elem.animX2}
              y2={props.elem.animY2}
              stroke={props.elem.stroke}
              strokeWidth="2"
              markerEnd="url(#Arrow)"
            />

            {/* <AnimatedPath
              d={`M${props.elem.animX} ${props.elem.animY}, L${props.elem.animX2} ${props.elem.animY2}`}
              stroke={props.elem.stroke}
              strokeWidth="2"
              markerEnd="url(#Arrow)"
            /> */}
          </>
        ) : props.elem.type === "100" ? (
          [
            Platform.OS === "web" ? (
              <G x={props.elem.x - props.elem.width / 3} y={props.elem.y}>
                <Path
                  id={"Svg" + props.index}
                  onLayout={(object) => {
                    const { width, height } = object.nativeEvent.layout;
                    if (!props.elem.width || !props.elem.height) {
                      props.setCoords({
                        width,
                        height,
                      });
                    }
                  }}
                  d={pinSvgPath}
                  stroke={props.elem.stroke}
                  strokeWidth={strokeWidth}
                  fill="red"
                  fillRule="evenodd"
                />
              </G>
            ) : (
              <Path
                id={"Svg" + props.index}
                onLayout={(object) => {
                  const { width, height } = object.nativeEvent.layout;
                  if (!props.elem.width || !props.elem.height) {
                    props.setCoords({
                      width,
                      height,
                    });
                  }
                }}
                d={pinSvgPath}
                stroke={props.elem.stroke}
                strokeWidth={strokeWidth}
                x={props.elem.x - props.elem.width / 3}
                y={props.elem.y}
                fill="red"
                fillRule="evenodd"
              />
            ),
            // <AnimatedRect
            //   key="TextR"
            //   style={{ zIndex: 1000 }}
            //   x={props.elem.x - 2.5}
            //   y={props.elem.y - 2.5}
            //   stroke="red"
            //   width={(props.elem.width ?? 0) + 5}
            //   height={(props.elem.height ?? 0) + 5}
            //   fillOpacity={0}
            // />,
          ]
        ) : null}
      </AnimatedG>

      {props.selected ? (
        <AnimatedG
          rotation={props.elem.animRotation}
          scale={props.elem.scale}
          originX={props.elem.origin.x}
          originY={props.elem.origin.y}
          // x={props.elem.x}
          // y={props.elem.y}
        >
          {props.elem.type !== "6" && props.elem.type !== "7" ? (
            props.elem.width && props.elem.height ? (
              <>
                <AnimatedRect
                  key="TL"
                  style={{ cursor: "nw-resize" }}
                  // x={props.elem.x - halfRectSize}
                  // y={props.elem.y - halfRectSize}
                  x={props.elem.tlRect.x}
                  y={props.elem.tlRect.y}
                  width={rectSize}
                  height={rectSize}
                  stroke={"#000"}
                  strokeWidth={resizeStrokeWidth}
                  fill={"#fff"}
                  fillOpacity={1}
                />

                {rotatable ? (
                  <>
                    <AnimatedRect
                      //rotate line
                      x={props.elem.rLineRect.x}
                      y={props.elem.rLineRect.y}
                      width={2 * reverseScale}
                      height={40 * reverseScale}
                      stroke={"#000"}
                      strokeWidth={resizeStrokeWidth}
                      fill={"#fff"}
                      fillOpacity={1}
                    />

                    <AnimatedRect
                      //rotate point
                      style={{ cursor: "grab" }}
                      x={props.elem.r1Rect.x}
                      y={props.elem.r1Rect.y}
                      width={rectSize}
                      height={rectSize}
                      stroke={"#000"}
                      strokeWidth={resizeStrokeWidth}
                      fill={"#fff"}
                      fillOpacity={1}
                    />
                    <AnimatedRect
                      //middle point
                      x={props.elem.r2Rect.x}
                      y={props.elem.r2Rect.y}
                      width={rectSize}
                      height={rectSize}
                      stroke={"#000"}
                      strokeWidth={resizeStrokeWidth}
                      fill={"#fff"}
                      fillOpacity={1}
                    />
                  </>
                ) : null}

                <AnimatedRect
                  key="TR"
                  style={{ cursor: "ne-resize" }}
                  x={props.elem.trRect.x}
                  y={props.elem.trRect.y}
                  width={rectSize}
                  height={rectSize}
                  stroke={"#000"}
                  strokeWidth={resizeStrokeWidth}
                  fill={"#fff"}
                  fillOpacity={1}
                />

                <AnimatedRect
                  key="BR"
                  style={{ cursor: "se-resize" }}
                  x={props.elem.brRect.x}
                  y={props.elem.brRect.y}
                  width={rectSize}
                  height={rectSize}
                  stroke={"#000"}
                  strokeWidth={resizeStrokeWidth}
                  fill={"#fff"}
                  fillOpacity={1}
                />
                <AnimatedRect
                  key="BL"
                  style={{ cursor: "sw-resize" }}
                  x={props.elem.blRect.x}
                  y={props.elem.blRect.y}
                  width={rectSize}
                  height={rectSize}
                  stroke={"#000"}
                  strokeWidth={resizeStrokeWidth}
                  fill={"#fff"}
                  fillOpacity={1}
                />
              </>
            ) : null
          ) : (
            <>
              <AnimatedRect
                key="LX1"
                style={{ cursor: "nw-resize" }}
                x={props.elem.l1xRect}
                y={props.elem.l1yRect}
                width={rectSize}
                height={rectSize}
                stroke={"#000"}
                strokeWidth={resizeStrokeWidth}
                fill={"#fff"}
                fillOpacity={1}
              />
              <AnimatedRect
                key="LX2"
                style={{ cursor: "ne-resize" }}
                x={props.elem.l2xRect}
                y={props.elem.l2yRect}
                width={rectSize}
                height={rectSize}
                stroke={"#000"}
                strokeWidth={resizeStrokeWidth}
                fill={"#fff"}
                fillOpacity={1}
              />
            </>
          )}
        </AnimatedG>
      ) : null}
    </>
  );
};

export default AnimatedSvgElem;

import "react";
import { Dimensions } from "react-native";
import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";
import Resizer from "./resizeImg";
import localForage from "localforage";
import html2canvas from "html2canvas";
import moment from "moment";
// import { getWithToken } from "./api";
import { Canvg } from "canvg";
import mime from "mime";
import {
  errorReport,
  parseLocation,
  base64ToBlob,
  reverseGeocode,
} from "./functions";
import i18next from "i18next";
import { versionNum } from "./versionNum";
import { customAlphabet } from "nanoid";

export const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-",
  10
);
export const hp = (val) => ((Dimensions.get("window").height / 100) * val) / 2;
export const fullHp = (val) => (Dimensions.get("window").height / 100) * val;
export const wp = (val) => ((Dimensions.get("window").width / 100) * val) / 2.5;
export const fullWp = (val) => (Dimensions.get("window").width / 100) * val;

export const getMimeExtension = mime.getExtension;

export const captureSentryError = (error) => {
  Sentry.captureException(error);
};

export const captureSentryMessage = (msg) => {
  errorReport({
    error: msg,
    errorInScreen: "captureSentryMessage",
    errorInFn: "captureSentryMessage",
    errorMsg: msg,
  });
};

export const getAppVersion = () => {
  return versionNum;
};

function mockFunction() {
  return null;
}

// function s2ab(s) {
//   var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
//   var view = new Uint8Array(buf); //create uint8array as viewer
//   for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
//   return buf;
// }

// const recursiveDocFetchFromFolderPath = async (
//   token,
//   currentPath,
//   docs,
//   docsFinished,
//   employeeDocs
// ) => {
//   let _docs = docs || [];
//   let _docsFinished = docsFinished || [];
//   let _employeeDocs = employeeDocs || [];
//   const res = await getWithToken("/folders?path=" + currentPath, token);
//   if (res.status === 200) {
//     _docs = _docs.concat(res.data.docs);
//     _docsFinished = _docsFinished.concat(res.data.docsFinished);
//     _employeeDocs = _employeeDocs.concat(res.data.employeeDocs);
//     if (res.data.folders) {
//       for (let i = 0; i < res.data.folders.length; i++) {
//         const x = res.data.folders[i];
//         const res2 = await recursiveDocFetchFromFolderPath(
//           token,
//           currentPath + "," + x.id,
//           _docs,
//           _docsFinished,
//           _employeeDocs
//         );
//         _docs = res2.docs;
//         _docsFinished = res2.docsFinished;
//         _employeeDocs = res2.employeeDocs;
//       }
//     }
//   }
//   return {
//     docs: _docs,
//     docsFinished: _docsFinished,
//     employeeDocs: _employeeDocs,
//   };
// };
// const excelFromDocs = (currentPath) => async () => {
//   try {
//     const { docs, docsFinished, employeeDocs } =
//       await recursiveDocFetchFromFolderPath(currentPath);

//     const _docs = docs.concat(docsFinished).concat(employeeDocs);
//     var wb = XLSX.utils.book_new();

//     wb.Props = {
//       Title: "SheetJS Tutorial",
//       Subject: i18next.t("test"),
//       Author: "Red Stapler",
//       CreatedDate: new Date(2017, 12, 19),
//     };

//     wb.SheetNames.push("Sheet1");

//     // var ws_data = [["hello", "world"]]; //a row with 2 columns
//     let ws_data = [];
//     ws_data.push([
//       i18next.t("site"),
//       i18next.t("propertyManager"),
//       i18next.t("pointsOfOffer"),
//       i18next.t("unitAmount"),
//       i18next.t("additionalInfo2"),
//       i18next.t("conditionClassification"),
//     ]);
//     // 13
//     _docs.forEach((doc) => {
//       if (doc.layoutId === "docLayouts/124") {
//         const valueEntries = Object.entries(doc.values);
//         let row = [doc.values.sites_2?.name, doc.values.customers_25?.name];
//         let col3 = [];
//         let col4 = [];

//         if (doc.values?.["13"]) {
//           doc.values["13"].forEach((x) => {
//             // 14, 15, 16 first filled value
//             const values = valueEntries.filter(
//               ([key, value]) =>
//                 key.startsWith("13_" + x.valueKey) &&
//                 (key.endsWith("14") || key.endsWith("15") || key.endsWith("16"))
//             );
//             const firstFilled = values.find((x) => !!x?.[1]);
//             col3.push(firstFilled?.[1] || "");
//             const amount = valueEntries.find(
//               ([key, value]) =>
//                 key.startsWith("13_" + x.valueKey) && key.endsWith("17")
//             );
//             col4.push(amount?.[1] || 0);
//           });
//           row.push(col3.join("\n"));
//           row.push(col4.join("\n"));
//         } else {
//           row.push("");
//           row.push("");
//         }
//         row.push(doc.values["12"]);
//         row.push(doc.values["24"]);
//         ws_data.push(row);
//       }
//     });
//     // _docs.forEach((doc) => {
//     //   ws_data.push([doc.values.sites_2?.name || i18next.t("noSiteName")]);
//     //   ws_data.push([doc.values.customers_25?.name || i18next.t("noSiteName")]);
//     //   doc.values["6"].forEach((value) => {
//     //     ws_data.push([value]);
//     //   });
//     //   doc.values["7"].split("\n").forEach((value) => {
//     //     ws_data.push([value]);
//     //   });
//     //   ws_data.push([doc.values["12"]]);
//     //   ws_data.push([doc.values["24"]]);
//     //   ws_data.push([]);
//     // });
//     var ws = XLSX.utils.aoa_to_sheet(ws_data);
//     // var wscols = [
//     //   { wch: 100 },
//     //   // {wch: 6}, // "characters"
//     //   // {wpx: 50}, // "pixels"
//     //   // ,
//     //   // {hidden: true} // hide column
//     // ];
//     // ws["!cols"] = wscols;

//     wb.Sheets[i18next.t("sheet1")] = ws;

//     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
//     showToast(
//       i18next.t("compiled") + " " + _docs.length + " dokumenttia",
//       3000,
//       "accent"
//     );
//     saveAs(
//       new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
//       "file.xlsx"
//     );
//     return wbout;
//   } catch (error) {
//     errorReport({
//       error: error,
//       errorInScreen: "excelFromDocs",
//       errorInFn: "excelFromDocs",
//       errorMsg: i18next.t("compilingFailed"),
//     });
//   }
// };

export const createExcelFromDocs = () =>
  // currentPath
  {
    return;
    // var loadJS = function (url, implementationCode, location) {
    //   //url is URL of external file, implementationCode is the code
    //   //to be called from the file, location is the location to
    //   //insert the <script> element
    //   var scriptTag = document.createElement("script");
    //   scriptTag.src = url;
    //   scriptTag.onload = implementationCode;
    //   scriptTag.onreadystatechange = implementationCode;
    //   location.appendChild(scriptTag);
    // };
    // loadJS(
    //   "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js",
    //   excelFromDocs(currentPath),
    //   document.body
    // );
  };

export const Canvasg = Canvg;

export const modalPickerOpts = {
  backdropOpacity: 0.9,
  animationInTiming: 500,
  backdropTransitionInTiming: 0,
  backdropTransitionOutTiming: 0,
  avoidKeyboard: false,
  propagateSwipe: true,
  hideModalContentWhileAnimating: true,
  deviceHeight: fullHp(100),
};

// callback, saveAttachmentsToPhotos
export const handleCameraOpen = (
  callback,
  saveAttachmentsToPhotos,
  inputEl
) => {
  if (!inputEl?.current) {
    showToast(i18next.t("cameraLaunchFailed"));
    return null;
  } else {
    inputEl.current.setAttribute("capture", "camera");
    inputEl.current.click();
    inputEl.current.removeAttribute("capture");
  }
};

export const handlePictureTaken = ({
  response,
  navProps,
  navigateFn,
  navigation,
  callback,
}) => {
  // https://github.com/react-native-image-picker/react-native-image-picker#the-response-object
  if (Array.isArray(response.assets) && response.assets.length > 0) {
    const data = response.assets[0];

    let imageObj = {
      uri: data.uri,
    };

    const splitUri = imageObj.uri.split("/");
    const splitFileName = splitUri.pop().split(".");
    imageObj.ext = splitFileName.pop();
    const imgName = splitFileName.join("").split("_").pop();
    imageObj.name = imgName;
    imageObj.tmpName = imgName;
    imageObj.type = data.type;
    imageObj.newImg = true;

    if (callback) {
      callback([imageObj]);
    } else if (navigateFn) {
      navigateFn("addAttachments", navigation, {
        attachments: [imageObj],
        ...navProps,
      });
    }
  } else if (response.errorCode) {
    errorReport({
      error: response.errorMessage,
      errorInScreen: "FilePickerRows",
      errorInFn: "handlePictureTaken",
      errorMsg: i18next.t("picTakingError"),
      dontNavigate: true,
    });
  }
};

export const pLimit = () => null;
export const PQueue = () => null;
export const pThrottle = () => null;

export const getLocation = async (
  accessToken,
  SET_COMPANY_SETTINGS_PROP,
  gpsLocation,
  SET_GPS_LOCATION,
  setLoading,
  callBack,
  cellLocation
) => {
  //return "";
  const diff = moment
    .duration(moment(moment()).diff(gpsLocation?.date))
    .asMinutes();
  const needWeather =
    cellLocation?.types &&
    cellLocation.types.some((x) => x.startsWith("currentWeather"));
  const needToUpdateWeather =
    needWeather &&
    (!gpsLocation?.currentWeather?.date ||
      moment
        .duration(moment(moment()).diff(gpsLocation.currentWeather.date))
        .asMinutes() > 1);

  if (!gpsLocation?.date || diff > 1 || needToUpdateWeather) {
    //console.warn(i18next.t("geolocationStatuses"), statuses);
    setLoading?.(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        reverseGeocode(
          accessToken,
          SET_COMPANY_SETTINGS_PROP,
          position.coords.latitude,
          position.coords.longitude,
          needWeather
        )
          .then((res) => {
            if (res) {
              //console.warn(i18next.t("geolocationGeocode"), res.features?.[0]);
              SET_GPS_LOCATION({ gpsLocation: res });
              if (callBack && cellLocation) {
                callBack(
                  parseLocation(res, cellLocation, res.currentWeather),
                  res
                );
              }
            } else {
              console.warn(res);
              showToast(i18next.t("locationFetchFailed"));
              callBack?.();
            }
            setLoading?.(false);
          })
          .catch((error) => {
            console.warn(error);
            showToast(i18next.t("locationFetchFailed"));
            setLoading?.(false);
            callBack?.();
          });
      },
      (error) => {
        console.warn(error);
        showToast(i18next.t("locationFetchFailed"));
        setLoading?.(false);
        callBack?.();
      },
      {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 10000,
      }
    );
  } else {
    if (callBack && cellLocation) {
      callBack(parseLocation(gpsLocation, cellLocation), gpsLocation);
    }
    setLoading?.(false);
  }
};

export const setNavParams = () => {
  return true;
};

export const setNavOptions = () => {
  return true;
};

export const navigate = (route, history, params) => {
  history.push({
    pathname: "/" + route,
    state: { ...history.state, ...params },
  });
};

export const replaceTab = (route, history, params) => {
  history.replace({
    pathname: "/" + route,
    state: { ...history.state, ...params },
  });
};

export const goBack = (history) => {
  history.goBack();
};

export const getChartFromHTML = (key) => {
  return html2canvas(document.getElementById("chart" + key /*key*/)).then(
    (canvas) => canvas.toDataURL("image/png")
  );
};

export const viewShot = (id, options) =>
  html2canvas(document.getElementById(id), options).then((canvas) =>
    canvas.toDataURL("image/png")
  );

export const onDocumentPicker = () => null;

export const checkPermission = () => {};

export const handleDocumentPicker = async () => {};

export const RNGoogleSignin = null;

const toastElem = (t, msg) => (
  <div style={{ whiteSpace: "pre-wrap" }} onClick={() => toast.dismiss(t.id)}>
    {msg}
  </div>
);
export function showToast(msg, duration, color, textColor) {
  toast((t) => toastElem(t, msg, color), {
    duration: duration ?? 5000,
    style: color
      ? color === "accent"
        ? { marginTop: 80 }
        : {
            marginTop: 80,
            backgroundColor: color === "green" ? "#14A44D" : color,
            color: textColor,
          }
      : { marginTop: 80, backgroundColor: "red", color: textColor },
  });
}

export function showExpiredTokenToast() {
  toast.dismiss();
  toast((t) => toastElem(t, i18next.t("sessionExpiredLoginAgain")));
}

export const convertHeic = async () => {
  // const inputBuffer = await blob.arrayBuffer();
  // const outputBuffer = await convert({
  //   buffer: inputBuffer, // the HEIC file buffer
  //   format: "PNG", // output format
  // });
  // return Promise.resolve(outputBuffer.toString("base64"));
};

export const resizeFile = (
  file,
  width,
  height,
  format,
  quality,
  rotation = 0,
  outputType = "base64"
) => {
  if (isNaN(width) || width < 1 || isNaN(height) || height < 1)
    throw "Invalid dimensions for resizer";

  format = format === "JPG" ? "JPEG" : format;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      format || "JPEG",
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType,
      width,
      height
    );
  });
};

export const resizeImg = async (
  uri,
  width,
  height,
  format,
  quality,
  rotation,
  outputPath,
  item,
  keepMeta,
  uriIsBase64
) => {
  const type = item.type || "image/jpg";
  if (isNaN(width) || width < 1 || isNaN(height) || height < 1)
    throw "Invalid dimensions for resizer";
  if (uriIsBase64) {
    return resizeFile(
      new File([base64ToBlob(uri, type)], item.name + "." + item.ext, {
        type: type,
      }),
      width,
      height,
      format,
      quality,
      rotation
    );
  } else {
    const itemBase64 = await localForage.getItem(uri);
    if (itemBase64) {
      return resizeFile(
        new File([base64ToBlob(itemBase64, type)], item.name + "." + item.ext, {
          type: type,
        }),
        width,
        height,
        format,
        quality,
        rotation
      );
    } else throw i18next.t("noAtchFound");
  }
};

export const setNetInfoListener = () => {
  return mockFunction;
};

export const fetchNetInfo = () => {
  return Promise.resolve({
    isConnected: navigator.onLine,
    isInternetReachable: navigator.onLine,
  });
};

export const setNavigationListener = () => {
  return mockFunction;
};

// export function lockToPortrait() {}
// export function lockToLandscapeLeft() {}
// export function addOrientationListener(fn) {
//   return mockFunction;
// }
// export function removeOrientationListener(fn) {
//   return mockFunction;
// }
// export function addDeviceOrientationListener(fn) {
//   return mockFunction;
// }
// export function removeDeviceOrientationListener(fn) {
//   return mockFunction;
// }
// export function getDeviceOrientation(fn) {
//   return fn("PORTRAIT");
// }

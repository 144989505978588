import React from "react";
import { Keyboard, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { connect } from "react-redux";
import StretchButton from "./StretchButton";

class DualButtons extends React.Component {
  constructor(props) {
    super(props);
    this.keyboardDidShowSub = Keyboard.addListener(
      "keyboardDidShow",
      this.keyboardDidShow
    );
    this.keyboardDidHideSub = Keyboard.addListener(
      "keyboardDidHide",
      this.keyboardDidHide
    );
    this.state = {
      keyboard: false,
    };
  }

  keyboardDidShow = () => {
    this.setState({ keyboard: true });
  };

  keyboardDidHide = () => {
    this.setState({ keyboard: false });
  };

  componentWillUnmount() {
    this.keyboardDidShowSub.remove();
    this.keyboardDidHideSub.remove();
  }

  render() {
    let { theme, colors } = this.context;
    return (
      <View
        style={{
          //height: 36,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <View style={theme.buttonContainer}>
          {this.props.leftButtonDisabled ? (
            <View />
          ) : (
            <StretchButton
              color={colors.text}
              backgroundColor={colors.lightBg}
              title={this.props.leftButtonTitle}
              onPress={this.props.onLeftButtonPress}
              disabled={this.props.isFetching}
              loading={this.props.isFetching}
            />
          )}

          {this.props.onMiddleButtonPress && this.props.middleButtonTitle ? (
            <StretchButton
              color={colors.text}
              backgroundColor={colors.borderLighter}
              title={this.props.middleButtonTitle}
              onPress={this.props.onMiddleButtonPress}
              disabled={this.props.isFetching}
              loading={this.props.isFetching}
            />
          ) : null}

          {this.props.rightButtonDisabled ? (
            <View />
          ) : (
            <StretchButton
              title={this.props.rightButtonTitle}
              onPress={() => {
                Keyboard.dismiss();
                this.props.onRightButtonPress();
              }}
              disabled={this.props.isFetching}
              loading={this.props.isFetching}
              iconWhenLoading
            />
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.isFetching,
});

DualButtons.contextType = ThemeContext;

export default connect(mapStateToProps)(DualButtons);

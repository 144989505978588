import "react";
import PublicHeaderBar from "../components/PublicHeaderBar";
import useWindowDimensions from "../functions/useWindowDimensions";
import { Helmet } from "react-helmet";
import InfoFooter from "../components/InfoFooter";
import { useTranslation } from "react-i18next";

export default function PricingScreen() {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const dataPricing = [
    {
      title: t("additionalWork"),
      price: `80,00€/${t("hour").toUpperCase()}`,
    },
    {
      title: t("systemIntroduction"),
      price: `180,00€/${t("event").toUpperCase()}`,
    },
    {
      title: t("eidentifyPrice"),
      price: `0,50€/${t("unitAmount").toUpperCase()}`,
      label: t("eidentifyInfo"),
    },
  ];

  return (
    <div
      className="infoContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{`${t("pricing")} - Fondion Docs`}</title>
        <meta name="description" content={t("doceanAppPricing")} />
      </Helmet>

      <PublicHeaderBar />

      <div
        style={{
          backgroundColor: "#f0f0f0",
          paddingTop: 96,
          paddingBottom: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div className={"pricingCard"} style={{ width: width, maxWidth: 800 }}>
          <div className={"pricingTitle"}>
            <p style={{ fontSize: 32 }}>{t("fixedMonthlyFee")}</p>
          </div>

          <div className={"pricingTitle"}>
            <div className={"flex-row"}>
              <p className={"licencePriceText"}>79€</p>
              <div style={{ paddingLeft: 8 }}></div>
              <p className={"pricingText"}>{`/ ${t("monthShort")}`}</p>
            </div>
          </div>

          <div className={"pricingTitle"}>
            <p style={{ fontSize: 32 }}>{t("users")}</p>
          </div>

          <div className={"pricingTitle"}>
            <div className={"flex-row"}>
              <p className={"licencePriceText"}>15€</p>
              <div style={{ paddingLeft: 8 }}></div>
              <p className={"pricingText"}>
                {`/ ${t("user")} / ${t("monthShort")}`}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              width: "100%",
              justifyContent: "center",
              paddingBottom: 32,
            }}
          >
            <p className={"pricingTextCentered"}>{t("limitlessDocuments")}</p>
          </div>

          {dataPricing.map((row) => (
            <div
              key={row.title}
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                //index !== dataPricing.length - 1 ? "solid" : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  backgroundColor: "#09151a",
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: "#FFF",
                }}
              >
                <p
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginBottom: row.label ? 8 : 16,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  {row.title}
                </p>

                {row.label ? (
                  <p
                    style={{
                      fontSize: 12,
                      margin: 0,
                      marginBottom: 16,
                      padding: 0,
                      paddingLeft: 16,
                      paddingRight: 16,
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    {row.label}
                  </p>
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  backgroundColor: "#f0f0f0",
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: "#000",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "right",
                  }}
                >
                  {row.price}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <InfoFooter />
    </div>
  );
}

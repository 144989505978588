import { useRef, useEffect } from "react";
import { View } from "react-native";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "./Modal";
import InputWithWarnings from "./InputWithWarnings";
import { ThemeContext } from "../theming/theme-context";
import ButtonGroup from "./ButtonGroup";
import { useTranslation } from "react-i18next";

export default function DataObjModal(props) {
  const { t } = useTranslation();
  const textInput = useRef(null);

  useEffect(() => {
    if (textInput.current) textInput.current.focus();
  }, [props.label]);

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visibility}
          onBackButtonPress={props.toggleModal}
          onBackdropPress={props.toggleModal}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          onModalShow={() => {
            textInput.current?.focus();
          }}
        >
          <View style={[theme.modalInputContainer, { paddingBottom: 0 }]}>
            <View style={theme.modalInputsWrapper}>
              <View style={theme.textInputWrapper}>
                <InputWithWarnings
                  ref={textInput}
                  label={props.label}
                  autoFocus={true}
                  value={props.name}
                  onChangeText={(text) => props.setTempObjName(text)}
                  onSubmitEditing={() => props.setDataObj()}
                  warnings={true}
                  err={props.err}
                  keyboardType={props.keyboardType}
                  selectTextOnFocus={props.selectTextOnFocus}
                  maxLength={props.maxLength}
                  blurOnSubmit={props.blurOnSubmit}
                />
              </View>

              <View style={[theme.modalButtonContainer, { marginTop: 8 }]}>
                <ButtonGroup
                  buttons={[
                    {
                      backgroundColor: colors.lightAccent,
                      color: colors.accent,
                      title: t("close"),
                      onPress: () => props.toggleModal(),
                    },
                    ...(props.extraButtons ? props.extraButtons : []),
                    {
                      title: t("save"),
                      disabled: props.isFetching,
                      loading: props.isFetching,
                      onPress: () => props.setDataObj(),
                    },
                  ]}
                />
              </View>
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

export const REFRESH_PROJECTS = (payload) => ({
  type: "REFRESH_PROJECTS",
  payload,
});

export const DELETE_PROJECT = (payload) => ({
  type: "DELETE_PROJECT",
  payload,
});

export const ADD_PROJECT = (payload) => ({
  type: "ADD_PROJECT",
  payload,
});

export const MOVE_PROJECT = (payload) => ({
  type: "MOVE_PROJECT",
  payload,
});

export const SET_PROJECTS_SORT_OBJECTS = (payload) => ({
  type: "SET_PROJECTS_SORT_OBJECTS",
  payload,
});

export const SORT_PROJECTS = (payload) => ({
  type: "SORT_PROJECTS",
  payload,
});

export const SET_PROJECT_PROP = (payload) => ({
  type: "SET_PROJECT_PROP",
  payload,
});

export const SET_MODIFY_PROJECT = (payload) => ({
  type: "SET_MODIFY_PROJECT",
  payload,
});

export const ADD_GROUP = (payload) => ({
  type: "ADD_GROUP",
  payload,
});

export const ADD_TASK = (payload) => ({
  type: "ADD_TASK",
  payload,
});

export const SET_STATE_PROP = (payload) => ({
  type: "SET_STATE_PROP",
  payload,
});

export const SET_PROJECT = (payload) => ({
  type: "SET_PROJECT",
  payload,
});
export const SET_INITIAL_PROJECT = (payload) => ({
  type: "SET_INITIAL_PROJECT",
  payload,
});
export const SET_TASK_PROP = (payload) => ({
  type: "SET_TASK_PROP",
  payload,
});
export const SET_GROUP_PROP = (payload) => ({
  type: "SET_GROUP_PROP",
  payload,
});
export const DELETE_GROUP = (payload) => ({
  type: "DELETE_GROUP",
  payload,
});
export const DELETE_TASK = (payload) => ({
  type: "DELETE_TASK",
  payload,
});

import { useContext } from "react";
import { useModal } from "react-native-modalfy";
import IconButton from "./IconButton";
import { ThemeContext } from "../theming/theme-context";
import {
  findLayoutCell,
  genNewValKey,
  getDocProps,
  getTranslatedOptions,
  getTranslatedText,
  isArrayWithItems,
} from "../lib/functions";
import { connect } from "react-redux";
import { showToast } from "../lib/helperFns";
import { useDispatch } from "react-redux";

function CellIconButton(props) {
  const theming = useContext(ThemeContext);
  const { openModal } = useModal();
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        round
        size={42}
        icon={props.cell.icons?.main || "plus"}
        color={theming.colors[props.cell.colors?.main || "text"]}
        backgroundColor={
          theming.colors[props.cell.backgroundColors?.main || "lightAccent2"]
        }
        onPress={() => {
          if (props.cell.fn === "extraRowModal") {
            if (isArrayWithItems(props.cell.fnProps)) {
              // add a new object when opening the modal
              const valueKey = props.cell.fnProps[0];
              const layoutCell = findLayoutCell(props.layout, valueKey);
              if (!layoutCell?.found) showToast("Missing cell");

              const rowValueKey = genNewValKey(
                (props.docToModify.currentValueKey ?? 0) + 1,
                props.profile.id
              );
              const item = layoutCell.found;
              const _title = getTranslatedText(
                item.formTitle ?? item.title,
                props.lang
              );

              openModal("CellModal", {
                title: _title,
                cellProps: props,
                cells: [
                  {
                    item,
                    noHeader: true,
                    disableAdd: true,
                    renderSpecificRow: rowValueKey,
                  },
                ],
                onMount: (actions) => {
                  // add a row on mount
                  actions.addToObjectArrWithGeneratedId({
                    type: "ExtraRow",
                    userId: props.profile.id,
                    valueKey: valueKey,
                    value: {},
                    docId: props.docId,
                    sortProp: item.prop ?? [item.inputs?.[0]?.valueKey],
                    idProp: "valueKey",
                    currentValueKey: rowValueKey,
                  });

                  // handle default values in form of presetObjects
                  // const presetObjects = [
                  //   {
                  //     valueKey: "912",
                  //     devaultValue: { text: { fin: "Pölyisyys" } },
                  //   },
                  // ];
                  if (
                    isArrayWithItems(props.cell.presetObjects) &&
                    isArrayWithItems(item.inputs)
                  ) {
                    props.cell.presetObjects.forEach((x) => {
                      const input = item.inputs.find(
                        (input) => input.valueKey === x.valueKey
                      );
                      if (input) {
                        // handle multipicker default options
                        if (input.type === "multiPicker") {
                          const defaultOptions = getTranslatedOptions(
                            x.defaultOptions,
                            props.lang
                          );
                          if (isArrayWithItems(defaultOptions)) {
                            defaultOptions.forEach((defaultOption) => {
                              actions.addToStringArr({
                                valueKey: `${valueKey}_${rowValueKey}_${x.valueKey}`,
                                value: defaultOption,
                                docId: props.docId,
                              });
                            });
                          }
                        }
                        // handle default text value
                        else if (
                          input.type === "textField" ||
                          input.type === "multilineField" ||
                          input.type === "picker" ||
                          input.type === "pickerTextarea" ||
                          input.type === "pickerTextField" ||
                          input.type === "multiPicker"
                        ) {
                          const defaultValue = getTranslatedText(
                            x.defaultValue,
                            props.lang
                          );
                          if (defaultValue) {
                            actions.modifyValue({
                              valueKey: `${valueKey}_${rowValueKey}_${x.valueKey}`,
                              value: defaultValue,
                              docId: props.docId,
                            });
                          }
                        }
                      }
                    });
                  }
                },
                onSave: (actionsDone) => {
                  // cell modal saves all the actions done to the tmp doc
                  // we can just redo them all to the real doc
                  // redux seems to batch these so any amount of actions should be fine
                  actionsDone.forEach((x) => {
                    dispatch(x);
                  });
                },
              });
            }
          }
        }}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { docLayout } = getDocProps(
    ownProps.viewUnfinishedLayout,
    state,
    true,
    ownProps.docId,
    ownProps.layoutId
  );
  const layout = ownProps.viewUnfinishedLayout
    ? docLayout?.versions?.[1]
    : docLayout?.versions?.[ownProps.docToModify?.layoutVersion];
  return {
    layout: layout,
  };
};

export default connect(mapStateToProps, null)(CellIconButton);

import "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import CheckBoxElem from "./CheckBoxElem";
import Icon from "./Icon";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "./Tooltip";
import { useContext } from "react";

const styles = {
  titleRow: {
    flex: 1,
    minHeight: 36,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textWrapper: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    padding: 8,
  },
  padding: { padding: 8 },
};

export default function CheckBoxWithTitle(
  props = {
    setChecked: Function,
    title: String,
    value: String,
    booleanChecked: Boolean,
    checked: Boolean,
    disabled: Boolean,
  }
) {
  const { theme, colors } = useContext(ThemeContext);

  return (
    <View style={styles.textWrapper}>
      <TouchableOpacity
        onPress={() => {
          requestAnimationFrame(() => {
            props.setChecked();
          });
        }}
        disabled={!!props.disabled}
        style={styles.titleRow}
      >
        {props.title ? (
          <View style={styles.textContainer}>
            <Text
              style={[
                theme.boldText,
                {
                  color: props.error ? "red" : colors.text,
                },
              ]}
            >
              {props.title}
            </Text>
          </View>
        ) : null}

        <View style={theme.hPadding}>
          {props.loading ? (
            <ActivityIndicator color={colors.text} />
          ) : (
            <CheckBoxElem
              onPress={() => {
                requestAnimationFrame(() => {
                  props.setChecked();
                });
              }}
              containerStyle={theme.zeroPaddingAndMargin}
              uncheckedIcon={
                // <View
                //   style={{
                //     borderWidth: 1,
                //     borderColor: colors.borderPrimary,
                //     backgroundColor: colors.checkBox,
                //     height: 24,
                //     width: 24,
                //   }}
                // />
                <Icon name="checkbox-blank" size={24} color={colors.checkBox} />
              }
              checkedIcon={
                <Icon name="checkbox-marked" size={24} color={colors.accent} />
              }
              size={theme.checkBoxSize}
              checkedColor={colors.accent}
              checked={
                props.booleanChecked
                  ? props.checked
                  : props.value === props.title
                  ? true
                  : false
              }
            />
          )}
        </View>
      </TouchableOpacity>

      {props.hint || props.hintTable ? (
        <View style={styles.padding}>
          <Tooltip
            hintTable={props.hintTable}
            tip={props.hint}
            flex={0}
            icon={true}
            lang={props.lang}
          />
        </View>
      ) : null}
    </View>
  );
}

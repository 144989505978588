import React from "react";
import { ThemeContext } from "../theming/theme-context";

export default function ProgressBarElem(props) {
  const { colors } = React.useContext(ThemeContext);
  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: colors.primary,
    borderRadius: 0,
  };

  const fillerStyles = {
    height: "100%",
    width: `${props.progress * 100}%`,
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: props.color ?? colors.accent,
  };

  const labelStyles = {
    padding: 5,
    color: "#000",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles} />
      </div>
    </div>
  );
}

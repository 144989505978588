import { useState, useRef, useEffect } from "react";
import { Pressable, ActivityIndicator, StyleSheet, View } from "react-native";
import update from "immutability-helper";
import Icon from "./Icon";
import { getLocation } from "../lib/helperFns";
import { useDebounce, reverseGeocode } from "../lib/functions";
import { connect } from "react-redux";
import {
  SET_COMPANY_SETTINGS_PROP,
  SET_GPS_LOCATION,
} from "../actions/ProfileActions";
import i18next from "i18next";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { defaultMapCenter } from "../lib/constants";
import { useContext } from "react";
import { ThemeContext } from "../theming/theme-context";

export let geocodingClient = null;

const styles = StyleSheet.create({
  map: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
});

const addressParseInfo = {
  types: ["route", "street_number", "postal_code", "locality"],
  separators: [" ", ", ", ", "],
};

const INITIAL_MAP_STATE = {
  properties: {
    center: [0, 0],
    bounds: {
      ne: [0, 0],
      sw: [0, 0],
    },
    zoom: 0,
    heading: 0,
    pitch: 0,
  },
  gestures: {
    isGestureActive: false,
  },
};

function MapView(props) {
  const { theme, colors } = useContext(ThemeContext);
  const mapRef = useRef();
  const mapContainerRef = useRef();
  const mapContainerParentRef = useRef();
  const customMarker = useRef();
  const [loading, setLoading] = useState(props.gpsLocation ? false : true);
  const parsedLocationRef = useRef();
  const mapboxAccessToken = props.company?.settings?.mapboxAccessToken;

  const debouncedRequest = useDebounce(() => {
    reverseGeocode(
      mapboxAccessToken,
      SET_COMPANY_SETTINGS_PROP,
      props.mapState.current.properties.center[1],
      props.mapState.current.properties.center[0]
    ).then((res) => {
      if (res) {
        props.SET_GPS_LOCATION({ gpsLocation: res });
        props.handleLocationChanged(res);
      }
    });
  }, 4000);

  const initMap = (
    _mapboxAccessToken = mapboxAccessToken,
    _gpsLocation = props.gpsLocation
  ) => {
    if (_mapboxAccessToken) {
      const center = _gpsLocation
        ? [_gpsLocation.coordinates.lng, _gpsLocation.coordinates.lat]
        : defaultMapCenter;
      mapboxgl.accessToken = _mapboxAccessToken;

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        center: center,
        zoom: 13,
      });

      customMarker.current = new mapboxgl.Marker({
        draggable: true, // Make the marker draggable
      })
        .setLngLat(center) // Set initial coordinates for the marker
        .addTo(mapRef.current); // Add the marker to the map

      // Event handler for when the marker is dragged
      customMarker.current.on("dragend", () => {
        const lngLat = customMarker.current.getLngLat(); // Get new position after dragging
        props.mapState.current = {
          properties: {
            center: [lngLat.lng, lngLat.lat],
          },
        };
        debouncedRequest();
      });

      // mapRef.current.on("move", () => {
      //   const mapCenter = mapRef.current.getCenter();
      //   props.mapState.current = {
      //     properties: {
      //       center: [mapCenter.lng, mapCenter.lat],
      //       zoom: mapRef.current.getZoom(),
      //     },
      //   };
      // });

      mapRef.current.on("click", (e) => {
        customMarker.current.setLngLat([e.lngLat.lng, e.lngLat.lat]);
        props.mapState.current = {
          properties: {
            center: [e.lngLat.lng, e.lngLat.lat],
          },
        };
        debouncedRequest();
        // mapRef.current.flyTo({
        //   center: e.lngLat,
        // });
      });
    }
  };

  const handleLocationGet = (_parsed, _gpsLocation) => {
    if (_gpsLocation) {
      parsedLocationRef.current = _parsed;

      props.mapState.current = update(INITIAL_MAP_STATE, {
        properties: {
          center: {
            $set: [_gpsLocation.coordinates.lng, _gpsLocation.coordinates.lat],
          },
        },
      });
    }
    initMap(mapboxAccessToken, _gpsLocation);
  };

  useEffect(() => {
    if (mapboxgl.accessToken && mapboxgl.accessToken !== mapboxAccessToken) {
      initMap(mapboxAccessToken);
    }
  }, [mapboxAccessToken]);

  useEffect(() => {
    if (props.gpsLocation) {
      props.mapState.current = update(INITIAL_MAP_STATE, {
        properties: {
          center: {
            $set: [
              props.gpsLocation.coordinates.lng,
              props.gpsLocation.coordinates.lat,
            ],
          },
        },
      });
    }

    getLocation(
      mapboxAccessToken,
      props.SET_COMPANY_SETTINGS_PROP,
      props.gpsLocation,
      props.SET_GPS_LOCATION,
      setLoading,
      handleLocationGet,
      addressParseInfo
    );

    return () => {
      mapRef.current.remove();
    };
  }, []);

  // ResizeObserver logic
  useEffect(() => {
    if (mapRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        try {
          mapRef.current?.resize(); // Trigger the map resize
        } catch {
          // Resize sometimes crashes if the map is unmounted during it
        }
      });

      if (mapContainerParentRef.current) {
        resizeObserver.observe(mapContainerParentRef.current); // Observe the container
      }

      return () => {
        if (mapContainerParentRef.current) {
          resizeObserver.unobserve(mapContainerParentRef.current); // Cleanup observer
        }
      };
    }
  }, [mapRef.current]);

  return (
    <View
      style={{
        width: props.width,
        height: props.height,
      }}
      ref={mapContainerParentRef}
    >
      <div style={styles.map} id="map-container" ref={mapContainerRef} />

      {loading ? (
        <View style={theme.centeredFlexContainer}>
          <ActivityIndicator color={colors.text} />
        </View>
      ) : (
        <Pressable
          onPress={props.toggleFullScreen}
          style={{
            position: "absolute",
            top: 22,
            right: 22,
            backgroundColor: colors.lightAccent,
            borderRadius: 20,
          }}
        >
          <Icon
            name={props.isFullScreen ? "fullscreen-exit" : "fullscreen"}
            size={32}
            color={colors.text}
          />
        </Pressable>
      )}
    </View>
  );
}

const mapDispatchToProps = {
  SET_GPS_LOCATION,
  SET_COMPANY_SETTINGS_PROP,
};

const mapStateToProps = (state) => {
  return {
    company: state.userInfo.company,
    gpsLocation: state.userInfo.gpsLocation,
    lang: i18next.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);

import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "../reducers/index";

import localForage from "localforage";

const persistConfig = {
  key: "root",
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["nav", "userInfo", "isFetching", "docs", "components"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

import React from "react";
import { Text, View } from "react-native";
import TextInput from "./TextInput";

const Input = React.forwardRef(
  (
    {
      val,
      unit,
      colors,
      theme,
      orientation,
      numeric,
      itemKey,
      itemIndex,
      onChangeText,
      disabled,
      selectTextOnFocus,
    },
    ref
  ) => (
    <View
      style={[
        {
          width: "40%",
          backgroundColor: colors.darkPrimary,
          margin: 8, //wp(1),
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        },
      ]}
    >
      <TextInput
        ref={ref}
        orientation={orientation}
        disableFullscreenUI={false}
        style={[
          {
            flex: 1,
            alignSelf: "stretch",
            textAlign: "right",
            color: colors.text,
            padding: 8, //wp(2),
            opacity: disabled ? 0.6 : 1,
          },
        ]}
        selectTextOnFocus={selectTextOnFocus}
        editable={!disabled}
        keyboardType={"decimal-pad"}
        value={val}
        placeholder={"-"}
        placeholderTextColor={colors.text}
        onChangeText={(text) => {
          if (text) {
            let _value = text.replace(/\r?\n|\r/g, "");
            if (numeric) {
              _value = _value.replace(/[^0-9.,-]/g, "").replace(/,/g, ".");
            }
            onChangeText(_value, itemIndex, itemKey);
          } else {
            onChangeText("", itemIndex, itemKey);
          }
        }}
      />
      <Text
        style={[theme.text, { opacity: disabled ? 0.6 : 1 }]}
      >{` ${unit} `}</Text>
    </View>
  )
);

export default function VariableNumInputRow({
  item,
  itemKey,
  itemIndex,
  colors,
  theme,
  hp,
  wp,
  viewKey,
  numeric,
  getTranslatedText,
  onChangeText,
  units,
  lang,
  disabled,
  selectTextOnFocus,
}) {
  return (
    <View
      key={viewKey}
      testID={viewKey}
      style={[
        {
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        },
      ]}
    >
      {Object.keys(item)
        .filter((x) => x !== "id")
        .map((key, index) => (
          <Input
            key={"VariableNumInput" + itemKey + key + index}
            disabled={disabled}
            selectTextOnFocus={selectTextOnFocus}
            colors={colors}
            theme={theme}
            wp={wp}
            hp={hp}
            val={`${item[key]}`}
            itemKey={key}
            itemIndex={itemIndex}
            numeric={numeric}
            unit={getTranslatedText(units?.[key], lang)}
            onChangeText={onChangeText}
          />
        ))}
    </View>
  );
}

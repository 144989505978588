import "react";
import { Platform, ActivityIndicator, Text, View, Image } from "react-native";
import { ThemeContext, img } from "../theming/theme-context";

export default function LoadingView(props) {
  return (
    <ThemeContext.Consumer>
      {({ logo, lightLogo, colors, theme, selectedTheme }) => (
        <View
          style={
            theme
              ? [
                  theme.container,
                  {
                    flex: 1,
                    height: "100%",
                    backgroundColor: colors.primary,
                  },
                ]
              : {
                  flex: 1,
                  alignItems: "center",
                  backgroundColor: colors.primary,
                }
          }
        >
          {Platform.OS !== "web" ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                resizeMode={"contain"}
                style={img}
                source={
                  props.logo
                    ? props.logo
                    : selectedTheme === "light"
                    ? lightLogo
                    : logo
                }
              />
            </View>
          ) : null}
          {props.title ? (
            <View
              style={{
                flex: 2,
                margin: Platform.OS === "web" ? 36 : 0,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <ActivityIndicator
                size="large"
                color={selectedTheme === "light" ? colors.text : colors.accent}
              />
              <Text
                style={
                  theme
                    ? selectedTheme === "light"
                      ? theme.reverseTitle
                      : theme.title
                    : {
                        color: colors.text,
                      }
                }
              >
                {props.title}
              </Text>
            </View>
          ) : null}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

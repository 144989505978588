import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";

import PublicHeaderBar from "../components/PublicHeaderBar";

import useWindowDimensions from "../functions/useWindowDimensions";
import { Helmet } from "react-helmet";

import RecipientsAndSignersScreen from "../../../common/screens/RecipientsAndSignersScreen";
import PreviewAndSignDocScreen from "../../../common/screens/PreviewAndSignDocScreen";
import { useTranslation } from "react-i18next";
import componentReducer from "../../../common/reducers/ComponentReducer";

const CreateDocWrapper = React.lazy(() => import("./CreateDocWrapper"));

export default function DemoScreen() {
  const { t } = useTranslation();
  let history = useHistory();
  const { width, height } = useWindowDimensions();
  const [state, dispatchState] = useReducer(componentReducer, {
    formRoute: { route: null, history: [], params: {} },
  });
  const demoCompany = {
    id: "TrialAccounts",
    name: "Test Oy",
    phone: "123 1234 12345",
    email: "testi@docean.fi",
    companyId: "12345-A",
    address: t("mockAddress"),
    zipcode: "12345",
    city: t("mockCity"),
    ewManager: {
      name: t("john"),
      lName: t("doe"),
      email:
        t("ewManager")
          .toLowerCase()
          .replace(/\r?\n|\r/g, "") + "@docean.fi",
      phone: "123 1234 12345",
    },
    contactPerson: {
      name: t("john"),
      lName: t("doe"),
      email:
        t("ewManager")
          .toLowerCase()
          .replace(/\r?\n|\r/g, "") + "@docean.fi",
      phone: "123 1234 12345",
    },
  };
  const demoProfile = {
    demo: true,
    id: "demo/1",
    name: t("john"),
    lName: t("doe"),
    phone: "123 1234 12345",
    email: "demo@docean.fi",
    role: "Trial",
    signDigitally: true,
  };
  const demoManager = {
    name: t("john"),
    lName: t("doe"),
    phone: "123 1234 12345",
    email: "manager@docean.fi",
  };
  const formNavigate = (route, _history, params) => {
    dispatchState({
      type: "setFormRoute",
      route: route,
      params: params,
    });
  };

  const formGoBack = (_navigation, amount) => {
    dispatchState({
      type: "formGoBack",
      amount,
    });
  };

  const formReplaceTab = (route, _history, params) => {
    dispatchState({
      type: "replaceTab",
      route: route,
      params: params,
    });
  };

  return (
    <div className="infoContainer">
      <Helmet>
        <title>Demo - Fondion Docs</title>
        <meta name="description" content={t("doceanDemoVersion")} />
      </Helmet>

      <PublicHeaderBar />

      <div
        style={{
          // marginTop: "100px",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // width: "400px",
          // height: "900px",
          // marginLeft: "-400px",
          // marginRight: "-900px",

          display: "flex",
          flex: 1,
          height: height - 48,
          scrollSnapAlign: "start",
          overflow: "hidden",
        }}
      >
        {/* <CreateDocWrapper
            history={history}
            viewHeight={height * 0.8}
            viewWidth={width * 0.8}
          /> */}
        {state.formRoute.route === "RecipientsAndSigners" ? (
          <RecipientsAndSignersScreen
            navigation={history}
            pageH={height}
            pageW={width}
            navigateFn={formNavigate}
            goBackFn={formGoBack}
            replaceTabFn={formReplaceTab}
            demo={true}
            company={demoCompany}
            profile={demoProfile}
            manager={demoManager}
          />
        ) : state.formRoute.route === "PreviewAndSign" ? (
          <PreviewAndSignDocScreen
            navigation={history}
            location={history.location}
            pageH={height}
            pageW={width}
            navigateFn={formNavigate}
            goBackFn={formGoBack}
            replaceTabFn={formReplaceTab}
            navState={{ generateModularPDF: true }}
            demo={true}
            company={demoCompany}
            profile={demoProfile}
            manager={demoManager}
          />
        ) : (
          <CreateDocWrapper
            disableHeader={true}
            demo={true}
            history={history}
            pageH={height}
            pageW={width}
            fullHeight={height}
            fullWidth={width}
            navState={null}
            navigateFn={formNavigate}
            goBackFn={formGoBack}
            replaceTabFn={formReplaceTab}
            company={demoCompany}
            profile={demoProfile}
            manager={demoManager}
          />
        )}
      </div>
    </div>
  );
}

/*
            <CreateDocScreen
              demo={true}
              navigation={history}
              pageH={height - 48}
              pageW={width}
              fullHeight={height}
              fullWidth={width}
              navState={null}
              navigateFn={formNavigate}
              goBackFn={formGoBack}
              replaceTabFn={formReplaceTab}
              company={demoCompany}
              profile={demoProfile}
              manager={demoManager}
            />
            */

import "react";
import { Text, View, TouchableOpacity } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import CheckBoxElem from "./CheckBoxElem";
import Icon from "./Icon";

export default function SingleCheckBoxRow(props) {
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => {
        return (
          <TouchableOpacity
            onPress={() => {
              requestAnimationFrame(() => {
                props.onCheckBoxPress();
              });
            }}
            style={[
              theme.halfContainer,
              theme.rowContainer,
              {
                flex: props.flex ?? 1,
              },
            ]}
          >
            <View style={theme.rowContainer}>
              <Text style={[theme.boldText, theme.textPaddingContainer]}>
                {props.title}
              </Text>

              <CheckBoxElem
                testID={props.testID || null}
                accessibilityLabel={props.accessibilityLabel || null}
                onPress={() => {
                  requestAnimationFrame(() => {
                    props.onCheckBoxPress();
                  });
                }}
                containerStyle={{ padding: 0, margin: 0 }}
                checkedColor={colors.accent}
                checked={props.checked}
                uncheckedIcon={
                  <Icon
                    name="checkbox-blank"
                    size={24}
                    color={colors.checkBox}
                  />
                }
                checkedIcon={
                  <Icon
                    name="checkbox-marked"
                    size={24}
                    color={colors.accent}
                  />
                }
              />
            </View>
          </TouchableOpacity>
        );
      }}
    </ThemeContext.Consumer>
  );
}

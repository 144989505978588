import "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View, Text } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "./Modal";
import moment from "moment";
import StretchButton from "./StretchButton";

const completedColor = "#025200";
const days = ["M", "T", "K", "T", "P", "L", "S"];

function DayButton(props) {
  // maxRows = 7
  // maxCols = 7
  const size = props.size;
  let agendaItems = props.agendaItemsFilter
    ? props.agendaItemsFilter(
        props.agendaItems,
        moment(`${props.text}.${props.month}.${props.year}`, "DD.M.YYYY")
      )
    : null;
  let completedItems = 0;
  let unfinishedItems = 0;
  if (agendaItems) {
    agendaItems.forEach((x) => {
      if (x.completion?.completed) {
        completedItems++;
      } else {
        unfinishedItems++;
      }
    });
  }
  const hasItems = completedItems || unfinishedItems ? true : false;
  if (props.text) {
    return (
      <TouchableOpacity
        onPress={() => {
          if (props.onPress && !props.disabled) {
            requestAnimationFrame(() => {
              props.onPress(props.text.toString());
            });
          }
        }}
      >
        <View
          style={{
            width: size,
            height: size,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: props.selected ? props.colors.accent : null,
            borderRadius: size * 0.05,
          }}
        >
          {hasItems ? (
            <View style={{ flexDirection: "row" }}>
              {completedItems ? (
                <View
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderBottomLeftRadius: 1,
                    borderBottomRightRadius: 1,
                    borderTopRightRadius: 1,
                    borderTopLeftRadius: 1,
                    backgroundColor: completedColor,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      props.theme.overline,
                      props.agendaTextSize
                        ? { color: "#fff", fontSize: props.agendaTextSize }
                        : { color: "#fff" },
                    ]}
                  >
                    {completedItems}
                  </Text>
                </View>
              ) : null}
              {unfinishedItems ? (
                <View
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderBottomLeftRadius: 1,
                    borderBottomRightRadius: 1,
                    borderTopRightRadius: 1,
                    borderTopLeftRadius: 1,
                    backgroundColor: props.selected
                      ? props.colors.primary
                      : props.colors.accent,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      props.theme.overline,
                      props.theme.zeroPaddingAndMargin,
                      props.agendaTextSize
                        ? { fontSize: props.agendaTextSize }
                        : {},
                      {
                        color: props.selected
                          ? props.colors.text
                          : props.colors.textOnAccent,
                      },
                    ]}
                  >
                    {unfinishedItems}
                  </Text>
                </View>
              ) : null}
            </View>
          ) : null}

          <Text
            style={[
              props.theme.text,
              {
                //fontSize: props.textColor ? wp(5) : wp(4),
                color: props.disabled
                  ? props.colors.disabledText
                  : props.textColor
                  ? props.textColor
                  : props.selected
                  ? props.colors.textOnAccent
                  : props.colors.accent,
              },
              props.dayFontSize ? { fontSize: props.dayFontSize } : {},
            ]}
          >
            {props.text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  } else {
    return (
      <View
        style={{
          width: size,
          height: size,
        }}
      />
    );
  }
}

function DayButtonRow({ children }) {
  return <View style={{ flexDirection: "row" }}>{children}</View>;
}

export default function DayPicker(props) {
  const { t } = useTranslation();
  const dayButtonSize = props.dayButtonSize;
  const _dateFormat = props.dateFormat || "DD.MM.YYYY";

  const getDayButtons = (theme, colors) => {
    const weekDays = moment.weekdays(true);

    const firstDayOfTheMonth = moment(
      "01." + props.month + "." + props.year,
      "DD.M.YYYY"
    ).format("dddd");
    const dayIndex = weekDays.findIndex((x) => x === firstDayOfTheMonth);

    const daysCount =
      moment(props.year + "-" + props.month, "YYYY-M").daysInMonth() + 1;

    let dayButtonRows = [];
    let dayButtons = [[]];
    let dayButtonsIndex = 0;

    for (let index = 0; index < dayIndex; index++) {
      dayButtons[dayButtonsIndex].push(
        <DayButton
          key={"DayButtonFiller" + index}
          size={dayButtonSize}
          pageH={props.pageH}
          pageW={props.pageW}
          theme={theme}
          colors={colors}
          dayFontSize={props.dayFontSize}
          agendaTextSize={props.agendaTextSize}
        />
      );
    }

    for (let index = 1; index < daysCount; index++) {
      if (dayButtons[dayButtonsIndex].length > 6) {
        dayButtons.push([]);
        dayButtonsIndex++;
      }
      dayButtons[dayButtonsIndex].push(
        <DayButton
          key={"DayButton" + index}
          size={dayButtonSize}
          pageH={props.pageH}
          pageW={props.pageW}
          border={true}
          selected={props.day == index}
          text={index}
          onPress={props.onConfirm}
          disabled={
            (props.minDate &&
              props.month <=
                parseInt(moment(props.minDate, _dateFormat).format("M")) &&
              index <
                parseInt(moment(props.minDate, _dateFormat).format("D"))) ||
            (props.maxDate &&
              props.month >=
                parseInt(moment(props.maxDate, _dateFormat).format("M")) &&
              index > parseInt(moment(props.maxDate, _dateFormat).format("D")))
          }
          theme={theme}
          colors={colors}
          month={props.month}
          year={props.year}
          agendaItems={props.agendaItems}
          agendaItemsFilter={props.agendaItemsFilter}
          dayFontSize={props.dayFontSize}
          agendaTextSize={props.agendaTextSize}
        />
      );
    }

    dayButtons.forEach((x, index) => {
      dayButtonRows.push(
        <DayButtonRow key={"DayButtonRow" + index}>{x}</DayButtonRow>
      );
    });

    return dayButtonRows;
  };

  const getDayTitleButtons = (theme, colors) => {
    let buttons = [];

    for (let index = 0; index < 7; index++) {
      buttons.push(
        <DayButton
          key={"DayButtonWeekDays" + index}
          size={dayButtonSize}
          pageH={props.pageH}
          pageW={props.pageW}
          textColor={"#ccc"}
          text={days[index]}
          theme={theme}
          colors={colors}
          dayFontSize={props.dayFontSize}
          agendaTextSize={props.agendaTextSize}
        />
      );
    }

    return buttons;
  };

  const renderButton = (theme, colors) => (
    <View style={theme.modalButtonContainer}>
      <StretchButton
        color={colors.text}
        backgroundColor={colors.secondary}
        title={t("cancel")}
        onPress={props.toggle}
      />
    </View>
  );

  const renderDayPicker = (theme, colors) => (
    <View
      style={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
        backgroundColor: colors.primary,
        height: 7 * dayButtonSize,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        {getDayTitleButtons(theme, colors)}
      </View>

      {getDayButtons(theme, colors)}

      {props.onlyPicker ? null : renderButton(theme, colors)}
    </View>
  );

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) =>
        props.onlyPicker ? (
          renderDayPicker(theme, colors)
        ) : (
          <Modal
            {...modalPickerOpts}
            isVisible={props.visible}
            onBackButtonPress={props.toggle}
            onBackdropPress={props.toggle}
            backdropColor={colors.borderLighter}
            backdropOpacity={0.6}
          >
            {renderDayPicker(theme, colors)}
          </Modal>
        )
      }
    </ThemeContext.Consumer>
  );
}

import { useState } from "react";
import { Platform, TouchableOpacity, View, Text } from "react-native";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import TimePicker from "./TimePicker";
import DayPicker from "./DayPicker";
import moment from "moment";

const getYearButtonsData = (year) => {
  const rounderDownTo10 = Math.floor(year / 10) * 10;
  let data = [];
  for (let i = rounderDownTo10; i < rounderDownTo10 + 10; i++) {
    data.push(i);
  }
  return data;
};
const ButtonRows = ({
  theme,
  colors,
  pageW,
  current,
  dayButtonSize,
  onPress,
  closeView,
  data,
  buttonsInRow,
  rowCount,
}) => {
  let rows = [];
  let buttons = [[]];
  let buttonsIndex = 0;
  const height = 7 * dayButtonSize;
  const rowHeight = height / rowCount;
  data.forEach((x, i) => {
    if (buttons[buttonsIndex].length > buttonsInRow - 1) {
      buttons.push([]);
      buttonsIndex++;
    }
    buttons[buttonsIndex].push(
      <TouchableOpacity
        key={x}
        style={{
          // flex: 1,
          // alignSelf: "stretch",
          height: rowHeight - 1,
          width: (pageW * 0.9) / buttonsInRow - 1,
          justifyContent: "center",
          alignItems: "center",
          borderWidth: 1,
          borderColor: colors.accent,
          backgroundColor:
            current === i || current === x ? colors.accent : colors.primary,
        }}
        onPress={() => {
          if (onPress) {
            requestAnimationFrame(() => {
              onPress(x, i);
              closeView();
            });
          }
        }}
      >
        <Text
          style={[
            theme.title,
            {
              color:
                current === i || current === x
                  ? colors.textOnAccent
                  : colors.text,
            },
          ]}
        >
          {x}
        </Text>
      </TouchableOpacity>
    );
  });

  buttons.forEach((x, index) => {
    rows.push(
      <View
        key={"ButtonRow" + index}
        style={{
          // flex: 1,
          height: rowHeight,
          // width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          // alignSelf: "stretch",
        }}
      >
        {x}
      </View>
    );
  });

  return (
    <View
      style={{
        // flex: 1,
        height: 7 * dayButtonSize,
        width: pageW * 0.9,
        // width: pageW * 0.9,
      }}
    >
      {rows}
    </View>
  );
};

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const styles = {
  arrowButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 60,
  },
  textButton: {
    flex: 1,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    alignSelf: "stretch",
  },
};
export default function Calendar(props) {
  const {
    showTimePicker,
    setShowTimePicker,
    // clockLabelText,
    setClockLabelText,
    dateFormat,
    minDate,
    maxDate,
    title,
    monthIndex,
    year,
    day,
    prevMonth,
    nextMonth,
    prevYear,
    setMonth,
    setYear,
    nextYear,
    dayPicker,
    agendaItems,
    agendaItemsFilter,
    err,
    setErr,
    setDay,
    toggle,
    timePicker,
    confirmDate,
    setTime,
    time,
    dayFontSize,
    agendaTextSize,
    theme,
    colors,
    pageH,
    pageW,
    loading,
  } = props;
  const { t } = useTranslation();
  const [viewType, setViewType] = useState();
  const _dateFormat = dateFormat || "DD.MM.YYYY";
  const fontSize = 16;
  const iconSize = 24;

  const _pageH = pageH - 76 - (title ? 36 : 0) - (err ? 36 : 0);
  const _height = pageH ? _pageH / 7 : 52;
  const _width = pageW ? pageW / 7 : 52;
  const dayButtonSize = Math.min(_height, _width);

  const isMinMonth =
    minDate && monthIndex < parseInt(moment(minDate, _dateFormat).format("M"));
  const isMinYear =
    minDate && year <= parseInt(moment(minDate, _dateFormat).format("YYYY"));

  const isMaxMonth =
    maxDate &&
    monthIndex + 1 >= parseInt(moment(maxDate, _dateFormat).format("M"));
  const isMaxYear =
    maxDate && year >= parseInt(moment(maxDate, _dateFormat).format("YYYY"));

  const closeView = () => {
    setViewType();
  };

  return (
    <View
      id="asdf"
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.primary,
      }}
    >
      {err ? (
        <View style={{ padding: 8, minHeight: 36 }}>
          <Text style={[theme.text, { color: colors.error }]}>
            {t("chooseDay")}
          </Text>
        </View>
      ) : null}

      {title ? (
        <View style={{ padding: 8, minHeight: 36 }}>
          <Text style={[theme.text, { fontSize: fontSize }]}>{title}</Text>
        </View>
      ) : null}

      {(timePicker && !showTimePicker) || !timePicker ? (
        <>
          <View
            style={[
              theme.rowContainer,
              Platform.OS === "web"
                ? {
                    minHeight: 52,
                    marginTop: 16,
                  }
                : null,
              {
                flex: 0,
                width: "100%",
              },
            ]}
          >
            <TouchableOpacity
              style={styles.arrowButton}
              disabled={!!(isMinYear || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  prevYear(viewType === "years" ? 10 : 1);
                });
              }}
            >
              <View
                style={{
                  height: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name={
                    viewType === "years" ? "chevron-double-left" : "arrow-left"
                  }
                  size={iconSize}
                  color={
                    isMinYear || loading ? colors.disabledText : colors.accent
                  }
                />
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.textButton, { borderColor: colors.accent }]}
              disabled={!!(isMinYear || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  setViewType("years");
                });
              }}
            >
              <Text style={[theme.text, { fontSize: fontSize }]}>{year}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.arrowButton}
              disabled={!!(isMaxYear || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  nextYear(viewType === "years" ? 10 : 1);
                });
              }}
            >
              <View
                style={{
                  height: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name={
                    viewType === "years"
                      ? "chevron-double-right"
                      : "arrow-right"
                  }
                  size={iconSize}
                  color={
                    isMaxYear || loading ? colors.disabledText : colors.accent
                  }
                />
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={[
              theme.rowContainer,
              Platform.OS === "web"
                ? {
                    minHeight: 52,
                    marginTop: 16,
                  }
                : null,
              {
                flex: 0,
                width: "100%",
              },
            ]}
          >
            <TouchableOpacity
              style={styles.arrowButton}
              disabled={!!(isMinMonth || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  prevMonth(viewType === "years" ? 10 : 0);
                });
              }}
            >
              <View
                style={{
                  height: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name="arrow-left"
                  size={iconSize}
                  color={
                    isMinMonth || loading ? colors.disabledText : colors.accent
                  }
                />
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.textButton, { borderColor: colors.accent }]}
              disabled={!!(isMinYear || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  setViewType("months");
                });
              }}
            >
              <Text style={[theme.text, { fontSize: fontSize }]}>
                {t(months[monthIndex])}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.arrowButton}
              disabled={!!(isMaxMonth || loading)}
              onPress={() => {
                requestAnimationFrame(() => {
                  nextMonth();
                });
              }}
            >
              <View
                style={{
                  height: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name="arrow-right"
                  size={iconSize}
                  color={
                    isMaxMonth || loading ? colors.disabledText : colors.accent
                  }
                />
              </View>
            </TouchableOpacity>
          </View>

          {viewType ? (
            <ButtonRows
              theme={theme}
              colors={colors}
              dayButtonSize={dayButtonSize}
              pageW={props.pageW}
              onPress={viewType === "months" ? setMonth : setYear}
              current={viewType === "months" ? monthIndex : year}
              data={
                viewType === "months"
                  ? moment.monthsShort()
                  : getYearButtonsData(year)
              }
              closeView={closeView}
              buttonsInRow={viewType === "months" ? 3 : 2}
              rowCount={viewType === "months" ? 4 : 5}
            />
          ) : dayPicker ? (
            <DayPicker
              dayButtonSize={dayButtonSize}
              pageW={pageW}
              pageH={_pageH}
              minDate={minDate}
              maxDate={maxDate}
              onlyPicker={true}
              toggle={toggle}
              onConfirm={(day) => {
                setErr(false);
                if (timePicker) {
                  setDay(parseInt(day));
                  setShowTimePicker(true);
                } else if (confirmDate) {
                  confirmDate(parseInt(day));
                } else {
                  setDay(parseInt(day));
                }
              }}
              day={day}
              month={monthIndex + 1}
              year={year}
              agendaItems={agendaItems}
              agendaItemsFilter={agendaItemsFilter}
              dateFormat={dateFormat}
              dayFontSize={dayFontSize}
              agendaTextSize={agendaTextSize}
            />
          ) : null}
        </>
      ) : null}

      {Platform.OS === "web" && timePicker && showTimePicker ? (
        <View style={theme.fullPaddingContainer}>
          <Text
            style={[
              theme.text,
              {
                display: "block",
                marginBlock: "1em",
                textAlign: "center",
              },
            ]}
          >
            {`${day}.${monthIndex + 1}.${year}`}
          </Text>
          <TimePicker
            value={time}
            themeVariant={colors.text === "#fff" ? "dark" : "light"}
            onChange={setTime}
            textColor={colors.text}
            getClockLabelText={(view, time) => setClockLabelText(time)}
          />
        </View>
      ) : null}
    </View>
  );
}

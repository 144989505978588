import {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Text, View } from "react-native";
import {
  getEmptyCellChar,
  replaceTemplateString,
  getTranslatedText,
  getTranslatedTextWithFallback,
  isArrayWithItems,
  errorReport,
} from "../lib/functions";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "../components/Tooltip";
import MenuComponent from "./MenuComponent";
import IconButton from "./IconButton";

import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import { getExtraTemplateOptions } from "../layoutEditor/lib/constants";

const tagRegex = /\[\[(.+?)\]\]/g;

const getTagValue = (
  text,
  t,
  sources,
  templateStringSources,
  valueObj,
  langToEdit,
  nonDocSources, // if values are just random values not related to doc
  translationPrefix
) => {
  let _text = "";
  if (nonDocSources) {
    if (sources) {
      const found = sources.find((x) => x.value === text);
      _text = found?.title || t("not found");
    } else {
      _text = t(
        translationPrefix
          ? `${translationPrefix}${text.slice(1, -1)}`
          : text.slice(1, -1)
      );
    }
  } else if (text === "{docType}") {
    _text = t("document_genitive") + " " + t("type").toLowerCase();
  } else if (text === "{docId}") {
    _text = t("docId");
  } else if (text === "{date}") {
    _text = `{${t("upToDateViewingDate")}}`;
  } else if (text === "{creationDate}") {
    _text = `{${t("docCreationDate")}}`;
  } else if (text === "{value}") {
    _text = t("value");
  } else if (text.startsWith("{valueKey")) {
    if (sources) {
      const found = sources.find((x) => x.value === text);
      _text = found?.title || t("invalidCellReference");
    } else {
      _text = getEmptyCellChar(valueObj);
    }
  } else {
    const [template, key] = text.slice(1, -1).split("_");

    if (templateStringSources?.[template]) {
      const templateObject = templateStringSources[template]?.[key];

      if (templateObject) {
        _text = getTranslatedText(templateObject.text, langToEdit);
      } else {
        errorReport({
          error: "Wrong templateString in cell",
          errorInScreen: "pdfCreator",
          errorInFn: "TemplateStringInput",
          errorParams: {
            valueObj,
          },
          dontShowToast: true,
        });
      }
    } else {
      if (sources) {
        const found = sources.find((x) => x.value === text);
        _text = found?.title || getEmptyCellChar(valueObj);
      } else {
        _text = getEmptyCellChar(valueObj);
      }
    }
  }
  return _text;
};

export default function TemplateStringInput(props) {
  const { theme, colors } = useContext(ThemeContext);
  const value = props.value;
  const menuAnchor = useRef();
  const tagifyRef = useRef();
  const [templateStringPickerOpen, setTemplateStringPickerOpen] =
    useState(false);

  let splitText = [""];
  let textIndex = 0;
  let tagIndices = [];
  let i = 0;
  while (i < value.length) {
    const c = value[i];
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO
    // TODO template string change to ${
    if (c === "{") {
      // try to find the end
      let found = false;
      for (let j = i; j < value.length; j++) {
        if (value[j] === "}") {
          splitText.push("");
          textIndex += 1;
          splitText[textIndex] = value.substring(i, j + 1);
          tagIndices.push(textIndex);
          splitText.push("");
          textIndex += 1;
          i = j;
          found = true;
          break;
        }
      }
      if (!found) splitText[textIndex] += c;
    } else {
      splitText[textIndex] += c;
    }
    i++;
  }

  const readableValue = replaceTemplateString(
    value,
    null,
    "text",
    props.valueObj,
    props.templateStringSources,
    props.doc,
    props.layout,
    props.profile
  );
  const handleTextChange = useCallback((e) => {
    let newValue = e.detail.value.replace(
      tagRegex,
      (x) => JSON.parse(x)[0][0].value
    );
    // value always ends in \n for some reason, problem in library probably
    let lastIndex = newValue.lastIndexOf("\n");
    if (lastIndex !== -1) {
      // Check if '\n' is found
      newValue = newValue.substring(0, lastIndex);
    }

    props.onChangeText(
      newValue,
      props.returnReadableString
        ? replaceTemplateString(
            newValue,
            null,
            "text",
            props.valueObj,
            props.templateStringSources,
            props.doc,
            props.layout,
            props.profile
          )
        : null
    );
  }, []);

  const addTag = (tagData) => {
    var tagElm = tagifyRef.current.createTagElem(tagData);
    tagifyRef.current.injectAtCaret(tagElm);
    var elm = tagifyRef.current.insertAfterTag(tagElm); //  <- adds space after the tag
    tagifyRef.current.placeCaretAfterNode(elm); //  <- places the caret after the space
  };

  const handleTemplateStringSelected = (obj) => {
    // if in editor the value can be e.g. tmp_sites/0 and we need to add the specialInput into layout
    if (props.handleTmpValue && obj.value.includes("tmp")) {
      const newId = props.handleTmpValue(obj);
      // newId may ne null if adding failed
      if (newId) {
        addTag({
          value: `{${newId}}`,
          text: obj.text,
          title: obj.title,
        });
      }
    } else {
      addTag({
        value: obj.value,
        text: obj.text,
        title: obj.title,
      });
    }
  };

  const onAddTag = useCallback(({ detail: { data, tag } }) => {
    // if in editor the value can be e.g. tmp_sites/0 and we need to add the specialInput into layout
    if (props.handleTmpValue && data.value.includes("tmp")) {
      const newId = props.handleTmpValue(data);
      if (newId) {
        tagifyRef.current.replaceTag(tag, { ...data, value: `{${newId}}` });
      } else {
        // newId may ne null if adding failed, remove the whole tag
        tagifyRef.current.removeTags(tag, true);
      }
    } else {
      tagifyRef.current.replaceTag(tag, { ...data, value: `{${data.value}}` });
    }
  }, []);

  const menuData = useMemo(() => {
    let whitelist = [];

    // tagifyRef.current.whitelist = [
    //   { value: 100, value: "kenny", title: "Kenny McCormick" },
    // ],

    if (props.sources) {
      props.sources.forEach((x) => {
        whitelist.push({
          value: `{${x.id}}`,
          text: x.title,
          title: x.title,
        });
      });
    }

    if (props.templateStringSources) {
      // need to add all textinput valueKeys
      // add docType
      if (!props.sources) {
        const docIdTitle = props.t("docId");
        whitelist.push({
          value: "docId",
          text: docIdTitle,
          title: docIdTitle,
        });
        const docTypeTitle =
          props.t("document_genitive") + " " + props.t("type").toLowerCase();
        whitelist.push({
          value: "docType",
          text: docTypeTitle,
          title: docTypeTitle,
        });

        if (props.layout) {
          let _sources = [];
          getExtraTemplateOptions(
            { found: {} },
            _sources,
            props.options,
            props.t,
            props.lang,
            props.layout,
            undefined,
            undefined,
            "rows",
            false,
            "valueKey_"
          );
          _sources.forEach((x) => {
            whitelist.push({
              value: `{${x.id}}`,
              text: x.title,
              title: x.title,
            });
          });
        }
      }

      const languages = [props.lang];
      if (props.layout && props.layout.languages) {
        languages.concat(props.layout.languages);
      }
      Object.entries(props.templateStringSources).forEach(([key, value]) => {
        Object.entries(value).forEach(([innerKey, innerValue]) => {
          const title = `${
            innerValue.type
              ? getTranslatedTextWithFallback(
                  languages,
                  innerValue.type,
                  props.langToEdit
                ) + " - "
              : ""
          }${getTranslatedTextWithFallback(
            languages,
            innerValue.text,
            props.langToEdit
          )}`;
          whitelist.push({
            value: `{${key}_${innerKey}}`,
            text: title,
            title: title,
          });
        });
      });
    }

    return whitelist;
  }, [props.templateStringSources, props.sources, props.langToEdit]);

  useEffect(() => {
    const elems = tagifyRef.current?.getTagElms();
    if (isArrayWithItems(elems)) {
      elems.forEach((x) => {
        const value = x.getAttribute("value");
        tagifyRef.current.setTagTextNode(
          x,
          getTagValue(
            value,
            props.t,
            menuData,
            props.templateStringSources,
            props.valueObj,
            props.langToEdit,
            props.nonDocSources,
            props.translationPrefix
          )
        );
      });
    }
  }, [menuData]);

  if (tagifyRef.current) {
    tagifyRef.current.whitelist = menuData;
    tagifyRef.current.enforceWhitelist = true;
    tagifyRef.current.tagBackgroundColor = colors.lightAccent;
    tagifyRef.current.textColor = colors.accent;
  }

  return (
    <View
      pointerEvents={props.disabled ? "none" : "auto"}
      style={{
        width: "100%",
        alignItems: "flex-start",
        opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
        backgroundColor: colors.primary,
      }}
    >
      {props.title || props.error ? (
        <View
          style={{
            width: "100%",
            padding: 8,
            paddingTop: 4,
            paddingBottom: 4,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 1, alignSelf: "stretch" }}>
            <Text
              style={[
                theme.boldText,
                { color: props.error ? "red" : colors.text },
              ]}
            >
              {props.title + (props.error ? ` - ${props.error}` : "")}
            </Text>
          </View>
          {props.hint || props.hintTable ? (
            <Tooltip
              flex={0}
              icon
              tip={props.hint}
              hintTable={props.hintTable}
              lang={props.lang}
            />
          ) : null}
        </View>
      ) : null}

      <View ref={menuAnchor} style={theme.buttonContainer}>
        <MixedTags
          autofocus={false}
          tagifyRef={tagifyRef}
          onChange={handleTextChange}
          onAdd={onAddTag}
          settings={{
            mode: "mix",
            pattern: /@|#/,
            tagTextProp: "text",
            whitelist: menuData,
            enforceWhitelist: true,
            editTags: false,
            dropdown: {
              enabled: 1,
              position: "text", // <-- render the suggestions list next to the typed text ("caret")
              mapValueTo: "text", // <-- similar to above "tagTextProp" setting, but for the dropdown items
            },
          }}
          defaultValue={value.replace(/{(.+?)}/g, (x) => {
            const found = menuData.find((data) => data.value === x);
            if (found)
              return `[[{"value":"${found.value}","text":"${found.text}","title":"${found.title}","prefix":"@"}]]`;
            else return x;
          })}
        />
        <Tooltip
          flex={0}
          tip={props.addTemplateStringInfo || props.t("addTemplateStringInfo")}
        >
          <IconButton
            icon="plus"
            onPress={() => setTemplateStringPickerOpen(true)}
          />
        </Tooltip>
      </View>

      {props.hideValueInCell ? null : (
        <View
          style={{
            width: "100%",
          }}
        >
          <View style={{ minHeight: 72 }}>
            <View
              style={{
                width: "100%",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flex: 1, alignSelf: "stretch" }}>
                <Text style={theme.text}>
                  {props.valueFieldTitle || props.t("valueInCell")}
                </Text>
              </View>
              {props.valueInCellHint ? (
                <Tooltip
                  flex={0}
                  icon
                  tip={props.valueInCellHint}
                  lang={props.lang}
                />
              ) : null}
            </View>

            <View
              style={[
                theme.textPaddingContainer,
                {
                  minHeight: 42,
                  backgroundColor: colors.lightBg,
                  width: "100%",
                  justifyContent: "center",
                  paddingTop: 6,
                  paddingBottom: 6,
                },
              ]}
            >
              <Text style={theme.text} multiline>
                {readableValue.newValue}
              </Text>
            </View>
          </View>
        </View>
      )}

      <MenuComponent
        handleClose={() => setTemplateStringPickerOpen(false)}
        textProp="title"
        rest={{
          anchorEl: menuAnchor.current,
          visible: templateStringPickerOpen,
          data: menuData,
          action: handleTemplateStringSelected,
        }}
      />
    </View>
  );
}

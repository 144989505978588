import "react";
import { View } from "react-native";

export default function SkeletonLoadingView(props) {
  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: props.colors.darkPrimary,
        height: props.height - 1,
        width: props.width,
      }}
    />
  );
}

import { useCallback, useMemo, useEffect, useState, useRef } from "react";
import {
  Text,
  Pressable,
  Keyboard,
  View,
  Platform,
  Animated,
  KeyboardAvoidingView,
} from "react-native";
import { dirs } from "../lib/fileOperations";
import { useTranslation } from "react-i18next";
import TypeSpecificPreview from "./TypeSpecificPreview";
import SimpleForm from "./SimpleForm";
import MapView from "./MapView";
import Icon from "./Icon";
import i18next from "i18next";
import { mapEnabled } from "../lib/constants";
import { parseLocation } from "../lib/functions";

function AttachmentInputs(props) {
  const {
    theme,
    colors,
    setBottomSheet,
    pageW,
    pageH,
    atch,
    modifiedAtch,
    atchIndex,
    fullscreenAtch,
    setFullscreenAtch,
    atchLoaded,
    setAttachmentsLoaded,
    modifyId,
    onDelete,
    setEditingAtch,
    openPreviewModal,
    profile,
    users,
    SET_GPS_LOCATION,
    customToken,
  } = props;
  const { t } = useTranslation();
  const keyboard = false;
  const [mapLoaded, setMapLoaded] = useState(false);
  const canUpdate = !props.permissions || props.permissions.Update;

  const _users = useMemo(
    () =>
      Array.isArray(users)
        ? users.filter((x) => x.email && x.id !== profile?.id)
        : [],
    [users]
  );
  const previewViewSize = {
    height: pageH * 0.5,
    width: pageW,
  };
  const maxImageSize = useMemo(
    () => ({
      height: previewViewSize.height,
      width: previewViewSize.width,
    }),
    [props.tmpAttachmentsLength, pageH, pageW]
  );

  const topViewHeight = useRef(
    new Animated.Value(previewViewSize.height)
  ).current;

  const handleKeyboardShow = () => {
    // setKeyboard(true);
    Animated.timing(topViewHeight, {
      toValue: 0,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const handleKeyboardHide = () => {
    // setKeyboard(false);
    Animated.timing(topViewHeight, {
      toValue: previewViewSize.height,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const _setEditingAtch = useCallback(() => {
    setEditingAtch(null, atchIndex);
  }, [setEditingAtch, atchIndex]);

  useEffect(() => {
    if (Platform.OS !== "web") {
      const keyboardDidShowSub = Keyboard.addListener(
        "keyboardDidShow",
        handleKeyboardShow
      );
      const keyboardDidHideSub = Keyboard.addListener(
        "keyboardDidHide",
        handleKeyboardHide
      );

      return () => {
        keyboardDidShowSub.remove();
        keyboardDidHideSub.remove();
      };
    }
  }, []);

  const _onDelete = useCallback(
    (uri) => {
      onDelete(uri, atchIndex);
    },
    [onDelete, atchIndex]
  );

  const toggleFullScreenMap = () => {
    if (Platform.OS === "web") {
      props.setMapIsFullScreen((val) => !val);
    } else {
      setBottomSheet({
        isVisible: true,
        // enableDynamicSizing: false,
        enableContentPanningGesture: false,
        // snapPoints: ["100%"],
        content: (
          <MapView
            mapState={props.mapState}
            handleLocationChanged={handleLocationChanged}
          />
        ),
      });
    }
  };

  const inputs = useMemo(() => {
    const multiStateProp = props.tmpAttachments
      ? props.attachmentIndex
      : undefined;
    const _inputs =
      multiStateProp !== undefined
        ? props.modifyState[`${multiStateProp}_inputs`]
        : props.modifyState.inputs;

    let retval = [
      {
        key: "name",
        type: "textField",
        title: { all: t("fileName") },
        value:
          modifiedAtch.name ||
          props.tmpAttachments[props.attachmentIndex]?.name,
        required: true,
        error: _inputs?.[0].error,
      },
      {
        key: "desc",
        type: "textField",
        multiline: true,
        title: { all: t("desc") },
        value:
          modifiedAtch.desc ||
          props.tmpAttachments[props.attachmentIndex]?.desc,
        error: _inputs?.[1].error,
      },
      {
        key: "street",
        type: "textField",
        title: { all: t("address") },
        value:
          modifiedAtch.street ||
          props.tmpAttachments[props.attachmentIndex]?.street,
        location: {
          types: ["route", "street_number"],
          separators: [" "],
        },
        error: _inputs?.[2].error,
      },
      {
        key: "zipcode",
        type: "textField",
        title: { all: t("zipcode") },
        value:
          modifiedAtch.zipcode ||
          props.tmpAttachments[props.attachmentIndex]?.zipcode,
        location: {
          types: ["postal_code"],
          separators: [" "],
        },
        error: _inputs?.[3].error,
      },
      {
        key: "city",
        type: "textField",
        title: { all: t("city") },
        value:
          modifiedAtch.city ||
          props.tmpAttachments[props.attachmentIndex]?.city,
        location: {
          types: ["locality"],
          separators: [" "],
        },
        error: _inputs?.[4].error,
      },
      {
        key: "country",
        type: "textField",
        title: { all: t("country") },
        value:
          modifiedAtch.country ||
          props.tmpAttachments[props.attachmentIndex]?.country,
        location: {
          types: ["country"],
          separators: [" "],
        },
        error: _inputs?.[5].error,
      },
      {
        key: "lng",
        type: "textField",
        numeric: true,
        title: { all: t("longitude") },
        value:
          modifiedAtch.lng || props.tmpAttachments[props.attachmentIndex]?.lng,
        location: {
          types: ["lng"],
          separators: [" "],
        },
        error: _inputs?.[6].error,
      },
      {
        key: "lat",
        type: "textField",
        numeric: true,
        title: { all: t("latitude") },
        value:
          modifiedAtch.lat || props.tmpAttachments[props.attachmentIndex]?.lat,
        location: {
          types: ["lat"],
          separators: [" "],
        },
        error: _inputs?.[7].error,
      },
    ];

    if (canUpdate) {
      retval.push({
        key: "global",
        type: "checkBox",
        title: { all: t("shareToCompany") },
        value:
          modifiedAtch.global ||
          props.tmpAttachments[props.attachmentIndex]?.global,
        error: _inputs?.[8].error,
      });
      retval.push({
        key: "owners",
        type: "multiPicker",
        multi: true,
        title: { all: t("shareToUsers") },
        options: { all: _users },
        value:
          modifiedAtch.owners ||
          props.tmpAttachments[props.attachmentIndex]?.owners,
        nonRemovable: [props.profile.id],
        getItemText: (value) => {
          if (typeof value === "string") {
            const found = users.find((x) => x.id === value);
            if (found) return `${found.name} ${found.lName}`;
            else return value;
          } else if (value) {
            return `${value.name} ${value.lName}`;
          }
        },
        error: _inputs?.[9].error,
      });
    }

    return retval;
  }, [canUpdate, props.tmpAttachments, props.attachmentIndex]);

  const onChange = (index, value) => {
    const prop = props.tmpAttachments
      ? `${props.attachmentIndex}_inputs`
      : "inputs";

    props.dispatchModifyState({
      type: "setWithUpdateObj",
      prop: prop,
      updateObj: {
        [index]: { value: { $set: value }, error: { $set: "" } },
      },
    });
  };

  const handleLocationChanged = (locationRes) => {
    if (inputs) {
      inputs.forEach((_input, _index) => {
        if (_input?.location) {
          onChange(_index, parseLocation(locationRes, _input.location));
        }
      });
    }
  };

  return (
    <KeyboardAvoidingView
      style={[
        {
          flex: 1,
        },
        theme.centeredContainer,
      ]}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      {keyboard || (Platform.OS === "web" && props.mapIsFullScreen) ? null : (
        <Animated.View
          style={{
            height:
              Platform.OS === "web" ? previewViewSize.height : topViewHeight,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.tmpAttachmentsLength > 1 && !props.mapIsFullScreen ? (
            <View
              style={{
                width: pageW,
                height: 36,
              }}
            >
              <View style={theme.buttonContainer}>
                <Pressable
                  style={{
                    flex: 1,
                    height: 36,
                    alignSelf: "stretch",
                    backgroundColor: colors.primary,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={props.prevAttachment}
                >
                  <Icon name={"chevron-left"} size={32} color={colors.text} />
                </Pressable>

                <View
                  style={[
                    {
                      height: 36,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: colors.primary,
                    },
                  ]}
                >
                  <Text style={theme.title}>{`${props.attachmentIndex + 1} / ${
                    props.tmpAttachmentsLength
                  }`}</Text>
                </View>

                <Pressable
                  style={{
                    flex: 1,
                    alignSelf: "stretch",
                    height: 36,
                    backgroundColor: colors.primary,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={props.nextAttachment}
                >
                  <Icon name={"chevron-right"} size={32} color={colors.text} />
                </Pressable>
              </View>
            </View>
          ) : null}

          <TypeSpecificPreview
            pageH={pageH}
            pageW={pageW}
            maxImgSize={maxImageSize}
            atch={atch}
            fetchedAttachments={atchLoaded}
            dirs={dirs}
            theme={theme}
            colors={colors}
            fullscreenAtch={modifyId ? atch : fullscreenAtch}
            setFullscreenAtch={setFullscreenAtch}
            setAttachmentsFetched={setAttachmentsLoaded}
            id={atch?.tmpName}
            ogImage={!modifyId}
            standAlone={true}
            thumbnailFlex={1}
            deleteButton={!!onDelete}
            onDelete={onDelete ? _onDelete : null}
            onAttachmentEdit={_setEditingAtch}
            openPreviewModal={openPreviewModal}
            ADD_ATTACHMENT={props.ADD_ATTACHMENT}
            customToken={customToken}
            permissions={props.permissions}
          />
        </Animated.View>
      )}
      <SimpleForm
        t={t}
        lang={i18next.language}
        inputs={inputs}
        listColor={props.colors.primary}
        height={
          Platform.OS === "web" && props.mapIsFullScreen
            ? pageH
            : props.pageH - previewViewSize.height
        }
        width={props.pageW}
        onSave={props.onSave}
        disabled={!canUpdate || !atchLoaded || !atch}
        noDisabledStyle={!canUpdate}
        onSaveTitle={t("save")}
        hideButtons={Platform.OS === "web" && props.mapIsFullScreen}
        onBackButtonPress={props.onBackButtonPress}
        backButtonTitle={t(props.cancelButtonTitle ?? "cancel")}
        disableAutoFocus
        SET_GPS_LOCATION={SET_GPS_LOCATION}
        state={props.modifyState}
        dispatchState={props.dispatchModifyState}
        multiStateProp={
          props.tmpAttachments ? props.attachmentIndex : undefined
        }
        multiStateProps={
          props.tmpAttachments
            ? props.tmpAttachments.map((_, index) => index)
            : undefined
        }
        onSaveValidationError={props.onSaveValidationError}
        // extraButtons={props.extraButtons}
        extraButtons={[
          props.fromDocumentScanner && !props.single
            ? {
                key: "CameraButton",
                // title={t("addNew"),
                icon: "camera-plus",
                onPress: props.onTakeAnotherPicture,
              }
            : null,
          props.mapboxAccessToken
            ? {
                key: "MapButton",
                // title={"Refine location",
                icon: "map-search",
                loading: props.loading,
                onPress: () => {
                  if (!mapLoaded) setMapLoaded(true);
                  toggleFullScreenMap();
                },
              }
            : null,
        ]}
        appendChildren={
          Platform.OS === "web" && mapEnabled && mapLoaded ? (
            <MapView
              t={t}
              theme={theme}
              colors={colors}
              width={
                Platform.OS === "web" && props.pageW > 700 ? 700 : props.pageW
              }
              // add 36 to offset buttons height
              height={props.mapIsFullScreen ? pageH + 36 : 0}
              isFullScreen={props.mapIsFullScreen}
              toggleFullScreen={toggleFullScreenMap}
              mapState={props.mapState}
              handleLocationChanged={handleLocationChanged}
            />
          ) : null
        }
      />
    </KeyboardAvoidingView>
  );
}

export default AttachmentInputs;

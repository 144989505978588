import { Platform } from "react-native";
import update from "immutability-helper";
import naturalCompare from "../lib/naturalCompare";
import moment from "moment";
import {
  valueMatches,
  getNewSortObj,
  sortUsingSortObj,
  addToArr,
  genNewValKey,
  errorReport,
  getTranslatedText,
  isArrayWithItems,
  getPdfLangValueKey,
} from "../lib/functions";
import i18next from "i18next";

export const targetPropArrs = ["measuringDevices", "standards"];

const INITIAL_GROUP_OBJ = {
  loopImpedance: { value: 0, values: [] },
  shortCircuit: { value: 0, values: [] },
  continuity: { value: 0, values: [] },
  insulationRes: { value: 0, values: [] },
  voltage: { value: 0, values: [] },
};
const INITIAL_RCD_OBJ = {
  rcdNominalValue: { value: 0, values: [] },
  rcdMeasValue: { value: 0, values: [] },
  protection: { value: 0, values: [] },
};
const INITIAL_GGGLTYPES = ["gG 0,4s", "gG 5,0s", "gL 0,4s", "gL 5,0s"];
const INITIAL_GROUPSTYPES_OBJ = {
  type: "",
  size: "",
  grounded: "Kyllä",
  rcdKey: "",
};
const INITIAL_RCDSTYPES_OBJ = {
  type: "",
  tested: "",
  use: "",
};
const INITIAL_STATE = {
  unfinishedDocs: {},
  lastDocRequests: {},
  lastInternalDocSaves: {},
};

export const dictPathsUpdateObj = (payload) => (tmp) =>
  update(
    tmp || {},
    payload.reduce((prev, cur) => {
      if (tmp[cur.id]) {
        prev[cur.id] = {
          path: { $set: cur.newPath },
        };
      }
      return prev;
    }, {})
  );

const sortArrByMaxOrMin = (arr, maxOrMin) => {
  if (maxOrMin) {
    return arr.sort((a, b) => b - a);
  } else {
    return arr.sort((a, b) => a - b);
  }
};

const getMergedObjMeasurementObj = (
  unfinishedObj,
  measurementObj,
  measurementKey,
  maxOrMin
) => {
  if (
    unfinishedObj[measurementKey] &&
    unfinishedObj[measurementKey].value &&
    measurementObj[measurementKey] &&
    measurementObj[measurementKey].value
  ) {
    return {
      value:
        (maxOrMin &&
          unfinishedObj[measurementKey].value >
            measurementObj[measurementKey].value) ||
        (!maxOrMin &&
          unfinishedObj[measurementKey].value <
            measurementObj[measurementKey].value)
          ? unfinishedObj[measurementKey].value
          : measurementObj[measurementKey].value,
      values: sortArrByMaxOrMin(
        unfinishedObj[measurementKey].values.concat(
          measurementObj[measurementKey].values
        ),
        maxOrMin
      ),
    };
  } else if (
    (unfinishedObj[measurementKey] &&
      unfinishedObj[measurementKey].value &&
      !measurementObj[measurementKey]) ||
    (unfinishedObj[measurementKey] &&
      unfinishedObj[measurementKey].value &&
      measurementObj[measurementKey] &&
      !measurementObj[measurementKey].value)
  ) {
    return {
      value: unfinishedObj[measurementKey].value,
      values: unfinishedObj[measurementKey].values,
    };
  } else if (
    (measurementObj[measurementKey] &&
      measurementObj[measurementKey].value &&
      !unfinishedObj[measurementKey]) ||
    (measurementObj[measurementKey] &&
      measurementObj[measurementKey].value &&
      unfinishedObj[measurementKey] &&
      !unfinishedObj[measurementKey].value)
  ) {
    return {
      value: measurementObj[measurementKey].value,
      values: measurementObj[measurementKey].values,
    };
  } else {
    return {
      value: 0,
      values: [],
    };
  }
};

const getUnMergedMeasurementObj = (
  unfinishedObj,
  measurementObj,
  measurementKey,
  maxOrMin
) => {
  let newValues = unfinishedObj[measurementKey]
    ? [...unfinishedObj[measurementKey].values]
    : [];

  if (newValues.length > 0 && measurementObj[measurementKey]) {
    measurementObj[measurementKey].values.forEach((e) => {
      const index = newValues.indexOf(e);

      if (index > -1) {
        newValues.splice(index, 1);
      }
    });
  }

  if (maxOrMin) {
    return {
      value: newValues[0] || 0,
      values: newValues,
    };
  } else {
    return {
      value: newValues[0] || 0,
      values: newValues,
    };
  }
};

const getNewGroupObj = (unfinishedGroup, measurementGroup, func) => {
  return {
    loopImpedance: func(
      unfinishedGroup,
      measurementGroup,
      "loopImpedance",
      true
    ),
    shortCircuit: func(
      unfinishedGroup,
      measurementGroup,
      "shortCircuit",
      false
    ),
    continuity: func(unfinishedGroup, measurementGroup, "continuity", true),
    insulationRes: func(
      unfinishedGroup,
      measurementGroup,
      "insulationRes",
      false
    ),
    voltage: func(unfinishedGroup, measurementGroup, "voltage", false),
  };
};

const getMergedGroupTypesObj = (
  unfinishedGroupTypes,
  measurementGroupTypes
) => {
  return {
    type: unfinishedGroupTypes.type
      ? unfinishedGroupTypes.type || ""
      : measurementGroupTypes.type || "",
    size: unfinishedGroupTypes.size
      ? unfinishedGroupTypes.size || ""
      : measurementGroupTypes.size || "",
    rcdKey: unfinishedGroupTypes.rcdKey
      ? unfinishedGroupTypes.rcdKey || ""
      : measurementGroupTypes.rcdKey || "",
  };
};

const getNewRcdObj = (unfinishedRcd, measurementRcd, func) => {
  return {
    rcdNominalValue: func(
      unfinishedRcd,
      measurementRcd,
      "rcdNominalValue",
      true
    ),
    rcdMeasValue: func(unfinishedRcd, measurementRcd, "rcdMeasValue", true),
    protection: func(unfinishedRcd, measurementRcd, "protection", true),
  };
};

const getRcdTypesObjTested = (unfinishedRcdTypes, measurementRcdTypes) => {
  if (unfinishedRcdTypes.tested && unfinishedRcdTypes.tested === "Ok") {
    return "Ok";
  } else if (
    measurementRcdTypes.tested &&
    measurementRcdTypes.tested === "Ok"
  ) {
    return "Ok";
  } else return "";
};

const getMergedRcdTypesObj = (unfinishedRcdTypes, measurementRcdTypes) => {
  return {
    type: unfinishedRcdTypes.type
      ? unfinishedRcdTypes.type || ""
      : measurementRcdTypes.type || "",
    use: unfinishedRcdTypes.use
      ? unfinishedRcdTypes.use || ""
      : measurementRcdTypes.use || "",
    tested: getRcdTypesObjTested(unfinishedRcdTypes, measurementRcdTypes),
  };
};

const getTrimmedUpperCaseKeys = (obj) => {
  let newKeys = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newKeys.push({
        new: key.replace(/ /g, "").toUpperCase(),
        key,
      });
    }
  }
  return newKeys;
};

const getNewMergedObj = (
  oldObj,
  newObj,
  objKey,
  mergedObj,
  mergedTypesObj,
  getMergedObjFunc,
  getMergedTypesObjFunc
) => {
  let mergedKeys = [];
  for (const key in oldObj[objKey]) {
    if (Object.prototype.hasOwnProperty.call(oldObj[objKey], key)) {
      const found = getTrimmedUpperCaseKeys(newObj[objKey]).find(
        (x) => x.new === key.replace(/ /g, "").toUpperCase()
      );
      if (found) {
        mergedObj[key] = getMergedObjFunc(
          oldObj[objKey][key],
          newObj[objKey][found.key],
          getMergedObjMeasurementObj
        );
        mergedTypesObj[key] = getMergedTypesObjFunc(
          oldObj[objKey + "Types"][key],
          newObj[objKey + "Types"][found.key],
          getMergedObjMeasurementObj
        );
        mergedKeys.push(found.key);
      }
    }
  }
  for (const key in newObj[objKey]) {
    if (Object.prototype.hasOwnProperty.call(newObj[objKey], key)) {
      if (!mergedKeys.includes(key)) {
        mergedObj[key] = newObj[objKey][key];
        mergedTypesObj[key] = newObj[objKey + "Types"][key];
      }
    }
  }
};

const compareArrs = (arr1, arr2) => {
  let arrsMatch = true;
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) arrsMatch = false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) arrsMatch = false;
  }

  return arrsMatch;
};

const compareMeasurementObjects = (obj1, obj2) => {
  let objectsMatch = true;
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) objectsMatch = false;

  obj1Keys.forEach((key) => {
    if (
      obj1[key].value != obj2[key].value ||
      !compareArrs(obj1[key].values, obj2[key].values)
    ) {
      objectsMatch = false;
    }
  });

  return objectsMatch;
};

const getNewUnmergedObj = (
  oldObj,
  objToUnMerge,
  objKey,
  mergedObj,
  mergedTypesObj,
  getUnMergedObjFunc
) => {
  for (const key in oldObj[objKey]) {
    if (Object.prototype.hasOwnProperty.call(oldObj[objKey], key)) {
      const found = getTrimmedUpperCaseKeys(objToUnMerge[objKey]).find(
        (x) => x.new === key.replace(/ /g, "").toUpperCase()
      );

      if (found) {
        if (
          compareMeasurementObjects(
            oldObj[objKey][key],
            objToUnMerge[objKey][found.key]
          )
        ) {
          if (mergedObj[key]) delete mergedObj[key];
          if (mergedTypesObj[key]) delete mergedTypesObj[key];
        } else {
          mergedObj[key] = getUnMergedObjFunc(
            oldObj[objKey][key],
            objToUnMerge[objKey][found.key],
            getUnMergedMeasurementObj
          );
        }
      }
    }
  }
};

const getDate = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
};

const modifyValueItemWithPreviousValue = (
  currentVal,
  newVal,
  oldVal,
  itemIsArr,
  remove,
  replace,
  replaceArr,
  findWithProp,
  index = -1,
  preset,
  setProp
) => {
  if (index !== -1) {
    if (findWithProp) {
      return update(currentVal || preset, {
        [index]: { [findWithProp]: { $set: newVal } },
      });
    } else {
      if (remove) {
        return update(currentVal || preset, { $splice: [[index, 1]] });
      } else if (setProp) {
        return update(currentVal || preset, {
          [index]: { [setProp]: { $set: newVal } },
        });
      } else {
        return update(currentVal || preset, { [index]: { $set: newVal } });
      }
    }
  } else if (itemIsArr) {
    if (!currentVal) {
      if (remove) return [];
      else return [newVal];
    }

    let _index = -1;

    if (findWithProp) {
      _index = currentVal.findIndex(
        (x) => x[findWithProp] === newVal[findWithProp]
      );
    } else {
      _index = currentVal.indexOf(replace && oldVal ? oldVal : newVal);
    }

    if (remove) {
      if (_index !== -1) return update(currentVal, { $splice: [[_index, 1]] });
    } else {
      if (Array.isArray(currentVal) && !replaceArr) {
        if (_index === -1) {
          return addToArr(newVal, currentVal, findWithProp);
        } else if (replace) {
          return update(currentVal, { [_index]: { $set: newVal } });
        } else return currentVal;
      } else {
        return [newVal];
      }
    }
  } else {
    if (remove) return null;
    return newVal;
  }
};

const updateDoc = (state, docId, updateObj, stateUpdates) => {
  return update(state, { unfinishedDocs: updateObj, ...(stateUpdates || {}) });
};

const getDocObj = (state, docId) => {
  if (state.unfinishedDocs[docId]) {
    return state.unfinishedDocs[docId];
  } else {
    return {};
  }
};

const sortDocs = ({
  state,
  docsArrName,
  searchArr,
  sortObj,
  sortArrIndex,
  sortObjProp,
  comparable,
  reversed,
  options,
  lang,
  arrToSort,
  keepReverse,
  onlySetNewSortObj,
  newSortObj,
}) => {
  if (onlySetNewSortObj || docsArrName === "unfinishedDocs") {
    if (keepReverse) return state;
    else {
      return update(state, {
        [sortObjProp]: {
          $set: newSortObj ?? getNewSortObj(sortObj, sortArrIndex, reversed),
        },
      });
    }
  } else {
    const { sortedArr, newSortObj } = sortUsingSortObj(
      arrToSort ?? state[searchArr],
      comparable,
      sortObj,
      sortArrIndex,
      reversed,
      keepReverse,
      options,
      lang
    );
    return update(state, {
      [searchArr]: {
        $set: sortedArr,
      },
      [sortObjProp]: {
        $apply: (x) => newSortObj ?? x,
      },
    });
  }
};

const arrayItemsTimestampsUpdate = (_date, arrayItems) => {
  let arrItemUpdateObj = {};
  let updateParentDate = false;
  if (Array.isArray(arrayItems)) {
    arrayItems.forEach((arrayItem) => {
      let updateObj = {};
      if (arrayItem.updateParentDate) updateParentDate = true;
      if (arrayItem.deleted) {
        updateParentDate = true;
        updateObj.deleted = { $set: true };
      } else {
        updateObj.$unset = ["deleted"];
      }

      let nestedArrayItemsUpdate;
      nestedArrayItemsUpdate = arrayItemsTimestampsUpdate(
        _date,
        arrayItem.arrayItems
      );

      updateObj.date = {
        $apply: (x) =>
          nestedArrayItemsUpdate.updateParentDate ? _date : x ?? _date,
      };
      if (nestedArrayItemsUpdate.updateObj) {
        updateObj.arrayItems = nestedArrayItemsUpdate.updateObj;
      }

      arrItemUpdateObj[arrayItem.key] = {
        $apply: (__tmp) => update(__tmp || {}, updateObj),
      };
    });
    return {
      updateObj: {
        $apply: (_tmp) => update(_tmp || {}, arrItemUpdateObj),
      },
      updateParentDate,
    };
  } else {
    return { updateParentDate: true };
  }
};
function getTimestampsUpdateObj(docObj, _date, type, arrayItems) {
  let updateObj = {};
  // statuses and customStatus check for compatibility reasons, old docs without these props don't work
  if ((docObj.statuses || docObj.customStatus) && type) {
    updateObj.type = { $set: type };
  }
  let arrayItemsUpdate;
  arrayItemsUpdate = arrayItemsTimestampsUpdate(_date, arrayItems);
  updateObj.date = {
    $apply: (x) => (arrayItemsUpdate.updateParentDate ? _date : x ?? _date),
  };
  if (arrayItemsUpdate.updateObj) {
    updateObj.arrayItems = arrayItemsUpdate.updateObj;
  }

  return updateObj;
}
function getDateModifyObj(docObj, date, type, valueKey, arrayItems) {
  const _date = date ?? getDate();

  return {
    date: { $set: _date },
    timestamps: {
      $apply: (x) =>
        update(x || {}, {
          [valueKey]: {
            $apply: (_tmp) =>
              update(
                _tmp || {},
                getTimestampsUpdateObj(docObj, _date, type, arrayItems)
              ),
          },
        }),
    },
  };
}

const getValuesArrayObjType = (obj) => {
  if (typeof obj === "string") {
    return "StringArray";
  } else if (Object.prototype.hasOwnProperty.call(obj, "x")) {
    return "ChartData";
  } else if (
    Object.prototype.hasOwnProperty.call(obj, "valueKey") &&
    Object.prototype.hasOwnProperty.call(obj, "id")
  ) {
    if (Object.keys(obj).length > 2) {
      return "PickerObj";
    } else {
      return "MeasObj";
    }
  } else if (
    Object.prototype.hasOwnProperty.call(obj, "valueKey") &&
    Object.prototype.hasOwnProperty.call(obj, "title")
  ) {
    return "Modular";
  } else if (
    Object.prototype.hasOwnProperty.call(obj, "valueKey") &&
    Object.prototype.hasOwnProperty.call(obj, "connectedToValueKey")
  ) {
    return "MeasObjCon";
  } else if (Object.prototype.hasOwnProperty.call(obj, "valueKey")) {
    return "ExtraRow";
  } else if (
    Object.prototype.hasOwnProperty.call(obj, "id") &&
    Object.prototype.hasOwnProperty.call(obj, "name")
  ) {
    return "Atch";
  } else return "";
};

const getKeyWithType = (type, obj) => {
  if (type === "StringArray") {
    return obj;
  } else if (
    type === "ChartData" ||
    type === "MeasObj" ||
    type === "Modular" ||
    type === "ExtraRow" ||
    type === "PickerObj"
  ) {
    return obj.valueKey;
  } else if (type === "MeasObjCon") {
    return obj.connectedToValueKey;
  } else if (type === "Atch") {
    return obj.id;
  }
};

// updates arrayItems if root doesn't have type
export const SYNC_VALUES_TIMESTAMPS_FN = (userId, docObj, syncArrayItems) => {
  let timestampsUpdateObj = {};

  const valueKeys = Object.keys(docObj.values);
  for (let i = 0; i < valueKeys.length; i++) {
    const valueKey = valueKeys[i];
    const value = docObj.values[valueKey];
    if (
      syncArrayItems &&
      Array.isArray(value) &&
      value[0] &&
      !docObj.timestamps?.[valueKey]?.type
    ) {
      const type = getValuesArrayObjType(value[0]);
      timestampsUpdateObj[valueKey] = (tmp) =>
        update(
          tmp || {},
          getTimestampsUpdateObj(
            docObj,
            docObj.date,
            type,
            value.map((x) => ({
              key: getKeyWithType(type, x),
              updateParentDate: true,
              arrayItems:
                type === "Modular" &&
                Array.isArray(x.innerItems) &&
                x.innerItems.length > 0
                  ? x.innerItems.map((innerItem) => ({
                      key: innerItem.valueKey,
                      updateParentDate: true,
                    }))
                  : null,
            })),
            true
          )
        );
    } else if (!docObj.timestamps?.[valueKey]) {
      timestampsUpdateObj[valueKey] = { $set: { date: docObj.date } };
    }
  }

  return {
    creatorId: { $set: userId },
    timestamps: timestampsUpdateObj,
  };
};
// !

export const MODIFY_OBJECT_ARR_ITEM = (state, action) => {
  const {
    userId,
    docId,
    valueKey,
    idProp,
    oldVal,
    value,
    propToSet,
    propsToSet,
    sortProp,
    preset,
    date,
    type,
    duplicateItemFor,
  } = action.payload;

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          [valueKey]: (tmpItems) =>
            update(tmpItems || preset || [], {
              $apply: (arr) => {
                const index = arr.findIndex(
                  (x) => x[idProp] === oldVal[idProp]
                );
                if (index !== -1) {
                  const newArr = update(arr, {
                    [index]: propsToSet
                      ? propsToSet.reduce((acc, cur) => {
                          acc[cur.prop] = { $set: cur.value };
                          return acc;
                        }, {})
                      : { [propToSet]: { $set: value } },
                  });
                  return sortProp
                    ? newArr.sort((a, b) =>
                        (a[sortProp] ?? "").localeCompare(
                          b[sortProp] ?? "",
                          undefined,
                          {
                            numeric: true,
                            sensitivity: "base",
                          }
                        )
                      )
                    : newArr;
                } else {
                  return arr;
                }
              },
            }),
        }),
      ...getDateModifyObj(
        getDocObj(state, docId),
        date,
        action.payload.type,
        valueKey,
        [{ key: oldVal[idProp] }]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  let newState = updateDoc(state, docId, updateObj, stateUpdates);

  if (duplicateItemFor) {
    const arr = newState.unfinishedDocs[docId].values[valueKey];
    let newItem = arr.find((x) => x[idProp] === oldVal[idProp]);
    for (let i = 0; i < duplicateItemFor; i++) {
      newState = ADD_TO_OBJECT_ARR_WITH_GENERATED_ID(newState, {
        payload: {
          userId,
          docId,
          valueKey,
          duplicateValueKey: newItem[idProp],
          value: newItem,
          idProp: idProp,
          type,
        },
      });
    }
  }

  return newState;
};

export const SET_DOC_PROP = (state, action) => {
  const { docId, prop, value } = action.payload;
  if (!docId) throw "SET_DOC_PROP" + " missing docId: " + docId;
  const updateObj = {
    [docId]: {
      [prop]: { $set: value },
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const SET_MULTIPLE_DOCS_PROPS = (state, action) => {
  if (Array.isArray(action.payload)) {
    const updateObj = action.payload.reduce((_updateObj, cur) => {
      let arrName;
      let isUnfinishedDoc = false;
      if (state.unfinishedDocs[cur.docId]) {
        arrName = "unfinishedDocs";
        isUnfinishedDoc = true;
      }

      if (arrName) {
        const docUpdateObj = {
          [cur.prop]: { $set: cur.value },
          date: { $set: getDate() },
        };
        if (!_updateObj[arrName]) _updateObj[arrName] = {};
        if (isUnfinishedDoc) {
          _updateObj.unfinishedDocs[cur.docId] = docUpdateObj;
        }
      }

      return _updateObj;
    }, {});

    return update(state, updateObj);
  } else {
    return state;
  }
};

export const REPLACE_SIGNATURES = (state, action) => {
  const { docId, newSignatures, newCreatorSignature } = action.payload;
  if (!docId) throw "REPLACE_SIGNATURES" + "missing docId";

  const updateObj = {
    [docId]: {
      signatures: { $apply: (x) => newSignatures ?? x },
      creatorSignature: { $apply: (x) => newCreatorSignature ?? x },
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const REMOVE_FROM_DOC_EMAILS = (state, action) => {
  const { docId, email } = action.payload;
  if (!docId) throw "REMOVE_FROM_DOC_EMAILS" + "missing docId";
  const updateObj = {
    [docId]: {
      emails: (tmpEmails) =>
        update(tmpEmails || [], {
          $apply: function (x) {
            const index = x.findIndex((_email) => _email === email);
            if (index !== -1) return update(x, { $splice: [[index, 1]] });
            else return x;
          },
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const PUSH_TO_DOC_EMAILS = (state, action) => {
  const { docId, email } = action.payload;
  if (!docId) throw "PUSH_TO_DOC_EMAILS" + "missing docId";
  const updateObj = {
    [docId]: {
      emails: (tmpEmails) =>
        update(tmpEmails || [], {
          $apply: function (x) {
            if (!x.includes(email)) return update(x, { $push: [email] });
            else return x;
          },
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const ADD_DOC_SIGNATURE = (state, action) => {
  const { docId, newSignature } = action.payload;
  if (!docId) throw "ADD_DOC_SIGNATURE" + "missing docId";
  const updateObj = {
    [docId]: {
      signatures: (tmpSignatures) =>
        update(tmpSignatures || [], {
          $push: [newSignature],
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const REMOVE_DOC_SIGNATURE = (state, action) => {
  const { docId, signatureIndex } = action.payload;
  if (!docId) throw "REMOVE_DOC_SIGNATURE" + "missing docId";
  const updateObj = {
    [docId]: {
      signatures: (tmpSignatures) =>
        update(tmpSignatures || [], {
          $splice: [[signatureIndex, 1]],
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const REMOVE_CREATOR_SIGNATURE = (state, action) => {
  const { docId } = action.payload;
  if (!docId) throw "REMOVE_CREATOR_SIGNATURE" + "missing docId";
  const updateObj = {
    [docId]: {
      creatorSignature: { $set: null },
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const MODIFY_CREATOR_SIGNATURE = (state, action) => {
  const { docId, newParams = {} } = action.payload;
  if (!docId) throw "MODIFY_CREATOR_SIGNATURE" + "missing docId";
  const updateObj = {
    [docId]: {
      creatorSignature: (tmpSignature) =>
        update(tmpSignature || {}, {
          $merge: newParams,
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const MODIFY_DOC_SIGNATURE = (state, action) => {
  const { docId, signatureIndex, newParams = {} } = action.payload;
  if (!docId) throw "MODIFY_DOC_SIGNATURE" + "missing docId";
  const updateObj = {
    [docId]: {
      signatures: (tmpSignatures) =>
        update(tmpSignatures || [], {
          [signatureIndex]: (tmpSignature) =>
            update(tmpSignature || {}, {
              $merge: newParams,
            }),
        }),
      date: { $set: getDate() },
    },
  };
  return updateDoc(state, docId, updateObj);
};

export const ADD_TO_OBJECT_ARR = (state, action) => {
  const { docId, valueKey, value, idProp, sortProp, date } = action.payload;

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          [valueKey]: (tmpItems) =>
            update(tmpItems || [], {
              $apply: (arr) => {
                const index = arr.findIndex((x) => x[idProp] === value[idProp]);
                if (index !== -1) {
                  return arr;
                } else {
                  const newArr = update(arr, {
                    $push: [value],
                  });
                  return sortProp
                    ? newArr.sort((a, b) =>
                        (a[sortProp] ?? "").localeCompare(
                          b[sortProp] ?? "",
                          "sw",
                          {
                            numeric: true,
                            sensitivity: "base",
                          }
                        )
                      )
                    : newArr;
                }
              },
            }),
        }),
      ...getDateModifyObj(
        getDocObj(state, docId),
        date,
        action.payload.type,
        valueKey,
        [{ key: value[idProp], updateParentDate: true }]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

export const REMOVE_OBJECT_ARR_ITEM = (state, action) => {
  const { docId, valueKey, valueKeys, idProp, oldVal, date, unsetOldValKeys } =
    action.payload;
  const _date = date ?? getDate();

  let timestampsUpdateObj = {
    [valueKey]: {
      $apply: (_tmp) =>
        update(
          _tmp || {},
          getTimestampsUpdateObj(
            getDocObj(state, docId),
            _date,
            action.payload.type,
            [{ key: oldVal[idProp], deleted: true }]
          )
        ),
    },
  };

  const updateFn = (tmpItems) =>
    update(tmpItems || [], {
      $apply: (arr) => {
        const index = arr.findIndex((x) => x[idProp] === oldVal[idProp]);
        if (index !== -1) {
          return update(arr, { $splice: [[index, 1]] });
        } else {
          return arr;
        }
      },
    });

  let stateUpdates;

  let updateObj = {
    [docId]: {
      values: (tmpValues) => {
        if (valueKeys) {
          return update(tmpValues || {}, {
            $unset: Object.keys(tmpValues).filter((x) =>
              valueKeys.some((valueKey) => {
                if (
                  x.startsWith(
                    valueKey + "_" + (unsetOldValKeys ? oldVal.valueKey : "")
                  )
                ) {
                  timestampsUpdateObj[x] = {
                    $set: { date: _date, deleted: true },
                  };
                  return true;
                } else {
                  return false;
                }
              })
            ),
            ...valueKeys.reduce((prev, cur) => {
              timestampsUpdateObj[cur] = {
                $apply: (_tmp) =>
                  update(
                    _tmp || {},
                    getTimestampsUpdateObj(
                      getDocObj(state, docId),
                      _date,
                      action.payload.type,
                      [{ key: oldVal[idProp], deleted: true }]
                    )
                  ),
              };
              // timestampsUpdateObj[cur] = {
              //   $set: { date: _date },
              // };
              prev[cur] = updateFn;
              return prev;
            }, {}),
          });
        } else {
          return update(tmpValues || {}, {
            $unset: Object.keys(tmpValues).filter((x) => {
              if (
                x.startsWith(
                  valueKey + "_" + (unsetOldValKeys ? oldVal.valueKey : "")
                )
              ) {
                timestampsUpdateObj[x] = {
                  $set: { date: _date, deleted: true },
                };
                return true;
              } else {
                return false;
              }
            }),
            // $unset: unsetKey ? Object.keys(tmpValues).filter((x) =>
            //   x.startsWith(valueKey + "_" + oldVal.valueKey)
            // ) : [],
            [valueKey]: updateFn,
          });
        }
      },
      timestamps: (x) => update(x || {}, timestampsUpdateObj),
      date: { $set: _date },
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};
const receiveDocs = (state, action) => {
  const { data, cleanSignIn, dontOverride, overrideProp } = action.payload;

  let newUnfinishedDocs = cleanSignIn ? {} : { ...state.unfinishedDocs };

  if (data.docs && data.docs.length > 0) {
    data.docs.forEach((docFromData) => {
      const id = docFromData.id;
      if (Object.prototype.hasOwnProperty.call(newUnfinishedDocs, id)) {
        if (dontOverride) {
          if (overrideProp) {
            newUnfinishedDocs[id] = update(newUnfinishedDocs[id], {
              [overrideProp]: { $set: docFromData[overrideProp] },
            });
          }
        } else {
          var a = moment(newUnfinishedDocs[id].date);
          var b = moment(docFromData.date);

          if (b.isAfter(a)) {
            newUnfinishedDocs[id] = docFromData;
          }
        }
      } else {
        newUnfinishedDocs[id] = docFromData;
      }
    });
  }

  return update(state, {
    unfinishedDocs: { $set: newUnfinishedDocs },
  });
};

const duplicateValue = (
  state,
  newState,
  userId,
  docId,
  valueKey,
  duplicateValueKey,
  newValueKey,
  prefix,
  newPrefix,
  tmpValues,
  duplicatedValueKeys,
  duplicatedExtraRows,
  onlyExtraRows
) => {
  let _newState = newState;
  Object.keys(tmpValues).forEach((tmpValueKey) => {
    if (!duplicatedValueKeys.includes(tmpValueKey)) {
      if (tmpValueKey.endsWith("connected")) {
        // we can use timestamp info to check the value type
        const valueType =
          state.unfinishedDocs[docId].timestamps[tmpValueKey]?.type;
        // ! example timestamp
        // "measurementObjects/2_connected": {
        //   "type": "MeasObjCon",
        //   "date": "2024-11-19T17:03:17.634000+02:00",
        //   "arrayItems": {
        //       "1u8b": {
        //           "date": "2024-11-19T17:03:17.634000+02:00"
        //       }
        //   }
        // }
        // ! example value
        // "measurementObjects/2_connected": [
        //   {
        //       "valueKey": "2u8b",
        //       "measurementObjectId": "measurementObjects/1",
        //       "connectedToValueKey": "1u8b"
        //   }
        // ]
        if (valueType === "MeasObjCon") {
          const value = tmpValues[tmpValueKey];
          if (isArrayWithItems(value)) {
            const splitValueKey = valueKey.split("_");
            const splitTmpValueKey = tmpValueKey.split("_");
            value.forEach((x) => {
              // need to replace the connectedToValueKey with newValueKey
              if (
                x.measurementObjectId === splitValueKey[0] &&
                x.connectedToValueKey === duplicateValueKey
              ) {
                _newState = ADD_MEASUREMENT_OBJ_CONNECTION(_newState, {
                  payload: {
                    docId,
                    measurementObjectId: splitTmpValueKey[0],
                    measurementObjectValueKey: x.valueKey,
                    measurementObjectToConnectId: splitValueKey[0],
                    measurementObjectToConnectValueKey: newValueKey,
                  },
                });
              }
            });
          }
        }
      } else if (tmpValueKey.startsWith(prefix)) {
        const value = tmpValues[tmpValueKey];
        // we can use timestamp info to check the value type
        const valueType =
          state.unfinishedDocs[docId].timestamps[tmpValueKey]?.type;
        // Primitive = 0
        // StringArray = 1 string array
        // ExtraRow = 2 special handling
        // Atch = 3 object array
        // MeasObj = 4 shouldn't be nested
        // Modular = 5 shouldn't be nested
        // MeasObjCon = 6 special handling
        // ChartData = 7 object
        // Measurement = 8 object
        // PickerObj = 9 object

        // if duplicating measurementObjects items the valueKeys are
        // measurementObjects/1 = main objects, need to duplicate one item
        // measurementObjects/1_43 = main items values
        // ...
        // measurementObjects/1_44 = [] if main item has extraRow cells they will be arrays
        // measurementObjects/1_44_12ub_92 = main items extraRows values

        const splitKey = tmpValueKey.split("_");
        // the extraRow key should always be second to last
        if (duplicatedExtraRows[splitKey[splitKey.length - 2]]) {
          // if found, replace with new key
          splitKey[splitKey.length - 2] =
            duplicatedExtraRows[splitKey[splitKey.length - 2]];
        }
        const splitNewPrefix = newPrefix.split("_");
        // the prefix may already have underscores so we can't just use a hardcoded index here
        let _newValueKey = `${newPrefix}_${splitKey
          .slice(splitNewPrefix.length)
          .join("_")}`;

        // need to loop twice, first to duplicate extraRows and get their valueKeys
        // second time to duplicate all non dynamic values and use the new valueKeys from extraRow duplication if needed
        if (onlyExtraRows) {
          if (valueType === "ExtraRow") {
            duplicatedValueKeys.push(tmpValueKey);

            value.forEach((tmpExtraRow) => {
              const newExtraRowValueKey = genNewValKey(
                (_newState.unfinishedDocs[docId].currentValueKey ?? 0) + 1,
                userId
              );
              _newState = update(_newState, {
                unfinishedDocs: {
                  [docId]: {
                    currentValueKey: { $apply: (x) => (x ?? 0) + 1 },
                  },
                },
              });

              // save the valueKeys so we can use them later
              duplicatedExtraRows[tmpExtraRow.valueKey] = newExtraRowValueKey;

              _newState = ADD_TO_OBJECT_ARR_WITH_GENERATED_ID(_newState, {
                payload: {
                  userId,
                  docId,
                  valueKey: _newValueKey,
                  value: { ...tmpExtraRow },
                  idProp: "valueKey",
                  type: valueType,
                  currentValueKey: newExtraRowValueKey,
                },
              });
            });
          }
        } else {
          duplicatedValueKeys.push(tmpValueKey);

          // use both string and int values since backend unreliably could return either
          switch (valueType) {
            case undefined:
            case 0:
            case "Primitive":
              {
                _newState = MODIFY_VALUE(_newState, {
                  payload: {
                    userId,
                    docId,
                    valueKey: _newValueKey,
                    value: value,
                    idProp: "valueKey",
                    type: valueType,
                  },
                });
              }
              break;
            case 1:
            case "StringArray":
              {
                value.forEach(
                  (tmpInnerValue) =>
                    (_newState = ADD_TO_STRING_ARR(_newState, {
                      payload: {
                        docId,
                        valueKey: _newValueKey,
                        value: tmpInnerValue,
                      },
                    }))
                );
              }
              break;
            case 7:
            case 8:
            case "ChartData": // just duplicate the object
            case "Measurement":
              {
                _newState = MODIFY_VALUE(_newState, {
                  payload: {
                    userId,
                    docId,
                    valueKey: _newValueKey,
                    value: JSON.parse(JSON.stringify(value)),
                    idProp: "valueKey",
                    type: valueType,
                  },
                });
              }
              break;
            case 3:
            case 9:
            case "PickerObj":
            case "Atch":
              {
                value.forEach((tmpObj) => {
                  _newState = ADD_TO_OBJECT_ARR(_newState, {
                    payload: {
                      type: valueType,
                      docId,
                      valueKey: _newValueKey,
                      value: JSON.parse(JSON.stringify(tmpObj)),
                      idProp: "id",
                    },
                  });
                });
              }
              break;
            // case "MeasObjCon": // handled separately
            // case "ExtraRow": // handled separately
            // case "MeasObj": // shouldn't be nested
            // case "Modular": // shouldn't be nested
            //   break;
            default:
              break;
          }
        }
      }
    }
  });

  return _newState;
};

const duplicateInnerItem = (
  innerItem,
  newInnerItemValueKey,
  dontAddNew,
  state,
  newState,
  userId,
  docId,
  valueKey,
  itemValueKey,
  newItemValueKey,
  tmpValues,
  duplicatedValueKeys,
  duplicatedExtraRows
) => {
  let _newState = newState;
  let _newInnerItemValueKey;

  if (newInnerItemValueKey) {
    _newInnerItemValueKey = newInnerItemValueKey;
  } else {
    _newInnerItemValueKey = genNewValKey(
      (_newState.unfinishedDocs[docId].currentValueKey ?? 0) + 1,
      userId
    );
    _newState = update(_newState, {
      unfinishedDocs: {
        [docId]: { currentValueKey: { $apply: (x) => (x ?? 0) + 1 } },
      },
    });
  }

  if (!dontAddNew) {
    _newState = ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID(_newState, {
      payload: {
        userId,
        docId,
        valueKey: valueKey,
        value: { ...innerItem },
        itemValueKey: newItemValueKey,
        idProp: "valueKey",
        sortProp: "title",
        currentValueKey: _newInnerItemValueKey,
      },
    });
  }

  const prefix = `${valueKey}_${itemValueKey}_${innerItem.valueKey}`;
  const newPrefix = `${valueKey}_${newItemValueKey}_${_newInnerItemValueKey}`;

  // duplicate inner items values
  _newState = duplicateValue(
    state,
    _newState,
    userId,
    docId,
    valueKey,
    itemValueKey,
    newItemValueKey,
    prefix,
    newPrefix,
    tmpValues,
    duplicatedValueKeys,
    duplicatedExtraRows,
    true
  );
  _newState = duplicateValue(
    state,
    _newState,
    userId,
    docId,
    valueKey,
    itemValueKey,
    newItemValueKey,
    prefix,
    newPrefix,
    tmpValues,
    duplicatedValueKeys,
    duplicatedExtraRows,
    false
  );
  return _newState;
};

const ADD_TO_OBJECT_ARR_WITH_GENERATED_ID = (state, action) => {
  // use signalr modify obj arr
  const {
    docId,
    valueKey,
    valueKeys,
    value,
    idProp,
    sortProp,
    currentValueKey,
    date,
    distinct,
    userId,
    presetObjects,
    type, // MeasObj
    duplicateValueKey,
    addMeasurementObjConnection,
    modifyObjectArrItem,
    handleNewValueKey,
  } = action.payload;
  if (!docId) throw "ADD_TO_OBJECT_ARR_WITH_GENERATED_ID" + "missing docId";
  const docObj = getDocObj(state, docId);
  const _date = date ?? getDate();

  const newValueKey =
    currentValueKey ?? genNewValKey((docObj.currentValueKey ?? 0) + 1, userId);

  const _presetObjects =
    docObj.values?.[valueKey + "_presetObjects"] || presetObjects;

  let timestampsUpdateObj = {
    [valueKey]: {
      $apply: (_tmp) =>
        update(
          _tmp || {},
          getTimestampsUpdateObj(
            getDocObj(state, docId),
            _date,
            action.payload.type,
            [{ key: `${newValueKey}`, updateParentDate: true }]
          )
        ),
    },
  };

  const updateFn = (tmpItems) =>
    update(tmpItems || [], {
      $apply: (arr) => {
        let index = -1;
        if (distinct) {
          index = arr.findIndex(
            (x) => x.id === value.id || x[idProp] === value[idProp]
          );
        }
        if (index !== -1) return arr;

        const valueUpdateObj = {
          [idProp]: { $set: `${newValueKey}` },
        };

        if (type === "Modular") {
          valueUpdateObj.innerItems = { $set: undefined };
        }

        if (duplicateValueKey && value) {
          let titleProp =
            type === "MeasObj" ? "id" : type === "Modular" ? "title" : null;
          if (titleProp) {
            let newTitle = value[titleProp];
            if (newTitle) {
              // if theres a number at the end of the "title" string we can increment it
              // find the overall highest number with the same prefix
              // e.g. if there's RDJ OH 1, RDJ OH 2, RDJ OH 3, RDJ OH 4 the new id should be RDJ OH 5 if duplicating any of these
              const splitTitle = newTitle.split(" ");
              const prefix =
                splitTitle.length === 1
                  ? splitTitle[0]
                  : splitTitle.slice(0, -1).join(" ");
              newTitle = `${prefix} ${
                getHighestTitleNumber(arr, titleProp, prefix) + 1
              }`;
              valueUpdateObj[titleProp] = { $set: newTitle };
            }
          }
        }
        const newArr = update(arr, {
          $push: [update(value || {}, valueUpdateObj)],
        });
        return sortProp
          ? newArr.sort((a, b) =>
              (a[sortProp] ?? "").localeCompare(b[sortProp] ?? "", "sw", {
                numeric: true,
                sensitivity: "base",
              })
            )
          : newArr;
      },
    });

  /*
    timestamps: {
      "measObjects/1": {date: 11.11.11, arrayItems: {[newValueKey]: {date: 11.11.11}}},
      "measObjects/2": {date: 11.11.11, arrayItems: {[newValueKey]: {date: 11.11.11}}},
      "measObjects/3": {date: 11.11.11, arrayItems: {[newValueKey]: {date: 11.11.11}}},
    }
  */
  // TODO need to update lastmodifieds or use functions to add the duplicate values instead of just copying them
  let newState = state;
  let stateUpdates;

  let updateObj = {
    [docId]: {
      values: (tmpValues) => {
        if (valueKeys) {
          return update(
            tmpValues || {},
            valueKeys.reduce((prev, cur) => {
              timestampsUpdateObj[cur] = {
                $apply: (_tmp) =>
                  update(
                    _tmp || {},
                    getTimestampsUpdateObj(
                      getDocObj(state, docId),
                      _date,
                      action.payload.type,
                      [{ key: `${newValueKey}` }]
                    )
                  ),
              };
              prev[cur] = (tmpItems) => updateFn(tmpItems);
              return prev;
            }, {})
          );
        } else {
          return update(tmpValues || {}, {
            [valueKey]: (tmpItems) => updateFn(tmpItems),
          });
        }
      },
      currentValueKey: {
        $set:
          typeof newValueKey === "string"
            ? parseInt(newValueKey.split("u")[0])
            : newValueKey,
      },
      date: { $set: _date },
      timestamps: {
        $apply: (x) => update(x || {}, timestampsUpdateObj),
      },
      // timestamps: (x) => update(x || {}, timestampsUpdateObj),
      // date: { $set: _date },
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }

  newState = updateDoc(newState, docId, updateObj, stateUpdates);

  // handle duplication
  let duplicatedExtraRows = {};
  let duplicatedValueKeys = [];
  if (duplicateValueKey) {
    const tmpValues = state.unfinishedDocs[docId].values;

    if (type === "MeasObj" || type === "ExtraRow") {
      // if theres extraRows inside measurementObjects we need to handle their duplication as well
      // duplicate main items values
      const prefix = `${valueKey}_${duplicateValueKey}`;
      const newPrefix = `${valueKey}_${newValueKey}`;
      // duplicate inner items values
      newState = duplicateValue(
        state,
        newState,
        userId,
        docId,
        valueKey,
        duplicateValueKey,
        newValueKey,
        prefix,
        newPrefix,
        tmpValues,
        duplicatedValueKeys,
        duplicatedExtraRows,
        true
      );
      newState = duplicateValue(
        state,
        newState,
        userId,
        docId,
        valueKey,
        duplicateValueKey,
        newValueKey,
        prefix,
        newPrefix,
        tmpValues,
        duplicatedValueKeys,
        duplicatedExtraRows,
        false
      );
    } else if (type === "Modular") {
      const currentModularItems =
        newState.unfinishedDocs[docId].values[valueKey];
      if (isArrayWithItems(currentModularItems)) {
        const currentModularItem = currentModularItems.find(
          (x) => x.valueKey === duplicateValueKey
        );

        // duplicate inner items and their values
        if (isArrayWithItems(currentModularItem?.innerItems)) {
          currentModularItem.innerItems.forEach((innerItem) => {
            newState = duplicateInnerItem(
              innerItem,
              null,
              false,
              state,
              newState,
              userId,
              docId,
              valueKey,
              duplicateValueKey,
              newValueKey,
              tmpValues,
              duplicatedValueKeys,
              duplicatedExtraRows
            );
          });
        }

        // duplicate main items values
        const prefix = `${valueKey}_${duplicateValueKey}`;
        const newPrefix = `${valueKey}_${newValueKey}`;
        // duplicate inner items values
        newState = duplicateValue(
          state,
          newState,
          userId,
          docId,
          valueKey,
          duplicateValueKey,
          newValueKey,
          prefix,
          newPrefix,
          tmpValues,
          duplicatedValueKeys,
          duplicatedExtraRows,
          true
        );
        newState = duplicateValue(
          state,
          newState,
          userId,
          docId,
          valueKey,
          duplicateValueKey,
          newValueKey,
          prefix,
          newPrefix,
          tmpValues,
          duplicatedValueKeys,
          duplicatedExtraRows,
          false
        );
      }
    } else {
      throw "Unsupported duplication type";
    }
  }

  if (_presetObjects) {
    const lang =
      (docObj && docObj.values[getPdfLangValueKey({ id: userId })]) ||
      i18next.language;

    _presetObjects.forEach((x) => {
      newState = ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID(newState, {
        payload: {
          userId,
          docId,
          valueKey: valueKey,
          value:
            typeof x === "string"
              ? { title: x }
              : { title: getTranslatedText(x.title, lang) },
          itemValueKey: newValueKey,
          idProp: "valueKey",
          sortProp: "title",
        },
      });
    });
  }

  if (addMeasurementObjConnection) {
    newState = ADD_MEASUREMENT_OBJ_CONNECTION(newState, {
      payload: {
        ...addMeasurementObjConnection,
        // replace undefined value with the new valueKey
        measurementObjectToConnectValueKey:
          addMeasurementObjConnection.measurementObjectToConnectValueKey ??
          newValueKey,
        measurementObjectValueKey:
          addMeasurementObjConnection.measurementObjectValueKey ?? newValueKey,
      },
    });
  }

  if (modifyObjectArrItem) {
    newState = MODIFY_OBJECT_ARR_ITEM(newState, {
      payload: modifyObjectArrItem,
    });
  }

  handleNewValueKey?.(newValueKey);
  return newState;
};
const ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID = (state, action) => {
  const {
    docId,
    valueKey,
    value,
    itemValueKey,
    idProp,
    sortProp,
    currentValueKey,
    date,
    userId,
    duplicateValueKey,
  } = action.payload;
  if (!docId)
    throw "ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID" + "missing docId";
  action.payload.type = "Modular";
  const docObj = getDocObj(state, docId);
  const itemIndex = docObj?.values[valueKey].findIndex(
    (x) => x.valueKey == itemValueKey
  );

  if (itemIndex == -1) return state;

  const _newValueKey =
    currentValueKey ?? genNewValKey((docObj.currentValueKey ?? 0) + 1, userId);

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          [valueKey]: (tmpItems) =>
            update(tmpItems || [], {
              [itemIndex]: (tmpItem) =>
                update(tmpItem || {}, {
                  innerItems: (tmpInnerItems) =>
                    update(tmpInnerItems || [], {
                      $apply: function (arr) {
                        let newInnerItem;
                        if (duplicateValueKey && value) {
                          const valueUpdateObj = {
                            [idProp]: { $set: `${_newValueKey}` },
                          };

                          let titleProp = "title";
                          if (titleProp) {
                            let newTitle = value[titleProp];
                            if (newTitle) {
                              // if theres a number at the end of the "title" string we can increment it
                              // find the overall highest number with the same prefix
                              // e.g. if there's RDJ OH 1, RDJ OH 2, RDJ OH 3, RDJ OH 4 the new id should be RDJ OH 5 if duplicating any of these
                              const splitTitle = newTitle.split(" ");
                              const prefix =
                                splitTitle.length === 1
                                  ? splitTitle[0]
                                  : splitTitle.slice(0, -1).join(" ");
                              newTitle = `${prefix} ${
                                getHighestTitleNumber(arr, titleProp, prefix) +
                                1
                              }`;
                              valueUpdateObj[titleProp] = { $set: newTitle };
                            }
                          }

                          newInnerItem = update(value || {}, valueUpdateObj);
                        } else {
                          newInnerItem = update(value || {}, {
                            [idProp]: { $set: `${_newValueKey}` },
                          });
                        }

                        const newArr = update(arr, {
                          $push: [newInnerItem],
                        });
                        return sortProp
                          ? newArr.sort((a, b) =>
                              (a[sortProp] ?? "").localeCompare(
                                b[sortProp] ?? "",
                                undefined,
                                {
                                  numeric: true,
                                  sensitivity: "base",
                                }
                              )
                            )
                          : newArr;
                      },
                    }),
                }),
            }),
        }),
      currentValueKey: {
        $set:
          typeof _newValueKey === "string"
            ? parseInt(_newValueKey.split("u")[0])
            : _newValueKey,
      },
      ...getDateModifyObj(
        state.unfinishedDocs[docId],
        date,
        action.payload.type,
        valueKey,
        [
          {
            key: itemValueKey,
            arrayItems: [{ key: _newValueKey, updateParentDate: true }],
          },
        ]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  let newState = updateDoc(state, docId, updateObj, stateUpdates);

  if (duplicateValueKey && value) {
    const tmpValues = state.unfinishedDocs[docId].values;
    let duplicatedExtraRows = {};
    let duplicatedValueKeys = [];
    newState = duplicateInnerItem(
      value,
      _newValueKey,
      true,
      state,
      newState,
      userId,
      docId,
      valueKey,
      itemValueKey,
      itemValueKey,
      tmpValues,
      duplicatedValueKeys,
      duplicatedExtraRows
    );
  }

  return newState;
};

const REMOVE_OBJECT_ARR_INNER_ITEM = (state, action) => {
  const {
    docId,
    valueKey,
    itemValueKey,
    innerItemValueKey,
    date,
    findWithProp,
    matchWith,
  } = action.payload;
  const _date = date ?? getDate();
  if (!docId) throw "REMOVE_OBJECT_ARR_INNER_ITEM" + "missing docId";
  const docObj = getDocObj(state, docId);
  const itemIndex = docObj?.values?.[valueKey]?.findIndex(
    (x) => x.valueKey == itemValueKey
  );

  if (itemIndex == -1) return state;

  const innerItems = docObj?.values?.[valueKey]?.[itemIndex]?.innerItems;
  let indexToRemove = -1;
  let _innerItemValueKey;

  if (innerItems) {
    for (let i = 0; i < innerItems.length; i++) {
      const x = innerItems[i];

      if (
        findWithProp
          ? x[findWithProp] === matchWith
          : x.valueKey == innerItemValueKey
      ) {
        indexToRemove = i;
        _innerItemValueKey = x.valueKey;
        break;
      }
    }
  }

  if (indexToRemove == -1) return state;

  let timestampsUpdateObj = {};
  let valueKeysToRemove = [];

  const valuesKeys = Object.keys(state.unfinishedDocs[docId].values);
  for (let i = 0; i < valuesKeys.length; i++) {
    const _valueKey = valuesKeys[i];
    if (
      _valueKey.startsWith(`${valueKey}_${itemValueKey}_${_innerItemValueKey}`)
    ) {
      valueKeysToRemove.push(_valueKey);
      timestampsUpdateObj[_valueKey] = {
        $set: { date: _date, deleted: true },
      };
    }
  }

  // const valueKeysToRemove = getValueKeysToRemove(
  //   [`${valueKey}_${itemValueKey}_${_innerItemValueKey}`],
  //   state.unfinishedDocs[docId].values
  // );

  timestampsUpdateObj[valueKey] = {
    $apply: (_tmp) =>
      update(
        _tmp || {},
        getTimestampsUpdateObj(
          state.unfinishedDocs[docId],
          _date,
          action.payload.type,
          [
            {
              key: itemValueKey,
              arrayItems: [{ key: _innerItemValueKey, deleted: true }],
            },
          ]
        )
      ),
  };

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          $unset: valueKeysToRemove,
          [valueKey]: (tmpItems) =>
            update(tmpItems || [], {
              [itemIndex]: (tmpItem) =>
                update(tmpItem || {}, {
                  innerItems: (tmpInnerItems) =>
                    update(tmpInnerItems || [], {
                      $splice: [[indexToRemove, 1]],
                    }),
                }),
            }),
        }),
      date: { $set: _date },
      timestamps: {
        $apply: (x) => update(x || {}, timestampsUpdateObj),
      },
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

const ADD_TO_STRING_ARR = (state, action) => {
  const { docId, valueKey, value, date, defaultArr } = action.payload;
  action.payload.type = "StringArray";
  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          [valueKey]: (tmpItems) =>
            update(tmpItems || defaultArr || [], {
              $apply: (arr) => {
                if (arr.includes(value)) {
                  return arr;
                } else {
                  return update(arr, { $push: [value] }).sort((a, b) =>
                    a.localeCompare(b, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    })
                  );
                }
              },
            }),
        }),
      ...getDateModifyObj(
        getDocObj(state, docId),
        date,
        action.payload.type,
        valueKey,
        [{ key: value, updateParentDate: true }]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

const ADD_MEASUREMENT_OBJ_CONNECTION = (state, action) => {
  const {
    docId,
    measurementObjectId,
    measurementObjectValueKey,
    measurementObjectToConnectId,
    measurementObjectToConnectValueKey,
    date,
  } = action.payload;

  if (!docId) throw "ADD_MEASUREMENT_OBJ_CONNECTION" + "missing docId";
  action.payload.type = "MeasObjCon";
  const valueKey = measurementObjectId + "_connected";

  const newObj = {
    valueKey: measurementObjectValueKey.toString(),
    measurementObjectId: measurementObjectToConnectId,
    connectedToValueKey: measurementObjectToConnectValueKey.toString(),
  };
  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: {
        [valueKey]: {
          $apply: (connections) => {
            if (connections) {
              const alreadyConnected = connections.findIndex(
                (x) =>
                  x.connectedToValueKey === measurementObjectToConnectValueKey
              );
              if (alreadyConnected !== -1) {
                return update(connections, {
                  [alreadyConnected]: {
                    measurementObjectId: {
                      $set: measurementObjectToConnectId,
                    },
                    valueKey: {
                      $set: measurementObjectValueKey,
                    },
                  },
                });
              } else if (
                connections.findIndex(
                  (x) =>
                    x.connectedToValueKey ===
                      measurementObjectToConnectValueKey &&
                    x.valueKey === measurementObjectValueKey &&
                    x.measurementObjectId === measurementObjectToConnectId
                ) !== -1
              ) {
                return connections;
              } else {
                return update(connections, { $push: [newObj] });
              }
            } else {
              return [newObj];
            }
          },
        },
      },
      ...getDateModifyObj(
        getDocObj(state, docId),
        date,
        action.payload.type,
        valueKey,
        [
          {
            key: newObj.connectedToValueKey,
            updateParentDate: true,
          },
        ]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

const DELETE_FROM_STRING_ARR = (state, action) => {
  const { docId, valueKey, oldVal, date, defaultArr } = action.payload;

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) =>
        update(tmpValues || {}, {
          [valueKey]: (tmpItems) =>
            update(tmpItems || defaultArr || [], {
              $apply: (arr) => {
                const index = arr.findIndex((x) => x === oldVal);
                if (index !== -1) {
                  return update(arr, { $splice: [[index, 1]] });
                } else {
                  return arr;
                }
              },
            }),
        }),
      ...getDateModifyObj(
        state.unfinishedDocs[docId],
        date,
        action.payload.type,
        valueKey,
        [{ key: oldVal, deleted: true }]
      ),
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

export const MODIFY_VALUE = (state, action) => {
  const { docId, valueKey, value, date, valueKeys, increase, unsigned } =
    action.payload;
  action.payload.type = "Primitive";
  const _date = date ?? getDate();

  let timestampsUpdateObj = {
    [valueKey]: { $set: { date: _date } },
  };

  let stateUpdates;
  let updateObj = {
    [docId]: {
      values: (tmpValues) => {
        const _updateObj =
          typeof increase === "boolean"
            ? {
                $apply: (_) => {
                  const newValue = isNaN(parseInt(_))
                    ? increase
                      ? 1
                      : 0
                    : parseInt(_) + (increase ? 1 : -1);
                  if (unsigned && newValue < 0) return "0";
                  else return newValue.toString();
                },
              }
            : { $set: value };
        if (valueKeys) {
          return update(
            tmpValues || {},
            valueKeys.reduce((prev, cur) => {
              timestampsUpdateObj[cur] = {
                $set: { date: _date },
              };
              prev[cur] = _updateObj;
              return prev;
            }, {})
          );
        } else {
          return update(tmpValues || {}, {
            [valueKey]: _updateObj,
          });
        }
      },
      timestamps: (x) => update(x || {}, timestampsUpdateObj),
      date: { $set: _date },
    },
  };
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};
export const DOC_SAVE_MERGE = (state, action) => {
  const {
    docId,
    values = {},
    timestamps,
    currentValueKey,
    rootValues = {},
    date,
  } = action.payload;

  let keysToRemove = [];

  let updateObj = {
    [docId]: {
      currentValueKey: { $apply: (x) => currentValueKey ?? x },
      date: { $set: date ?? getDate() },
    },
  };

  if (timestamps) {
    const timestampKeys = Object.keys(timestamps);
    for (let i = 0; i < timestampKeys.length; i++) {
      const valueKey = timestampKeys[i];
      if (timestamps[valueKey].deleted) {
        keysToRemove.push(valueKey);
      }
    }

    updateObj[docId].timestamps = (x) =>
      update(x || {}, { $merge: timestamps });
  }

  if (values) {
    updateObj[docId].values = { $merge: values, $unset: keysToRemove };
  } else {
    updateObj[docId].values = { $unset: keysToRemove };
  }
  if (rootValues) {
    updateObj[docId].$merge = rootValues;
  }
  let stateUpdates;
  if (date) {
    stateUpdates = {
      lastDocRequests: (tmp) =>
        update(tmp || {}, {
          [docId]: (x) => update(x || {}, { saved: { $set: date } }),
        }),
    };
  }
  return updateDoc(state, docId, updateObj, stateUpdates);
};

const getHighestTitleNumber = (array, titleProp, prefix) => {
  const regex = new RegExp(`^${prefix}\\s+(\\d+)\\s*$`);
  return array.reduce((max, item) => {
    const match = item?.[titleProp]?.match(regex);
    const number = match ? parseInt(match[1], 10) : 0;
    return Math.max(max, number);
  }, 0);
};

// DOCSREDUCER
export default function docsReducer(state = INITIAL_STATE || null, action) {
  try {
    if (action.type === "CLEAR_STORE") {
      return INITIAL_STATE;
    } else if (action.type === "SIGN_OUT") {
      if (Platform.OS === "web") {
        return INITIAL_STATE;
      } else {
        return state;
      }
    } else if (
      action.type === "RECEIVE_USER_DATA" ||
      action.type === "RECEIVE_DOCS"
    ) {
      return receiveDocs(state, action);
    } else if (action.type === "REFRESH_DOCS") {
      const { data, docsArrName, completed = [] } = action.payload;

      let newData;
      if (docsArrName === "unfinishedDocs") {
        let newUnfinishedDocs = { ...state.unfinishedDocs };

        if (data && data.length > 0) {
          data.forEach((e) => {
            const id = e.id;

            if (Object.prototype.hasOwnProperty.call(newUnfinishedDocs, id)) {
              // var a = moment(newUnfinishedDocs[id].date);
              // var b = moment(e.date);
              // if (b.isAfter(a)) {
              //   newUnfinishedDocs[id] = e;
              // }
            } else {
              newUnfinishedDocs[id] = e;
            }
          });
        }

        completed.forEach((x) => {
          delete newUnfinishedDocs[x];
        });

        return update(state, { [docsArrName]: { $set: newUnfinishedDocs } });
      }

      return update(state, {
        [docsArrName]: {
          $set: newData,
        },
      });
    } else if (action.type === "REMOVE_DOCS") {
      if (isArrayWithItems(action.payload.docIds)) {
        return update(state, {
          unfinishedDocs: { $unset: action.payload.docIds },
        });
      } else {
        return state;
      }
    } else if (action.type === "RESET_TMP_DOC") {
      return update(state, { editor: { $set: { values: {} } } });
    } else if (action.type === "UPDATE_PATHS") {
      return update(state, {
        unfinishedDocs: (tmp) =>
          update(
            tmp || {},
            action.payload.reduce((prev, cur) => {
              if (tmp[cur.id]) {
                prev[cur.id] = {
                  path: { $set: cur.newPath },
                };
              }
              return prev;
            }, {})
          ),
      });
    } else if (action.type === "ADD_DOC") {
      const { id } = action.payload;

      if (state.unfinishedDocs?.[id]) return state;
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [id]: { $set: action.payload },
        }),
      };
    } else if (action.type === "CHANGE_LAYOUTS") {
      // add tmp docs if needed
      return update(state, {
        unfinishedDocs: action.payload.layouts.reduce((prev, x) => {
          const doc = {
            id: `${x.layoutId}_tmp`,
            values: {},
            layoutVersion: 1,
            type: x.type,
          };
          prev[doc.id] = {
            $apply: (tmp) => tmp ?? doc,
          };
          return prev;
        }, {}),
      });
    } else if (action.type === "ADD_TO_DOC_ROOT_ARR") {
      const { arrName, docId, prop, value, addMultiple } = action.payload;
      return update(state, {
        [arrName]: {
          [arrName === "unfinishedDocs"
            ? docId
            : state[arrName].findIndex((x) => x.id === docId)]: {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    if (
                      !arr.some((x) => valueMatches(action.payload, x, value))
                    ) {
                      return update(arr, {
                        $push: addMultiple ? value : [value],
                      });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          },
        },
      });
    } else if (action.type === "REMOVE_FROM_DOC_ROOT_ARR") {
      const { arrName, docId, prop, value } = action.payload;
      return update(state, {
        [arrName]: {
          [arrName === "unfinishedDocs"
            ? docId
            : state[arrName].findIndex((x) => x.id === docId)]: {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) =>
                      valueMatches(action.payload, x, value)
                    );

                    if (index !== -1) {
                      return update(arr, { $splice: [[index, 1]] });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          },
        },
      });
    } else if (action.type === "CHANGE_UNFINISHED_DOC_ID") {
      const { oldDocId, newDocId } = action.payload;

      if (!oldDocId || !newDocId) {
        return state;
      }

      const doc = state.unfinishedDocs[oldDocId];
      if (!doc) return state;

      const newState = update(state, {
        unfinishedDocs: {
          [newDocId]: { $set: update(doc, { id: { $set: newDocId } }) },
        },
      });
      return update(newState, {
        unfinishedDocs: { $unset: [oldDocId] },
      });
    } else if (action.type === "UPDATE_LAST_DOC_REQUESTS") {
      const { id, obj } = action.payload;

      return update(state, {
        lastDocRequests: (tmp) =>
          update(tmp || {}, {
            [id]: (x) => update(x || {}, { $merge: obj }),
          }),
      });
    } else if (action.type === "UPDATE_LAST_INTERNAL_DOC_SAVES") {
      const { id, date } = action.payload;

      return update(state, {
        lastInternalDocSaves: {
          [id]: { $set: date },
        },
      });
    } else if (action.type === "SET_SORT_OBJECTS") {
      const { docsArrName, sortObj } = action.payload;

      const sortObjProp = docsArrName + "SortObj";

      return update(state, {
        [sortObjProp]: {
          $set: sortObj,
        },
      });
    } else if (action.type === "SORT_DOCS") {
      const {
        docsArrName,
        sortArrIndex,
        options,
        keepReverse,
        onlySetNewSortObj,
        newSortObj,
        lang,
      } = action.payload;

      const searchArr = docsArrName;
      const sortObjProp = docsArrName + "SortObj";

      const _sortObj = state[sortObjProp];

      const comparable = _sortObj?.[sortArrIndex]?.comparable;
      const reversed = !_sortObj?.[sortArrIndex]?.reversed;

      return sortDocs({
        state,
        docsArrName,
        searchArr,
        sortObj: _sortObj,
        sortArrIndex,
        sortObjProp,
        comparable,
        reversed,
        options,
        lang,
        keepReverse,
        onlySetNewSortObj,
        newSortObj,
      });
    } else if (action.type === "SET_DOC_PROP") {
      return SET_DOC_PROP(state, action);
    } else if (action.type === "SET_MULTIPLE_DOCS_PROPS") {
      return SET_MULTIPLE_DOCS_PROPS(state, action);
    } else if (action.type === "REPLACE_SIGNATURES") {
      return REPLACE_SIGNATURES(state, action);
    } else if (action.type === "MODIFY_DOC_SIGNATURE") {
      return MODIFY_DOC_SIGNATURE(state, action);
    } else if (action.type === "ADD_DOC_SIGNATURE") {
      return ADD_DOC_SIGNATURE(state, action);
    } else if (action.type === "REMOVE_DOC_SIGNATURE") {
      return REMOVE_DOC_SIGNATURE(state, action);
    } else if (action.type === "MODIFY_CREATOR_SIGNATURE") {
      return MODIFY_CREATOR_SIGNATURE(state, action);
    } else if (action.type === "REMOVE_CREATOR_SIGNATURE") {
      return REMOVE_CREATOR_SIGNATURE(state, action);
    } else if (action.type === "PUSH_TO_DOC_EMAILS") {
      return PUSH_TO_DOC_EMAILS(state, action);
    } else if (action.type === "REMOVE_FROM_DOC_EMAILS") {
      return REMOVE_FROM_DOC_EMAILS(state, action);
    } else if (action.type === "MODIFY_VALUE_ITEM") {
      const {
        docId,
        valueKey,
        itemIsArr,
        value,
        oldVal,
        remove,
        replace,
        replaceArr,
        idProp,
        index,
        preset,
        setProp,
        sortProp,
      } = action.payload;

      if (!docId) throw "MODIFY_VALUE_ITEM" + "missing docId";
      const updateObj = {
        [docId]: {
          values: (tmpDataProp) =>
            update(tmpDataProp || {}, {
              [valueKey]: (tmpValueItem) =>
                update(tmpValueItem || null, {
                  $apply: function (x) {
                    return modifyValueItemWithPreviousValue(
                      x,
                      value,
                      oldVal,
                      itemIsArr,
                      remove,
                      replace,
                      replaceArr,
                      idProp,
                      index,
                      preset,
                      setProp,
                      sortProp
                    );
                  },
                }),
            }),
          ...getDateModifyObj(
            getDocObj(state, docId),
            undefined,
            action.payload.type,
            valueKey
          ),
        },
      };
      return updateDoc(state, docId, updateObj);
    } else if (action.type === "TRACK_PRODUCTIVITY") {
      const { docId, valueKey, date } = action.payload;

      let updateObj = {
        [docId]: {
          productivity: (tmp) =>
            update(tmp || [], {
              $apply: (x) => {
                if (
                  !x.some(
                    (y) => y.userId === action.userId && y.valueKey === valueKey
                  )
                ) {
                  return update(x, {
                    $push: [
                      { valueKey, userId: action.userId, date: getDate() },
                    ],
                  });
                } else {
                  return x;
                }
              },
            }),
          date: { $set: date ?? getDate() },
        },
      };
      return updateDoc(state, docId, updateObj);
    } else if (action.type === "MODIFY_OBJECT_ARR_ITEM") {
      return MODIFY_OBJECT_ARR_ITEM(state, action);
    } else if (action.type === "REPLACE_OBJECT_ARR_ITEM") {
      const {
        docId,
        valueKey,
        idProp,
        oldVal,
        value,
        sortProp,
        replaceSameObj,
        date,
      } = action.payload;

      let _arrItems = [{ key: value[idProp], updateParentDate: true }];

      if (!replaceSameObj && oldVal) {
        _arrItems.push({ key: oldVal[idProp], deleted: true });
      }
      let stateUpdates;
      let updateObj = {
        [docId]: {
          values: (tmpValues) =>
            update(tmpValues || {}, {
              [valueKey]: (tmpItems) =>
                update(tmpItems || [], {
                  $apply: (arr) => {
                    if (Array.isArray(arr)) {
                      if (oldVal) {
                        const index = arr.findIndex(
                          (x) => x[idProp] === oldVal[idProp]
                        );
                        if (index !== -1) {
                          return update(arr, {
                            [index]: { $set: value },
                          }).sort((a, b) =>
                            (a[sortProp] ?? "").localeCompare(
                              b[sortProp] ?? "",
                              "sw",
                              {
                                numeric: true,
                                sensitivity: "base",
                              }
                            )
                          );
                        } else {
                          return arr;
                        }
                      } else {
                        return update(arr, { $push: [value] });
                      }
                    } else {
                      return [value];
                    }
                  },
                }),
            }),
          ...getDateModifyObj(
            getDocObj(state, docId),
            date,
            action.payload.type,
            valueKey,
            _arrItems
          ),
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "ADD_TO_OBJECT_ARR") {
      return ADD_TO_OBJECT_ARR(state, action);
    } else if (action.type === "ADD_TO_OBJECT_ARR_WITH_GENERATED_ID") {
      if (Array.isArray(action.payload)) {
        let newState = state;
        action.payload.forEach((x) => {
          newState = ADD_TO_OBJECT_ARR_WITH_GENERATED_ID(newState, {
            payload: x,
          });
        });
        return newState;
      }
      return ADD_TO_OBJECT_ARR_WITH_GENERATED_ID(state, action);
    } else if (action.type === "ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID") {
      // use signalr modify obj arr
      const {
        docId,
        valueKey,
        value,
        idProp,
        sortProp,
        amount,
        currentValueKey,
        date,
        userId,
      } = action.payload;
      if (!docId)
        throw "ADD_MULTIPLE_TO_OBJECT_ARR_WITH_GENERATED_ID" + "missing docId";
      const intAmount = parseInt(amount);
      const docObj = getDocObj(state, docId);

      let curValKeyHasUserId = true;
      let _userId;
      let newValueKey;

      let timestampsUpdateArr = [];

      if (currentValueKey) {
        // "123u2b"
        if (
          typeof currentValueKey === "string" &&
          currentValueKey.includes("u")
        ) {
          const splitKey = currentValueKey.split("u");
          newValueKey = splitKey[0] - intAmount;
          _userId = `user/${splitKey[1].slice(0, -1)}`;
        } else {
          curValKeyHasUserId = false;
          newValueKey = currentValueKey - intAmount;
        }
      } else {
        newValueKey = (docObj.currentValueKey ?? 0) + 1;
        _userId = userId;
      }

      // const newValueKey = currentValueKey
      //   ? currentValueKey - intAmount
      //   : (docObj.currentValueKey ?? 0) + 1;

      let arrToAdd = [];

      for (let i = 0; i < intAmount; i++) {
        const _newValueKey = curValKeyHasUserId
          ? genNewValKey(newValueKey + i, _userId)
          : `${newValueKey + i}`;
        timestampsUpdateArr.push({ key: _newValueKey, updateParentDate: true });
        arrToAdd.push({
          [sortProp]: Array.isArray(value)
            ? value[i][sortProp]
            : `${value[sortProp]} ${i + 1}`,
          [idProp]: _newValueKey,
        });
      }

      let stateUpdates;
      const updateObj = {
        [docId]: {
          values: (tmpValues) =>
            update(tmpValues || {}, {
              [valueKey]: (tmpItems) =>
                update(tmpItems || [], {
                  $apply: (arr) => {
                    const newArr = update(arr, {
                      $push: arrToAdd,
                    });
                    return sortProp
                      ? newArr.sort((a, b) =>
                          (typeof a[sortProp] === "string"
                            ? a[sortProp]
                            : a[sortProp] || ""
                          ).localeCompare(
                            typeof b[sortProp] === "string"
                              ? b[sortProp]
                              : b[sortProp] || "",
                            "fi",
                            {
                              numeric: true,
                              sensitivity: "base",
                            }
                          )
                        )
                      : newArr;
                  },
                }),
            }),
          currentValueKey: {
            $set: newValueKey + intAmount,
          },
          ...getDateModifyObj(
            getDocObj(state, docId),
            date,
            action.payload.type,
            valueKey,
            timestampsUpdateArr
          ),
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "DELETE_FROM_STRING_ARR") {
      return DELETE_FROM_STRING_ARR(state, action);
    } else if (action.type === "DELETE_MULTIPLE_FROM_STRING_ARR") {
      let newState = state;
      action.payload.oldValues.forEach((x) => {
        newState = DELETE_FROM_STRING_ARR(newState, {
          ...action,
          payload: { ...action.payload, oldVal: x },
        });
      });
      return newState;
    } else if (action.type === "MODIFY_STRING_ARR") {
      const { docId, valueKey, oldVal, value, date } = action.payload;

      let stateUpdates;
      let updateObj = {
        [docId]: {
          values: (tmpValues) =>
            update(tmpValues || {}, {
              [valueKey]: (tmpItems) =>
                update(tmpItems || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) => x === oldVal);
                    if (index !== -1) {
                      return update(arr, {
                        [index]: { $set: value },
                      }).sort((a, b) =>
                        a.localeCompare(b, "sw", {
                          numeric: true,
                          sensitivity: "base",
                        })
                      );
                    } else {
                      return update(arr, { $push: [value] }).sort((a, b) =>
                        a.localeCompare(b, "sw", {
                          numeric: true,
                          sensitivity: "base",
                        })
                      );
                    }
                  },
                }),
            }),
          ...getDateModifyObj(
            getDocObj(state, docId),
            date,
            action.payload.type,
            valueKey,
            [{ key: oldVal, deleted: true }, { key: value }]
          ),
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "ADD_TO_STRING_ARR") {
      return ADD_TO_STRING_ARR(state, action);
    } else if (action.type === "MODIFY_VALUE") {
      if (Array.isArray(action.payload)) {
        let newState = state;
        action.payload.forEach((x) => {
          newState = MODIFY_VALUE(newState, { payload: x });
        });
        return newState;
      }
      return MODIFY_VALUE(state, action);
    } else if (action.type === "ADD_MODULAR_MEASUREMENT") {
      const { docId, valueKey, value, maxOrMin, replace, date } =
        action.payload;
      if (!docId) throw "ADD_MODULAR_MEASUREMENT" + "missing docId";
      const docObj = getDocObj(state, docId);
      if (docObj?.values?.[valueKey]?.values) {
        let stateUpdates;
        const updateObj = {
          [docId]: {
            values: {
              [valueKey]: {
                values: {
                  $apply: function (x) {
                    const floatVal = parseFloat(value);
                    if (replace) {
                      return [floatVal];
                    } else {
                      let arr = update(x, { $push: [floatVal] });
                      if (maxOrMin) {
                        return arr.sort((a, b) => b - a);
                      } else {
                        return arr.sort((a, b) => a - b);
                      }
                    }
                  },
                },
                worstValue: {
                  $apply: function () {
                    const floatVal = parseFloat(value);
                    if (replace) {
                      return floatVal;
                    } else {
                      const values = docObj?.values?.[valueKey]?.values ?? [];
                      if (maxOrMin) {
                        let max = Math.max(...values);
                        if (floatVal > max) return floatVal;
                        else return max;
                      } else {
                        let min = Math.min(...values);
                        if (floatVal < min) return floatVal;
                        else return min;
                      }
                    }
                  },
                },
              },
            },
            ...getDateModifyObj(
              getDocObj(state, docId),
              date,
              action.payload.type,
              valueKey
            ),
          },
        };
        if (date) {
          stateUpdates = {
            lastDocRequests: (tmp) =>
              update(tmp || {}, {
                [docId]: (x) => update(x || {}, { saved: { $set: date } }),
              }),
          };
        }
        return updateDoc(state, docId, updateObj, stateUpdates);
      } else {
        let stateUpdates;
        const updateObj = {
          [docId]: {
            values: {
              [valueKey]: {
                $set: {
                  worstValue: value,
                  values: [value],
                },
              },
            },
            ...getDateModifyObj(
              getDocObj(state, docId),
              date,
              action.payload.type,
              valueKey
            ),
          },
        };
        if (date) {
          stateUpdates = {
            lastDocRequests: (tmp) =>
              update(tmp || {}, {
                [docId]: (x) => update(x || {}, { saved: { $set: date } }),
              }),
          };
        }
        return updateDoc(state, docId, updateObj, stateUpdates);
      }
    } else if (action.type === "REMOVE_MODULAR_MEASUREMENT") {
      const { docId, valueKey, maxOrMin, oldVal, date } = action.payload;
      if (!docId) throw "REMOVE_MODULAR_MEASUREMENT" + "missing docId";
      const docObj = getDocObj(state, docId);
      let newArr;
      if (docObj?.values?.[valueKey]) {
        let stateUpdates;
        const updateObj = {
          [docId]: {
            values: {
              [valueKey]: {
                values: (tmpValues) =>
                  update(tmpValues ?? [], {
                    $apply: function (valuesArr = []) {
                      const index = valuesArr.findIndex((x) => x === oldVal);
                      if (index !== -1) {
                        newArr = update(valuesArr, { $splice: [[index, 1]] });
                      } else {
                        newArr = valuesArr;
                      }
                      return newArr;
                    },
                  }),
                worstValue: {
                  $apply: function () {
                    return newArr.length > 0
                      ? Math[maxOrMin ? "max" : "min"](...newArr)
                      : 0;
                  },
                },
              },
            },
            ...getDateModifyObj(
              getDocObj(state, docId),
              date,
              action.payload.type,
              valueKey
            ),
          },
        };
        if (date) {
          stateUpdates = {
            lastDocRequests: (tmp) =>
              update(tmp || {}, {
                [docId]: (x) => update(x || {}, { saved: { $set: date } }),
              }),
          };
        }
        return updateDoc(state, docId, updateObj, stateUpdates);
      } else return state;
    } else if (action.type === "ADD_MEASUREMENT_OBJ_CONNECTION") {
      return ADD_MEASUREMENT_OBJ_CONNECTION(state, action);
    } else if (action.type === "REMOVE_MEASUREMENT_OBJ_CONNECTION") {
      const {
        docId,
        measurementObjectId,
        measurementObjectValueKey,
        measurementObjectToConnectId,
        measurementObjectToConnectValueKey,
        date,
      } = action.payload;

      if (!docId) throw "REMOVE_MEASUREMENT_OBJ_CONNECTION" + "missing docId";

      const valueKey = measurementObjectId + "_connected";
      let stateUpdates;
      let updateObj = {
        [docId]: {
          values: {
            [valueKey]: (tmp) =>
              update(tmp || [], {
                $apply: (connections) => {
                  const index = connections.findIndex(
                    (x) =>
                      x.connectedToValueKey ===
                        measurementObjectToConnectValueKey &&
                      x.valueKey === measurementObjectValueKey &&
                      x.measurementObjectId === measurementObjectToConnectId
                  );
                  if (index !== -1) {
                    return update(connections, { $splice: [[index, 1]] });
                  } else {
                    return connections;
                  }
                },
              }),
          },
          ...getDateModifyObj(
            getDocObj(state, docId),
            date,
            action.payload.type,
            valueKey,
            [
              {
                key: measurementObjectToConnectValueKey,
                deleted: true,
              },
            ]
          ),
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (
      action.type === "ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID"
    ) {
      return ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID(state, action);
    } else if (
      action.type ===
      "ADD_INNER_ITEM_TO_MULTIPLE_OBJECT_ARRAYS_WITH_GENERATED_ID"
    ) {
      const { docId, valueKey, itemValueKeys } = action.payload;
      action.payload.type = "Modular";

      const _itemValueKeys =
        itemValueKeys ??
        getDocObj(state, docId).values[valueKey]?.map((x) => x.valueKey);
      let newState = state;
      _itemValueKeys.forEach((itemValueKey) => {
        newState = ADD_TO_OBJECT_ARR_INNER_ITEMS_WITH_GENERATED_ID(newState, {
          ...action,
          payload: {
            ...action.payload,
            itemValueKey,
          },
        });
      });
      return newState;
    } else if (action.type === "MODIFY_OBJECT_ARR_INNER_ITEM") {
      const {
        docId,
        valueKey,
        itemValueKey,
        innerItemValueKey,
        propToModify,
        value,
        date,
      } = action.payload;
      if (!docId) throw "MODIFY_OBJECT_ARR_INNER_ITEM" + "missing docId";
      const docObj = getDocObj(state, docId);
      const itemIndex = docObj?.values?.[valueKey]?.findIndex(
        (x) => x.valueKey == itemValueKey
      );
      if (itemIndex == -1) return state;
      const innerItemIndex = docObj?.values?.[valueKey]?.[
        itemIndex
      ]?.innerItems?.findIndex((x) => x.valueKey == innerItemValueKey);
      if (innerItemIndex == -1) return state;

      let stateUpdates;
      let updateObj = {
        [docId]: {
          values: (tmpValues) =>
            update(tmpValues || {}, {
              [valueKey]: (tmpItems) =>
                update(tmpItems || [], {
                  [itemIndex]: (tmpItem) =>
                    update(tmpItem || {}, {
                      innerItems: (tmpInnerItems) =>
                        update(tmpInnerItems || [], {
                          [innerItemIndex]: {
                            [propToModify]: {
                              $set: value,
                            },
                          },
                        }),
                    }),
                }),
            }),
          ...getDateModifyObj(
            getDocObj(state, docId),
            date,
            action.payload.type,
            valueKey,
            [
              {
                key: itemValueKey,
                arrayItems: [{ key: innerItemValueKey }],
              },
            ]
          ),
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "DOC_SAVE_MERGE") {
      return DOC_SAVE_MERGE(state, action);
    }

    // ! actions that delete valueKeys from doc
    else if (action.type === "REMOVE_MEAS_OBJECTS") {
      const {
        docId,
        connectedLayoutId,
        measurementObjectId,
        keysToRemove,
        date,
      } = action.payload;
      if (!docId) throw "REMOVE_MEAS_OBJECTS" + "missing docId";
      const _date = date ?? getDate();
      const docObj = getDocObj(state, docId);
      // object can be connected to others with its own measurement id
      const connectedToObjectsKey = `${measurementObjectId}_connected`;
      // object can also be connected in other measurement objects
      const connectedObjectsKey = `${connectedLayoutId}_connected`;

      const values = docObj?.values || {};
      const valueKeys = Object.keys(values);

      let valueKeysToRemove = [];
      let timestampsUpdateObj = {};
      let timestampsUpdateArr = [];
      let newMeasurementObjects = values[measurementObjectId];
      let newConnectedObjects = connectedLayoutId
        ? values[connectedObjectsKey]
        : null;
      let newConnectedToObjects = values[connectedToObjectsKey];

      let removedConnectedToKeys = [];
      let removedConnectedKeys = [];

      keysToRemove.forEach((keyToRemove) => {
        timestampsUpdateArr.push({ key: keyToRemove, deleted: true });
        valueKeys.filter((valueKey) => {
          if (valueKey.startsWith(`${measurementObjectId}_${keyToRemove}_`)) {
            valueKeysToRemove.push(valueKey);
            timestampsUpdateObj[valueKey] = {
              $set: { date: _date, deleted: true },
            };
          }
        });

        if (Array.isArray(newMeasurementObjects)) {
          newMeasurementObjects = newMeasurementObjects.filter(
            (y) => y.valueKey != keyToRemove
          );
        }

        if (connectedLayoutId && Array.isArray(newConnectedObjects)) {
          newConnectedObjects = newConnectedObjects.filter((y) => {
            if (
              y.measurementObjectId === measurementObjectId &&
              y.connectedToValueKey == keyToRemove
            ) {
              removedConnectedKeys.push({
                key: keyToRemove,
                deleted: true,
              });
              return false;
            } else return true;
          });
        }

        if (Array.isArray(newConnectedToObjects)) {
          newConnectedToObjects = newConnectedToObjects.filter((y) => {
            if (y.valueKey == keyToRemove) {
              removedConnectedToKeys.push({
                key: y.connectedToValueKey,
                deleted: true,
              });
              return false;
            } else return true;
          });
        }
      });

      if (removedConnectedKeys.length > 0) {
        timestampsUpdateObj[connectedObjectsKey] = {
          $apply: (_tmp) =>
            update(
              _tmp || {},
              getTimestampsUpdateObj(
                getDocObj(state, docId),
                _date,
                action.payload.type,
                removedConnectedKeys
              )
            ),
        };
      }

      if (removedConnectedToKeys.length > 0) {
        timestampsUpdateObj[connectedToObjectsKey] = {
          $apply: (_tmp) =>
            update(
              _tmp || {},
              getTimestampsUpdateObj(
                state.unfinishedDocs[docId],
                _date,
                action.payload.type,
                removedConnectedToKeys
              )
            ),
        };
      }

      timestampsUpdateObj[measurementObjectId] = {
        $apply: (_tmp) =>
          update(
            _tmp || {},
            getTimestampsUpdateObj(
              getDocObj(state, docId),
              _date,
              action.payload.type,
              timestampsUpdateArr
            )
          ),
      };

      let stateUpdates;
      let updateObj = {
        [docId]: {
          values: {
            [measurementObjectId]: { $set: newMeasurementObjects },
            $unset: valueKeysToRemove,
          },
          date: { $set: _date },
          timestamps: {
            $apply: (x) => update(x || {}, timestampsUpdateObj),
          },
          // ...getDateModifyObj(state.unfinishedDocs[docId],_date, action.payload.type, valueKey, 4),
          // timestamps: (x) => update(x || {}, timestampsUpdateObj),
          // date: { $set: _date },
        },
      };

      if (connectedLayoutId) {
        updateObj[docId].values[connectedObjectsKey] = {
          $set: newConnectedObjects,
        };
      }
      if (removedConnectedToKeys.length > 0) {
        updateObj[docId].values[connectedToObjectsKey] = {
          $set: newConnectedToObjects,
        };
      }
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "REMOVE_MODULAR_ITEMS") {
      const { docId, valueKey, keysToRemove, date } = action.payload;
      const _date = date ?? getDate();
      if (!docId) throw "REMOVE_MODULAR_ITEMS" + "missing docId";
      const docObj = getDocObj(state, docId);

      let timestampsUpdateObj = {};
      let timestampsUpdateArr = [];
      let valueKeysToRemove = [];
      const valueKeys = Object.keys(docObj.values);
      keysToRemove.forEach((keyToRemove) => {
        timestampsUpdateArr.push({ key: keyToRemove, deleted: true });
        valueKeys.forEach((x) => {
          if (x.startsWith(valueKey + "_" + keyToRemove + "_")) {
            valueKeysToRemove.push(x);
            timestampsUpdateObj[x] = {
              $set: { date: _date, deleted: true },
            };
          }
        });
      });

      timestampsUpdateObj[valueKey] = {
        $apply: (_tmp) =>
          update(
            _tmp || {},
            getTimestampsUpdateObj(
              getDocObj(state, docId),
              _date,
              action.payload.type,
              timestampsUpdateArr
            )
          ),
      };

      let stateUpdates;
      const updateObj = {
        [docId]: {
          values: (tmpValues) =>
            update(tmpValues || {}, {
              $unset: valueKeysToRemove,
              [valueKey]: (tmpItems) =>
                update(tmpItems || [], {
                  $apply: (x) =>
                    x.filter((item) => !keysToRemove.includes(item.valueKey)),
                }),
            }),
          date: { $set: _date },
          timestamps: {
            $apply: (x) => update(x || {}, timestampsUpdateObj),
          },
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "REMOVE_OBJECT_ARR_ITEM") {
      return REMOVE_OBJECT_ARR_ITEM(state, action);
    } else if (action.type === "REMOVE_OBJECT_ARR_INNER_ITEM") {
      return REMOVE_OBJECT_ARR_INNER_ITEM(state, action);
    } else if (
      action.type === "REMOVE_MULTIPLE_INNER_ITEMS_FROM_MULTIPLE_OBJECT_ARRAYS"
    ) {
      const { docId, valueKey, itemValueKeys, innerItemValueKeys, matchWith } =
        action.payload;

      const _itemValueKeys =
        itemValueKeys ??
        getDocObj(state, docId).values[valueKey]?.map((x) => x.valueKey);

      let newState = state;

      _itemValueKeys.forEach((itemValueKey) => {
        if (innerItemValueKeys) {
          innerItemValueKeys.forEach((innerItemValueKey) => {
            newState = REMOVE_OBJECT_ARR_INNER_ITEM(newState, {
              ...action,
              payload: {
                ...action.payload,
                itemValueKey,
                innerItemValueKey,
              },
            });
          });
        } else {
          matchWith.forEach((_matchWith) => {
            newState = REMOVE_OBJECT_ARR_INNER_ITEM(newState, {
              ...action,
              payload: {
                ...action.payload,
                itemValueKey,
                matchWith: _matchWith,
              },
            });
          });
        }
      });
      return newState;
    }
    // SIGNAL R ONLY
    else if (action.type === "SET_DOC_VALUES") {
      const { docId, valuesToSet, date } = action.payload;

      if (!docId) throw "SET_DOC_VALUES" + "missing docId";
      let stateUpdates;
      let updateObj = {
        [docId]: {
          date: { $set: date ?? getDate() },
        },
      };
      valuesToSet.forEach((x) => {
        updateObj[docId][x.prop] = { $set: x.value };
      });
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "REMOVE_USER_FROM_SHARED_TO") {
      const { docId, userId, date } = action.payload;

      if (!docId) throw "REMOVE_USER_FROM_SHARED_TO" + "missing docId";
      let stateUpdates;
      let updateObj = {
        [docId]: {
          sharedTo: (tmp) =>
            update(tmp || [], { $apply: (x) => x.filter((y) => y !== userId) }),
          date: { $set: date ?? getDate() },
        },
      };
      if (date) {
        stateUpdates = {
          lastDocRequests: (tmp) =>
            update(tmp || {}, {
              [docId]: (x) => update(x || {}, { saved: { $set: date } }),
            }),
        };
      }
      return updateDoc(state, docId, updateObj, stateUpdates);
    } else if (action.type === "MERGE_VALUES") {
      const {
        targetDocId,
        doc,
        mergeIndicator,
        omitValueKeys = [],
      } = action.payload;
      const values = doc.values || {};
      const timestamps = doc.timestamps || {};
      let updateObj = {
        [targetDocId]: {
          values: {
            $auto: { $merge: update(values, { $unset: omitValueKeys }) },
          },
          timestamps: {
            $auto: { $merge: update(timestamps, { $unset: omitValueKeys }) },
          },
        },
      };
      let newState = updateDoc(state, targetDocId, updateObj);

      if (mergeIndicator) {
        newState = MODIFY_VALUE(newState, {
          payload: {
            docId: targetDocId,
            valueKey: "valuesMerged",
            value: mergeIndicator,
            idProp: "valueKey",
          },
        });
      }
      return newState;
    }
    // ! WILL BE DEPRECATED
    // SYNC_VALUES_TIMESTAMPS_FN still used in create doc screen, maybe no need to deprecate
    else if (action.type === "SYNC_VALUES_TIMESTAMPS") {
      const { docId, userId, syncArrayItems } = action.payload;
      const docObj = getDocObj(state, docId);
      let syncObj = SYNC_VALUES_TIMESTAMPS_FN(userId, docObj, syncArrayItems);
      let updateObj = {
        [docId]: {
          creatorId: syncObj.creatorId,
          timestamps: (x) => update(x || {}, syncObj.timestamps),
        },
      };
      return updateDoc(state, docId, updateObj);
    }
    // !

    // internal docs actions
    else if (action.type === "ADD_INTERNAL_DOC") {
      const { id } = action.payload;
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [id]: { $set: action.payload },
        }),
      };
    }
    // ! DEPRECATED
    else if (action.type === "SET_FUSE_TYPE_OR_SIZE") {
      const { docId, groupId, value, setType } = action.payload;
      if (!docId) throw "SET_FUSE_TYPE_OR_SIZE" + "missing docId";
      const groupTypesObj = getDocObj(state, docId).groupsTypes;
      const groupTypeObj = groupTypesObj[groupId];
      let newObj;
      let fuseSize = "";

      // if changing from gg/gl type to another gg/gl type, then set fuseSize to current fuseSize
      // if changing from a not gg/gl type to gg/gl the size should be set to empty string
      // if changing from non gg/gl to non gg/gl then just keep the current fuseSize
      if (INITIAL_GGGLTYPES.includes(value)) {
        if (INITIAL_GGGLTYPES.includes(groupTypeObj.type))
          fuseSize = groupTypeObj.size;
      } else {
        fuseSize = groupTypeObj.size;
      }
      setType
        ? (newObj = { ...groupTypeObj, type: value, size: fuseSize })
        : (newObj = { ...groupTypeObj, size: value });
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            groupsTypes: { [groupId]: { $set: newObj } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_RCD_TYPE") {
      const { docId, rcdId, value } = action.payload;
      if (!docId) throw "SET_RCD_TYPE" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            rcdsTypes: { [rcdId]: { type: { $set: value } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_RCD_USE") {
      const { docId, rcdId, value } = action.payload;
      if (!docId) throw "SET_RCD_USE" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            rcdsTypes: { [rcdId]: { use: { $set: value } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_RCD_TESTED") {
      const { docId, rcdId, value } = action.payload;
      if (!docId) throw "SET_RCD_TESTED" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            rcdsTypes: { [rcdId]: { tested: { $set: value } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "ADD_RCD_PROTECTED_GROUP") {
      const { docId, rcdId, group } = action.payload;
      if (!docId) throw "ADD_RCD_PROTECTED_GROUP" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            groupsTypes: { [group]: { rcdKey: { $set: rcdId } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "REMOVE_RCD_PROTECTED_GROUP") {
      const { docId, group } = action.payload;
      if (!docId) throw "REMOVE_RCD_PROTECTED_GROUP" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            groupsTypes: { [group]: { rcdKey: { $set: "" } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_GROUP_GROUNDED") {
      const { docId, groupId, value } = action.payload;
      if (!docId) throw "SET_GROUP_GROUNDED" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            groupsTypes: { [groupId]: { grounded: { $set: value } } },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_DATA_VALUE_AND_VALUES") {
      const {
        docId,
        dataPropName,
        dataObjKey,
        measurementKey,
        value,
        maxOrMin,
        replace,
      } = action.payload;
      if (!docId) throw "SET_DATA_VALUE_AND_VALUES" + "missing docId";
      if (
        state.unfinishedDocs[docId][dataPropName][dataObjKey][measurementKey]
      ) {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: {
                [dataObjKey]: {
                  [measurementKey]: {
                    values: {
                      $apply: function (x) {
                        const floatVal = parseFloat(value);
                        if (measurementKey === "protection") {
                          return [floatVal];
                        } else {
                          if (replace) {
                            return [floatVal];
                          } else {
                            let arr = update(x, { $push: [floatVal] });
                            if (maxOrMin) {
                              return arr.sort((a, b) => b - a);
                            } else {
                              return arr.sort((a, b) => a - b);
                            }
                          }
                        }
                      },
                    },
                    value: {
                      $apply: function () {
                        const floatVal = parseFloat(value);
                        if (measurementKey === "protection") {
                          return floatVal;
                        } else {
                          const values =
                            state.unfinishedDocs[docId][dataPropName][
                              dataObjKey
                            ][measurementKey].values;
                          if (maxOrMin) {
                            let max = Math.max(...values);
                            if (floatVal > max) return floatVal;
                            else return max;
                          } else {
                            let min = Math.min(...values);
                            if (floatVal < min) return floatVal;
                            else return min;
                          }
                        }
                      },
                    },
                  },
                },
              },
              date: { $set: getDate() },
            },
          }),
        };
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: {
                [dataObjKey]: {
                  $merge: {
                    [measurementKey]: {
                      value: value,
                      values: [value],
                    },
                  },
                },
              },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "SET_DATA_VALUE") {
      const { docId, dataPropName, dataObjKey, measurementKey, value } =
        action.payload;
      if (!docId) throw "SET_DATA_VALUE" + "missing docId";
      if (
        state.unfinishedDocs[docId][dataPropName][dataObjKey][measurementKey]
      ) {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: {
                [dataObjKey]: { [measurementKey]: { value: { $set: value } } },
              },
              date: { $set: getDate() },
            },
          }),
        };
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: {
                [dataObjKey]: {
                  $merge: {
                    [measurementKey]: {
                      value: value,
                      values: [],
                    },
                  },
                },
              },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "REMOVE_MEASUREMENT") {
      const { docId, dataPropName, dataObjKey, measurementKey, index, value } =
        action.payload;
      if (!docId) throw "REMOVE_MEASUREMENT" + "missing docId";
      return {
        ...state,
        unfinishedDocs: update(state.unfinishedDocs, {
          [docId]: {
            [dataPropName]: {
              [dataObjKey]: {
                [measurementKey]: {
                  values: { $splice: [[index, 1]] },
                  value: {
                    $apply: function () {
                      const values =
                        state.unfinishedDocs[docId][dataPropName][dataObjKey][
                          measurementKey
                        ].values;
                      if (values[0] === value) {
                        return values[1] ? values[1] : 0;
                      } else {
                        return values[0];
                      }
                    },
                  },
                },
              },
            },
            date: { $set: getDate() },
          },
        }),
      };
    } else if (action.type === "SET_NEW_DATA_OBJ") {
      const { docId, dataPropName, newObjName, addArray } = action.payload;
      if (!docId) throw "SET_NEW_DATA_OBJ" + "missing docId";

      let newObj = {};
      let typesObj = {};

      if (dataPropName === "groups") {
        newObj = INITIAL_GROUP_OBJ;
        typesObj = INITIAL_GROUPSTYPES_OBJ;
      } else if (dataPropName === "rcds") {
        newObj = INITIAL_RCD_OBJ;
        typesObj = INITIAL_RCDSTYPES_OBJ;
      } else if (addArray) {
        newObj = [];
      }

      var keys = Object.keys(state.unfinishedDocs[docId][dataPropName] || {});
      keys.push(newObjName);

      const sortedKeys = keys.sort(naturalCompare);

      var tmpDataPropObj = {};

      sortedKeys.map((x) => {
        if (x === newObjName) tmpDataPropObj[x] = newObj;
        else tmpDataPropObj[x] = state.unfinishedDocs[docId][dataPropName][x];
      });

      if (dataPropName === "groups" || dataPropName === "rcds") {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: { $set: tmpDataPropObj },
              [dataPropName + "Types"]: { [newObjName]: { $set: typesObj } },
              date: { $set: getDate() },
            },
          }),
        };
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              [dataPropName]: { $set: tmpDataPropObj },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "SET_GROUPS_WITH_LINE_COUNT") {
      const { docId, keys, oldKey, fuseSize, fuseType, addRcdKey } =
        action.payload;
      if (!docId) throw "SET_GROUPS_WITH_LINE_COUNT" + "missing docId";
      //set 2-3 new groups to replace a group
      //also optionally set rcdKey to their groupsTypes

      //get keys so we can sort them
      let groupsKeys = Object.keys(state.unfinishedDocs[docId].groups);
      const newKeys = groupsKeys.concat(keys);

      //sort the new keys
      const sortedKeys = newKeys.sort(naturalCompare);

      //create a new object where keys are sorted and create a new groupsTypes object
      let tmpDataPropObj = {};
      let tmpGroupsTypesObj = {};

      sortedKeys.map((x) => {
        if (x !== oldKey) {
          if (state.unfinishedDocs[docId].groups[x]) {
            tmpDataPropObj[x] = { ...state.unfinishedDocs[docId].groups[x] };
            tmpGroupsTypesObj[x] = {
              ...state.unfinishedDocs[docId].groupsTypes[x],
            };
          } else {
            tmpDataPropObj[x] = { ...INITIAL_GROUP_OBJ };
            tmpGroupsTypesObj[x] = {
              ...INITIAL_GROUPSTYPES_OBJ,
              size: fuseSize || "",
              type: fuseType || "",
              rcdKey: addRcdKey ? oldKey : "",
            };
          }
        }
      });

      if (addRcdKey) {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              groups: { $set: tmpDataPropObj },
              groupsTypes: { $set: tmpGroupsTypesObj },
              rcds: { [oldKey]: { $set: INITIAL_RCD_OBJ } },
              rcdsTypes: { [oldKey]: { $set: INITIAL_RCDSTYPES_OBJ } },
              date: { $set: getDate() },
            },
          }),
        };
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              groups: { $set: tmpDataPropObj },
              groupsTypes: { $set: tmpGroupsTypesObj },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "CHANGE_DATA_OBJ_NAME") {
      const { docId, dataPropName, oldKey, key } = action.payload;
      if (!docId) throw "CHANGE_DATA_OBJ_NAME" + "missing docId";

      const _doc = state.unfinishedDocs[docId];
      //get keys so we can sort them
      let keys = Object.keys(_doc[dataPropName]);
      keys.push(key);

      //sort the new keys
      const sortedKeys = keys.sort(naturalCompare);

      if (dataPropName === "groups") {
        //create a new object where keys are sorted and create a new groupsTypes object
        let tmpDataPropObj = {};
        let tmpGroupsTypesObj = {};

        sortedKeys.map((x) => {
          if (x === key) {
            tmpDataPropObj[x] = {
              ...state.unfinishedDocs[docId][dataPropName][oldKey],
            };
            tmpGroupsTypesObj[x] = {
              ...state.unfinishedDocs[docId].groupsTypes[oldKey],
            };
          } else if (x !== oldKey) {
            tmpDataPropObj[x] = {
              ...state.unfinishedDocs[docId][dataPropName][x],
            };
            tmpGroupsTypesObj[x] = {
              ...state.unfinishedDocs[docId].groupsTypes[x],
            };
          }
        });

        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              groups: { $set: tmpDataPropObj },
              groupsTypes: { $set: tmpGroupsTypesObj },
              date: { $set: getDate() },
            },
          }),
        };
      } else if (dataPropName === "rcds") {
        //create a new object where keys are sorted and create a new rcdsTypes object
        let tmpDataPropObj = {};
        let tmpRcdsTypesObj = {};

        sortedKeys.map((x) => {
          if (x === key) {
            tmpDataPropObj[x] = {
              ...state.unfinishedDocs[docId][dataPropName][oldKey],
            };
            tmpRcdsTypesObj[x] = {
              ...state.unfinishedDocs[docId].rcdsTypes[oldKey],
            };
          } else if (x !== oldKey) {
            tmpDataPropObj[x] = {
              ...state.unfinishedDocs[docId][dataPropName][x],
            };
            tmpRcdsTypesObj[x] = {
              ...state.unfinishedDocs[docId].rcdsTypes[x],
            };
          }
        });

        //check if any groupsTypes obj has a rcdKey that needs to be changed
        const groupsTypes = state.unfinishedDocs[docId].groupsTypes;
        let tmpGroupsTypesObj = { ...state.unfinishedDocs[docId].groupsTypes };

        for (const groupKey of Object.keys(groupsTypes)) {
          if (groupsTypes[groupKey].rcdKey === oldKey) {
            tmpGroupsTypesObj[groupKey] = {
              ...groupsTypes[groupKey],
              rcdKey: key,
            };
          }
        }

        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              rcds: { $set: tmpDataPropObj },
              rcdsTypes: { $set: tmpRcdsTypesObj },
              groupsTypes: { $set: tmpGroupsTypesObj },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "REMOVE_DATA_OBJECTS") {
      const { docId, dataPropName, keysToRemove } = action.payload;
      if (!docId) throw "REMOVE_DATA_OBJECTS" + "missing docId";
      let tempDocs;
      if (dataPropName === "groups") {
        tempDocs = update(state.unfinishedDocs, {
          [docId]: {
            groups: { $unset: keysToRemove },
            groupsTypes: { $unset: keysToRemove },
            date: { $set: getDate() },
          },
        });
      } else if (dataPropName === "rcds") {
        const groupsTypes = state.unfinishedDocs[docId].groupsTypes;
        let newGroupsTypes = { ...groupsTypes };

        // if removing rcds, we need to check if there's groupsTypes objects with the to be removed rcdKeys
        keysToRemove.forEach((x) => {
          for (const key of Object.keys(groupsTypes)) {
            if (groupsTypes[key].rcdKey === x) {
              newGroupsTypes[key] = { ...groupsTypes[key], rcdKey: "" };
            }
          }
        });

        tempDocs = update(state.unfinishedDocs, {
          [docId]: {
            [dataPropName]: { $unset: keysToRemove },
            rcdsTypes: { $unset: keysToRemove },
            groupsTypes: { $set: newGroupsTypes },
            date: { $set: getDate() },
          },
        });
      } else {
        tempDocs = update(state.unfinishedDocs, {
          [docId]: {
            [dataPropName]: { $unset: keysToRemove },
            date: { $set: getDate() },
          },
        });
      }
      return {
        ...state,
        unfinishedDocs: tempDocs,
      };
    } else if (action.type === "MERGE_MEASUREMENT_DOC") {
      const { docId, measurementDocId } = action.payload;
      if (!docId) throw "MERGE_MEASUREMENT_DOC" + "missing docId";

      const emptyDocObj = {
        groups: {},
        groupsTypes: {},
        rcds: {},
        rcdsTypes: {},
      };
      const measurementDoc = state.measurementDocs?.filter(
        (x) => x.id === measurementDocId
      )[0];
      const unfinishedDoc = state.unfinishedDocs[docId].measurementDocs
        ? state.unfinishedDocs[docId].measurementDocs[
            measurementDoc.target.switchBoardId
          ] || emptyDocObj
        : emptyDocObj;

      let newGroups = { ...unfinishedDoc.groups };
      let newGroupsTypes = { ...unfinishedDoc.groupsTypes };
      let newRcds = { ...unfinishedDoc.rcds };
      let newRcdsTypes = { ...unfinishedDoc.rcdsTypes };

      getNewMergedObj(
        unfinishedDoc,
        measurementDoc,
        "groups",
        newGroups,
        newGroupsTypes,
        getNewGroupObj,
        getMergedGroupTypesObj
      );
      getNewMergedObj(
        unfinishedDoc,
        measurementDoc,
        "rcds",
        newRcds,
        newRcdsTypes,
        getNewRcdObj,
        getMergedRcdTypesObj
      );

      return update(state, {
        unfinishedDocs: (unfinishedDocs) =>
          update(unfinishedDocs || {}, {
            [docId]: {
              measurementDocs: (tmpMeasurementDocs) =>
                update(tmpMeasurementDocs || {}, {
                  [measurementDoc.target.switchBoardId]: (tmpSwitchBoardId) =>
                    update(tmpSwitchBoardId || {}, {
                      groupsTypes: { $set: newGroupsTypes },
                      groups: { $set: newGroups },
                      rcdsTypes: { $set: newRcdsTypes },
                      rcds: { $set: newRcds },
                      measuringDevices: (measuringDevices) =>
                        update(measuringDevices || [], {
                          $push: measurementDoc.target.measuringDevices || [],
                        }),
                    }),
                }),
              date: { $set: getDate() },
            },
          }),
      });
    } else if (action.type === "UNMERGE_MEASUREMENT_DOC") {
      const { docId, measurementDocId } = action.payload;
      if (!docId) throw "UNMERGE_MEASUREMENT_DOC" + "missing docId";

      const emptyDocObj = {
        groups: {},
        groupsTypes: {},
        rcds: {},
        rcdsTypes: {},
      };
      const measurementDoc = state.measurementDocs?.filter(
        (x) => x.id === measurementDocId
      )[0];
      const unfinishedDoc = state.unfinishedDocs[docId].measurementDocs
        ? state.unfinishedDocs[docId].measurementDocs[
            measurementDoc.target.switchBoardId
          ] || emptyDocObj
        : emptyDocObj;

      let newGroups = { ...unfinishedDoc.groups };
      let newGroupsTypes = { ...unfinishedDoc.groupsTypes };
      let newRcds = { ...unfinishedDoc.rcds };
      let newRcdsTypes = { ...unfinishedDoc.rcdsTypes };

      let newState;

      getNewUnmergedObj(
        unfinishedDoc,
        measurementDoc,
        "groups",
        newGroups,
        newGroupsTypes,
        getNewGroupObj
      );
      getNewUnmergedObj(
        unfinishedDoc,
        measurementDoc,
        "rcds",
        newRcds,
        newRcdsTypes,
        getNewRcdObj
      );

      if (
        Object.entries(newGroups).length === 0 &&
        newGroups.constructor === Object &&
        Object.entries(newGroupsTypes).length === 0 &&
        newGroupsTypes.constructor === Object &&
        Object.entries(newRcds).length === 0 &&
        newRcds.constructor === Object &&
        Object.entries(newRcdsTypes).length === 0 &&
        newRcdsTypes.constructor === Object
      ) {
        newState = update(state, {
          unfinishedDocs: (unfinishedDocs) =>
            update(unfinishedDocs || {}, {
              [docId]: {
                measurementDocs: {
                  $unset: [measurementDoc.target.switchBoardId],
                },
                date: { $set: getDate() },
              },
            }),
        });
      } else {
        newState = update(state, {
          unfinishedDocs: (unfinishedDocs) =>
            update(unfinishedDocs || {}, {
              [docId]: {
                measurementDocs: (tmpMeasurementDocs) =>
                  update(tmpMeasurementDocs || {}, {
                    [measurementDoc.target.switchBoardId]: (tmpSwitchBoardId) =>
                      update(tmpSwitchBoardId || {}, {
                        groupsTypes: { $set: newGroupsTypes },
                        groups: { $set: newGroups },
                        rcdsTypes: { $set: newRcdsTypes },
                        rcds: { $set: newRcds },
                        measuringDevices: (measuringDevices) =>
                          update(measuringDevices || [], {
                            $splice: [
                              [
                                measuringDevices.indexOf(
                                  measurementDoc.target.measuringDevices
                                ),
                                1,
                              ],
                            ],
                          }),
                      }),
                  }),
                date: { $set: getDate() },
              },
            }),
        });
      }

      return newState || state;
    } else if (action.type === "REMOVE_PROP_ARR_ITEM") {
      const { prop, docId, value } = action.payload;
      if (!docId) throw "REMOVE_PROP_ARR_ITEM" + "missing docId";
      const index = state.unfinishedDocs[docId].target[prop].indexOf(value);

      if (index === -1) {
        return state;
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              target: { [prop]: { $splice: [[index, 1]] } },
              date: { $set: getDate() },
            },
          }),
        };
      }
    } else if (action.type === "SET_TARGET_PROP") {
      const { docId, prop, value, replaceArrIndex } = action.payload;
      if (!docId) throw "SET_TARGET_PROP" + "missing docId";

      if (targetPropArrs.includes(prop)) {
        if (state.unfinishedDocs[docId].target[prop]) {
          if (replaceArrIndex !== undefined && replaceArrIndex !== -1) {
            return {
              ...state,
              unfinishedDocs: update(state.unfinishedDocs, {
                [docId]: {
                  target: {
                    [prop]: { $splice: [[replaceArrIndex, 1, value]] },
                  },
                  date: { $set: getDate() },
                },
              }),
            };
          } else {
            return {
              ...state,
              unfinishedDocs: update(state.unfinishedDocs, {
                [docId]: {
                  target: { [prop]: { $push: [value] } },
                  date: { $set: getDate() },
                },
              }),
            };
          }
        } else {
          return {
            ...state,
            unfinishedDocs: update(state.unfinishedDocs, {
              [docId]: {
                target: { $merge: { [prop]: [value] } },
                date: { $set: getDate() },
              },
            }),
          };
        }
      } else {
        return {
          ...state,
          unfinishedDocs: update(state.unfinishedDocs, {
            [docId]: {
              target: { [prop]: { $set: value } },
              date: { $set: getDate() },
            },
          }),
        };
      }
    }

    return state;
  } catch (error) {
    const errorStr = action.payload
      ? JSON.stringify(
          action.payload?.options
            ? Object.keys(action.payload).reduce((obj, key) => {
                if (key !== "options") obj[key] = action.payload[key];
                return obj;
              }, {})
            : action.payload
        )
      : "no payload";
    if (!__DEV__) {
      errorReport({
        error,
        errorInFn: action?.type,
        errorInScreen: "DocsReducer",
        errorParams: {
          type: action?.type,
          payload: errorStr,
        },
      });
    } else {
      console.warn(error, errorStr);
    }
    return state;
  }
}

import "react";
import Icon from "./Icon";
import {
  TouchableOpacity,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { Tooltip } from "../components/Tooltip";

export default function RowText(props) {
  const { text, title, ellipsizeMode, numberOfLines, hint, hintTable, lang } =
    props;
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View
          pointerEvents={props.disabled ? "none" : "auto"}
          style={{
            width: "100%",
            alignItems: "flex-start",
            opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
            backgroundColor: colors.primary,
          }}
        >
          {title || props.error ? (
            <View
              style={{
                width: "100%",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flex: 1, alignSelf: "stretch" }}>
                <Text
                  style={[
                    theme.boldText,
                    { color: props.error ? "red" : colors.text },
                  ]}
                >
                  {title + (props.error ? ` - ${props.error}` : "")}
                </Text>
              </View>
              {hint || hintTable ? (
                <Tooltip
                  flex={0}
                  icon
                  tip={hint}
                  hintTable={hintTable}
                  lang={lang}
                />
              ) : null}
            </View>
          ) : null}

          <View
            style={[
              theme.rightRowContainer,
              {
                // backgroundColor: colors.secondary,
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
              },
            ]}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                numberOfLines={numberOfLines}
                ellipsizeMode={ellipsizeMode || "tail"}
                style={[theme.textInput, theme.text]}
              >
                {text}
              </Text>
              {props.unit ? (
                <TouchableOpacity
                  onPress={props.onUnitPress}
                  disabled={!props.onUnitPress}
                >
                  <Text style={theme.boldText}>{` ${props.unit} `}</Text>
                </TouchableOpacity>
              ) : null}
              {props.endIcon ? (
                props.loadingIcon ? (
                  <View
                    style={{
                      height: 30,
                      width: 30,
                      align: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ActivityIndicator
                      color={props.endIconColor ?? colors.text}
                      size={Platform.OS === "web" ? "small" : "large"}
                      focusable={false}
                      tabIndex="-1"
                    />
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={props.onEndIconPress}
                    disabled={!props.onEndIconPress}
                    focusable={false}
                    tabIndex="-1"
                  >
                    <Icon
                      name={props.endIcon}
                      size={30}
                      color={props.endIconColor ?? colors.text}
                    />
                  </TouchableOpacity>
                )
              ) : null}
            </View>
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

import "react";
import { Platform, Text, FlatList, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import Icon from "./Icon";

export default function ValueList(
  props = {
    dataObjKey: String,
    secondaryDataObjKey: String,
    dualRow: Boolean,
    removable: Boolean,
    data: [],
    data2: [],
    unit: String,
    unit2: String,
    measurementKey: String,
    measurementKey2: String,
    onRemove: Function,
    title: Boolean,
  }
) {
  const { t } = useTranslation();
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={{ flex: props.noFlex ? 0 : 1 }}>
          <View
            style={[
              theme.rowContainer,
              {
                flex: props.noFlex ? 0 : 1,
                justifyContent: "flex-start",
                alignItems: "center",
              },
            ]}
          >
            <FlatList
              listKey={props.dataObjKey + "valueList"}
              horizontal={true}
              initialNumToRender={15}
              keyboardShouldPersistTaps="handled"
              data={props.data}
              keyExtractor={(item, index) =>
                "allValues" + props.dataObjKey + index.toString()
              }
              ListEmptyComponent={() => (
                <View style={theme.valueListContainer}>
                  <Text style={theme.text}>{t("emptyValueListInfo")}</Text>
                </View>
              )}
              renderItem={({ item, index }) => (
                <View style={theme.valueListContainer}>
                  <Text style={theme.text}>{item}</Text>
                  <Text style={theme.text}> {props.unit}</Text>
                  <TouchableOpacity
                    disabled={!!props.disabled}
                    onPress={() =>
                      props.onRemove(
                        index,
                        item,
                        props.dataObjKey,
                        props.measurementKey,
                        props.dataPropName
                      )
                    }
                    focusable={false}
                  >
                    <Icon
                      style={{
                        paddingLeft: 8,
                        paddingRight: 0,
                      }}
                      name="close"
                      size={Platform.OS !== "web" ? 32 : 24}
                      color={colors.accent}
                    />
                  </TouchableOpacity>
                </View>
              )}
            />
          </View>

          {props.dualRow ? (
            <View
              style={[
                theme.rowContainer,
                {
                  borderTopWidth: 1,
                  borderTopColor: colors.text,
                  flex: props.noFlex ? 0 : 1,
                  justifyContent: "center",
                  alignItems: "flex-start",
                },
              ]}
            >
              <FlatList
                listKey={props.dataObjKey + "valueList2"}
                initialNumToRender={15}
                horizontal={true}
                keyboardShouldPersistTaps="handled"
                data={props.data2}
                keyExtractor={(item, index) =>
                  "allValues2" + props.secondaryDataObjKey + index.toString()
                }
                ListEmptyComponent={() => (
                  <View style={theme.valueListContainer}>
                    <Text style={theme.text}>{t("emptyValueListInfo")}</Text>
                  </View>
                )}
                renderItem={({ item, index }) => (
                  <View style={theme.valueListContainer}>
                    <Text style={theme.text}>{item}</Text>
                    <Text style={theme.text}> {props.unit2}</Text>
                    <TouchableOpacity
                      disabled={!!props.disabled}
                      onPress={() =>
                        props.onRemove(
                          index,
                          item,
                          props.secondaryDataObjKey,
                          props.measurementKey2,
                          props.dataPropName
                        )
                      }
                      focusable={false}
                    >
                      <Icon
                        style={{
                          paddingLeft: 8,
                        }}
                        name="close"
                        size={Platform.OS !== "web" ? 32 : 24}
                        color={colors.accent}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              />
            </View>
          ) : null}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}

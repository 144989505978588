import axios from "axios";
import { Platform } from "react-native";
import queryString from "query-string";
import { osVersion } from "../lib/versionNum";
import { api } from "../lib/constants";
import {
  dirs,
  fileDownload,
  unlinkFile,
  fileExists,
} from "../lib/fileOperations";
import { getAppVersion } from "./helperFns";

// var MockAdapter = require("axios-mock-adapter");
// var mock = new MockAdapter(axios);

axios.defaults.baseURL = api;
axios.defaults.https = true;
axios.defaults.timeout = 15000;
axios.defaults.withCredentials = true;

//var MockAdapter = require("axios-mock-adapter");
//var mock = new MockAdapter(axios, { delayResponse: 1000 });

//mock.onPost("/attachments/add").reply(200);

export const apiRequest = (url, method = "post", body, token) =>
  axios({
    method: method,
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: body,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 406) return "licenceErr";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 444) return "versionErr";
          else return error.response;
        } else return error.response;
      }
    });

export const apiRequestWithoutVersionNum = (body, url, token) =>
  axios({
    method: "post",
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: body,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 406) return "licenceErr";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 444) return "versionErr";
          else return "failure";
        } else return "failure";
      }
    });

export const apiRequestWithoutToken = (body, url) =>
  axios({
    method: "post",
    url: url,
    headers: {
      "content-type": "application/json",
    },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 419) return error.response;
          else if (error.response.status === 444) return "versionErr";
          else return "failure";
        } else return "failure";
      }
    });

export const getWithToken = (url, token, params, timeout) => {
  return axios({
    method: "get",
    url: url + (params ? "?" + queryString.stringify(params) : ""),
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    timeout,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 410) return "gone";
          else if (error.response.status === 469) return "roleErr";
          else if (error.response.status === 419) return error.response;
          else {
            return "failure";
          }
        } else {
          return "failure";
        }
      }
    });
};

export const putWithToken = (body, url, token) => {
  return axios({
    method: "put",
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 469) return "roleErr";
          else if (error.response.status === 419) return error.response;
          else return "failure";
        } else return "failure";
      }
    });
};
export const deleteWithToken = (body, url, token) =>
  axios({
    method: "delete",
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 469) return "roleErr";
          else if (error.response.status === 419) return error.response;
          else return "failure";
        } else return "failure";
      }
    });

export const patchWithToken = (body, url, token) =>
  axios({
    method: "patch",
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 469) return "roleErr";
          else if (error.response.status === 419) return error.response;
          else return "failure";
        } else return "failure";
      }
    });

export const apiRequestWithToken = (body, url, token) => {
  return axios({
    method: "post",
    url: url,
    headers: token
      ? {
          "content-type": "application/json",
          Credentials: "include",
          Authorization: "Bearer " + token,
        }
      : {
          "content-type": "application/json",
        },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else if (error.message === "Network Error") {
        return "networkErr";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        if (error.response) {
          if (error.response.status === 401) return "tokenErr";
          else if (error.response.status === 405) return "expired";
          else if (error.response.status === 403) return "forbidden";
          //else if (error.response.status === 409) return "deviceErr";
          else if (error.response.status === 406) return "licenceErr";
          else if (error.response.status === 409) return error.response;
          else if (error.response.status === 410) return "gone";
          else if (error.response.status === 469) return "roleErr";
          else if (error.response.status === 419) return error.response;
          else if (error.response.status === 423) return error.response;
          else return "failure";
        } else return "failure";
      }
    });
};

export const sendEmailConfirmation = (body, url) =>
  axios({
    method: "post",
    url: url,
    headers: {
      "content-type": "application/json",
    },
    data: { ...body, version: getAppVersion(), os: Platform.OS, osVersion },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        return "timeout";
      } else {
        //showToast("BACKENDERROR: url:" + url + " err: " + error, 10000, "red", -300);
        return "failure";
      }
    });

/**
 * Download file from server if file doesn't exist in DocumentDir
 *
 * @param {string} id attachment id.
 * @param {string} fileName fileName.
 * @param {function} setFileLoaded function to use for setting the request status
 * @param {function} body body to send with request
 * @param {function} url request url to be added in front of axios baseURL
 * @param {function} errorFn function to run in promise catch
 * @param {string} deepLinkToken deeplink token to use
 */
export const downloadFileWithToken = ({
  id,
  fileName,
  setFileLoaded,
  body,
  url,
  errorFn,
  deepLinkToken,
  setUploadProgress,
  method = "post",
  forceDownload,
}) => {
  const uri = `${dirs.DocumentDir}/${fileName}`;

  return fileExists(uri)
    .then((exist) => {
      if (!forceDownload && exist) {
        if (setFileLoaded) {
          setFileLoaded({ key: id, value: true });
        }
        return null; // return null so we don't download again
      } else {
        if (deepLinkToken) return deepLinkToken;
        else return;
      }
    })
    .then((deepLinkToken) => {
      if (deepLinkToken !== null) {
        return fileDownload(
          uri,
          url,
          deepLinkToken,
          method,
          body,
          setUploadProgress
        )
          .then((res) => {
            if (Platform.OS === "web") {
              if (res && res !== "error" && res !== "forbidden") {
                if (res === "no content") {
                  setFileLoaded?.({ key: id, value: "no content" });
                  return "file fetched";
                } else {
                  setFileLoaded?.({ key: id, value: true });
                  return "file fetched";
                }
              } else {
                errorFn(res);
              }
            } else if (
              res.respInfo.status === 422 ||
              res.respInfo.status === 204
            ) {
              setFileLoaded?.({ key: id, value: "no content" });
              return "file fetched";
            } else if (res.respInfo.status !== 200) {
              if (errorFn) errorFn(res);
              return unlinkFile(uri);
            } else {
              setFileLoaded?.({ key: id, value: true });
              return "file fetched";
            }
          })
          .catch((err) => {
            if (errorFn) errorFn(err);
            return unlinkFile(uri);
          });
      }
    })
    .catch((err) => {
      if (errorFn) errorFn(err);
      return "cached";
    });
};

import "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import { useHistory } from "react-router-dom";
import SettingsScreen from "../../../common/screens/SettingsScreen";

function SettingsScreenWrapper() {
  let history = useHistory();
  const { width, height } = useWindowDimensions();

  return (
    <div className="mainContainer">
      <SettingsScreen navigation={history} pageW={width} pageH={height} />
    </div>
  );
}

export default SettingsScreenWrapper;

import "react";
import { TouchableOpacity, Text, View } from "react-native";
import IconButton from "./IconButton";

export default function MultiPickerItem({
  prop,
  item,
  text,
  onRemove,
  removeValue,
  onItemPress,
  theme,
  colors,
  disabled,
}) {
  return (
    <View style={theme.rowContainer}>
      {!disabled && (onRemove || removeValue) ? (
        <IconButton
          color={colors.text}
          backgroundColor={colors.lightBg}
          icon="trash-can-outline"
          onPress={() => {
            if (onRemove) {
              onRemove(text, item);
            } else {
              removeValue(prop, text);
            }
          }}
        />
      ) : null}

      <TouchableOpacity
        style={{ flex: 1, minHeight: 36 }}
        onPress={() => onItemPress?.(text, item)}
      >
        <View
          style={[
            {
              flexGrow: 1,
              padding: 8,
            },
          ]}
        >
          <Text style={[theme.boldText /*props.titleStyle*/]}>{text}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

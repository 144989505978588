import "react";
import { ScrollView, View, Text, TouchableOpacity } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts } from "../lib/helperFns";
import Modal from "./Modal";
import Icon from "./Icon";
import StretchButton from "./StretchButton";

const styles = {
  scrollWrapper: { minHeight: 36, maxHeight: 200 },
  titleWrapper: {
    minHeight: 36,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
};
export default function ModalWithTitle(props) {
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={props.onClose}
          onBackdropPress={props.onClose}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
          animationIn={props.animationIn}
          animationOut={props.animationOut}
          style={{
            height: "100%",
          }}
        >
          <View style={theme.modalListContainer}>
            <ScrollView style={styles.scrollWrapper}>
              <View style={styles.titleWrapper}>
                <Text
                  style={[
                    theme.title,
                    {
                      textAlign: "center",
                      textAlignVertical: "center",
                      fontSize: 20,
                    },
                  ]}
                >
                  {props.title}
                </Text>
                <TouchableOpacity
                  onPress={props.onClose}
                  style={[
                    {
                      height: 36,
                      width: 36,
                      paddingTop: 0,
                      paddingBottom: 0,
                      backgroundColor: colors.accent,
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 0,
                    },
                  ]}
                >
                  <Icon name="close" size={24} color={colors.textOnAccent} />
                </TouchableOpacity>
              </View>
            </ScrollView>

            <View
              style={[
                theme.line,
                {
                  borderBottomColor: colors.borderPrimary,
                },
              ]}
            />

            {props.children}

            <View
              style={[theme.line, { borderBottomColor: colors.borderPrimary }]}
            />

            <View style={theme.modalButtonContainer}>
              {props.onLeftButtonPress ? (
                <StretchButton
                  color={props.leftButtonColor}
                  backgroundColor={props.leftButtonBgColor}
                  title={props.leftButtonTitle}
                  onPress={props.onLeftButtonPress}
                />
              ) : null}
              {props.onMiddleButtonPress ? (
                <StretchButton
                  color={props.middleButtonColor}
                  backgroundColor={props.middleButtonBgColor}
                  title={props.middleButtonTitle}
                  onPress={props.onMiddleButtonPress}
                />
              ) : null}
              <StretchButton
                color={props.rightButtonColor}
                backgroundColor={props.rightButtonBgColor}
                title={props.rightButtonTitle}
                onPress={props.onRightButtonPress}
              />
            </View>
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
}

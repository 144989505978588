import "react";
import { View, Image } from "react-native";
import { img } from "../theming/theme-context";

export default function DoceanLogo(props) {
  let { theme, logo } = props;
  return (
    <View style={theme.centeredContainer}>
      <Image
        source={logo}
        resizeMode={"contain"}
        style={[img, { height: 200 }]}
      />
    </View>
  );
}

import Route from "route-parser";

const routes = [
  {
    routeName: "signIn",
    screen: "SignIn",
    route: new Route("/docean.fi/signIn?:email"),
  },
  {
    routeName: "signIn",
    screen: "SignIn",
    route: new Route("/redirect/signIn?:email"),
  },
  {
    routeName: "passReset",
    screen: "SignIn",
    route: new Route("/docean.fi/passReset?:email?:token"),
  },
  {
    routeName: "passReset",
    screen: "SignIn",
    route: new Route("/dev.docean.fi/passReset?:email?:token"),
  },
  {
    routeName: "passReset",
    screen: "SignIn",
    route: new Route("/redirect/passReset?:email?:token"),
  },
  {
    routeName: "activate",
    screen: "SignIn",
    route: new Route("/docean.fi/activate?:email?:token"),
  },
  {
    routeName: "activate",
    screen: "SignIn",
    route: new Route("/redirect/activate?:email?:token"),
  },
  {
    routeName: "activate",
    screen: "SignIn",
    route: new Route("/dev.docean.fi/activate?:email?:token"),
  },
  {
    routeName: "trialRegister",
    screen: "SignIn",
    route: new Route("/docean.fi/trialRegister?:email?:token"),
  },
  {
    routeName: "trialRegister",
    screen: "SignIn",
    route: new Route("/dev.docean.fi/trialRegister?:email?:token"),
  },
  {
    routeName: "trialRegister",
    screen: "SignIn",
    route: new Route("/redirect/trialRegister?:email?:token"),
  },
  {
    routeName: "deviceChange",
    screen: "SignIn",
    route: new Route("/docean.fi/deviceChange?:email?:token"),
  },
  {
    routeName: "deviceChange",
    screen: "SignIn",
    route: new Route("/redirect/deviceChange?:email?:token"),
  },
  // complete doc auths
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/dev.docean.fi/authentication?code=:code&state=:token?:reason?:platform?:docId"
    ),
  },
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?code=:code&state=:token?:reason?:platform?:docId"
    ),
  },
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/redirect/authentication?code=:code&state=:token?:reason?:platform?:docId"
    ),
  },
  // !
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/dev.docean.fi/authentication?code=:code&state=:token?:reason?:platform"
    ),
  },
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?code=:code&state=:token?:reason?:platform"
    ),
  },
  // !
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/dev.docean.fi/authentication?code=:code&state=:token?:reason"
    ),
  },
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?code=:code&state=:token?:reason"
    ),
  },
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route(
      "/redirect/authentication?code=:code&state=:token?:reason"
    ),
  },
  // users doc sign auth error
  {
    routeName: "authErr",
    screen: "SignIn",
    route: new Route(
      "/dev.docean.fi/authentication?state=:token?:reason?:platform?:docId&error=:error&error_description=:errorDesc"
    ),
  },
  {
    routeName: "authErr",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?state=:token?:reason?:platform?:docId&error=:error&error_description=:errorDesc"
    ),
  },
  // doc sign error
  {
    routeName: "authErr",
    screen: "SignIn",
    route: new Route(
      "/dev.docean.fi/authentication?state=:token?:reason&error=:error&error_description=:errorDesc"
    ),
  },
  {
    routeName: "authErr",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?state=:token?:reason&error=:error&error_description=:errorDesc"
    ),
  },

  // old sign in
  {
    routeName: "authentication",
    screen: "SignIn",
    route: new Route("/redirect/authentication?code=:code&state=:state"),
  },
  //   {
  //     routeName: 'authentication',
  //     screen: 'SignIn',
  //     route: new Route('/redirect/authentication?code=:code&state='),
  //   },
  //"authentication?state=G6Ig?signIn&error=cancel&error_description=user+cancel/"",
  {
    routeName: "ignore",
    screen: "SignIn",
    route: new Route(
      "/docean.fi/authentication?state=:token?:reason&error=:error&error_description=:errordesc"
    ),
  },
  {
    routeName: "ignore",
    screen: "SignIn",
    route: new Route(
      "/redirect/authentication?state=:token?:reason&error=:error&error_description=:errordesc"
    ),
  },
  {
    routeName: "signDoc",
    screen: "PreviewAndSign",
    route: new Route("/redirect/signDoc?:token"),
  },
  {
    routeName: "signDoc",
    screen: "PreviewAndSign",
    route: new Route("/docean.fi/signDoc?:token"),
  },
  {
    routeName: "signDoc",
    screen: "PreviewAndSign",
    route: new Route("/dev.docean.fi/signDoc?:token"),
  },
  {
    routeName: "document",
    screen: "LinkDocForm",
    route: new Route("/docean.fi/document/*id?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "document",
    screen: "LinkDocForm",
    route: new Route("/dev.docean.fi/document/*id?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "document",
    screen: "LinkDocForm",
    route: new Route("/redirect/document/*id?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "folders",
    screen: "folders",
    route: new Route("/docean.fi/folders/*path?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "folders",
    screen: "folders",
    route: new Route("/dev.docean.fi/folders/*path?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "folders",
    screen: "folders",
    route: new Route("/redirect/folders/*path?token=:token"),
    allowAnonymous: true,
    fetchPermissions: true,
  },
  {
    routeName: "ecom",
    screen: "create",
    route: new Route("/docean.fi/ecom?:params"),
  },
  {
    routeName: "fondion",
    screen: "create",
    route: new Route("/docean.fi/fondion?:params"),
  },
  {
    routeName: "ecom",
    screen: "create",
    route: new Route("/dev.docean.fi/ecom?:params"),
  },
  {
    routeName: "fondion",
    screen: "create",
    route: new Route("/dev.docean.fi/fondion?:params"),
  },
  {
    routeName: "downloadDoc",
    screen: "downloadDoc",
    route: new Route("/docean.fi/downloadDoc?:token?:fileName"),
  },
  {
    routeName: "downloadDoc",
    screen: "downloadDoc",
    route: new Route("/dev.docean.fi/downloadDoc?:token?:fileName"),
  },
  {
    routeName: "downloadDoc",
    screen: "downloadDoc",
    route: new Route("/redirect/downloadDoc?:token?:fileName"),
  },
  // link from ecom without auth
  /*
    {
        routeName: "ecom",
        screen: "create",
        route: new Route('/docean.fi/ecom?cName=:cName?cId=:cId?')
    },
    */
];

export default routes;

import { useEffect, forwardRef, useState } from "react";
import moment from "moment";
import { getTranslatedText, isArrayWithItems } from "../lib/functions";
import {
  VictoryLine,
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryZoomContainer,
} from "victory";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";

const dateAxisStyle = { tickLabels: { angle: -15 } };

const dateTickFormat = (tick) => {
  return moment.unix(tick).format("D.M.YYYY");
};

function getEntireDomain(data) {
  if (isArrayWithItems(data)) {
    return {
      y: [
        Math.floor(minBy(data, (d) => d.y).y),
        Math.ceil(maxBy(data, (d) => d.y).y),
      ],
      x: [minBy(data, (d) => d.x).x, maxBy(data, (d) => d.x).x],
    };
  } else return { x: [0, 0], y: [0, 0] };
}

export default forwardRef((props, ref) => {
  const data = props.data || [];
  const haveData = isArrayWithItems(data);
  const entireDomain = getEntireDomain(data);
  const [state, setState] = useState({
    zoomedXDomain: entireDomain.x,
  });

  const strokeWidth = props.strokeWidth || 3;
  const baseLabelStyles = {
    padding: 10, //{ top: 110, right: 110, bottom: 110, left: 110 },
    // fontWeight: 600,
    // fontSize: 10,
    fill: "#000",
  };

  const line = {
    axis: {
      style: {
        grid: { stroke: "#ddd" },

        axisLabel: {
          ...baseLabelStyles,
          // padding: 25,
        },
        tickLabels: baseLabelStyles,
      },
    },

    line: {
      style: {
        data: {
          stroke: props.strokeColor || "#000",
          strokeWidth: strokeWidth,
        },
        labels: {
          ...baseLabelStyles,
        },
      },
      //...baseProps,
    },

    scatter: {
      style: {
        data: {
          fill: props.strokeColor || "#000",
        },
        labels: {
          ...baseLabelStyles,
        },
      },
      //...baseProps,
    },
  };

  function onDomainChange(domain) {
    setState({
      zoomedXDomain: domain.x,
    });
  }

  useEffect(() => {
    if (haveData) {
      onDomainChange(entireDomain);
    }
  }, [props.data]);

  function getData() {
    const { zoomedXDomain } = state;
    const { maxPoints } = props;
    const filtered = data.filter(
      (d) => d.x >= zoomedXDomain[0] && d.x <= zoomedXDomain[1]
    );

    if (filtered.length > maxPoints) {
      const k = Math.ceil(filtered.length / maxPoints);
      return filtered.filter((d, i) => i % k === 0);
    }
    return filtered;
  }

  // function getZoomFactor() {
  //   const { zoomedXDomain } = state;
  //   const factor = 10 / (zoomedXDomain[1] - zoomedXDomain[0]);
  //   return _.round(factor, factor < 3 ? 1 : 0);
  // }

  const renderedData = getData();
  return (
    <VictoryChart
      ref={ref}
      domain={props.domain || entireDomain}
      theme={line}
      width={props.width ?? 350}
      height={props.height ?? 350}
      containerComponent={
        <VictoryZoomContainer
          allowPan={haveData && props.allowPan}
          allowZoom={haveData && props.allowZoom}
          zoomDimension="x"
          onZoomDomainChange={onDomainChange}
          minimumZoom={{ x: 1 / 10000 }}
        />
      }
    >
      <VictoryAxis
        dependentAxis={false}
        offsetY={50}
        offsetX={50}
        crossAxis={false}
        tickValues={props.tickValuesY}
        label={getTranslatedText(props.labelY, props.lang)}
        tickFormat={props.tickFormatX === "date" ? dateTickFormat : null}
        fixLabelOverlap={props.fixLabelOverlap}
        style={props.tickFormatX === "date" ? dateAxisStyle : undefined}
      />
      <VictoryLine
        // style={{
        //   axis: { stroke: "#fff" },
        //   axisLabel: { fill: "#fff", fontSize: 20, padding: 30 },
        //   labels: { fill: "#fff", fontSize: 20, padding: 30 },
        //   data: { stroke: props.colors.accent },
        //   parent: { border: "1px solid #ccc", borderColor: props.colors.accent },
        // }}
        data={renderedData}
      />
      {props.type === "lineScatter" ? (
        <VictoryScatter data={renderedData} size={strokeWidth + 2} />
      ) : null}
      <VictoryAxis
        crossAxis={false}
        dependentAxis={true}
        orientation={props.orientationX || "right"}
        label={getTranslatedText(props.labelX, props.lang)}
        offsetY={50}
        offsetX={50}
        tickFormat={props.tickFormatY === "date" ? dateTickFormat : null}
        tickValues={props.tickValuesX}
        fixLabelOverlap={props.fixLabelOverlap}
        style={props.tickFormatY === "date" ? dateAxisStyle : undefined}
      />
    </VictoryChart>
  );
});

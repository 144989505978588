import React, { useState } from "react";
import { Platform, Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import TextInput from "./TextInput";

const styles = {
  warningWrapper: {
    width: "100%",
  },
};
const InputWithWarnings = React.forwardRef((props, ref) => {
  const [focused, setFocused] = useState(false);

  return (
    <ThemeContext.Consumer>
      {({ theme, colors, orientation }) => (
        <View style={props.style}>
          <View
            style={[
              props.inputContainerStyle ||
                (props.rowInput
                  ? theme.rowTextInputContainer
                  : theme.textInputContainer),
              {
                borderColor: focused
                  ? colors.accent
                  : props.err
                  ? colors.error
                  : colors.borderPrimary,
              },
            ]}
          >
            <Text style={theme.boldText}>{props.label}</Text>
            <View style={theme.row}>
              <TextInput
                orientation={orientation}
                disableFullscreenUI={false}
                autoFocus={Platform.OS === "web" && props.autoFocus}
                testID={props.testID}
                accessibilityLabel={props.testID}
                ref={ref}
                onFocus={() => {
                  props.onChangeText(props.value || "");
                  setFocused(true);
                }}
                onBlur={() => {
                  setFocused(false);
                  if (props.onBlur) props.onBlur();
                }}
                placeholder={props.placeholder ?? ""}
                style={[
                  { width: "100%" },
                  props.inputStyle ?? theme.modalTextInput,
                ]}
                placeholderTextColor={
                  props.placeholderTextColor ?? colors.borderLight
                }
                returnKeyType={props.returnKeyType || "next"}
                textContentType={props.textContentType || "none"}
                autoCompleteType={props.autoCompleteType}
                blurOnSubmit={props.multiline ? false : props.blurOnSubmit}
                multiline={props.multiline}
                onChangeText={(text) => {
                  let textToUse = "";
                  if (props.numeric) {
                    textToUse = text
                      .replace(/[^0-9.,-]/g, "")
                      .replace(/,/g, ".");
                  } else {
                    textToUse = text.replace(/\r?\n|\r/g, "");
                  }
                  props.onChangeText(textToUse);
                }}
                onSubmitEditing={
                  props.multiline || !props.onSubmitEditing
                    ? null
                    : props.onSubmitEditing
                }
                value={props.value || ""}
                autoCapitalize={props.autoCapitalize || "sentences"}
                selectTextOnFocus={props.selectTextOnFocus}
                editable={props.editable ?? true}
                maxLength={props.maxLength}
                secureTextEntry={props.secureTextEntry}
                autoComplete={
                  props.passwordInput ? "password" : props.autoComplete
                }
                keyboardType={
                  props.passwordInput
                    ? "default"
                    : props.numeric
                    ? "decimal-pad"
                    : props.keyboardType ?? "default"
                }
              />
              {props.icon}
            </View>
          </View>
          <View style={styles.warningWrapper}>
            <Text
              style={[
                theme.text,
                {
                  color: colors.error,
                },
              ]}
            >
              {props.err || " "}
            </Text>
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
});

export default InputWithWarnings;

// import "../../common/lib/wdyr.js";
import "react";
import { createRoot } from "react-dom/client";
import "./index.css";
// import * as serviceWorker from "./serviceWorker";
import App from "./App";
import * as Sentry from "@sentry/react";
import iconFont from "react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { isDevBuild } from "../../common/lib/constants";

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`;

Sentry.init({
  dsn: "https://c0344d3308dc51e6a7f5addd72ebbf50@o1418878.ingest.us.sentry.io/4509031323598848",
  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the BrowserTracing integration
  // * add Sentry.addTracingExtensions() above your Sentry.init() call
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    // Sentry.reactRouterV5BrowserTracingIntegration({
    //   history,
    // }),
  ],

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 0.0, // Disable performance tracing
  replaysSessionSampleRate: 0.0, // Disable session replay (if applicable)

  // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', "https://yourserver.io/api/"],
  enabled: !isDevBuild,
});

// create stylesheet for vector icons
const style = document.createElement("style");
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App tab="home" />);

document.head.appendChild(style);

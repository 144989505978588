import "react";
import { ScrollView, Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import { modalPickerOpts, fullHp } from "../lib/helperFns";
import Modal from "./Modal";
import DualButtons from "./DualButtons";
import { useTranslation } from "react-i18next";

export default (props) => {
  const { t } = useTranslation();

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <Modal
          {...modalPickerOpts}
          deviceHeight={fullHp(100)}
          backdropOpacity={1}
          isVisible={props.isVisible}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={theme.flex}>
            <View style={props.wrapperStyle || [theme.fullPaddingContainer]}>
              <ScrollView>
                <Text style={theme.text}>
                  Tässä Document Ocean Oy:n (tästä eteenpäin Document Ocean
                  Oy:sta saatetaan myös käyttää lyhennystä “Docean Oy” tai sanaa
                  “me”) tietosuojatiedotteessa kuvataan rekisterinpitäjänä
                  toimivan Docean Oy:n henkilötietojen käsittelytoimia
                  (jäljempänä ”GDPR-Tiedote”).{"\n"}
                  {"\n"}
                  GDPR-Tiedote pitää sisällään rekisterinpitäjänä toimivan
                  Docean Oy:n selosteen käsittelytoimista, ja se myös informoi
                  Docean Oy:n rekisteröityjä (jäljempänä meidän rekisteröityjä
                  voidaan kutsua myös sanalla ”sinä”) siitä, kuinka Docean Oy
                  käsittelee heidän henkilötietojaan.{"\n"}
                  {"\n"}
                  Täten Docean Oy:n GDPR-Tiedote kattaa mm. EU:n yleisen
                  tietosuoja asetuksen (679/2016) artikloissa 13, 14 ja 30
                  vaaditut tiedot. Docean Oy takaa, että Tiedote on aina
                  julkisesti, läpinäkyvästi ja helposti saatavilla Docean Oy:n
                  internetsivuilla.{"\n"}
                </Text>

                <Text style={theme.title}>1. REKISTERINPITÄJÄ{"\n"}</Text>
                <Text style={theme.text}>
                  Nimi: Document Ocean Oy{"\n"}
                  Y-tunnus: 3086017-5{"\n"}
                  Postiosoite: Puolikkotie 8, 02230 Espoo{"\n"}
                </Text>

                <Text style={theme.title}>
                  2. REKISTERIASIOISTA VASTAAVA HENKILÖ{"\n"}
                </Text>
                <Text style={theme.text}>
                  Nimi: Joose Seppälä{"\n"}
                  Yhteystiedot:{"\n"}
                  +358 50 362 4466{"\n"}
                  joose.seppala@docean.fi{"\n"}
                </Text>

                <Text style={theme.title}>3. REKISTERÖITYJEN RYHMÄT{"\n"}</Text>
                <Text style={theme.text}>
                  Docean Oy:n GDPR-Tiedote rekisterinpitäjänä koskee seuraavia
                  rekisteröityjen ryhmiä:{"\n"}
                  {"\n"}
                  1. henkilöt, jotka toimivat asiakasyritystemme
                  yhteyshenkilöinä;
                  {"\n"}
                  {"\n"}
                  2. henkilöt, jotka ovat asiakasyritystemme työntekijöitä;
                  {"\n"}
                  {"\n"}
                  3. henkilöt, jotka ovat asiakasyritystemme asiakkaita;{"\n"}
                </Text>

                <Text style={theme.title}>4. HENKILÖTIEDOT{"\n"}</Text>
                <Text style={theme.text}>
                  Rekisteröityjen ryhmät 1-2 voi sisältää seuraavia
                  henkilötietoja
                  {"\n"}
                  {"\n"}• yhteystiedot, kuten nimi, osoite, puhelinnumerot,
                  sähköpostiosoitteet ja henkilötunnukset;{"\n"}
                  {"\n"}• mahdolliset rekisteröitymistiedot, kuten
                  käyttäjätunnus, nimimerkki, salasana ja muu mahdollinen
                  yksilöivä tunnus{"\n"}
                  {"\n"}• mahdolliset muut suostumuksella kerätyt tiedot.{"\n"}
                  {"\n"}
                  Rekisteröityjen ryhmä 3 voi sisältää seuraavia henkilötietoja
                  {"\n"}
                  {"\n"}• yhteystiedot, kuten nimi, osoite, puhelinnumerot,
                  sähköpostiosoitteet;{"\n"}
                  {"\n"}• mahdolliset muut suostumuksella kerätyt tiedot.{"\n"}
                </Text>

                <Text style={theme.title}>
                  5. HENKILÖTIETOJEN KÄSITTELYN TARKOITUS{"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisteröityjen ryhmien 1 ja 2 henkilötietoja voidaan
                  käsitellä seuraavia tarkoituksia varten:{"\n"}
                  {"\n"}• asiakassuhteiden hallinnointi ja kehittäminen;{"\n"}
                  {"\n"}• asiakaspalvelu;{"\n"}
                  {"\n"}• käyttäjäkokemuksen parantaminen;{"\n"}
                  {"\n"}• lainsäädännöllisten vaatimusten noudattaminen; ja
                  {"\n"}
                  {"\n"}• analysointi ja tilastointi.{"\n"}
                  {"\n"}• käyttäjän henkilöllisyyden varmistaminen{"\n"}
                  {"\n"}• markkinointi{"\n"}
                  {"\n"}
                  Rekisteröityjen ryhmän 3 henkilötietoja voidaan käsitellä
                  seuraavia tarkoituksia varten:{"\n"}
                  {"\n"}• osoitetietojen varmistaminen{"\n"}
                  {"\n"}• henkilötietojen varmistaminen{"\n"}
                </Text>

                <Text style={theme.title}>
                  6. KÄSITTELYN OIKEUSPERUSTE{"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisterinpitäjällä on oikeus käsitellä rekisteröityjen
                  henkilötietoja, joko:{"\n"}
                  {"\n"}• rekisteröidyltä saadun suostumuksen perusteella;{"\n"}
                  {"\n"}• sen vuoksi, että käsittely on tarpeen sopimuksen
                  täytäntöön panemiseksi, jossa rekisteröity on osapuolena, tai
                  sopimuksen tekemistä edeltävien toimenpiteiden toteuttamiseksi
                  rekisteröidyn pyynnöstä; tai{"\n"}
                  {"\n"}• käsittely on tarpeen rekisterinpitäjän lakisääteisen
                  velvoitteen noudattamiseksi.{"\n"}
                </Text>

                <Text style={theme.title}>
                  7. SÄÄNNÖNMUKAISET TIETOLÄHTEET{"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisteröityä koskevat tiedot saadaan säännönmukaisesti:{"\n"}
                  {"\n"}• rekisteröidyltä itseltään palvelumme kautta,
                  puhelimitse, kasvotusten, internetin kautta, sähköpostilla tai
                  muulla vastaavalla tavalla;{"\n"}
                  {"\n"}• evästeiden tai muiden vastaavien tekniikoiden avulla;
                  {"\n"}
                  {"\n"}• Docean Oy:n muilta suomalaisilta
                  kumppanuusyrityksiltä; tai{"\n"}
                  {"\n"}• VRK/Väestötietojärjestelmästä, Postin
                  osoitetietojärjestelmästä, puhelinyhtiöiden
                  yhteystietorekistereistä ja muista vastaavista yksityisistä ja
                  julkisista rekistereistä.{"\n"}
                </Text>

                <Text style={theme.title}>
                  8. HENKILÖTIETOJEN SÄILYTYSAIKA{"\n"}
                </Text>
                <Text style={theme.text}>
                  8.1 Säilytämme rekisteröityjen ryhmän 1 henkilötietoja viiden
                  (5) vuoden ajan asiakassuhteen päättymisen jälkeen.{"\n"}
                  {"\n"}
                  8.2 Säilytämme rekisteröityjen ryhmän 2 henkilötietoja yhden
                  (1) vuoden ajan asiakassuhteen päättymisen jälkeen.{"\n"}
                  {"\n"}
                  8.3 Säilytämme tietoja kohdassa 3 mainituista koskevia
                  henkilötietoja kymmenen (10) vuoden ajan, sillä meillä on
                  lakisääteinen velvollisuus säilyttää
                  käyttöönottomittaus-pöytäkirjoja tuon ajanjakson ajan; tai
                  säilytämme henkilötietoja yhden(1) kuukauden
                  asiakassuhteen(asiakasyrityksemme kanssa) päättymisen jälkeen.
                  {"\n"}
                  {"\n"}
                  8.4 Voimme kuitenkin säilyttää rekisteröityjen ryhmien 3.1) –
                  3.3) henkilötietoja pidempään pidempään kuin yllä on kuvattu,
                  jos se on tarpeen rikostutkinnan, mahdollisen oikeudenkäynnin
                  tai muiden vastaavien syiden vuoksi.{"\n"}
                  {"\n"}
                  8.5 Rekisterinpitäjä tarkastaa joka kuudes (6) kuukausi
                  rekistereissä olevien tietojen tarpeenmukaisuuden.{"\n"}
                </Text>

                <Text style={theme.title}>
                  9. HENKILÖTIETOJEN VASTAANOTTAJIEN RYHMÄT{"\n"}
                </Text>
                <Text style={theme.text}>
                  Henkilötietojen vastaanottajiin voivat kuulua seuraavat
                  ryhmät:
                  {"\n"}
                  {"\n"}• Docean Oy:n kumppanuus- ja asiakasyritykset:{"\n"}
                  {"\n"}• pilvipalveluita tarjoavat tahot;{"\n"}
                  {"\n"}• kirjanpito-, tilin- ja tietosuojatarkastuspalveluita
                  tarjoavat tahot;{"\n"}
                  {"\n"}• tahot, jotka avustavat Docean Oy:tä sen lakisääteisten
                  velvollisuuksien toteuttamisessa{"\n"}
                  {"\n"}• Docean Oy:n asiakasyritykset.{"\n"}
                </Text>

                <Text style={theme.title}>
                  10. HENKILÖTIETOJEN SIIRTO EU:N TAI EUROOPAN TALOUSALUEEN
                  ULKOPUOLELLE{"\n"}
                </Text>
                <Text style={theme.text}>
                  Henkilötietoja ei siirretä kolmansiin maihin.{"\n"}
                </Text>

                <Text style={theme.title}>
                  11. REKISTERÖIDYN OIKEUDET{"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisteröidyllä on oikeus käyttää kaikkia alla lueteltuja
                  oikeuksia. Oikeuksia koskevat yhteydenotot tulee tehdä
                  kirjallisina kohdassa 2. mainitulle rekisteriasioista
                  vastaavalle henkilölle. Rekisteröidyn oikeudet voidaan
                  toteuttaa vasta, kun rekisteröidyn henkilöllisyys on
                  varmennettu asianmukaisella tavalla.{"\n"}
                </Text>

                <Text style={theme.boldText}>Tarkastusoikeus{"\n"}</Text>
                <Text style={theme.text}>
                  Rekisteröidyllä on oikeus tiedon etsimiseksi tarpeelliset
                  seikat ilmoitettuaan saada tietää, mitä häntä koskevia tietoja
                  tähän rekisteriin on talletettu tai, ettei rekisterissä ole
                  häntä koskevia tietoja. Rekisterinpitäjän tulee samalla
                  ilmoittaa rekisteröidylle rekisterin säännönmukaiset
                  tietolähteet sekä mihin rekisterin tietoja käytetään ja
                  säännönmukaisesti luovutetaan.
                  {"\n"}
                </Text>

                <Text style={theme.boldText}>
                  Oikaisu- ja poisto-oikeus{"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisteröidyllä on oikeus vaatia rekisterinpitäjää oikaisemaan
                  rekisteröityä koskevat epätarkat, virheelliset ja
                  puutteelliset henkilötiedot.{"\n"}
                  {"\n"}
                  Rekisteröity voi vaatia rekisterinpitäjää poistamaan
                  rekisteröityä koskevat henkilötiedot, jos:{"\n"}
                  {"\n"}• rekisteröityjä tietoja ei enää tarvita niihin
                  tarkoituksiin, joita varten ne kerättiin;{"\n"}
                  {"\n"}• rekisteröity peruuttaa suostumuksensa, eikä
                  käsittelyyn enää ole muuta laillista perustetta;{"\n"}
                  {"\n"}• rekisteröityä koskevia henkilötietoja on käsitelty
                  lainvastaisesti; tai{"\n"}
                  {"\n"}• henkilötiedot on poistettava unionin oikeuteen tai
                  jäsenvaltion lainsäädäntöön perustuvan rekisterinpitäjään
                  sovellettavan lakisääteisen velvoitteen noudattamiseksi.{"\n"}
                  {"\n"}
                  Huomattakoon kuitenkin, että rekisterinpitäjän
                  liiketoiminnasta johtuen rekisteröityjen oikeus poistaa tai
                  oikaista tietoja on rajoitettu koskemaan ainoastaan sellaisia
                  tietoja, joita rekisterinpitäjän ei tule sen
                  lainsäädännöllisten velvoitteiden perusteella säilyttää.
                  Jollei rekisterinpitäjä hyväksy rekisteröidyn vaatimusta
                  tiedon oikaisusta tai poistamisesta, hänen on annettava
                  rekisteröidylle asiasta kirjallinen todistus. Todistuksessa on
                  mainittava myös ne syyt, joiden vuoksi vaatimusta ei ole
                  hyväksytty. Rekisteröity voi saattaa asian
                  tietosuojavaltuutetun käsiteltäväksi. Rekisterinpitäjän on
                  ilmoitettava tiedon oikaisusta tai poistamisesta sille, jolle
                  rekisterinpitäjä on luovuttanut tai jolta rekisterinpitäjä on
                  saanut virheellisen henkilötiedon.{"\n"}
                  {"\n"}
                  Ilmoitusvelvollisuutta ei kuitenkaan ole, jos ilmoittaminen on
                  mahdotonta tai vaatii kohtuutonta vaivaa.{"\n"}
                </Text>

                <Text style={theme.boldText}>Rajoittamisoikeus{"\n"}</Text>
                <Text style={theme.text}>
                  Rekisteröity voi vaatia rekisterinpitäjää rajoittamaan häntä
                  koskevien henkilötietojen käsittelyä, jos:{"\n"}
                  {"\n"}• henkilötiedot eivät ole paikkansapitäviä, jolloin
                  käsittelyä rajoitetaan ajaksi, jonka kuluessa rekisterinpitäjä
                  voi varmistaa niiden paikkansapitävyyden;{"\n"}
                  {"\n"}• henkilötietojen käsittely on lainvastaista ja
                  rekisteröity vastustaa henkilötietojen poistamista ja vaatii
                  sen sijaan niiden käytön rajoittamista;{"\n"}
                  {"\n"}• rekisterinpitäjä ei enää tarvitse kyseisiä
                  henkilötietoja käsittelyn tarkoituksiin mutta rekisteröity
                  tarvitsee niitä oikeudellisen vaateen laatimiseksi,
                  esittämiseksi tai puolustamiseksi; tai{"\n"}
                  {"\n"}• rekisteröity on vastustanut henkilötietojen käsittelyä
                  EU:n yleisen tietosuoja- asetuksen 21 artiklan 1 kohdan
                  nojalla odotettaessa sen todentamista, syrjäyttävätkö
                  rekisterinpitäjän oikeutetut perusteet rekisteröidyn
                  perusteet. Jos rekisterinpitäjä on rajoittanut käsittelyä
                  mainituilla perusteilla, rekisterinpitäjän on tehtävä
                  rekisteröidylle ilmoitus, ennen kuin käsittelyä koskeva
                  rajoitus poistetaan.
                  {"\n"}
                </Text>

                <Text style={theme.boldText}>Vastustamisoikeus{"\n"}</Text>
                <Text style={theme.text}>
                  Jos henkilötietoja käsitellään suoramarkkinointia varten,
                  rekisteröidyllä on oikeus, milloin tahansa vastustaa häntä
                  koskevien henkilötietojen käsittelyä tällaista markkinointia
                  varten, mukaan lukien profilointia silloin kun se liittyy
                  tällaiseen suoramarkkinointiin.{"\n"}
                </Text>

                <Text style={theme.boldText}>Siirto-oikeus{"\n"}</Text>
                <Text style={theme.text}>
                  Rekisteröidyllä on oikeus saada häntä koskevat henkilötiedot,
                  jotka hän on toimittanut rekisterinpitäjälle strukturoidussa
                  muodossa mutta Docean Oy ei ole velvollinen siirtämään tietoja
                  toiselle palveluntarjoajalle.{"\n"}
                </Text>

                <Text style={theme.boldText}>
                  Automatisoidut yksittäispäätökset, profilointi mukaan luettuna
                  {"\n"}
                </Text>
                <Text style={theme.text}>
                  Rekisteröidyllä on oikeus olla joutumatta sellaisen päätöksen
                  kohteeksi, joka perustuu pelkästään automaattiseen
                  käsittelyyn, kuten profilointiin, ja jolla on häntä koskevia
                  oikeusvaikutuksia tai joka vaikuttaa häneen vastaavalla
                  tavalla merkittävästi. Rekisteröidyllä ei kuitenkaan ole yllä
                  kuvattua oikeutta, jos päätös:{"\n"}
                  {"\n"}• on välttämätön rekisteröidyn ja rekisterinpitäjän
                  välisen sopimuksen tekemistä tai täytäntöönpanoa varten;{"\n"}
                  {"\n"}• on hyväksytty rekisterinpitäjään sovellettavassa
                  unionin oikeudessa tai jäsenvaltion lainsäädännössä, jossa
                  vahvistetaan myös asianmukaiset toimenpiteet rekisteröidyn
                  oikeuksien ja vapauksien sekä oikeutettujen etujen
                  suojaamiseksi; tai{"\n"}
                  {"\n"}• perustuu rekisteröidyn nimenomaiseen suostumukseen.
                  {"\n"}
                </Text>

                <Text style={theme.boldText}>
                  Oikeus peruuttaa suostumus{"\n"}
                </Text>
                <Text style={theme.text}>
                  Mikäli henkilötietojen käsittelyn oikeusperustana on
                  rekisteröityjen antama suostumus, on rekisteröidyillä myös
                  oikeus suostumuksen peruuttamiseen.{"\n"}
                  {"\n"}
                </Text>

                <Text style={theme.title}>
                  12. OIKEUS TEHDÄ VALITUS VALVONTAVIRANOMAISELLE{"\n"}
                </Text>
                <Text style={theme.text}>
                  Jokaisella rekisteröidyllä on oikeus tehdä valitus
                  valvontaviranomaiselle, jos rekisteröity katsoo, että häntä
                  koskevien henkilötietojen käsittelyssä rikotaan
                  tietosuoja-asetusta. Valitus voidaan tehdä siinä EU:n
                  jäsenvaltiossa, jossa rekisteröidyn vakinainen asuinpaikka tai
                  työpaikka on taikka jossa väitetty rikkominen on tapahtunut.
                  {"\n"}
                </Text>

                <Text style={theme.title}>13. EVÄSTEET{"\n"}</Text>
                <Text style={theme.text}>
                  Palvelu käyttää evästeitä, joiden tarkoituksena on kehittää
                  Palvelua ja tehdä siitä käyttäjäystävällisempi. Tämä on
                  tavanomainen toimintapa useilla verkkosivuilla. Evästeet ovat
                  pieniä tiedostoja, joita verkkosivu tallentaa laitteellesi,
                  kun vierailet verkkosivulla. Evästeet tallentavat tietoa
                  käyttäytymisestäsi verkkosivulla. Evästeitä ei käytetä
                  henkilön tunnistamiseen.{"\n"}
                  {"\n"}
                  Voit vapaasti hallinnoida evästeiden käyttöä
                  verkkoselaimessasi. Ohjeet tähän löytyy esimerkiksi
                  verkko-osoitteesta: aboutcookies.org.{"\n"}
                </Text>

                <Text style={theme.title}>
                  14. KÄSITTELYN TURVALLISUUS{"\n"}
                </Text>
                <Text style={theme.text}>
                  Toteutamme mm. seuraavat tekniset ja organisatoriset
                  toimenpiteet varmistaaksemme asianmukaisen turvallisuustason
                  henkilötietojen käsittelylle:{"\n"}
                  {"\n"}• Salaamme tietokantamme ja käyttäjien salasanat
                  käyttäen kaikkia asianmukaisia keinoja.{"\n"}
                  {"\n"}• Suojaamme järjestelmiemme ja palvelumme
                  luottamuksellisuuden, eheyden, käytettävyyden ja
                  vikasietoisuuden käyttäen kaikkia asianmukaisia keinoja, kuten
                  mm. tokeneita.{"\n"}
                  {"\n"}• Tietokantamme varmuuskopioidaan säännöllisesti, ja
                  tiedot voidaan palauttaa helposti.{"\n"}
                  {"\n"}• Tietojen käsittelymme auditoidaan säännöllisesti,
                  jonka seurauksena pystymme takaamaan, että noudatamme kaikkia
                  relevantteja tietosuojalakeja.{"\n"}
                </Text>

                <Text style={theme.title}>15. PROFILOINTI{"\n"}</Text>
                <Text style={theme.text}>
                  Uudet käyttäjät voivat ottaa Docean-palvelun käyttöön
                  tekemällä itselleen profiilin. Profiili vaatii uuden tilin
                  luomisen. Rekisteröinti voidaan tehdä joko luomalla Tiitukseen
                  käyttäjätili syöttämällä sähköposti ja salasana. Pakollisia
                  tietoja ovat nimi, sähköposti, puhelin,yritys jossa
                  työskentelee. Profiilia luodessa täytyy tunnistautua
                  verkkopankkitunnuksilla henkilöllisyyden oikeellisuuden
                  varmistamiseksi.{"\n"}
                </Text>

                <Text style={theme.title}>
                  16. TIETOJENSUOJA PERIAATTEET{"\n"}
                </Text>
                <Text style={theme.text}>
                  Docean Oy käyttää kaikkia kohtuullisia toimia ylläpitääkseen
                  fyysisiä, elektronisia ja hallinnollisia turvatoimia
                  suojatakseen henkilökohtaisia tietojasi luvattomalta tai
                  sopimattomalta käytöltä mutta huomauttaa myös, että Internet
                  ei ole aina turvallinen väline. Docean Oy rajoittaa sinuun
                  liittyvien tietojen saantia niille Docean Oy:n henkilöstöön
                  kuuluville, joiden täytyy tietää nämä tiedot esimerkiksi
                  heidän vastatessaan kysymyksiisi tai pyyntöihisi.{"\n"}
                </Text>
              </ScrollView>
            </View>
            <DualButtons
              leftButtonDisabled={props.hideAcceptButton}
              leftButtonTitle={t("back")}
              onLeftButtonPress={props.onGDPRClose}
              rightButtonTitle={props.rightButtonTitle || t("agree")}
              onRightButtonPress={props.onGDPRAccept}
            />
          </View>
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
};

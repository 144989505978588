export const validatePhoneNumber = (value, validIfEmpty) => {
  if (!value) {
    if (validIfEmpty) {
      return true;
    } else {
      return false;
    }
  }
  return /^\(?\+?[\d]{1,4}\)?[\d]{5,12}$/g.test(value.replace(/[\s]/gi, ""));
};

export const validateZipCode = (elementValue, validIfEmpty) => {
  if (!elementValue) {
    if (validIfEmpty) {
      return true;
    } else {
      return false;
    }
  }
  return /^\d{5}$|^\d{5}-\d{4}$/.test(elementValue);
};

export const validateEmail = (text, validIfEmpty) => {
  if (!text) {
    if (validIfEmpty) {
      return true;
    } else {
      return false;
    }
  }
  const textToTest = String(text.replace(/[\s]/gi, ""));
  //var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(textToTest).toLowerCase());
};

export const validateFileName = (text) => {
  if (!text) {
    return "emptyStr";
  }

  // Remove any surrounding whitespace
  const textToTest = String(text.trim());

  // Check for empty after trimming
  if (!textToTest.length) {
    return "emptyStr";
  }

  // Regex to allow Unicode letters, numbers, and most symbols, but disallow / and null byte
  // eslint-disable-next-line no-useless-escape
  const re = /[\/\0]/g;

  if (re.test(textToTest)) {
    return "invalidChars"; // File name contains forbidden characters
  }

  return null; // File name is valid
};

export const checkIfFilled = (value, validateBoolean) => {
  if (typeof value === "boolean") {
    if (validateBoolean) {
      return value;
    } else return true;
  } else if (value) {
    return /\S/.test(value);
  } else {
    return false;
  }
};

function isValidRegex(pattern) {
  try {
    return new RegExp(pattern);
  } catch {
    return null;
  }
}

export const validateWithRegex = (regexStr, value) => {
  const regex = isValidRegex(regexStr);

  if (regex && typeof value === "string") {
    return regex.test(value.trim());
  } else return true;
};

import { useEffect, useState, useRef } from "react";
import { Platform, Linking, Text, View, TouchableOpacity } from "react-native";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Alert from "../components/AlertModal";
import Modal from "../components/Modal";
import SimpleForm from "../components/SimpleForm";
import LoadingView from "../components/LoadingView";
import SimpleModalPicker from "../components/SimpleModalPicker";
import Settings from "../components/Settings";
import { ThemeContext } from "../theming/theme-context";
import {
  modalPickerOpts,
  fetchNetInfo,
  RNGoogleSignin,
  wp,
  fullHp,
  fullWp,
  navigate,
  showToast,
} from "../lib/helperFns";
import { errorReport, isArrayWithItems } from "../lib/functions";
import { deleteWithToken, getWithToken, putWithToken } from "../lib/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setFetchNotification,
  resetFetchNotification,
} from "../actions/ApiActions";
import {
  SET_UI_SETTINGS_PROP,
  signOut,
  deleteUser,
  changePhoneNumber,
  toggleProfileTheme,
  toggleProfileFontSize,
} from "../actions/ProfileActions";
import { lsStat, unlinkFile, dirs } from "../lib/fileOperations";
import { api } from "../lib/constants";
import axios from "axios";

const emailSendInstance = axios.create({
  baseURL: api,
  https: true,
  timeout: 40000,
});

export const SettingsScreen = (props) => {
  const { t, i18n } = useTranslation();
  const drawerRef = useRef(null);
  const [, setDrawerIsOpen] = useState(false);
  const [modalForm, setModalForm] = useState({ isVisible: false });
  const [modalPicker, setModalPicker] = useState({ visible: false });
  const [alert, setAlert] = useState({ visible: false });
  const [_profile, _setProfile] = useState({});

  useEffect(() => {
    getWithToken("/users/profile").then((res) => {
      if (res.status === 200) {
        _setProfile(res.data);
      }
    });
  }, []);

  const toggleAlert = () => {
    setAlert({ visible: false });
  };

  const closeModalForm = () => {
    setModalForm({ isVisible: false });
  };

  const closeDrawer = () => {
    if (Platform.OS !== "web") {
      drawerRef.current.closeDrawer();
    } else {
      setDrawerIsOpen(false);
    }
  };

  const setUiSettingsProp = (key, value) => {
    if (props.profile.role !== "Trial") {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          putWithToken(
            {
              key: key,
              value: value,
            },
            "/users/uiSettings"
          ).then(() => {
            props.SET_UI_SETTINGS_PROP({
              prop: key, //`${OS}_${key}`,
              value: value,
            });
          });
        }
      });
    } else {
      props.SET_UI_SETTINGS_PROP({
        prop: key, //`${OS}_${key}`,
        value: value,
      });
    }
  };

  async function signOut() {
    if (Platform.OS !== "web") {
      try {
        await RNGoogleSignin.revokeAccess();
        await RNGoogleSignin.signOut();
      } catch (error) {
        console.error(error);
      }
    }
    props.signOut();
  }

  // async function handleUserDeleted() {
  //   if (Platform.OS === "web") {
  //     await unlinkFile(dirs.CacheDir);
  //     props.deleteUser();
  //   } else {
  //     const filesToKeep = [
  //       "PersistedInstallation",
  //       "generatefid",
  //       "ReactNative",
  //       "SKS",
  //     ];
  //     // persistStore

  //     let cacheDir;
  //     let fileDir;

  //     if (Platform.OS === "ios") {
  //       cacheDir = dirs.CacheDir.split("/");
  //       cacheDir = cacheDir.slice(0, cacheDir.length - 2);
  //       cacheDir.push("tmp");
  //       cacheDir = cacheDir.join("/");

  //       fileDir = dirs.DocumentDir;
  //     } else {
  //       cacheDir = dirs.CacheDir;
  //       fileDir = dirs.DocumentDir;
  //     }
  //     await unlinkFile(cacheDir);
  //     const files = await lsStat(fileDir);
  //     for (let j = 0; j < files.length; j++) {
  //       const file = files[j];
  //       if (file && !filesToKeep.some((x) => file.filename.startsWith(x))) {
  //         const exist = await fileExists(file.path);
  //         if (exist) {
  //           const res = await unlinkFile(file.path);
  //         }
  //       }
  //     }
  //     props.deleteUser();
  //   }
  // }

  const cancelUserDeletion = () => {
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        getWithToken("/users/canceldelete").then(async (res) => {
          if (res.status === 204) {
            showToast(t("userDeletionCancelled"));
            _setProfile((_data) => ({ ..._data, deleteRequestedOn: null }));
          } else {
            showToast(t("unhandledError"));
          }
        });
      } else {
        t("checkInternetConnection");
      }
    });
  };
  const handleUserDeletionAgreed = () => {
    // TODO clear data when user is deleted in a week????
    // TODO clear redux
    // TODO clear file storage
    // TODO call normal sign out

    // handleUserDeleted();
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        deleteWithToken({}, "/users/delete").then(async (res) => {
          if (res.status === 200) {
            showToast(
              t("userWillBeDeletedAt") +
                " " +
                moment(res.data).format("DD.MM.YYYY"),
              10000,
              "accent"
            );
            _setProfile((_data) => ({
              ..._data,
              deleteRequestedOn: moment(res.data)
                .subtract(7, "days")
                .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            }));
          } else {
            showToast(t("unhandledError"));
          }
        });
      } else {
        t("checkInternetConnection");
        // handleUserDeleted();
      }
    });
  };

  async function handleDeleteUser() {
    setAlert({
      visible: true,
      title: t("deleteUserConfirmation"),
      text:
        props.role === "Trial"
          ? t("trialUserDeletionInfo")
          : t("userDeletionInfo"),
      middleButtonDisabled: true,
      leftButtonTitle: t("cancel"),
      rightButtonTitle: t("yes"),
      cancelButton: true,
      onMiddleButtonPress: null,
      onRightButtonPress: handleUserDeletionAgreed,
    });
  }

  // const responseGoogle = (response) => {
  //   // TODO handle google sign in
  //   console.warn("response", response);
  //   // if (response?.tokenId) {
  //   //   props.handleGoogleSignIn(response.tokenId);
  //   // } else {
  //   //   console.warn(response);
  //   // }
  // };

  function saveEmailCredentials(values) {
    emailSendInstance({
      method: "put",
      url: "email/credentials",
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
      data: values,
    })
      .then((res) => {
        if (res.status === 204) {
          setModalForm({
            isVisible: false,
          });
          showToast(t("credentialsSaved"));
        } else {
          if (
            res.response?.data?.startsWith("Sending with credentials failed")
          ) {
            showToast(t("sendWithCredentialsFailed"));
          } else {
            showToast(t("sendingTestEmailFailed"));
          }
          setModalForm((_modalForm) => ({
            ..._modalForm,
            loading: null,
          }));
        }
      })
      .catch((err) => {
        if (err.response.data.includes("Username and Password not accepted")) {
          setModalForm((_modalForm) => ({
            ..._modalForm,
            loading: null,
            infoText: t("lessSecureAppInfo"),
          }));
          showToast(t("checkEmailOrPassword"));
        } else if (err.response.data.includes("534")) {
          setModalForm((_modalForm) => ({
            ..._modalForm,
            loading: null,
            infoText: t("signInAndTryAgainGmailInfo"),
          }));
          showToast(t("checkEmailOrPassword"));
        } else {
          setModalForm((_modalForm) => ({
            ..._modalForm,
            isVisible: true,
            loading: null,
          }));
          showToast(t("sendingTestEmailFailed"));
        }
      });
  }

  useEffect(() => {
    if (modalForm?.loading) {
      saveEmailCredentials(modalForm.loading);
    }
  }, [modalForm]);

  /**
   * Credentials save will try to send a test email from server.
   * @param {*} values Values from inputs
   */
  function onEmailCredentialsSave(values) {
    setModalForm((_modalForm) => ({
      ..._modalForm,
      isVisible: true,
      loading: values,
    }));
  }

  const setEmailCredentialInputs = (values) => {
    const emailCredentialsInputs = [
      {
        title: { all: t("emailCredentials") },
        type: "title",
        size: "h1",
        hint: {
          all: t("unfinishedDocSendingUsesTheseCredentials"),
        },
      },
      {
        title: {
          all: t("gmailCredentialsInstructions"),
        },
        type: "label",
      },
      {
        key: "user",
        title: { all: t("emailAddress") },
        type: "textField",
        validations: [
          { fn: "validateEmail", errMsg: { all: t("checkEmail") } },
        ],
        onSaveActions: ["toLowerCase", "removeWhitespace"],
        required: true,
        hint: { all: t("emailToSendFrom") },
      },
      {
        key: "password",
        password: true,
        title: { all: t("emailPassword") },
        defaultValue: "",
        type: "textField",
        required: true,
        onSaveActions: ["trim"],
      },
      {
        key: "secret",
        password: true,
        title: { all: t("emailSendSecret") },
        defaultValue: "",
        type: "textField",
        required: true,
        onSaveActions: ["trim"],
        hint: { all: t("emailSendSecretInfo") },
      },
      {
        key: "client",
        title: { all: t("smtpCredentialsClient") },
        defaultValue: "",
        type: "textField",
        required: true,
        onSaveActions: ["trim"],
        hint: {
          all: t("smtpCredentialsClientHint"),
        },
      },
      {
        key: "port",
        title: { all: t("smtpCredentialsPort") },
        defaultValue: "",
        type: "textField",
        required: true,
        numeric: true,
        onSaveActions: ["trim"],
        hint: {
          all: t("smtpCredentialsPortHint"),
        },
      },
    ];
    setModalForm({
      isVisible: true,
      inputs: isArrayWithItems(values)
        ? emailCredentialsInputs.map((x) => ({
            ...x,
            value: values[0][x.key].toString(),
          }))
        : emailCredentialsInputs,
      onSave: onEmailCredentialsSave,
      isEmailForm: __DEV__, // ! NEEDS REWORK IF CAN USE EMAIL API FROM GOOGLE
    });
  };
  const renderFontSizePicker = () => {
    return (
      <ThemeContext.Consumer>
        {({ theme, colors, fontSize, changeFontSize }) => (
          <View
            style={[
              theme.drawerItemContainer,
              { paddingLeft: 8, backgroundColor: colors.primary },
            ]}
          >
            <View style={{ flex: 4, justifyContent: "center" }}>
              <Text style={theme.boldText}>{t("fontSize")}</Text>
            </View>
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-around",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  props.toggleProfileFontSize("SmallText");
                  changeFontSize("SmallText");
                }}
              >
                <View
                  style={{
                    padding: 8,
                    borderWidth: 0,
                    borderColor: colors.accent,
                    borderBottomWidth: fontSize === "SmallText" ? 1 : 0,
                  }}
                >
                  <Text style={[theme.text, { fontSize: wp(2.7) }]}>Aa</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  props.toggleProfileFontSize("MediumText");
                  changeFontSize("MediumText");
                }}
              >
                <View
                  style={{
                    padding: 8,
                    borderWidth: 0,
                    borderColor: colors.accent,
                    borderBottomWidth: fontSize === "MediumText" ? 1 : 0,
                  }}
                >
                  <Text style={[theme.text, { fontSize: wp(3.4) }]}>Aa</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  props.toggleProfileFontSize("LargeText");
                  changeFontSize("LargeText");
                }}
              >
                <View
                  style={{
                    padding: 8,
                    borderWidth: 0,
                    borderColor: colors.accent,
                    borderBottomWidth: fontSize === "LargeText" ? 1 : 0,
                  }}
                >
                  <Text style={[theme.text, { fontSize: wp(4) }]}>Aa</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  };

  const deleteAppFiles = async (files) => {
    setModalPicker((_modalPicker) => ({ ..._modalPicker, isFetching: true }));
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        await unlinkFile(file.path);
      } catch {
        /* empty */
      }
    }
    openFilePickerModal();
  };

  const openFilePickerModal = async () => {
    try {
      const files = await lsStat(dirs.DocumentDir);
      let data = [];

      if (Platform.OS !== "web") {
        data = files.reduce((_files, file) => {
          let lowerCaseFilename = file.filename.toLowerCase();
          if (
            !file.filename.startsWith("companyLogo") &&
            ["jpg", "jpeg", "png", "pdf"].some((char) =>
              lowerCaseFilename.endsWith(char)
            )

            // file.filename &&
            // file.type === "file" &&
            // !file.filename.startsWith("companyLogo") &&
            // file.filename.includes(".") &&
            // !["json", "lock", "js"].some((char) =>
            //   lowerCaseFilename.endsWith(char)
            // )
          ) {
            _files.push({ path: file.path, name: file.filename });
          }
          return _files;
        }, data);
      } else {
        data = files.reduce((_files, filePath) => {
          if (filePath.startsWith("/") && filePath !== "/companyLogo.jpg") {
            _files.push({
              path: filePath,
              name: filePath.substring(1, filePath.length),
            });
          }
          return _files;
        }, data);
      }

      setModalPicker({
        visible: true,
        title: t("deleteAppFiles"),
        data: data,
        multiple: true,
        textProp: "name",
        isFetching: false,
        action: deleteAppFiles,
      });
    } catch (error) {
      errorReport({
        error,
        errorInFn: "openFilePickerModal",
        errorInScreen: "SettingsScreen",
      });
    }
  };

  const getSettings = (
    selectedTheme,
    theme,
    colors
    // toggleTheme
  ) => {
    const isAdmin = props.role === "Admin" || props.role === "SuperAdmin";
    let settings;
    if (!props.onlyProfileSettings) {
      settings = [
        {
          type: "select",
          key: "language",
          title: "language",
          values: i18n.options.supportedLngs.filter((lng) => lng !== "cimode"),
          value: i18n.language,
          setValue: (key, value) => {
            i18n.changeLanguage(value);
            props.SET_UI_SETTINGS_PROP({
              prop: "language",
              value: value,
            });
          },
        },
        {
          type: "select",
          key: "homeScreen",
          title: "startScreen",
          values: ["browse", "folders", "Customers", "Sites"],
          valueTitles: ["documentBrowse", "folders", "Customers", "Sites"],
          value: props.uiSettings?.homeScreen,
          defaultValue: "browse",
        },
        {
          type: "select",
          key: "defaultCompletedDocAction",
          title: "defaultCompletedDocAction",
          values: ["editForm", "preview"],
          valueTitles: ["toEditForm", "pdfPreview"],
          value: props.uiSettings?.defaultCompletedDocAction,
          defaultValue: "preview",
        },
        {
          key: "saveAttachmentsToPhotos",
          type: "checkBox",
          title: t("saveAttachmentsToPhotos"),
          value: props.uiSettings?.saveAttachmentsToPhotos,
        },
        {
          key: "saveAttachmentsOnMount",
          type: "checkBox",
          title: t("saveAttachmentsOnMount"),
          value: props.uiSettings?.saveAttachmentsOnMount,
        },
        {
          key: "defaultPDFLanguage",
          type: "select",
          title: t("defaultPDFLanguage"),
          // loops all layouts and gets their languages
          values: Object.keys(props.options?.layouts || {}).reduce(
            (prev, layoutId) => {
              const layoutWrapper = props.options.layouts[layoutId];
              const highestVersion = Math.max(
                ...Object.keys(layoutWrapper.versions)
              );
              const layout = layoutWrapper.versions[highestVersion];

              if (layout.languages) {
                layout.languages.forEach((x) => {
                  if (!prev.includes(x)) prev.push(x);
                });
              }
              return prev;
            },
            []
          ),
          value: props.uiSettings?.defaultPDFLanguage || "",
        },
        {
          key: "signDigitally",
          type: "checkBox",
          title: t("digitalSignature"),
          value: props.uiSettings?.signDigitally ?? props.signDigitally,
        },
      ];
      if (Platform.OS !== "web") {
        settings.push({
          key: "fontSize",
          type: "custom",
          component: renderFontSizePicker,
        });
      } else {
        // settings.push({
        //   type: "checkBox",
        //   key: "theme",
        //   title: t("darkThemeOn"),
        //   value: props.uiSettings?.selectedTheme
        //     ? props.uiSettings.selectedTheme === "dark"
        //     : true,
        //   setValue: () => {
        //     const newValue = (
        //       props.uiSettings?.selectedTheme
        //         ? props.uiSettings.selectedTheme === "dark"
        //         : true
        //     )
        //       ? "light"
        //       : "dark";
        //     setUiSettingsProp("selectedTheme", newValue);
        //     toggleTheme(newValue);
        //   },
        // });
      }

      settings.push({
        type: "button",
        title: t("setPersonalEmailCredentials"),
        setValue: () => {
          fetchNetInfo().then((state) => {
            if (state.isConnected) {
              getWithToken("email/credentials")
                .then((res) => {
                  if (res.status === 200) {
                    setEmailCredentialInputs(res.data);
                  } else {
                    setEmailCredentialInputs();
                  }
                })
                .catch(() => {
                  setEmailCredentialInputs();
                });
            } else {
              showToast(t("checkInternetConnection"));
            }
          });
        },
      });
      settings.push({
        type: "button",
        title: t("contact"),
        setValue: () =>
          Linking.canOpenURL("mailto:info@docean.fi?subject=Support").then(
            (canOpen) => {
              if (canOpen) {
                Linking.openURL("mailto:info@docean.fi?subject=Support");
              } else {
                // TODO add toast
                // showToast(t())
              }
            }
          ),
      });
      settings.push({
        type: "button",
        title: t("appInfo"),
        setValue: () => navigate("AppInfo", props.navigation),
      });

      settings.push({
        type: "button",
        title: t("deleteAppFiles"),
        setValue: openFilePickerModal,
      });
    } else {
      settings = [];
    }

    if (_profile?.deleteRequestedOn) {
      settings.push({
        type: "button",
        title:
          t("userWillBeDeleted") +
          " " +
          moment(_profile?.deleteRequestedOn)
            .add(7, "days")
            .format("DD.MM.YYYY") +
          "; " +
          t("cancel"),
        textColor: "#fff",
        backgroundColor: colors.error,
        setValue: cancelUserDeletion,
      });
    }
    // disable user deletion button for play store and app store testers
    else if (
      props.profile?.email &&
      props.profile.email !== "playtest@docean.fi"
    ) {
      settings.push({
        type: "button",
        title: t("deleteUser"),
        textColor: "#fff",
        backgroundColor: colors.error,
        setValue: handleDeleteUser,
      });
    }

    settings.push({
      type: "button",
      title: t("signOut"),
      textColor: colors.textOnAccent,
      backgroundColor: colors.accent,
      setValue: signOut,
    });

    if (isAdmin) {
      settings.push({
        type: "custom",
        component: () => <Text style={theme.title}>Admin settings</Text>,
      });

      settings.push({
        key: "skipIntegrationTenantVerify",
        type: "checkBox",
        title: "Skip integration tenant verify from Fondion link",
        value: props.uiSettings?.skipIntegrationTenantVerify,
      });

      // settings.push({
      //   key: "companyContactEmails",
      //   type: "button",
      //   title: "Get all companies contact emails",
      //   setValue: () => {
      //     getWithToken("/admins/companyContactEmails");
      //   },
      // });

      settings.push({
        key: "syncAttachedToDocs",
        type: "button",
        title: "syncAttachedToDocs",
        setValue: () => {
          const tmpInstance = axios.create({
            baseURL: api,
            https: true,
            timeout: 0,
          });
          tmpInstance.defaults.withCredentials = true;
          tmpInstance.get("/admins/syncAttachedToDocs");
        },
      });

      if (__DEV__) {
        // settings.push({
        //   key: "get database statistics",
        //   type: "button",
        //   title: "Get database statistics",
        //   setValue: () => {
        //     const tmpInstance = axios.create({
        //       baseURL: api,
        //       https: true,
        //       timeout: 0,
        //     });
        //     tmpInstance.defaults.withCredentials = true;

        //     tmpInstance.get("/admins/statistics").then((res) => {
        //       if (res.status === 200) {
        //         console.warning(
        //           "finished docs",
        //           res.data.reduce((acc, cur) => {
        //             acc[cur.companyName] =
        //               cur.detailedCollectionStatistics.collections.DocFinisheds
        //                 ?.countOfDocuments || 0;
        //             return acc;
        //           }, {})
        //         );
        //       }
        //     });
        //   },
        // });

        settings.push({
          key: "get docs created from layout",
          type: "button",
          title: "Get docs created from layout",
          setValue: () => {
            const tmpInstance = axios.create({
              baseURL: api,
              https: true,
              timeout: 0,
            });
            tmpInstance.defaults.withCredentials = true;

            tmpInstance
              .get(
                "/admins/docsCreatedFromLayout?layoutId=" +
                  encodeURIComponent("layouts/420-A")
              )
              .then((res) => {
                if (res.status === 200 && res.data) {
                  const keys = Object.keys(res.data);
                  console.warning(
                    "docsCreatedFromLayout",
                    keys.reduce((acc, key) => {
                      const obj = res.data[key];

                      if (obj) {
                        acc[key] = obj;
                      }
                      return acc;
                    }, {})
                  );
                }
              });
          },
        });

        // settings.push({
        //   key: "fixDependencyLayoutsSharedTo",
        //   type: "button",
        //   title: "Fix dependency layouts shared to",
        //   setValue: () => {
        //     const tmpInstance = axios.create({
        //       baseURL: api,
        //       https: true,
        //       timeout: 0,
        //     });
        //     tmpInstance.defaults.withCredentials = true;

        //     tmpInstance.get("/layouts/fixDependencyLayoutsSharedTo");
        //   },
        // });
      }

      settings.push({
        key: "errorTest",
        type: "button",
        title: "Sentry error boundary test",
        textColor: "#fff",
        backgroundColor: colors.error,
        setValue: () => {
          settings.onClick();
        },
      });

      settings.push({
        key: "errorTest",
        type: "button",
        title: "Sentry capture error test",
        textColor: "#fff",
        backgroundColor: colors.error,
        setValue: () => {
          errorReport({
            error: "test",
          });
        },
      });
    }
    return settings;
  };

  const renderFormHeader = (theme, colors) => {
    if (modalForm.infoText) {
      return (
        <View
          style={{
            padding: 8,
            margin: 8,
            backgroundColor: modalForm.infoBackgroundColor || colors.primary,
          }}
        >
          <Text
            style={[theme.text, { color: modalForm.infoColor || colors.text }]}
          >
            {modalForm.infoText}
          </Text>
        </View>
      );
    } else {
      return null;
    }
  };
  return (
    <ThemeContext.Consumer>
      {({ selectedTheme, theme, colors, toggleTheme }) => (
        <View style={[theme.container, theme.paddingContainer]}>
          <Settings
            theme={theme}
            colors={colors}
            role={props.role}
            closeDrawer={closeDrawer}
            noCloseButton
            settings={getSettings(selectedTheme, theme, colors, toggleTheme)}
            setValue={setUiSettingsProp}
          >
            {props.children}
          </Settings>

          {modalForm?.isVisible ? (
            <Modal
              {...modalPickerOpts}
              isVisible={true}
              backdropColor={colors.borderLighter}
              backdropOpacity={0.6}
              onBackButtonPress={closeModalForm}
              // style={{
              //   flex: 1,
              //   justifyContent: "flex-end",
              // }}
            >
              <View
                style={{
                  flex: 1,
                  borderWidth: 2,
                  borderColor: colors.accent,
                }}
              >
                {modalForm.loading ? (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: fullHp(100) * 0.9 - 36,
                      width: fullWp(100) * 0.9 - 4,
                      zIndex: 1000,
                    }}
                  >
                    <LoadingView title={t("sendingTestEmailInfo")} />
                  </View>
                ) : null}

                <SimpleForm
                  inputs={modalForm.inputs}
                  lang={i18n.language}
                  pageW={fullWp(90)}
                  pageH={fullHp(90)}
                  saveDisabled={modalForm.loading}
                  onSaveTitle={t("save")}
                  onSave={modalForm.onSave}
                  backButtonTitle={t("cancel")}
                  onBackButtonPress={closeModalForm}
                  renderHeader={renderFormHeader}
                >
                  {/* {modalForm.isEmailForm ? (
                    <View
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 16,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: colors.primary,
                          padding: 16,
                        }}
                      >
                        <View style={{ paddingBottom: 16 }}>
                          <Text style={theme.title}>
                            {t("googleEmailCredentialsInfo")}
                          </Text>
                        </View>
                        <GoogleLoginButton handleResponse={responseGoogle} />
                      </View>
                    </View>
                  ) : null} */}
                </SimpleForm>
              </View>
            </Modal>
          ) : null}

          <SimpleModalPicker
            {...modalPicker}
            onToggleModal={() =>
              setModalPicker((_modalPicker) => ({
                ..._modalPicker,
                visible: !_modalPicker.visible,
              }))
            }
          />
          <Alert toggleAlert={toggleAlert} {...alert} />
        </View>
      )}
    </ThemeContext.Consumer>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      deleteUser,
      toggleProfileTheme,
      toggleProfileFontSize,
      changePhoneNumber,
      setFetchNotification,
      resetFetchNotification,
      SET_UI_SETTINGS_PROP,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    options: state.options,
    uiSettings: state.userInfo.uiSettings,
    role: state.userInfo.profile.role,
    signDigitally: state.userInfo.uiSettings?.signDigitally ?? true,
    notificationToken: state.userInfo.notificationToken,
    profile: state.userInfo.profile,
    company: state.userInfo.company,
    isFetching: state.isFetching,
    fromScreen: state.userInfo.fromScreen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);

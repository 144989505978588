import { useState } from "react";
import { View, TextInput } from "react-native";
import IconButton from "./IconButton";

/**
 *
 * @param {*} props
 * isMobile
 * theme
 * colors
 * isVisible
 * isSelected
 * title
 * onSelect
 * onDuplicate
 * duplicationDisabled
 * onChangeText
 * onBlur
 * onAddNested
 * addDisabled
 * onToggleVisible if this is provided then visibility is assumed to be controlled by parent, otherwise this component will handle it
 * @returns
 */
export default function CollapsableItem(props) {
  const [isVisible, setIsVisible] = useState(
    props.openByDefault ? true : false
  );
  const _isVisible = props.onToggleVisible ? props.isVisible : isVisible;
  return (
    <View>
      <View
        style={[
          props.theme.rowContainer,
          {
            paddingTop: 4,
            paddingBottom: _isVisible ? 0 : 4,
            backgroundColor: props.colors.lightBg,
          },
        ]}
      >
        {props.creationRestricted ? null : (
          <>
            <IconButton
              backgroundColor={props.colors.accent}
              color={props.colors.textOnAccent}
              icon={props.isSelected ? "select-inverse" : "select"}
              size={48}
              onPress={props.onSelect}
              disabled={!props.onSelect}
            />

            <View style={props.theme.iconDivider} />

            <IconButton
              disabled={props.duplicationDisabled}
              backgroundColor={props.colors.accent}
              color={props.colors.textOnAccent}
              size={48}
              icon="content-duplicate"
              onPress={props.onDuplicate}
            />
          </>
        )}

        <View
          style={props.theme.textFieldPrimaryBg}
          pointerEvents={props.onChangeText ? "auto" : "none"}
        >
          <TextInput
            style={props.theme.textFieldPrimaryBg}
            disableSeparateFullscreen
            value={props.title}
            onChangeText={props.onChangeText}
            onBlur={props.onBlur}
            numberOfLines={1}
          />
        </View>

        {props.onAddNested ? (
          <IconButton
            backgroundColor={props.colors.accent}
            color={props.colors.textOnAccent}
            icon={"plus"}
            height={48}
            width={props.isMobile ? 48 : 72}
            onPress={props.onAddNested}
          />
        ) : null}

        {props.onAddNested ? <View style={props.theme.iconDivider} /> : null}

        <IconButton
          disabled={props.addDisabled}
          backgroundColor={props.colors.secondary}
          color={props.colors.text}
          icon={_isVisible ? "chevron-up" : "chevron-down"}
          height={48}
          width={props.isMobile ? 48 : 72}
          onPress={
            props.onToggleVisible
              ? props.onToggleVisible
              : () => setIsVisible((_) => !_)
          }
        />
      </View>
      {_isVisible && props.children ? (
        <View style={props.theme.lightBgBorder4}>{props.children}</View>
      ) : null}
    </View>
  );
}
